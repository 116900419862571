(function() {
    'use strict';

    var eaPlanInformation = {
        bindings: {
            policy: '<'
        },
        templateUrl: 'components/eligibility/bv-selection/rx/split-response/coverage-details/plan-information/plan-information.html',
        controller: 'PlanInformationController as planInfoCtrl'
    };

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilitySplitResponse')
        .component('eaPlanInformation', eaPlanInformation);
})();
