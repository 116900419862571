/**
 * Created by mcaputo on 1/26/17.
 */

(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Components.InsurancePolicy', [
            'eAccess.ProviderPortal.Components.Payer'
        ]);
})();
