(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Widgets.RecentlyExpiredBvList')
        .controller('RecentlyExpiredBvListController', RecentlyExpiredBvListController);

    RecentlyExpiredBvListController.$inject = ['StatusStyles', 'ReverificationService', 'DATA_LIMIT_CONSTS', '$log', 'Utility', 'ConfigurationService', 'Eligibility'];

    function RecentlyExpiredBvListController(StatusStyles, ReverificationService, DATA_LIMIT_CONSTS, $log, Utility, ConfigurationService, Eligibility) {
        var vm = this;

        // data
        vm.recentlyExpiredList = [];
        vm.recentlyExpiredBVsLoaded = true;

        // functions
        vm.getIconClass = StatusStyles.getAdjudicationIconClass;
        vm.getIconTooltipInfo = StatusStyles.getIconTooltipInfo;
        vm.getRecentlyExpiredBvs = getRecentlyExpiredBvs;
        vm.getRecentBvInDays = Utility.getRecentBvInDays;
        vm.changeState = Utility.shouldChangeState;
        vm.patientManagementStatus = ConfigurationService.getPatientManagementStatus;
        vm.reverifyCoverage = Eligibility.reverifyCoverage;

        vm.$onInit = function() {
            getRecentlyExpiredBvs();
        };

        function getRecentlyExpiredBvs() {
            var params = {
                showCount: false,
                offset: null,
                limit: DATA_LIMIT_CONSTS.DASHBOARD_REVERIFICATION_LIMIT,
                sortBy: null
            };
            vm.recentlyExpiredBVsLoaded = false;
            ReverificationService.loadReverifications(params.showCount, params.offset, params.limit, params.sortBy).then(
                function(response) {
                    vm.recentlyExpiredList = response.responseList;
                }
            ).catch(function(err) {
                $log.debug('error', err);
            }).finally(function () {
                vm.recentlyExpiredBVsLoaded = true;
            });
        }
    }
})();
