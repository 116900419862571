/**
 * Created by nikesh on 10/11/2017.
 */
(function() {
    'use strict';

    var eaBreadcrumb = {
        bindings: {
            crumbList: '=',
            callOnClick: '<',
            currentStep: '='
        },
        templateUrl: 'components/breadcrumb/breadcrumb.html',
        controller: 'BreadcrumbController as breadcrumb'
    };

    angular
        .module('eAccess.ProviderPortal.Components.Breadcrumb')
        .component('eaBreadcrumb', eaBreadcrumb);
})();
