/**
 * Created by mcaputo on 1/6/17.
 */
( function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.MedEligibility')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('medEligibility', {
                url: '/eligibility',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Check Coverage'
                },
                params: {
                    showProviderError: false
                },
                views: {
                    'content@': {
                        templateUrl: 'components/eligibility/bv-selection/med/med-eligibility.html',
                        controller: 'MedEligibilityController as med'
                    },
                    // Cached toast template file, this is necessary to display toast after browser offline
                    // http://cmikavac.net/2015/12/01/pre-caching-html-templates-with-angularjs-ui-router-directive/
                    cachedToastTemplate: {
                        templateUrl: 'components/toast-notification/toast-notification.html'
                    }
                }
            });

    }

})();
