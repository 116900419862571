(function() {
    'use strict';

    var eaResponseHeader = {
        bindings: {
            response: '<'
        },
        templateUrl: 'components/eligibility/bv-selection/rx/split-response/response-header/response-header.html',
        controller: 'RxSplitResponseHeaderController as responseHeaderCtrl'
    };

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilitySplitResponse')
        .component('eaResponseHeader', eaResponseHeader);
})();
