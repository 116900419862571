/**
 * Created by aayush.regmi on 10/17/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PracticeSetup')
        .controller('PracticeSetupCompleteController', PracticeSetupCompleteController);

    PracticeSetupCompleteController.$inject = ['$state', 'PracticeSetupView', 'UserInfoService', 'Storage', 'SystemNotificationService'];

    function PracticeSetupCompleteController($state, PracticeSetupView, UserInfoService, Storage, SystemNotificationService) {
        var vm = this;

        vm.redirectToDashboardPage = redirectToDashboardPage;

        init();

        function init() {

            PracticeSetupView.configure({
                title: 'Practice Setup Complete',
                linkText: 'Print Agreement',
                linkIcon: 'fa fa-print'
            });
        }

        function redirectToDashboardPage() {
            UserInfoService.setCurrentUserInfo();
            Storage.clearSessionData('isPracticeSetup');
            SystemNotificationService.showPortalNotification = true;
            $state.go('dashboard');
        }
    }
})();

