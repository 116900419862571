(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.MBVForm')
        .controller('MBVFormController', MBVFormController);

    MBVFormController.$inject = ['$anchorScroll', '$location', '$log', 'Eligibility', 'HELP_TEXT_CONSTS', 'ELIGIBILITY_CONSTS', 'INPUT_FORM_VALIDATION', 'Utility', 'ToastNotificationService', 'GLOBAL_CONSTS', 'ENV'];

    function MBVFormController($anchorScroll, $location, $log, Eligibility, HELP_TEXT_CONSTS, ELIGIBILITY_CONSTS, INPUT_FORM_VALIDATION, Utility, ToastNotificationService, GLOBAL_CONSTS, ENV) {
        var vm = this;
        vm.submitRequest = false;

        // data
        vm.mbvHelpText = HELP_TEXT_CONSTS.MANUAL_BENEFIT_VERIFICATIONS.HELP_TEXT;
        vm.rxMbvHelpText = HELP_TEXT_CONSTS.MANUAL_BENEFIT_VERIFICATIONS.RX_HELP_TEXT;
        vm.minLength = 10;
        vm.payerPhone = [];
        vm.mbvRequestSubmitted = false;     // indicates wheather the mbv form has already been submitted (successfully).
        vm.phoneNumberValidationMessage = INPUT_FORM_VALIDATION.PHONE_NUMBER_VALIDATION_MESSAGE;
        vm.pbmNameToolTip = HELP_TEXT_CONSTS.RX_RESPONSE.RX_MBV_FORM.PBM_NAME_LABEL;
        vm.pbmPhoneToolTip = HELP_TEXT_CONSTS.RX_RESPONSE.RX_MBV_FORM.PBM_PHONE_LABEL;
        vm.helpText = HELP_TEXT_CONSTS.MED_BV_RESPONSE.MBV_REQUEST_SECTION;
        vm.enableManualBv = ENV.ENABLE_MANUAL_BV;

        // functions
        vm.submitManualBV = submitManualBV;
        vm.submitRxManualBV = submitRxManualBV;
        vm.$onInit = function () {
            vm.isBVMethodMedical = Utility.isMedical.bind(null, vm.bvMethod);
        };

        function submitManualBV() {
            // bail if form is invalid
            if(vm.mbvSubmitForm.$invalid) {
                return false;
            }
            ToastNotificationService.clearToastNotification();
            vm.submitRequest = true;
            vm.mbvRequestSubmitted = false;

            var mbvRequest = _prepareMbvRequest();

            Eligibility.submitManualBV(mbvRequest).then(function(response) {
                vm.manualBvError = false;
                vm.mbvRequestSubmitted = true;
            }).catch(function(err) {
                vm.manualBvError = true;
            }).finally(function() {
                vm.submitRequest = false;
                vm.manualBvSubmitted = true;
                vm.manualBvSubmittedOpen = true;

                var mbvToastSettings = {
                    manualBvSubmitted: vm.manualBvSubmitted,
                    manualBvSubmittedOpen: vm.manualBvSubmittedOpen,
                    manualBvError: vm.manualBvError
                };

                vm.showMbvToast(mbvToastSettings);

                // auto focus top of eligibility page
                // we need to focus this instead of the mBV toast due to a timing bug
                // where sometimes the mBV element isn't ready before we try to focus,
                // and so the page doesn't move.
                $location.hash(ELIGIBILITY_CONSTS.ELIGIBILITY_RESPONSE);
                $anchorScroll();
            });
        }

        function _prepareMbvRequest() {
            var req = {};

            req.patientPhone = vm.patientPhone;
            req.patientPhoneType = vm.selectedPhoneType;
            if(vm.payerPhone[0]) {
                req.primaryPayerPhone = vm.payerPhone[0];
            }
            if(vm.payerPhone[1]) {
                req.secondaryPayerPhone = vm.payerPhone[1];
            }
            if(vm.payerPhone[2]) {
                req.tertiaryPayerPhone = vm.payerPhone[2];
            }
            req.responseId = vm.bvResponse.responseId;

            return req;
        }

        function _prepareRxMbvRequest() {
            var req = {};

            req.patientPhone = vm.patientPhone;
            req.patientPhoneType = vm.selectedPhoneType;
            req.responseId = vm.bvResponse.responseId;
            req.pbmName = vm.pbmName;
            req.pbmPhoneNumber = vm.pbmPhone;

            return req;
        }

        function submitRxManualBV() {
            if(vm.mbvSubmitForm.$invalid) {
                return false;
            }
            ToastNotificationService.clearToastNotification();
            vm.submitRequest = true;
            vm.mbvRequestSubmitted = false;

            var mbvRequest = _prepareRxMbvRequest();

            Eligibility.submitRxManualBV(mbvRequest).then(function(response) {
                vm.mbvRequestSubmitted = true;
                vm.manualBvError = false;
                vm.requestDate = response.requestSubmittedDate;
                _setToastNotification(ELIGIBILITY_CONSTS.MBV_SUCCESS_HEADER_MESSAGE, ELIGIBILITY_CONSTS.MBV_SUCCESS_MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
            }).catch(function(err) {
                vm.manualBvError = true;
                _setToastNotification(ELIGIBILITY_CONSTS.MBV_ERROR_HEADER_MESSAGE, ELIGIBILITY_CONSTS.MBV_ERROR_MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
                $log.info("Error getting rx mbv response", err);
            }).finally(function() {
                vm.submitRequest = false;
                vm.manualBvSubmitted = true;
                vm.manualBvSubmittedOpen = true;
                // auto focus top of eligibility page
                // we need to focus this instead of the mBV toast due to a timing bug
                // where sometimes the mBV element isn't ready before we try to focus,
                // and so the page doesn't move.
                vm.reloadData();
                $location.hash(ELIGIBILITY_CONSTS.ELIGIBILITY_RESPONSE);
                $anchorScroll();
            });
        }

        function _setToastNotification(header, message, toastType) {
            ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, header, message, toastType));
        }
    }
})();
