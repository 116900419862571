(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Patient')
        .controller('PatientAddController', PatientAddController);

    PatientAddController.$inject = ['$log', 'InsurancePolicy', 'Utility', 'WarnOnLeave', 'PatientService', 'moment', 'SEARCH_CONSTS', '$state', '$anchorScroll', 'USER_CONSTS', 'PATIENT_CONSTS', 'HELP_TEXT_CONSTS', 'ToastNotificationService', 'GLOBAL_CONSTS', 'STATUS_CODE'];

    function PatientAddController($log, InsurancePolicy, Utility, WarnOnLeave, PatientService, moment, SEARCH_CONSTS, $state, $anchorScroll, USER_CONSTS, PATIENT_CONSTS, HELP_TEXT_CONSTS, ToastNotificationService, GLOBAL_CONSTS, STATUS_CODE) {
        var vm = this;
        vm.savePatient = savePatient;
        vm.openWarnOnLeave = openWarnOnLeave;
        vm.validateAndSavePatient = validateAndSavePatient;
        vm.savingPatient = false;
        vm.displayCheckForUpdate = false;
        vm.patient = {
            dob: null,
            consentMessage: {
                onFile: false
            },
            patientConsent: {
                onFile: false
            }
        };
        vm.insurancePolicies = [new InsurancePolicy()];
        vm.showToastMessage = false;
        vm.consentHelpText = HELP_TEXT_CONSTS.BV_REQUEST.PATIENT_INFO.CONSENT;

        /* For displaying E-Hipaa Consent*/
        vm.disableSubmit = false;
        vm.isEhipaa = false;
        vm.patientConsent = {
            type: '',
            emailOrMobile: '',
            eHipaaStatus: '',
        };

        function validateAndSavePatient() {
            vm.savingPatient = true;
            PatientService.validateZipCode(vm.patient.address.zipCode).then(function (validity) {
                vm.patientForm.zipCode.$setValidity('zipcode', validity);
            }).catch(function (err) {
                $log.info('could not validate zipCode');
            }).finally(function () {
                savePatient();
            });
        }

        function savePatient() {
            if(vm.patientForm.$invalid) {
                vm.savingPatient = false;
                return false;
            }

            var requestData = {
                patient: angular.copy(vm.patient),
                policyList: !Utility.isEmpty(vm.insurancePolicies) ? angular.copy(vm.insurancePolicies)
                    .map(PatientService.mapPatientPolicyRequest)
                    .filter(function(policy) {
                        return _isPolicyFilled(policy);
                    }) : []
            };
            if(!Utility.isEmpty(requestData.patient.dob)) requestData.patient.dob = moment(requestData.patient.dob).format(SEARCH_CONSTS.DATE_FORMAT);

            PatientService.savePatient(requestData).then(function() {
                vm.patientForm.$setPristine();
                $state.go('patient.all', {patientAdded: true});
            }).catch(function(error) {
                $log.error("Error occurred saving patient: ", requestData, error);
                if(error.status === STATUS_CODE['409_CONFLICT']) {
                    ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(
                        true,
                        PATIENT_CONSTS.PATIENT_ALREADY_EXISTS.HEADER,
                        PATIENT_CONSTS.PATIENT_ALREADY_EXISTS.MESSAGE,
                        GLOBAL_CONSTS.TOAST_TYPES.ERROR
                    ));
                } else {
                    ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(
                        true,
                        PATIENT_CONSTS.PATIENT_NOT_ADDED.HEADER,
                        PATIENT_CONSTS.PATIENT_NOT_ADDED.MESSAGE,
                        GLOBAL_CONSTS.TOAST_TYPES.WARNING
                    ));
                }
                $anchorScroll();
            }).finally(function() {
                vm.savingPatient = false;
            });
        }

        function openWarnOnLeave() {
            WarnOnLeave.show({
                form: vm.patientForm,
                toState: {name: 'patient.all'}
            });

        }

        function _isPolicyFilled(policy) {
            return (!Utility.isEmpty(policy.rank) && policy.rank !== '')
                && (!Utility.isEmpty(policy.payer) && policy.payer !== '')
                && (!Utility.isEmpty(policy.memberId) && policy.memberId !== '');
        }
    }

})();
