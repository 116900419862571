/**
 * Created by nikesh on 10/30/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.AddPractice')
        .component('eaAddPractice', {
            templateUrl: 'components/add-practice/add-practice.html',
            controller: 'AddPracticeController as addPractice',
            bindings: {
                parentForm: '=',
                practice: '=',
                nextCallback: '=',
                practiceSaved: '=',
                genericErrorMessage: '<',
                savingPractice: '<',
                uniqueErrorMessage: '<'
            }
        });

})();
