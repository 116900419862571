(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Pharmacy')
        .factory('PharmacyService', PharmacyService);

    PharmacyService.$inject = ['API', '$log'];

    function PharmacyService(API, $log) {
        var service = {
            getPharmacies: getPharmacies
        };

        function getPharmacies(params) {
            // Remove try catch because the result data is not manipulated and returned as is.
            return API.get('pharmacy/search', params);
        }
        return service;
    }

})();
