/**
 * Created by nikesh on 3/11/2018.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PatientInfo')
        .controller('PatientInfoController', PatientInfoController);

    PatientInfoController.$inject = ['PatientInfoService', '$uibModal', '$scope', '$log', 'HELP_TEXT_CONSTS', 'Utility'];

    function PatientInfoController(PatientInfoService, $uibModal, $scope, $log, HELP_TEXT_CONSTS, Utility) {
        var vm = this;

        vm.validation = PatientInfoService.getValidationInfo();
        vm.errorMessages = PatientInfoService.getErrorMessages(vm.validation);

        vm.datePicker = PatientInfoService.getDatePickerOption();

        vm.searchFacets = PatientInfoService.getSearchFacetOptions();
        vm.validateDoBChange = validateDoBChange;
        vm.resetPatientIdInvalidity = resetPatientIdInvalidity;
        vm.isMedicareIdDisplayable = isMedicareIdDisplayable;

        vm.helpText = {
            ssn: HELP_TEXT_CONSTS.BV_REQUEST.PATIENT_INFO.SOCIAL_SECURITY,
            paracticePatientId: HELP_TEXT_CONSTS.BV_REQUEST.PATIENT_INFO.PRACTICE_PATIENT_ID,
            useExistingPatient: HELP_TEXT_CONSTS.BV_REQUEST.PATIENT_INFO.EXISTING_PATIENT_LINK
        };


        vm.openDatePicker = openDatePicker;
        vm.patientSearchCallback = patientSearchCallback;
        vm.patientSearchEligibilityCallback = patientSearchEligibilityCallback;
        vm.displaySearchModal = displaySearchModal;

        vm.checkInputLength = checkInputLength;
        vm.isInvalidSSN = false;
        vm.isInvalidMedicareId = false;

        // You can't use $watch with 'controller as' syntax, so we're using binding to get access
        // https://stackoverflow.com/questions/24078535/angularjs-controller-as-syntax-and-watch
        //
        // We are watching vm.patientInfo because on page init it doesn't exist and we can't access vm.patientInfo.db
        // This will be triggered every time any of the patientInfo is changed which isn't ideal, but is better than watching
        // all of the vm scope
        $scope.$watch(angular.bind(vm.patientInfo, function() {
            return vm.patientInfo.dob;
        }), function() {
            vm.validateDoBChange();
        });

        function validateDoBChange() {
            if (Utility.isEmpty(vm.patientInfo) || Utility.isEmpty(vm.patientInfo.dob)) {
                return;
            }
            vm.parentForm.dob.$setValidity('date-range-early', new Date(vm.patientInfo.dob).getTime() >= new Date('01/01/1900').getTime());
        }

        vm.$onInit = function() {
            if(Utility.isEmpty(vm.patientInfo)) {
                vm.patientInfo = {address: ''};
            }
            vm.showExistingPatientLink = Utility.isEmpty(vm.showExistingPatientLink);
        };

        function openDatePicker() {
            vm.datePicker.opened = true;
        }

        function patientSearchCallback(patientResponse) {
            vm.patientInfo = patientResponse;
            vm.patientInfo.practicePatientId = patientResponse.externalId;
            // ensure that the warn dialog will be triggered on leave
            vm.parentForm.$setDirty();
        }

        function patientSearchEligibilityCallback(data, externalId) {
            vm.searchEligibilityCallback(data, externalId);
        }

        function displaySearchModal() {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'partials/templates/patient-search-modal.html',
                controller: 'PatientSearchModalController',
                controllerAs: 'patientSearchModal',
                appendTo: angular.element('body'),
                resolve: {
                    searchFacets: function() {
                        return vm.searchFacets;
                    },
                    searchCallback: function() {
                        return vm.patientSearchCallback;
                    },
                    searchCallbackEligibility: function() {
                        return vm.patientSearchEligibilityCallback;
                    },
                    bvMethod: function() {
                        return vm.bvMethod;
                    }
                }
            });

            modalInstance.result.then(angular.noop, angular.noop);
            return modalInstance;
        }

        function resetPatientIdInvalidity() {
            if(!Utility.isEmpty(vm.parentForm.patientId.$error) && !Utility.isEmpty(vm.parentForm.patientId.$error.duplicate) && vm.parentForm.patientId.$error.duplicate === true) {
                vm.parentForm.patientId.$error = {};
            }
        }

        function isMedicareIdDisplayable() {
            return (Utility.isRx(vm.bvMethod) && Utility.isRxApiTypeSplit(vm.rxApiType));
        }

        function checkInputLength() {
            var inputLength = vm.patientInfo.ssnOrMedicareId.length;
            if (inputLength < 4) {
                vm.isInvalidMedicareId = false;
                vm.isInvalidSSN = true;
            } else if (inputLength > 4 && inputLength < 11) {
                vm.isInvalidSSN = false;
                vm.isInvalidMedicareId = true;
            } else if (inputLength > 11) {
                vm.isInvalidMedicareId = true;
            }
        }
    }
})();
