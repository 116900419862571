(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .factory('PracticeLocationReactivateDeactivate', PracticeLocationReactivateDeactivate);

    PracticeLocationReactivateDeactivate.$inject = ['$log', 'ReactivateDeactivate', 'LOCATION_CONSTS', 'Utility'];

    function PracticeLocationReactivateDeactivate($log, ReactivateDeactivate, LOCATION_CONSTS, Utility) {
        
        var locationReactivateDeactivateData = {
            reactivate : {
                multipleMessage: LOCATION_CONSTS.CONFIRMATION_MESSAGE.REACTIVATE_MULTIPLE_LOCATION,
                singleMessage:  LOCATION_CONSTS.CONFIRMATION_MESSAGE.REACTIVATE_ONE_LOCATION,
                replaceString: "#locations"
            },
            deactivate: {
                multipleMessage: LOCATION_CONSTS.CONFIRMATION_MESSAGE.DEACTIVATE_MULTIPLE_LOCATION,
                singleMessage: LOCATION_CONSTS.CONFIRMATION_MESSAGE.DEACTIVATE_ONE_LOCATION,
                replaceString: "#locations"
            },
            cannotDeactivate: {
                modalTitle: LOCATION_CONSTS.CANNOT_DEACTIVATE_ALL_MODAL.TITLE,
                modalBody:  LOCATION_CONSTS.CANNOT_DEACTIVATE_ALL_MODAL.BODY
            }
        };

        Utility.extend(PracticeLocationReactivateDeactivateInstance, ReactivateDeactivate);

        function PracticeLocationReactivateDeactivateInstance() {
            ReactivateDeactivate.call(this, locationReactivateDeactivateData);
        }

        function _setSubTitleText(locationList) {
            this.modalSubTitle =  _getSubtitleText.call(this,locationList);     
        }

        function _getSubtitleText(locationList) {
            if(this.list.length === 1) {
                var mapSize = locationList[this.viewableStatus].length;
                for(var i = 0; i < mapSize; i++) {
                    var currentLocationElement = locationList[this.viewableStatus][i];
                    if(currentLocationElement.locationId === this.list[0].locationId) {
                        return currentLocationElement.locationName;
                    }
                }
            }
            return '';
        }

        PracticeLocationReactivateDeactivateInstance.prototype.setModalSubTitle = function (locationList) {
            _setSubTitleText.call(this, locationList);
        };

        return PracticeLocationReactivateDeactivateInstance;
    }
})();
