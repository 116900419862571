/**
 * @ngdoc service
 * @name eAccess.ProviderPortal.Components.Practice.service:PracticeLocationService
 * @author Sterling Stokes
 * @requires $http
 * @requires $q
 * @requires $log
 * @requires PracticeLocationModel
 * @requires API
 * @description
 *
 * This is the service for practice location-specific API calls.
 **/

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .factory('PracticeLocationService', PracticeLocationService);

    PracticeLocationService.$inject = ['$http', '$q', '$log', 'PracticeLocationModel', 'API', 'PRACTICE_CONSTS', 'Utility', 'LOCATION_CONSTS', 'DATA_LIMIT_CONSTS'];

    function PracticeLocationService($http, $q, $log, PracticeLocationModel, API, PRACTICE_CONSTS, Utility, LOCATION_CONSTS, DATA_LIMIT_CONSTS) {

        var service = {
            loadPracticeLocationsByStatus: loadPracticeLocationsByStatus,
            createNewPracticeLocation: createNewPracticeLocation,
            updatePracticeLocation: updatePracticeLocation,
            preparePracticeLocationData: preparePracticeLocationData,
            getPracticeLocation: getPracticeLocation,
            preparePracticeLocationDataForUpdate: preparePracticeLocationDataForUpdate,
            getValidationInfo: getValidationInfo,
            getErrorMessages: getErrorMessages,
            updateLocationStatus: updateLocationStatus,
            getByLocationId: getByLocationId,
            getLocationsInformation: getLocationsInformation
        };

        /**
         * @ngdoc function
         * @name loadPracticeLocationsByStatus
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:PracticeLocationService
         * @description
         *
         * Loads practice locations based on active/inactive.
         *
         * @param showActive
         * @param count
         * @param limit
         * @param offset
         * @param sortBy
         */

        function loadPracticeLocationsByStatus(showActive, limit, offset, sortBy, count, nextLink) {
            var canceler = $q.defer();
            var deferred = $q.defer();

            var queryParams = _getQueryParams(showActive, limit, offset, sortBy, count);

            var locationApi = API.get('locations', queryParams, null, null, canceler, nextLink);

            locationApi.promise.then(_mapLocationResponse.bind(null, deferred)).catch(_handleError.bind(null, deferred));

            return {
                promise: deferred.promise,
                canceler: locationApi.canceler
            };

        }

        /**
         * function to map provider response to location list for view model
         * @param deferred
         * @param response
         * @private
         */
        function _mapLocationResponse(deferred, response) {
            var locations = response.data.locationList.map(function(location) {
                return new PracticeLocationModel(location);
            });
            var locationObj = {
                totalCount: response.data.locationCount || DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE,
                responseList: locations || []
            };
            locationObj.link = (!Utility.isEmpty(response.link)) ? response.link : null;
            deferred.resolve(locationObj);
        }

        /**
         * function to set query param object
         * @param showActive
         * @param count
         * @param limit
         * @param offset
         * @param sortBy
         * @returns {queryParamObject}
         * @private
         */
        function _getQueryParams(showActive, limit, offset, sortBy, count) {
            return {
                active: showActive,
                count: count || DATA_LIMIT_CONSTS.DEFAULT_COUNT,
                limit: !Utility.isEmpty(limit) ? limit : DATA_LIMIT_CONSTS.DEFAULT_LIMIT,
                sort: sortBy || LOCATION_CONSTS.LOCATION_SORT.LOCATION_NAME,
                offset: offset || DATA_LIMIT_CONSTS.DEFAULT_OFFSET,
            };
        }

        function _handleError(deferred, err) {
            $log.error('There was an error', err);
            deferred.reject(err);
        }

        /**
         *  Gets all locations information.
         *
         * @param showActive
         * @param count
         * @param limit
         * @param offset
         * @param sortBy
         */
        function getLocationsInformation(showActive, limit, offset, sortBy, count) {
            var deferred = $q.defer();

            var queryParams = _getQueryParams(showActive, limit, offset, sortBy, count);

            API.get('locations', queryParams).then(_mapLocationResponse.bind(null, deferred)).catch(_handleError.bind(null, deferred));

            return deferred.promise;
        }

        /**
         * Updates location status by using given request data.
         * @param requestData
         * @returns {Function}
         */
        function updateLocationStatus(requestData) {
            return API.put('locations/update/status', requestData);
        }

        function createNewPracticeLocation(locationData) {
            return !(locationData.locationId) ?
                API.post('locations/create', preparePracticeLocationData(locationData)) :
                service.updatePracticeLocation(locationData, locationData.locationId);
        }

        function updatePracticeLocation(locationData, locationId) {
            return API.put('locations/update', preparePracticeLocationDataForUpdate(locationData, locationId));
        }

        function preparePracticeLocationData(locationData) {
            return _getLocationRequestJSON(locationData);
        }

        function preparePracticeLocationDataForUpdate(locationData, locationId) {
            var model = _getLocationRequestJSON(locationData);
            model.locationId = locationId;
            return model;
        }

        function getPracticeLocation(locationId) {
            var deferred = $q.defer();

            API.get('locations/' + locationId).then(function(response) {
                var locations;
                if(response) {
                    locations = response;
                } else {
                    locations = [];
                }
                deferred.resolve(locations);
            });

            return deferred.promise;
        }

        function getValidationInfo() {
            return {
                name: {
                    minLength: PRACTICE_CONSTS.NAME_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.NAME_MAX_LENGTH
                },
                npi: {
                    minLength: PRACTICE_CONSTS.NPI_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.NPI_MAX_LENGTH
                },
                taxId: {
                    minLength: PRACTICE_CONSTS.TAX_ID_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.TAX_ID_MAX_LENGTH
                },
                practiceLocationId: {
                    minLength: PRACTICE_CONSTS.PRACTICE_LOCATION_ID_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.PRACTICE_LOCATION_ID_MAX_LENGTH
                },
                address1: {
                    minLength: PRACTICE_CONSTS.ADDRESS_1_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.ADDRESS_1_MAX_LENGTH
                },
                address2: {
                    minLength: PRACTICE_CONSTS.ADDRESS_2_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.ADDRESS_2_MAX_LENGTH
                },
                city: {
                    minLength: PRACTICE_CONSTS.CITY_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.CITY_MAX_LENGTH
                },
                zip: {
                    minLength: PRACTICE_CONSTS.ZIP_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.ZIP_MAX_LENGTH
                },
                mainPhone: {
                    minLength: PRACTICE_CONSTS.MAIN_PHONE_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.MAIN_PHONE_MAX_LENGTH
                },
                mainFax: {
                    minLength: PRACTICE_CONSTS.MAIN_FAX_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.MAIN_FAX_MAX_LENGTH
                },
                legalName: {
                    minLength: PRACTICE_CONSTS.LEGAL_NAME_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.LEGAL_NAME_MAX_LENGTH
                }
            };
        }

        function getErrorMessages(validation) {
            return {
                name: {
                    minLength: 'Name must be greater than ' + validation.name.minLength + ' characters',
                    maxLength: 'Name can be no more than ' + validation.name.maxLength + ' characters',
                    required: 'Name is required',
                    unique: 'A location with that name already exists'
                },
                npiNumber: {
                    minLength: 'Practice NPI must be exactly ' + validation.npi.minLength + ' characters',
                    maxLength: 'Practice NPI must be exactly ' + validation.npi.maxLength + ' characters',
                    required: 'Provider NPI is required',
                    invalid: PRACTICE_CONSTS.ERROR_MESSAGES.INVALID_LOCATION_NPI,
                    locationMinLength: 'Location NPI must be exactly ' + validation.npi.minLength + ' characters',
                    locationMaxLength: 'Location NPI must be exactly ' + validation.npi.maxLength + ' characters',
                    locationRequired: 'Location NPI is required',
                },
                taxIdNumber: {
                    // actual Tax ID length is 9, but this allows for the '-' character in mask / validation
                    minLength: 'Practice Tax ID must be exactly ' + (validation.taxId.minLength - 1) + ' characters',
                    maxLength: 'Practice Tax ID must be exactly ' + (validation.taxId.maxLength - 1) + ' characters',
                    required: 'Tax ID Number is required',
                    mask: 'Practice Tax ID must be exactly ' + (validation.taxId.minLength - 1) + ' characters'
                },
                practiceLocationId: {
                    minLength: 'Practice Location ID must be greater than ' + validation.practiceLocationId.minLength + ' characters',
                    maxLength: 'Practice Location ID can be no more than ' + validation.practiceLocationId.maxLength + ' characters',
                    unique: 'A location with that Practice Location ID already exists'
                },
                address1: {
                    minLength: 'Practice Address 1 must be greater than ' + validation.address1.minLength + ' characters',
                    maxLength: 'Practice Address 1 can be no more than ' + validation.address1.maxLength + ' characters',
                    required: 'Street Address is required'
                },
                address2: {
                    maxLength: 'Practice Address 2 can be no more than ' + validation.address2.maxLength + ' characters'
                },
                city: {
                    minLength: 'City must be greater than ' + validation.city.minLength + ' characters',
                    maxLength: 'City can be no more than ' + validation.city.maxLength + ' characters',
                    required: 'City is required'
                },
                zipCode: {
                    minLength: 'Zip Code must be exactly ' + validation.zip.minLength + ' characters',
                    maxLength: 'Zip Code must be exactly ' + validation.zip.maxLength + ' characters',
                    required: 'Zip Code is required'
                },
                mainPhoneNumber: {
                    mask: 'Invalid Telephone Number',
                    required: 'Main Phone Number is required'
                },
                mainFaxNumber: {
                    mask: 'Invalid main fax.'
                },
                legalName: {
                    minLength: 'Practice Legal Name must be greater than ' + validation.legalName.minLength + ' characters',
                    maxLength: 'Practice Legal Name can be no more than ' + validation.legalName.maxLength + ' characters',
                    required: 'Practice Legal Name is required'
                }

            };
        }

        function _getLocationRequestJSON(locationData) {
            return {
                locationName: locationData.locationName || null,
                practiceLocationId: locationData.practiceLocationId || null,
                npi: locationData.npi || null,
                taxId: locationData.taxId || null,
                address1: locationData.address1 || null,
                address2: locationData.address2 || null,
                city: locationData.city || null,
                state: locationData.state || null,
                zipCode: locationData.zipCode || null,
                mainPhone: locationData.mainPhone || null,
                mainFax: locationData.mainFax || null
            };
        }

        function getByLocationId(locationId) {
            return API.get('locations/' + locationId).then(function(response) {
                // map to location model
                return new PracticeLocationModel(response);
            });
        }

        return service;
    }
})();
