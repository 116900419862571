/**
 * @ngdoc Component
 * @module eAccess.ProviderPortal.Components.RxEligibilityResponse
 * @name eaAutoPolicyResponseHeader
 * @kind Component
 *
 * @description
 * This component is used for Auto RxmBV Response
 *
 * Basic Usage
 * <ea-auto-policy-response-header ></ea-auto-policy-response-header>
 *
 * @author pramesh
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .component('eaAutoPolicyResponseHeader', {
            bindings: {
                insurancePolicies: '<',
                responseDate: '<',
                autoMbvErrorMessage: '<'
            },
            templateUrl: 'components/eligibility/bv-selection/rx/rx-response/auto-policy-response-header/auto-policy-response-header.html',
            controller: 'AutoRxPolicyResponseHeaderController as autoRxPolicyResponseHeaderCtrl'
        });
})();
