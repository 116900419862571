(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ConsentMessage', [
            'eAccess.ProviderPortal.Constants.Patients'
        ]);

})();
