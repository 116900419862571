/**
 * Created by nikesh on 3/11/2018.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PatientInfo')
        .factory('PatientInfoService', PatientInfoService);

    PatientInfoService.$inject = ['PATIENT_INFO_CONSTS', 'ERROR_MESSAGES', 'SEARCH_CONSTS'];

    function PatientInfoService(PATIENT_INFO_CONSTS, ERROR_MESSAGES, SEARCH_CONSTS) {

        var service = {
            getValidationInfo: getValidationInfo,
            getErrorMessages: getErrorMessages,
            getDatePickerOption: getDatePickerOption,
            getSearchFacetOptions: getSearchFacetOptions
        };

        function getValidationInfo() {
            return {
                medicareIdPattern: PATIENT_INFO_CONSTS.VALIDATION.MEDICARE_ID_REGEX,
                ssnMaxLength: PATIENT_INFO_CONSTS.VALIDATION.SSN_MAX_LENGTH,
                ssnMinLength: PATIENT_INFO_CONSTS.VALIDATION.SSN_MIN_LENGTH,
                patientIdMinLength: PATIENT_INFO_CONSTS.VALIDATION.PATIENT_ID_MIN_LENGTH,
                patientIdMaxLength: PATIENT_INFO_CONSTS.VALIDATION.PATIENT_ID_MAX_LENGTH,
                patientFirstNameMinLength: PATIENT_INFO_CONSTS.VALIDATION.PATIENT_FIRST_NAME_MIN_LENGTH,
                patientFirstNameMaxLength: PATIENT_INFO_CONSTS.VALIDATION.PATIENT_FIRST_NAME_MAX_LENGTH,
                patientMiddleNameMinLength: PATIENT_INFO_CONSTS.VALIDATION.PATIENT_MIDDLE_NAME_MIN_LENGTH,
                patientMiddleNameMaxLength: PATIENT_INFO_CONSTS.VALIDATION.PATIENT_MIDDLE_NAME_MAX_LENGTH,
                patientLastNameMinLength: PATIENT_INFO_CONSTS.VALIDATION.PATIENT_LAST_NAME_MIN_LENGTH,
                patientLastNameMaxLength: PATIENT_INFO_CONSTS.VALIDATION.PATIENT_LAST_NAME_MAX_LENGTH,
                ssnLength: PATIENT_INFO_CONSTS.VALIDATION.SSN_LENGTH
            };
        }

        function getErrorMessages(validation) {
            return {
                ssn: {
                    minLength: PATIENT_INFO_CONSTS.ERROR_MESSAGES.SSN_MIN_LENGTH.replace('#maxLength', validation.ssnMaxLength),
                    maxLength: PATIENT_INFO_CONSTS.ERROR_MESSAGES.SSN_MIN_LENGTH.replace('#maxLength', validation.ssnMaxLength)
                },
                patientId: {
                    minLength: PATIENT_INFO_CONSTS.ERROR_MESSAGES.PATIENT_ID_MIN_LENGTH.replace('#minLength', validation.patientIdMinLength).replace('#maxLength', validation.patientIdMaxLength),
                    maxLength: PATIENT_INFO_CONSTS.ERROR_MESSAGES.PATIENT_ID_MAX_LENGTH.replace('#minLength', validation.patientIdMinLength).replace('#maxLength', validation.patientIdMaxLength),
                    duplicate: PATIENT_INFO_CONSTS.ERROR_MESSAGES.PATIENT_ID_DUPLICATE
                },
                patientName: {
                    invalidFirstNameLength: PATIENT_INFO_CONSTS.ERROR_MESSAGES.PATIENT_FIRST_NAME_MAX_LENGTH.replace('#maxLength', validation.patientFirstNameMaxLength),
                    firstNameRequired: PATIENT_INFO_CONSTS.ERROR_MESSAGES.PATIENT_FIRST_NAME_REQUIRED,
                    invalidMiddleNameLength: PATIENT_INFO_CONSTS.ERROR_MESSAGES.PATIENT_MIDDLE_NAME_MAX_LENGTH.replace('#maxLength', validation.patientMiddleNameMaxLength),
                    invalidLastNameLength: PATIENT_INFO_CONSTS.ERROR_MESSAGES.PATIENT_LAST_NAME_MAX_LENGTH.replace('#maxLength', validation.patientLastNameMaxLength),
                    lastNameRequired: PATIENT_INFO_CONSTS.ERROR_MESSAGES.PATIENT_LAST_NAME_REQUIRED
                },
                patientDob: {
                    required: PATIENT_INFO_CONSTS.ERROR_MESSAGES.PATIENT_DOB_REQUIRED,
                    future: ERROR_MESSAGES.DATE_IN_FUTURE,
                    mask: PATIENT_INFO_CONSTS.ERROR_MESSAGES.PATIENT_DOB_MASK,
                    past: PATIENT_INFO_CONSTS.ERROR_MESSAGES.PATIENT_DOB_IN_PAST,
                    invalid: PATIENT_INFO_CONSTS.ERROR_MESSAGES.PATIENT_DOB_INVALID_DATE
                },
                zipCode: {
                    invalidLength: PATIENT_INFO_CONSTS.ERROR_MESSAGES.PATIENT_ZIP_CODE_INVALID_LENGTH,
                    zipCodeRequired: PATIENT_INFO_CONSTS.ERROR_MESSAGES.PATIENT_ZIP_CODE_REQUIRED
                },
                gender: {
                    required: PATIENT_INFO_CONSTS.ERROR_MESSAGES.PATIENT_GENDER_REQUIRED
                },
                medicareId: {
                    invalid: PATIENT_INFO_CONSTS.ERROR_MESSAGES.MEDICARE_ID_INVALID
                },
                ssnAndMedicareId: {
                    minLength: PATIENT_INFO_CONSTS.ERROR_MESSAGES.SSN_LENGTH.replace('#minLength', validation.ssnLength),
                    invalid: PATIENT_INFO_CONSTS.ERROR_MESSAGES.MEDICARE_ID_INVALID,
                    required: PATIENT_INFO_CONSTS.ERROR_MESSAGES.PATIENT_SSN_OR_MEDICARE_ID_REQUIRED
                }
            };
        }

        function getDatePickerOption() {
            return {
                format: 'MMddyyyy',
                opened: false,
                options: {
                    formatYear: 'yy',
                    startingDay: 1,
                    initDate: new Date('1991'),
                    maxDate: new Date(),
                    showWeeks: false
                }
            };
        }

        function getSearchFacetOptions() {
            return [
                {
                    searchCategory: SEARCH_CONSTS.SEARCH_FACETS.PATIENT.SEARCH_CATEGORY.PATIENT_LAST_NAME,
                    placeholderText: SEARCH_CONSTS.SEARCH_FACETS.PATIENT.PLACE_HOLDER_TEXT.LAST_NAME,
                    value: SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.LAST_NAME
                },
                {
                    searchCategory: SEARCH_CONSTS.SEARCH_FACETS.PATIENT.SEARCH_CATEGORY.PRACTICE_PATIENT_ID,
                    placeholderText: SEARCH_CONSTS.SEARCH_FACETS.PATIENT.PLACE_HOLDER_TEXT.PRACTICE_PATIENT_ID,
                    value: SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.PRACTICE_PATIENT_ID_MODAL
                },
                {
                    searchCategory: SEARCH_CONSTS.SEARCH_FACETS.PATIENT.SEARCH_CATEGORY.DATE_OF_BIRTH,
                    placeholderText: SEARCH_CONSTS.SEARCH_FACETS.PATIENT.PLACE_HOLDER_TEXT.DATE_OF_BIRTH,
                    value: SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.DATE_OF_BIRTH
                }
            ];
        }

        return service;

    }

})();
