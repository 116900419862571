/**
 * Created by nikesh on 10/17/2017.
 */
(function() {
    'use strict';

    var VALUES = {
        INSTRUCTION_TEXT: 'Practice setup takes about 5 minutes. You should make sure you have this information before you begin:',
        SUCCESS_MESSAGE: 'Your User account has been successfully created and you\'re ready to set up your practice!',
        ADD_PRACTICE: 'name, address, telephone, fax, Tax ID and NPI (if applicable).',
        ADD_LOCATION: 'name, address, telephone, fax, Tax ID and NPI (if different from your Practice).  You must set up at least one location defining where services are delivered, but may add more after practice setup is complete.',
        ADD_PROVIDER: 'name, Tax ID and NPI (if different from your Practice). You must set up at least one provider, but may add more after practice setup is complete.',
        PRACTICE_SETUP_STEPS: {
            ADD_PRACTICE: 'Add Practice',
            ADD_LOCATION: 'Add a Location',
            ADD_PROVIDER: 'Add a Provider'
        },
        PRACTICE_ERROR_MESSAGE: 'A Practice with this Practice Name already exists',
        PRACTICE_GENERIC_ERROR_MESSAGE: 'There was an issue saving your practice.',
        LOCATION_ERROR_MESSAGE: 'A location with this name already exists',
        LOCATION_GENERIC_ERROR_MESSAGE: 'There was an issue saving your location.',
        PROVIDER_ERROR_MESSAGE: 'A provider with this name already exists',
        PROVIDER_GENERIC_ERROR_MESSAGE: 'There was an issue saving your provider.',
        NPI_TYPE_2_ERROR_MESSAGE: 'Only NPI-2 can be registered.',
        NPI_TYPE_1_ERROR_MESSAGE: 'Only NPI-1 can be registered.',
        UNABLE_TO_FIND_NPI_MESSAGE: 'We are unable to find provided NPI in NPPES registry.',
        BUTTON_TEXT: {
            SAVE_CONTINUE: 'Save & Continue'
        }
    };

    angular.module('eAccess.ProviderPortal').constant('PRACTICE_SETUP_CONSTS', VALUES);
})();
