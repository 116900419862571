/**
 * Created by aayush.regmi on 10/27/2017.
 */

(function() {
    'use strict';

    angular.module('eAccess.ProviderPortal.Directives').directive('eaScrollToBottom', eaScrollToBottom);

    eaScrollToBottom.$inject = ['$log'];

    function eaScrollToBottom($log) {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                var raw = element[0];
                element.bind('scroll', function() {
                    /**
                     * arbitrary number "5" has been added for browser compatibility
                     * this is only to be on the safe side
                     * code will work even without this arbitrary value
                     * */

                    if(raw.scrollHeight - raw.scrollTop <= raw.clientHeight + 5) {
                        scope.$apply(attrs.eaScrollToBottom);
                    }
                });

                element.bind('mouseover', function(){
                    if(raw.clientHeight >= raw.scrollHeight) {
                        scope.$apply(attrs.eaScrollToBottom);
                    }
                });
            }
        };
    }
})();
