/**
 * Created by aayush.regmi on 2/19/2018.
 */
/**
 *  This directive is used for the purpose of printing a section
 * */
(function() {
    'use strict';

    angular.module('eAccess.ProviderPortal.Directives').directive('eaPrint', eaPrint);

    eaPrint.$inject = ['$window'];

    function eaPrint($window) {
        return {
            restrict: 'A',
            scope: {
                printId: '<' //ID of the section that needs to be printed
            },
            link: function(scope, element) {
                element.bind("click", function() {
                    var win = angular.element($window);
                    var printSection = win[0].document.querySelector('#' + scope.printId);
                    if(printSection) {
                        var innerContents = printSection.innerHTML;
                        var popUpWindow = $window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
                        popUpWindow.document.open();
                        popUpWindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="./css/main.css" /><link rel="stylesheet" type="text/css" href="./bower_components/components-font-awesome/css/font-awesome.css" /></head><body onload="window.print()">' + innerContents + '</html>');
                        popUpWindow.document.close();
                    }
                });
            }
        };
    }
})();
