(function() {
    angular
        .module('eAccess.ProviderPortal.Components.State')
        .controller('StateListController', StateListController);

    StateListController.$inject = ['$log', 'State'];

    function StateListController($log, State) {
        var vm = this;
        vm.stateList = State.getStateList();
    }
})();
