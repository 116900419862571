/**
 * Created by pramesh on 10/20/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.InitialUserInformation')
        .factory('InitialUserService', InitialUserService);

    InitialUserService.$inject = ['$log', 'API', 'USER_CONSTS', 'Utility'];

    function InitialUserService($log, API, USER_CONSTS, Utility) {

        var service = {
            addInitialUser: addInitialUser,
            getValidationInfo: getValidationInfo,
            getErrorMessage: getErrorMessage,
            addUser: addUser,
            updateUser: updateUser
        };

        /**
         * @ngdoc function
         * @name addInitialUser
         * @description
         *
         * Adds a new admin user if it does not exist.
         *
         * @param user
         */

        function addInitialUser(user) {
            return API.post('users/create/initialuser', user);
        }

        /**
         * @ngdoc function
         * @name getValidationInfo
         * @description
         *
         * Provide Validation Information for creating user.
         *
         * @param user
         */

        function getValidationInfo() {
            return {
                firstName: {
                    minLength: USER_CONSTS.ERROR_MESSAGES.FIRST_NAME_MIN_LENGTH,
                    maxLength: USER_CONSTS.ERROR_MESSAGES.FIRST_NAME_MAX_LENGTH
                },
                middleName: {
                    minLength: USER_CONSTS.ERROR_MESSAGES.MIDDLE_NAME_MIN_LENGTH,
                    maxLength: USER_CONSTS.ERROR_MESSAGES.MIDDLE_NAME_MAX_LENGTH
                },
                lastName: {
                    minLength: USER_CONSTS.ERROR_MESSAGES.LAST_NAME_MIN_LENGTH,
                    maxLength: USER_CONSTS.ERROR_MESSAGES.LAST_NAME_MAX_LENGTH
                },
                username: {
                    minLength: USER_CONSTS.ERROR_MESSAGES.USER_NAME_MIN_LENGTH,
                    maxLength: USER_CONSTS.ERROR_MESSAGES.USER_NAME_MAX_LENGTH
                },
                phoneNumber: {
                    minLength: USER_CONSTS.ERROR_MESSAGES.PHONE_NUMBER_MIN_LENGTH,
                    maxLength: USER_CONSTS.ERROR_MESSAGES.PHONE_NUMBER_MAX_LENGTH
                },
                faxNumber: {
                    minLength: USER_CONSTS.ERROR_MESSAGES.FAX_NUMBER_MIN_LENGTH,
                    maxLength: USER_CONSTS.ERROR_MESSAGES.FAX_NUMBER_MIN_LENGTH
                }
            };
        }

        /**
         * @ngdoc function
         * @name getErrorMessage
         * @description
         *
         * Provide Error message creating user.
         *
         * @param user
         */

        function getErrorMessage(validation) {
            return {
                firstName: {
                    minLength: 'First Name must be greater than ' + validation.firstName.minLength + ' characters',
                    maxLength: 'First Name can be no more than ' + validation.firstName.maxLength + ' characters',
                    required: 'First Name is required'
                },
                middleName: {
                    minLength: 'Middle Name must be greater than ' + validation.middleName.minLength + ' characters',
                    maxLength: 'Middle Name can be no more than ' + validation.middleName.maxLength + ' characters',
                },
                lastName: {
                    minLength: 'Last Name must be greater than ' + validation.lastName.minLength + ' characters',
                    maxLength: 'Last Name can be no more than ' + validation.lastName.maxLength + ' characters',
                    required: 'Last Name is required'
                },
                userName: {
                    minLength: 'Username must be between ' + validation.username.minLength + ' - ' + validation.username.maxLength + ' characters',
                    maxLength: 'Username must be between ' + validation.username.minLength + ' - ' + validation.username.maxLength + ' characters',
                    required: 'Username is required'
                },
                email: {
                    required: 'Email is required',
                    pattern: 'Email is invalid'
                },
                phoneNumber: {
                    minLength: 'Phone Number must be greater than ' + validation.phoneNumber.minLength + ' characters',
                    maxLength: 'Phone Number can be no more than ' + validation.phoneNumber.maxLength + ' characters',
                    required: 'Phone Number is required',
                    mask: 'Invalid Phone Number'
                },
                phoneNumberType: {
                    required: 'Phone Number Type is required'
                },
                faxNumber: {
                    minLength: 'Fax Number must be greater than ' + validation.faxNumber.minLength + ' characters',
                    maxLength: 'Fax Number can be no more than ' + validation.faxNumber.maxLength + ' characters',
                    mask: 'Invalid Fax Number'
                },
                userRole: {
                    required: 'User Role is required'
                },
                contactPreference: {
                    required: 'Contact Preference is required'
                }
            };
        }

        /**
         * Function to create user from inside an application.
         * @param user
         */
        function addUser(user) {
            return API.post('users/create', user);
        }

        /**
         * Function to update user.
         */
        function updateUser(user) {
            return API.put('users/update', getRequestJSON(user));
        }

        /**
         * Remove unnecessary attributes (i.e: attributes with null values) for sending update request
         * @param user
         * @returns
         */
        function getRequestJSON(user) {
            // Only map necessary attributes
            var request = {
                email: user.email || null,
                faxNo: user.faxNo || null,
                firstName: user.firstName || null,
                lastName: user.lastName || null,
                middleName: user.middleName || null,
                phoneNo: user.phoneNo || null,
                phoneNoType: user.phoneNoType || null,
                status: user.status || null,
                userId: user.userId || null,
                userName: user.userName || null,
                userRole: user.userRole ||  null,
                contactPreference: user.contactPreference || null,
            };

            // Holds final result (i.e after removing attributes with null values.)
            var result = {};

            // Remove attributes with null values
            Object.keys(request).forEach(function(key) {
                var value = request[key];
                if(!Utility.isEmpty(value)) {
                    result[key] = value;
                }
            });
            return result;
        }

        return service;
    }
})();
