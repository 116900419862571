(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('practice.users', {
                parent: 'practice',
                url: '/users',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Practice Users'
                },
                views: {
                    'practiceContent': {
                        templateUrl: 'components/practice/users/practice-users.html',
                        controller: 'PracticeUserController as user'
                    }
                },
                params: {
                    userCreated: null,
                    invalidUrl: false
                }
            });
    }

})();
