/**
 * Created by nikesh on 10/12/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Modals')
        .controller('RequestRegistrationCodeModalController', RequestRegistrationCodeModalController);

    RequestRegistrationCodeModalController.$inject = ['$log', '$uibModalInstance', '$state', 'REGISTER_CONSTS', 'assistanceInfo'];

    function RequestRegistrationCodeModalController($log, $uibModalInstance, $state, REGISTER_CONSTS, assistanceInfo) {

        var vm = this;

        vm.closeModal = closeModal;

        vm.gotoHome = gotoHome;

        vm.modalProperties = {
            supportMessage: assistanceInfo
        };

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        }

        function gotoHome() {
            $state.go('login');
            $uibModalInstance.dismiss('cancel');
        }
    }

})();
