(function() {
    'use strict';

    var WeeklyCompletedBvsWidget = {
        bindings: {
            className: '<'
        },
        templateUrl: 'components/dashboard/widgets/weekly-completed-bvs/weekly-completed-bvs.html',
        controller: 'WeeklyCompletedBvsController as weeklyCompletedBvs'
    };

    angular
        .module('eAccess.ProviderPortal.Widgets.WeeklyCompletedBvs')
        .component('eaWeeklyCompletedBvsWidget', WeeklyCompletedBvsWidget);
})();
