(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Filters')
        .filter('state', stateFilter);

    stateFilter.$inject = ['$log', 'State', 'Utility'];

    function stateFilter($log, State, Utility) {
        return function(state) {
            if(!state) {
                return '';
            }

            var value, stateList = State.getStateList();

            value = stateList.filter(function(data) {
                return data.key === state;
            });

            if(Utility.isEmpty(value) || value.length === 0) {
                return state;
            }
            return value[0].value;
        };
    }
})();
