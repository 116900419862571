/**
 * Created by mcaputo on 2/23/17.
 */

(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Components.ServiceDeliveryInformation', [
            'eAccess.ProviderPortal.Components.AdminCodeList'
        ]);
})();
