/**
 * Created by nikesh on 10/17/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PracticeSetup', []);

})();
