(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .factory('ProviderReactivateDeactivate', ProviderReactivateDeactivate);

    ProviderReactivateDeactivate.$inject = ['$log', 'ReactivateDeactivate', 'PROVIDER_CONSTS', 'Utility'];

    function ProviderReactivateDeactivate($log, ReactivateDeactivate, PROVIDER_CONSTS, Utility) {
        
        var ProviderReactivateDeactivateData = {
            reactivate : {
                multipleMessage: PROVIDER_CONSTS.CONFIRMATION_MESSAGE.REACTIVATE_MULTIPLE_PROVIDER,
                singleMessage:  PROVIDER_CONSTS.CONFIRMATION_MESSAGE.REACTIVATE_ONE_PROVIDER,
                replaceString: "#providers"
            },
            deactivate: {
                multipleMessage: PROVIDER_CONSTS.CONFIRMATION_MESSAGE.DEACTIVATE_MULTIPLE_PROVIDER,
                singleMessage: PROVIDER_CONSTS.CONFIRMATION_MESSAGE.DEACTIVATE_ONE_PROVIDER,
                replaceString: "#providers"
            },
            cannotDeactivate: {
                modalTitle: PROVIDER_CONSTS.MODAL.DEACTIVATE.TITLE,
                modalBody: PROVIDER_CONSTS.MODAL.DEACTIVATE.BODY
            }
        };

        Utility.extend(ProviderReactivateDeactivateInstance, ReactivateDeactivate);

        function ProviderReactivateDeactivateInstance() {
            ReactivateDeactivate.call(this, ProviderReactivateDeactivateData);
        }

        function _setSubTitleText() {
            this.modalSubTitle = (this.list.length === 1) ? Utility.getFullName(this.list[0]) : '';
        }

        ProviderReactivateDeactivateInstance.prototype.setModalSubTitle = function (locationList) {
            _setSubTitleText.call(this, locationList);
        };

        return ProviderReactivateDeactivateInstance;
    }
})();
