(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilitySplitResponse')
        .controller('PolicyHeaderDetailsController', PolicyHeaderDetailsController);

    PolicyHeaderDetailsController.$inject = ['Utility', 'StatusStyles', 'ERROR_MESSAGES', 'GLOBAL_CONSTS'];

    function PolicyHeaderDetailsController(Utility, StatusStyles, ERROR_MESSAGES, GLOBAL_CONSTS) {
        var vm = this;

        vm.setActiveStatusClass = setActiveStatusStyles;
        vm.concatAddress = concatAddress;
        //vm.productNotCoveredMessage = ERROR_MESSAGES.PRODUCT_NOT_COVERED_MESSAGE;
        vm.isValueUnknown = isValueUnknown;

        vm.$onInit = function () {
            vm.displayObj = {};
            if(!Utility.isEmpty(vm.policy)) {
                vm.displayObj.drugName = vm.policy.drugName;
                vm.displayObj.productDaysSupply = vm.policy.productDaysSupply;
                vm.displayObj.quantity = vm.policy.quantity;
                vm.displayObj.pharmacyUsedList = vm.policy.pharmacyUsedList;
                if(!Utility.isEmpty(vm.policy.plan)) {
                    vm.displayObj.planName = vm.policy.plan.planName;
                    vm.displayObj.binLocationNumber = vm.policy.plan.binLocationNumber;
                    vm.displayObj.pcnNumber = vm.policy.plan.pcnNumber;
                    if(!Utility.isEmpty(vm.policy.plan.status)) {
                        vm.displayObj.priorAuthorizationStatus = vm.policy.plan.status.priorAuthorizationStatus;
                    } else {
                        vm.displayObj.priorAuthorizationStatus = null;
                    }
                    if(!Utility.isEmpty(vm.policy.plan.planCoverageLimitations)) {
                        vm.displayObj.planFormularyStatus = vm.policy.plan.planCoverageLimitations.planFormularyStatus;
                    } else {
                        vm.displayObj.planFormularyStatus = null;
                    }
                } else {
                    vm.displayObj.planName = null;
                    vm.displayObj.binLocationNumber = null;
                    vm.displayObj.pcnNumber = null;
                }
            } else {
                vm.displayObj.drugName = null;
                vm.displayObj.productDaysSupply = null;
                vm.displayObj.quantity = null;
                vm.displayObj.pharmacyUsedList = null;
            }

            if(!Utility.isEmpty(vm.response) && !Utility.isEmpty(vm.response.request) && !Utility.isEmpty(vm.response.request.service)  && !Utility.isEmpty(vm.response.request.service.product)) {
                vm.displayObj.ndc = vm.response.request.service.product.ndc;
            } else {
                vm.displayObj.ndc = null;
            }
        };

        function setActiveStatusStyles(status) {
            return StatusStyles.setActiveStatusClass({status:status});
        }

        function concatAddress(addressObj) {
            if(Utility.isEmpty(addressObj)) {
                return null;
            }

            var finalAddress = '';

            if(!Utility.isEmpty(addressObj.address1) && addressObj.address1 !== '' && isValueUnknown(addressObj.address1)) {
                finalAddress += addressObj.address1 + ' ';
            }

            if(!Utility.isEmpty(addressObj.address2) && addressObj.address2 !== '' && isValueUnknown(addressObj.address2)) {
                finalAddress += addressObj.address2 + ' ';
            }

            return finalAddress.trim();
        }

        function isValueUnknown(value) {
            return Utility.lowerCase(value) !== Utility.lowerCase(GLOBAL_CONSTS.UNKNOWN)
                && value !== null;
        }
    }
})();
