(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.VerifyBv')
        .component('eaVerifyBv', {
            templateUrl: 'components/verify-bv/verify-bv.html',
            controller: 'VerifyBvController as verifyBvCtrl',
            bindings: {
                bvMethod: '<',
                request: '<',
                showErrorToast: '<'
            }
        });

})();
