/**
 * Created by nikesh on 10/30/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.AddPractice')
        .controller('AddPracticeController', AddPracticeController);

    AddPracticeController.$inject = ['PracticeService', 'HELP_TEXT_CONSTS', 'WarnOnLeave', 'ConfigurationService', 'Utility'];

    function AddPracticeController(PracticeService, HELP_TEXT_CONSTS, WarnOnLeave, ConfigurationService, Utility) {
        var vm = this;

        // Data
        vm.validation = PracticeService.getValidationInfo();
        vm.siteRegistrationEnabled = Utility.isTruthy(ConfigurationService.getOrganizationProperty('siteRegistration') || 0);
        // error messages for fields
        vm.errorMessages = PracticeService.getErrorMessages(vm.validation);
        vm.tooltipInfo = HELP_TEXT_CONSTS.PRACTICE;

        // functions
        vm.savePractice = savePractice;
        vm.showWarnOnLeaveOnCancel = showWarnOnLeaveOnCancel;
        vm.resetError = resetError;

        function savePractice() {
            vm.parentForm.name.$setValidity('unique', true);
            vm.parentForm.npiNumber.$setValidity('npiType2', true);

            if(vm.parentForm.$invalid) {
                return false;
            }

            vm.nextCallback(PracticeService.createPractice(vm.practice));
        }

        function showWarnOnLeaveOnCancel() {
            WarnOnLeave.show({form: vm.parentForm, toState: {name: 'login'}});
        }

        function resetError(inputName ,value) {
            vm.parentForm[inputName].$setValidity(value, true);
        }
    }

})();
