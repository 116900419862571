/**
 * @ngdoc Component
 * @module eAccess.ProviderPortal.Components.RxEligibilityResponse
 * @name eaServiceInformation
 * @kind Component
 *
 * @description
 * Service Information Section Block. It takes in service information and renders
 * to the view
 *
 * Basic Usage
 * <ea-service-information-response></ea-patient-information-response>
 *
 * @author girianish
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .component('eaServiceInformationResponse', {
            bindings: {
                serviceInformation: '<',
                locationInformation: '<',
                requestDate: '<'
            },
            templateUrl: 'components/eligibility/bv-selection/rx/rx-response/request-details/service-information-response/service-information-response.html',
            controller: 'ServiceInformationResponseController as serviceInfoCtrl'
        });
})();
