/**
 * Created by pramesh on 10/31/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Modals')
        .controller('WarnOnBackModalController', WarnOnBackModalController);

    WarnOnBackModalController.$inject = ['$log', '$uibModalInstance'];

    function WarnOnBackModalController($log, $uibModalInstance) {

        var vm = this;

        vm.closeModal = closeModal;
        vm.leave = leave;

        function closeModal() {
            $uibModalInstance.dismiss();
        }

        function leave() {
            $uibModalInstance.close();
        }

    }

})();
