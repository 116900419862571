/**
 * @ngdoc directive
 * @name eAccess.ProviderPortal.directive:eaSelectAll
 * @description
 *
 * This directive is added as an attribute to master select box
 *
 * Note : Based on https://gist.github.com/ghengeveld/8669299
 *
 * Note that the 'rel' attribute on slave checkboxes should match the 'ea-select-all' attribute
 *
 * @author girianish
 **/
(function() {
    'use strict';

    angular.module('eAccess.ProviderPortal.Directives')
            .directive('eaSelectAll', eaSelectAll)
            .directive('eaSelectAll', afterNgRepeat);

    eaSelectAll.$inject = ['$parse','$document'];

    function eaSelectAll($parse, $document) {
        return {
            restrict: 'A',
            require: '?ngModel',
            multiElement: true,
            priority: 1001,
            link: function(scope, masterElement, attrs, ngModel) {
                var slaveName = attrs.eaSelectAll;
                var slaveSelector = ':checkbox[rel="' + slaveName + '"]';

                /**
                 * @ngdoc function
                 * @name setChildState
                 * @methodOf eaSelectAll
                 * @description
                 * 1) Callback function for click event in master element
                 * 2) Extracts the master checkboxes checked state 
                 * 3) Loops the slave checkbox and assigns the master state to all the slave checkbox
                 * 4) Prepares the new array of checked check box
                 * 5) Prepares the new updated checked models
                 */

                var setChildState = function() {
                    // get the state of master
                    var masterState = masterElement[0].checked;
                    // update child state according to master
                    angular.element(slaveSelector).map(function(i, elem) {
                        var localScope = angular.element(elem).scope();
                        var model = $parse(angular.element(elem).attr('ng-model'));

                        model.assign(localScope, masterState);
                    });
                    scope.$apply();  
                    // prepare new checked list
                    var checkedListmodel = $parse(angular.element(masterElement[0]).attr('checked-list'));
                    var newCheckedList = angular.element(slaveSelector).filter(function(i, elem) {
                        return elem.checked;
                    });
                    // prepare new updated checked models
                    var updatedModels = newCheckedList.map(function(i, elem) {
                        return angular.element(elem).val();
                    });

                    // patch changes to the dom
                    if(angular.isFunction(checkedListmodel) && checkedListmodel !== angular.noop) {
                        checkedListmodel.assign(scope, updatedModels);
                        scope.$apply(); 
                    } 
                };

                /**
                 * @ngdoc function
                 * @name setChildState
                 * @methodOf eaSelectAll
                 * @description
                 * 1) Callback function for click event in slave elements
                 * 2) Extracts the salve checkboxes checked state 
                 * 3) Checks if all the checkbox are checked or not and sets the master state
                 * 4) Prepares the new array of checked checkboxes
                 * 5) Prepares the new updated list of checked models
                 */

                var setMasterState = function(event) {
                    // execute only if the clicked element is the salve checkbox
                    if( ('rel' in event.target.attributes) && event.target.attributes.rel.nodeValue === slaveName) {
                        var slaveElements = angular.element(slaveSelector);
                        
                        // get the checked elements
                        var checkedSlaves = slaveElements.filter(function(i, elem) {
                            return angular.element(elem).prop('checked');
                        });
                        
                        // check if all the slave checkbox are cheked
                        var isChecked = (checkedSlaves.length === slaveElements.length);
                        // assign the master checkbox state likewise
                        masterElement[0].checked = isChecked;

                        //updating model value
                        if(ngModel) {
                            ngModel.$setViewValue(isChecked);
                            ngModel.$render();
                        }

                        // pepare the new checked list
                        var checkedListmodel = $parse(angular.element(masterElement[0]).attr('checked-list'));
                        var newCheckedList = angular.element(slaveSelector).filter(function(i, elem) {
                            return elem.checked;
                        });

                        // prepare the new updated models
                        var updatedModels = newCheckedList.map(function(i, elem) {
                            return angular.element(elem).val();
                        });
                        
                        // patch the changes to the dom
                        if(angular.isFunction(checkedListmodel) && checkedListmodel !== angular.noop) {
                            checkedListmodel.assign(scope, updatedModels);
                            scope.$apply();
                        }
                    }
                };

               // let attach the events for master and slave checkboxes
                masterElement.on('click', setChildState);
                $document.on('click', setMasterState);

                scope.$on('$destroy', function() {
                    // lets clean it up
                    $document.off('click', setMasterState);
                    masterElement.off('click', setChildState);
                });

            }
        };
    }

    afterNgRepeat.$inject = ['$log', '$parse', '$timeout'];

    /**
     * @ngdoc directive
     * @name eAccess.ProviderPortal.directive:eaSelectAll
     * @description
     * This directive is called after finish of ngrepeat
     * It should be used when ever this directive is used with infinite scroll
     * It basically checks the master state after each ng-repeat and update models like wise for slaves.
     * @author girianish
     **/   

    function afterNgRepeat($log, $parse, $timeout) {
        return {
            restrict: 'A',
            priority: 999,
            scope: {
                masterStt : '=',
                checkedList: '='
            },
            link: function(scope, masterElement, attrs) {
                $timeout(function() {
                    if(scope.$parent.$last) {
                        var slaveName = attrs.eaSelectAll;
                        var slaveSelector = ':checkbox[rel="' + slaveName + '"]';
                        var masterState = scope.masterStt;
                    
                                            // loop out the child checkboxes and update the state according to master
                        angular.element(slaveSelector).each(function(i, elem) {
                            if(!angular.element(elem).prop('checked')) {
                                var localScope = angular.element(elem).scope();
                                var model = $parse(angular.element(elem).attr('ng-model'));
                            
                                model.assign(localScope, masterState);
                                localScope.$apply();
                            }
                                                
                        });
                    
                        // prepare new checked lists
                        var newCheckedList = angular.element(slaveSelector).filter(function(i, elem) {
                            return elem.checked;
                        });
                    
                        // prepare new updated checked models
                        var updatedModels = newCheckedList.map(function(i, elem) {
                            return angular.element(elem).val();
                        });
                    
                        // patch the changes to the dom
                        scope.checkedList = updatedModels;
                    }   
                                        
                                    
                });
            }
        };

    }
})();

