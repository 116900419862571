/**
 * Created by mcaputo on 1/26/17.
 */


angular
    .module('eAccess.ProviderPortal.Components', [
        'eAccess.ProviderPortal.Components.DiagnosisCode',
        'eAccess.ProviderPortal.Components.Footer',
        'eAccess.ProviderPortal.Components.InsurancePolicy',
        'eAccess.ProviderPortal.Components.Eligibility',
        'eAccess.ProviderPortal.Components.MedEligibility',
        'eAccess.ProviderPortal.Components.PolicyCoverageList',
        'eAccess.ProviderPortal.Components.Login',
        'eAccess.ProviderPortal.Components.PolicyCoverageList',
        'eAccess.ProviderPortal.Components.ISIContent',
        'eAccess.ProviderPortal.Components.UserAuthEmailLink',
        'eAccess.ProviderPortal.Components.SetPassword',
        'eAccess.ProviderPortal.Components.ChangePassword',
        'eAccess.ProviderPortal.Components.LinkExpired',
        'eAccess.ProviderPortal.Components.AddPractice',
        'eAccess.ProviderPortal.Components.AddLocation',
        'eAccess.ProviderPortal.Components.AddProvider',
        'eAccess.ProviderPortal.Components.PasswordComplexity',
        'eAccess.ProviderPortal.Components.ConsentMessage',
        'eAccess.ProviderPortal.Components.MBVView',
        'eAccess.ProviderPortal.Components.LocationForm',
        'eAccess.ProviderPortal.Components.ViewUser',
        'eAccess.ProviderPortal.Components.State',
        'eAccess.ProviderPortal.Components.Header',
        'eAccess.ProviderPortal.Components.BvSelection',
        'eAccess.ProviderPortal.Components.RxEligibility',
        'eAccess.ProviderPortal.Components.BvHeader',
        'eAccess.ProviderPortal.Components.PatientInfo',
        'eAccess.ProviderPortal.Components.RxServiceDeliveryForm',
        'eAccess.ProviderPortal.Components.RxEligibilityResponse',
        'eAccess.ProviderPortal.Components.RxEligibilitySplitResponse',
        'eAccess.ProviderPortal.Components.PatientDataConflict',
        'eAccess.ProviderPortal.Components.MBVMessage',
        'eAccess.ProviderPortal.Components.VerifyBv',
        'eAccess.ProviderPortal.Components.SOBHeader',
        'eAccess.ProviderPortal.Components.SOB',
        'eAccess.ProviderPortal.Components.Pharmacy'
    ]);

