/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.controller:ReverificationsController
 * @author Anna Lee
 * @requires $log
 * @description
 *
 * This is the controller for the Reverifications page.
 **/

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Reverifications')
        .controller('ReverificationsController', ReverificationsController);

    ReverificationsController.$inject = ['$log', 'ReverificationsView', 'SEARCH_CONSTS', 'PaginationService', 'Utility', 'ELIGIBILITY_CONSTS', 'BVHistoryView', 'BVSelectionService', 'ConfigurationService', 'Search', 'DATA_LIMIT_CONSTS', 'Eligibility', 'ReverificationService', 'BV_SELECTION_CONSTS', 'StatusStyles', 'SearchModel', 'SearchPaginationModel'];

    function ReverificationsController($log, ReverificationsView, SEARCH_CONSTS, PaginationService, Utility, ELIGIBILITY_CONSTS, BVHistoryView, BVSelectionService, ConfigurationService, Search, DATA_LIMIT_CONSTS, Eligibility, ReverificationService, BV_SELECTION_CONSTS, StatusStyles, SearchModel, SearchPaginationModel) {

        var vm = this;

        // data
        vm.viewService = ReverificationsView;
        vm.toggleParameterSearchBy = toggleParameterSearchBy;
        vm.getSearchText = getSearchText;
        vm.loadReverificationList = loadReverificationList;
        vm.viewBVResponse = Eligibility.viewBVResponse;
        vm.reverifyCoverage = Eligibility.reverifyCoverage;
        vm.dayRangeSearchCallback = dayRangeSearchCallback;
        vm.parameterSearchCallback = parameterSearchCallback;
        vm.clearAllCallback = clearSearchCallback;
        vm.changeState = Utility.shouldChangeState;
        vm.nextLink = null;

        vm.getRecentBvInDays = Utility.getRecentBvInDays;
        vm.getIconTooltipInfo = StatusStyles.getIconTooltipInfo;
        vm.getIconClass = StatusStyles.getAdjudicationIconClass;
        vm.sortReverificationData = sortReverificationData;
        vm.changeState = Utility.shouldChangeState;
        vm.patientManagementStatus = ConfigurationService.getPatientManagementStatus;


        vm.offset = DATA_LIMIT_CONSTS.ZERO_LIMIT;
        vm.searchType = SEARCH_CONSTS.SEARCH_TYPE.REVERIFICATION;
        vm.dataSortOrders = _defaultSortOrder();
        vm.searchFacets = BVHistoryView.getDefaultSearchFacets();
        /**
         * By default the reverification list is sorted by daysSinceLastBV (Oldest to newest recent bv)
         * */
        vm.sortBy = '-' + ELIGIBILITY_CONSTS.BV_SORT.RECENT_BV;

        vm.reverificationList = [];
        vm.loadingReverification = false;


        vm.$onInit = function () {
            ReverificationsView.configure({
                title: 'Benefit Verifications | Reverifications',
                tabIndex: 1
            });
            _fetchDefaultTablePageSize();
            BVSelectionService.getBvMethod().then(function(bvMethod) {
                vm.searchFacets.push.apply(vm.searchFacets, BVHistoryView.getSearchFacets(bvMethod));
            }).catch(function(err) {
                $log.debug(err);
            });
        };

        function _fetchDefaultTablePageSize() {
            vm.loadingReverification = true;
            ConfigurationService.fetchDefaultTablePageSize()
                .then(
                    function(limit) {
                        if(!Utility.isEmpty(limit)) {
                            vm.limit = limit;
                        } else {
                            vm.limit = DATA_LIMIT_CONSTS.DEFAULT_LIMIT; // This is just in case 'defaultTablePageSize' is not returned from the API
                        }
                    }
                )
                .catch(
                    function(err) {
                        vm.limit = DATA_LIMIT_CONSTS.DEFAULT_LIMIT; // This is just in case 'defaultTablePageSize' is not returned from the API
                        $log.error('Unable to fetch portal data', err);
                    }
                )
                .finally(function() {
                    vm.loadingReverification = false;
                    //even if we do not get the default table page size, we call the loadReverificationList
                    vm.loadReverificationList();
                });
        }

        function toggleParameterSearchBy() {
            vm.showParameterSearch = !vm.showParameterSearch;
            vm.currentSearchQuery = null;
            vm.selectedFacet = null;
        }

        function getSearchText() {
            return vm.showParameterSearch ? SEARCH_CONSTS.SEARCH_BY_DAYS_TEXT : SEARCH_CONSTS.SEARCH_BY_PARAMETER_TEXT;
        }

        function loadReverificationList() {
            $log.info("loading Reverification lists");
            if(!_shouldCallAPI()) {
                return;
            }

            if(!Utility.isEmpty(vm.currentSearchQuery) && vm.currentSearchQuery !== '') {
                _callForSearchParam();
                return;
            }

            vm.loadingReverification = true;
            var showCount = true;

            ReverificationService.loadReverifications(showCount, vm.offset, vm.limit, vm.sortBy, vm.nextLink).then(function(reverificationList) {
                var paginationResponse = PaginationService.getPaginationResponse(reverificationList, vm.reverificationList, 'responseId', vm.offset, vm.limit);
                vm.reverificationCount = paginationResponse.totalCount;
                vm.nextLink = paginationResponse.nextLink;
                vm.reverificationList = paginationResponse.list;
                if(vm.reverificationList.length === 0) vm.searchWarningMessage = SEARCH_CONSTS.NO_DATA_FOUND_REVERIFICATION;
            }).catch(function(err) {
                $log.error("Error getting BV list", err);
                if(!vm.reverificationList.length) {
                    vm.noDataFound = true;
                    vm.searchWarningMessage = SEARCH_CONSTS.NO_DATA_FOUND_REVERIFICATION;
                }
            }).finally(function() {
                vm.loadingReverification = false;
            });
        }

        function _shouldCallAPI() {
            return !vm.loadingReverification && vm.reverificationCount !== vm.reverificationList.length && !vm.noDataFound;
        }

        function _callForSearchParam() {
            if(vm.loadingReverification) {
                return;
            }
            vm.loadingReverification = true;

            var searchBy =  (vm.showParameterSearch ? SEARCH_CONSTS.SEARCH_CATEGORY.PARAMETER : SEARCH_CONSTS.SEARCH_CATEGORY.DAY);
            var pagination = new SearchPaginationModel(true, vm.offset, vm.limit, vm.sortBy, vm.nextLink);
            var searchModel = new SearchModel(pagination, vm.currentSearchQuery, vm.selectedFacet, null, null, null, searchBy);

            _searchAndSetBvData(Search.reverificationSearch(searchModel));
        }

        /**
         * function to set searched reverification list
         * @param reverificationSearch reverification searched promise object
         * @private
         */
        function _searchAndSetBvData(reverificationSearch) {
            reverificationSearch.then(function(reverificationList) {
                var paginationResponse = PaginationService.getPaginationResponse(reverificationList, vm.reverificationList, 'responseId', vm.offset, vm.limit);
                vm.reverificationCount = paginationResponse.totalCount;
                vm.nextLink = paginationResponse.nextLink;
                vm.reverificationList = paginationResponse.list;
            }).catch(function(err) {
                $log.error("Error getting reverification list", err);
                if(!vm.reverificationList.length) {
                    vm.noDataFound = true;
                }
            }).finally(function() {
                vm.loadingReverification = false;
            });
        }

        /**
         * function to check if search callback promise object is null,
         * if null then set warning message for empty search query
         * @param searchCallBackModel
         * @param setSearchResult
         * @returns {boolean}
         * @private
         */
        function _isSearchCallBackEmpty(searchCallBackModel, setSearchResult) {
            if(!searchCallBackModel.search) {
                vm.selectedFacet = null;
                vm.currentSearchQuery = null;
                vm.noDataFound = true;
                setSearchResult.call();
                return true;
            }
            return false;
        }

        function dayRangeSearchCallback(searchCallBackModel) {
            vm.noDataFound = false;
            vm.reverificationList = [];
            vm.searchWarningMessage = null;

            var bound = _setResultTextForSearch.bind(null, {searchType: SEARCH_CONSTS.SEARCH_CATEGORY.EMPTY});
            if(_isSearchCallBackEmpty(searchCallBackModel, bound)) {
                return;
            }

            vm.loadingReverification = true;
            var searchResult = {
                searchType: SEARCH_CONSTS.SEARCH_CATEGORY.DAY,
                param1: searchCallBackModel.formattedStartDate,
                param2: searchCallBackModel.formattedEndDate
            };

            var currentSearchQuery = {
                minDays: searchCallBackModel.formattedStartDate,
                maxDays: searchCallBackModel.formattedEndDate
            };

            _handleSearchCallback(searchCallBackModel, currentSearchQuery, _setResultTextForSearch.bind(null, searchResult));
        }

        function parameterSearchCallback(searchCallBackModel) {
            vm.noDataFound = false;
            vm.reverificationList = [];
            vm.searchWarningMessage = null;

            var bound = _setResultTextForSearch.bind(null, {searchType: SEARCH_CONSTS.SEARCH_CATEGORY.EMPTY});
            if(_isSearchCallBackEmpty(searchCallBackModel, bound)) {
                return;
            }

            vm.loadingReverification = true;
            var searchResult = {
                searchType: SEARCH_CONSTS.SEARCH_CATEGORY.PARAMETER,
                param1: searchCallBackModel.query,
                param2: searchCallBackModel.selectedFacet.searchCategory
            };
            _handleSearchCallback(searchCallBackModel, searchCallBackModel.query, _setResultTextForSearch.bind(null, searchResult));
        }

        function _handleSearchCallback(searchCallBackModel, currentSearchQuery, setSearchResult) {
            vm.selectedFacet = searchCallBackModel.selectedFacet;
            vm.sortBy = searchCallBackModel.pagination.sortOrder;
            searchCallBackModel.search.then(function(reverificationFound) {
                var paginationResponse = PaginationService.getPaginationResponse(reverificationFound, vm.reverificationList, 'responseId', vm.offset, vm.limit);
                vm.reverificationCount = paginationResponse.totalCount;
                vm.reverificationList = paginationResponse.list;
                vm.nextLink = paginationResponse.nextLink;
                vm.currentSearchQuery = currentSearchQuery;
                setSearchResult.call();
            }).catch(function(err) {
                $log.error('something went wrong searching for reverification ', err);
                vm.noDataFound = true;
            }).finally(function() {
                vm.loadingReverification = false;
            });
        }

        function _setResultTextForSearch(searchResult) {
            var messageMap = Eligibility.getSearchResultAndWarningMessage.call(searchResult, vm.reverificationCount);
            vm.searchResultText = messageMap.searchResultText;
            vm.searchWarningMessage = messageMap.searchWarningMessage;
        }

        function clearSearchCallback() {
            vm.searchResultText = null;
            vm.searchQuery = null;
            vm.minDays = null;
            vm.maxDays = null;
            vm.reverificationCount = DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE;
            vm.reverificationList = [];
            vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
            vm.nextLink = null;
            vm.noDataFound = false;
            vm.selectedFacet = null;
            vm.currentSearchQuery = '';
            vm.searchWarningMessage = null;
            vm.sortBy = '-' + ELIGIBILITY_CONSTS.BV_SORT.RECENT_BV;
            vm.dataSortOrders = _defaultSortOrder();
            vm.loadReverificationList();
        }

        function _defaultSortOrder() {
            return {
                patientName: {isAscending: false},
                recentBV: {isAscending: false},
                dateCompleted: {isAscending: true}
            };
        }

        function sortReverificationData(sortBy) {
            vm.reverificationList = [];
            vm.nextLink = null;
            vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
            switch(sortBy) {
                case ELIGIBILITY_CONSTS.BV_SORT.PATIENT_NAME:
                    vm.dataSortOrders.patientName.isAscending = !vm.dataSortOrders.patientName.isAscending;
                    //negative sign indicates descending order
                    vm.sortBy = vm.dataSortOrders.patientName.isAscending ? ELIGIBILITY_CONSTS.BV_SORT.PATIENT_NAME : '-' + ELIGIBILITY_CONSTS.BV_SORT.PATIENT_NAME;
                    break;
                case ELIGIBILITY_CONSTS.BV_SORT.RECENT_BV:
                    vm.dataSortOrders.recentBV.isAscending = !vm.dataSortOrders.recentBV.isAscending;
                    //negative sign indicates descending order
                    vm.sortBy = vm.dataSortOrders.recentBV.isAscending ? ELIGIBILITY_CONSTS.BV_SORT.RECENT_BV : '-' + ELIGIBILITY_CONSTS.BV_SORT.RECENT_BV;
                    break;
                case ELIGIBILITY_CONSTS.BV_SORT.DATE_COMPLETED:
                    vm.dataSortOrders.dateCompleted.isAscending = !vm.dataSortOrders.dateCompleted.isAscending;
                    //negative sign indicates descending order
                    vm.sortBy = vm.dataSortOrders.dateCompleted.isAscending ? ELIGIBILITY_CONSTS.BV_SORT.DATE_COMPLETED : '-' + ELIGIBILITY_CONSTS.BV_SORT.DATE_COMPLETED;
                    break;
            }
            vm.loadReverificationList();
        }

    }
})();
