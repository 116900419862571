/**
 * Created by mcaputo on 2/2/17.
 */

(function() {
    'use strict';

    var eaPolicyCoverageList = {
        bindings: {
            insurancePolicies: '<',
            manualBvSubmitted: '=',
            selectedDrawer: '=',
            requestDate: '<',
            requestDrawerCollapsed: '=',
            productDetails: '<',
            responseDate: '<',
            requestObject: '<',
            paFormCallback: '<',
            responseId: '<',
            bvType: '<',
            adjudicationMethod: '<',
            bvStatus: '<'
        },
        templateUrl: 'components/eligibility/policy-coverage-list/policy-coverage-list.html',
        controller: 'PolicyCoverageListController as policyCoverageList'
    };

    angular
        .module('eAccess.ProviderPortal.Components.PolicyCoverageList')
        .component('eaPolicyCoverageList', eaPolicyCoverageList);
})();
