(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilitySplitResponse')
        .controller('RxSplitResponseHeaderController', RxSplitResponseHeaderController);

    RxSplitResponseHeaderController.$inject = ['$log', 'Utility', 'moment', 'ToastNotificationService', 'PATIENT_CONSTS', 'GLOBAL_CONSTS', 'ERROR_MESSAGES', 'STATUS_CONSTS', '$anchorScroll', 'ELIGIBILITY_CONSTS'];

    function RxSplitResponseHeaderController($log, Utility, moment, ToastNotificationService, PATIENT_CONSTS, GLOBAL_CONSTS, ERROR_MESSAGES ,STATUS_CONSTS, $anchorScroll, ELIGIBILITY_CONSTS) {
        var vm = this;

        vm.patient = null;
        vm.showRequestMBV = true;
        vm.showVerifyMed = true;
        vm._getProductCoverageStatusForSplitResponse = _getProductCoverageStatusForSplitResponse;
        vm._getCoverageSummaryCoverageStatus = _getCoverageSummaryCoverageStatus;
        vm._isCoverageStatusCovered = _isCoverageStatusCovered;
        vm.sobCallbackErrorMessage = setSobErrorMessage;
        vm.setSobErrorMessage = setSobErrorMessage;
        vm.note = ELIGIBILITY_CONSTS.NOTE;

        vm.$onInit = function() {
            if(!Utility.isEmpty(vm.response.request)) {
                vm.patient = vm.response.request.patient;
                vm.patient.patientName = Utility.getFullName(vm.patient);
                vm.patient.age = moment().diff(vm.patient.dob, 'years');
                vm.request = vm.response.request;
                vm.bvAdjudicationType = vm.response.bvAdjudicationType;
                vm.coverageStatus = vm.response.policies[0].plan.status.insuranceStatus;
                vm.coverageStatus = _getProductCoverageStatusForSplitResponse(vm.response.policies[0]);
            }
            vm.responseDate = moment(vm.response.statusDate);
            vm.isPatientActive = !(!Utility.isEmpty(vm.response.patientActive) && vm.response.patientActive === false);
            if(!vm.isPatientActive) {
                _setToastMessage(PATIENT_CONSTS.DANGER_TOAST_MESSAGE.PATIENT_INACTIVE_HEADER, PATIENT_CONSTS.DANGER_TOAST_MESSAGE.PATIENT_INACTIVE_MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
            }
            if(!Utility.isEmpty(vm.response.bvAdjudicationType)) {
                vm.adjudicationMethod = vm.response.bvAdjudicationType;
            }
        };
        function _setToastMessage(header, message, toastType) {
            ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, header, message, toastType));
        }
        function _getProductCoverageStatusForSplitResponse(policy) {
            if (!Utility.isEmpty(policy) && !Utility.isEmpty(policy.coverageSummary)) {
                var retail30CoverageStatus = _getCoverageSummaryCoverageStatus(policy.coverageSummary.retail30);
                var retail90CoverageStatus = _getCoverageSummaryCoverageStatus(policy.coverageSummary.retail90);
                var mail90CoverageStatus = _getCoverageSummaryCoverageStatus(policy.coverageSummary.mail90);

                if (_isCoverageStatusCovered(retail30CoverageStatus) ||
                    _isCoverageStatusCovered(retail90CoverageStatus) ||
                    _isCoverageStatusCovered(mail90CoverageStatus)) {
                    return Utility.firstCharacterUpperCase(STATUS_CONSTS.COVERAGE.COVERED);
                }
                return retail30CoverageStatus || retail90CoverageStatus || mail90CoverageStatus || STATUS_CONSTS.COVERAGE.UNKNOWN;
            }
            return null;
        }

        function _getCoverageSummaryCoverageStatus(summaryObject) {
            if (!Utility.isEmpty(summaryObject)) {
                if (!Utility.isEmpty(summaryObject.coverageStatus)) {
                    return summaryObject.coverageStatus;
                }
            }
            return null;
        }

        function _isCoverageStatusCovered(status) {
            return Utility.lowerCase(status) === STATUS_CONSTS.COVERAGE.COVERED
                ||  Utility.lowerCase(status) ===  Utility.lowerCase(STATUS_CONSTS.COVERAGE.COVERED_WITH_RESTRICTIONS);
        }

        function setSobErrorMessage(errorMessage) {
            ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, ERROR_MESSAGES.SOB_ERROR.TITLE, errorMessage, GLOBAL_CONSTS.TOAST_TYPES.ERROR));
            $anchorScroll();
        }
    }
})();
