/**
 * Created by user on 9/1/2017.
 */
(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('practice.providers.edit', {
                parent: 'practice',
                url: '/providers/edit/{id}',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Edit Practice Provider'
                },
                views: {
                    'practiceContent': {
                        templateUrl: 'components/practice/providers/edit/practice-provider-edit.html',
                        controller: 'PracticeProviderEditController as providerEdit'
                    }
                },
                resolve: {
                    providerResponse: ['ProviderService', '$stateParams', 'Utility', function(ProviderService, $stateParams, Utility) {
                        return Utility.getById.call(ProviderService.getProviderByProviderId($stateParams.id), 'practice.providers');
                    }]
                }
            });
    }

})();
