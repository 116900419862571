(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Components.Patient', [
            'eAccess.ProviderPortal.Services.Policy',
            'eAccess.ProviderPortal.Services.PatientStyles',
            'eAccess.ProviderPortal.Services.StatusStyles',
            'eAccess.ProviderPortal.Components.Patient.Profile',
            'eAccess.ProviderPortal.Components.Search',
            'eAccess.ProviderPortal.Constants.TableName',
            'eAccess.ProviderPortal.Constants.Patients',
            'eAccess.ProviderPortal.Constants.StatusCode',
            'eAccess.ProviderPortal.Services.CancelableQ'
        ]);
})();
