/**
 * Created by nikesh on 10/24/2017.
 */
(function () {
    'use strict';

    var VALUES = {

        LINK_EXPIRED_MESSAGE: 'The link emailed to you has expired.',
        CUSTOMER_SUPPORT_DEFAULT: {
            FIRST_MESSAGE: 'If you need assistance, contact VirMedicaine Customer Support at 888-888-8888 or via email at ',
            SECOND_MESSAGE: '. Support is available Monday to Friday from 8:00 AM to 8:00 PM.'
        }
    };

    angular.module('eAccess.ProviderPortal.constants.LinkExpired', []).constant('LINK_EXPIRED_CONSTS', VALUES);
})();
