/**
 * @ngdoc service
 * @name eAccess.ProviderPortal.service:Okta
 * @requires $log
 * @requires $q
 * @description
 *
 * This is the service for handling Okta SDK calls.
 **/

(function() {

    'use strict';

    angular.module('eAccess.ProviderPortal').factory('Okta', Okta);

    Okta.$inject = ['$log', '$q', 'Storage', 'OKTA_CONSTS'];

    function Okta($log, $q, Storage, OKTA_CONSTS) {

        var service = {
            signIn: signIn,
            signOut: signOut,
            isLoggedIn: isLoggedIn,
            getTokens: getTokens,
            tokenDecode: tokenDecode,
            refreshSession: refreshSession,
            refreshIdToken: refreshIdToken,
            refreshAuthToken: refreshAuthToken,
            authClient: null,
            loadOktaConfiguraton: _loadOktaConfiguration
        };

        /**
         * fetch portal configuration
         */
        function _loadOktaConfiguration(publicData) {
            if(!service.authClient) {
                // Sets up the Okta SDK client configuration
                // https://developer.okta.com/code/javascript/okta_auth_sdk_ref#client-configuration
                var issuer = publicData.oktaUrl + '/oauth2/' + publicData.oktaAuthServerId;

                // eslint-disable-next-line no-undef
                service.authClient = new OktaAuth({
                    url: publicData.oktaUrl,
                    clientId: publicData.oktaClientId,
                    redirectUri: publicData.portalOktaRedirectURI,
                    issuer: issuer
                });
            }
        }

        /**
         * @ngdoc function
         * @name signIn
         * @methodOf eAccess.ProviderPortal.service:Okta
         * @description
         *
         * Wrapper for Okta service.authClient.signIn
         *
         * @param credentials
         */

        function signIn(credentials) {
            return service.authClient.signIn(credentials);
        }

        /**
         * @ngdoc function
         * @name signOut
         * @methodOf eAccess.ProviderPortal.service:Okta
         * @description
         *
         * Wrapper for Okta service.authClient.signOut
         */

        function signOut() {
            return service.authClient.signOut();
        }

        /**
         * @ngdoc function
         * @name getTokens
         * @methodOf eAccess.ProviderPortal.service:Okta
         * @description
         *
         * Wrapper for Okta service.authClient.token.getWithoutPrompt. This is where we
         * set scopes and which response types we'd like to receive from Okta.
         * It's important to note what can and cannot be included in this client
         * configuration:
         *
         * https://developer.okta.com/code/javascript/okta_auth_sdk_ref#openid-connect-options
         *
         * @param sessionToken
         */

        function getTokens(sessionToken) {
            var nonceGen = getnonce();
            var requestData = {
                sessionToken: sessionToken,
                responseType: OKTA_CONSTS.RESPONSE_TYPE,
                scopes: OKTA_CONSTS.TOKEN_SCOPES,
                nonce: nonceGen
            };
            Storage.saveToSession('nonce', nonceGen);
            $log.debug('request data:', requestData);
            return service.authClient.token.getWithoutPrompt(requestData);
        }

        function isLoggedIn() {
            return service.authClient.session.exists();
        }

        function tokenDecode(token) {
            return service.authClient.token.decode(token);
        }

        function refreshSession() {
            return service.authClient.session.refresh();
        }

        function refreshIdToken(idToken, issuer) {
            var requestData = {
                idToken: idToken,
                scopes: OKTA_CONSTS.TOKEN_SCOPES,
                authorizeUrl: issuer + OKTA_CONSTS.AUTHORIZE_URL_SUFFIX,
                issuer: issuer
            };
            return service.authClient.token.refresh(requestData);
        }

        function refreshAuthToken(authToken, issuer) {
            var requestData = {
                accessToken: authToken,
                scopes: OKTA_CONSTS.TOKEN_SCOPES,
                authorizeUrl: issuer + OKTA_CONSTS.AUTHORIZE_URL_SUFFIX,
                issuer: issuer
            };
            return service.authClient.token.refresh(requestData);
        }

        function getnonce() {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
            }

            return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        }

        return service;

    }

})();
