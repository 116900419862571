/**
 * Created by mcaputo on 1/18/17.
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .factory('API', API);

    API.$inject = ['$http', '$log', '$q', '$state', '$location', 'ENV', 'Storage', 'VerifyUserService', 'Utility', 'moment'];

    function API($http, $log, $q, $state, $location, ENV, Storage, VerifyUserService, Utility, moment) {

        var service = {
            get: get,
            post: post,
            put: put
        };

        function get(path, params, config, responseType, canceler, nextlink) {
            return _doMethod('GET', path, params, '', '', responseType, canceler, nextlink);
        }

        function post(path, data, config, params, canceler, nextlink) {
            var deferred = $q.defer();
            var _doMethodApi = _doMethod('POST', path, params, data, '', '', canceler, nextlink);
            if(canceler) {
                _doMethodApi =  _doMethodApi.promise;
            }
            _doMethodApi.then(function(response) {
                // hack: POST JSON is a bit mangled at the moment. It returns an extra nested data,
                // so we need to actually return response.data.data, not response.data
                if(angular.isDefined(response.data)) {
                    if(!Utility.isEmpty(response.link)) {
                        response.data.link = response.link;
                    }
                    if(!Utility.isEmpty(response.statusDate)) {
                        response.data.statusDate = response.statusDate;
                    }
                    return deferred.resolve(response.data);
                } else {
                    return deferred.resolve(response);
                }
            }).catch(function(err) {
                $log.error('api error', err);
                return deferred.reject(err);
            });

            if(canceler) {
                return {
                    promise: deferred.promise,
                    canceler: canceler
                };
            }

            return deferred.promise;
        }

        function put(path, data, config) {
            var deferred = $q.defer();
            _doMethod('PUT', path, null, data).then(function(response) {
                // hack: POST JSON is a bit mangled at the moment. It returns an extra nested data,
                // so we need to actually return response.data.data, not response.data
                if(angular.isDefined(response.data)) {
                    return deferred.resolve(response.data);
                } else {
                    return deferred.resolve(response);
                }
            }).catch(function(err) {
                $log.error('api error', err);
                return deferred.reject(err);
            });

            return deferred.promise;
        }

        /*
         * A wrapper method for $http
         * This method allows us to set defaults for all requests, such as the auth_token
         * header.
         */
        function _doMethod(method, path, params, data, headers, responseType, canceler, nextlink) {

            var deferred = $q.defer();
            if(!VerifyUserService.isRoleAdmin() && VerifyUserService.isAPIForbidden(path)) {
                $state.go("dashboard", {forbidden: true});
                return deferred.reject;
            }

            headers = headers || {};
            _addAuthTokenHeader(headers);
            _addHostnameHeader(headers);
            _addZoneOffset(headers);

            $http({
                method: method,
                url: !Utility.isEmpty(nextlink) ?  nextlink : ENV.API_URL + path,
                params: Utility.isEmpty(nextlink) ? params : null,
                data: data,
                headers: headers,
                responseType: responseType,
                //this @Attribute timeout is for cancelling the network process.
                timeout: !Utility.isEmpty(canceler) ? canceler.promise : null
            }).then(function(response) {
                // hack: check for non-200 response because POST requests store
                // the response code somewhere different than GET requests.
                // This needs to be unified on the back end
                var link = response.headers('link');
                if(response.data.statusCode !== 200 && method === 'POST' && response.data.statusCode !== null && angular.isDefined(response.data.statusCode)) {
                    return deferred.reject(response.data);
                } else {
                    if(!Utility.isEmpty(link) && link !== '') {
                        response.data.link = link;
                    }
                    return deferred.resolve(response.data);
                }
            }).catch(function(err) {
                // check for 401 or 403
                if(err.status === 401 || err.status === 403) {
                    // clear session storage
                    Storage.clearAll();

                    // Redirect to login
                    $state.go('login');
                    return deferred.reject(err);
                }

                $log.error('Something went wrong with call to ', ENV.API_URL + path);
                return deferred.reject(err);
            });

            if(!Utility.isEmpty(canceler)) {
                return {
                    promise: deferred.promise,
                    canceler: canceler
                };
            }
            return deferred.promise;
        }

        /*
         * Adds the auth_token header to all requests if an auth_token is found
         * in session storage.
         */
        function _addAuthTokenHeader(headers) {
            var auth_token = Storage.getSessionItem('auth_token');
            var id_token = Storage.getSessionItem('id_token');
            var nonce = Storage.getSessionItem('nonce');
            if(angular.isDefined(auth_token) && auth_token !== null) {
                headers.auth_token = auth_token;
            }
            if(angular.isDefined(id_token) && id_token !== null) {
                headers.id_token = id_token;
            }
            if(angular.isDefined(nonce) && nonce !== null) {
                headers.nonce = nonce;
            }
        }

        function _addHostnameHeader(headers) {
            var host = $location.host();
            headers.host_name = host;
        }

        function _addZoneOffset(headers) {
            headers.zoneOffset = moment().utcOffset();
        }

        return service;
    }
})();
