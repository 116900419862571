
/**
 * Created by pramesh on 10/25/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components')
        .component('eaValidateUser', {
            templateUrl: 'components/register/validate-user-account/validate-user.html',
            controller: 'ValidateUserController as validateUser',
            bindings: {
                registrationGuid: '='
            }
        });

})();
