/**
 * @ngdoc Component
 * @module eAccess.ProviderPortal.Components.RxEligibilityResponse
 * @name eaRestrictionResponse
 * @kind Component
 *
 * @description
 * Restriction Section Block. It takes in restriction data and renders
 * to the view
 *
 * Basic Usage
 * <ea-restriction-response></ea-restriction-response>
 *
 * @author girianish
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .component('eaRestrictionResponse', {
            bindings: {
                policy: '<',
                toolTip: '<',
                getDisplayValue: '<'
            },
            templateUrl: 'components/eligibility/bv-selection/rx/rx-response/policy-coverage/restrictions-response/restrictions-response.html',
            controller: 'RestrictionResponseController as restrictionInfoCtrl'
        });
})();
