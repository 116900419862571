(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Services.CoverageSummary', [])
        .factory('CoverageSummaryService', CoverageSummaryService);

    CoverageSummaryService.$inject = ['$log', 'Utility', 'STATUS_CONSTS'];

    function CoverageSummaryService($log, Utility, STATUS_CONSTS) {
        var service = {
            isValid: isValid,
            useAmountValues: useAmountValues,
            getValidRetail: getValidRetail,
            isCovered: isCovered
        };

        function getValidRetail(coverageSummary) {
            if(coverageSummary.retail30 && coverageSummary.retail30.totalPatientCost) {
                return 'retail30';
            } else if(isValid('retail90', coverageSummary)) {
                return 'retail90';
            } else if(isValid('mail90', coverageSummary)) {
                return 'mail90';
            } else {
                return 'retail30';
            }
        }

        function isValid(key, coverageSummary) {
            return coverageSummary[key]
                && coverageSummary[key].totalPatientCost
                && coverageSummary[key].coverageStatus
                && isCovered(key, coverageSummary);
        }

        function useAmountValues(key, coverageSummary) {
            return {
                deductibleRemainingAmount : (coverageSummary[key].deductibleRemainingAmount ? coverageSummary[key].deductibleRemainingAmount: null),
                oopRemainingAmount : (coverageSummary[key].oopRemainingAmount ? coverageSummary[key].oopRemainingAmount: null)
            };
        }

        function isCovered(key, coverageSummary) {
            return Utility.lowerCase(coverageSummary[key].coverageStatus) === Utility.lowerCase(STATUS_CONSTS.COVERAGE.COVERED)
                || Utility.lowerCase(coverageSummary[key].coverageStatus) === Utility.lowerCase(STATUS_CONSTS.COVERAGE.COVERED_WITH_RESTRICTIONS);
        }

        return service;
    }
})();
