/**
 * Created by pramesh on 10/11/2017.
 */
(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('forgotPassword', {
                url: '/forgotPassword',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Forgot Password'
                },
                views: {
                    'content@': {
                        templateUrl: 'components/forgot-password/forgot-password.html',
                        controller: 'ForgotPasswordController as forgotPassword'
                    }
                }
            });
    }
})();
