/**
 * Created by aayush regmi on 4/12/19.
 */

(function() {
    'use strict';

    var VALUES = {
        KEYS: {
            ISI_PAGE_CONTENT: 'isipagecontent',
            PATIENT_CONSENT_TEXT: 'patientconsenttext',
            ISI_DISCLAIMER: 'isidisclaimer',
            PRACTICE_TERMS_CONDITIONS: 'practicetermsconditions',

        }
    };

    angular.module('eAccess.ProviderPortal').constant('CONTENT_STORE_CONSTS', VALUES);
})();

