/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.Components.ForgotPassword.controller:ForgotPasswordController
 * @requires $log
 * @requires FORGOT_PASSWORD_CONSTS
 * @requires ForgotPasswordService
 * @requires $uibModal
 * @requires ConfigurationService
 * @requires InitialUserService
 * @author pramesh
 * @description
 *
 * This is the controller for the Forgot Password page.
 **/
(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .controller('ForgotPasswordController', ForgotPasswordController);

    ForgotPasswordController.$inject = ['$log', 'FORGOT_PASSWORD_CONSTS', 'ForgotPasswordService', '$uibModal', 'ConfigurationService', 'InitialUserService', 'GLOBAL_CONSTS'];

    function ForgotPasswordController($log, FORGOT_PASSWORD_CONSTS, ForgotPasswordService, $uibModal, ConfigurationService, InitialUserService, GLOBAL_CONSTS) {
        var vm = this;

        vm.user = {};
        vm.userNotExists = false;
        vm.userNotExistsMessage = '';
        vm.requestSent = false;

        // Data
        vm.pageTitle = FORGOT_PASSWORD_CONSTS.PAGE_TITLE;
        vm.message = FORGOT_PASSWORD_CONSTS.MESSAGE;
        vm.successMessage = FORGOT_PASSWORD_CONSTS.SUCCESS_MESSAGE;
        vm.additionalMessage = GLOBAL_CONSTS.EMAIL.USER_NOTIFICATION;
        vm.resendEmail = FORGOT_PASSWORD_CONSTS.RESEND_EMAIL;
        vm.emailSendMessage = GLOBAL_CONSTS.EMAIL.RESENT_MESSAGE;

        // validation requirements
        vm.validation = InitialUserService.getValidationInfo();

        vm.errorMessages = InitialUserService.getErrorMessage(vm.validation);

        init();

        function init() {
            ConfigurationService.fetchRegistrationConfiguration().then(
                function(response) {
                    vm.assistanceMessage = response.customerSupport;
                }
            );
        }

        // function
        vm.forgotPassword = forgotPassword;
        vm.forgotUsername = forgotUsername;
        vm.resendEmailLink = resendEmailLink;

        function forgotPassword() {
            // bail if the form is invalid
            if (vm.forgotPasswordForm.$invalid) return;
            vm.sendingRequest = true;
            vm.userNotExists = false;
            vm.userNotExistsMessage = '';
            var requestObj = _requestObject();
            ForgotPasswordService.requestForgotPassword(requestObj)
                .then(function () {
                    vm.requestSent = true;
                    vm.forgotPasswordForm.$setPristine();
                }).catch(function (error) {
                    $log.error('Unable to send request ', error);
                    vm.userNotExists = true;
                    vm.userNotExistsMessage = FORGOT_PASSWORD_CONSTS.ERROR_MESSAGES.INVALID_USER_NAME;
                }).finally(function () {
                    vm.sendingRequest = false;
                });
        }

        function _requestObject() {
            return {
                userName: vm.user.userName,
                type: FORGOT_PASSWORD_CONSTS.TYPE
            };
        }

        function forgotUsername() {
            $uibModal.open({
                animation: true,
                templateUrl: 'partials/templates/forgot-username-modal.html',
                controller: 'ForgotUsernameModalController',
                controllerAs: 'forgotUsername',
                appendTo: angular.element('body'),
                resolve: {
                    titleText: function() {
                        return FORGOT_PASSWORD_CONSTS.MODAL_MESSAGE.TITLE_TEXT;
                    },
                    message: function() {
                        return FORGOT_PASSWORD_CONSTS.MODAL_MESSAGE.MESSAGE;
                    },
                    assistanceMessage: function() {
                        return vm.assistanceMessage;
                    }
                }
            });
        }

        function resendEmailLink() {
            vm.emailSend = false;
            var requestObj = _requestObject();
            ForgotPasswordService.requestForgotPassword(requestObj)
                .then(function(response) {
                    vm.emailSend = true;
                }).catch(function(error) {
                    $log.error('error: ', error);
                });
        }

    }
})();
