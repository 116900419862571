(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Filters')
        .filter('zip', zipFilter);

    zipFilter.$inject = [];

    function zipFilter() {
        return function(zip) {
            // based off of https://stackoverflow.com/questions/12700145/format-telephone-and-credit-card-numbers-in-angularjs

            if(!zip) {
                return '';
            }

            var pre, post, value = zip.toString().trim().replace(/^\+/, '');

            if(value.match(/[^0-9]/)) {
                return zip;
            }

            // Only 12345-5678 numbers are allowed
            if(value.length !== 9) {
                return value;
            }

            pre = value.slice(0, 5);
            post = value.slice(5);

            return (pre + "-" + post).trim();
        };
    }
})();
