(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Eligibility')
        .factory('PAFormRequestModel', PAFormRequestModel);

    PAFormRequestModel.$inject = ['$log', 'Utility'];

    function PAFormRequestModel($log, Utility) {

        var _defaultPaFormRequestSettings = {
            adjudicationMethod: null,
            bvType: null,
            responseId: null,
            formTemplateId: null,
            policyRank: null
        };

        function PAFormRequestModelInstance(options) {
            angular.extend(this, _defaultPaFormRequestSettings, options);
            if(!Utility.isEmpty(this.adjudicationMethod)) {
                this.adjudicationMethod = this.adjudicationMethod.toUpperCase();
            }

            if(!Utility.isEmpty(this.bvType)) {
                this.bvType = this.bvType.toUpperCase();
            }
        }

        return PAFormRequestModelInstance;
    }
})();
