(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ProviderForm')
        .controller('ProviderFormController', ProviderFormController);

    ProviderFormController.$inject = ['ProviderService', 'HELP_TEXT_CONSTS', 'Utility', 'WarnOnLeave'];

    function ProviderFormController(ProviderService, HELP_TEXT_CONSTS, Utility, WarnOnLeave) {
        var vm = this;

        vm.submitForm = submitForm;

        vm.validation = ProviderService.getValidationInfo();
        vm.errorMessages = ProviderService.getErrorMessages(vm.validation);

        // Help text for help icon tooltips
        vm.helpText = {
            state: HELP_TEXT_CONSTS.PROVIDER.STATE,
            npi: HELP_TEXT_CONSTS.PROVIDER.NPI,
            taxId: HELP_TEXT_CONSTS.PROVIDER.TAX_ID,
            practiceProviderId: HELP_TEXT_CONSTS.PROVIDER.PRACTICE_PROVIDER_ID
        };

        vm.showWarnOnLeaveOnCancel = showWarnOnLeaveOnCancel;
        vm.resetError = resetError;

        vm.$onInit = function() {
            vm.showFooterLinks = Utility.isEmpty(vm.showFooterLinks) ? true : vm.showFooterLinks;
            vm.showHeader = Utility.isEmpty(vm.showHeader) ? true : vm.showHeader;
            vm.showSavingSpinner = Utility.isEmpty(vm.showSavingSpinner) ? false : vm.showSavingSpinner;
        };

        function submitForm() {
            // set validity true for NPI during initial submit
            vm.form.npi.$setValidity('npiType1', true);

            // Set display error message to false initially when form submits
            vm.errorMessages.display = false;
            vm.submitCallback();
        }

        function showWarnOnLeaveOnCancel() {
            WarnOnLeave.show({form: vm.form, toState: {name: 'login'}});
        }

        function resetError(inputName ,value) {
            vm.form[inputName].$setValidity(value, true);
        }
    }
})();
