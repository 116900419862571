(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilitySplitResponse')
        .controller('RxEligibilitySplitResponseController', RxEligibilitySplitResponseController);

    RxEligibilitySplitResponseController.$inject = ['eligibilityResponse', 'RxEligibilityService', 'StatusStyles', 'Utility', 'STATUS_CONSTS'];

    function RxEligibilitySplitResponseController(eligibilityResponse, RxEligibilityService, StatusStyles, Utility, STATUS_CONSTS) {

        var vm = this;
        vm.isPolicyDisplayable =  isPolicyDisplayable;
        vm.setActiveStatusClass = setActiveStatusStyles;
        vm.checkForTabChange = checkForTabChange;
        vm.coverageDetailExpanded = false;

        vm.$onInit = function() {
            RxEligibilityService.getRxApiType()
                .then(function(rxApiType) {
                    vm.rxApiType = rxApiType;
                });
            vm.coverageResponse = eligibilityResponse;
            vm.coverageStatus = vm.coverageResponse.policies[0].plan.status.insuranceStatus;
            if(!Utility.isEmpty(vm.coverageResponse.policies)) {
                vm.coverageResponse.policies.forEach(RxEligibilityService.mapPharmacyStatus);
            }
            vm.requestDetailsExpanded = false;
        };

        function setActiveStatusStyles(status) {
            return StatusStyles.setActiveStatusClass({status:status});
        }

        function isPolicyDisplayable(policy) {
            return isInsuranceActive(policy);
        }

        function checkForTabChange(policy) {
            vm.requestDetailsExpanded = !isInsuranceActive(policy);
        }

        function isInsuranceActive(policy) {
            if(!Utility.isEmpty(policy) && !Utility.isEmpty(policy.plan) && !Utility.isEmpty(policy.plan.status) && !Utility.isEmpty(policy.plan.status.insuranceStatus)) {
                return Utility.lowerCase(policy.plan.status.insuranceStatus) === STATUS_CONSTS.STATUS.ACTIVE;
            } else {
                return false;
            }
        }
    }
})();
