(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Modals')
        .controller('WarningNotificationModalController', WarningNotificationModalController);

    WarningNotificationModalController.$inject = ['$uibModalInstance', 'messageSetting'];

    function WarningNotificationModalController($uibModalInstance, messageSetting) {

        var vm = this;

        vm.title = messageSetting.title;
        vm.content = messageSetting.content;
        vm.btnText = messageSetting.btnTxt;

        vm.closeModal = closeModal;
        vm.leave = leave;

        function closeModal() {
            $uibModalInstance.close();
        }

        function leave() {
            closeModal();
        }

    }
})();
