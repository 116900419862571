(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.InsurancePolicy')
        .factory('InsurancePolicyService', InsurancePolicyService);

    InsurancePolicyService.$inject = ['$q', '$log', 'Payer', 'API', 'Utility', 'ELIGIBILITY_CONSTS', 'PatientService', 'moment', 'SEARCH_CONSTS', 'HELP_TEXT_CONSTS', '$state', 'PATIENT_CONSTS'];

    function InsurancePolicyService($q, $log, Payer, API, Utility, ELIGIBILITY_CONSTS, PatientService, moment, SEARCH_CONSTS, HELP_TEXT_CONSTS, $state, PATIENT_CONSTS) {
        var service = {
            loadPayers: loadPayers,
            mapPolicyRank: mapPolicyRank,
            getPossibleRanks: getPossibleRanks,
            mapPatientInsurancePolicies: mapPatientInsurancePolicies,
            getHelpText: getHelpText,
            getHelpTextForRemovePolicyLink: getHelpTextForRemovePolicyLink
        };

        function getPossibleRanks() {
            return [
                Utility.firstCharacterUpperCase(ELIGIBILITY_CONSTS.POLICY_RANK.PRIMARY),
                Utility.firstCharacterUpperCase(ELIGIBILITY_CONSTS.POLICY_RANK.SECONDARY),
                Utility.firstCharacterUpperCase(ELIGIBILITY_CONSTS.POLICY_RANK.TERTIARY)
            ];
        }

        function mapPolicyRank(value) {
            var rank = null;
            if (Utility.isEmpty(value)) return null;
            switch (value) {
                case 1:
                    rank = getPossibleRanks()[0];
                    break;
                case 2:
                    rank = getPossibleRanks()[1];
                    break;
                case 3:
                    rank = getPossibleRanks()[2];
                    break;
                default:
                    rank = null;
            }
            return rank;
        }

        function loadPayers() {
            var deferred = $q.defer();

            API.get('payers/user').then(function (response) {
                var payers = response.map(function (payerResponse) {
                    return new Payer(payerResponse);
                });
                deferred.resolve(payers);
            });

            return deferred.promise;
        }

        function mapPatientInsurancePolicies(policy) {
            policy.subscriberPatient = PatientService.isPatientSubscriber(policy);
            policy.subscriber = {
                firstName: policy.subscriberFirstName,
                lastName: policy.subscriberLastName,
                dob: (policy.subscriberDob) ? moment(policy.subscriberDob, SEARCH_CONSTS.DATE_FORMAT).toDate() : null,
                memberId: policy.subscriberMemberId,
                gender: policy.subscriberGender
            };
            policy.rank = mapPolicyRank(policy.payerRank);
            if (!Utility.isEmpty(policy.payerOrg)) {
                for (var obj in policy.payerOrg) {
                    policy.payer[obj] = policy.payerOrg[obj];
                }
            }
            if (Utility.isEmpty(policy.payer.id)) policy.payer.id = policy.payer.payerId;
            if (Utility.isEmpty(policy.payer.name)) policy.payer.name = policy.payer.payerName;

            delete policy.payer.payerId;
            delete policy.payer.payerName;

            return policy;
        }

        function getHelpText() {
            return {
                providerOutOfNetwork: HELP_TEXT_CONSTS.POLICY.OUT_OF_NETWORK,
                rank: HELP_TEXT_CONSTS.BV_REQUEST.INSURANCE_INFO.RANK,
                memberId: HELP_TEXT_CONSTS.BV_REQUEST.INSURANCE_INFO.MEMBER_ID,
                insuranceCarrier: HELP_TEXT_CONSTS.BV_REQUEST.INSURANCE_INFO.INSURANCE_CARRIER,
                medicareMemberId: HELP_TEXT_CONSTS.BV_REQUEST.INSURANCE_INFO.MEDICARE_MEMBER_ID,
                medicareSuppPlanPolicy: HELP_TEXT_CONSTS.BV_REQUEST.INSURANCE_INFO.MEDICARE_SUPP_PLAN_POLICY,
                providerIsInNetwork: HELP_TEXT_CONSTS.BV_REQUEST.INSURANCE_INFO.PROVIDER_IS_IN_NETWORK,
                patientIsSubscriber: HELP_TEXT_CONSTS.BV_REQUEST.INSURANCE_INFO.PATIENT_IS_SUBSCRIBER,
                subscriberFirstName: HELP_TEXT_CONSTS.BV_REQUEST.INSURANCE_INFO.SUBSCRIBER_FIRSTNAME,
                removePolicyLink: service.getHelpTextForRemovePolicyLink()
            };
        }

        function getHelpTextForRemovePolicyLink() {
            var currentStateName = $state.current.name;
            return (currentStateName.indexOf(PATIENT_CONSTS.STATE_NAME) !== -1) ? HELP_TEXT_CONSTS.PATIENT.INSURANCE_INFO.REMOVE_POLICY_LINK : HELP_TEXT_CONSTS.BV_REQUEST.INSURANCE_INFO.REMOVE_POLICY_LINK;
        }

        return service;
    }
})();
