/**
 * Created by pramesh on 12/14/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .factory('PracticeUserModel', PracticeUserModel);

    PracticeUserModel.$inject = ['USER_CONSTS', 'STATUS_CONSTS', 'Utility'];

    function PracticeUserModel(USER_CONSTS, STATUS_CONSTS, Utility) {

        var _defaultUser = {
            firstName: null,
            middleName: null,
            lastName: null,
            userName: null,
            userRole: null,
            email: null,
            userId: null,
            status: null,
            phoneNo: null,
            phoneNoType: null,
            faxNo: null
        };

        function PracticeUserModelInstance(options) {
            angular.extend(this, _defaultUser, options);

            // status for user
            (Utility.lowerCase(options.status) === USER_CONSTS.STATUS.DEPROVISIONED) ? this.status = STATUS_CONSTS.STATUS.INACTIVE : this.status = STATUS_CONSTS.STATUS.ACTIVE;
        }

        return PracticeUserModelInstance;
    }

})();
