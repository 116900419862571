/**
 * Created by sstokes on 1/10/17.
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .factory('PlaceOfService', PlaceOfService);

    PlaceOfService.$inject = ['$log', '$http', '$q', '$timeout', 'API', 'POSModel'];

    function PlaceOfService($log, $http, $q, $timeout, API, POSModel) {
        var service = {
            get: get,
            getByProduct: getByProduct
        };

        function get() {
            var deferred = $q.defer();

            API.get('pos').then(function(response) {
                var serviceList = response.map(function(posJson) {
                    var newPOS = new POSModel(posJson.posId, posJson.posDescription, posJson.posCode);
                    return newPOS;
                });

                deferred.resolve(serviceList);
            }).catch(function(err) {
                $log.error(err);
            });

            return deferred.promise;
        }

        function getByProduct(productId) {
            var deferred = $q.defer();

            API.get('pos/productId/' + productId).then(function(posResponse) {
                var serviceList = posResponse.map(function(posJson) {
                    var newPOS = new POSModel(posJson.posId, posJson.posDescription, posJson.posCode);
                    return newPOS;
                });

                deferred.resolve(serviceList);
            }).catch(function(err) {
                $log.error(err);
            });

            return deferred.promise;
        }

        return service;
    }
})();
