/**
 * Created by pramesh on 7/10/2018.
 */
(function() {
    'use strict';

    var VALUES = {
        EXPIRED: 'expired',
        IS_BEING_EXPIRED: 'isBeingExpired',
        NOT_EXPIRED: 'notExpired',
        SESSION_NOT_AVAILABLE: 'sessionNotAvailable'
    };

    angular.module('eAccess.ProviderPortal')
        .constant('USER_SESSION_CONSTS', VALUES);
})();
