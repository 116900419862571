/**
 * Created by aayush.regmi on 10/9/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.HipaaBaa')
        .controller('HipaaBaaController', HipaaBaaController);

    HipaaBaaController.$inject = ['$log', 'HipaaBaaService', 'Storage', 'REGISTER_CONSTS', '$uibModal'];

    function HipaaBaaController($log, HipaaBaaService, Storage, REGISTER_CONSTS, $uibModal) {
        var vm = this;

        vm.attestations = [];
        vm.hipaaBaaContent = '';
        vm.hipaaEsignature = '';
        vm.clickedSaveButton = false;
        vm.isScrolledToBottom = false;
        vm.showErrorMessage = false;
        vm.errorMessages = {
            required: REGISTER_CONSTS.ERROR_MESSAGES.ELECTRONIC_SIGNATURE.REQUIRED,
            attestation: {
                undersignedRequired: REGISTER_CONSTS.ERROR_MESSAGES.ATTESTATION.I_AM_UNDER_SIGNED_REQUIRED,
                iAgreeRequired: REGISTER_CONSTS.ERROR_MESSAGES.ATTESTATION.I_AGREE_REQUIRED,
                bothRequired: REGISTER_CONSTS.ERROR_MESSAGES.ATTESTATION.SELECT_BOTH
            }
        };

        vm.hipaaBaa = {};
        vm.getHipaaBaaContent = getHipaaBaaContent;
        vm.getHipaaConfiguration = getHipaaConfiguration;
        vm.saveHipaaBaaData = saveHipaaBaaData;
        vm.changeHipaaBaaAttestationValue = changeHipaaBaaAttestationValue;
        vm.isFormInvalid = isFormInvalid;
        vm.scrolledToBottom = scrolledToBottom;
        vm.isAttestationSelected = isAttestationSelected;
        vm.getErrorMessage = getErrorMessage;
        vm.openWarnOnLeave = openWarnOnLeave;
        vm.goBack = goBack;
        vm.fetchHipaaBaaFromSession = fetchHipaaBaaFromSession;
        vm.getHipaaConfiguration();

        function getHipaaBaaContent() {
            return vm.hipaaBaaContent;
        }

        function getHipaaConfiguration() {
            HipaaBaaService.getHipaaConfiguration()
                .then(
                    function(response) {
                        vm.attestations = [];
                        vm.hipaaBaaContent = response.hipaaBaaTermsConditions;
                        vm.attestations.push({label: response.userIAgreeText, selected: false});
                        vm.attestations.push({label: response.userIAmUndersignedText, selected: false});
                        vm.fetchHipaaBaaFromSession();
                    }
                )
                .catch(
                    function() {
                        vm.attestations = [];
                        vm.hipaaBaaContent = '';
                    });
        }

        function saveHipaaBaaData() {
            vm.clickedSaveButton = true;
            if(!isFormInvalid()) {
                Storage.saveToSession('hipaaBaaEsignature', vm.hipaaEsignature);
                Storage.saveToSession('hipaaBaaSignedDate', new Date());
                Storage.saveToSession('hipaaBaaAttestation1', vm.attestations[0].label);
                Storage.saveToSession('hipaaBaaAttestation2', vm.attestations[1].label);
                vm.nextCallback();
            } else {
                return false;
            }
        }

        function changeHipaaBaaAttestationValue(index) {
            if(!vm.isScrolledToBottom) {
                vm.showErrorMessage = true;
                vm.attestations[index].selected = false;
                return false;
            } else {
                return true;
            }
        }

        function isFormInvalid() {
            return (!vm.hipaaEsignature || !vm.attestations[0].selected || !vm.attestations[1].selected);
        }

        function scrolledToBottom() {
            vm.isScrolledToBottom = true;
        }

        function isAttestationSelected(index) {
            return vm.attestations[index].selected;
        }

        function getErrorMessage(index) {
            if(index === 0) {
                return vm.errorMessages.attestation.iAgreeRequired;
            } else {
                return vm.errorMessages.attestation.undersignedRequired;
            }
        }

        function openWarnOnLeave() {
            if(vm.hipaaBaaForm.$dirty) {
                var warnModal = $uibModal.open({
                    animation: true,
                    ariaLabelledBy: 'warn-on-leave-title',
                    arialDescribedBy: 'warn-on-leave-content',
                    templateUrl: 'partials/templates/warn-on-leave-modal.html',
                    controller: 'WarnOnBackModalController',
                    controllerAs: 'warnOnLeave',
                    appendTo: angular.element('body')
                });
                warnModal.result.then(function() {
                    Storage.clearSessionData('hipaaBaaEsignature');
                    Storage.clearSessionData('hipaaBaaSignedDate');
                    Storage.clearSessionData('hipaaBaaAttestation1');
                    Storage.clearSessionData('hipaaBaaAttestation2');
                });
            } else {
                return false;
            }
        }

        function goBack() {
            $log.debug('Go to user agreement');
            Storage.clearSessionData('hipaaBaaEsignature');
            Storage.clearSessionData('hipaaBaaSignedDate');
            Storage.clearSessionData('hipaaBaaAttestation1');
            Storage.clearSessionData('hipaaBaaAttestation2');
            vm.previousCallback();
        }

        function fetchHipaaBaaFromSession() {
            var esignature = Storage.getSessionItem('hipaaBaaEsignature');
            if(esignature) {
                vm.hipaaEsignature = esignature;
                vm.isScrolledToBottom = true;
                vm.attestations[0].selected = true;
                vm.attestations[1].selected = true;
            }
        }

    }
})();
