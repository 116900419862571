/**
 * Created by mcaputo on 2/9/17
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Directives')
        .directive('eaNoFutureDates', EaNoFutureDates);

    EaNoFutureDates.$inject = ['$log'];

    // This directive ensures that a date is not in the future.
    // Any field containing a future date is set to invalid.
    function EaNoFutureDates($log) {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function(scope, element, attrs, modelCtrl) {
                // Set the date the last midnight
                var now = new Date();
                var today_str = (now.getMonth() + 1) + '/' + (now.getDate()) + '/' + (now.getFullYear());
                var today = new Date(today_str);

                modelCtrl.$parsers.push(function (ngModelVal) {
                    var userDate = new Date(ngModelVal);

                    // Entered date is in the future
                    if (today < userDate || today === userDate) {
                        modelCtrl.$setValidity('date-range', false);
                        return ngModelVal;
                    }

                    var dateIsInPast = userDate < today && today !== userDate;

                    modelCtrl.$setValidity('date-range', dateIsInPast);
                    return ngModelVal;
                });
            }
        };
    }
})();
