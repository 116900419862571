/**
 * Created by girianish.
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Services.RxEligibilityResponse')
        .factory('RxPolicyResponseHeaderService', RxPolicyResponseHeaderService);


    RxPolicyResponseHeaderService.$inject = ['$log', 'Utility', 'StatusStyles', 'HELP_TEXT_CONSTS'];

    function RxPolicyResponseHeaderService($log, Utility, StatusStyles, HELP_TEXT_CONSTS) {
        var service = {
            setProductCoverageClass: setProductCoverageClass,
            getPolicyResponseHeaderToolTip: getPolicyResponseHeaderToolTip
        };

         /**
         * @ngdoc function
         * @name setProductCoverageClass
         * @description
         * Wrapper function for StatusStyles.setCoverageDetailsClass
         *
         * This is done to reuse StatusStyles.setCoverageDetailsClass with minimal changes
         * as we are getting coverage status in status.productCoverageStatus node and
         * setCoverageDetailsClass is expecting in coverageStatus
         * @param status
         **/
        function setProductCoverageClass(status) {
            if(Utility.isEmpty(status)){
                return null;
            }
            var productCoverage = {
                coverageStatus: status.productCoverageStatus
            };
            return StatusStyles.setCoverageDetailsClass(productCoverage);
        }

        function getPolicyResponseHeaderToolTip() {
            return {
                policyStatusValue: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_RESPONSE_HEADER.POLICY_STATUS_VALUE,
                productNameValue: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_RESPONSE_HEADER.PRODUCT_NAME_VALUE,
                daysSupplyLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_RESPONSE_HEADER.DAYS_SUPPLY_LABEL,
                productCoverageStatusLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_RESPONSE_HEADER.PRODUCT_COVERAGE_STATUS_LABEL,
                formularyStatusLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_RESPONSE_HEADER.FORMULARY_STATUS_LABEL,
                pharmacyUsedLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_RESPONSE_HEADER.PHARMACY_USED_LABEL,
                additionalCoverageDetails: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_RESPONSE_HEADER.ADDITIONAL_COVERAGE_DETAILS_LABEL,
                totalPatientCostLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_RESPONSE_HEADER.TOTAL_PATIENT_COST_LABEL
            };
        }

        return service;
    }
})();
