(function() {
    'use strict';

    var VALUES = {
        DASH: '---',
        UNKNOWN: 'unknown',
        OTHER: 'Other',
        EMAIL: {
            USER_NOTIFICATION: 'If you have received multiple emails, remember to click the link in the most recent one (older links are no longer valid).',
            RESENT_MESSAGE: 'A new email has been sent. Please check your inbox. If you have received multiple emails, remember to click the link in the most recent one (older links are no longer valid).'
        },
        DATE_FORMAT: {
            YYYY_MM_DD: {
                SLASH: 'YYYY/MM/DD',
                DASH: 'YYYY-MM-DD'
            },
            MM_DD_YYYY: {
                SLASH: 'MM/DD/YYYY'
            }
        },
        TOAST_TYPES: {
            SUCCESS: 'success',
            ERROR: 'error',
            WARNING: 'warning'
        },
        TOAST_TITLE: {
            SAVE_SUCCESSFUL: 'Save Successful',
            BAD_REQUEST_409: 'Sorry, We Have Experienced a Client Error.',
            BAD_REQUEST_500: 'Sorry, We Have Experienced a Technical Error.',
            SYSTEM_ERROR: 'System Error',
            BAD_REQUEST: 'Bad Request',
            COVERAGE_NOT_FOUND: 'Coverage Not Found'
        },
        TOAST_MESSAGE: {
            BAD_REQUEST: 'There was a problem with your request. Please re-enter your information and try again. If the problem persists, please contact customer service.',
            SYSTEM_MESSAGE: "Your request was not processed due to a system error. Please try again. If you continue to receive this message, please contact Customer Support.",
            SAVE_SUCCESSFUL: 'Your changes have been successfully saved.',
            BAD_REQUEST_409: 'The portal cannot process your request because of a client error. A notification has been sent to technical services to make them aware of this error. We apologize for the inconvenience. <br><br>You can retry your action and if you continue to experience difficulties with the portal, please contact Customer Support directly.',
            BAD_REQUEST_500: 'The portal has experienced a technical error that is not due to your computer or internet connection. A notification has been sent to technical services to make them aware of this error. We apologize for the inconvenience. <br><br>If you continue to experience difficulties with the portal please contact Customer Support directly.'
        },
        GENDER: {
            MALE: 'male',
            FEMALE: 'female',
            UNKNOWN: 'unknown'
        },
        REACTIVATE: 'Reactivate',
        DEACTIVATE: 'Deactivate',
        ORGANIZATION_NAME: {
            DEPUY: 'depuy'
        },
        SYSTEM_NOTIFICATION: {
            HEADER: 'System Notification',
            BTN_TEXT: 'OK',
            KEYS: {
                SYSTEM_NOTIFICATION: 'systemNotification'
            }
        },
        PORTAL: 'Portal',
        PUBLIC: 'Public',
        TYPE: 'Type',
        INTERIOR: 'Interior',
        FOOTER: {
            TERMS_OF_USE_LABEL: 'Terms of Use',
            TERMS_OF_USE_ENDPOINT: 'termsofuse',
            PRIVACY_POLICY_LABEL: 'Privacy Policy',
            PRIVACY_POLICY_LABEL_ENDPOINT: 'privacypolicy'
        },
        KEYS: {
            ID: 'id'
        },
        CARDHOLDER: {
            MASK: {
                NUMBER_OF_CHARS_TO_SHOW_IN_START: 2,
                NUMBER_OF_CHARS_TO_SHOW_IN_END: 2
            }
        },
        COVERED: {
            TITLE: 'Coverage Found',
            MESSAGE: 'We\'ve found coverage for this patient. This transaction was automatically initiated to find the best coverage for the patient. If you feel this is in error, you may initiate another verification'
        },
        SSN: 'ssn',
        MEDICARE_ID: 'medicareId',
        PORTAL_PAGE_TITLE: 'eAccess Provider Portal'
    };
    angular.module('eAccess.ProviderPortal.Constants.Global', []).constant('GLOBAL_CONSTS', VALUES);
})();
