(function () {
    'use strict';

    angular.module('eAccess.ProviderPortal.Services.UserSession').factory('UserSessionEvent', UserSessionEvent);

    UserSessionEvent.$inject = ['$log', 'UserActivityTimer', 'Storage', 'ForceLogout', 'Utility', '$timeout', 'Auth', 'OKTA_CONSTS'];


    function UserSessionEvent($log, UserActivityTimer, Storage, ForceLogout, Utility, $timeout, Auth, OKTA_CONSTS) {

        var service = {
            initialize: initialize,
            fetchSessionStorage: fetchSessionStorage,
            setRefreshCallEvent: setRefreshCallEvent,
            setIdleTimeEvent: setIdleTimeEvent,
            idleTimeCalculation: idleTimeCalculation,
            saveSessionRefreshConfig: saveSessionRefreshConfig,
            clearAllEvents: clearAllEvents
        };

        var userSessionProperties = {
            refreshCallEventScope: undefined,
            idleCallEventScope: undefined,
            sessionTimeFrame: undefined,
            sessionRefreshTime: undefined,
            idleTimeEvent: undefined,
            refreshTime: undefined
        };

        /**
         * function to initialize idleTime, refreshCallEvent and idleTimeEvent
         */
        function initialize() {
            UserActivityTimer.setLastActiveTime();
            service.setRefreshCallEvent();
            service.setIdleTimeEvent();
        }

        function fetchSessionStorage() {
            userSessionProperties.sessionTimeFrame = Storage.getSessionItem('session_time_frame');
            userSessionProperties.sessionRefreshTime = Storage.getSessionItem('session_refresh_time');
            userSessionProperties.idleTimeEvent = Storage.getSessionItem('idle_time_event');
            userSessionProperties.refreshTime = userSessionProperties.sessionRefreshTime - Math.floor(new Date() / 1000);
        }

        /**
         * function to set refreshCallEvent in queue stack,
         * recursively call the refresh session by calculating refresh time
         */
        function setRefreshCallEvent() {
            service.fetchSessionStorage();
            userSessionProperties.refreshCallEventScope = $timeout(function () {
                Auth.refreshSession().then(function () {
                    service.setRefreshCallEvent();
                });
            }, userSessionProperties.refreshTime*1000); // time will be in milli-second
        }

        /**
         * function to set idleTimeEvent in queue stack,
         * recursively calculate the idle time of the user
         */
        function setIdleTimeEvent() {
            service.fetchSessionStorage();
            var _idleTime = userSessionProperties.idleTimeEvent - Math.floor(new Date() / 1000);
            userSessionProperties.idleCallEventScope = $timeout(function () {
                service.idleTimeCalculation();
            }, _idleTime*1000); // time will be in milli-second
        }

        /**
         * function to calculate idle time of user
         * if user idle time is more then session time frame (i.e provided by okta)
         * then force logout the user as session time out
         * if user idle time is less then session time frame
         * then set another idle time to call idleTimeEvent
         */
        function idleTimeCalculation() {
            var _userLastActiveTime = UserActivityTimer.getLastActiveTime(),
                _currentTime = Math.floor(new Date() / 1000),
                _idleTimeInterval = (_currentTime - _userLastActiveTime);
            if (_idleTimeInterval >= userSessionProperties.sessionTimeFrame) {
                ForceLogout.logout(true); // force logout for being idle
            } else {
                // new idle time callback event
                // time remaining to expire session = sessionTimeFrame - idleTimeInterval
                // idleTimeEventTime = time remaining to expire session + current time in second
                var _idleTimeEventTime = (userSessionProperties.sessionTimeFrame - _idleTimeInterval) + Math.floor(new Date() / 1000);
                Storage.saveToSession('idle_time_event', _idleTimeEventTime);
                service.setIdleTimeEvent();
            }
        }

        /**
         * function to set refresh_time_config and session_refresh_time in session storage
         * @param refreshBeforeTimeout in second
         */
        function saveSessionRefreshConfig(refreshBeforeTimeout) {
            // if refresh before time is null, then set refreshBeforeTime as default time from constant file
            if(Utility.isEmpty(refreshBeforeTimeout)) {
                refreshBeforeTimeout = OKTA_CONSTS.BEFORE_SESSION_REFRESH_TIME;
            }

            // if refreshBeforeTimeout is more thean sessionTimeFrame, then set refreshBeforeTime as default time from constant file
            var sessionTimeFrame = Storage.getSessionItem('session_time_frame');
            if(refreshBeforeTimeout >= sessionTimeFrame) {
                refreshBeforeTimeout = OKTA_CONSTS.BEFORE_SESSION_REFRESH_TIME;
            }

            // save refresh before time from portal config response
            Storage.saveToSession('refresh_time_config', refreshBeforeTimeout);
            var expireAt = Storage.getSessionItem('session_expiration');
            // time to call refresh session in okta
            Storage.saveToSession('session_refresh_time', (expireAt - refreshBeforeTimeout));
        }

        /**
         * function to clear all timeout events that had been set by this service
         */
        function clearAllEvents() {
            if (!Utility.isEmpty(userSessionProperties.refreshCallEventScope)) {
                $timeout.cancel(userSessionProperties.refreshCallEventScope);
            }
            if (!Utility.isEmpty(userSessionProperties.idleCallEventScope)) {
                $timeout.cancel(userSessionProperties.idleCallEventScope);
            }
        }

        return service;
    }

})();
