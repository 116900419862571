(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Services.StatusStyles')
        .factory('StatusStyles', StatusStyles);

    StatusStyles.$inject = ['$log', 'STATUS_CONSTS', 'STYLES_CONSTS', 'Utility', 'ELIGIBILITY_CONSTS', 'HELP_TEXT_CONSTS', 'BV_SELECTION_CONSTS', 'PATIENT_CONSTS'];

    function StatusStyles($log, STATUS_CONSTS, STYLES_CONSTS, Utility, ELIGIBILITY_CONSTS, HELP_TEXT_CONSTS, BV_SELECTION_CONSTS, PATIENT_CONSTS) {

        var service = {
            setActiveStatusClass: setActiveStatusClass,
            setCoverageDetailsClass: setCoverageDetailsClass,
            setIconClass: setIconClass,
            setTransactionErrorClass: setTransactionErrorClass,
            setDisplayTextIcon: setDisplayTextIcon,
            setDisplayTextClass: setDisplayTextClass,
            setFormularyTextClass: setFormularyTextClass,
            setProductCoverageStatus: setProductCoverageStatus,
            setProductCoverageIconClass: setProductCoverageIconClass,
            setTransactionMessageClass: setTransactionMessageClass,
            setTransactionIconClass: setTransactionIconClass,
            setActiveStatusClassByValue: setActiveStatusClassByValue,
            getAdjudicationIconClass: getAdjudicationIconClass,
            getIconTooltipInfo: getIconTooltipInfo
        };

        /**
         * @param {Object} element
         */
        function setActiveStatusClass(element) {
            return (!Utility.isEmpty(element) && !Utility.isEmpty(element.status)) ? setActiveStatusClassByValue(element.status) : null;
        }

        /**
         * @param {string} status Status
         */
        function setActiveStatusClassByValue(status) {
            if (Utility.isEmpty(status)) return null;
            switch (Utility.lowerCase(status)) {
                case STATUS_CONSTS.STATUS.ACTIVE:
                    return STYLES_CONSTS.STYLES.SUCCESS_MESSAGE;
                case STATUS_CONSTS.STATUS.UNDETERMINED:
                case STATUS_CONSTS.STATUS.INCOMPLETE:
                    return STYLES_CONSTS.STYLES.WARNING_MESSAGE;
                case STATUS_CONSTS.STATUS.INACTIVE:
                case STATUS_CONSTS.STATUS.INELIGIBLE:
                    return STYLES_CONSTS.STYLES.ERROR_MESSAGE;
                case STATUS_CONSTS.STATUS.REQUIRED:
                    return STYLES_CONSTS.STYLES.ERROR_MESSAGE;
                default:
                    return '';
            }
        }

        /**
         * @param {Object} element
         */
        function setCoverageDetailsClass(element) {
            return (Utility.isEmpty(element) || Utility.isEmpty(element.coverageStatus)) ? null : setProductCoverageStatus(element.coverageStatus, true);
        }

        /**
         * @param {String} statusText
         * @param {Boolean} forCoverageDetailsClass
         */
        function setProductCoverageStatus(statusText, forCoverageDetailsClass) {
            if (Utility.isBlank(statusText)) return null;
            switch (Utility.lowerCase(statusText)) {

                case STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.PAYER_GUIDELINES_APPLY:
                case STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.COVERED:
                case STATUS_CONSTS.COVERAGE.PAYER_REQUIREMENTS_APPLY:
                case STATUS_CONSTS.COVERAGE.COVERED_WITH_RESTRICTIONS:
                    return STYLES_CONSTS.STYLES.SUCCESS_MESSAGE;

                case STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.UNDETERMINED:
                case STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.PRIOR_AUTHORIZATION_REQUIRED:
                case STATUS_CONSTS.COVERAGE.COVERED_WITH_CONDITIONS:
                case Utility.lowerCase(STATUS_CONSTS.PENDING_MANUAL_VERIFICATION):
                    return STYLES_CONSTS.STYLES.WARNING_MESSAGE;

                case STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.NOT_COVERED:
                    return STYLES_CONSTS.STYLES.ERROR_MESSAGE;

                default:
                    return (!Utility.isEmpty(forCoverageDetailsClass)) ? STYLES_CONSTS.STYLES.ERROR_MESSAGE : '';
            }
        }

        /**
         * @param {Object} element
         */
        function setIconClass(element) {
            if (Utility.isEmpty(element) || Utility.isEmpty(element.status)) return null;
            switch (Utility.lowerCase(element.status)) {
                case STATUS_CONSTS.STATUS.UNDETERMINED:
                    return STYLES_CONSTS.STYLES.ICONS.FA_EXCLAMATION_TRIANGLE;
                case STATUS_CONSTS.STATUS.INACTIVE:
                    return STYLES_CONSTS.STYLES.ICONS.FA_TIMES_CIRCLE;
                default:
                    return STYLES_CONSTS.STYLES.ICONS.FA_EXCLAMATION_TRIANGLE;
            }
        }

        /**
         * @param {Object} element
         */
        function setTransactionErrorClass(element) {
            if (Utility.isEmpty(element) || Utility.isEmpty(element.status)) return null;
            switch (Utility.lowerCase(element.status)) {
                case STATUS_CONSTS.STATUS.INACTIVE:
                    return STYLES_CONSTS.STYLES.ERROR_MESSAGE;
                default:
                    return STYLES_CONSTS.STYLES.WARNING_MESSAGE;
            }
        }

        /**
         * @param {String} statusText
         */
        function setDisplayTextIcon(statusText) {
            if (Utility.isEmpty(statusText)) return null;
            switch (Utility.lowerCase(statusText)) {
                case STATUS_CONSTS.COVERAGE.UNDETERMINED:
                case STATUS_CONSTS.COVERAGE.COVERED_WITH_CONDITIONS:
                    return STYLES_CONSTS.STYLES.ICONS.FA_EXCLAMATION_WARNING;
                case STATUS_CONSTS.COVERAGE.NOT_COVERED:
                case PATIENT_CONSTS.CONSENT_TEXT.STATUS.INCOMPLETE:
                case PATIENT_CONSTS.CONSENT_TEXT.STATUS.ACCEPTED:
                case PATIENT_CONSTS.CONSENT_TEXT.STATUS.SENT:
                case PATIENT_CONSTS.CONSENT_TEXT.STATUS.ENGAGED:
                case PATIENT_CONSTS.CONSENT_TEXT.STATUS.INVALID:
                case PATIENT_CONSTS.CONSENT_TEXT.STATUS.EXPIRED:
                    return STYLES_CONSTS.STYLES.ICONS.FA_TIMES_CIRCLE_ERROR;
                case PATIENT_CONSTS.CONSENT_TEXT.STATUS.COMPLETE:
                    return STYLES_CONSTS.STYLES.ICONS.FA_CHECK_CIRCLE_SUCCESS;
                default:
                    return '';
            }
        }

        /**
         * @param {String} statusText
         */
        function setDisplayTextClass(statusText) {
            if (Utility.isEmpty(statusText)) return null;
            switch (Utility.lowerCase(statusText)) {
                case STATUS_CONSTS.COVERAGE.UNDETERMINED:
                    return STYLES_CONSTS.STYLES.WARNING_MESSAGE;
                case STATUS_CONSTS.COVERAGE.NOT_COVERED:
                case PATIENT_CONSTS.CONSENT_TEXT.STATUS.INCOMPLETE:
                case PATIENT_CONSTS.CONSENT_TEXT.STATUS.CONSENT_EXPIRED:
                case PATIENT_CONSTS.CONSENT_TEXT.STATUS.EXPIRED:
                    return STYLES_CONSTS.STYLES.ERROR_MESSAGE;
                case STATUS_CONSTS.COVERAGE.APPLIES:
                case PATIENT_CONSTS.CONSENT_TEXT.STATUS.COMPLETE:
                    return STYLES_CONSTS.STYLES.SUCCESS_MESSAGE;
                default:
                    return '';
            }
        }

        /**
         * @param {String} formularyStatus
         */
        function setFormularyTextClass(formularyStatus) {
            if (Utility.isEmpty(formularyStatus)) return null;
            switch (Utility.lowerCase(formularyStatus)) {
                case STATUS_CONSTS.FORMULARY.NOT_APPLICABLE:
                case STATUS_CONSTS.FORMULARY.NOT_APPLICABLE_APPLY:
                    return '';
                case STATUS_CONSTS.FORMULARY.NOT_COVERED:
                    return STYLES_CONSTS.STYLES.ERROR_MESSAGE;
                case STATUS_CONSTS.FORMULARY.ON_FORMULARY:
                case STATUS_CONSTS.FORMULARY.UNDETERMINED:
                    return STYLES_CONSTS.STYLES.WARNING_MESSAGE;
                default:
                    return '';
            }
        }

        /**
         * @param {string} status Status
         * @param declinedIndicator
         *
         * This function is only using a single value although it holds
         * resembelance to the setTransactionErrorMessageClass, the latter
         * one is expecting an object while this one is expecting a string.
         */

        //TODO: Refactor this code with setTransactionErrorMessageClass.
        function setTransactionMessageClass(status, declinedIndicator) {
            if (Utility.isEmpty(status) || Utility.isEmpty(declinedIndicator)) return null;
            // if declined indicator in response is present and its true
            if (declinedIndicator) return STYLES_CONSTS.STYLES.WARNING_MESSAGE;

            switch (Utility.lowerCase(status)) {
                case STATUS_CONSTS.STATUS.INACTIVE:
                    return STYLES_CONSTS.STYLES.ERROR_MESSAGE;
                case STATUS_CONSTS.STATUS.ACTIVE:
                case STATUS_CONSTS.STATUS.UNDETERMINED:
                    return STYLES_CONSTS.STYLES.WARNING_MESSAGE;
                default:
                    return STYLES_CONSTS.STYLES.WARNING_MESSAGE;
            }
        }

        /**
         * @param {string} status Status
         * @param declinedIndicator
         */
        function setTransactionIconClass(status, declinedIndicator) {
            if (Utility.isEmpty(status) || Utility.isEmpty(declinedIndicator)) return null;
            // if declined indicator in response is present and its true
            if (declinedIndicator) return STYLES_CONSTS.STYLES.ICONS.FA_EXCLAMATION_CIRCLE;

            switch (Utility.lowerCase(status)) {
                case STATUS_CONSTS.STATUS.UNDETERMINED:
                    return STYLES_CONSTS.STYLES.ICONS.FA_EXCLAMATION_CIRCLE;
                case STATUS_CONSTS.STATUS.INACTIVE:
                    return STYLES_CONSTS.STYLES.ICONS.FA_TIMES_CIRCLE;
                case STATUS_CONSTS.STATUS.ACTIVE:
                    return STYLES_CONSTS.STYLES.ICONS.FA_EXCLAMATION_TRIANGLE;
                default:
                    return STYLES_CONSTS.STYLES.ICONS.FA_EXCLAMATION_CIRCLE;
            }
        }

        /**
         * @param {String} statusText
         */
        function setProductCoverageIconClass(statusText) {
            if (Utility.isEmpty(statusText)) return null;
            switch (Utility.lowerCase(statusText)) {
                case STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.UNDETERMINED:
                    return STYLES_CONSTS.STYLES.ICONS.FA_EXCLAMATION_CIRCLE_WARNING;
                case STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.NOT_COVERED:
                    return STYLES_CONSTS.STYLES.ICONS.FA_TIMES_CIRCLE_ERROR;
                case STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.COVERED:
                    return STYLES_CONSTS.STYLES.ICONS.FA_CHECK_CIRCLE_SUCCESS;
                default:
                    return '';
            }
        }

        /**
         * Function to provide adjudication icon class
         * @param bvAdjudicationType Medical or Rx
         * @param size icon size
         * @return {String | null} icon class name
         */
        function getAdjudicationIconClass(bvAdjudicationType, size) {
            if (Utility.isEmpty(bvAdjudicationType)) return null;
            var isBvAdjudicationMedical = Utility.isMedical(bvAdjudicationType);
            switch (Utility.lowerCase(size)) {
                case STYLES_CONSTS.STYLES.ICON_SIZE.SMALL.toLowerCase():
                    return isBvAdjudicationMedical ? STYLES_CONSTS.STYLES.ADJUDICATION_METHOD.MED.SMALL : STYLES_CONSTS.STYLES.ADJUDICATION_METHOD.RX.SMALL;
                case STYLES_CONSTS.STYLES.ICON_SIZE.MEDIUM.toLowerCase():
                    return isBvAdjudicationMedical ? STYLES_CONSTS.STYLES.ADJUDICATION_METHOD.MED.MEDIUM : STYLES_CONSTS.STYLES.ADJUDICATION_METHOD.RX.MEDIUM;
                default:
                    return null;
            }
        }

        /**
         * Function to get icon tool tip for adjudication type
         * @param bvAdjudicationType
         * @return {String | null}
         */
        function getIconTooltipInfo(bvAdjudicationType) {
            if (Utility.isEmpty(bvAdjudicationType)) return null;
            switch (Utility.lowerCase(bvAdjudicationType)) {
                case BV_SELECTION_CONSTS.METHOD_NAME.MEDICAL:
                    return HELP_TEXT_CONSTS.ADJUDICATION_METHOD.MED;
                case BV_SELECTION_CONSTS.METHOD_NAME.RX:
                    return HELP_TEXT_CONSTS.ADJUDICATION_METHOD.RX;
                default:
                    return null;
            }
        }

        return service;
    }
})();
