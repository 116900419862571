(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('practice.locations', {
                parent: 'practice',
                url: '/locations',
                params: {
                    locationSubmitted: null,
                    locationUpdated: null,
                    invalidUrl: false
                },
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Practice Locations'
                },
                views: {
                    'practiceContent': {
                        templateUrl: 'components/practice/locations/practice-locations.html',
                        controller: 'PracticeLocationController as location'
                    }
                }
            });
    }

})();
