(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Login', [
            'eAccess.ProviderPortal.Constants.Okta',
            'eAccess.ProviderPortal.Constants.Login',
            'eAccess.ProviderPortal.Services.EmailService',
            'eAccess.ProviderPortal.Services.UserSession'
        ]);
})();
