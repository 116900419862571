/**
 * Created by pratik budhathoki on 2/28/2018.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibility')
        .controller('RxEligibilityController', RxEligibilityController);

    RxEligibilityController.$inject = ['$log', 'ProviderService', 'Eligibility', '$anchorScroll', 'ProviderModel', '$q', 'moment', 'ERROR_MESSAGES', '$location', '$state', 'ELIGIBILITY_CONSTS', 'Utility', 'CacheDataService', '$scope', 'HELP_TEXT_CONSTS', 'PatientService', 'ConsentService', 'STATUS_CODE', 'BV_SELECTION_CONSTS', 'STATUS_CONSTS', 'GLOBAL_CONSTS', 'ToastNotificationService', 'RxEligibilityService'];

    function RxEligibilityController($log, ProviderService, Eligibility, $anchorScroll, ProviderModel, $q, moment, ERROR_MESSAGES, $location, $state, ELIGIBILITY_CONSTS, Utility, CacheDataService, $scope, HELP_TEXT_CONSTS, PatientService, ConsentService, STATUS_CODE, BV_SELECTION_CONSTS, STATUS_CONSTS, GLOBAL_CONSTS, ToastNotificationService, RxEligibilityService) {
        var vm = this;

        vm.coverageResponse = null;
        vm.gettingCoverage = false;
        vm.showProviderDropdown = true;
        vm.providerExists = false;

        vm.editConsent = true;

        vm.isFormDirty = false;
        vm.bvAdjudicationMethod = ELIGIBILITY_CONSTS.BV_TYPE.RX;

        vm.helpText = {
            consent: HELP_TEXT_CONSTS.BV_REQUEST.PATIENT_INFO.CONSENT,
            switchToMed: HELP_TEXT_CONSTS.BV_REQUEST.SWITCH_MED_BUTTON
        };
        vm.rxApiType = ELIGIBILITY_CONSTS.RX_API_TYPES.COMBINED;

        vm.checkCoverage = checkCoverage;
        vm.patientSearchEligibilityCallback = patientSearchEligibilityCallback;
        vm.setPatientGender = setPatientGender;
        vm.setPatientDob = setPatientDob;


        vm.$onInit = function() {
            RxEligibilityService.getRxApiType()
                .then(function(rxApiType) {
                    vm.rxApiType = rxApiType;
                })
                .catch(function(err) {
                    $log.error('error', err);
                });
            _initCachedData();
        };

        $scope.$on('$stateChangeSuccess', function(ev, to, toParams, from, fromParams) {
            if(from.name === ELIGIBILITY_CONSTS.STATE.MED_ELIGIBILITY_RESPONSE && toParams.showProviderError) {
                vm.showProviderError = toParams.showProviderError;
            }
        });

        function _isAnyPatientInfoFilled() {
            return ((!Utility.isEmpty(vm.patient.address) && !Utility.isEmpty(vm.patient.address.zipCode)) ||
                !Utility.isEmpty(vm.patient.firstName) ||
                !Utility.isEmpty(vm.patient.lastName) ||
                !Utility.isEmpty(vm.patient.middleName) ||
                !Utility.isEmpty(vm.patient.dob) ||
                !Utility.isEmpty(vm.patient.gender));
        }

        /**
         * @ngdoc function
         * @name addProvider
         * @methodOf eAccess.ProviderPortal.controller:MedEligibilityController
         * @description
         *
         * Adds a new provider if it does not exist.
         *
         * @param provider
         **/

        function addProvider(provider) {
            return vm.providerInfo.showProviderDropdown ? $q.when() : ProviderService.addProvider(provider);
        }

        function checkCoverage() {
            // reset validation
            vm.providerExists = false;
            $location.hash('');

            // bail if the form is invalid
            if(vm.checkCoverageForm.$invalid) {
                return false;
            }

            vm.gettingCoverage = true;

            // Temporary patient dob object before formatting
            // Added as part of EP-1279
            vm.tempPatientDob = vm.patient.dob;

            vm.coverageResponse = null;

            // Added as part of EP-1279
            vm.patient.dob = angular.copy(vm.tempPatientDob);

            // Prepare request data
            var requestData = angular.copy(prepareRequestData());

            addProvider(vm.providerInfo.provider)
                .then(function(provider) {
                    requestData.provider = getProviderInformation(provider);
                    // Format patient dob
                    if(vm.patient.dob) {
                        requestData.patient.dob = moment(vm.patient.dob).format(GLOBAL_CONSTS.DATE_FORMAT.YYYY_MM_DD.DASH);
                    }

                    var apiResult;

                    if(Utility.isRxApiTypeSplit(vm.rxApiType)) {
                        apiResult = Eligibility.checkRxSplitCoverage(requestData);
                    } else {
                        apiResult = Eligibility.checkRxCoverage(requestData);
                    }
                    apiResult.then(_handleSuccess)
                        .catch(_handleFailure)
                        .finally(_cleanUp);
                })
                .catch(function(error) {
                    // Provider exists
                    $log.error('error', error.data.message);
                    vm.providerExists = true;
                    vm.providerExistsMessage = error.data.message;
                    vm.gettingCoverage = false;
                    $location.hash('provider-information-heading');
                    $anchorScroll();
                })
                .finally(function() {
                    // reset patient dob to object
                    if(vm.patient.dob) {
                        // Added as part of EP-1279
                        vm.patient.dob = angular.copy(vm.tempPatientDob);
                    }
                });
        }

        function prepareRequestData() {
            return {
                patient: vm.patient,
                service: _getServiceDeliveryInfo(),
                provider: vm.providerInfo.provider,
                location: vm.serviceDeliveryInformation.selectedLocation
            };
        }

        function _getServiceDeliveryInfo() {
            return {
                product: vm.serviceDeliveryInformation.selectedProduct,
                daysSupply: vm.serviceDeliveryInformation.daysSupply,
                quantity: vm.serviceDeliveryInformation.quantity
            };
        }

        function getProviderInformation(provider) {
            if(provider) {
                vm.providerInfo.provider = new ProviderModel(provider);
            }
            return vm.providerInfo.provider;
        }

        function patientSearchEligibilityCallback(data) {
            Eligibility.prepareMedEligibilityFormData(data.request.patient.patientId, BV_SELECTION_CONSTS.METHOD_NAME.RX).then(function(formData) {
                CacheDataService.setRxBvCacheData(formData);
                _initCachedData();
                vm.checkCoverageForm.$dirty = true;
                vm.patientProvider = vm.providerInfo.provider;
            });
        }

        function _fetchPatientConsent(patientId) {
            //This is to retrieve patient consent details
            ConsentService.getConsentData(patientId).then(function(data) {
                vm.consentMessage = data;
                vm.consentMessage.onFile = vm.consentMessage.onfile && !vm.consentMessage.expired;
                vm.editConsent = vm.consentMessage.onfile === false || vm.consentMessage.expired;
            }).catch(function(err) {
                $log.error("Could not retrieve patient consent: ", err);
            });
        }

        function setPatientGender(gender) {
            if(Utility.isEmpty(gender)) return null;
            return (Utility.lowerCase(gender) === Utility.lowerCase(GLOBAL_CONSTS.GENDER.MALE.charAt(0)) || Utility.lowerCase(gender) === Utility.lowerCase(GLOBAL_CONSTS.GENDER.MALE)) ? Utility.upperCase(GLOBAL_CONSTS.GENDER.MALE.charAt(0)) : Utility.upperCase(GLOBAL_CONSTS.GENDER.FEMALE.charAt(0));
        }

        function setPatientDob(dob) {
            if(Utility.isEmpty(dob)) return null;
            var date = new Date(dob);
            return new Date(date.valueOf() + date.getTimezoneOffset() * 60000);
        }

        function _initCachedData() {
            //Initializing cached bv data if available
            vm.patient = CacheDataService.patient;
            if(!Utility.isEmpty(vm.patient) && !Utility.isEmpty(vm.patient.dob)) vm.patient.dob = moment(vm.patient.dob, GLOBAL_CONSTS.DATE_FORMAT.YYYY_MM_DD.DASH).toDate();
            vm.patient.gender = PatientService.setPatientGender(vm.patient);
            vm.providerInfo = CacheDataService.providerInfo;
            vm.consentMessage = CacheDataService.consentMessage;
            vm.serviceDeliveryInformation = CacheDataService.rxServiceDeliveryInformation;

            //making sure that form should be dirty after cached data are initialize
            vm.isFormDirty = _isAnyPatientInfoFilled() || !Utility.isEmpty(vm.providerInfo.provider) || !Utility.isEmpty(vm.consentMessage.onFile);

            if(Utility.isEmpty(vm.providerInfo.provider)) {
                vm.providerInfo.showProviderDropdown = true;
            }
            if(!Utility.isEmpty(vm.patient.patientId)) {
                _fetchPatientConsent(vm.patient.patientId);
            }
        }

        function _handleSuccess(response) {
            vm.checkCoverageForm.$setPristine();
            CacheDataService.clearBVCache();
            var redirectToState = Utility.isRxApiTypeSplit(vm.rxApiType) ? ELIGIBILITY_CONSTS.STATE.RX_ELIGIBILITY_SPLIT_RESPONSE : ELIGIBILITY_CONSTS.STATE.RX_ELIGIBILITY_RESPONSE;

            if(!Utility.isEmpty(response.patientConflicts) && response.patientConflicts.length > 0) {
                redirectToState = ELIGIBILITY_CONSTS.STATE.RX_DATA_CONFLICT;
            }
            $state.go(redirectToState, {
                responseId: response.responseId,
                adjudicationType: ELIGIBILITY_CONSTS.BV_TYPE.RX
            });
        }

        function _handleFailure(err) {
            $log.error('error getting coverage', err);
            var notification;
            if(err.status === STATUS_CODE["409_CONFLICT"]) {
                vm.checkCoverageForm.patientId.$invalid = true;
                vm.checkCoverageForm.patientId.$error = {duplicate: true};
                $location.hash('patient-id-label');
            } else if(err.status === STATUS_CODE["404_NOT_FOUND"]) {
                notification = ToastNotificationService.buildToastNotification(
                    true,
                    GLOBAL_CONSTS.TOAST_TITLE.SYSTEM_ERROR,
                    ELIGIBILITY_CONSTS.VERIFY_BV.ERROR.MESSAGE.INACTIVE_PATIENT,
                    GLOBAL_CONSTS.TOAST_TYPES.ERROR
                );
                ToastNotificationService.setToastNotification(notification);

                $anchorScroll();
            } else {
                // Using generic message for error
                notification = ToastNotificationService.buildToastNotification(
                    true,
                    ERROR_MESSAGES.BV_ERROR.TITLE,
                    ERROR_MESSAGES.BV_ERROR.MESSAGE,
                    GLOBAL_CONSTS.TOAST_TYPES.ERROR
                );
                ToastNotificationService.setToastNotification(notification);

                $anchorScroll();
            }


            /*
             * Set error states here
             */
            throw err;
        }

        function _cleanUp() {
            vm.gettingCoverage = false;

            // Added as part of EP-1279
            vm.patient.dob = angular.copy(vm.tempPatientDob);
        }
    }

})();
