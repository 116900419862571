(function () {
    angular
        .module('eAccess.ProviderPortal.Components.PasswordComplexity')
        .controller('PasswordComplexityController', PasswordComplexityController);

    PasswordComplexityController.$inject = ['$log', 'PasswordComplexityFactory'];

    function PasswordComplexityController($log, PasswordComplexityFactory) {
        var vm = this;

        vm.getPasswordComplexityData = getPasswordComplexityData;
        vm.getPasswordComplexity = {};


        vm.$onInit = function () {
            vm.getPasswordComplexityData();
        };

        function getPasswordComplexityData() {
            PasswordComplexityFactory.getPasswordComplexityData().then(function (data) {
                vm.headerMessage = data.pwComplexityInfo;
            });
        }

    }
})();
