(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Services.SystemNotification')
        .factory('SystemNotification', SystemNotification);

    SystemNotification.$inject = ['Utility', 'GLOBAL_CONSTS'];

    function SystemNotification(Utility, GLOBAL_CONSTS) {

        var _defaultSettings = {
            notificationModalId: null,
            display: false,
            message: null,
            title: GLOBAL_CONSTS.SYSTEM_NOTIFICATION.HEADER,
            notificationType: null,
            systemNotification: false,
            btnText: GLOBAL_CONSTS.SYSTEM_NOTIFICATION.BTN_TEXT,
        };

        function SystemNotificationInstance(notification, btnText) {
            if(Utility.isEmpty(notification)) {
                return _defaultSettings;
            } else {
                this.notificationModalId = notification.notificationModalId || _defaultSettings.notificationModalId;
                this.display = notification.active || _defaultSettings.display;
                this.message = notification.bodyContent || _defaultSettings.message;
                this.title = notification.headerContent || _defaultSettings.title;
                this.notificationType = notification.notificationType || _defaultSettings.notificationType;
                this.systemNotification = notification.systemNotification || _defaultSettings.systemNotification;
                this.btnText = btnText || _defaultSettings.btnText;
            }
        }

        return SystemNotificationInstance;
    }

})();
