(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Modals')
        .controller('PatientSearchModalController', PatientSearchModalController);

    PatientSearchModalController.$inject = ['$log', 'PatientService', '$uibModalInstance', 'searchFacets', 'searchCallbackEligibility', 'PatientModel', 'SEARCH_CONSTS', 'Search', 'DATA_LIMIT_CONSTS', 'ConfigurationService', 'Utility', 'TABLE_NAME_CONSTS', 'PaginationService', 'PATIENT_CONSTS', 'bvMethod', 'SearchModel', 'SearchPaginationModel', 'moment'];

    function PatientSearchModalController($log, PatientService, $uibModalInstance, searchFacets, searchCallbackEligibility, PatientModel, SEARCH_CONSTS, Search, DATA_LIMIT_CONSTS, ConfigurationService, Utility, TABLE_NAME_CONSTS, PaginationService, PATIENT_CONSTS, bvMethod, SearchModel, SearchPaginationModel, moment) {

        var vm = this;

        // data
        vm.loadingPatients = false;
        vm.offset = DATA_LIMIT_CONSTS.OFFSET;
        vm.nextLink = null;
        vm.fetchPatientCount = true;
        vm.totalPatientCount = DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE;
        // Defaults to patientLastName asc because it's the option that gets preselected while opening up patient search modal
        vm.sortBy = SEARCH_CONSTS.SORT_VALUES.PATIENT_NAME;
        vm.activePatientsOnly = SEARCH_CONSTS.ACTIVE_PATIENTS_ONLY;

        vm.closeModal = closeModal;
        vm.searchFacets = searchFacets;
        vm.patientSearchCallback = patientSearchCallback;
        vm.selectPatient = selectPatient;
        vm.searchType = SEARCH_CONSTS.SEARCH_TYPE.PATIENT_MODAL;
        vm.loadMoreCallBack = loadMoreCallBack;
        vm.bvMethod = bvMethod;

        vm.$onInit = function() {
            ConfigurationService.fetchDefaultTablePageSize(TABLE_NAME_CONSTS.PATIENT_TABLE).then(function(pageLimit) {
                vm.limit = !Utility.isEmpty(pageLimit) ? pageLimit : DATA_LIMIT_CONSTS.DEFAULT_LIMIT;
            }).catch(function(err) {
                vm.limit = DATA_LIMIT_CONSTS.DEFAULT_LIMIT; // this is just in case if 'defaultLimit' not returned from the API
                $log.error('Unable to fetch portal data', err);
            }).finally(function() {
                vm.loadingPatients = false;
            });
        };

        function closeModal() {
            $uibModalInstance.close();
        }

        function patientSearchCallback(searchModel) {
            vm.patientResponseList = [];

            if(searchModel.search === null) {
                vm.noDataFound = true;
                vm.warningMessage = SEARCH_CONSTS.EMPTY_SEARCH_WARNING_MESSAGE;
                return;
            }
            vm.selectedFacet = searchModel.selectedFacet;
            vm.loadingPatients = true;
            vm.sortBy = searchModel.pagination.sortOrder;
            searchModel.search.then(function(patients) {
                var paginationResponse = PaginationService.getPaginationResponse(patients, vm.patientResponseList, PATIENT_CONSTS.DATA_FILTER_KEYS.PATIENT_PRACTICE_ID, vm.offset, vm.limit);
                vm.patientCount = paginationResponse.totalCount;
                // vm.callDirFunc(paginationResponse.list.length, vm.limit);
                vm.patientResponseList = paginationResponse.list;
                vm.nextLink = paginationResponse.nextLink;

            }).catch(function(err) {
                //only set this value if there are no data previously
                if(!vm.patientResponseList.length) {
                    vm.noDataFound = true;
                }
                vm.patientCount = DATA_LIMIT_CONSTS.ZERO_LIMIT;
            }).finally(function() {
                vm.searchParam = searchModel.selectedFacet.searchCategory;
                vm.searchQuery = searchModel.query;
                vm.warningMessage = Search.getSearchResultText(vm.patientCount, vm.searchParam, vm.searchQuery);
                vm.loadingPatients = false;
                vm.warningMessage = Search.getSearchResultText(vm.patientCount, vm.searchParam, vm.searchQuery);

            });
        }

        function selectPatient(patient) {
            $log.debug('selectPatient patient', patient);
            // true is sent to get patient data from next BV if fails to get from first one
            PatientService._getPatientDataForEligibility(patient.patient, vm.bvMethod, true).then(function(data) {
                searchCallbackEligibility(data, patient.externalId);
            });
            closeModal();
        }

        function loadMoreCallBack() {
            var totalCountEqualsDataCount = vm.totalPatientCount === vm.patientResponseList.length;
            if(!Utility.shouldCallAPI(vm.loadingPatients, totalCountEqualsDataCount, vm.noDataFound)) {
                return;
            }

            vm.loadingPatients = true;

            var searchParam = Search.getSearchCategoryValue(vm.searchFacets, vm.searchParam);

            var searchQuery = angular.copy(vm.searchQuery);
            if(searchParam === PATIENT_CONSTS.PATIENT_SORT.DATE_OF_BIRTH) {
                searchQuery = PatientService.getArrayFormattedDateString(moment(vm.searchQuery).format(SEARCH_CONSTS.DATE_FORMAT));
            }
            var pagination = new SearchPaginationModel(null, vm.offset, vm.limit, Search.getSortValueForSelectedFaceted(vm.selectedFacet.value), vm.nextLink);
            var searchModel = new SearchModel(pagination, searchQuery, vm.selectedFacet, false, false);

            Search.patientSearch(searchModel)
                .then(function(response) {
                    var paginationResponse = PaginationService.getPaginationResponse(response, vm.patientResponseList, PATIENT_CONSTS.DATA_FILTER_KEYS.PATIENT_PRACTICE_ID, vm.offset, vm.limit);
                    vm.totalPatientCount = paginationResponse.totalCount;
                    vm.patientResponseList = paginationResponse.list;
                    vm.nextLink = paginationResponse.nextLink;
                })
                .catch(function(err) {
                    //only set this value if there are no data previously
                    if(!vm.patientResponseList.length) {
                        vm.noDataFound = true;
                    }
                })
                .finally(function() {
                    vm.loadingPatients = false;
                });
        }
    }
})();
