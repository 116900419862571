(function() {
    'use strict';

    var LOGIN_CONSTS = {
        SESSION_EXPIRE: {
            TITLE: 'Session Timed Out',
            MESSAGE: 'Your session has timed out. Please log in to continue.',
        },
        DISPLAY_TEXT: {
            LOGGING_IN_TEXT: 'Logging in...',
            LOG_IN_TEXT: 'Login'
        },
        ACCOUNT_DEACTIVATED: {
            HEADER: 'Account Deactivated',
            MESSAGE: 'Your account has been deactivated. Please contact an Admin User at your practice for further assistance.'
        }
    };

    angular.module('eAccess.ProviderPortal.Constants.Login', []).constant('LOGIN_CONSTS', LOGIN_CONSTS);
})();
