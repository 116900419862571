/**
 * @ngdoc directive
 * @methodOf eAccess.ProviderPortal.Components.Practice.directive:eaWarnOnLeave
 * @name eAccess.ProviderPortal.directive:eaWarnOnLeave
 * @requires $log
 * @requires $window
 * @requires WarnOnLeave
 * @description
 *
 * This directive is added as an attribute to forms in order to prompt
 * a warning when the form is dirty and the user tries to change states.
 *
 * NOTE: You will also need to set the form to pristine:
 *           vm.exampleForm.$setPristine();
 *       after successfully submitting the form in the controller and
 *       before legitimately changing states, e.g. save the practice
 *       info, reset form to pristine, redirect to practice info page.
 *
 * @example form(name="exampleForm" ea-warn-on-leave)
 **/

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Directives')
        .directive('eaWarnOnLeave', eaWarnOnLeave);

    eaWarnOnLeave.$inject = ['$log', '$window', 'WarnOnLeave', 'BV_SELECTION_CONSTS', 'Storage', 'Utility', 'CacheDataService'];

    function eaWarnOnLeave($log, $window, WarnOnLeave, BV_SELECTION_CONSTS, Storage, Utility, CacheDataService) {

        return {
            require: '^form',
            restrict: 'A',
            link: function(scope, element, attrs, form) {
                // eslint-disable-next-line no-unused-vars
                var $locationChangeStartUnbind = scope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
                    if (form.$dirty) {
                        // if session has expired warn on leave model shall not be displayed
                        if(Utility.isEmpty($window.sessionStorage.getItem('auth_token'))) {
                            CacheDataService.clearBVCache();
                        }
                        // if we are switching from med BV page to rx BV page or vice-versa then we should not render the warning-leave pop up.
                        else if((fromState.name === BV_SELECTION_CONSTS.STATE_NAME.MEDICAL && toState.name === BV_SELECTION_CONSTS.STATE_NAME.RX) ||
                            (fromState.name === BV_SELECTION_CONSTS.STATE_NAME.RX && toState.name === BV_SELECTION_CONSTS.STATE_NAME.MEDICAL)) {
                            // no need to render the warning leave pop up
                        } else {
                            if(!WarnOnLeave.isFormDirty) {
                                event.preventDefault();
                                WarnOnLeave.isFormDirty = true;
                                // adding toParams object in warn on leave config object
                                toState.params = toParams;
                                WarnOnLeave.show({
                                    toState: toState,
                                    form: form
                                });
                            }
                        }
                    }
                });
                scope.$on('$destroy', function() {
                    $window.onbeforeunload = null;
                    $locationChangeStartUnbind();
                });
            }
        };

    }

})();
