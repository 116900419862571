/**
 * Created by pramesh on 12/1/2017.
 */

(function() {
    'use strict';

    var eaMbvView = {
        bindings: {
            manualBv: '<',
            benefitVerificationDate : '<',
            adjudicationMethod: '<'
        },
        templateUrl: 'components/mbv-view/mbv-view.html',
        controller: 'MBVViewController as mbView'
    };

    angular
        .module('eAccess.ProviderPortal.Components.MBVView')
        .component('eaMbvView', eaMbvView);

})();
