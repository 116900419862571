/**
 * Created by aayush regmi on 4/12/19.
 */

(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .factory('ContentStoreService', ContentStoreService);

    ContentStoreService.$inject = ['$http', 'ENV', 'BRAND_IMAGE_CONSTS', '$q', '$window', '$sce'];

    function ContentStoreService($http, ENV, BRAND_IMAGE_CONSTS, $q, $window, $sce) {
        var service = {
            getContentStoreData: getContentStoreData
        };

        function getContentStoreData(key) {
            var deferred = $q.defer();
            $http.get(ENV.API_URL + BRAND_IMAGE_CONSTS.API + key).then(function (response) {
                var originalData = response.data;
                originalData = originalData.substring(1, originalData.length - 1);
                var decodedData = $window.atob(originalData);
                return deferred.resolve(decodedData);
            }).catch(function (err) {
                return deferred.reject(err);
            });

            return deferred.promise;
        }

        return service;
    }

})();
