(function() {
    'use strict';

    var eaDateRangeSearchComponent = {
        bindings: {
            instructionText: '=',
            callback: '<',
            showClearAll: '<',
            clearAllCallback: '<',
            startDate: '=?',
            endDate: '=?',
            viewableStatus: '<',
            limit: '<',
            filterQuery: '<',
            searchByDateRange: '<',
            searchType: '<'
        },
        templateUrl: 'components/search/date-range-search.html',
        controller: 'DateRangeSearchController as dateRangeSearch'
    };

    angular
        .module('eAccess.ProviderPortal.Components.Search')
        .component('eaDateRangeSearchComponent', eaDateRangeSearchComponent);
})();
