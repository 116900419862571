(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ConsentMessage')
        .factory('ConsentService', ConsentService);

    ConsentService.$inject = ['$q', '$log', 'API'];

    function ConsentService($q, $log, API) {
        var service = {
            getConsentData: getConsentData,
            sendInvitationToPatient: sendInvitationToPatient,
            getEhipaaPdf: getEhipaaPdf
        };

        function getConsentData(patientId) {
            return API.get('patients/consent/' + patientId);
        }

        function sendInvitationToPatient(req) {
            return API.post('patients/consents/ehipaa', req);
        }

        function getEhipaaPdf(patientId) {
            return API.get('patients/consents/pdf/' + patientId);
        }

        return service;
    }
})();
