(function() {
    'use strict';

    var VALUES = {

        PRACTICE: {
            PATIENT_ID: 'Adding this information will enable you to search for your patient using your practice\'s patient ID.',
            NPI: "This should be the practice location\'s NPI (NPPES NPI Registry Type 2 for Organizations or Practice Locations).",
            TAX_ID: 'This information is used for insurance carrier validation when obtaining benefit verifications. Enter the Tax ID used when submitting claims for services delivered by this provider. This may be either the provider\'s personal TIN, or the practice\'s organization TIN, depending on your organization\'s billing practices.',
            LEGAL_NAME: 'The legal name under which your Practice does business.'
        },

        POLICY: {
            OUT_OF_NETWORK: 'If the Provider delivering services is contracted with this patient\'s insurance plan/network, select Yes. Otherwise, select No to receive out-of-network plan benefits.',
            LOCATION: 'Select the practice location where services will be delivered.',
            SECONDARY_DIAGNOSIS: "Add a secondary diagnosis only if it is dependent on the patient's primary diagnosis.",
            PRIMARY_DIAGNOSIS: "Choose the primary diagnosis the selected product will be used to treat.",
            RANK: "Primary is the patient's primary coverage policy. Secondary is the policy that provides secondary coverage. Tertiary is the policy that provides tertiary coverage."
        },
        PROVIDER: {
            PRACTICE_PROVIDER_ID: 'Adding this information will enable you to search for your provider using your practice\'s provider ID.',
            TAX_ID: "This information is used for insurance carrier validation when obtaining benefit verifications. Enter the Tax ID used when submitting claims for services delivered by this provider. This may be either the provider's personal TIN, or the practice's organization TIN, depending on your organization's administrative practices.",
            BILLING_TAX_ID: "This information is used for insurance carrier validation when obtaining benefit verifications. Enter the Tax ID used when submitting claims for services delivered by this provider. This can be either the provider's personal TIN, or the practice's organization TIN, depending on your organization's billing practices.",
            NPI: "This should be the provider\'s individual NPI (NPPES NPI Registry Type 1 for Individuals).",
            BILLING_NPI: "Enter the NPI used when submitting claims for services delivered by this provider. This can be either the provider's personal NPI, or the practice's organization NPI, depending on your organization's billing practices.",
            STATE: "Select the state in which this provider will be delivering services. This may be either the state where the provider practices, or the state in which the organization is located, depending on your organization's administrative practices.",
            BILLING_STATE: "Select the state in which this provider will be delivering services. This can be either the state where the provider practices, or the state in which the organization is located, depending on your organization's billing practices."
        },

        PRACTICE_LOCATION: {
            NPI: "This should be the practice location\'s NPI (NPPES NPI Registry Type 2 for Organizations or Practice Locations).",
            TAX_ID: "This information is used for insurance carrier validation when obtaining benefit verifications. Enter the Tax ID used when submitting claims for services delivered at this location. This can be either the location TIN, or the practice's organization TIN, depending on your organization's billing practices.",
            PRACTICE_LOCATION_ID: 'Adding this information will enable you to search for your location using your practice\'s location ID.'
        },

        MANUAL_BENEFIT_VERIFICATIONS: {
            HELP_TEXT: "If you'd like us to contact the patient's insurance carrier on your behalf to verify coverage, please provide the information below. We will notify you when your response is ready for review -- usually within one to two business days.",
            RX_HELP_TEXT: "If you'd like us to contact the patient's Pharmacy Benefit Manager on your behalf to verify coverage, please provide the information below. We will notify you when your response is ready for review – usually within one to two business days."
        },
        REGISTER: {
            REGISTRATION_CODE: 'Your Registration Code is required to start the Practice Registration process. If you have not received the Registration Code for your Practice, click the following link to request it.'
        },

        USER: {
            ROLE: 'Admins have full access to managing practice information, locations, patients, providers, and users. Users do not have access to managing other Users, and cannot deactivate locations, providers, or patients.',
            OWN_ROLE: 'You may not change your own User Role. Contact an Admin User for your Practice for further assistance. Admins have full access to managing practice information, locations, patients, providers, and users. Users do not have access to managing other Users, and cannot deactivate locations, providers, or patients.'
        },

        BENEFIT_VERIFICATION: {
            PRACTICE_PATIENT_ID: "Adding this information will enable you to search for your patient using your practice's patient ID.",
            MOOP: "The most the patient pays for covered services in a plan year. After this amount is spent on deductibles, copayments, and coinsurance, the health plan pays 100% of the costs of covered benefits.",
            PROVIDER: {
                STATE: "Select the state in which this provider will be delivering services. This can be either the state where the provider practices, or the state in which the organization is located, depending on your organization's billing practices."
            }
        },
        SERVICE_DELIVERY: {
            DAYS_SUPPLY: 'The number of days the supply of dispensed medication will last.',
            QUANTITY: 'The exact amount of the product to be provided.',
            LOCATION: 'Select the practice location where services will be delivered.'
        },
        ADJUDICATION_METHOD: {
            MED: 'Medical Benefit Verification',
            RX: 'Rx Benefit Verification'
        },
        RX_RESPONSE: {
            POLICY_RESPONSE_HEADER: {
                POLICY_STATUS_VALUE: 'This is the status of the patient\'s prescription coverage plan policy with this PBM. Active means the patient has active prescription coverage. Inactive means the patient no longer has active prescription coverage. Undetermined means we are unable to confirm the patient\'s prescription coverage status with this PBM.',
                PRODUCT_NAME_VALUE: 'You have checked this patient\'s benefits for this product.',
                DAYS_SUPPLY_LABEL: 'The presented coverage and patient cost is for this days supply/quantity, which may be different from the amounts submitted in the BV request.',
                PRODUCT_COVERAGE_STATUS_LABEL: 'This is the patient\'s product coverage for this prescription plan. Prior Authorization required means prior authorization must be obtained for the product to be covered. Payer Guidelines apply means prior authorization is not required, but the payer\'s medical policy and/or any other applicable guidelines must still be observed. Undetermined means we are unable to confirm product coverage for this plan.',
                FORMULARY_STATUS_LABEL: 'This is the plan formulary status for this product when a prescription is filled for the presented days supply/quantity at this pharmacy, and is subject to change based on the dispensing pharmacy used.',
                PHARMACY_USED_LABEL: 'The presented coverage and patient cost is valid when the prescription is filled at this pharmacy.',
                ADDITIONAL_COVERAGE_DETAILS_LABEL: 'These are messages supplied by the PBM and related to additional information regarding this patient\'s coverage for this medication, and is subject to change based on when the prescription is filled and the dispensing pharmacy used.',
                TOTAL_PATIENT_COST_LABEL: 'This is the total amount the patient can expect to pay for this medication when a prescription is filled for the presented days supply/quantity at this pharmacy, and is subject to change based on when the prescription is filled and the dispensing pharmacy used.'
            },
            POLICY_COVERAGE: {
                VIEW_FULL_TRANSACTION_LINK: ' Click here to see plan details and other information specific to this patient\'s prescription plan.',
                PATIENT_COPAY_LABEL: 'This is the copayment amount the patient can expect to pay for this medication when a prescription is filled for the presented days supply/quantity at this pharmacy, and is subject to change based on when the prescription is filled and the dispensing pharmacy used.',
                OUT_OF_POCKET_LABEL: 'These are the out-of-pocket limits that will apply and be remaining when a prescription is filled for the presented days supply/quantity at this pharmacy, and is subject to change based on when the prescription is filled and the dispensing pharmacy used.',
                DEDUCTIBLE_LABEL: 'These are the deductible amounts that will apply and be remaining when a prescription is filled for the presented days supply/quantity at this pharmacy, and is subject to change based on when the prescription is filled and the dispensing pharmacy used.',
                PLAN_PAY_AMOUNT_LABEL: 'This is the amount the prescription plan will pay when a prescription is filled for the presented days supply/quantity at this pharmacy, and is subject to change based on when the prescription is filled and the dispensing pharmacy used.',
                TOTAL_COST_OF_PRODUCT_LABEL: 'This is the total cost of the product when a prescription is filled for the presented days supply/quantity at this pharmacy, and is subject to change based on when the prescription is filled and the dispensing pharmacy used.',
                PRIOR_AUTHORIZATION_STATUS_LABEL: 'Whether prior authorization is required for product coverage when a prescription is filled for the presented days supply/quantity at this pharmacy, and is subject to change based on the dispensing pharmacy used.',
                COVERAGE_LIMITATIONS_LABEL: 'These are messages supplied by the PBM related to limitations on this patient\'s coverage for this medication, and is subject to change based on when the prescription is filled and the dispensing pharmacy used.',
                PHARMACY_COVERAGE_ROW_LABEL: 'If supplied by the PBM, indicates whether the plan covers prescriptions filled at these types of pharmacies. Undetermined means the PBM did supply information for that pharmacy type.',
                COPAY_COINSURANCE_ROW_LABEL: 'If supplied by the PBM, these are the plan\'s share amounts that may be applied when a prescription is filled at these types of pharmacies.',
                COPAY_TIER_ROW_LABEL: 'If supplied by the PBM, this is the tier (out of the total number of available plan tiers) that applies when a prescription is filled at these types of pharmacies.',
                COPAY_MESSAGE_ROW_LABEL: 'If supplied by the PBM, these are additional messages related to the copayment structure for each type of pharmacy.',
                OUT_OF_POCKET_MIN_MAX_ROW_LABEL: 'If supplied by the PBM, these are the plan\'s minimum and maximum out-of-pocket limits that will apply to prescriptions filled at these types of pharmacies.',
                PLAN_COVERAGE_LIMITATION_SECTION_HEADER: 'If supplied by the PBM, these are coverage requirements and limitations specific to this patient\'s prescription plan.'
            },
            RX_MBV_FORM: {
                PBM_NAME_LABEL: 'Enter the PBM\'s name as it appears on the patient\'s insurance card.',
                PBM_PHONE_LABEL: 'Enter the PBM\'s telephone number as it appears on the patient\'s insurance card.'
            }
        },
        MED_BV_RESPONSE: {
            PRODUCT_NAME: 'You have checked this patient\'s benefits for this product.',
            POLICY_COLUMN_HEADER: 'These are the insurance policies you entered for this patient.',
            COPAY_COLUMN_HEADER: 'Whether patient will have a copayment under this policy coverage for this product.',
            COINSURANCE_COLUMN_HEADER: 'Whether patient will have to pay coinsurance under this policy coverage for this product.',
            DEDUCTIBLE_COLUMN_HEADER: 'Whether the patient\'s deductible for this policy will apply to this product.',
            MOOP_COLUMN_HEADER: 'Whether the patient\'s maximum out-of-pocket for this policy will apply to this product.',
            INSURANCE_STATUS: 'This is the status of the patient\'s policy with this carrier. Active means the patient has active insurance coverage under this policy. Inactive means the patient no longer has active coverage under this policy. Undetermined means we are unable to confirm the patient\'s coverage status for this policy.',
            PRODUCT_COVERAGE_STATUS: 'This is the patient\'s product coverage for this policy. Prior Authorization required means prior authorization must be obtained for the product to be covered. Payer Guidelines apply means prior authorization is not required, but the payer\'s medical policy and/or any other applicable guidelines must still be observed. Undetermined means we are unable to confirm product coverage for this policy.',
            POLICY_SECTION: {
                INSURANCE_STATUS: 'This is the status of the patient\'s policy with this carrier. Active means the patient has active insurance coverage under this policy. Inactive means the patient no longer has active coverage under this policy. Undetermined means we are unable to confirm the patient\'s coverage status for this policy.',
                PRODUCT_COVERAGE_STATUS: 'This is the patient\'s product coverage for this policy. Prior Authorization required means prior authorization must be obtained for the product to be covered. Payer Guidelines apply means prior authorization is not required, but the payer\'s medical policy and/or any other applicable guidelines must still be observed. Undetermined means we are unable to confirm product coverage for this policy.',
                IN_NETWORK_MESSAGE: 'The benefits presented in this response are applicable only when the treating provider is contracted with the patient\'s insurance plan/network. If the treating provider is not contracted with the patient\'s insurance plan, you may request a Manual Benefit Verification to receive out-of-network benefits.',
                PRIOR_AUTH_REQUIRED: 'Whether prior authorization is required for product coverage under this policy. Undetermined means we are unable to confirm whether prior authorization is required.',
                PHYSICIAN_PURCHASE: 'Whether product fulfillment under physician buy and bill is permitted under this policy. Undetermined means we are unable to confirm whether it is permitted.',
                SPECIALTY_PHARMACY: 'Whether product fulfillment through a specialty pharmacy is permitted under this policy. Not available means it is not permitted. Available means it is permitted but not required. Required means it is mandated. Specialty Pharmacy name and telephone displays when information is supplied by the insurance carrier.',
                CODE_COLUMN_HEADER: 'This is the product or admin code to which the cost share details apply.',
                DESCRIPTION_COLUMN_HEADER: 'This is the description of the product or admin code to which the cost share details apply.',
                COPAY_COLUMN_HEADER: 'Whether patient will have a copayment under this policy coverage for this product or admin code.',
                COINSURANCE_COLUMN_HEADER: 'Whether patient will have to pay coinsurance under this policy coverage for this product or admin code.',
                DEDUCTIBLE_COLUMN_HEADER: 'Whether the patient\'s deductible for this policy will apply to this product or admin code.',
                MOOP_COLUMN_HEADER: 'Whether the patient\'s maximum out-of-pocket for this policy will apply to this product or admin code.',
                ACCUMULATORS_PCP: 'This is the patient\'s PCP copay amount for this policy, when applicable. Unknown means this information was not supplied by the insurance carrier.',
                ACCUMULATORS_SPECIALTY: 'This is the patient\'s Specialist copay amount for this policy, when applicable. Unknown means this information was not supplied by the insurance carrier.',
                ACCUMULATORS_COINSURANCE: 'This is the patient\'s coinsurance percentage for this policy, when applicable. Unknown means this information was not supplied by the insurance carrier.',
                ACCUMULATORS_DEDUCTIBLE: 'These are the patient\'s individual and family deductibles (plan year amount and amount met to date) for this policy, when applicable. Unknown means this information was not supplied by the insurance carrier.',
                ACCUMULATORS_MOOP: 'These are the patient\'s individual and family max out-of-pocket limits (plan year amount and amount met to date) for this policy, when applicable. Unknown means this information was not supplied by the insurance carrier.'
            },
            MBV_REQUEST_SECTION: {
                PAYER_PHONE: 'Enter the insurance carrier\'s telephone number as it appears on the patient\'s insurance card.',
                PATIENT_PHONE: 'Enter the best telephone number to call the patient if we have questions during the benefit verification process.',
                PATIENT_PHONE_TYPE: 'Enter the type of telephone number you are supplying for calling the patient.',
                NPI: 'Enter the NPI (National Provider Identifier) used when submitting claims for services delivered by this provider. This can be either the provider\'s personal NPI, or the practice\'s organization NPI, depending on your organization\'s billing practices.'
            }
        },
        COB_HEADER: {
            COB: 'This is how multiple policies will coordinate benefits when processing claims for this product.'
        },
        MED_MBV_RESPONSE: {
            CONFIRMATION_NUMBER: "This is the confirmation number supplied by the insurance carrier when benefits were manually verified.",
            BV_DATE: "This is the date on which benefits were manually verified with the insurance carrier.",
            PAYER_REP_NAME: "This is the name of the insurance carrier representative who supplied the manually verified benefits.",
            PAYER_TELEPHONE_NUMBER: "This is the telephone number used for contacting the insurance carrier when benefits were manually verified.",
            COMMENTS: "This is additional information related to the manual benefit verification outcome. Click the show more/show less link to expand/collapse this section."
        },
        RX_MBV_RESPONSE: {
            CONFIRMATION: 'This is the confirmation number supplied by the PBM when benefits were manually verified.',
            BV_DATE: 'This is the date on which benefits were manually verified with the PBM.',
            PAYER_REP: 'This is the name of the PBM representative who supplied the manually verified benefits.',
            PAYER_TELEPHONE_NUMBER: 'This is the telephone number used for contacting the PBM when benefits were manually verified.',
            COMMENT: 'This is additional information related to the manual benefit verification outcome. Click the show more/show less link to expand/collapse this section.'
        },
        BV_REQUEST: {
            SWITCH_MED_BUTTON: "Click here to verify Medical benefits for this patient.",
            SWITCH_RX_BUTTON: "Click here to verify Rx benefits for this patient.",
            PATIENT_INFO: {
                SOCIAL_SECURITY: "Supplying this information will improve our ability to verify this patient's benefits.",
                PRACTICE_PATIENT_ID: "If your practice uses Patient IDs, adding it here will allow you to use it later to find a patient.",
                CONSENT: "Please confirm you have the patient's permission to use their information. A checkmark will display if confirmation has already been supplied.",
                EXISTING_PATIENT_LINK: "Click here to find an existing patient and verify their benefits.",
            },
            SERVICE_DELIVERY: {
                PRACTICE_LOCATION: "Choose the practice location where treatment will be delivered. If you don't see your location here, go to the My Practice/Locations page to add new one.",
                PRIMARY_DIAGNOSIS: "Choose the primary diagnosis the selected product will be used to treat.",
                SECONDARY_DIAGNOSIS: "Add a secondary diagnosis only if it is dependent on the patient's primary diagnosis.",
                PLACE_OF_SERVICE: "Select the place of service that best describes the location where services will be delivered.",
                ADMIN_CODE: "If applicable, select the code that will be used for product administration."
            },
            INSURANCE_INFO: {
                RANK:"Primary is the patient's primary coverage policy. Secondary is the policy that provides secondary coverage. Tertiary is the policy that provides tertiary coverage.",
                INSURANCE_CARRIER: "Choose the insurance carrier for this policy. Key in the Insurance Carrier name to jump down in the list.",
                MEMBER_ID: "Enter the patient's insurance policy Member ID as it appears on their insurance card. Do not add any special characters such as spaces, asterisks or hyphens.",
                MEDICARE_MEMBER_ID: "If the patient has a Medicare Member ID in addition to an insurance policy Member ID, enter it here.",
                MEDICARE_SUPP_PLAN_POLICY: "If this is a Medicare Supplemental policy and you know the Plan letter, enter it here. The Plan letter sometimes appears on the patient's insurance card.",
                PROVIDER_IS_IN_NETWORK: "If the Provider delivering services is contracted with this patient's insurance plan/network, select Yes. Otherwise, select No.",
                PATIENT_IS_SUBSCRIBER: "If the patient is the primary policyholder (Subscriber), select Yes. If the patient is a dependent, select No.",
                SUBSCRIBER_FIRSTNAME: "Subscriber information is optional, but will improve our ability to verify this patient's benefits. If any information is entered, all fields are required.",
                REMOVE_POLICY_LINK: "Click this link to remove this policy from your request."
            },
            PROVIDER_INFO: {
                SELECT_YOUR_PROVIDER: "Choose the patient's treating provider from this list, or click the Add New Provider link to add a new one. Key in the Provider's last name to jump down the list.",
                NEW_PROVIDER_LINK: "If the patient's treating provider isn't on the Provider list, click here to add a new one.",
                NPI: "Enter the NPI (National Provider Identifier) used when submitting claims for services delivered by this provider. This can be either the provider's personal NPI, or the practice's organization NPI, depending on your organization's billing practices.",
                STATE: "Select the state in which this provider will be delivering services. This can be either the state where the provider practices, or the state in which the organization is located, depending on your organization's billing practices.",
                TAX_ID: "This information is used for insurance carrier validation when obtaining benefit verifications. Enter the Tax ID used when submitting claims for services delivered by this provider. This can be either the provider's personal TIN, or the practice's organization TIN, depending on your organization's billing practices.",
                PRACTICE_PROVIDER_ID: "If your practice uses Provider IDs, adding it here will allow you to use it later to find a provider.",
                CHOOSE_EXISITING_PROVIDER: "Click here to return to the Provider list."
            }
        },
        PATIENT_PROFILE: {
            VIEW_INSURANCE: {
                POLICY: "Choose the insurance carrier for this policy. Key in the Insurance Carrier name to jump down in the list.",
                MEMBER_ID: "Enter the patient's insurance policy Member ID as it appears on their insurance card. Do not add any special characters such as spaces, asterisks or hyphens.",
                MEDICARE_MEMBER_ID: "If the patient has a Medicare Member ID in addition to an insurance policy Member ID, enter it here.",
                SUPPLEMENTAL_PLAN_LETTER: "If this is a Medicare Supplemental policy and you know the Plan letter, enter it here. The Plan letter sometimes appears on the patient's insurance card.",
                PATIENT_IS_SUBSCRIBER: "If the patient is the primary policyholder (Subscriber), select Yes. If the patient is a dependent, select No.",
                SUBSCRIBER_NAME: "Subscriber information is optional, but will improve our ability to verify this patient's benefits. If any information is entered, all fields are required."
            }
        },
        PATIENT: {
            INSURANCE_INFO: {
                REMOVE_POLICY_LINK: "Click this link to remove this policy."
            }
        }
    };

    angular.module('eAccess.ProviderPortal').constant('HELP_TEXT_CONSTS', VALUES);
})();
