/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.Components.ViewUser.controller:ViewUserController
 * @author Pramesh Bhattarai
 * @requires $log
 * @requires PracticeView
 * @requires HELP_TEXT_CONSTS
 * @requires ERROR_MESSAGES
 * @requires Utility
 * @requires USER_CONSTS
 * @description
 *
 * This is the controller for the Practice User View page.
 **/
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ViewUser')
        .controller('ViewUserController', ViewUserController);

    ViewUserController.$inject = ['$log', 'PracticeView', 'HELP_TEXT_CONSTS', 'ERROR_MESSAGES', 'Utility', 'USER_CONSTS', 'PAGE_TITLES', 'PracticeUserService', 'CHANGE_PASSWORD_CONSTS', 'STATUS_CONSTS', 'ToastNotificationService', 'GLOBAL_CONSTS'];

    function ViewUserController($log, PracticeView, HELP_TEXT_CONSTS, ERROR_MESSAGES, Utility, USER_CONSTS, PAGE_TITLES, PracticeUserService, CHANGE_PASSWORD_CONSTS, STATUS_CONSTS, ToastNotificationService, GLOBAL_CONSTS) {
        var vm = this;

        vm.pageTitle = PAGE_TITLES.PRACTICE.BASE + PAGE_TITLES.USER.USERS;
        vm.getUserRoleToolTip = getUserRoleToolTip;
        vm.errorMessages = {
            notAvailable: ERROR_MESSAGES.NOT_AVAILABLE
        };

        vm.viewService = PracticeView;


        // function
        vm.$onInit = function() {
            if(!Utility.isEmpty(vm.viewService.changePasswordLink) && !Utility.isEmpty(vm.viewService.changePasswordLink.passwordChanged) && vm.viewService.changePasswordLink.passwordChanged) {
                ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true,
                    CHANGE_PASSWORD_CONSTS.SUCCESS_MESSAGE.PASSWORD_CHANGED_HEADER,
                    CHANGE_PASSWORD_CONSTS.SUCCESS_MESSAGE.PASSWORD_CHANGED_MESSAGE,
                    GLOBAL_CONSTS.TOAST_TYPES.SUCCESS));
            }

            //initializing default tab for user section which is 3
            var tabIndex = 3;
            vm.user.name = Utility.getFullName(vm.user);
            if(vm.myAccount) {
                tabIndex = 4;
                vm.pageTitle = PAGE_TITLES.PRACTICE.BASE;
                vm.contentTitle = PAGE_TITLES.USER.MY_ACCOUNT;
                vm.resetPasswordText = '';
                vm.changePasswordText = USER_CONSTS.CHANGE_PASSWORD;
                vm.user.status = STATUS_CONSTS.STATUS.ACTIVE;
            } else {
                vm.changePasswordText = '';
                vm.resetPasswordText = USER_CONSTS.RESET_PASSWORD;
                vm.contentTitle = vm.user.name;
                $log.info(vm.user);
            }
            PracticeView.configure({
                title: vm.pageTitle,
                contentTitle: vm.contentTitle,
                tabIndex: tabIndex,
                editText: 'edit',
                editHref: 'practice.users.edit',
                editId: vm.user.userId,
                editViewableStatus: Utility.isStatusActive(vm.user.status),
                user: vm.user,
                resetPasswordText: vm.resetPasswordText,
                passwordChanged: false,
                changePasswordText: vm.changePasswordText,
                changePasswordHref: 'changePassword' // state name of changePassword state
            });
        };

        function _isUserAdmin() {
            return !Utility.isEmpty(vm.user) && (Utility.lowerCase(vm.user.userRole) === Utility.lowerCase(USER_CONSTS.USER_ROLES.ADMIN));
        }

        function getUserRoleToolTip() {
            return _isUserAdmin() ? HELP_TEXT_CONSTS.USER.ROLE : HELP_TEXT_CONSTS.USER.OWN_ROLE;
        }

    }
})();
