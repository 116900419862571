(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.SOB')
        .controller('SOBController', SOBController);

    SOBController.$inject = ['$log', 'RxEligibilityService', 'Utility', 'SOBService', 'ERROR_MESSAGES', 'STATUS_CONSTS'];

    function SOBController($log, RxEligibilityService, Utility, SOBService, ERROR_MESSAGES, STATUS_CONSTS) {
        var vm = this;

        // data
        vm.isSOBDisplayable = false;

        // functions
        vm.openPdf = openPdf;
        vm.setDisplaySOB = setDisplaySOB;

        vm.$onInit = function () {
            setDisplaySOB();
        };

        function openPdf() {
            if (!Utility.isEmpty(vm.mbvId)) {
                var param = {
                    bvAdjudicationType: vm.bvAdjudicationType
                };
                SOBService.getSobFile(vm.mbvId, param).then(function (response) {
                    var data = Utility.base64ToArrayBuffer(response);
                    Utility.openPdf(data);
                }).catch(function (err) {
                    $log.debug('Error while fetching sob file', err);
                    var errorMessage = (!Utility.isEmpty(err.data) && !Utility.isEmpty(err.data.message)) ? err.data.message : ERROR_MESSAGES.GENERIC_ERROR;
                    vm.sobErrorMessageCallback(errorMessage);
                });
            }
        }

        /**
         * Set isSOBDisplayable value true if coverageStatus is covered or prior authorization required
         */
        function setDisplaySOB() {
            vm.isSOBDisplayable = Utility.lowerCase(vm.coverageStatus) === Utility.lowerCase(STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.COVERED)
                || Utility.lowerCase(vm.coverageStatus) === Utility.lowerCase(STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.PRIOR_AUTHORIZATION_REQUIRED);
        }
    }
})();
