(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('practice.locations.view', {
                url: '/locations/view/{locationId}',
                parent: 'practice',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Practice Location'
                },
                views: {
                    'practiceContent': {
                        templateUrl: 'components/practice/locations/view/practice-locations-view.html',
                        controller: 'PracticeLocationViewController as locationViewCtrl'
                    }
                },
                resolve: {
                    locationResponse: ['PracticeLocationService', '$stateParams', 'Utility', function(PracticeLocationService, $stateParams, Utility) {
                        return Utility.getById.call(PracticeLocationService.getByLocationId($stateParams.locationId), 'practice.locations');
                    }]
                }
            });
    }
})();
