(
    function() {
        'use strict';

        angular
            .module('eAccess.ProviderPortal.Components.Modals')
            .controller('PasswordExpiryController', PasswordExpiryController);

        PasswordExpiryController.$inject = ['modalTitle', 'modalBody', 'okButtonText', 'cancelButtonText', '$uibModalInstance'];

        function PasswordExpiryController(modalTitle, modalBody, okButtonText, cancelButtonText, $uibModalInstance){
            var vm = this;
            vm.closeModal = closeModal;
            vm.cancelModal = cancelModal;

            vm.$onInit = function() {
                vm.modalTitle = modalTitle;
                vm.modalBody = modalBody;
                vm.okButtonText = okButtonText;
                vm.cancelButtonText = cancelButtonText;
            };

            function closeModal() {
                $uibModalInstance.close();
            }

            function cancelModal() {
                $uibModalInstance.dismiss();
            }
        }
    }
)();
