(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Pharmacy')
        .controller('PharmacyController', PharmacyController);

    // inject services here.
    PharmacyController.$inject = ['$uibModal', 'PharmacyService', 'PHARMACY_CONSTS', 'Storage'];

    function PharmacyController($uibModal, PharmacyService, PHARMACY_CONSTS, Storage) {
        var vm = this;

        // data
        vm.val = false;
        vm.preferredSelectedPharmacy = {};
        vm.pharmacyType = {
            selectedPharmacy: PHARMACY_CONSTS.PHARMACY_SELECTION_OPTIONS.IN_NETWORK
        };
        vm.pharmaciesObject = {};
        vm.selectedPharmacyId = "";
        vm.selectedPharmacyType = "";

        // functions
        vm.openPharmacySearchModal = openPharmacySearchModal;
        vm.searchPharmacies = searchPharmacies;
        vm.openPharmacyListModal = openPharmacyListModal;
        vm.setPageLimitAndOffset = setPageLimitAndOffset;
        vm.radioSelectedChanged = radioSelectedChanged;

        function searchPharmacies(params) {
            setPageLimitAndOffset(params);
            return PharmacyService.getPharmacies(params).then(function (pharmacies) {
                vm.pharmaciesObject = pharmacies;
            });
        }

        function openPharmacySearchModal() {
            var modalInstance = $uibModal.open({
                animation: true,
                backdrop: false,
                size: 'lg',
                templateUrl: 'partials/templates/pharmacy-search-modal.html',
                controller: 'PharmacySearchModalController',
                controllerAs: 'pharmacySearchCtrl',
                appendTo: angular.element('body'),
                windowClass : "compress-modal",
                resolve: {
                    searchPharmacies: function () {
                        return vm.searchPharmacies;
                    }
                }
            });

            modalInstance.result.then(function (response) {
                if (response) {
                    openPharmacyListModal();
                }
            });
            return modalInstance;
        }

        function openPharmacyListModal() {
            var modalInstance = $uibModal.open({
                animation: true,
                backdrop: false,
                size: 'lg',
                templateUrl: 'partials/templates/pharmacy-list-modal.html',
                controller: 'PharmacyListModalController',
                controllerAs: 'pharmacyListCtrl',
                appendTo: angular.element('body'),
                resolve: {
                    pharmacyList: function () {
                        return vm.pharmaciesObject;
                    }
                }
            });

            modalInstance.result.then(function (response) {
                if (response.event === PHARMACY_CONSTS.PHARMACY_SELECTED) {
                    vm.preferredSelectedPharmacy = response.pharmacyObject;
                    vm.selectedPharmacyId = response.pharmacyObject.pharmacyNCPDPID;
                    vm.pharmacyType.selectedPharmacy = PHARMACY_CONSTS.PHARMACY_SELECTION_OPTIONS.PREFERRED;
                    vm.selectedPharmacyType = vm.pharmacyType.selectedPharmacy;
                    Storage.clearLocalData(PHARMACY_CONSTS.SEARCH_ITEMS);
                }
                else if (response.event === PHARMACY_CONSTS.SEARCH_AGAIN) {
                    openPharmacySearchModal();
                } else {
                    Storage.clearLocalData(PHARMACY_CONSTS.SEARCH_ITEMS);
                }
            });
            return modalInstance;
        }

        function setPageLimitAndOffset(params) {
            params.maxResult = PHARMACY_CONSTS.PAGING_CONFIG.PAGE_SIZE;
            params.pageNumber = PHARMACY_CONSTS.PAGING_CONFIG.PAGE_NUMBER;
        }

        function radioSelectedChanged(value) {
            vm.selectedPharmacyType = value;
        }
    }
})();
