/**
 * Created by nikesh on 1/11/2018.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .controller('PracticeUserEditController', PracticeUserEditController);

    PracticeUserEditController.$inject = ['PracticeView', 'PAGE_TITLES', 'USER_CONSTS', 'userResponse'];

    function PracticeUserEditController(PracticeView, PAGE_TITLES, USER_CONSTS, userResponse) {
        var vm = this;

        // data
        vm.user = {};
        vm.pageTitle = PAGE_TITLES.PRACTICE.BASE + PAGE_TITLES.USER.USERS;
        vm.contentTitle = PAGE_TITLES.USER.EDIT_USER;
        vm.isSingleStep = true;
        vm.changePasswordText = '';


        // functions
        vm.$onInit = function() {
            vm.myAccount = userResponse.myAccount;
            vm.user = userResponse.response;
            //initializing default tab for user section which is 3
            var tabIndex = 3;
            if(vm.myAccount) {
                tabIndex = 4;
                vm.changePasswordText = USER_CONSTS.CHANGE_PASSWORD;
                vm.pageTitle = PAGE_TITLES.PRACTICE.BASE;
                vm.contentTitle = PAGE_TITLES.USER.EDIT_ACCOUNT;
            }
            PracticeView.configure({
                title: vm.pageTitle,
                contentTitle: vm.contentTitle,
                tabIndex: tabIndex,
                changePasswordText: vm.changePasswordText,
                changePasswordHref: 'changePassword'
            });
        };
    }
})();
