(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Dashboard', [
            'eAccess.ProviderPortal.Components.Search',
            'eAccess.ProviderPortal.Services.Dashboard',
            'eAccess.ProviderPortal.Widgets',
            'eAccess.ProviderPortal.Constants.DashboardConstants'
        ]);
})();
