(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Pharmacy')
        .component('eaPharmacy', {
            templateUrl: 'components/pharmacy/pharmacy.html',
            controller: 'PharmacyController as pharmacyCtrl',
            bindings: {
                parentForm: '=',
                selectedPharmacyId: '=',
                selectedPharmacyType: '=',
                isAio: '='
            }
        });

})();
