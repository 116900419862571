(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.SOBHeader')
        .component('eaSobHeader', {
            templateUrl: 'components/sob-header/sob-header.html',
            controller: 'SOBHeaderController as sobHeaderCtrl',
            bindings: {
                bvType: '<',
                bvStatus: '<',
                // Object that contains sobIdentifier object
                documentIdentifiers: '<',
                coverageStatus: '<',
                mbvId: '<',
                bvAdjudicationType: '<', // Medical or Rx
                sobErrorMessageCallback: '<' // call back function for set sob error message
            }
        });

})();
