(function() {
    'use strict';

    var VALUES = {
        ALERT_MESSAGE: 'Due to several unsuccessful login attempts, you must set a new password.',
        INFORMATION: 'We have sent you an email with instructions for setting your password. If you do not see the email in your inbox in a few minutes, check your junk or spam mailbox.',
        EMAIL_RESEND_MESSAGE: 'A new email has been sent. Please check your inbox. If you have received multiple emails, remember to click the link in the most recent one (older links are no longer valid).'
    };

    angular.module('eAccess.ProviderPortal').constant('ACCOUNT_LOCKED_CONSTS', VALUES);
})();
