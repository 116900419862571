/**
 * Created by pramesh on 10/24/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Modals')
        .controller('ForgotUsernameModalController', ForgotUsernameModalController);

    ForgotUsernameModalController.$inject = ['$log', '$uibModalInstance', 'titleText', 'message', 'assistanceMessage'];

    function ForgotUsernameModalController($log, $uibModalInstance, titleText, message, assistanceMessage) {

        var vm = this;
        vm.titleText = titleText;
        vm.message = message;
        vm.assistanceMessage = assistanceMessage;

        vm.closeModal = closeModal;

        function closeModal() {
            $uibModalInstance.close();
        }

    }

})();
