/**
 * Created by aayush.regmi on 10/17/2017.
 */

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PracticeSetup')
        .component('eaPracticeSetupComplete', {
            templateUrl: 'components/practice-setup/complete/practice-setup-complete.html',
            controller: 'PracticeSetupCompleteController as setupComplete'
        });

})();
