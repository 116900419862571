/**
 * Created by pramesh on 2/8/2018.
 */
(function() {
    'use strict';

    var VALUES = {
        PROVIDER_TABLE: 'providerTable',
        BV_TABLE: 'bvTable',
        LOCATION_TABLE: 'locationTable',
        PATIENT_TABLE: 'patientTable',
        USER_TABLE: 'userTable',
        REVERIFICATION_TABLE: 'reverificationTable'
    };

    angular.module('eAccess.ProviderPortal.Constants.TableName', []).constant('TABLE_NAME_CONSTS', VALUES);
})();
