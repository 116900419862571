(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .factory('PracticeLocationModel', PracticeLocationModel);

    PracticeLocationModel.$inject = ['$log'];

    function PracticeLocationModel($log) {

        var _defaultLocation = {
            active: null,
            address1 : null,
            address2 : null,
            id : null,
            city : null,
            locationName : null,
            mainPhone : null,
            npi : null,
            practice : null,
            practiceLocationId : null,
            state : null,
            taxId : null,
            zipCode : null,
            mainFax : null
        };

        function PracticeLocationModelInstance(options) {
            angular.extend(this, _defaultLocation, options);
            if (options) {
                this.id = options.locationId;
            }
        }

        return PracticeLocationModelInstance;

    }

})();
