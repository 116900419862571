/**
 * @ngdoc Component
 * @module eAccess.ProviderPortal.Components.RxEligibilityResponse
 * @name eaProductCoverageResponse
 * @kind Component
 *
 * @description
 * Product Coverage Section Block. It takes in product coverage information and renders
 * to the view
 *
 * Basic Usage
 * <ea-product-coverage-response ></ea-product-coverage-response>
 *
 * @author girianish
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .component('eaProductCoverageResponse', {
            bindings:{
                status: '<',
                coverage: '<',
                toolTip: '<',
                getDisplayValue: '<'
            },
            templateUrl: 'components/eligibility/bv-selection/rx/rx-response/policy-coverage/product-coverage-response/product-coverage-response.html',
            controller: 'ProductCoverageResponseController as productCoverageCtrl'
        });
})();
