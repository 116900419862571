/**
 * Created by aayush.regmi on 1/14/2018.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Services.EmailService', [])
        .factory('EmailService', EmailService);

    EmailService.$inject = ['$log', 'API'];

    function EmailService($log, API) {
        var service = {
            sendEmail: sendEmail
        };

        function sendEmail(emailRequest) {
            return API.post('users/notifications', emailRequest);
        }

        return service;
    }
})();
