(function () {
    'use strict';

    var VALUES = {
        PAGING_CONFIG: {
            PAGE_NUMBER: 0,
            PAGE_SIZE: 5,
            PAGE_SIZE_OPTIONS: [5, 10, 15]
        },
        SEARCH_AGAIN: 'Search Again',
        SEARCH_ITEMS: 'Search Items',
        OPEN_LIST_MODAL: 'Open List Modal',
        PHARMACY_SELECTED: 'Pharmacy Selected',
        PHARMACY_SELECTION_OPTIONS: {
            IN_NETWORK: 'inNetwork',
            PREFERRED: 'preferred'
        }
    };

    angular.module('eAccess.ProviderPortal.Constants.Pharmacy', []).constant('PHARMACY_CONSTS', VALUES);
})();
