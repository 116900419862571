
(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PasswordExpired')
        .controller('PasswordExpiredController', PasswordExpiredController);

    PasswordExpiredController.$inject = ['PASSWORD_EXPIRED_CONSTS', 'ConfigurationService', 'EmailService', '$stateParams', 'GLOBAL_CONSTS'];

    function PasswordExpiredController(PASSWORD_EXPIRED_CONSTS, ConfigurationService, EmailService, $stateParams, GLOBAL_CONSTS) {
        var vm = this;

        vm.pageTitle = PASSWORD_EXPIRED_CONSTS.PAGE_TITLE;
        vm.errorMessage = PASSWORD_EXPIRED_CONSTS.ERROR_MESSAGE;
        vm.message = PASSWORD_EXPIRED_CONSTS.MESSAGE;
        vm.additionalMessage = GLOBAL_CONSTS.EMAIL.USER_NOTIFICATION;
        vm.instruction = PASSWORD_EXPIRED_CONSTS.EMAIL_INSTRUCTION;
        vm.emailSendMessage = GLOBAL_CONSTS.EMAIL.RESENT_MESSAGE;
        vm.passwordExpiredResponseObject = $stateParams.passwordExpiredResponseObject;
        vm.emailSend = false;

        // functions
        vm.sendPasswordExpiredEmail = sendPasswordExpiredEmail;

        init();

        function init() {
            getCustomerSupport();
        }

        function sendPasswordExpiredEmail(){
            EmailService.sendEmail(vm.passwordExpiredResponseObject).then(function() {
                vm.emailSend = true;
            }).catch(function() {
                vm.emailSend = false;
            });

        }

        function getCustomerSupport() {
            ConfigurationService.fetchRegistrationConfiguration()
                .then(function(response) {
                    vm.customerSupport = response.customerSupport;
                })
                .catch(function() {
                    vm.customerSupport = '';
                });
        }
    }
})();
