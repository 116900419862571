/**
 * @ngdoc Component
 * @module eAccess.ProviderPortal.Components.RxEligibilityResponse
 * @name eaProviderInformationResponse
 * @kind Component
 *
 * @description
 * Provider Information Section Block. It takes in provider information and renders
 * to the view
 *
 * Basic Usage
 * <ea-provider-information-response provider-information="passproviderinformation"></ea-provider-information-response>
 *
 * @author girianish
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .component('eaProviderInformationResponse', {
            bindings: {
                providerInformation: "<"
            },
            templateUrl: 'components/eligibility/bv-selection/rx/rx-response/request-details/provider-information-response/provider-information-response.html',
            controller: 'ProviderInformationResponseController as providerInfoCtrl'
        });
})();
