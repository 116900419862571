/**
 * Created by pramesh on 7/10/2018.
 */
(function () {
    'use strict';

    angular.module('eAccess.ProviderPortal.Directives').directive('eaUserEvent', eaUserEvent);

    eaUserEvent.$inject = ['Storage', 'Utility', 'UserActivityTimer'];

    function eaUserEvent(Storage, Utility, IdleTimer) {
        return {
            restrict: 'A',
            scope: true,
            link: function (scope, element, attrs) {
                var events = ['click', 'mousedown', 'mouseup', 'mouseover', 'mouseout', 'mousemove', 'mouseenter', 'mouseleave', 'keydown', 'keyup', 'keypress'];
                events.forEach(function (event) {
                    element.on(event, function () {
                        var idToken = Storage.getSessionItem('id_token'),
                            authToken = Storage.getSessionItem('auth_token');
                        if (!Utility.isEmpty(idToken) && !Utility.isEmpty(authToken)) {
                            IdleTimer.setLastActiveTime();
                        }
                    });
                });
            }
        };
    }
})();
