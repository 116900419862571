/**
 * @ngdoc Model Service
 * @module eAccess.ProviderPortal.Components.Search
 * @name SearchCallBackModel
 * @kind Factory
 *
 * @description
 * This Model is used for modeling search callback data required
 * in the controller.
 * @author girianish
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Search')
        .factory('SearchCallbackModel', SearchCallbackModel);

    SearchCallbackModel.$inject = ['SearchPaginationModel'];

    function SearchCallbackModel(SearchPaginationModel) {
        function SearchCallbackInstance(search, pagination, selectedFacet, query, formattedStartDate, formattedEndDate) {
            //Search Promise
            this.search = search || null;
            this.selectedFacet = selectedFacet || null;
            this.pagination = pagination || new SearchPaginationModel();
            this.query = query || null;
            this.formattedStartDate = formattedStartDate || null;
            this.formattedEndDate = formattedEndDate || null;
        }

        return SearchCallbackInstance;
    }

})();
