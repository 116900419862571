(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Dashboard')
        .controller('DashboardController', DashboardController);

    DashboardController.$inject = ['$log', 'PatientService', 'DashboardService', 'ERROR_MESSAGES', 'STATUS_CONSTS', 'SEARCH_CONSTS', 'DASHBOARD_CONSTS', 'ConfigurationService', '$stateParams', 'Utility', 'ToastNotificationService', 'GLOBAL_CONSTS', 'SystemNotificationService', 'ENV', 'BRAND_IMAGE_CONSTS', '$window'];

    function DashboardController($log, PatientService, DashboardService, ERROR_MESSAGES, STATUS_CONSTS, SEARCH_CONSTS, DASHBOARD_CONSTS, ConfigurationService, $stateParams, Utility, ToastNotificationService, GLOBAL_CONSTS, SystemNotificationService, ENV, BRAND_IMAGE_CONSTS, $window) {

        var vm = this;

        // data
        vm.totalPatients = ERROR_MESSAGES.NOT_AVAILABLE;

        vm.recentlyCompletedBvPatients = [];

        vm.searchFacets = [
            {
                searchCategory: SEARCH_CONSTS.SEARCH_FACETS.PATIENT.SEARCH_CATEGORY.PATIENT_LAST_NAME,
                placeholderText: SEARCH_CONSTS.SEARCH_FACETS.PATIENT.PLACE_HOLDER_TEXT.LAST_NAME,
                value: SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.LAST_NAME
            },
            {
                searchCategory: SEARCH_CONSTS.SEARCH_FACETS.PATIENT.SEARCH_CATEGORY.PRACTICE_PATIENT_ID,
                placeholderText: SEARCH_CONSTS.SEARCH_FACETS.PATIENT.PLACE_HOLDER_TEXT.PRACTICE_PATIENT_ID,
                value: SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.PRACTICE_PATIENT_ID_MODAL
            },
            {
                searchCategory: SEARCH_CONSTS.SEARCH_FACETS.PATIENT.SEARCH_CATEGORY.DOB,
                placeholderText: SEARCH_CONSTS.SEARCH_FACETS.PATIENT.PLACE_HOLDER_TEXT.DATE_OF_BIRTH,
                value: SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.DATE_OF_BIRTH
            }
        ];

        vm.patientSearchType = SEARCH_CONSTS.SEARCH_TYPE.DASHBOARD;

        vm.currentDate = new Date();
        vm.dashboardWidgetLogoImage = ENV.API_URL + BRAND_IMAGE_CONSTS.API + BRAND_IMAGE_CONSTS.KEYS.DASHBOARD_WIDGET_LOGO;
        vm.orgPropertiesMap = null;
        vm.className = 'col-md-4';

        // functions
        vm.loadUsefulLinks = loadUsefulLinks;
        vm.openPDF = openPDF;
        vm.showWidget = _showWidget;

        function init() {
            _getTotalPatients();
            loadUsefulLinks();
            if($stateParams.forbidden) {
                ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, ERROR_MESSAGES.ACCESS_DENIED, ERROR_MESSAGES.NO_ACCESS_MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.ERROR));
            }
            if(SystemNotificationService.showPortalNotification){
                SystemNotificationService.displayInteriorNotification();
            }
            _setOrgPropertiesMap();
        }

        function _getTotalPatients() {
            PatientService.getPatientCount(STATUS_CONSTS.STATUS.ACTIVE).then(function(patientListLength) {
                vm.totalPatients = patientListLength;
            }).catch(function(err) {
                $log.error('error fetching patient list for dashboard widget', err);
                vm.totalPatients = ERROR_MESSAGES.NOT_AVAILABLE;
            });
        }

        /**
         * Function that fetches the useful links and populates it into usefulLinks list.
         */
        function loadUsefulLinks() {
            vm.usefulLinks = [];
            DashboardService.fetchUsefulLinks().then(function(response) {
                vm.usefulLinks = response;
            });
        }

        function openPDF(link) {
            if(!link.url) {
                var fetchPDFFor = '';
                switch(link.label) {
                    case DASHBOARD_CONSTS.USEFUL_LINKS.LABEL.BENEFIT_VERIFICATION:
                        fetchPDFFor = DASHBOARD_CONSTS.USEFUL_LINKS.API_ENDPOINT.BENEFIT_VERIFICATION;
                        break;
                    case DASHBOARD_CONSTS.USEFUL_LINKS.LABEL.DASHBOARD_GUIDE:
                        fetchPDFFor = DASHBOARD_CONSTS.USEFUL_LINKS.API_ENDPOINT.DASHBOARD_GUIDE;
                        break;
                    default:
                        return false;
                }
                var url = Utility.getFileURLForKey(fetchPDFFor);
                Utility.openPdf(url, true);
                $log.log('PDF opened');
            } else {
                Utility.openPdf(link.url, true);
                $log.log('URL opened');
            }
        }

        function _setOrgPropertiesMap() {
            ConfigurationService.fetchPortalConfiguration().then(function (response) {
                if (!Utility.isEmpty(response) && !Utility.isEmpty(response.orgPropertiesMap)) {
                    vm.orgPropertiesMap = response.orgPropertiesMap;
                    vm.className = _getClassNameForFirstRowWidgets();
                }
            });
        }

        function _getClassNameForFirstRowWidgets() {
            var count = 0;
            _showWidget(vm.orgPropertiesMap.manualBvsInProgress) ? ++count : '';
            _showWidget(vm.orgPropertiesMap.bvsCompletedThisWeek) ? ++count : '';
            _showWidget(vm.orgPropertiesMap.activePatients) ? ++count : '';
            _showWidget(vm.orgPropertiesMap.bvsCompletedThisMonth) ? ++count : '';
            return count > 0 ? 'col-md-' + (12 / count) : 'col-md-4';
        }

        function _showWidget(value) {
            return value === DASHBOARD_CONSTS.ONE;
        }

        init();
    }
})();
