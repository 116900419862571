(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Login')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router ($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('login', {
                url: '/login',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Login'
                },
                views: {
                    'content@': {
                        templateUrl: 'components/login/login.html',
                        controller: 'LoginController as login'
                    }
                },
                params: {
                    errorMessage: null,
                    sessionExpired: null
                }
            });
    }
})();
