(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Modals')
        .controller('DeactivateReactivateItemModalController', DeactivateReactivateItemModalController);

    DeactivateReactivateItemModalController.$inject = ['$log', '$uibModalInstance', 'titleText', 'subTitleText', 'message', 'isDeactivate'];

    function DeactivateReactivateItemModalController($log, $uibModalInstance, titleText, subTitleText, message, isDeactivate) {

        var vm = this;

        vm.titleText = titleText;
        vm.subTitleText = subTitleText;
        vm.message = message;
        vm.isDeactivate = isDeactivate;

        vm.closeModal = closeModal;
        vm.confirmAction = confirmAction;
        vm.getHeaderClass = getHeaderClass;

        function getHeaderClass() {
            return  vm.isDeactivate ? 'deactivate-title' : 'activate-title';
        }

        function closeModal() {
            $uibModalInstance.close(false);
        }

        function confirmAction() {
            $uibModalInstance.close(true);
        }

    }

})();
