(function() {
    'use strict';

    var eaRecentlyExpiredBvList = {
        templateUrl: 'components/dashboard/widgets/recently-expired-bv-list/recently-expired-bv-list.html',
        controller: 'RecentlyExpiredBvListController as recentlyExpiredListCtrl'
    };

    angular
        .module('eAccess.ProviderPortal.Widgets.RecentlyExpiredBvList')
        .component('eaRecentlyExpiredBvList', eaRecentlyExpiredBvList);
})();
