(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Footer')
        .component('eaFooter', {
            templateUrl: 'components/footer/footer.html',
            controller: 'FooterController as footer'
        });
})();
