(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Patient')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('patient.all', {
                parent: 'patient',
                url: '', // NOTE: Intentionally blank to use parent url "/patient"
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Patients'
                },
                views: {
                    'patientContent': {
                        templateUrl: 'components/patient/all/patient-all.html',
                        controller: 'PatientAllController as patients'
                    },
                    // Cached toast template file, this is necessary to display toast after browser offline
                    // http://cmikavac.net/2015/12/01/pre-caching-html-templates-with-angularjs-ui-router-directive/
                    cachedToastTemplate: {
                        templateUrl: 'components/toast-notification/toast-notification.html'
                    }
                },
                params: {
                    invalidUrl: false,
                    patientAdded: false
                }
            });
    }
})();
