(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse', [
            'eAccess.ProviderPortal.Services.RxEligibilityResponse',
            'eAccess.ProviderPortal.Services.StatusStyles',
            'eAccess.ProviderPortal.Services.Policy',
            'eAccess.ProviderPortal.Services.PolicyPlanDetails'
        ]);
})();
