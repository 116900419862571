/**
 * Created by mcaputo on 3/13/17.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ServiceDeliveryInformation')
        .factory('POSModel', POSModel);

    POSModel.$inject = ['$log'];

    function POSModel($log) {
        function POSInstance(id, name, code) {
            this.id = id || null;
            this.name = name || null;
            this.code = code || false;
        }

        return POSInstance;
    }
})();
