(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Patient')
        .factory('PatientReactivateDeactivate', PatientReactivateDeactivate);

    PatientReactivateDeactivate.$inject = ['$log', 'ReactivateDeactivate', 'PATIENT_CONSTS', 'Utility'];

    function PatientReactivateDeactivate($log, ReactivateDeactivate, PATIENT_CONSTS, Utility) {
        
        var PatientReactivateDeactivateData = {
            reactivate : {
                multipleMessage: PATIENT_CONSTS.CONFIRMATION_MESSAGE.REACTIVATE_MULTIPLE_PATIENT,
                singleMessage:  PATIENT_CONSTS.CONFIRMATION_MESSAGE.REACTIVATE_ONE_PATIENT,
                replaceString: "#patients"
            },
            deactivate: {
                multipleMessage: PATIENT_CONSTS.CONFIRMATION_MESSAGE.DEACTIVATE_MULTIPLE_PATIENT,
                singleMessage: PATIENT_CONSTS.CONFIRMATION_MESSAGE.DEACTIVATE_ONE_PATIENT,
                replaceString: "#patients"
            }
        };

        Utility.extend(PatientReactivateDeactivateInstance, ReactivateDeactivate);

        function PatientReactivateDeactivateInstance() {
            ReactivateDeactivate.call(this, PatientReactivateDeactivateData);
        }

        function _setSubTitleText() {
            this.modalSubTitle = (this.list.length === 1) ? Utility.getFullName(this.list[0]) : '';
        }

        PatientReactivateDeactivateInstance.prototype.setModalSubTitle = function (locationList) {
            _setSubTitleText.call(this, locationList);
        };

        return PatientReactivateDeactivateInstance;
    }
})();
