( function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Patient.Profile')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('patient.profile', {

                /**
                 * "/{id}/" is changed to "/profile/{portalPatientId}"
                 * this is to prevent application from redirecting to "/{portalPatientId}" even when intended url is "/add"
                 */
                //
                url: '/profile/{portalPatientId}',
                parent: 'patient',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Patient Profile'
                },
                views: {
                    'patientContent': {
                        templateUrl: 'components/patient/profile/patient-profile.html',
                        controller: 'PatientProfileController as profile'
                    },
                    // Cached toast template file, this is necessary to display toast after browser offline
                    // http://cmikavac.net/2015/12/01/pre-caching-html-templates-with-angularjs-ui-router-directive/
                    cachedToastTemplate: {
                        templateUrl: 'components/toast-notification/toast-notification.html'
                    }
                },
                params: {
                    portalPatientId: null
                },
                resolve: {
                    patientData: ['PatientService', '$stateParams', 'Utility', function(PatientService, $stateParams, Utility) {
                        return Utility.getById.call(PatientService.getByPortalPatientId($stateParams.portalPatientId), 'patient.all');
                    }]
                }
            });
    }
})();
