/**
 * This service holds list of states accessible by non-admin user.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Services.AllowedState')
        .factory('AllowedStateService', AllowedStateService);

    AllowedStateService.$inject = ['ALLOWED_STATE_CONSTS'];

    function AllowedStateService(ALLOWED_STATE_CONSTS) {

        var service = {
            allowedStates: [
                ALLOWED_STATE_CONSTS.DASHBOARD,
                ALLOWED_STATE_CONSTS.MED_ELIGIBILITY,
                ALLOWED_STATE_CONSTS.ELIGIBILITY_RESPONSE,
                ALLOWED_STATE_CONSTS.RX_ELIGIBILITY,
                ALLOWED_STATE_CONSTS.RX_ELIGIBILITY_RESPONSE,
                ALLOWED_STATE_CONSTS.RX_ELIGIBILITY_SPLIT_RESPONSE,
                ALLOWED_STATE_CONSTS.RX_DATA_CONFLICT,
                ALLOWED_STATE_CONSTS.BV_SELECTION,
                ALLOWED_STATE_CONSTS.BV_HISTORY,
                ALLOWED_STATE_CONSTS.REVERIFICATION,
                ALLOWED_STATE_CONSTS.PATIENT_ALL,
                ALLOWED_STATE_CONSTS.PRACTICE_INFORMATION,
                ALLOWED_STATE_CONSTS.ADD_LOCATION,
                ALLOWED_STATE_CONSTS.EDIT_LOCATION,
                ALLOWED_STATE_CONSTS.VIEW_LOCATION,
                ALLOWED_STATE_CONSTS.LIST_LOCATION,
                ALLOWED_STATE_CONSTS.ADD_PROVIDER,
                ALLOWED_STATE_CONSTS.EDIT_PROVIDER,
                ALLOWED_STATE_CONSTS.VIEW_PROVIDER,
                ALLOWED_STATE_CONSTS.LIST_PROVIDER,
                ALLOWED_STATE_CONSTS.ADD_PATIENT,
                ALLOWED_STATE_CONSTS.PATIENT_PROFILE,
                ALLOWED_STATE_CONSTS.USER_VIEW,
                ALLOWED_STATE_CONSTS.CHANGE_PASSWORD,
                ALLOWED_STATE_CONSTS.USER_EDIT,
                ALLOWED_STATE_CONSTS.LOGIN
            ],
            isStateAccessible: isStateAccessible
        };

        /**
         * This function returns true if state is present in allowedStates list (i.e accessible by non-admin user)
         * @param state
         * @returns {boolean}
         */
        function isStateAccessible(state) {
            return service.allowedStates.indexOf(state) >= 0;
        }

        return service;
    }
})();
