/**
 * Created by mcaputo on 2/9/17
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Directives')
        .directive('eaZipcodeValid', EaZipcodeValid);

    EaZipcodeValid.$inject = ['$log', 'ELIGIBILITY_CONSTS'];

    // This directive validates a zip code.
    // A valid zipcode has exactly 4 or exactly 9 digits
    function EaZipcodeValid($log, ELIGIBILITY_CONSTS) {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function(scope, element, attrs, modelCtrl) {
                modelCtrl.$parsers.push(function (ngModelVal) {
                    if(ngModelVal.length > 5) {
                        ngModelVal = ngModelVal.substring(0, 5);
                        modelCtrl.$setViewValue(ngModelVal);
                    }
                    var validZipLength = ELIGIBILITY_CONSTS.VALID_ZIP_LENGTHS.indexOf(ngModelVal.length) !== -1;
                    modelCtrl.$setValidity('zipcode', validZipLength);
                    modelCtrl.$render();

                    return ngModelVal;
                });

                modelCtrl.$parsers.push(function(ngModelVal) {
                    var numOnlyRegex = new RegExp(/[^0-9]/g);
                    var cleanInputValue = ngModelVal.replace(numOnlyRegex, '');

                    if (cleanInputValue !== ngModelVal) {
                        modelCtrl.$setViewValue(cleanInputValue);
                        modelCtrl.$render();
                    }

                    return cleanInputValue;
                });
            }
        };
    }

})();
