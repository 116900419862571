/**
 * @ngdoc Controller
 * @module eAccess.ProviderPortal.Components.RxEligibilityResponse
 * @name RxEligibilityResponseController
 * @kind Controller
 *
 * @description
 * Controller for the RxBv Response
 *
 * @author girianish
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .controller('RxEligibilityResponseController', RxEligibilityResponseController);

    RxEligibilityResponseController.$inject = ['$log', 'Utility', 'Eligibility', '$stateParams', 'STATUS_CONSTS', 'ELIGIBILITY_CONSTS', 'RxEligibilityService', 'moment', '$state', 'ERROR_MESSAGES', '$anchorScroll', 'PATIENT_CONSTS', 'eligibilityResponse', 'ToastNotificationService', 'GLOBAL_CONSTS'];

    function RxEligibilityResponseController($log, Utility, Eligibility, $stateParams, STATUS_CONSTS, ELIGIBILITY_CONSTS, RxEligibilityService, moment, $state, ERROR_MESSAGES, $anchorScroll, PATIENT_CONSTS, eligibilityResponse, ToastNotificationService, GLOBAL_CONSTS) {

        var vm = this;

        // data
        vm.insurancePolicyList = null;
        vm.patient = null;
        vm.coverageResponse = null;
        vm.responseId = null;
        vm.request = null;
        vm.patientName = null;
        vm.locationName = null;
        vm.responseDate = null;
        vm.requestDetailsExpanded = false;
        vm.selectedPolicyCoverageDrawer = null;
        vm.requestDate = null;
        vm.declined = false;
        vm.bvType = null;
        vm.bvStatus = null;
        vm.showAutoMBV = false;

        vm.isInsuranceStatusInactive = isInsuranceStatusInactive;

        vm.manualBvSubmitted = false;
        vm.manualBvError = false;
        vm.displayToast = false;
        vm.bvIncompleteStatus = false;
        vm.isAutoMbv = false;

        vm.isBvCompleted = isBvCompleted;
        vm.isManualBv = RxEligibilityService.isManualBv;
        vm.isBvStatusCompleted = RxEligibilityService.isBvStatusCompleted;
        vm.isResponseAutoMbv = isResponseAutoMbv;
        vm.setSobErrorMessage = setSobErrorMessage;
        vm.getDisplayValue = getDisplayValue;
        vm.reloadData = reloadData;

        vm.$onInit = function() {
            vm.coverageResponse = eligibilityResponse;
            _setInitialData();
        };

        function isResponseAutoMbv() {
            if(!Utility.isEmpty(vm.coverageResponse.autoManual) && vm.coverageResponse.autoManual && Utility.lowerCase(vm.bvStatus) === STATUS_CONSTS.STATUS.INCOMPLETE) {
                vm.isAutoMbv = true;
                vm.requestDetailsExpanded = true;
                if(vm.showAutoMBV) {
                    _setToastMessage(ELIGIBILITY_CONSTS.AUTO_RX_MBV_RESPONSE.SUCCESS.TOAST.HEADER, ELIGIBILITY_CONSTS.AUTO_RX_MBV_RESPONSE.SUCCESS.TOAST.MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
                }
            } else if(!Utility.isEmpty(vm.coverageResponse.autoManual) && !vm.coverageResponse.autoManual && !Utility.isEmpty(vm.coverageResponse.warnings) && vm.coverageResponse.warnings.length) {
                vm.isAutoMbv = true;
                vm.requestDetailsExpanded = true;
                vm.autoMbvErrorMessage = Utility.getAutoMbvWarningMessage(ELIGIBILITY_CONSTS.AUTO_RX_MBV_RESPONSE.ERROR.WARNING_CODE, vm.coverageResponse.warnings);
                if(vm.showAutoMBV) {
                    _setToastMessage(ELIGIBILITY_CONSTS.AUTO_RX_MBV_RESPONSE.ERROR.TOAST.HEADER, vm.autoMbvErrorMessage, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
                }
            }
        }



        function isInsuranceStatusInactive(policy) {
            return !Utility.isEmpty(policy) &&
                !Utility.isEmpty(policy.plan) &&
                !Utility.isEmpty(policy.plan.status) &&
                !Utility.isEmpty(policy.plan.status.insuranceStatus) &&
                Utility.lowerCase(policy.plan.status.insuranceStatus) === STATUS_CONSTS.STATUS.INACTIVE;
        }

        function isBvCompleted() {
            if (Utility.lowerCase(vm.bvType) === ELIGIBILITY_CONSTS.BV_TYPE.MANUAL && Utility.lowerCase(vm.bvStatus) === STATUS_CONSTS.STATUS.INCOMPLETE) {
                vm.bvIncompleteStatus = true;
                return false;
            }
            return !vm.manualBvError && vm.manualBvSubmitted;
        }

        function setSobErrorMessage(errorMessage) {
            _setToastMessage(ERROR_MESSAGES.SOB_ERROR.TITLE, errorMessage, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
            $anchorScroll();
        }

        function _setToastMessage(header, message, toastType) {
            ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, header, message, toastType));
        }

        function reloadData() {
            Utility.getById.call(Eligibility.fetchResponseById($stateParams.responseId, $stateParams.adjudicationType), 'bvHistory')
                .then(function (response) {
                    vm.coverageResponse = response;
                    _setInitialData();
                });
        }

        function getDisplayValue(value) {
            vm.bvStatus = vm.bvIncompleteStatus ? STATUS_CONSTS.STATUS.INCOMPLETE : STATUS_CONSTS.STATUS.COMPLETE;
            vm.bvType = vm.bvStatus === STATUS_CONSTS.STATUS.INCOMPLETE ? ELIGIBILITY_CONSTS.BV_TYPE.MANUAL : vm.bvType;
            return Utility.getValueForPendingManualVerification(vm.bvType, vm.bvStatus, value);
        }

        function _setInitialData() {
            if(!Utility.isEmpty($stateParams.status) && Utility.lowerCase($stateParams.status) === STATUS_CONSTS.STATUS.INCOMPLETE) {
                vm.bvIncompleteStatus = true;
            }
            if(!Utility.isEmpty($stateParams.showAutoMBV)) {
                vm.showAutoMBV = $stateParams.showAutoMBV;
            }
            vm.requestDetailsExpanded = vm.declined = $stateParams.declined;
            // Transaction response date
            vm.responseDate = vm.coverageResponse.responseDate;
            if(!Utility.isEmpty(vm.coverageResponse.request) && !Utility.isEmpty(vm.coverageResponse.request.location)) {
                vm.location = vm.coverageResponse.request.location;
                vm.locationName = vm.coverageResponse.request.location.name || vm.coverageResponse.request.location.locationName;
            }
            if(!Utility.isEmpty(vm.coverageResponse.policies)) {
                vm.insurancePolicyList = angular.copy(vm.coverageResponse.policies);
            }

            if(!Utility.isEmpty(vm.coverageResponse.request)) {
                vm.request = vm.coverageResponse.request;
                vm.patient = vm.request.patient;
                vm.patientName = Utility.getFullName(vm.patient);
            }

            vm.responseId = vm.coverageResponse.responseId;

            vm.responseDate = moment(vm.responseDate);

            // set request date
            vm.requestDate = moment(vm.coverageResponse.request.requestDate);

            if(!Utility.isEmpty(vm.coverageResponse.bvType)) vm.bvType = vm.coverageResponse.bvType;
            if(!Utility.isEmpty(vm.coverageResponse.status)) vm.bvStatus = vm.coverageResponse.status;

            if(!Utility.isEmpty(vm.coverageResponse.bvAdjudicationType)) {
                vm.adjudicationMethod = vm.coverageResponse.bvAdjudicationType;
            }
            vm.isPatientActive = !(!Utility.isEmpty(vm.coverageResponse.patientActive) && vm.coverageResponse.patientActive === false);
            vm.isResponseAutoMbv();
            if(!vm.isPatientActive) {
                _setToastMessage(PATIENT_CONSTS.DANGER_TOAST_MESSAGE.PATIENT_INACTIVE_HEADER, PATIENT_CONSTS.DANGER_TOAST_MESSAGE.PATIENT_INACTIVE_MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
            }
            vm.coverageStatus = vm.insurancePolicyList[0].plan.status.insuranceStatus;
        }


    }
})();
