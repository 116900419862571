/**
 * Created by mcaputo on 1/6/17.
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .factory('Eligibility', Eligibility);


    Eligibility.$inject = ['$q', '$log', 'API', 'moment', 'ProductModel', 'ebvResponseMapper', 'PolicyService', 'STATUS_CONSTS', 'Product', 'Utility', 'HELP_TEXT_CONSTS', 'ERROR_MESSAGES', 'ELIGIBILITY_CONSTS', 'SEARCH_CONSTS', '$state', 'InsurancePolicy', 'PatientService', 'BV_SELECTION_CONSTS', 'BVSelectionService', 'ServiceInformationModel', 'GLOBAL_CONSTS', 'Payer', 'CacheDataService', 'AdminCode', 'StatusStyles'];

    function Eligibility($q, $log, API, moment, ProductModel, ebvResponseMapper, PolicyService, STATUS_CONSTS, Product, Utility, HELP_TEXT_CONSTS, ERROR_MESSAGES, ELIGIBILITY_CONSTS, SEARCH_CONSTS, $state, InsurancePolicy, PatientService, BV_SELECTION_CONSTS, BVSelectionService, ServiceInformationModel, GLOBAL_CONSTS, Payer, CacheDataService, AdminCode, StatusStyles) {
        var _lastRequestDetails = null;
        var _responseCache = {};
        var _possibleRanks = ELIGIBILITY_CONSTS.POSSIBLE_POLICY_RANKS;

        var service = {
            checkMedAdvCoverage: checkMedAdvCoverage,
            getResponseById: getResponseById,
            submitManualBV: submitManualBV,
            submitRxManualBV: submitRxManualBV,
            getLastRequest: getLastRequest,
            fetchResponseById: fetchResponseById,
            mapPolicy: mapPolicy,
            getTooltip: getTooltip,
            checkRxCoverage: checkRxCoverage,
            pharmacyDetailsDisplayable: pharmacyDetailsDisplayable,
            resolveRxPatientConflict: resolveRxPatientConflict,
            getMedBVResponseTooltip: getMedBVResponseTooltip,
            getTooltipTextForMBV: getTooltipTextForMBV,
            viewBVResponse: viewBVResponse,
            getRankByRankNumber: getRankByRankNumber,
            isRxAndMedVerifyButtonDisplayable: isRxAndMedVerifyButtonDisplayable,
            prepareMedEligibilityFormData: prepareMedEligibilityFormData,
            reverifyCoverage: reverifyCoverage,
            getSearchResultAndWarningMessage: getSearchResultAndWarningMessage,
            getInsuranceStatus: getInsuranceStatus,
            getDisplayValue: getDisplayValue,
            setDisplayTextIcon: setDisplayTextIcon,
            checkRxSplitCoverage: checkRxSplitCoverage,
            checkVaccineCoverage: checkVaccineCoverage
        };

        function getTooltip() {
            return {
                practicePatientId: HELP_TEXT_CONSTS.PRACTICE.PATIENT_ID,
                responsePracticePatientId: HELP_TEXT_CONSTS.BV_REQUEST.PATIENT_INFO.PRACTICE_PATIENT_ID,
                secondaryDiagnosis: HELP_TEXT_CONSTS.POLICY.SECONDARY_DIAGNOSIS,
                primaryDiagnosis: HELP_TEXT_CONSTS.POLICY.PRIMARY_DIAGNOSIS,
                providerOutOfNetwork: HELP_TEXT_CONSTS.POLICY.OUT_OF_NETWORK,
                practiceLocation: HELP_TEXT_CONSTS.POLICY.LOCATION,
                responsePracticeLocation: HELP_TEXT_CONSTS.BV_REQUEST.SERVICE_DELIVERY.PRACTICE_LOCATION,
                providerState: HELP_TEXT_CONSTS.PROVIDER.BILLING_STATE,
                providerNPINumber: HELP_TEXT_CONSTS.PROVIDER.BILLING_NPI,
                responseProviderNPINumber: HELP_TEXT_CONSTS.MED_BV_RESPONSE.MBV_REQUEST_SECTION.NPI,
                providerTaxID: HELP_TEXT_CONSTS.PROVIDER.BILLING_TAX_ID,
                socialSecurity: HELP_TEXT_CONSTS.BV_REQUEST.PATIENT_INFO.SOCIAL_SECURITY,
                memberId: HELP_TEXT_CONSTS.BV_REQUEST.INSURANCE_INFO.MEMBER_ID,
                medicareMemberId: HELP_TEXT_CONSTS.BV_REQUEST.INSURANCE_INFO.MEDICARE_MEMBER_ID,
                medicareSuppPlanPolicy: HELP_TEXT_CONSTS.BV_REQUEST.INSURANCE_INFO.MEDICARE_SUPP_PLAN_POLICY,
                providerInNetwork: HELP_TEXT_CONSTS.BV_REQUEST.INSURANCE_INFO.PROVIDER_IS_IN_NETWORK,
                patientIsSubscriber: HELP_TEXT_CONSTS.BV_REQUEST.INSURANCE_INFO.PATIENT_IS_SUBSCRIBER,
                subscriberName: HELP_TEXT_CONSTS.BV_REQUEST.INSURANCE_INFO.SUBSCRIBER_FIRSTNAME,
                serviceDelivery: HELP_TEXT_CONSTS.BV_REQUEST.SERVICE_DELIVERY.PLACE_OF_SERVICE,
                adminCode: HELP_TEXT_CONSTS.BV_REQUEST.SERVICE_DELIVERY.ADMIN_CODE,
                practiceProviderId: HELP_TEXT_CONSTS.BV_REQUEST.PROVIDER_INFO.PRACTICE_PROVIDER_ID
            };
        }

        function checkMedAdvCoverage(data) {
            var deferred = $q.defer();
            API.post('coverage', data).then(function(response) {
                if(response) {
                    if(!Utility.isEmpty(response.bvAdjudicationType)) {
                        response.coverageResponseDto.data.bvAdjudicationType = response.bvAdjudicationType;
                    }
                    if(!Utility.isEmpty(response.mbvId)) {
                        response.coverageResponseDto.data.mbvId = response.mbvId;
                    }
                    _prepareResponse(response);
                    ebvResponseMapper.mapResponse(response.coverageResponseDto.data).then(function(mappedResponse) {
                        _responseCache[response.coverageResponseDto.data.responseId] = _mapJSONResponse(mappedResponse);
                        _lastRequestDetails = mappedResponse.request;
                        // Added as part of EP-199
                        // If there isn't policyList.plan info and there is transactionErrorMessage then create plan object with
                        // only necessary attributes (i.e: status, insurer, coverageStatus)
                        mappedResponse = addPolicyPlan(mappedResponse);
                        return deferred.resolve(mappedResponse);
                    }).catch(function(err) {
                        $log.error('There was an error mapping the eBV response');
                        return deferred.reject(err);
                    });
                }
                else {
                    deferred.reject(ERROR_MESSAGES.NO_RESPONSE);
                }
            }).catch(function(err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function _hasCoveringPlan(policies) {
            if(Utility.isEmpty(policies.plan)) {
                return false;
            }
            policies = policies.some(function(policy) {
                return Utility.lowerCase(policy.plan.coverageStatus) === STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.PAYER_GUIDELINES_APPLY;
            });
        }

        function getResponseById(responseId, adjudicationType) {
            var response = _responseCache[responseId];
            if(response && Utility.lowerCase(adjudicationType) === Utility.lowerCase(response.bvAdjudicationType)) {
                return $q.when(_responseCache[responseId]);
            } else {
                var deferred = $q.defer();

                service.fetchResponseById(responseId, adjudicationType).then(function(response) {
                    deferred.resolve(response);
                }).catch(function(err) {
                    deferred.reject("Could not fetch bv response", err);
                });

                return deferred.promise;
            }
        }

        function submitManualBV(bvData) {
            return API.post('manual', bvData);
        }

        function submitRxManualBV(bvData) {
            return API.post('manual/rx', bvData);
        }

        function getLastRequest() {
            return _lastRequestDetails;
        }

        function _mapJSONResponse(response) {
            response.serviceInformation = new ServiceInformationModel(response.request.provider, response.request.service.placeOfService, response.request.service.diagnosisCodeList[0]);

            var products = {
                product: {
                    productId: response.request.service.product.id,
                    displayName: response.request.service.product.name,
                    requiresAdminCode: response.request.service.product.requiresAdminCode
                }
            };
            response.request.productDetails = {
                product: new ProductModel(products),
                covered: _hasCoveringPlan(response.policies),
                costShare: response.costShareSummary,
                governmentProgram: response.govtProgramIndicator
            };

            // format patient dob
            if(!Utility.isEmpty(response.request.patient.dob)) {
                response.request.patient.dob = Utility.getDateByFormat(response.request.patient.dob, SEARCH_CONSTS.DATE_FORMAT);
            }

            // Added as part of EP-475 AC 5.2.3
            angular.forEach(response.policies, function(policy) {
                mapPolicy(policy);
            });

            angular.forEach(response.request.policies, function(policy) {
                if(angular.isDefined(policy.subscriber) && angular.isDefined(policy.subscriber.dob)) {
                    policy.subscriber.dob = new Date(moment(policy.subscriber.dob, SEARCH_CONSTS.DATE_FORMAT));
                }
            });

            return response;
        }

        function mapPolicy(policy) {
            // If Insurance Status is 'Active' and JCode is 'Covered' and priorAuth is 'Yes' for JCode and/or AdminCode
            // Then set coverage status to 'Prior Authorization Required'
            var policyPlanStatusIsActive = !Utility.isEmpty(policy.plan) && !Utility.isEmpty(policy.plan.status) && !Utility.isObject(policy.plan.status) && Utility.lowerCase(policy.plan.status) === STATUS_CONSTS.STATUS.ACTIVE;
            if(policyPlanStatusIsActive) {
                var jCode = PolicyService.getJCode(policy);

                if(Utility.isEmpty(jCode) || Utility.isEmpty(jCode.coverageStatus)) {
                    policy.plan.coverageStatus = STATUS_CONSTS.DISPLAY_TEXT.UNDETERMINED;
                } else if(Utility.lowerCase(jCode.coverageStatus) === STATUS_CONSTS.COVERAGE.COVERED) {
                    PolicyService.setProductCoverageStatus(policy);
                }
            }
        }

        /**
         * Add policy plan to the response if it isn't already there and transactionErrorMessage is present
         * @param mappedResponse
         */
        function addPolicyPlan(mappedResponse) {
            if(!Utility.isEmpty(mappedResponse) && !Utility.isEmpty(mappedResponse.policies)) {
                mappedResponse.policies.map(function(policy) {
                    if(!Utility.isEmpty(policy.transaction) && !Utility.isEmpty(policy.transaction.transactionErrorMessage)) {
                        if(angular.isUndefined(policy.plan) || policy.plan === null || angular.isUndefined(policy.plan.insurer) || policy.plan.insurer === null) {
                            policy.plan = {
                                insurer: policy.payer.clientName,
                                status: STATUS_CONSTS.DISPLAY_TEXT.UNDETERMINED,
                                coverageStatus: STATUS_CONSTS.DISPLAY_TEXT.UNDETERMINED
                            };
                        }
                    }
                });
            }
            return mappedResponse;
        }

        function fetchResponseById(responseId, bvAdjudicationType) {
            var queryParam = {
                bvAdjudicationType: bvAdjudicationType
            };
            var deferred = $q.defer();

            API.get('ebv/response/' + responseId, queryParam).then(function(response) {
                if(response) {
                    if(!Utility.isEmpty(response.bvAdjudicationType)) {
                        response.coverageResponseDto.data.bvAdjudicationType = response.bvAdjudicationType;
                    }
                    if(!Utility.isEmpty(response.mbvId)) {
                        response.coverageResponseDto.data.mbvId = response.mbvId;
                    }
                    _prepareResponse(response);
                    if(Utility.isMedical(bvAdjudicationType)) {
                        if(!Utility.isEmpty(response.responseDate)) {
                            response.coverageResponseDto.data.responseDate = response.responseDate;
                        }
                        ebvResponseMapper.mapResponse(response.coverageResponseDto.data).then(function(mappedResponse) {
                            if(angular.isDefined(response.coverageResponseDto.data.responseId)) {
                                _responseCache[response.coverageResponseDto.data.responseId] = _mapJSONResponse(mappedResponse);
                                _lastRequestDetails = mappedResponse.request;
                                mappedResponse = addPolicyPlan(mappedResponse);
                                mappedResponse = addProductDetails(mappedResponse);
                                mappedResponse.status = response.status;

                                deferred.resolve(mappedResponse);
                            }
                        }).catch(function(err) {
                            $log.error('There was an error mapping the eBV response');
                            deferred.reject(err);
                        });
                    } else if(Utility.isRx(bvAdjudicationType)) {
                        if(!Utility.isEmpty(response.responseDate)) {
                            response.coverageResponseDto.data.responseDate = response.responseDate;
                        }
                        ebvResponseMapper.mapResponse(response.coverageResponseDto.data).then(function(mappedResponse) {
                            if(angular.isDefined(response.coverageResponseDto.data.responseId)) {
                                _responseCache[response.coverageResponseDto.data.responseId] = _mapRxJSONResponse(mappedResponse);
                                _lastRequestDetails = mappedResponse.request;
                                mappedResponse = addProductDetails(mappedResponse);
                                mappedResponse.status = response.status;

                                deferred.resolve(mappedResponse);
                            }
                        }).catch(function(err) {
                            $log.error('There was an error mapping the eBV response');
                            deferred.reject(err);
                        });
                    }
                }
                else {
                    deferred.reject(ERROR_MESSAGES.NO_RESPONSE);
                }
            }).catch(function(err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function addProductDetails(response) {
            if(angular.isDefined(response.request.product)) {
                response.request.productDetails = angular.copy(response.request.product);
                response.request.productDetails.product = angular.copy(response.request.product);
            }
            Product.setCurrentProduct(response.request.productDetails.product);
            return response;
        }

        function checkRxCoverage(data) {
            var deferred = $q.defer();
            API.post('coverage/rxebv', data).then(function(response) {
                if(response) {
                    _prepareResponse(response);
                    response = mapRxResponse(response.coverageResponseDto.data);
                    deferred.resolve(response);
                }
                else {
                    deferred.reject(ERROR_MESSAGES.NO_RESPONSE);
                }
            }).catch(function(err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function resolveRxPatientConflict(data) {
            var deferred = $q.defer();
            API.post('coverage/rxebv/resolveconflict', data).then(function(response) {
                if(response) {
                    _prepareResponse(response);
                    response = mapRxResponse(response.coverageResponseDto.data);
                    return deferred.resolve(response);
                }
                else {
                    deferred.reject(ERROR_MESSAGES.NO_RESPONSE);
                }
            }).catch(function(err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function pharmacyDetailsDisplayable(policy) {
            if(!Utility.isEmpty(policy) && !Utility.isEmpty(policy.plan) && !Utility.isEmpty(policy.plan.status)) {
                if(Utility.isEmpty(policy.plan.status.insuranceStatus)) {
                    return false;
                } else {
                    return (Utility.lowerCase(policy.plan.status.insuranceStatus) !== STATUS_CONSTS.STATUS.INACTIVE);
                }
            }
            return false;
        }

        function mapRxResponse(response) {
            _responseCache[response.responseId] = response;
            _lastRequestDetails = response.request;
            // Added as part of EP-199 might not be necessary
            // If there isn't policyList.plan info and there is transactionErrorMessage then create plan object with
            // only necessary attributes (i.e: status, insurer, coverageStatus)
            response = addPolicyPlan(response);
            return response;
        }

        function _mapRxJSONResponse(response) {
            response.serviceInformation = new ServiceInformationModel(response.request.provider);

            var products = {
                product: {
                    productId: response.request.service.product.id,
                    displayName: response.request.service.product.name
                }
            };
            response.request.productDetails = {
                product: new ProductModel(products),
            };

            response.patient = response.request.patient;

            // format patient dob
            if(!Utility.isEmpty(response.request.patient.dob)) {
                response.request.patient.dob = Utility.getDateByFormat(response.request.patient.dob, GLOBAL_CONSTS.DATE_FORMAT.YYYY_MM_DD.SLASH);
            }

            return response;
        }

        function getMedBVResponseTooltip() {
            return HELP_TEXT_CONSTS.MED_BV_RESPONSE;
        }

        function getTooltipTextForMBV(adjudicationMethod) {
            var tooltipText = {};
            if(Utility.isEmpty(adjudicationMethod)) {
                return tooltipText;
            }
            switch(adjudicationMethod.toLowerCase()) {
                case BV_SELECTION_CONSTS.METHOD_NAME.RX:
                    tooltipText = {
                        confirmation: HELP_TEXT_CONSTS.RX_MBV_RESPONSE.CONFIRMATION,
                        bvDate: HELP_TEXT_CONSTS.RX_MBV_RESPONSE.BV_DATE,
                        payerRep: HELP_TEXT_CONSTS.RX_MBV_RESPONSE.PAYER_REP,
                        payerTel: HELP_TEXT_CONSTS.RX_MBV_RESPONSE.PAYER_TELEPHONE_NUMBER,
                        comment: HELP_TEXT_CONSTS.RX_MBV_RESPONSE.COMMENT
                    };
                    break;
                case BV_SELECTION_CONSTS.METHOD_NAME.MEDICAL:
                    tooltipText = {
                        confirmation: HELP_TEXT_CONSTS.MED_MBV_RESPONSE.CONFIRMATION_NUMBER,
                        bvDate: HELP_TEXT_CONSTS.MED_MBV_RESPONSE.BV_DATE,
                        payerRep: HELP_TEXT_CONSTS.MED_MBV_RESPONSE.PAYER_REP_NAME,
                        payerTel: HELP_TEXT_CONSTS.MED_MBV_RESPONSE.PAYER_TELEPHONE_NUMBER,
                        comment: HELP_TEXT_CONSTS.MED_MBV_RESPONSE.COMMENTS
                    };
                    break;
                default:
                    tooltipText = {};
            }
            return tooltipText;
        }

        function _prepareResponse(response) {
            if(!Utility.isEmpty(response.autoManual)) {
                response.coverageResponseDto.data.autoManual = response.autoManual;
            }
            if(!Utility.isEmpty(response.warnings)) {
                response.coverageResponseDto.data.warnings = response.warnings;
            }
            if(!Utility.isEmpty(response.coverageResponseDto.statusDate)) {
                response.coverageResponseDto.data.statusDate = response.coverageResponseDto.statusDate;
            }
            if(!Utility.isEmpty(response.responseDate)) {
                response.coverageResponseDto.data.responseDate = response.responseDate;
            }
            if(!Utility.isEmpty(response.status)) {
                response.coverageResponseDto.data.status = response.status;
            }
            if(!Utility.isEmpty(response.bvType)) {
                response.coverageResponseDto.data.bvType = response.bvType;
            }
            if(!Utility.isEmpty(response.mbvId)) {
                response.coverageResponseDto.data.mbvId = response.mbvId;
            }
            if(!Utility.isEmpty(response.patientActive)) {
                response.coverageResponseDto.data.patientActive = response.patientActive;
            }
        }

        function viewBVResponse(responseId, bvAdjudicationType, rxType) {
            var redirectTo = '';
            if(Utility.lowerCase(bvAdjudicationType) === BV_SELECTION_CONSTS.METHOD_NAME.MEDICAL) {
                redirectTo = ELIGIBILITY_CONSTS.STATE.MED_ELIGIBILITY_RESPONSE;
            } else if(Utility.lowerCase(bvAdjudicationType) === BV_SELECTION_CONSTS.METHOD_NAME.RX) {
                redirectTo = Utility.lowerCase(rxType) === ELIGIBILITY_CONSTS.RX_API_TYPES.COMBINED ? ELIGIBILITY_CONSTS.STATE.RX_ELIGIBILITY_RESPONSE : ELIGIBILITY_CONSTS.STATE.RX_ELIGIBILITY_SPLIT_RESPONSE;
            }
            service.fetchResponseById(responseId, bvAdjudicationType).then(function(response) {
                var responseId = response.responseId;
                $state.go(redirectTo, {
                    responseId: responseId,
                    status: response.status,
                    showAutoMBV: false,
                    adjudicationType: bvAdjudicationType
                });
            }).catch(function(err) {
                $log.error('error getting coverage', err);
            });
        }

        /**
         * Returns corresponding rankValue for given rankNumber.
         * @param rankNumber
         * @param defaultReturnValue
         */
        function getRankByRankNumber(rankNumber, defaultReturnValue) {
            return (Utility.isEmpty(rankNumber) || rankNumber <= 0 || rankNumber > _possibleRanks.length) ? defaultReturnValue : _possibleRanks[rankNumber - 1];
        }

        /**
         * Function checks if bvMethod is 'Combined' and provided 'Product' exists in both Rx & Medical product list.
         * @param product An instance of ProductModel
         */
        function isRxAndMedVerifyButtonDisplayable(product) {
            var deferred = $q.defer();
            // Check if bvMethod is 'Combined'
            BVSelectionService.getBvMethod().then(function(method) {
                if(Utility.lowerCase(method) === BV_SELECTION_CONSTS.METHOD_NAME.COMBINED) {
                    // Check if given product is in both 'Rx' and 'Medical' product list
                    Product.get(Utility.firstCharacterUpperCase(method)).then(function(productList) {
                        var foundInRx = false;
                        var foundInMed = false;
                        productList.forEach(function(prod) {
                            if(prod.id === product.id) {
                                if(prod.productMethod.toLowerCase() === BV_SELECTION_CONSTS.METHOD_NAME.MEDICAL) {
                                    foundInMed = true;
                                } else if(prod.productMethod.toLowerCase() === BV_SELECTION_CONSTS.METHOD_NAME.RX) {
                                    foundInRx = true;
                                }
                            }
                        });
                        deferred.resolve(foundInMed && foundInRx);
                    }).catch(function(err) {
                        deferred.reject(err);
                    });
                } else {
                    deferred.resolve(false);
                }

            }).catch(function(err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * Uses patient id to prepare med eligibility request form data by fetching patient and insurance information from combined api
         * And Provider and Service information from lastBV (Med)
         * @param patientId
         * @param bvMethod Either Medical or Rx
         */
        function prepareMedEligibilityFormData(patientId, bvMethod) {
            var deferred = $q.defer();
            var formDataObject = {
                patient: null,
                medPolicies: [new InsurancePolicy()],
                provider: null,
                showProviderDropdown: true,
                medServiceDeliveryInformation: {},
                rxServiceDeliveryInformation: {},
                selectedProduct: null,
                selectedLocation: null
            };
            bvMethod = bvMethod || BV_SELECTION_CONSTS.METHOD_NAME.MEDICAL;

            // Fetch Patient & Insurance Information
            // If we can't fetch Patient & Insurance Info then verification cannot be done so reject the promise
            var fetchPatientOnly = false;
            PatientService.getPatientAndInsuranceInfoByPatientId(patientId, fetchPatientOnly).then(function(patientResponse) {
                formDataObject.patient = _preparePatientData(patientResponse.patientPractice);
                formDataObject.medPolicies = (!Utility.isEmpty(patientResponse.patientPayers) && patientResponse.patientPayers.length > 0) ? patientResponse.patientPayers.map(_mapInsurancePolicies) : [new InsurancePolicy()];

                // Fetch Service & Provider Info
                // If we can't fetch Service & Provider Info we'll resolve our promise with whatever data we have
                PatientService._getPatientDataForEligibility(formDataObject.patient, bvMethod, true).then(function(caseBV) {
                    formDataObject.provider = !Utility.isEmpty(caseBV.request.provider) ? caseBV.request.provider : null;
                    formDataObject.rxServiceDeliveryInformation = !Utility.isEmpty(caseBV.request.service) ? caseBV.request.service : {};
                    // CRSN-638 : Product name shouldn't be auto selected for existing patient
                    // formDataObject.selectedProduct = !Utility.isEmpty(caseBV.request.service) ? caseBV.request.service.product : null;
                    formDataObject.selectedProduct = null;
                    formDataObject.selectedLocation = caseBV.request.location;
                    formDataObject.medServiceDeliveryInformation = !Utility.isEmpty(caseBV.request.service) ? caseBV.request.service : {};
                    if(!Utility.isEmpty(caseBV.request.patient.medicareId)) {
                        formDataObject.patient.medicareId = caseBV.request.patient.medicareId;
                    }
                    if(!Utility.isEmpty(caseBV.request.service)) {
                        AdminCode.getByProduct(formDataObject.medServiceDeliveryInformation.product.id).then(function (adminCodes) {
                            var adminCodeList = [];
                            if (!Utility.isEmpty(formDataObject.medServiceDeliveryInformation.adminCodeList) && formDataObject.medServiceDeliveryInformation.adminCodeList.length > 0) {
                                formDataObject.medServiceDeliveryInformation.adminCodeList.forEach(function (requestAdminCode) {
                                    adminCodeList = adminCodes.filter(function (responseAdminCode) {
                                        return responseAdminCode.id === requestAdminCode.id;
                                    });
                                });
                                formDataObject.medServiceDeliveryInformation.adminCodeList = adminCodeList.length === 0 ? [null] : adminCodeList;
                            }
                        });
                    }
                    deferred.resolve(formDataObject);
                }).catch(function(err) {
                    $log.debug('Something went wrong while fetching patient & insurance info', err);
                    deferred.resolve(formDataObject);
                });
            }).catch(function(err) {
                $log.debug('Something went wrong while fetching patient & insurance info', err);
                deferred.reject(err);
            });
            return deferred.promise;
        }

        function _preparePatientData(patientPractice) {
            return {
                patientId: patientPractice.patient.patientId || null,
                externalPatientId: patientPractice.patient.externalPatientId || null,
                practicePatientId: patientPractice.externalId || null,
                firstName: patientPractice.patient.firstName || null,
                middleName: patientPractice.patient.middleName || null,
                lastName: patientPractice.patient.lastName || null,
                dob: patientPractice.patient.dob || null,
                gender: patientPractice.patient.gender || null,
                ssnOrMedicareId: PatientService.getSsnOrMedIdDisplayValue(patientPractice.patient) || null,
                address: {
                    zipCode: patientPractice.patient.zip || null
                },
                medicareId: patientPractice.patient.medicareId || null
            };
        }

        function _mapInsurancePolicies(data) {
            var newPolicy = new InsurancePolicy({
                payer: new Payer(data.payerOrg),
                memberId: data.memberId,
                medicareMemberId: data.medicareMemberId
            });
            newPolicy.subscriberPatient = PatientService.isPatientSubscriber(data);

            // Fix for existing patient BV for medical.
            // Payer id is not populating from backend inside payer object
            if(!Utility.isEmpty(newPolicy.payer.payer) && !Utility.isEmpty(newPolicy.payer.payer.payerId)) {
                newPolicy.payer.id = newPolicy.payer.payer.payerId;
            }

            // Medicare plan letter will be inside medSuppData object
            if(!Utility.isEmpty(data.medSuppData) && !Utility.isEmpty(data.medSuppData.planLetter)) {
                newPolicy.medSuppData = {
                    planLetter: data.medSuppData.planLetter
                };
            }

            // Fill following data only if patient is not a subscriber
            if(!newPolicy.subscriberPatient || (!Utility.isEmpty(data.subscriberPatient) && data.subscriberPatient === false)) {
                newPolicy.subscriber = {
                    firstName: data.subscriberFirstName,
                    middleName: data.subscriberMiddleName,
                    lastName: data.subscriberLastName,
                    memberId: data.subscriberMemberId,
                    gender: data.subscriberGender
                };
                if(!Utility.isEmpty(data.subscriberDob)) {
                    newPolicy.subscriber.dob = new Date(data.subscriberDob);
                }
            }
            newPolicy.rank = service.getRankByRankNumber(data.payerRank, null);
            return newPolicy;
        }

        function reverifyCoverage(patientId, bvAdjudicationMethod) {
            service.prepareMedEligibilityFormData(patientId, bvAdjudicationMethod).then(function(formData) {
                var redirectTo = '';
                if(Utility.isMedical(bvAdjudicationMethod)) {
                    redirectTo = BV_SELECTION_CONSTS.STATE_NAME.MEDICAL;
                    CacheDataService.setMedBvCacheData(formData);
                } else if(Utility.isRx(bvAdjudicationMethod)) {
                    redirectTo = BV_SELECTION_CONSTS.STATE_NAME.RX;
                    CacheDataService.setRxBvCacheData(formData);
                }
                $state.go(redirectTo);
            });
        }

        function getSearchResultAndWarningMessage(count) {
            var searchResult = this,
                messageMap = {
                    searchResultText: null,
                    searchWarningMessage: null
                };

            var resultText = 'result';
            if(!Utility.isEmpty(searchResult)) {
                resultText = count > 1 ? 'results' : 'result';
            }
            switch(searchResult.searchType) {
                case SEARCH_CONSTS.SEARCH_CATEGORY.DAY:
                case SEARCH_CONSTS.SEARCH_CATEGORY.DATE:
                    messageMap.searchResultText = count + ' ' + resultText + ' from "' + searchResult.param1 + '" to "' + searchResult.param2 + '"';
                    if(!count) {
                        messageMap.searchWarningMessage = 'Your search from "' + searchResult.param1 + '" to "' + searchResult.param2 + '" did not return any items.';
                    }
                    break;
                case SEARCH_CONSTS.SEARCH_CATEGORY.PARAMETER:
                    messageMap.searchResultText = count + ' ' + resultText + ' for "' + searchResult.param2 + '" of "' + searchResult.param1 + '"';
                    if(!count) {
                        messageMap.searchWarningMessage = 'Your search for "' + searchResult.param2 + '" of "' + searchResult.param1 + '" did not return any items.';
                    }
                    break;
                case SEARCH_CONSTS.SEARCH_CATEGORY.EMPTY:
                    messageMap.searchResultText = null;
                    messageMap.searchWarningMessage = SEARCH_CONSTS.EMPTY_SEARCH_WARNING_MESSAGE;
                    break;
            }

            return messageMap;
        }

        function getInsuranceStatus(policy, bvType, bvStatus) {
            var insuranceStatus = PolicyService.getInsuranceStatus(policy.plan);
            return Utility.getValueForPendingManualVerification(bvType, bvStatus, insuranceStatus);
        }

        function getDisplayValue(value, returnValue, bvType, bvStatus) {
            var changedValue = Utility.getValueForPendingManualVerification(bvType, bvStatus, value);
            // If changes value is different than original value then return changed value if returnValue is not provided.
            if(value !== changedValue) {
                if(!Utility.isEmpty(returnValue)) {
                    return returnValue;
                } else {
                    return changedValue;
                }
            }
            return changedValue;
        }

        function setDisplayTextIcon(statusText, bvType, bvStatus) {
            var valueForPendingManualVerification = Utility.getValueForPendingManualVerification(bvType, bvStatus, statusText);
            if(Utility.lowerCase(valueForPendingManualVerification) === Utility.lowerCase(STATUS_CONSTS.PENDING_MANUAL_VERIFICATION)) {
                return "";
            }
            return StatusStyles.setDisplayTextIcon(statusText);
        }

        function checkRxSplitCoverage(data) {
            var deferred = $q.defer();
            API.post('coverage/rxebv/split', data).then(function(response) {
                // TODO: fix this once API is sending response
                response.bvAdjudicationType = 'Rx';
                _responseCache[response.responseId] = response;
                deferred.resolve(response);
            }).catch(function(err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function checkVaccineCoverage(data) {
            var deferred = $q.defer();
            API.post('coverage/rxebv/vaccine', data).then(function(response) {
                response.bvAdjudicationType = 'Rx';
                _responseCache[response.responseId] = response;
                deferred.resolve(response);
            }).catch(function(err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        return service;
    }
})();
