(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('help', {
                url: '/help',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Help'
                },
                views: {
                    'content@': {
                        templateUrl: 'components/help/help.html',
                        controller: 'HelpController'
                    }
                }
            });
    }
})();
