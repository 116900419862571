/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.Components.Practice.controller:PracticeInformationController
 * @author Sterling Stokes
 * @requires $log
 * @requires $state
 * @requires PracticeView
 * @requires PracticeService
 * @requires HELP_TEXT_CONSTS
 * @requires PRACTICE_CONSTS
 * @requires ERROR_MESSAGES
 * @requires PAGE_TITLES
 * @description
 *
 * This is the controller for the Practice Information page.
 **/

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .controller('PracticeInformationController', PracticeInformationController);

    PracticeInformationController.$inject = ['$log', '$state', 'PracticeView', 'PracticeService', 'HELP_TEXT_CONSTS', 'PRACTICE_CONSTS', 'ERROR_MESSAGES', 'PAGE_TITLES', 'ConfigurationService', 'ToastNotificationService', 'GLOBAL_CONSTS', 'Utility', 'PracticeSetupAddService'];

    function PracticeInformationController($log, $state, PracticeView, PracticeService, HELP_TEXT_CONSTS, PRACTICE_CONSTS, ERROR_MESSAGES, PAGE_TITLES, ConfigurationService, ToastNotificationService, GLOBAL_CONSTS, Utility, PracticeSetupAddService) {

        var vm = this;

        vm.loadingPractice = false;
        vm.savingPractice = false;
        vm.siteRegistrationEnabled = Utility.isTruthy(ConfigurationService.getOrganizationProperty('siteRegistration') || 0);
        vm.resetError = resetError;

        // validation requirements
        vm.validation = {
            name: {
                minLength: PRACTICE_CONSTS.NAME_MIN_LENGTH,
                maxLength: PRACTICE_CONSTS.NAME_MAX_LENGTH
            },
            legalName: {
                minLength: PRACTICE_CONSTS.LEGAL_NAME_MIN_LENGTH,
                maxLength: PRACTICE_CONSTS.LEGAL_NAME_MAX_LENGTH
            },
            npi: {
                minLength: PRACTICE_CONSTS.NPI_MIN_LENGTH,
                maxLength: PRACTICE_CONSTS.NPI_MAX_LENGTH
            },
            taxId: {
                minLength: PRACTICE_CONSTS.TAX_ID_MIN_LENGTH,
                maxLength: PRACTICE_CONSTS.TAX_ID_MAX_LENGTH
            },
            mainPhone: {
                minLength: PRACTICE_CONSTS.MAIN_PHONE_MIN_LENGTH,
                maxLength: PRACTICE_CONSTS.MAIN_PHONE_MAX_LENGTH
            },
            mainFax: {
                minLength: PRACTICE_CONSTS.MAIN_FAX_MIN_LENGTH,
                maxLength: PRACTICE_CONSTS.MAIN_FAX_MAX_LENGTH
            },
            address1: {
                maxLength: PRACTICE_CONSTS.ADDRESS_1_MAX_LENGTH
            },
            address2: {
                maxLength: PRACTICE_CONSTS.ADDRESS_2_MAX_LENGTH
            },
            city: {
                minLength: PRACTICE_CONSTS.CITY_MIN_LENGTH,
                maxLength: PRACTICE_CONSTS.CITY_MAX_LENGTH
            },
            zip: {
                minLength: PRACTICE_CONSTS.ZIP_MIN_LENGTH,
                maxLength: PRACTICE_CONSTS.ZIP_MAX_LENGTH
            }
        };

        vm.errorMessages = {
            loadPractice: {
                display: false,
                text: 'There was an error loading the practice. Please try again.'
            },
            edit: {
                practice: {
                    name: {
                        minLength: 'Practice Name must be greater than ' + vm.validation.name.minLength + ' characters',
                        maxLength: 'Practice Name can be no more than ' + vm.validation.name.maxLength + ' characters',
                        required: 'Practice Name is required',
                        unique: 'A practice with this name already exists'
                    },
                    legalName: {
                        minLength: 'Practice Legal Name must be greater than ' + vm.validation.legalName.minLength + ' characters',
                        maxLength: 'Practice Legal Name can be no more than ' + vm.validation.legalName.maxLength + ' characters',
                        required: 'Practice Legal Name is required'
                    },
                    npi: {
                        minLength: 'Practice NPI must be exactly ' + vm.validation.npi.minLength + ' characters',
                        maxLength: 'Practice NPI must be exactly ' + vm.validation.npi.maxLength + ' characters',
                        required: PRACTICE_CONSTS.ERROR_MESSAGES.PRACTICE_NPI_REQUIRED,
                        invalid: PRACTICE_CONSTS.ERROR_MESSAGES.INVALID_PRACTICE_NPI
                    },
                    taxId: {
                        // actual Tax ID length is 9, but this allows for the '-' character in mask / validation
                        mask: 'Practice Tax ID must be exactly ' + (vm.validation.taxId.minLength - 1) + ' characters',
                        maxLength: 'Practice Tax ID must be exactly ' + (vm.validation.taxId.maxLength - 1) + ' characters'
                    },
                    mainPhone: {
                        mask: 'Practice Main Phone must be exactly ' + vm.validation.mainPhone.minLength + ' characters',
                        required: 'Practice Main Phone is required'
                    },
                    mainFax: {
                        mask: 'Practice Main Fax must be exactly ' + vm.validation.mainFax.minLength + ' characters'
                    },
                    address1: {
                        maxLength: 'Practice Address 1 can be no more than ' + vm.validation.address1.maxLength + ' characters',
                        required: 'Practice Address 1 is required'
                    },
                    address2: {
                        maxLength: 'Practice Address 2 can be no more than ' + vm.validation.address2.maxLength + ' characters'
                    },
                    city: {
                        minLength: 'Practice City must be greater than ' + vm.validation.city.minLength + ' characters',
                        maxLength: 'Practice City can be no more than ' + vm.validation.city.maxLength + ' characters',
                        required: 'Practice City is required'
                    },
                    zip: {
                        minLength: 'Practice Zip must be greater than ' + vm.validation.zip.minLength + ' characters',
                        maxLength: 'Practice Zip can be no more than ' + vm.validation.zip.maxLength + ' characters',
                        required: 'Practice Zip is required'
                    }
                }

            },
            notAvailable: ERROR_MESSAGES.NOT_AVAILABLE,
            practiceNameNotUnique: 'A Practice with this Practice Name already exists'
        };

        // Help text for help icon tooltips
        vm.helpText = {
            npi: HELP_TEXT_CONSTS.PRACTICE.NPI,
            taxId: HELP_TEXT_CONSTS.PRACTICE.TAX_ID,
            legalName: HELP_TEXT_CONSTS.PRACTICE.LEGAL_NAME
        };

        vm.savePractice = savePractice;
        vm.getTitle = getTitle;

        init();

        function init() {
            var currentState = $state.current.name;
            PracticeView.configure({
                title: vm.getTitle(currentState),
                tabIndex: 0
            });
            loadPractice();

            // Set flag for displaying Edit link
            // Only show if current user is admin
            vm.displayEditLink = !ConfigurationService.isUserWithUserRole();
        }

        /**
         * @ngdoc function
         * @name loadPractice
         * @methodOf eAccess.ProviderPortal.Components.Practice.controller:PracticeInformationController
         * @description
         *
         * Loads a practice based on the logged-in user.
         */

        function loadPractice() {
            vm.loadingPractice = true;
            vm.errorMessages.loadPractice.display = false;
            PracticeService.loadPractice()
                .then(function(practice) {
                    vm.practice = practice;
                    var currentState = $state.current.name;
                    if(currentState === PRACTICE_CONSTS.PRACTICE_INFORMATION_EDIT || ConfigurationService.isUserWithUserRole()){
                        PracticeView.configure({
                            title: vm.getTitle(currentState, practice.name),
                            contentTitle: PAGE_TITLES.PRACTICE.EDIT,
                            tabIndex: 0
                        });
                    } else {
                        PracticeView.configure({
                            title: vm.getTitle(currentState, practice.name),
                            contentTitle: practice.name,
                            tabIndex: 0,
                            editText: 'edit',
                            editHref: 'practice.information.edit',
                            editViewableStatus: vm.practice.active,
                            editId: vm.practice.id
                        });
                    }
                })
                .catch(function(err) {
                    $log.error('Error loading the practice information:', err);
                    vm.errorMessages.loadPractice.display = true;
                })
                .finally(function() {
                    vm.loadingPractice = false;
                });
        }

        /**
         * @ngdoc function
         * @name savePractice
         * @methodOf eAccess.ProviderPortal.Components.Practice.controller:PracticeInformationController
         * @description
         *
         * Saves updated practice information.
         */

        function savePractice() {
            // reset practiceName and npiNumber validity
            vm.practiceForm.practiceName.$setValidity('unique', true);
            vm.practiceForm.practiceNpi.$setValidity('npiType2', true);
            // bail if the form is invalid
            if(vm.practiceForm.$invalid) {
                return false;
            }
            vm.savingPractice = true;
            PracticeService.updatePractice(vm.practice)
                .then(function() {
                    vm.practiceForm.$setPristine();
                    $state.go('practice.information');
                    ToastNotificationService.setGlobalToastInfo(GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
                })
                .catch(function(error) {
                    if(error.data.message === vm.errorMessages.practiceNameNotUnique) {
                        vm.practiceForm.practiceName.$setValidity('unique', false);
                    } else if(PracticeSetupAddService.isNpiError(error)) {
                        vm.practiceForm.practiceNpi.$setValidity('npiType2', false);
                    } else {
                        ToastNotificationService.setGlobalToastInfo(error.status);
                    }
                })
                .finally(function() {
                    vm.savingPractice = false;
                });
        }

        function getTitle(currentState, practiceName) {
            $log.debug('practice', vm);
            if(currentState === 'practice.information.edit') {
                return PAGE_TITLES.PRACTICE.DEFAULT;
            }
            if(practiceName) {
                return PAGE_TITLES.PRACTICE.DEFAULT;
            }
            else {
                return PAGE_TITLES.PRACTICE.DEFAULT;
            }

        }

        function resetError(inputName ,value) {
            vm.practiceForm[inputName].$setValidity(value, true);
        }

    }

})();
