/**
 * @ngdoc Model Service
 * @module eAccess.ProviderPortal.Components.Search
 * @name SearchModel
 * @kind Factory
 *
 * @description
 * This Model is used while calling the search api.
 * It contains pagination data, search query and filter query
 * @author girianish
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Search')
        .factory('SearchModel', SearchModel);

    SearchModel.$inject = ['SearchPaginationModel', 'STATUS_CONSTS', 'Utility'];

    function SearchModel(SearchPaginationModel, STATUS_CONSTS, Utility) {
        function SearchModelInstance(pagination, query, selectedFacet, viewableStatus, includePayer, filterQuery, searchBy) {
            this.pagination = pagination || new SearchPaginationModel();
            this.query = query || null;
            this.selectedFacet = selectedFacet || null;
            this.viewableStatus = viewableStatus || STATUS_CONSTS.STATUS.ACTIVE;
            this.includePayer = !Utility.isEmpty(includePayer) ? includePayer : true;
            this.filterQuery = filterQuery || null;
            this.searchBy = searchBy || null;
        }

        return SearchModelInstance;
    }

})();
