/**
 * @ngdoc service
 * @name eAccess.ProviderPortal.Components.PracticeSetup.service:PracticeSetupView
 * @author Nikesh Maharjan
 * @description
 *
 * This is the service for view functions that apply to all practice setup views.
 **/

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PracticeSetup')
        .factory('PracticeSetupView', PracticeSetupView);

    PracticeSetupView.$inject = [];

    function PracticeSetupView() {

        var DEFAULTS = {
            LINK_TEXT: 'Home',
            LINK_HREF: '#/login',
            ICON_CLASS: 'fa fa-home',
            LINK_STATE: 'login'
        };

        var service = {
            configure: configure,
            setPageTitle: setPageTitle,
            setHeaderLink: setHeaderLink
        };

        return service;

        /**
         * @ngdoc function
         * @name configure
         * @methodOf eAccess.ProviderPortal.Components.PracticeSetup.service:PracticeSetupView
         * @description
         *
         * Configures all info for PracticeSetup view based on passed config object.
         *
         * @param config
         * @example configure({
         *     title: '',
         *     linkText: '',
         *     linkHref: '',
         *     linkIcon: '',
         *     linkState:''
         * })
         */
        function configure(config) {
            setPageTitle(config.title);
            setHeaderLink(config.linkText, config.linkHref, config.linkIcon, config.linkState);
        }

        /**
         * @ngdoc function
         * @name setPageTitle
         * @methodOf eAccess.ProviderPortal.Components.PracticeSetup.service:PracticeSetupView
         * @description
         *
         * Sets the page title on the PracticeSetup view.
         *
         * @param title
         */
        function setPageTitle(title) {
            service.pageTitle = title || null;
        }

        /**
         * @ngdoc function
         * @name setHomeLink
         * @methodOf eAccess.ProviderPortal.Components.PracticeSetup.service:PracticeSetupView
         * @description
         *
         * Sets the text and href for the page link on the PracticeSetup view.
         *
         * @param linkText
         * @param linkHref
         * @param linkIcon
         * @param linkState
         */
        function setHeaderLink(linkText, linkHref, linkIcon, linkState) {
            service.headerLink = {
                text: linkText || DEFAULTS.LINK_TEXT,
                href: linkHref || DEFAULTS.LINK_HREF,
                iconClass: linkIcon || DEFAULTS.ICON_CLASS,
                state: linkState || DEFAULTS.LINK_STATE
            };
        }
    }

})();
