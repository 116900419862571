(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Modals.SOBModal', [])
        .controller('SOBModalController', SOBModalController);

    SOBModalController.$inject = ['$log', '$uibModalInstance', 'sobComplete', 'Eligibility'];

    function SOBModalController($log, $uibModalInstance, sobComplete, Eligibility) {
        var vm = this;

        vm.sobComplete = sobComplete;
        vm.closeModal = closeModal;
        vm.submitManualBV = submitManualBV;

        function closeModal() {
            $uibModalInstance.close();
        }

        function submitManualBV() {
            var mockManualResponse = true;
            var response;
            Eligibility.submitManualBV(mockManualResponse).then(function(result) {
                response = result.data.sobSubmitted;
            }).catch(function(err) {
                $log.error('Error submitting manual bv', err);
            }).finally(function() {
                $uibModalInstance.close(response);
            });
        }
    }
})();
