/**
 * Created by aayush.regmi on 10/30/2017.
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.UserAuthEmailLink')
        .factory('UserAuthEmailLinkService', UserAuthEmailLinkService);

    UserAuthEmailLinkService.$inject = ['$state', '$log', 'API', '$q', 'STATUS_CODE', 'ERROR_MESSAGES'];

    function UserAuthEmailLinkService($state, $log, API, $q, STATUS_CODE, ERROR_MESSAGES) {
        var service = {
            validateToken: validateToken
        };

        function validateToken(token) {
            return API.get('users/verify/' + token).then(function(response){
                return response;
            }).catch(function(error){
                if (error.status === STATUS_CODE['404_NOT_FOUND']) {
                    $state.go('login', {errorMessage: ERROR_MESSAGES.EMAIL_LINK_404});
                }
            });
        }

        return service;
    }
})();
