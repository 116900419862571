/**
 * Created by nikesh on 10/31/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PracticeSetup')
        .controller('PracticeSetupAddController', PracticeSetupAddController);

    PracticeSetupAddController.$inject = ['$log', 'PracticeSetupView', 'PracticeSetupAddService', 'Storage', 'PRACTICE_SETUP_CONSTS', '$state', '$anchorScroll', '$location', 'Utility'];

    function PracticeSetupAddController($log, PracticeSetupView, PracticeSetupAddService, Storage, PRACTICE_SETUP_CONSTS, $state, $anchorScroll, $location, Utility) {

        var vm = this;

        // data
        vm.viewService = PracticeSetupView;
        vm.practiceSetupFlowConfig = null;
        vm.practiceSetupSteps = PRACTICE_SETUP_CONSTS.PRACTICE_SETUP_STEPS;
        vm.savingPracticeSetup = false;
        vm.practice = {};
        vm.location = {};
        vm.provider = {};
        vm.errorMessage = null;
        vm.practiceForm = {};
        vm.locationForm = {};
        vm.providerForm = {};
        vm.locationBtnText = PRACTICE_SETUP_CONSTS.BUTTON_TEXT.SAVE_CONTINUE;

        // functions
        // vm.breadcrumbClickedCallback = breadcrumbClickedCallback;

        vm.nextStep = nextStep;
        vm.previousStep = previousStep;
        vm.resetErrorMessage = resetErrorMessage;

        init();

        function init() {
            // Clear session data for every process
            _clearSessionData();

            // Fetch practiceSetupFlowConfig data
            vm.practiceSetupFlowConfig = PracticeSetupAddService.getPracticeSetupFlowConfig();

            PracticeSetupView.configure({
                title: _constructPageTitle()
            });
        }

        function nextStep(promiseResponseObject) {
            vm.resetErrorMessage();
            _submitForm(promiseResponseObject);
        }

        function previousStep() {
            --vm.practiceSetupFlowConfig.currentStep;
            vm.practiceSetupFlowConfig.currentStepName = vm.practiceSetupFlowConfig.stepNames[vm.practiceSetupFlowConfig.currentStep - 1];
            // Update progress breadcrumb
            _resetBreadCrumbToPreviousOrNextStep();
            _setPageTitle();
        }

        function _constructPageTitle() {
            return 'PRACTICE SETUP (STEP ' + vm.practiceSetupFlowConfig.currentStep + ' of ' + vm.practiceSetupFlowConfig.totalNoOfSteps + ') | ' + vm.practiceSetupFlowConfig.currentStepName;
        }

        function _setPageTitle() {
            PracticeSetupView.configure({
                title: _constructPageTitle()
            });
            $anchorScroll();
        }

        function _resetBreadCrumbToPreviousOrNextStep() {
            var currentIndex = vm.practiceSetupFlowConfig.currentStep - 1;
            vm.practiceSetupFlowConfig.breadcrumbInfo.forEach(function(item, index) {
                (index <= currentIndex) ? item.isActive = true : item.isActive = false;
            });
        }

        /**
         * function to setup practice
         * @param promiseResponseObject
         * @private
         */
        function _practiceSetupProcess(promiseResponseObject) {
            // reset practiceLocationName (i.e name) and npiNumber validity
            vm.practiceForm.name.$setValidity('unique', true);
            vm.practiceForm.npiNumber.$setValidity('npiType2', true);

            promiseResponseObject.then(function(response) {
                if(!Utility.isEmpty(response) && !Utility.isEmpty(response.practiceId)) {
                    // while creating we will get practiceId, which we need in updating practice
                    vm.practice.id = response.practiceId;
                }
                // while filling location form user might use "User Practice info"
                // so we need to save practice info in storage
                Storage.saveToSession("practice", angular.toJson(vm.practice));
                _proceedToNextStep();
            }).catch(function(error) {
                _setErrorMessage(error,
                    PRACTICE_SETUP_CONSTS.PRACTICE_ERROR_MESSAGE,
                    PRACTICE_SETUP_CONSTS.PRACTICE_GENERIC_ERROR_MESSAGE,
                    vm.practiceForm.name,
                    'practice-add-form',
                    vm.practiceForm
                );
            }).finally(function () {
                vm.practiceSetupFlowConfig.savingInformation = false;
            });
        }

        /**
         * function to setup location
         * @param promiseResponseObject
         * @private
         */
        function _locationSetupProcess(promiseResponseObject) {
            // reset practiceLocation validity
            vm.locationForm.locationName.$setValidity('unique', true);
            vm.locationForm.npiNumber.$setValidity('npiType2', true);

            promiseResponseObject.then(function(response) {
                // while creating we will get practice locationId, which we need in updating location
                if(!Utility.isEmpty(response) && !Utility.isEmpty(response.locationId)) {
                    vm.location.locationId = response.locationId;
                }
                _proceedToNextStep();
            }).catch(function(error) {
                _setErrorMessage(error,
                    PRACTICE_SETUP_CONSTS.LOCATION_ERROR_MESSAGE,
                    PRACTICE_SETUP_CONSTS.LOCATION_GENERIC_ERROR_MESSAGE,
                    vm.locationForm.locationName,
                    'location-add-form',
                    vm.locationForm
                );
            }).finally(function () {
                vm.practiceSetupFlowConfig.savingInformation = false;
            });
        }

        /**
         * function to setup provider
         * @param promiseResponseObject
         * @private
         */
        function _providerSetupProcess(promiseResponseObject) {
            // reset provider NPI type error message
            vm.providerForm.npi.$setValidity('npiType1', true);
            promiseResponseObject.then(function() {
                $log.info('saved provider');
                vm.providerForm.$setPristine();
                _clearSessionData();
                $state.go('practice-setup.complete');
            }).catch(function(error) {
                $log.error('Could not create provider', error);
                _setErrorMessage(error,
                    PRACTICE_SETUP_CONSTS.LOCATION_ERROR_MESSAGE,
                    PRACTICE_SETUP_CONSTS.LOCATION_GENERIC_ERROR_MESSAGE,
                    vm.providerForm.firstName,
                    'provider-add-form',
                    vm.providerForm
                );
            }).finally(function () {
                vm.practiceSetupFlowConfig.savingInformation = false;
            });
        }

        /**
         * Function that chains api calls to save practice, location and provider.
         * @param promiseResponseObject api called object
         * @returns {boolean}
         * @private
         */
        function _submitForm(promiseResponseObject) {
            $location.hash('');
            $location.replace();
            vm.practiceSetupFlowConfig.savingInformation = true;
            var currentStep = vm.practiceSetupFlowConfig.currentStep - 1,
                processArray = [
                    _practiceSetupProcess,
                    _locationSetupProcess,
                    _providerSetupProcess
                ];
            if(currentStep > 2 || currentStep < 0) return false;
            processArray[currentStep].call(null, promiseResponseObject);
        }

        /**
         * Function to set error message and form element attribute unique false for invalid data
         * @param error
         * @param specificErrorMessage
         * @param genericErrorMessage
         * @param formElement to set unique false
         * @param hashId DOM element id to scroll
         * @private
         */
        function _setErrorMessage(error, specificErrorMessage, genericErrorMessage, formElement, hashId, formObj) {
            if(!Utility.isEmpty(error.data) && !Utility.isEmpty(error.data.message.trim()) && error.data.message.trim() === specificErrorMessage) {
                formElement.$setValidity('unique', false);
                vm.uniqueErrorMessage = error.data.message;
            } else if(PracticeSetupAddService.isNpiError(error) && hashId === 'practice-add-form') {
                formObj.npiNumber.$setValidity('npiType2', false);
            } else if(PracticeSetupAddService.isNpiError(error) && hashId === 'provider-add-form') {
                formObj.npi.$setValidity('npiType1', false);
            } else if(PracticeSetupAddService.isNpiError(error) && hashId === 'location-add-form') {
                formObj.npiNumber.$setValidity('npiType2', false);
            } else {
                vm.errorMessage = genericErrorMessage;
            }
            $location.hash(hashId);
            $anchorScroll();
        }

        /**
         * function to proceed to next step in practice setup process
         * @private
         */
        function _proceedToNextStep() {
            vm.practiceSetupFlowConfig.currentStepName = vm.practiceSetupFlowConfig.stepNames[vm.practiceSetupFlowConfig.currentStep++];
            _resetBreadCrumbToPreviousOrNextStep();
            _setPageTitle();
        }

        /**
         * Function to clear session for practice
         * @private
         */
        function _clearSessionData() {
            Storage.clearSessionData('practice');
        }

        function resetErrorMessage() {
            vm.errorMessage = null;
        }
    }

})();
