/**
 * Created by nikesh on 10/26/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Register')
        .controller('UserAgreementController', UserAgreementController);

    UserAgreementController.$inject = ['$log', 'RegisterView', 'RegisterService', 'UserAgreementService', 'REGISTER_CONSTS', 'Storage'];

    function UserAgreementController($log, RegisterView, RegisterService, UserAgreementService, REGISTER_CONSTS, Storage) {

        var vm = this;

        // data
        vm.userAgreementFlowConfig = null;
        vm.userAgreementSteps = REGISTER_CONSTS.REGISTRATION_STEPS;
        vm.userAgreementData = {
            termsAndConditions: '',
            hipaaBaa: ''
        };

        vm.user = {};

        vm.registrationGuid = '';

        // functions
        vm.nextStep = nextStep;

        vm.previousStep = previousStep;

        vm.breadcrumbClickedCallback = breadcrumbClickedCallback;

        vm.validateUserAccount = validateUserAccount;


        init();

        function init() {
            //clear session data before initiating process
            _clearSessionData();
            vm.displayHipaaBaaPage = RegisterService.isDisplayHipaaBaaPage();

            vm.userAgreementFlowConfig = UserAgreementService.getUserAgreementFlowConfig(vm.displayHipaaBaaPage);

            RegisterView.configure({
                title: _constructPageTitle()
            });

        }

        function _constructPageTitle() {
            return 'PRACTICE REGISTRATION (STEP ' + vm.userAgreementFlowConfig.currentStep + ' of ' + vm.userAgreementFlowConfig.totalNoOfSteps + ') | ' + vm.userAgreementFlowConfig.currentStepName;
        }

        function _setPageTitle() {
            RegisterView.setPageTitle(_constructPageTitle());
        }

        function nextStep() {
            if(vm.userAgreementFlowConfig.currentStep < vm.userAgreementFlowConfig.totalNoOfSteps) {
                vm.userAgreementFlowConfig.currentStepName = vm.userAgreementFlowConfig.stepNames[vm.userAgreementFlowConfig.currentStep++];
            }

            // Update progress breadcrumb
            if(vm.userAgreementFlowConfig.isHIPAAPresent) {
                _resetBreadCrumb();
                _activateBreadCrumbs();
            }

            _setPageTitle();
        }

        function validateUserAccount(gUid) {
            vm.registrationGuid = gUid;
            $log.info('response from initial user ' + gUid);
            $log.info('guid ' + vm.registrationGuid);

            vm.userAgreementFlowConfig.currentStepName = REGISTER_CONSTS.REGISTRATION_STEPS.VALIDATE_USER;
        }

        function previousStep() {
            --vm.userAgreementFlowConfig.currentStep;
            vm.userAgreementFlowConfig.currentStepName = vm.userAgreementFlowConfig.stepNames[vm.userAgreementFlowConfig.currentStep - 1];

            // Update progress breadcrumb
            if(vm.userAgreementFlowConfig.isHIPAAPresent) {
                _resetBreadCrumb();
                _activateBreadCrumbs();
            }

            _setPageTitle();
        }

        function _resetBreadCrumb() {
            vm.userAgreementFlowConfig.breadcrumbInfo.forEach(function(item) {
                item.isActive = false;
            });
        }

        function _activateBreadCrumbs() {
            var currentIndex = vm.userAgreementFlowConfig.currentStep - 1;

            vm.userAgreementFlowConfig.breadcrumbInfo.forEach(function(item, index) {
                if(index <= currentIndex) {
                    item.isActive = true;
                }
            });
        }

        function breadcrumbClickedCallback(stepName, stepNo) {
            vm.userAgreementFlowConfig.currentStep = stepNo;
            vm.userAgreementFlowConfig.currentStepName = stepName;

            _resetBreadCrumb();
            _activateBreadCrumbs();
        }

        function _clearSessionData() {
            //clear user agreement data if any
            Storage.clearSessionData('userAgreementEsignature');
            Storage.clearSessionData('userAgreementSignedDate');
            Storage.clearSessionData('userAgreementAttestation1');
            Storage.clearSessionData('userAgreementAttestation2');
            //clear hipaa data if any
            Storage.clearSessionData('hipaaBaaEsignature');
            Storage.clearSessionData('hipaaBaaSignedDate');
            Storage.clearSessionData('hipaaBaaAttestation1');
            Storage.clearSessionData('hipaaBaaAttestation2');
        }

    }

})();
