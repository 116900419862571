/**
 * Created by aayush regmi on 4/29/18.
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.MBVMessage', []);
})();
