(function() {
    'use strict';

    angular.module('eAccess.ProviderPortal.Directives').directive('eaFormDirty', eaFormDirty);

    eaFormDirty.$inject = ['$log'];

    // This directive sets form $dirty property depending on some external variable
    function eaFormDirty($log) {
        return {
            require: '^form',
            restrict: 'A',
            scope: {
                isDirty: '<'
            },
            link: function(scope, element, attrs, form) {
                scope.$watch(scope.isDirty, function() {
                    if(scope.isDirty) form.$dirty = true;
                    $log.debug('form is dirty');
                });
            }
        };
    }
})();
