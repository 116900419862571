(function() {
    'use strict';

    var VALUES = {
        PASSWORD_NOT_MATCHED: 'Your passwords do not match.',
        INCORRECT_CURRENT_PASSWORD: 'Incorrect password. Please try again.',
        INCORRECT_PASSWORD_RESPONSE: 'Incorrect current password. Please try again.',
        COMPLEXITY_NOT_MET: 'Your password does not meet the complexity requirements',
        RECENTLY_USED_PASSWORD: 'Password has been used too recently',
        GENERIC_ERROR: 'There was an issue changing your password',
        CURRENT_PASSWORD: 'Password cannot be your current password',
        CURRENT_CHANGED: 'Password has been changed too recently',
        ERROR_TYPES: {
            COMPLEXITY_NOT_MET: 'COMPLEXITY_NOT_MET',
            PASSWORD_NOT_MATCHED: 'PASSWORD_NOT_MATCHED',
            INCORRECT_CURRENT_PASSWORD: 'INCORRECT_CURRENT_PASSWORD',
            RECENTLY_USED_PASSWORD: 'RECENTLY_USED_PASSWORD',
            GENERIC_ERROR: 'GENERIC_ERROR',
            CURRENT_PASSWORD: 'CURRENT_PASSWORD',
            CURRENT_CHANGED: 'CURRENT_CHANGED'
        },
        SUCCESS_MESSAGE: {
            PASSWORD_CHANGED_HEADER: 'Password Successfully Changed!',
            PASSWORD_CHANGED_MESSAGE: 'Your password has been changed.'
        },
        REQUIRED: {
            CURRENT_PASSWORD: 'Current password is required.',
            NEW_PASSWORD: 'New password is required.',
            CONFIRM_PASSWORD: 'Confirm password is required.'
        }
    };

    angular.module('eAccess.ProviderPortal.Constants.ChangePassword', []).constant('CHANGE_PASSWORD_CONSTS', VALUES);
})();
