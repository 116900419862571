/**
 * Created by Bikash Shrestha.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Widgets.MonthlyCompletedBvs')
        .controller('MonthlyCompletedBvsController', MonthlyCompletedBvsController);

    MonthlyCompletedBvsController.$inject = ['$log', 'BV_SELECTION_CONSTS', 'BVSelectionService', 'CaseBVService', 'Utility', 'ELIGIBILITY_CONSTS'];

    function MonthlyCompletedBvsController($log, BV_SELECTION_CONSTS, BVSelectionService, CaseBVService, Utility, ELIGIBILITY_CONSTS) {
        var vm = this;
        
        //data
        vm.loading = false;
        vm.medBvCount = ELIGIBILITY_CONSTS.CONS_N_A;
        vm.rxBvCount = ELIGIBILITY_CONSTS.CONS_N_A;
        vm.na = ELIGIBILITY_CONSTS.CONS_N_A;
        vm.bvAdjudication = null;
        //function
        vm.isCombined = Utility.isCombined;
        vm.isRx = Utility.isRx;
        vm.isMedical = Utility.isMedical;

        vm.$onInit = function() {
            vm.loading = true;
            BVSelectionService.getBvMethod()
                .then(_setBvAdjudicationAndGetBvsCount)
                .then(_setBvsCount)
                .catch(function(error) {
                    $log.error('Something went wrong:', error);
                }).finally(function() {
                    vm.loading = false;
                });
        };

        function _setBvAdjudicationAndGetBvsCount(bvAdjudication) {
            vm.bvAdjudication = bvAdjudication;
            return CaseBVService.fetchMonthlyCompletedBvsCount(bvAdjudication);
        }

        function _setBvsCount(bvCount) {
            if(!Utility.isEmpty(bvCount)) {
                vm.medBvCount = !Utility.isEmpty(bvCount.medManualBv) 
                    ? bvCount.medManualBv 
                    : ELIGIBILITY_CONSTS.CONS_N_A;
                vm.rxBvCount = !Utility.isEmpty(bvCount.rxManualBv) 
                    ? bvCount.rxManualBv 
                    : ELIGIBILITY_CONSTS.CONS_N_A;
            }
        }

    }
})();
