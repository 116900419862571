(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.AdminCodeList')
        .controller('AdminCodeListController', AdminCodeListController);

    AdminCodeListController.$inject = ['$log', '$scope', 'AdminCodeModel', 'AdminCode', 'ELIGIBILITY_CONSTS', 'Utility', 'GLOBAL_CONSTS'];

    function AdminCodeListController($log, $scope, AdminCodeModel, AdminCode, ELIGIBILITY_CONSTS, Utility, GLOBAL_CONSTS) {
        var vm = this;

        // Controller data
        // Controller functions
        vm.canAddNewCode = canAddNewCode;
        vm.addNewCode = addNewCode;
        vm.removeCode = removeCode;
        vm.getFilteredOptions = getFilteredOptions;
        vm.clearAdminCode = clearAdminCode;
        vm.clearAdminCodeErrors = clearAdminCodeErrors;
        vm.otherCode = GLOBAL_CONSTS.OTHER;

        vm.adminOtherCode = ELIGIBILITY_CONSTS.ADMIN_OTHER_CODE;
        vm.adminCode = ELIGIBILITY_CONSTS.ADMIN_CODE;

        vm.validation = {
            adminOtherCode: {
                minLength: 5,
                maxLength: 8
            }
        };

        vm.errorMessages = {
            adminCode: {
                required: 'At least one Admin Code is required',
                duplicate: "You cannot select the same Admin Code value more than once"
            },
            adminOtherCode: {
                required: 'Admin Other Code is required',
                minLength: 'Admin Other Code must be greater than ' + vm.validation.adminOtherCode.minLength + ' characters',
                maxLength: 'Admin Other Code can be no more than ' + +vm.validation.adminOtherCode.maxLength + ' characters'
            }
        };

        // Component set up
        vm.$onInit = function() {
            if(vm.selectedProduct) {
                vm.loadingAdminCodes = true;
                AdminCode.getByProduct(vm.selectedProduct.id).then(function(adminCodeOptions) {
                    vm.adminCodesDropdownOptions = adminCodeOptions;
                    var other = new AdminCodeModel(0, vm.otherCode, vm.otherCode);
                    vm.adminCodesDropdownOptions.push(other);
                }).catch(function(err) {
                    $log.error('error getting admin codes', err);
                    vm.adminCodesDropdownOptions = [];
                }).finally(function() {
                    vm.loadingAdminCodes = false;
                });
            }
        };

        function canAddNewCode() {
            var unfilledCode = vm.adminCodeList.some(function(code) {
                return code === null || Utility.isEmpty(code) || code.id === null || Utility.isEmpty(code.id);
            });
            return vm.adminCodeList.length < 5 && !unfilledCode;
        }

        function addNewCode() {
            vm.adminCodeList.push(null);
        }

        function removeCode(code) {
            vm.adminCodeList = vm.adminCodeList.filter(function(item) {
                return item !== code;
            });
        }

        function getFilteredOptions(index) {
            if(Utility.isEmpty(vm.adminCodesDropdownOptions)) {
                return [];
            }
            return vm.adminCodesDropdownOptions.filter(function(codeOption) {
                return vm.adminCodeList.indexOf(codeOption) === -1 || vm.adminCodeList.indexOf(codeOption) === index;
            });
        }

        function clearAdminCode(index) {
            $log.debug('clear admin code', index);
            vm.adminCodeList[index] = null;
        }

        function clearAdminCodeErrors() {
            if(!Utility.isEmpty(vm.adminCodeList)) {
                angular.forEach(vm.adminCodeList, function(value, index) {
                    if(!Utility.isEmpty(vm.parentForm[vm.adminCode + index])) {
                        vm.parentForm[vm.adminCode+index].$setValidity('duplicate', true);
                    }
                    if(!Utility.isEmpty(vm.parentForm[vm.adminOtherCode + index])) {
                        vm.parentForm[vm.adminOtherCode+index].$setValidity('duplicate', true);
                    }
                });
            }
        }

        vm.$onChanges = function (bindingChanges) {
            var selectedProduct = bindingChanges.selectedProduct.currentValue;
            if(!Utility.isEmpty(selectedProduct)) {
                vm.loadingAdminCodes = true;
                AdminCode.getByProduct(selectedProduct.id).then(function(adminCodeOptions) {
                    vm.adminCodesDropdownOptions = adminCodeOptions;
                    var other = new AdminCodeModel(0, vm.otherCode, vm.otherCode);
                    vm.adminCodesDropdownOptions.push(other);
                }).catch(function(err) {
                    $log.error('error getting admin codes', err);
                    vm.adminCodesDropdownOptions = [];
                }).finally(function() {
                    vm.loadingAdminCodes = false;
                });
            }
        };

    }

})();
