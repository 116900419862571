(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Eligibility')
        .controller('AutoMbvResponseController', AutoMbvResponseController);

    AutoMbvResponseController.$inject = ['$log', 'ELIGIBILITY_CONSTS', 'Utility', 'STYLES_CONSTS', 'moment', 'STATUS_CONSTS'];

    function AutoMbvResponseController($log, ELIGIBILITY_CONSTS, Utility, STYLES_CONSTS, moment, STATUS_CONSTS) {
        var vm = this;

        vm.autoMedMbvMessage = {
            messageIcon: '',
            message: ''
        };
        vm.showRxBenefitsMessage = false;

        // functions
        vm.insuranceStatusDisplayable = insuranceStatusDisplayable;

        vm.$onInit = function() {
            if(!Utility.isEmpty(vm.errorMessage)) {
                vm.autoMedMbvMessage.message = Utility.getAutoMbvWarningMessage(ELIGIBILITY_CONSTS.AUTO_MED_MBV_RESPONSE.ERROR.WARNING_CODE, vm.errorMessage);
                vm.autoMedMbvMessage.messageIcon = STYLES_CONSTS.STYLES.ICONS.FA_FA_TIMES_CIRCLE;
            } else {
                var formattedDate = (!Utility.isEmpty(vm.responseDate)) ? moment(vm.responseDate).format('MM/DD/YYYY') : '';
                vm.autoMedMbvMessage.message = ELIGIBILITY_CONSTS.AUTO_MED_MBV_RESPONSE.SUCCESS.RESPONSE_MESSAGE.replace('_responseDate_', formattedDate);
                vm.autoMedMbvMessage.messageIcon = STYLES_CONSTS.STYLES.ICONS.FA_FA_EXCLAMATION_CIRCLE;
                vm.showRxBenefitsMessage = true;
            }
        };

        function insuranceStatusDisplayable(policyPlan) {
            // Added as part of EP-2465
            return !Utility.isEmpty(policyPlan) && !Utility.isEmpty(policyPlan.status) && policyPlan.status.toLowerCase() === STATUS_CONSTS.STATUS.ACTIVE;
        }
    }
})();
