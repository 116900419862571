/**
 * Created by pramesh on 10/9/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components')
        .component('eaInitialUserInformation', {
            templateUrl: 'components/initial-user/initial-user.html',
            controller: 'InitialUserInformationController as user',
            bindings: {
                user: '=',
                previousCallback: '=',
                validateUserAccount: '<',
                isSingleStep: '<',   // Indicates if user creation is done as part of registration (multi) step or independent user creation (single) step.
                isUpdate: '<',   // Indicates if component is being used for update operation
                myAccount: '<'   // Indicates if own account
            }
        });

})();
