/**
 * @ngdoc Router
 * @module eAccess.ProviderPortal.Components.RxEligibilityResponse
 * @name RxEligibilityResponse
 * @kind Router
 *
 * @description
 * It handles Routing config for RxBv Response
 *
 * @example
 * $state.go('RxEligibilityResponse', {
 *                  responseId: response.patient.externalPatientId,
 *                  status: response.status
 *       });
 *
 * @author girianish
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('RxEligibilityResponse', {
                url: '/eligibility/rx/{responseId}?{adjudicationType}',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Eligibility Rx Coverage Response'
                },
                params: {
                    'responseId': '',
                    'status': '',
                    // added as part of EP-1848 AC 6.4.2
                    // declined is set to false in default
                    // declined will be true when the redirected from patient info decline page
                    'declined': false,
                    'showAutoMBV': true,
                    'adjudicationType': null
                },
                views: {
                    'content@': {
                        templateUrl: 'components/eligibility/bv-selection/rx/rx-response/rx-eligibility-response.html',
                        controller: 'RxEligibilityResponseController as rxResponseCtrl'
                    }
                },
                resolve: {
                    eligibilityResponse: ['Eligibility', '$stateParams', 'Utility', function(Eligibility, $stateParams, Utility) {
                        return Utility.getById.call(Eligibility.getResponseById($stateParams.responseId, $stateParams.adjudicationType), 'bvHistory');
                    }]
                }
            });

    }

})();
