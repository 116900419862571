/**
 * @ngdoc Controller
 * @module eAccess.ProviderPortal.Components.RxEligibilityResponse
 * @name ProviderInformationResponseController
 * @kind Controller
 *
 * @description
 * Controller for the provider informations section
 * @author girianish
 */

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .controller('ProviderInformationResponseController', ProviderInformationResponse);

    ProviderInformationResponse.$inject = ['HELP_TEXT_CONSTS'];

    function ProviderInformationResponse(HELP_TEXT_CONSTS) {
        var vm = this;

        vm.helpText = {
            state: HELP_TEXT_CONSTS.PROVIDER.BILLING_STATE,
            npiNumber: HELP_TEXT_CONSTS.BV_REQUEST.PROVIDER_INFO.NPI,
            practiceProviderId: HELP_TEXT_CONSTS.BV_REQUEST.PROVIDER_INFO.PRACTICE_PROVIDER_ID,
            taxIdNumber: HELP_TEXT_CONSTS.PROVIDER.BILLING_TAX_ID
        };
    }


})();
