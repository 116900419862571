// View-specific data and functions for Patient
(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Services.PatientStyles', ['eAccess.ProviderPortal.Constants.Status'])
        .factory('PatientStyles', PatientStyles);

    PatientStyles.$inject = ['STATUS_CONSTS', '$log', 'Utility', 'STYLES_CONSTS'];

    function PatientStyles(STATUS_CONSTS, $log, Utility, STYLES_CONSTS) {
        var service = {
            setBenefitStatusClass: setBenefitStatusClass,
            setCoverageDetailsClass: setCoverageDetailsClass,
            setCoverageDetailsIcon: setCoverageDetailsIcon
        };

        function setBenefitStatusClass(status) {
            var benefitStatus = Utility.lowerCase(status);

            // Switch formatting is messed up because eslint will break otherwise
            // and telling eslint to expect this formatting somehow breaks jenkins
            switch (benefitStatus) {
                case STATUS_CONSTS.STATUS.ACTIVE:
                    return STYLES_CONSTS.STYLES.SUCCESS_MESSAGE;
                case STATUS_CONSTS.STATUS.UNDETERMINED:
                    return STYLES_CONSTS.STYLES.WARNING_MESSAGE;
                case STATUS_CONSTS.STATUS.INACTIVE:
                    return STYLES_CONSTS.STYLES.ERROR_MESSAGE;
            }
        }

        function setCoverageDetailsClass(status) {
            var coverageStatus = Utility.lowerCase(status);

            // Switch formatting is messed up because eslint will break otherwise
            // and telling eslint to expect this formatting somehow breaks jenkins
            switch (coverageStatus) {
                case STATUS_CONSTS.COVERAGE.PAYER_GUIDELINES_APPLY:
                    return STYLES_CONSTS.STYLES.SUCCESS_MESSAGE;
                case STATUS_CONSTS.COVERAGE.COVERED_WITH_CONDITIONS:
                case STATUS_CONSTS.COVERAGE.UNDETERMINED:
                    return STYLES_CONSTS.STYLES.WARNING_MESSAGE;
                case STATUS_CONSTS.COVERAGE.NOT_COVERED:
                    return STYLES_CONSTS.STYLES.ERROR_MESSAGE;
            }
        }

        function setCoverageDetailsIcon(status) {
            var coverageStatus = Utility.lowerCase(status);

            // Switch formatting is messed up because eslint will break otherwise
            // and telling eslint to expect this formatting somehow breaks jenkins
            switch (coverageStatus) {
                case STATUS_CONSTS.COVERAGE.COVERED_WITH_CONDITIONS:
                case STATUS_CONSTS.COVERAGE.UNDETERMINED:
                    return STYLES_CONSTS.STYLES.ICONS.FA_FA_EXCLAMATION_TRIANGLE;
                case STATUS_CONSTS.COVERAGE.NOT_COVERED:
                    return STYLES_CONSTS.STYLES.ICONS.FA_FA_TIMES_CIRCLE;
            }
        }

        return service;
    }
})();
