(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Components.Eligibility', [
            'eAccess.ProviderPortal.Services.Policy',
            'eAccess.ProviderPortal.Components.MBVForm',
            'eAccess.ProviderPortal.Components.COBHeader',
            'eAccess.ProviderPortal.Services.RxEligibilityResponse'
        ]);
})();
