(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Reverifications')
        .factory('ReverificationService', ReverificationService);

    ReverificationService.$inject = ['$log', '$q', 'API', 'DATA_LIMIT_CONSTS', 'ELIGIBILITY_CONSTS', 'Utility', 'CaseBVService'];

    function ReverificationService($log, $q, API, DATA_LIMIT_CONSTS, ELIGIBILITY_CONSTS, Utility, CaseBVService) {
        var service = {
            loadReverifications: loadReverifications
        };

        return service;

        function loadReverifications(count, offset, limit, sort, nextLink) {
            var queryParams = {
                count: count || false,
                offset: offset || DATA_LIMIT_CONSTS.DEFAULT_OFFSET,
                limit: limit || DATA_LIMIT_CONSTS.CASEBV_LIMIT, //-1 represents all data,
                sort: sort || '-' + ELIGIBILITY_CONSTS.BV_SORT.RECENT_BV
            };

            var endpoint = 'ebv/combined/rever';
            var resultObject = {
                totalCount: DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE,
                responseList: []
            };

            var deferred = $q.defer();

            API.get(endpoint, queryParams, null, null, null, nextLink).then(
                function(response) {
                    resultObject.totalCount = response.data.benefitVerifcationCount;
                    resultObject.responseList = response.data.benefitVerificationList.filter(function(data) {
                        return !Utility.isEmpty(data.coverageResponseDto);
                    }).map(CaseBVService.mapReverificationCase);
                    if(!Utility.isEmpty(response.link)) {
                        resultObject.link = response.link;
                    }
                    deferred.resolve(resultObject);
                }
            ).catch(function(err) {
                $log.debug('err', err);
                deferred.reject(err);
            });

            return deferred.promise;
        }
    }
})();
