(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Directives')
        .directive('eaEmailOrMobileValid', EaEmailOrMobileValid);

    EaEmailOrMobileValid.$inject = ['$log'];

    function EaEmailOrMobileValid($log) {

        return {
            require: 'ngModel',
            restrict: 'A',
            link: function(scope, element, attrs, modelCtrl) {
                modelCtrl.$parsers.push(function (ngModelVal) {
                    // if length of value is greater than 0, value is is a number
                    // and length of value is not 10, return false
                    // Note: !isNaN('') returns true
                    var mobileValidity = !(ngModelVal.length > 0 && !isNaN(ngModelVal) && ngModelVal.length !== 10);

                    var emailPattern = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/i;

                    // if value is not a number, doesn't match email pattern, return false
                    var emailValidity = !(isNaN(ngModelVal) && !ngModelVal.match(emailPattern));

                    modelCtrl.$setValidity('mobile', mobileValidity);
                    modelCtrl.$setValidity('email', emailValidity);
                    modelCtrl.$render();

                    return ngModelVal;
                });

            }
        };
    }

})();
