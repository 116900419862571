/**
 * Created by giri@nish
 */
(function() {
    'use strict';

    angular.module('eAccess.ProviderPortal.Services.ApplicationStartup', [])
            .factory('ApplicationStartup', ApplicationStartup);

    ApplicationStartup.$inject = ['$log', '$window','$urlRouter', 'ConfigurationService', 'ForceLogout', 'BVSelectionService', 'Utility', '$state', 'VerifyUserService', 'AllowedStateService', 'Storage', '$anchorScroll', 'APPLICATION_STARTUP_CONSTS', '$location', 'ToastNotificationService', '$uibModalStack'];

    function ApplicationStartup($log, $window, $urlRouter, ConfigurationService, ForceLogout, BVSelectionService, Utility, $state, VerifyUserService, AllowedStateService, Storage, $anchorScroll, APPLICATION_STARTUP_CONSTS, $location, ToastNotificationService, $uibModalStack) {

        var service = {
            setApplicationStyles : setApplicationStyles,
            deregisterStartCallback: deregisterStartCallback,
            deRegisterCallBack: deRegisterCallBack,
            removeTrailingSlashFromUrl: removeTrailingSlashFromUrl
        };

        function setApplicationStyles() {
            var link = $window.document.createElement('link');
            ConfigurationService.fetchConfiguration().then(function(configData) {
                $log.debug('Config Data:', configData);
                // Once config is fetched, set proper config values
                _setCSSAttribute(link, configData.loginCss);
            }).catch(function(err) {
                $log.debug('Error:', err);
                // There was a problem fetching the configuration
                // Loading nothing uses the default css.
                _setCSSAttribute(link);
            }).finally(function() {
                $window.document.querySelector('head').appendChild(link);
                $log.debug('Configuration loaded');
                link.onload = function() {
                    $urlRouter.sync();
                    $urlRouter.listen();
                };
            });
        }

        function _setCSSAttribute(link, href) {
            link.setAttribute('rel', APPLICATION_STARTUP_CONSTS.PORTAL_STYLES.REL);
            link.setAttribute('type',APPLICATION_STARTUP_CONSTS.PORTAL_STYLES.TYPE);
            link.setAttribute('href', (href) ? href : APPLICATION_STARTUP_CONSTS.PORTAL_STYLES.DEFAULT_FILE_PATH);
        }

        function deregisterStartCallback(toState, fromState, stateParams, event) {

            // close all uib-modal in state change
            $uibModalStack.dismissAll();

            // Reset toast notification on every state change
            ToastNotificationService.clearToastNotification();

                // check if redirecting to login when session is expired
            if(toState.url === APPLICATION_STARTUP_CONSTS.URLS.LOGIN && !Utility.isEmpty(stateParams.sessionExpired) && stateParams.sessionExpired) {
                event.preventDefault();
                return false;
            }
    
                // Check if toState url is accessible by non admin user
            if($window.sessionStorage.getItem('auth_token') && !VerifyUserService.isRoleAdmin() && !AllowedStateService.isStateAccessible(toState.name)) {
                $state.go('dashboard', {forbidden: true}, {reload: true});
                event.preventDefault();
                return false;
            }   
    
                // Check if we are logged in or in a public url
            if(isPublicUrl(toState.url) || $window.sessionStorage.getItem('auth_token')) {
    
                if(Storage.getSessionItem('isPracticeSetup') && isPracticeRegistrationUrl(fromState.url) && !isPracticeRegistrationUrl(toState.url)) {
                    if(toState.url === APPLICATION_STARTUP_CONSTS.URLS.LOGIN && isPracticeRegistrationUrl(fromState.url)) {
                        // force a logout
                        ForceLogout.logout(false);
                        event.preventDefault();
                        return false;
                    } else if(toState.url === APPLICATION_STARTUP_CONSTS.URLS.DASHBOARD && fromState.url === APPLICATION_STARTUP_CONSTS.URLS.COMPLETE) {
                        $log.debug('Do Nothing');
                        //do nothing
                        return false;
                    }
                    ForceLogout.logout(false);
                    event.preventDefault();
                    return false;
                }
    
                if(!isPublicUrl(toState.url) && !isPracticeRegistrationUrl(fromState.url)) {
                    if(!$window.sessionStorage.getItem('auth_token')) {
                        event.preventDefault();
                        return false;
                    }
                }
    
                if(isPublicUrl(fromState.url) && isPracticeRegistrationUrl(toState.url)) {
                    if(!$window.sessionStorage.getItem('auth_token')) {
                        event.preventDefault();
                        return false;
                    }
                }

                if(Storage.getSessionItem('isPracticeSetup') && fromState.url === APPLICATION_STARTUP_CONSTS.URLS.BLANK) {
                    ForceLogout.logout(false);
                    event.preventDefault();
                    return false;
                }
                    // check if user is allowed to switch Med BV and Rx BV
                BVSelectionService.isBVMethodAvailableToRedirect(toState.url, event);
            } else {
                ForceLogout.logout(false);
                event.preventDefault();
            }

        }

        function deRegisterCallBack (event, toState) {
        // Scroll to the top of the page
            $anchorScroll();
        // Set the page title from state data property
            setPageTitle(event, toState, $window);
        }

        function isPublicUrl(url) {
            return APPLICATION_STARTUP_CONSTS.PUBLIC_URLS.indexOf(url) > -1;
        }
    
        function isPracticeRegistrationUrl(url) {
            return APPLICATION_STARTUP_CONSTS.REGISTRATION_URLS.indexOf(url) > -1;
        }

          // Set the page title from state data property
        function setPageTitle(event, toState, $window) {
            var titleKey =  APPLICATION_STARTUP_CONSTS.DEFAULT_TITLE;
        // Set the page title key to the one configured in state or use default one
            if(toState.data.pageTitle) {
                titleKey = toState.data.pageTitle;
            }
            $window.document.title = titleKey;
        }

        /* */
        /**
         * Checks if provided url ends with '/'
         * if it does then remove the '/' and update the url
         * also check if location.path() is '/' because '/' is valid path for dashboard
         * @param nextUrl provided url to be checked
         */
        function removeTrailingSlashFromUrl(nextUrl) {
            nextUrl = Utility.getTrimmedString(decodeURIComponent(nextUrl));
            if(!Utility.isBlank(nextUrl) && Utility.getCharacterAt(nextUrl, nextUrl.length - 1) === APPLICATION_STARTUP_CONSTS.URLS.DASHBOARD && $location.path() !== APPLICATION_STARTUP_CONSTS.URLS.DASHBOARD) {
                // current location path
                var currentPath =  Utility.getTrimmedString(decodeURIComponent($location.path()));
                $location.path(currentPath.substring(0, currentPath.length - 1));
            }
        }

        return service;
    }
})();
