(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.LocationForm')
        .controller('LocationFormController', LocationFormController);

    LocationFormController.$inject = ['HELP_TEXT_CONSTS', 'PracticeLocationService', 'STATUS_CONSTS', '$uibModal', 'WarnOnLeave', 'Storage', '$anchorScroll'];

    function LocationFormController(HELP_TEXT_CONSTS, PracticeLocationService, STATUS_CONSTS, $uibModal, WarnOnLeave, Storage, $anchorScroll) {
        var vm = this;

        vm.helpText = {
            npiNumber: HELP_TEXT_CONSTS.PRACTICE_LOCATION.NPI,
            taxIdNumber: HELP_TEXT_CONSTS.PRACTICE_LOCATION.TAX_ID,
            practiceLocationId: HELP_TEXT_CONSTS.PRACTICE_LOCATION.PRACTICE_LOCATION_ID
        };

        // validation criteria
        vm.validation = PracticeLocationService.getValidationInfo();

        // error messages for fields
        vm.errorMessages = PracticeLocationService.getErrorMessages(vm.validation);

        vm.viewableLocationStatus = STATUS_CONSTS.STATUS.ACTIVE;


        // functions
        vm.submitForm = submitForm;
        vm.usePracticeDetailInfo = usePracticeDetailInfo;
        vm.usePracticeContactInfo = usePracticeContactInfo;
        vm.showWarnOnLeaveOnCancel = showWarnOnLeaveOnCancel;
        vm.resetError = resetError;

        vm.$onInit = function() {
            $anchorScroll();
        };

        function submitForm() {
            vm.submitCallback();
        }

        /**
         * Function that retrieves practice detail info from session and fills in.
         */
        function usePracticeDetailInfo() {
            vm.usePracticeDetailInfoCallback();
        }

        /**
         * Function that retrieves practice contact info from session and fills in.
         */
        function usePracticeContactInfo() {
            vm.usePracticeContactInfoCallback();
        }

        function showWarnOnLeaveOnCancel() {
            WarnOnLeave.show({form: vm.form, toState: {name: 'login'}});
        }

        function resetError(inputName ,value) {
            vm.form[inputName].$setValidity(value, true);
        }
    }
})();
