(function () {
    'use strict';

    angular.module('eAccess.ProviderPortal.Services.UserSession').factory('UserActivityTimer', UserActivityTimer);

    UserActivityTimer.$inject = ['$log', 'Storage'];

    function UserActivityTimer($log, Storage) {

        var service = {
            getLastActiveTime: getLastActiveTime,
            setLastActiveTime: setLastActiveTime
        };

        function getLastActiveTime() {
            return Storage.getSessionItem("idle_time");
        }

        function setLastActiveTime() {
            // save current time in second
            Storage.saveToSession("idle_time",  Math.floor((new Date()) / 1000));
        }

        return service;
    }

})();
