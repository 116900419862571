/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.Components.Practice.controller:PracticeProviderAddController
 * @author Pramesh Bhattarai
 * @requires $log
 * @requires PracticeView
 * @requires $state
 * @requires ProviderService
 * @requires ProviderModel
 * @requires PROVIDER_CONSTS
 * @requires HELP_TEXT_CONSTS
 * @description
 *
 * This is the controller for the Practice Providers page.
 **/

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .controller('PracticeProviderAddController', PracticeProviderAddController);

    PracticeProviderAddController.$inject = ['$log', 'PracticeView', '$state', 'ProviderService', 'ProviderModel', 'PROVIDER_CONSTS', 'HELP_TEXT_CONSTS', 'STATUS_CODE', 'STATUS_CONSTS', 'ToastNotificationService', 'PracticeService', 'Utility', 'GLOBAL_CONSTS', 'PracticeSetupAddService'];

    function PracticeProviderAddController($log, PracticeView, $state, ProviderService, ProviderModel, PROVIDER_CONSTS, HELP_TEXT_CONSTS, STATUS_CODE, STATUS_CONSTS, ToastNotificationService, PracticeService, Utility, GLOBAL_CONSTS, PracticeSetupAddService) {
        var vm = this;

        // data
        vm.provider = {};
        vm.provider.active = true; //default value, while adding new Provider

        vm.savingPracticeProvider = false;

        init();

        function init() {
            PracticeView.configure({
                title: 'My Practice | Add Provider',
                tabIndex: 2
            });
        }

        // function
        vm.savePracticeProvider = savePracticeProvider;

        /**
         * @ngdoc function
         * @name savePractice
         * @methodOf eAccess.ProviderPortal.Components.Practice.Providers.Add.controller:PracticeProviderAddController
         * @description
         *
         * Saves updated practice information.
         */

        function savePracticeProvider() {
            vm.practiceForm.practiceProviderId.$setValidity('unique', true);
            vm.practiceForm.npi.$setValidity('npiType1', true);

            // bail if the form is invalid
            if(vm.practiceForm.$invalid) {
                return false;
            }

            vm.savingPracticeProvider = true;
            _setErrorMessage(false, null, null);
            ProviderService.addProvider(vm.provider)
                .then(function() {
                    vm.practiceForm.$setPristine();
                    $state.go('practice.providers', {providerSubmitted: true});
                })
                .catch(_handleError)
                .finally(function() {
                    vm.savingPracticeProvider = false;
                });
        }

        function _handleError(error) {
            $log.error('save provider error:', error);
            if(error.data && error.status === STATUS_CODE["409_CONFLICT"]) {
                if(error.data.message === PROVIDER_CONSTS.ERROR_MESSAGES.PRACTICE_PROVIDER_ID_IS_NOT_UNIQUE) {
                    _setErrorMessage(true, PROVIDER_CONSTS.ERROR_MESSAGES.PROVIDER_ID_IS_NOT_UNIQUE_TITLE, error.data.message);
                    vm.practiceForm.practiceProviderId.$setValidity('unique', false);
                } else {
                    _setErrorMessage(true, PROVIDER_CONSTS.ERROR_MESSAGES.PROVIDER_ALREADY_EXISTS_TITLE, PROVIDER_CONSTS.ERROR_MESSAGES.PROVIDER_ALREADY_EXISTS_MESSAGE);
                }
            } else if(PracticeSetupAddService.isNpiError(error)) {
                vm.practiceForm.npi.$setValidity('npiType1', false);
            } else {
                // default
                _setErrorMessage(true, GLOBAL_CONSTS.TOAST_TITLE.SYSTEM_ERROR, GLOBAL_CONSTS.TOAST_MESSAGE.SYSTEM_MESSAGE);
            }
        }

        function _setErrorMessage(showModal, title, message) {
            if(showModal) {
                ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, title, message, GLOBAL_CONSTS.TOAST_TYPES.ERROR));
            }
        }
    }
})();
