/**
 * Created by mcaputo on 2/23/17.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ServiceDeliveryInformation')
        .factory('ProductModel', ProductModel);

    ProductModel.$inject = ['Utility'];

    function ProductModel(Utility) {
        function ProductInstance(product) {
            // Default values
            this.id = null;
            this.name = null;
            this.requiresAdminCode = null;
            this.productMethod = null;
            this.defaultDays = null;
            this.defaultQuantity = null;
            this.ndc =null;

            // Safe check
            if(!Utility.isEmpty(product) && !Utility.isEmpty(product.product)) {
                this.id = product.product.productId || null;
                this.name = product.product.displayName || null;
                this.requiresAdminCode = product.product.requiresAdminCode || false;
                this.defaultDays = product.defaultDays || null;
                this.defaultQuantity = product.defaultQuantity || null;
                this.ndc = product.product.ndc || null;
            }

            // Safe check
            if(!Utility.isEmpty(product) && !Utility.isEmpty(product.bvAdjudication)) {
                this.productMethod = product.bvAdjudication.bvAdjudicationMethod || null;
            }
        }

        return ProductInstance;
    }
})();
