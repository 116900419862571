/**
 * Created by pramesh on 12/27/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ViewUser', [
            'eAccess.ProviderPortal.Constants.User'
        ]);

})();
