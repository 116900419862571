/**
 * @ngdoc Controller
 * @module eAccess.ProviderPortal.Components.RxEligibilityResponse
 * @name RxAutoPolicyResponseHeaderController
 * @kind Controller
 *
 * @description
 * Controller for the auto Rx mBV Response policy list header
 * @author pramesh
 */

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .controller('AutoRxPolicyResponseHeaderController', AutoRxPolicyResponseHeader);

    AutoRxPolicyResponseHeader.$inject = ['$log', 'ELIGIBILITY_CONSTS', 'moment', 'Utility', 'STYLES_CONSTS', 'STATUS_CONSTS'];

    function AutoRxPolicyResponseHeader($log, ELIGIBILITY_CONSTS, moment, Utility, STYLES_CONSTS, STATUS_CONSTS) {
        var vm = this;
        vm.autoRxMbvMessage = {};

        // functions
        vm.insuranceStatusDisplayable = insuranceStatusDisplayable;

        vm.$onInit = function () {

            if(Utility.isEmpty(vm.autoMbvErrorMessage)) {
                vm.responseDate = moment(vm.responseDate).format("MM/DD/YYYY");
                vm.autoRxMbvMessage.messageIcon = STYLES_CONSTS.STYLES.ICONS.FA_EXCLAMATION_CIRCLE;
                vm.autoRxMbvMessage.message = ELIGIBILITY_CONSTS.AUTO_RX_MBV_RESPONSE.SUCCESS.RESPONSE_MESSAGE.replace('__response_date__', vm.responseDate);
                vm.autoRxMbvMessage.verifyMessage = ELIGIBILITY_CONSTS.AUTO_RX_MBV_RESPONSE.SUCCESS.RESPONSE_MESSAGE_VERIFY;
            } else {
                vm.autoRxMbvMessage.messageIcon = STYLES_CONSTS.STYLES.ICONS.FA_FA_TIMES_CIRCLE;
                vm.autoRxMbvMessage.message = vm.autoMbvErrorMessage;
            }
        };

        function insuranceStatusDisplayable(policyPlan) {
            return !Utility.isEmpty(policyPlan) && !Utility.isEmpty(policyPlan.status) && !Utility.isEmpty(policyPlan.status.insuranceStatus) && policyPlan.status.insuranceStatus.toLowerCase() === STATUS_CONSTS.STATUS.ACTIVE;
        }

    }
})();
