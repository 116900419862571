/**
 * Created by aayush.regmi on 10/30/2017.
 */

(function() {
    angular
        .module('eAccess.ProviderPortal.Components.UserAuthEmailLink')
        .controller('UserAuthEmailLinkController', UserAuthEmailLinkController);

    UserAuthEmailLinkController.$inject = ['$state', 'USER_AUTH_EMAIL_LINK_CONSTS', 'status', 'ERROR_MESSAGES', 'Utility'];

    function UserAuthEmailLinkController($state, USER_AUTH_EMAIL_LINK_CONSTS, status, ERROR_MESSAGES, Utility) {
        var vm = this;

        vm.verifyEmailLinkToken = verifyEmailLinkToken;

        vm.verifyEmailLinkToken();

        function verifyEmailLinkToken() {
            if(!Utility.isEmpty(status) && status.messageId === USER_AUTH_EMAIL_LINK_CONSTS.LINK_RESPONSE_MESSAGE.SUCCESS) {
                if(!Utility.isEmpty(status.linkType) && status.linkType === USER_AUTH_EMAIL_LINK_CONSTS.LINK_TYPE.CHANGE_PASSWORD) {
                    $state.go('changePassword');
                } else {
                    $state.go('set-password');
                }
            } else if(status.messageId === USER_AUTH_EMAIL_LINK_CONSTS.LINK_RESPONSE_MESSAGE.LINK_EXPIRED) {
                $state.go('link-expired');
            } else if(status.messageId === USER_AUTH_EMAIL_LINK_CONSTS.LINK_RESPONSE_MESSAGE.PASSWORD_SET){
                $state.go('login', {errorMessage: ERROR_MESSAGES.PASSWORD_SET_MESSAGE});
            } else if(status.messageId === USER_AUTH_EMAIL_LINK_CONSTS.LINK_RESPONSE_MESSAGE.PRACTICE_SET){
                $state.go('login', {errorMessage: ERROR_MESSAGES.PRACTICE_SET_MESSAGE});
            } else {
                $state.go('login', {errorMessage: ERROR_MESSAGES.INVALID_REGISTRATION_CODE});
            }
        }
    }

})();
