(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Filters')
        .filter('wrapBy', wrapFilter);

    wrapFilter.$inject = [];

    function wrapFilter() {
        return function (value, wrapWith) {
            // wrapWith value must be of even length, otherwise the final result might look unexpected
            var startWith = '';
            var endWith = '';
            if(wrapWith) {
                var wrapperLength = wrapWith.length;
                startWith = wrapWith.substring(0, wrapperLength/2);
                endWith = wrapWith.substring(wrapperLength/2);
            }

            return startWith + value + endWith;
        };
    }
})();
