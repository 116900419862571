/**
 * Created by nikesh on 8/31/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Modals')
        .controller('CannotDeactivateAllModalController', CannotDeactivateAllModalController);

    CannotDeactivateAllModalController.$inject = ['$log', '$uibModalInstance', 'titleText', 'message', 'displaySecondaryButton'];

    function CannotDeactivateAllModalController($log, $uibModalInstance, titleText, message, displaySecondaryButton) {

        var vm = this;
        vm.titleText = titleText;
        vm.message = message;
        vm.displaySecondaryButton = displaySecondaryButton;

        vm.closeModal = closeModal;

        function closeModal() {
            $uibModalInstance.close();
        }

    }

})();
