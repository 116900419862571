(function () {
    
    'use strict';
    
    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilitySplitResponse')
        .controller('AdditionalFinancialDetailsController', AdditionalFinancialDetails);

    AdditionalFinancialDetails.$inject = ['Utility', 'ConfigurationService', 'STATUS_CONSTS', 'CoverageSummaryService'];

    function AdditionalFinancialDetails(Utility, ConfigurationService, STATUS_CONSTS, CoverageSummaryService) {
        var vm = this;
        vm.displaySingleCostShareOnly =  Utility.isTruthy(ConfigurationService.getOrganizationProperty('displaySingleCostShareOnly') || 0);
        vm.deductibleRemainingAmount = null;
        vm.oopRemainingAmount = null;
        vm.setAmountValues = setAmountValues;

        vm.$onInit = function () {
            setAmountValues();
        };

        function setAmountValues() {
            if(!vm.displaySingleCostShareOnly) { return false; }

            var validRetail = CoverageSummaryService.getValidRetail(vm.coverageSummary);
            var amountValues = CoverageSummaryService.useAmountValues(validRetail, vm.coverageSummary);

            vm.deductibleRemainingAmount = amountValues.deductibleRemainingAmount;
            vm.oopRemainingAmount = amountValues.oopRemainingAmount;
        }

    }


})();
