/**
 * Created by giri@nish.
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Widgets.WeeklyCompletedBvs')
        .controller('WeeklyCompletedBvsController', WeeklyCompletedBvsController);

    WeeklyCompletedBvsController.$inject = ['$log', 'BV_SELECTION_CONSTS', 'BVSelectionService', 'CaseBVService', 'Utility', 'ELIGIBILITY_CONSTS'];

    function WeeklyCompletedBvsController($log, BV_SELECTION_CONSTS, BVSelectionService, CaseBVService, Utility, ELIGIBILITY_CONSTS) {
        var vm = this;
        
        //data
        vm.loading = false;
        vm.medBvCount = ELIGIBILITY_CONSTS.CONS_N_A;
        vm.rxBvCount = ELIGIBILITY_CONSTS.CONS_N_A;
        vm.na = ELIGIBILITY_CONSTS.CONS_N_A;
        vm.bvAdjudication = null;
        //function
        vm.isCombined = Utility.isCombined;
        vm.isRx = Utility.isRx;
        vm.isMedical = Utility.isMedical;

        vm.$onInit = function() {
            vm.loading = true;
            BVSelectionService.getBvMethod()
                .then(_setBvAdjudicaitonAndgetBvsCount)
                .then(_setBvsCount)
                .catch(function(error) {
                    $log.error('Something went worng:', error);
                }).finally(function() {
                    vm.loading = false;
                });
        };

        function _setBvAdjudicaitonAndgetBvsCount(bvAdjudication) {
            vm.bvAdjudication = bvAdjudication;
            return CaseBVService.fetchWeeklyCompletedBvsCount(bvAdjudication);
        }

        function _setBvsCount(bvCount) {
            if(!Utility.isEmpty(bvCount)) {
                vm.medBvCount = !Utility.isEmpty(bvCount.medManualBv) 
                    ? bvCount.medManualBv 
                    : ELIGIBILITY_CONSTS.CONS_N_A;
                vm.rxBvCount = !Utility.isEmpty(bvCount.rxManualBv) 
                    ? bvCount.rxManualBv 
                    : ELIGIBILITY_CONSTS.CONS_N_A;
            }
        }

    }
})();
