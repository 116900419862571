/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.Components.Register.controller:RegisterController
 * @author Nikesh Maharjan
 * @requires $log
 * @requires RegisterView
 * @description
 *
 * This is the controller for the overall Register page.
 **/

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Register')
        .controller('RegisterController', RegisterController);

    RegisterController.$inject = ['$log', '$state', 'RegisterView', 'ConfigurationService', 'REGISTRATION_CONSTS', 'REGISTER_CONSTS', 'RegisterService', 'WarnOnLeave'];

    function RegisterController($log, $state, RegisterView, ConfigurationService, REGISTRATION_CONSTS, REGISTER_CONSTS, RegisterService, WarnOnLeave) {

        var vm = this;

        // data
        vm.viewService = RegisterView;

        //stores the registration configuration values
        vm.registrationConfig = null;

        //flag to denote whether registration code page is required or not
        vm.displayRegistrationCodePage = true;

        //flag to denote if registration code has been verified
        vm.registrationCodeVerified = false;

        //flag to denote if hipaa baa page should be displayed or not
        vm.displayHipaaBaaPage = true;

        // functions
        vm.getRegistrationConfiguration = getRegistrationConfiguration;

        vm.getRegistrationConfiguration();
        vm.openWarnOnLeave = openWarnOnLeave;

        init();

        function init() {
            RegisterView.setHomeLink();
        }

        function getRegistrationConfiguration() {
            vm.loading = true;
            ConfigurationService.fetchRegistrationConfiguration()
                .then(
                    function(response) {
                        vm.registrationConfig = response;
                        vm.displayRegistrationCodePage = vm.registrationConfig.registrationMethod.registrationMethodId !== REGISTRATION_CONSTS.REGISTRATION_METHOD.NO_REGISTRATION;
                        vm.displayHipaaBaaPage = vm.registrationConfig.complianceMethod.complianceCode === REGISTRATION_CONSTS.COMPLIANCE_METHOD.HIPAA_BAA;

                        RegisterService.setDisplayHipaaBaaPage(vm.displayHipaaBaaPage);

                        if(vm.displayRegistrationCodePage) {
                            if(vm.registrationCodeVerified) {
                                // Redirect to user agreement page
                                $state.go(REGISTER_CONSTS.STATE.USER_AGREEMENT);
                            } else {
                                // Redirect to registration code page
                                $state.go(REGISTER_CONSTS.STATE.CODE);
                            }
                        } else {
                            // Redirect to user agreement page
                            $state.go(REGISTER_CONSTS.STATE.USER_AGREEMENT);
                        }
                    }
                )
                .catch(function(err) {
                    $log.debug('There was an error', err);
                })
                .finally(function() {
                    vm.loading = false;
                });
        }

        function openWarnOnLeave() {
            //redirecting to login page directly if home link is clicked from register code and user agreement page
            if($state.current.name === REGISTER_CONSTS.STATE.CODE || $state.current.name === REGISTER_CONSTS.STATE.USER_AGREEMENT) {
                $state.go('login');
            } else {
                WarnOnLeave.show({form: undefined, toState: {name: 'login'}});
            }
        }
    }

})();
