/**
 * Created by nikesh on 8/25/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .controller('PracticeLocationAddController', PracticeLocationAddController);

    PracticeLocationAddController.$inject = ['$log', '$state', 'PracticeView', 'PracticeLocationService', 'PRACTICE_CONSTS', 'INPUT_FORM_VALIDATION', 'PracticeService', 'Utility', 'PracticeSetupAddService'];

    function PracticeLocationAddController($log, $state, PracticeView, PracticeLocationService, PRACTICE_CONSTS, INPUT_FORM_VALIDATION, PracticeService, Utility, PracticeSetupAddService) {
        var vm = this;

        // data
        vm.location = {};
        vm.btnText = 'Save';

        vm.savingPracticeLocation = false;
        vm.errorMessage = null;
        // functions
        vm.savePracticeLocation = savePracticeLocation;

        init();

        function init() {
            PracticeView.configure({
                title: 'My Practice | Add Location',
                tabIndex: 1
            });
            PracticeService.loadPractice().then(function(practiceResponse) {
                if(!Utility.isEmpty(practiceResponse.npi)) vm.location.npi = practiceResponse.npi;
                if(!Utility.isEmpty(practiceResponse.taxId)) vm.location.taxId = practiceResponse.taxId;
            }).catch(function(error) {
                $log.error("Could not fetch Practice information.", error);
            });
        }

        function savePracticeLocation() {
            // reset practiceLocationName(i.e name) validity
            vm.practiceLocationForm.locationName.$setValidity(INPUT_FORM_VALIDATION.VALIDATE.UNIQUE, true);
            vm.practiceLocationForm.practiceLocationId.$setValidity(INPUT_FORM_VALIDATION.VALIDATE.UNIQUE, true);
            vm.practiceLocationForm.npiNumber.$setValidity(INPUT_FORM_VALIDATION.VALIDATE.NPI_TYPE_2, true);

            // bail if the form is invalid
            if(vm.practiceLocationForm.$invalid) {
                return false;
            }

            vm.savingPracticeLocation = true;

            PracticeLocationService.createNewPracticeLocation(vm.location)
                .then(function() {
                    vm.practiceLocationForm.$setPristine();
                    $state.go('practice.locations', {locationSubmitted: true});
                })
                .catch(function(error) {
                    if(error && error.data && error.data.message){
                        vm.errorMessage = error.data.message;
                        if(vm.errorMessage === PRACTICE_CONSTS.ERROR_MESSAGES.LOCATION_NAME_IS_NOT_UNIQUE) {
                            vm.practiceLocationForm.locationName.$setValidity(INPUT_FORM_VALIDATION.VALIDATE.UNIQUE, false);
                        }
                        if(vm.errorMessage === PRACTICE_CONSTS.ERROR_MESSAGES.LOCATION_ID_NOT_UNIQUE) {
                            vm.practiceLocationForm.practiceLocationId.$setValidity(INPUT_FORM_VALIDATION.VALIDATE.UNIQUE, false);
                        }
                        if(PracticeSetupAddService.isNpiError(error)) {
                            vm.practiceLocationForm.npiNumber.$setValidity(INPUT_FORM_VALIDATION.VALIDATE.NPI_TYPE_2, false);
                        }
                    }
                })
                .finally(function() {
                    vm.savingPracticeLocation = false;
                });
        }

    }
})();
