(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('dashboard', {
                url: '/',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Dashboard'
                },
                views: {
                    'content@': {
                        templateUrl: 'components/dashboard/dashboard.html',
                        controller: 'DashboardController as dashboard'
                    },
                    // Cached toast template file, this is necessary to display toast after browser offline
                    // http://cmikavac.net/2015/12/01/pre-caching-html-templates-with-angularjs-ui-router-directive/
                    cachedToastTemplate: {
                        templateUrl: 'components/toast-notification/toast-notification.html'
                    }
                },
                params: {
                    forbidden: false
                }
            });
    }
})();
