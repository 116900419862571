/**
 * @ngdoc service
 * @name eAccess.ProviderPortal.Components.Practice.service:PracticeUserService
 * @author pramesh
 * @requires $http
 * @requires $q
 * @requires $log
 * @requires PracticeUserModel
 * @requires API
 * @description
 *
 * This is the service for practice user-specific API calls.
 **/
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .factory('PracticeUserService', PracticeUserService);

    PracticeUserService.$inject = ['$q', '$log', 'PracticeUserModel', 'API', 'Utility', 'USER_CONSTS', 'DATA_LIMIT_CONSTS'];

    function PracticeUserService($q, $log, PracticeUserModel, API, Utility, USER_CONSTS, DATA_LIMIT_CONSTS) {

        var service = {
            getUsersByStatus: getUsersByStatus,
            getUserByUserId: getUserByUserId,
            fetchLoggedInUser: fetchLoggedInUser,
            updateUserStatus: updateUserStatus,
            resetUserPassword: resetUserPassword
        };

        /**
         * @ngdoc function
         * @name getUsersByStatus
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:PracticeUserService
         * @description
         *
         * Loads practice users based on active/inactive.
         *
         * @param showActive
         * @param count
         * @param length
         * @param offset
         * @param sort
         */
        function getUsersByStatus(count, showActive, length, offset, sort, nextLink) {
            var canceler = $q.defer();
            var deferred = $q.defer();

            var queryParams = {
                active: showActive,
                count: count || DATA_LIMIT_CONSTS.DEFAULT_COUNT,
                limit: length || DATA_LIMIT_CONSTS.DEFAULT_LIMIT,
                offset: offset || DATA_LIMIT_CONSTS.DEFAULT_OFFSET,
                sort: sort || USER_CONSTS.USER_SORT.USER_LAST_NAME_AND_USERNAME
            };

            var userApi = API.get('users', queryParams, null, null, canceler, nextLink);

            userApi.promise.then(function(response) {

                var users = response.data.userList.map(function(user) {
                    return new PracticeUserModel(user);
                });

                var userObject = {
                    totalCount: response.data.userCount || DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE,
                    responseList: users || []
                };
                userObject.link = (!Utility.isEmpty(response.link)) ? response.link : null;
                deferred.resolve(userObject);
            }).catch(function(err) {
                $log.error('Could not fetch user by status:', err);
                deferred.reject(err);
            });

            return {
                promise: deferred.promise,
                canceler: userApi.canceler
            };
        }

        /**
         * @ngdoc function
         * @name getUserByUserId
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:PracticeUserService
         * @description
         *
         * Get user by user id.
         *
         * @param userId
         */
        function getUserByUserId(userId) {
            var deferred = $q.defer();

            API.get('users/' + userId)
                .then(function(response) {
                    var user = new PracticeUserModel(response);
                    deferred.resolve(user);
                })
                .catch(function(err) {
                    $log.error('Could not fetch user:', err);
                    deferred.reject(err);
                });

            return deferred.promise;
        }

        /**
         * @ngdoc function
         * @name resetUserPassword
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:resetUserPassword
         * @description
         *
         * reset user password
         *
         * @param userId
         */
        function resetUserPassword(userId) {
            var requestObj = {
                userId: userId
            };
            return API.post('users/reset/password', requestObj);
        }

        function fetchLoggedInUser() {
            return API.get('users/me').then(function(response) {
                return response;
            }).catch(function(err) {
                $log.error('Could not get user info', err);
                return $q.reject(err);
            });
        }

        function updateUserStatus(requestObj) {
            return API.put('users/update/status', requestObj);
        }

        return service;
    }
})();
