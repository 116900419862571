/**
 * Created by nikesh on 10/24/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.LinkExpired')
        .controller('LinkExpiredController', LinkExpiredController);

    LinkExpiredController.$inject = ['$log', 'LINK_EXPIRED_CONSTS', 'LinkExpiredService', 'ConfigurationService', 'GLOBAL_CONSTS'];

    function LinkExpiredController($log, LINK_EXPIRED_CONSTS, LinkExpiredService, ConfigurationService, GLOBAL_CONSTS) {
        var vm = this;

        //data
        vm.customerSupportInfo = '';
        vm.linkExpiredMessage = LINK_EXPIRED_CONSTS.LINK_EXPIRED_MESSAGE;
        vm.additionalMessage = GLOBAL_CONSTS.EMAIL.USER_NOTIFICATION;
        vm.emailResendMessage = GLOBAL_CONSTS.EMAIL.RESENT_MESSAGE;

        //function
        vm.resendLink = resendLink;

        init();

        function init() {
            ConfigurationService.fetchRegistrationConfiguration().then(
                function (response) {
                    vm.assistanceMessage = response.customerSupport;
                    $log.info('Assistance', response);
                }
            ).catch(function () {
                vm.defaultSuportMessages = [LINK_EXPIRED_CONSTS.CUSTOMER_SUPPORT_DEFAULT.FIRST_MESSAGE, LINK_EXPIRED_CONSTS.CUSTOMER_SUPPORT_DEFAULT.SECOND_MESSAGE];
            });
        }

        function resendLink() {
            LinkExpiredService.resendLink().then(
                function(isResent) {
                    vm.isResent = isResent;
                }
            );
        }
    }


})();
