/**
 * Created by nikesh on 12/27/2017.
 */
(function() {
    'use strict';

    var VALUES = {
        // default pagination offset limit and count value
        DEFAULT_OFFSET: 0,
        DEFAULT_LIMIT: 25,
        DEFAULT_LIMIT_ALL: -1,
        DEFAULT_COUNT_VALUE: 0,
        DEFAULT_COUNT: true,
        DEFAULT_TOTAL_COUNT_VALUE: -1,

        // constants for infinite scroll
        INFINITE_SCROLL_DISTANCE: 900,
        INFINITE_SCROLL_DISTANCE_FOR_MODAL: 1000,
        INITIAL_SCROLL_POSITION: 0,

        // constants for casebv
        CASEBV_LIMIT: 100,
        CASEBV_LIMIT_ALL: -1, //-1 represents all data
        CASEBV_DEFAULT_OFFSET: 0,
        CASEBV_DEFAULT_COUNT: false,
        CASEBV_INCOMPLETE: false,
        ZERO_LIMIT:0,
        DEFAULT_PATIENT_RESULT_COUNT: 0,
        DASHBOARD_REVERIFICATION_LIMIT: 10,
        SCREEN_HEIGHT_LIMIT: 12,
        DEFAULT_ROW_HEIGHT: 36,
        DEFAULT_TABLE_HEIGHT: 26,

        // constants for decimal digits directive
        DECIMAL_PRECISION: 2
    };

    angular.module('eAccess.ProviderPortal.Constants.DataLimit', []).constant('DATA_LIMIT_CONSTS', VALUES);
})();
