(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Widgets.RecentlyCompletedList')
        .controller('RecentlyCompletedListController', RecentlyCompletedListController);

    RecentlyCompletedListController.$inject = ['$log', '$state', 'CaseBVService', 'Eligibility', 'ELIGIBILITY_CONSTS', 'StatusStyles', 'Utility', 'ConfigurationService', '$anchorScroll', 'GLOBAL_CONSTS', 'ToastNotification', 'ToastNotificationService', 'ERROR_MESSAGES', 'STATUS_CONSTS'];

    function RecentlyCompletedListController($log, $state, CaseBVService, Eligibility, ELIGIBILITY_CONSTS, StatusStyles, Utility, ConfigurationService, $anchorScroll, GLOBAL_CONSTS, ToastNotification, ToastNotificationService, ERROR_MESSAGES, STATUS_CONSTS) {
        var vm = this;

        // data
        vm.recentBvs = [];
        vm.recentBvLoaded = true;

        // functions
        vm.viewBVResponse = Eligibility.viewBVResponse;
        vm.getIconClass = StatusStyles.getAdjudicationIconClass;
        vm.getIconTooltipInfo = StatusStyles.getIconTooltipInfo;
        vm.changeState = Utility.shouldChangeState;
        vm.patientManagementStatus = ConfigurationService.getPatientManagementStatus;
        vm.sobCallbackErrorMessage = setSobErrorMessage;
        vm.getUserIconClass = getUserIconClass;
        vm.setResponseData = setResponseData;
        vm._getProductCoverageStatusForSplitResponse = _getProductCoverageStatusForSplitResponse;
        vm._getCoverageSummaryCoverageStatus = _getCoverageSummaryCoverageStatus;
        vm._isCoverageStatusCovered = _isCoverageStatusCovered;
        vm.isSOBDisplay = isSOBDisplay;

        vm.$onInit = function() {
            _fetchRecentBvs();
        };

        function _fetchRecentBvs() {
            vm.recentBvLoaded = false;
            CaseBVService.fetchRecentBVList(null, ELIGIBILITY_CONSTS.DEFAULT_BV_LIST_LIMIT, null).then(function(bvList) {
                if(bvList && bvList.responseList) {
                    setResponseData(bvList.responseList);
                    vm.recentBvs = bvList.responseList
                        .sort(function(a, b) {
                            return new Date(a.dateCompleted) < new Date(b.dateCompleted);
                        });
                }
            }).catch(function(err) {
                $log.error('Error getting recent bv list:', err);
                vm.recentBvs = [];
            }).finally(function () {
                vm.recentBvLoaded = true;
            });
        }

        function setSobErrorMessage(errorMessage) {
            ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, ERROR_MESSAGES.SOB_ERROR.TITLE, errorMessage, GLOBAL_CONSTS.TOAST_TYPES.ERROR));
            $anchorScroll();
        }

        function getUserIconClass(status) {
            return (!Utility.isEmpty(status) && status === false) ? STATUS_CONSTS.STATUS.INACTIVE : STATUS_CONSTS.STATUS.ACTIVE;
        }

        function setResponseData(bvList) {
            var status;
            bvList.forEach(function (response) {
                if (response.bvAdjudicationType === 'Rx') {
                    response.policies.forEach(function (policy) {
                        status = _getProductCoverageStatusForSplitResponse(policy);
                    });
                    response.coverageStatus = status;
                }
                else {
                    response.policies.forEach(function (policy) {
                        response.coverageStatus = policy.plan.coverageStatus;
                    });
                }
            });
        }

        function _getProductCoverageStatusForSplitResponse(policy) {
            if (!Utility.isEmpty(policy) && !Utility.isEmpty(policy.coverageSummary)) {
                var retail30CoverageStatus = _getCoverageSummaryCoverageStatus(policy.coverageSummary.retail30);
                var retail90CoverageStatus = _getCoverageSummaryCoverageStatus(policy.coverageSummary.retail90);
                var mail90CoverageStatus = _getCoverageSummaryCoverageStatus(policy.coverageSummary.mail90);

                if (_isCoverageStatusCovered(retail30CoverageStatus) ||
                    _isCoverageStatusCovered(retail90CoverageStatus) ||
                    _isCoverageStatusCovered(mail90CoverageStatus)) {
                    return Utility.firstCharacterUpperCase(STATUS_CONSTS.COVERAGE.COVERED);
                }
                return retail30CoverageStatus || retail90CoverageStatus || mail90CoverageStatus || STATUS_CONSTS.COVERAGE.UNKNOWN;
            }
            return null;
        }

        function _getCoverageSummaryCoverageStatus(summaryObject) {
            if (!Utility.isEmpty(summaryObject)) {
                if (!Utility.isEmpty(summaryObject.coverageStatus)) {
                    return summaryObject.coverageStatus;
                }
            }
            return null;
        }

        function _isCoverageStatusCovered(status) {
            return Utility.lowerCase(status) === STATUS_CONSTS.COVERAGE.COVERED;
        }

        /**
         * Set isSOBDisplayable value true if coverageStatus is covered or prior authorization required
         */
        function isSOBDisplay(coverageStatus) {
            return  Utility.lowerCase(coverageStatus) === Utility.lowerCase(STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.COVERED)
                || Utility.lowerCase(coverageStatus) === Utility.lowerCase(STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.PRIOR_AUTHORIZATION_REQUIRED);
        }
    }
})();
