/**
 * Created by pramesh on 12/27/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ViewUser')
        .component('eaViewUser', {
            templateUrl: 'components/view-user/view-user.html',
            controller: 'ViewUserController as viewUser',
            bindings: {
                user: '<',
                myAccount: '<'
            }
        });

})();
