/**
 * Created by aayush.regmi on 3/2/2018.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.BvHeader')
        .controller('BvHeaderController', BvHeaderController);

    BvHeaderController.$inject = ['$log', 'ELIGIBILITY_CONSTS', 'BV_SELECTION_CONSTS', '$state', 'BVSelectionService', 'CacheDataService', 'Utility'];

    function BvHeaderController($log, ELIGIBILITY_CONSTS, BV_SELECTION_CONSTS, $state, BVSelectionService, CacheDataService, Utility) {
        var vm = this;
        vm.methodName = null;
        // if BV Adjudication Method is configured for both (Med and RX) then
        // isBVSwitchAvailable will be true, else it will be false
        vm.isBVSwitchAvailable = false;

        vm.setBVMethodName = setBVMethodName;
        vm.getButtonText = getButtonText;
        vm.switchBVMethod = switchBVMethod;

        vm.$onInit = function() {
            BVSelectionService.getBvMethod().then(function(bvMethod) {
                vm.isBVSwitchAvailable = Utility.isCombined(bvMethod);
                vm.setBVMethodName(vm.bvMethod);
            }).catch(function(error) {
                $log.debug("Unable to get BV Method name " + error);
            });

        };

        function setBVMethodName(methodName) {
            vm.methodName = BVSelectionService.getBvMethodName(methodName);
        }

        function getButtonText() {
            return BVSelectionService.getSwitchButtonText(vm.methodName);
        }

        function switchBVMethod() {
            $state.go(BVSelectionService.getSwitchStateName(vm.methodName));
        }

    }
})();
