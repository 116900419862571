(function() {
    'use strict';

    var STATUS_CODE = {
        "409_CONFLICT": 409,
        "404_NOT_FOUND": 404,
        "500_INTERNAL_SERVER_ERROR": 500,
        '-1_OFFLINE': -1
    };

    angular.module('eAccess.ProviderPortal.Constants.StatusCode', []).constant('STATUS_CODE', STATUS_CODE);
})();
