/**
 * @ngdoc service
 * @name eAccess.ProviderPortal.Components.BvHistory.service:BVHistoryView
 * @author Anna Lee
 * @description
 *
 * This is the service for view functions that apply to BV History view.
 **/

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.BVHistory')
        .factory('BVHistoryView', BVHistoryView);

    BVHistoryView.$inject = ['SEARCH_CONSTS', 'BV_SELECTION_CONSTS', 'Utility'];

    function BVHistoryView(SEARCH_CONSTS, BV_SELECTION_CONSTS, Utility) {

        var DEFAULTS = {
            TITLE: 'Benefit Verifications | BV History',
            TAB_INDEX: 0
        };

        var service = {
            configure: configure,
            setTabInfo: setTabInfo,
            setPageTitle: setPageTitle,
            setActiveTab: setActiveTab,
            getSearchFacets: getSearchFacets,
            getDefaultSearchFacets: getDefaultSearchFacets
        };

        function getDefaultSearchFacets() {
            return [
                {
                    searchCategory: SEARCH_CONSTS.SEARCH_TYPE.PATIENT_LAST_NAME,
                    placeholderText: Utility.lowerCase(SEARCH_CONSTS.SEARCH_TYPE.PATIENT_LAST_NAME),
                    value: SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.PATIENT_NAME
                },
                {
                    searchCategory: SEARCH_CONSTS.SEARCH_TYPE.PRACTICE_PATIENT_ID,
                    placeholderText: SEARCH_CONSTS.SEARCH_TYPE.PRACTICE_PATIENT_ID_PLACEHOLDER,
                    value: SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.PRACTICE_PATIENT_ID
                }
            ];
        }

        function getSearchFacets(bvMethod) {
            var result = [];
            var medSearchFacet = {
                searchCategory: SEARCH_CONSTS.SEARCH_TYPE.PAYER_NAME,
                placeholderText: Utility.lowerCase(SEARCH_CONSTS.SEARCH_TYPE.PAYER_NAME),
                value: SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.PAYER_NAME
            };
            var rxSearchFacet = {
                searchCategory: SEARCH_CONSTS.SEARCH_TYPE.PBM_NAME,
                placeholderText: SEARCH_CONSTS.SEARCH_TYPE.PBM_NAME_PLACEHOLDER,
                value: SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.PBM_NAME
            };

            if(Utility.lowerCase(bvMethod) === BV_SELECTION_CONSTS.METHOD_NAME.COMBINED) {
                result.push(medSearchFacet, rxSearchFacet);
            } else if(Utility.lowerCase(bvMethod) === BV_SELECTION_CONSTS.METHOD_NAME.MEDICAL) {
                result.push(medSearchFacet);
            } else if(Utility.lowerCase(bvMethod) === BV_SELECTION_CONSTS.METHOD_NAME.RX) {
                result.push(rxSearchFacet);
            }

            return result;
        }

        return service;

        /**
         * @ngdoc function
         * @name configure
         * @methodOf eAccess.ProviderPortal.Components.BvHistory.service:BVHistoryView
         * @description
         *
         * Configures info for the BV History view based on passed config object.
         *
         * @param config
         * @example configure({
         *     title: '',
         *     tabIndex: '',
         * })
         */

        function configure(config) {
            setTabInfo(config.title, config.tabIndex);
        }


        /**
         * @ngdoc function
         * @name setTabInfo
         * @methodOf eAccess.ProviderPortal.Components.BvHistory.service:BVHistoryView
         * @description
         *
         * Sets the tab info for the BV History view, including setting the page title
         * and changing the active tab.
         *
         * @param title
         * @param tabIndex
         */

        function setTabInfo(title, tabIndex) {
            setPageTitle(title);
            setActiveTab(tabIndex);
        }

        /**
         * @ngdoc function
         * @name setPageTitle
         * @methodOf eAccess.ProviderPortal.Components.BvHistory.service:BVHistoryView
         * @description
         *
         * Sets the page title on the BV History view.
         *
         * @param title
         */

        function setPageTitle(title) {
            service.pageTitle = title || DEFAULTS.TITLE;
        }

        /**
         * @ngdoc function
         * @name setActiveTab
         * @methodOf eAccess.ProviderPortal.Components.BvHistory.service:BVHistoryView
         * @description
         *
         * Sets the active tab on BV History view.
         *
         * @param tabIndex
         */

        function setActiveTab(tabIndex) {
            service.activeTab = tabIndex || DEFAULTS.TAB_INDEX;
        }
    }
})();
