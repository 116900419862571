/**
 * Created by nikesh on 3/11/2018.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxServiceDeliveryForm')
        .controller('RxServiceDeliveryFormController', RxServiceDeliveryFormController);

    RxServiceDeliveryFormController.$inject = ['Product', 'PracticeLocationService', '$log', 'HELP_TEXT_CONSTS', 'RxServiceDeliveryFormService', 'ConfigurationService', 'Utility', 'LOCATION_CONSTS', 'DATA_LIMIT_CONSTS'];

    function RxServiceDeliveryFormController(Product, PracticeLocationService, $log, HELP_TEXT_CONSTS, RxServiceDeliveryFormService, ConfigurationService, Utility, LOCATION_CONSTS, DATA_LIMIT_CONSTS) {
        var vm = this;

        vm.$doCheck = function() {
            if(vm.selectedLocation && vm.locations) {
                _isLocationAvailable(vm.selectedLocation.id);
            }
        };

        vm.loadingProducts = false;
        vm.loadingLocations = false;

        vm.helpText = {
            daysSupply: HELP_TEXT_CONSTS.SERVICE_DELIVERY.DAYS_SUPPLY,
            quantity: HELP_TEXT_CONSTS.SERVICE_DELIVERY.QUANTITY,
            location: HELP_TEXT_CONSTS.BV_REQUEST.SERVICE_DELIVERY.PRACTICE_LOCATION
        };
        vm.validation = RxServiceDeliveryFormService.getValidationInfo();
        vm.errorMessages = RxServiceDeliveryFormService.getErrorMessages(vm.validation);

        vm.fetchProducts = fetchProducts;
        vm.selectedProductChanged = selectedProductChanged;
        vm.getLocations = getLocations;
        vm.$onInit = $onInit;
        vm.locationSelectOnChange = locationSelectOnChange;

        function $onInit() {
            if(Utility.isEmpty(vm.serviceDeliveryInformation)) {
                vm.serviceDeliveryInformation = {};
            }
            // Fetch portal configuration to get bvMethod and call fetch products accordingly
            ConfigurationService.fetchPortalConfiguration().then(
                function(response) {
                    vm.bvMethod = response.portalBVMethod;
                }
            ).catch(function(err){
                $log.debug(err);
            }).finally(function() {
                vm.fetchProducts();
                vm.getLocations();
            });
        }

        function fetchProducts() {
            vm.loadingProducts = true;
            Product.get(vm.bvMethod)
                .then(function(products) {
                    vm.products = products;
                })
                .catch(function() {
                    vm.products = [];
                })
                .finally(function() {
                    vm.loadingProducts = false;
                });
        }

        /**
         * @ngdoc function
         * @name selectedProductChanged
         * @methodOf eAccess.ProviderPortal.controller:RxServiceDeliveryFormController
         * @description
         *
         * Kicks off required changes after a new product is selected
         **/
        function selectedProductChanged() {
            // bail if there is no selected product
            if(!vm.selectedProduct) {
                // Reset DaysSupply and ProductQuantity
                vm.serviceDeliveryInformation.daysSupply = null;
                vm.serviceDeliveryInformation.quantity = null;

                return false;
            }

            //setDaysSupply
            vm.serviceDeliveryInformation.daysSupply = vm.selectedProduct.defaultDays;

            //setProductQuantity
            vm.serviceDeliveryInformation.quantity = vm.selectedProduct.defaultQuantity;

            Product.setCurrentProduct(vm.selectedProduct);
        }

        /**
         * @ngdoc function
         * @name getLocations
         * @methodOf eAccess.ProviderPortal.controller:ServiceDeliveryController
         * @description
         *
         * Get locations for the Service Delivery Information product dropdown.
         **/

        function getLocations() {
            vm.loadingLocations = true;
            var showActiveLocations = true;
            var fetchCount = false;
            PracticeLocationService.getLocationsInformation(showActiveLocations, DATA_LIMIT_CONSTS.DEFAULT_LIMIT_ALL, DATA_LIMIT_CONSTS.DEFAULT_OFFSET, LOCATION_CONSTS.LOCATION_SORT.LOCATION_NAME, fetchCount)
                .then(function(locations) {
                    vm.locations = locations.responseList;
                    if(vm.selectedLocation && vm.locations) {
                        _isLocationAvailable(vm.selectedLocation.id);
                    }
                })
                .catch(function(err) {
                    $log.error(err);
                    vm.locations = [];
                })
                .finally(function() {
                    vm.loadingLocations = false;

                    // Set the selected location if there is one
                    if(vm.selectedLocation) {
                        vm.locations.some(function(location) {
                            if(location.id === vm.selectedLocation.id) {
                                vm.selectedLocation = location;
                            }

                            return location === vm.selectedLocation;
                        });
                    }
                });
        }

        /**
         * function to check if current patient provider is available in provider dropdown list or not
         * if provider is not available in dropdown list, set require error message in form
         */
        function _isLocationAvailable(locationId) {
            if(!Utility.containValueInObjectList(vm.locations, locationId)) {
                vm.parentForm.$submitted = true;
                vm.parentForm.location.$invalid = true;
                vm.parentForm.location.$error.locationNotActive = true;
                vm.parentForm.location.$viewValue = null;
            } else {
                delete vm.parentForm.providers.$error.locationNotActive;
                vm.parentForm.location.$invalid = false;
            }
        }

        function locationSelectOnChange() {
            // vm.showProviderError = false;
            if(vm.selectedLocation && vm.locations) {
                _isLocationAvailable(vm.selectedLocation.id);
            }
        }
    }
})();

