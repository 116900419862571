(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.LocationForm')
        .component('eaLocationForm', {
            templateUrl: 'components/location-form/location-form.html',
            controller: 'LocationFormController as locationForm',
            bindings: {
                submitCallback: '<',
                form: '=',
                location: '=',
                savingLocation: '<',
                nextCallback: '=',
                previousCallback: '=',
                genericErrorMessage: '<',
                isSingleStep: '<',
                goBack: '<',
                usePracticeDetailInfoCallback: '<',
                usePracticeContactInfoCallback: '<',
                errorMessage: '<',
                btnText: '<'
            }
        });

})();
