(function() {
    'use strict';

    var VALUES = {
        STATUS: {
            ACTIVE: 'active',
            UNDETERMINED: 'undetermined',
            INACTIVE: 'inactive',
            INELIGIBLE: 'ineligible',
            INCOMPLETE: 'incomplete',
            NOT_REQUIRED: 'not required',
            NOT_APPLICABLE: 'not applicable',
            REQUIRED: 'required',
            ON_FORMULARY: 'on formulary',
            COMPLETE: 'complete'
        },
        COVERAGE: {
            COVERED: 'covered',
            COVERED_WITH_RESTRICTIONS: 'covered with restrictions',
            COVERED_WITH_CONDITIONS: 'prior authorization required',
            NOT_COVERED: 'not covered',
            PAYER_GUIDELINES_APPLY: 'payer guidelines apply',
            UNDETERMINED: 'undetermined',
            UNKNOWN: 'Unknown',
            PAYER_REQUIREMENTS_APPLY: 'payer requirements apply',
            APPLIES: 'applies',
            NOT_FOUND: 'Not Found'
        },
        BV_STATUS: {
            COMPLETED: 'completed',
            IN_PROGRESS: 'in progress'
        },
        SUCCESS: 'Success',
        ACCOUNT_STATUS: {
            LOCKED: 'LOCKED_OUT',
            PASSWORD_EXPIRED: 'PASSWORD_EXPIRED'
        },
        APPLIES: {
            UNDETERMINED: 'undetermined',
            YES: 'yes',
            NOT_AVAILABLE: 'n/a',
            NO: 'no',
            APPLIES: 'applies'
        },
        DISPLAY_TEXT: {
            PRIOR_AUTH_REQUIRED: 'Prior Authorization required',
            UNDETERMINED: 'Undetermined',
            PAYER_GUIDELINES_APPLY: 'Payer guidelines apply'
        },
        ACCUMULATORS: {
            NOT_FOUND: 'not found',
            NOT_AVAILABLE: 'n/a',
            UNAVAILABLE: 'unavailable'
        },
        EMAIL_TYPE: {
            ACCOUNT_LOCKED: 'ACCOUNT_LOCKED',
            PASSWORD_EXPIRED: 'EXPIRED_PASSWORD'
        },
        TOAST_TYPE: {
            PATIENT_ACTIVATED: 'patientactivated'
        },
        ACTION_TEXT: {
            DEACTIVATE_SELECTED: 'Deactivate Selected',
            REACTIVATE_SELECTED: 'Reactivate Selected'
        },
        FORMULARY: {
            NOT_APPLICABLE: 'not applicable',
            NOT_APPLICABLE_APPLY: 'not applicable apply',
            ON_FORMULARY: 'on formulary',
            UNDETERMINED: 'undetermined',
            NOT_COVERED: 'not covered'
        },
        PRODUCT_COVERAGE_STATUS: {
            PAYER_GUIDELINES_APPLY: 'payer guidelines apply',
            NOT_COVERED: 'not covered',
            PRIOR_AUTHORIZATION_REQUIRED: 'prior authorization required',
            UNDETERMINED: 'undetermined',
            COVERED: 'covered'
        },
        NO_BV_MESSAGE: 'No BV on file',
        PENDING_MANUAL_VERIFICATION: 'Pending Manual Verification'
    };

    angular
        .module('eAccess.ProviderPortal.Constants.Status', [])
        .constant('STATUS_CONSTS', VALUES);
})();
