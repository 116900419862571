(function() {
    'use strict';

    var MonthlyCompletedBvsWidget = {
        bindings: {
            className: '<'
        },
        templateUrl: 'components/dashboard/widgets/monthly-completed-bvs/monthly-completed-bvs.html',
        controller: 'MonthlyCompletedBvsController as monthlyCompletedBvs'
    };

    angular
        .module('eAccess.ProviderPortal.Widgets.MonthlyCompletedBvs')
        .component('eaMonthlyCompletedBvsWidget', MonthlyCompletedBvsWidget);
})();
