/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.controller:InitialUserInformationController
 * @author pramesh
 * @requires $state
 * @requires WarnOnLeave
 * @requires HELP_TEXT_CONSTS
 * @requires USER_CONSTS
 * @requires InitialUserService
 * @description
 *
 * This is the controller for the Initial User Information component.
 **/
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.InitialUserInformation')
        .controller('InitialUserInformationController', InitialUserInformationController);

    InitialUserInformationController.$inject = ['$state', 'WarnOnLeave', 'HELP_TEXT_CONSTS', 'USER_CONSTS', 'InitialUserService', 'Storage', '$uibModal', 'Utility', 'INPUT_FORM_VALIDATION', 'PracticeUserService', 'UserInfoService', 'PATIENT_CONSTS', '$anchorScroll', 'STATUS_CODE', 'ToastNotificationService', 'GLOBAL_CONSTS'];

    function InitialUserInformationController($state, WarnOnLeave, HELP_TEXT_CONSTS, USER_CONSTS, InitialUserService, Storage, $uibModal, Utility, INPUT_FORM_VALIDATION, PracticeUserService, UserInfoService, PATIENT_CONSTS, $anchorScroll, STATUS_CODE, ToastNotificationService, GLOBAL_CONSTS) {

        var vm = this;

        vm.savingInitialUser = false;

        vm.phoneNumberType = {
            work: 'work',
            cell: 'cell'
        };

        vm.contactPreference = {
            phone: 'PHONE',
            fax: 'FAX',
            email: 'EMAIL'
        };

        // validation requirements
        vm.validation = InitialUserService.getValidationInfo();

        // Help text for help icon tooltips
        vm.helpText = {
            userRole: HELP_TEXT_CONSTS.USER.ROLE
        };

        vm.errorMessages = InitialUserService.getErrorMessage(vm.validation);

        vm.emailRegex = /^[a-zA-Z0-9!#$&_*?^{}~-]+(\.[a-zA-Z0-9!#$&_*?^{}~-]+)*@([a-zA-Z0-9]+([a-zA-Z0-9-]*)\.)+[a-zA-Z]+$/;
        // List of possible values for userRole field
        vm.userRoles = [USER_CONSTS.USER_ROLES.ADMIN, USER_CONSTS.USER_ROLES.USER];
        vm.user = null;

        // function
        vm.saveInitialUser = saveInitialUser;
        vm.openWarnOnLeave = openWarnOnLeave;
        vm.goBack = goBack;
        vm.getUserRoleToolTip = getUserRoleToolTip;

        vm.$onInit = function() {
            if(!vm.isSingleStep) {
                vm.user.userRole = USER_CONSTS.USER_ROLES.ADMIN;
            } else {
                PracticeUserService.fetchLoggedInUser().then(function(user) {
                    vm.loggedInUser = user;
                });
            }

        };

        function _isUserAdmin() {
            return !Utility.isEmpty(vm.loggedInUser) && (Utility.lowerCase(vm.loggedInUser.userRole) === Utility.lowerCase(USER_CONSTS.USER_ROLES.ADMIN));
        }

        function getUserRoleToolTip() {
            return _isUserAdmin() ? HELP_TEXT_CONSTS.USER.ROLE : HELP_TEXT_CONSTS.USER.OWN_ROLE;
        }


        function saveInitialUser() {
            if(Utility.isEmpty(vm.isUpdate) || vm.isUpdate === false) {
                vm.initialUserForm.userName.$setValidity(INPUT_FORM_VALIDATION.VALIDATE.UNIQUE, true);
            }

            // bail if the form is invalid
            if(vm.initialUserForm.$invalid) {
                return false;
            }
            vm.savingInitialUser = true;

            // Execute for registration process only
            if(Utility.isEmpty(vm.isSingleStep) || vm.isSingleStep === false) {
                addInitialUser();
            } else if(!Utility.isEmpty(vm.isSingleStep) && vm.isSingleStep) {
                if(Utility.isEmpty(vm.isUpdate) || vm.isUpdate === false) {
                    addUser();
                } else if(vm.isUpdate) {
                    updateUser();
                }
            }
        }

        function _setUserAgreement(user) {
            var eSignature = Storage.getSessionItem('userAgreementEsignature'),
                signedDate = Storage.getSessionItem('userAgreementSignedDate');
            user.eSignature = angular.isDefined(eSignature) ? eSignature : '';
            user.signedDate = angular.isDefined(signedDate) ? new Date(signedDate) : '';
        }

        function _setHipaaAgreement(user) {
            var esignatureHIPAA = Storage.getSessionItem('hipaaBaaEsignature'),
                signedDateHIPAA = Storage.getSessionItem('hipaaBaaSignedDate');
            user.esignatureHIPAA = angular.isDefined(esignatureHIPAA) ? esignatureHIPAA : '';
            user.signedDateHIPAA = angular.isDefined(signedDateHIPAA) ? new Date(signedDateHIPAA) : '';
        }

        function _deleteAgreement() {
            Storage.clearSessionData('userAgreementEsignature');
            Storage.clearSessionData('userAgreementSignedDate');
            Storage.clearSessionData('hipaaBaaEsignature');
            Storage.clearSessionData('hipaaBaaSignedDate');
        }

        function openWarnOnLeave() {
            if(Utility.isEmpty(vm.isSingleStep) || vm.isSingleStep === false) {
                WarnOnLeave.show({form: vm.initialUserForm, toState: {name: 'login'}});
            } else if(!Utility.isEmpty(vm.isSingleStep) && vm.isSingleStep) {
                if(!Utility.isEmpty(vm.isUpdate) && vm.isUpdate) {
                    WarnOnLeave.show({
                        form: vm.initialUserForm,
                        toState: {name: 'practice.users.view', params: {userId: vm.user.userId}}
                    });
                } else {
                    WarnOnLeave.show({form: vm.initialUserForm, toState: {name: 'practice.users'}});
                }
            }
        }

        function goBack() {
            var warnModal = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'warn-on-leave-title',
                arialDescribedBy: 'warn-on-leave-content',
                templateUrl: 'partials/templates/warn-on-leave-modal.html',
                controller: 'WarnOnBackModalController',
                controllerAs: 'warnOnLeave',
                appendTo: angular.element('body')
            });
            warnModal.result.then(function(success) {
                _resetFormData();
                vm.previousCallback();
            }, function(error) {
                angular.noop;
            });
        }

        function _resetFormData() {
            vm.user.firstName = '';
            vm.user.lastName = '';
            vm.user.middleName = '';
            vm.user.userName = '';
            vm.user.email = '';
            vm.user.phoneNo = '';
            vm.user.phoneNoType = '';
            vm.user.faxNo = '';
            vm.user.contactPreference = '';

        }

        /**
         * Used for initial user creation only.
         */
        function addInitialUser() {
            _setUserAgreement(vm.user);
            _setHipaaAgreement(vm.user);
            InitialUserService.addInitialUser(vm.user)
                .then(function(response) {
                    _deleteAgreement();
                    vm.user = {};
                    // Execute for registration process only
                    if(Utility.isEmpty(vm.isSingleStep) || vm.isSingleStep === false) {
                        vm.validateUserAccount(response.registrationGuid);
                    }
                })
                .catch(function(error) {
                    _setMessage(error, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
                })
                .finally(function() {
                    vm.savingInitialUser = false;
                });
        }

        /**
         * Used for adding user from inside an application.
         */
        function addUser() {
            InitialUserService.addUser(vm.user)
                .then(function(response) {
                    vm.user = {};
                    vm.initialUserForm.$setPristine();
                    $state.go('practice.users', {userCreated: true});
                })
                .catch(function(error) {
                    _setMessage(error, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
                })
                .finally(function() {
                    vm.savingInitialUser = false;
                });
        }

        /**
         * Used for updating user.
         */
        function updateUser() {
            InitialUserService.updateUser(vm.user)
                .then(function(response) {
                    var userId = vm.user.userId;
                    // if user is updating their own user information, then we need to save it in session storage
                    if(UserInfoService.userId === userId) {
                        Storage.saveToSession(USER_CONSTS.LAST_NAME_KEY, vm.user.lastName);
                        Storage.saveToSession(USER_CONSTS.FIRST_NAME_KEY, vm.user.firstName);
                    }
                    vm.user = {};
                    vm.initialUserForm.$setPristine();
                    $state.go('practice.users.view', {userId: userId});
                    ToastNotificationService.setGlobalToastInfo(GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
                    $anchorScroll();
                })
                .catch(function(error) {
                    _setMessage(error, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
                })
                .finally(function() {
                    vm.savingInitialUser = false;
                });
        }

        function _setMessage(error, toastType) {
            //This is added here to reset the toast notification message if it already exists in view.
            ToastNotificationService.clearToastNotification();
            //This is added here to remove the user already exists error while displaying toast notification message if it already exists in view.
            if(vm.userExists) {
                vm.userExists = false;
            }

            if(!Utility.isEmpty(error) && !Utility.isEmpty(error.data) && error.status === STATUS_CODE['500_INTERNAL_SERVER_ERROR']) {
                _setToastNotificationMessage(toastType, GLOBAL_CONSTS.TOAST_TITLE.SYSTEM_ERROR, error.data.message);
                $anchorScroll();
            } else if(!Utility.isEmpty(error) && !Utility.isEmpty(error.data) && !Utility.isEmpty(error.data.message)) {
                vm.userExists = true;
                vm.userExistsMessage = error.data.message;
            } else {
                _setToastNotificationMessage(toastType, GLOBAL_CONSTS.TOAST_TITLE.SYSTEM_ERROR, GLOBAL_CONSTS.TOAST_MESSAGE.SYSTEM_MESSAGE);
            }

        }

        function _setToastNotificationMessage(toastType, headerMessage, errorMessage) {
            ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, headerMessage, errorMessage, toastType));
        }

    }
})();
