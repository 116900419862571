/**
 * Created by aayush on 09/12/17.
 */

(function () {
    'use strict';

    angular.module('eAccess.ProviderPortal.Directives').directive('eaFormInvalidFocus', EaFormInvalidFocus);

    // This directive limits an input to only digits
    function EaFormInvalidFocus() {
        return {
            restrict: 'A',
            link: function (scope, element) {
                // set up event handler on the form element
                element.on('submit', function () {
                    element.find('input.ng-invalid,textarea.ng-invalid,select.ng-invalid').first().focus();
                });

            }
        };
    }
})();
