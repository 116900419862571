/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.controller:MBVViewController
 * @author pramesh
 * @requires $log
 * @requires StatusStyles
 * @description
 *
 * This is the controller for the View MBV component.
 **/
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.MBVView')
        .controller('MBVViewController', MBVViewController);

    MBVViewController.$inject = ['$log', 'StatusStyles', 'Eligibility'];

    function MBVViewController($log, StatusStyles, Eligibility) {
        var vm = this;

        vm.selectedDrawer = null;
        vm.selectPolicyDrawer = selectPolicyDrawer;
        vm.showMoreComment = true;
        vm.mbvComment = mbvComment;
        vm.showMoreLess = false;

        vm.setActiveStatusClass = StatusStyles.setActiveStatusClass;
        vm.setCoverageDetailsClass = StatusStyles.setCoverageDetailsClass;


        vm.$onInit = function() {
            // tooltip-values
            vm.tooltipText = Eligibility.getTooltipTextForMBV(vm.adjudicationMethod);
        };

        /**
         * @ngdoc function
         * @name selectPolicyDrawer
         * @methodOf eAccess.ProviderPortal.controller:ViewBVController
         * @description
         *
         * Sets a policy drawer as selected. If the selected drawer is already open,
         * selectPolicyDrawer removes that drawer as the selected drawer, otherwise the drawer
         * is set as selected.
         *
         * @param policyIndex
         */
        function selectPolicyDrawer(policyIndex) {
            vm.selectedDrawer = _policyAlreadyOpen(policyIndex) ? null : policyIndex;
        }

        function _policyAlreadyOpen(policyIndex) {
            return vm.selectedDrawer === policyIndex;
        }

        function mbvComment(comments) {
            if(angular.isUndefined(comments) || comments === null)
                return '';
            var parseComment = comments.split('.');
            if(parseComment.length > 1) {
                vm.showMoreLess = true;
                return parseComment[0] + '.' + parseComment[1] + '.';
            }
            return parseComment[0];
        }
    }
})();
