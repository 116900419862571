/**
 * @ngdoc service
 * @name eAccess.ProviderPortal.Components.Register.service:RegisterService
 * @author Nikesh Maharjan
 * @description
 *
 * This is the common service for register.
 **/
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Register')
        .factory('RegisterService', RegisterService);

    RegisterService.$inject = [];

    function RegisterService() {

        var service = {
            _displayHipaaPage: null,
            setDisplayHipaaBaaPage: setDisplayHipaaBaaPage,
            isDisplayHipaaBaaPage: isDisplayHipaaBaaPage
        };

        return service;

        /**
         * @ngdoc function
         * @name setDisplayHipaaBaaPage
         * @methodOf eAccess.ProviderPortal.Components.Register.service:RegisterService
         * @description
         *
         * Set wheather to display hipaa page or not
         *
         * @param displayHipaa
         */
        function setDisplayHipaaBaaPage(displayHipaa) {
            service._displayHipaaPage = displayHipaa;
        }

        /**
         * @ngdoc function
         * @name isDisplayHipaaBaaPage
         * @methodOf eAccess.ProviderPortal.Components.Register.service:RegisterService
         * @description
         *
         * Get wheather to display hipaa page or not
         *
         */
        function isDisplayHipaaBaaPage() {
            return service._displayHipaaPage;
        }
    }

})();
