(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Filters')
        .filter('tel', telFilter);

    function telFilter() {
        return function(tel) {
            // based off of https://stackoverflow.com/questions/12700145/format-telephone-and-credit-card-numbers-in-angularjs

            if (!tel) {
                return '';
            }

            var value = tel.toString().trim().replace(/^\+/, '');

            if (value.match(/[^0-9]/)) {
                return tel;
            }

            // Only 123-456-7890 numbers are allowed
            if (value.length !== 10) {
                return '';
            }

            var city, number;

            city = value.slice(0, 3);
            number = value.slice(3);

            number = number.slice(0, 3) + '-' + number.slice(3);
            return (city + "-" + number).trim();
        };
    }
})();
