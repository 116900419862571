(function() {
    angular
        .module('eAccess.ProviderPortal.Components.Search')
        .controller('DateRangeSearchController', DateRangeSearchController);

    DateRangeSearchController.$inject = ['$log', 'Search', 'Utility', 'ELIGIBILITY_CONSTS', 'SearchModel', 'SearchCallbackModel', 'SearchPaginationModel', 'SEARCH_CONSTS'];

    function DateRangeSearchController($log, Search, Utility, ELIGIBILITY_CONSTS, SearchModel, SearchCallbackModel, SearchPaginationModel, SEARCH_CONSTS) {
        var vm = this;

        vm.startDatePicker = {
            format: 'MMddyyyy',
            opened: false,
            options: {
                formatYear: 'yy',
                startingDay: 1,
                maxDate: new Date(),
                showWeeks: false
            }
        };

        vm.endDatePicker = {
            format: 'MMddyyyy',
            opened: false,
            options: {
                formatYear: 'yy',
                startingDay: 1,
                maxDate: new Date(),
                showWeeks: false
            }
        };

        vm.toggleStartDatePicker = toggleStartDatePicker;
        vm.toggleEndDatePicker = toggleEndDatePicker;
        vm.clearSearch = clearSearch;
        vm.performSearch = performSearch;

        vm.$onInit = function() {
            $log.debug('created date range search controller', vm.showClearAll);
            vm.searchByDateRange = (!Utility.isEmpty(vm.searchByDateRange)) ? vm.searchByDateRange : true;
        };

        function toggleStartDatePicker() {
            vm.startDatePicker.opened = !vm.startDatePicker.opened;
        }

        function toggleEndDatePicker() {
            vm.endDatePicker.opened = !vm.endDatePicker.opened;
        }

        function clearSearch() {
            vm.startDate = '';
            vm.endDate = '';
            vm.clearAllCallback();
        }

        function performSearch() {
            vm.instructionText = '';
            // check if the search is performed with empty or blank values
            if(Utility.isBlank(vm.startDate) || Utility.isBlank(vm.endDate)) {
                vm.callback(new SearchCallbackModel());
                return;
            }
            var pagination = new SearchPaginationModel(true, null, vm.limit),
                searchModel = new SearchModel(pagination, null, null, vm.viewableStatus, null, vm.filterQuery),
                callbackParam = null;

            if(Utility.lowerCase(vm.searchType) === SEARCH_CONSTS.SEARCH_TYPE.REVERIFICATION) {
                pagination.sortOrder = '-' + ELIGIBILITY_CONSTS.BV_SORT.RECENT_BV; // default sort by desc daysSinceLastBV
                searchModel.searchBy = SEARCH_CONSTS.SEARCH_TYPE.DAY;
                searchModel.query = {minDays: vm.startDate, maxDays: vm.endDate};
                callbackParam = new SearchCallbackModel(Search.reverificationSearch(searchModel), pagination, null, null, vm.startDate, vm.endDate);
                vm.callback(callbackParam);
                return;
            }

            if(vm.startDate && vm.endDate) {
                var formattedStartDate = Utility.parseDateToReadableForm(vm.startDate),
                    formattedEndDate = Utility.parseDateToReadableForm(vm.endDate);

                pagination.sortOrder = '-' + ELIGIBILITY_CONSTS.BV_SORT.DATE_COMPLETED;
                searchModel.query = {startDate: formattedStartDate, endDate: formattedEndDate};
                searchModel.searchBy = 'date';
                callbackParam = new SearchCallbackModel(Search.bvSearch(searchModel), pagination, null, null, formattedStartDate, formattedEndDate);
                vm.callback(callbackParam);
            }

        }
    }
})();
