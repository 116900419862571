(function () {
    'use strict';

    var VALUES = {
        NAME_MIN_LENGTH: 1,
        NAME_MAX_LENGTH: 40,
        LEGAL_NAME_MIN_LENGTH: 1,
        LEGAL_NAME_MAX_LENGTH: 55,
        NPI_MIN_LENGTH: 10,
        NPI_MAX_LENGTH: 10,
        // actual Tax ID length is 9, but this allows for the '-' character in mask / validation
        TAX_ID_MIN_LENGTH: 10,
        // actual Tax ID length is 9, but this allows for the '-' character in mask / validation
        TAX_ID_MAX_LENGTH: 10,
        ADDRESS_1_MIN_LENGTH: 1,
        ADDRESS_1_MAX_LENGTH: 55,
        ADDRESS_2_MIN_LENGTH: 1,
        ADDRESS_2_MAX_LENGTH: 55,
        CITY_MIN_LENGTH: 2,
        CITY_MAX_LENGTH: 30,
        ZIP_MIN_LENGTH: 5,
        ZIP_MAX_LENGTH: 9,
        MAIN_PHONE_MIN_LENGTH: 10,
        MAIN_PHONE_MAX_LENGTH: 10,
        MAIN_FAX_MIN_LENGTH: 10,
        MAIN_FAX_MAX_LENGTH: 10,
        PRACTICE_LOCATION_ID_MIN_LENGTH: 1,
        PRACTICE_LOCATION_ID_MAX_LENGTH: 30,
        ERROR_MESSAGES: {
            LOCATION_NAME_IS_NOT_UNIQUE: 'A location with that name already exists',
            LOCATION_ID_NOT_UNIQUE: 'A location with that Practice Location ID already exists',
            INVALID_PRACTICE_NPI: 'Invalid Practice NPI number',
            PRACTICE_NPI_REQUIRED: 'Practice NPI is required',
            INVALID_LOCATION_NPI: 'Invalid Location NPI number'
        },
        PRACTICE_INFORMATION_EDIT: 'practice.information.edit'
    };

    angular.module('eAccess.ProviderPortal').constant('PRACTICE_CONSTS', VALUES);
})();
