(function() {
    'use strict';

    angular.module('eAccess.ProviderPortal.Directives').directive('eaIsi', EaIsi);

    EaIsi.$inject = ['$window'];

    function EaIsi($window) {
        return {
            restrict: 'A',
            link: function(scope, element) {
                var isiFixed = element.find('#isi-fixed');
                var isiFull = element.find('#isi-full');
                if(isiFixed[0].offsetWidth !== isiFull[0].offsetWidth) {
                    _setIsiFixedWidth();
                }

                angular.element($window).bind('scroll resize', function() {
                    _stickyFooter();
                    scope.$apply();
                });

                function _stickyFooter() {
                    var windowViewPort = $window.innerHeight - isiFixed[0].offsetHeight;
                    var scrollTop = $window.pageYOffset;
                    var elementOffset = isiFull[0].offsetTop;
                    var distance = (elementOffset - scrollTop);
                    if(isiFixed[0].offsetWidth !== isiFull[0].offsetWidth) {
                        _setIsiFixedWidth();
                    }
                    if(distance >= windowViewPort) {
                        isiFixed.css('visibility', 'visible');
                    } else {
                        isiFixed.css('visibility', 'hidden');
                    }
                }

                function _setIsiFixedWidth() {
                    angular.element(isiFixed[0]).css('width', isiFull[0].offsetWidth + 'px');
                }
            }
        };
    }
})();
