/**
 * Created by nikesh on 3/9/2018.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxServiceDeliveryForm')
        .factory('RxServiceDeliveryFormService', RxServiceDeliveryFormService);

    RxServiceDeliveryFormService.$inject = ['RX_SERVICE_DELIVER_FORM_CONSTS'];

    function RxServiceDeliveryFormService(RX_SERVICE_DELIVER_FORM_CONSTS) {
        var service = {
            getErrorMessages: getErrorMessages,
            getValidationInfo: getValidationInfo
        };

        function getErrorMessages(validation) {
            return {
                product: {
                    required: RX_SERVICE_DELIVER_FORM_CONSTS.ERROR_MESSAGES.PRODUCT.REQUIRED
                },
                daysSupply: {
                    required: RX_SERVICE_DELIVER_FORM_CONSTS.ERROR_MESSAGES.DAYS_SUPPLY.REQUIRED,
                    minValue: RX_SERVICE_DELIVER_FORM_CONSTS.ERROR_MESSAGES.DAYS_SUPPLY.MIN_LENGTH.replace('#min', validation.daysSupply.minValue),
                },
                quantity: {
                    required: RX_SERVICE_DELIVER_FORM_CONSTS.ERROR_MESSAGES.QUANTITY.REQUIRED,
                    minValue: RX_SERVICE_DELIVER_FORM_CONSTS.ERROR_MESSAGES.QUANTITY.MIN_LENGTH.replace('#min', validation.quantity.minValue)
                },
                location: {
                    required: RX_SERVICE_DELIVER_FORM_CONSTS.ERROR_MESSAGES.LOCATION.REQUIRED,
                    locationNotActive: RX_SERVICE_DELIVER_FORM_CONSTS.ERROR_MESSAGES.LOCATION.LOCATION_NOT_ACTIVE
                }
            };
        }

        function getValidationInfo() {
            return {
                daysSupply: {
                    minValue: RX_SERVICE_DELIVER_FORM_CONSTS.VALIDATION.DAYS_SUPPLY_MIN_VALUE
                },
                quantity: {
                    minValue: RX_SERVICE_DELIVER_FORM_CONSTS.VALIDATION.QUANTITY_MIN_VALUE
                }
            };
        }

        return service;
    }
})();
