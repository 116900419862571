/**
 * Created by mcaputo on 2/28/17.
 */

(function() {
    'use strict';

    angular.module('eAccess.ProviderPortal.Components.Modals', [
        'eAccess.ProviderPortal.Components.Modals.PlanDetailsModal',
        'eAccess.ProviderPortal.Components.Modals.SOBModal',
        'eAccess.ProviderPortal.Components.Modals.PharmacyModal',
        'eAccess.ProviderPortal.Components.Modals.PharmacyListModal'
    ]);
})();
