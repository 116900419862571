/**
 * @ngdoc Component
 * @module ng
 * @name eaRequestDetail
 * @kind Component
 *
 * @description
 * Request Detail block
 *
 * Basic Usage
 * <eaRequestDetail patientInformation="passPatientInformation"
 *                  providerInformation="passProviderInformation"
 *                  serviceInformation="passServicenformation">
 * </eaRequestDetail>
 *
 * @author girianish
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .component('eaRequestDetails', {
            bindings: {
                patientInformation: '<',
                providerInformation: '<',
                serviceInformation: '<',
                locationInformation: '<',
                responseId: '<',
                requestDate: '<',
                requestDetailsExpanded: '=',
                bvMethod: '<',
                request: '<',
                isPatientActive: '<',
                rxApiType: '<'
            },
            templateUrl: 'components/eligibility/bv-selection/rx/rx-response/request-details/request-details.html',
            controller: 'RequestDetailsController as requestDetailsCtrl'
        });
})();
