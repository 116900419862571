// Controller for Coordination of Benefits Header on eBV Response page
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.COBHeader')
        .controller('COBHeaderController', COBHeaderController);

    COBHeaderController.$inject = ['$log', '$scope', 'Utility', 'HELP_TEXT_CONSTS'];

    function COBHeaderController($log, $scope, Utility, HELP_TEXT_CONSTS) {
        var vm = this;

        vm.cobHeaderTooltip = HELP_TEXT_CONSTS.COB_HEADER.COB;
        vm.showCobHeader = showCobHeader;

        vm.$onInit = function() {
            $log.debug('cob controller', vm.cobMessage);
        };

        // Only display the header if we have a cob message
        function showCobHeader() {
            return !Utility.isEmpty(vm.cobMessage) && vm.cobMessage.length > 0;
        }
    }
})();
