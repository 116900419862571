(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Case')
        .factory('CaseModel', CaseModel);

    CaseModel.$inject = ['$log', 'moment', 'Utility'];

    function CaseModel($log, moment, Utility) {
        var _defaultCaseSettings = {
            responseId: null,
            patient: {},
            provider: {},
            bvResult: {},
            dateCompleted: null,
            bvResponse: null,
            product: null,
            bvType: null,
            bvAdjudicationType: null,
            status: null,
            documentIdentifiers: null,
            mbvId: null,
            patientActive: null,
            rxType: null
        };

        function CaseModelInstance(options, responseDate, bvType, bvAdjudicationType, bvStatus, patientActive, rxType) {
            angular.extend(this, _defaultCaseSettings, options);

            // clean up values that don't map exactly
            var req = null;
            if(!Utility.isEmpty(options) && !Utility.isEmpty(options.request)) req = options.request;
            if(req) {
                this.provider = req.provider;
                this.dateCompleted = req.requestDate;

                if(req.service) {
                    this.product = req.service.product;
                }

            }
            if(!Utility.isEmpty(bvStatus)) this.status = bvStatus;
            if(!Utility.isEmpty(responseDate))
                this.dateCompleted = responseDate;

            if(!Utility.isEmpty(bvType))
                this.bvType = bvType;

            if(!Utility.isEmpty(bvAdjudicationType)) {
                this.bvAdjudicationType = bvAdjudicationType;
                if(Utility.isRx(bvAdjudicationType) && !Utility.isEmpty(req) && !Utility.isEmpty(req.patient)) {
                    this.patient = req.patient;
                }
            }

            if(!Utility.isEmpty(bvStatus)) {
                this.status = bvStatus;
            }

            if(!Utility.isEmpty(patientActive)){
                this.patientActive = patientActive;
            }

            if (!Utility.isEmpty(rxType)) {
                this.rxType = rxType;
            }

        }

        return CaseModelInstance;
    }
})();
