/**
 * Created by pramesh on 10/20/2017.
 */
(function() {
    'use strict';

    var VALUES = {
        VALIDATE_USER: {
            'PAGE_TITLE': 'Practice registration complete',
            'SUCCESS_MESSAGE': 'User account created!',
            'MESSAGE': 'We have sent you an email with instructions for validating your account. If you do not see the email in your inbox in a few minutes, check your junk or spam mailbox.',
            'RESEND_EMAIL': 'If you would like us to resend the email,'
        }
    };

    angular.module('eAccess.ProviderPortal')
        .constant('VALIDATE_USER_CONSTS', VALUES);
})();
