(function() {
    'use strict';

    var VALUES = {
        STYLES : {
            SUCCESS_MESSAGE : 'success-message',
            ERROR_MESSAGE : 'error-message',
            WARNING_MESSAGE : 'warning-message',
            DISABLED : 'disabled',
            ICONS :{
                FA_EXCLAMATION_TRIANGLE : 'fa-exclamation-triangle',
                FA_FA_EXCLAMATION_TRIANGLE: 'fa fa-exclamation-triangle',
                FA_FA_EXCLAMATION_CIRCLE: 'fa fa-exclamation-circle',
                FA_TIMES_CIRCLE : 'fa-times-circle',
                FA_FA_TIMES_CIRCLE : 'fa fa-times-circle',
                FA_EXCLAMATION_WARNING: 'fa-exclamation-triangle warning-message',
                FA_TIMES_CIRCLE_ERROR: 'fa-times-circle error-message icon-font',
                FA_EXCLAMATION_CIRCLE : 'fa-exclamation-circle',
                FA_CHECK: 'fa fa-check',
                FA_CHECK_SUCCESS: 'fa-check success-message',
                FA_CHECK_CIRCLE_SUCCESS: 'fa-check-circle success-message',
                FA_EXCLAMATION_CIRCLE_WARNING: 'fa-exclamation-circle warning-message',
                FA_USER_TIMES : 'fa-user-times',
                FA_USER_PLUS : 'fa-user-plus',
                FA_CHECK_CIRCLE_ACTIVE: 'fa-check-circle status-icon--active',
                FA_CHECK_CIRCLE_INACTIVE: 'fa-check-circle status-icon--active'

            },
            ADJUDICATION_METHOD: {
                MED: {
                    SMALL: 'icon icon--med icon--small',
                    MEDIUM: 'icon icon--med icon--medium'
                },
                RX: {
                    SMALL: 'icon icon--rx icon--small',
                    MEDIUM: 'icon icon--rx icon--medium'
                }
            },
            ICON_SIZE: {
                SMALL: 'Small',
                MEDIUM: 'Medium'
            }
        }
    };

    angular
        .module('eAccess.ProviderPortal.Constants.Styles', [])
        .constant('STYLES_CONSTS', VALUES);
})();
