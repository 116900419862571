/**
 * Created by Lenovo on 2/16/2018.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Services.UserInfo')
        .factory('UserInfoService', UserInfoService);

    UserInfoService.$inject = ['Storage', 'USER_CONSTS', 'Utility', 'PracticeUserService'];

    function UserInfoService(Storage, USER_CONSTS, Utility, PracticeUserService) {
        var service = {
            setCurrentUserInfo: setCurrentUserInfo,
            getUserResponseById: getUserResponseById
        };

        function setCurrentUserInfo() {
            service.firstName = Storage.getSessionItem(USER_CONSTS.FIRST_NAME_KEY);
            service.lastName = Storage.getSessionItem(USER_CONSTS.LAST_NAME_KEY);
            service.userId = Storage.getSessionItem(USER_CONSTS.USER_ID_KEY);
        }

        function getUserResponseById(userId){
            if(service.userId === userId) {
                return Utility.getById.call(PracticeUserService.fetchLoggedInUser(), 'practice.users')
                    .then(function(response) {
                        return {
                            myAccount: true,
                            response: response
                        };
                    });
            } else {
                return Utility.getById.call(PracticeUserService.getUserByUserId(userId), 'practice.users')
                    .then(function(response) {
                        return {
                            myAccount: false,
                            response: response
                        };
                    });
            }
        }

        return service;
    }

})();
