/**
 * Created by kailash.shrestha on 11/09/2017.
 */
(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Components.ConsentMessage')
        .component('eaConsentMessage', {
            templateUrl: 'components/consent-message/consent-message.html',
            controller: 'ConsentMessageController as consentMessage',
            bindings: {
                parentForm: '=',
                patientInfo: '<',
                consent: '<',
                patientConsent: '=',
                showErrorMessage: '<',
                helpText: '<',
                isEditPatient: '<',
                parentDisableSubmit: '=',
                isEhipaa: '=',
                allowedEhipaaStatus : '<',
                ehipaaFormProcessing: '=',
                displayCheckForUpdate: '=',
                profileViewPage: '<',
                patientId: '<'
            }
        });
})();
