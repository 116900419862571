(function() {
    angular
        .module('eAccess.ProviderPortal.Components.Search')
        .controller('FacetedSearchController', FacetedSearchController);

    FacetedSearchController.$inject = ['$rootScope', '$state', '$scope', '$log', 'Search', 'moment', 'SEARCH_CONSTS', 'Utility', 'ELIGIBILITY_CONSTS', 'SearchCallbackModel', 'DATA_LIMIT_CONSTS', 'SearchPaginationModel', 'SearchModel', 'PatientService', 'USER_CONSTS'];

    function FacetedSearchController($rootScope, $state, $scope, $log, Search, moment, SEARCH_CONSTS, Utility, ELIGIBILITY_CONSTS, SearchCallbackModel, DATA_LIMIT_CONSTS, SearchPaginationModel, SearchModel, PatientService, USER_CONSTS) {
        var vm = this;
        vm.searchForDob = false;

        vm.setFacet = setFacet;
        vm.performSearch = performSearch;
        vm.clearSearch = clearSearch;

        vm.$onInit = function() {
            vm.selectedFacet = vm.searchOptions[0];
            //checking if $rootScope.searchType and $rootScope.searchQuery contains values and invoke performSearch() function
            if(!Utility.isEmpty($rootScope.searchType) || !Utility.isEmpty($rootScope.searchQuery)) {
                vm.selectedFacet = null;
                vm.selectedFacet = vm.searchOptions.filter(function(data) {
                    if(data.value === $rootScope.searchType) {
                        return data;
                    }
                })[0];
                $scope.facetedSearch.searchQuery = $rootScope.searchQuery;
                vm.performSearch();
            }
            $rootScope.searchType = null;
            $rootScope.searchQuery = null;
        };

        function setFacet(facet) {
            vm.selectedFacet = facet;
            vm.searchForDob = _isSearchForDOB();
        }

        function _isSearchForDOB() {
            return Utility.lowerCase(vm.selectedFacet.value) === SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.DATE_OF_BIRTH;
        }

        function performSearch() {
            if(Utility.isBlank(vm.searchQuery) && vm.searchType !== SEARCH_CONSTS.SEARCH_TYPE.DASHBOARD) {
                vm.callback(new SearchCallbackModel());
                return;
            }

            // This is a hack to format the dob the way we want in the front end
            // and transform it before sending to the back end, which requires
            // yyyy-mm-dd format.
            var query = angular.copy(vm.searchQuery);

            vm.searchForDob = _isSearchForDOB();
            if(vm.searchForDob) {
                query = PatientService.getArrayFormattedDateString(moment(vm.searchQuery).format(SEARCH_CONSTS.DATE_FORMAT));
            }

            var pagination = _getPaginationDetails(),
                callbackParam = new SearchCallbackModel(null, pagination, vm.selectedFacet, vm.searchQuery),
                searchParam = new SearchModel(pagination, query, vm.selectedFacet, vm.viewableStatus, null);

            switch(vm.searchType) {
                case SEARCH_CONSTS.SEARCH_TYPE.PROVIDER:
                    callbackParam.search = Search.providerSearch(searchParam);
                    vm.callback(callbackParam);
                    break;
                case SEARCH_CONSTS.SEARCH_TYPE.PATIENT:
                case SEARCH_CONSTS.SEARCH_TYPE.PATIENT_MODAL:
                    if(vm.searchType === SEARCH_CONSTS.SEARCH_TYPE.PATIENT_MODAL) searchParam.includePayer = false;
                    callbackParam.search = Search.patientSearch(searchParam);
                    vm.callback(callbackParam);
                    break;
                case SEARCH_CONSTS.SEARCH_TYPE.DASHBOARD:
                    $rootScope.searchType = vm.selectedFacet.value;
                    $rootScope.searchQuery = vm.searchQuery;
                    $rootScope.searchCategory = vm.selectedFacet.searchCategory;
                    $state.go('patient.all');
                    break;
                case SEARCH_CONSTS.SEARCH_TYPE.LOCATION:
                    callbackParam.search = Search.locationSearch(searchParam);
                    vm.callback(callbackParam);
                    break;
                case SEARCH_CONSTS.SEARCH_TYPE.BV:
                    searchParam.pagination.sortOrder = vm.searchForDob ? '-' + ELIGIBILITY_CONSTS.BV_SORT.DATE_COMPLETED : Search.getSortValueForSelectedFaceted(vm.selectedFacet.value);
                    searchParam.filterQuery = vm.filterQuery;
                    searchParam.searchBy = 'parameter';
                    callbackParam.search = Search.bvSearch(searchParam);
                    vm.callback(callbackParam);
                    break;
                case SEARCH_CONSTS.SEARCH_TYPE.REVERIFICATION:
                    searchParam.searchBy = 'parameter';
                    searchParam.pagination.sortOrder = '-' + ELIGIBILITY_CONSTS.BV_SORT.RECENT_BV; // default sort by desc daysSinceLastBV
                    callbackParam.search = Search.reverificationSearch(searchParam);
                    vm.callback(callbackParam);
                    break;
                case SEARCH_CONSTS.SEARCH_TYPE.USER:
                    searchParam.pagination.sortOrder = USER_CONSTS.USER_SORT.USER_LAST_NAME_AND_USERNAME;
                    callbackParam.search = Search.userSearch(searchParam);
                    vm.callback(callbackParam);
                    break;
                default:
                    vm.callback(Search.search(query, vm.selectedFacet.value), vm.selectedFacet.searchCategory, query);
                    break;
            }
        }

        function _getPaginationDetails() {
            return new SearchPaginationModel(null, null, vm.limit, Search.getSortValueForSelectedFaceted(vm.selectedFacet.value));
        }

        function clearSearch() {
            vm.searchQuery = '';
            vm.clearAllCallback();
        }
    }
})();
