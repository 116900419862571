/**
 * Created by bikash.shrestha on 12/31/2018.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ISIContent')
        .factory('IsiService', IsiService);

    IsiService.$inject = [];

    function IsiService() {

        var service = {
            isiHeader: null,
            isiContent: null,
            isiDisclaimer: null,
            stickyIsiContent: null,
            isiVisible: null,
            loadIsiHeaderAndContent: _loadIsiHeaderAndContent
        };

        function _loadIsiHeaderAndContent(publicData) {
            service.isiHeader = publicData.isiHeaderContent;
            service.isiContent = publicData.isiPageContent;
            service.isiDisclaimer = publicData.isiDisclaimer;
            service.isiVisible = publicData.isiVisible;
            service.stickyIsiContent = _parseStickyISIContent(service.isiContent);
        }

        function _parseStickyISIContent(isiContent) {
            if (isiContent) {
                //first replacing <br/> tags with <br> tags if there are any
                isiContent = isiContent.replace(/<br\/>/g, "<br>");
                //'4' has been added so as to include the br tag as well
                var str1 = isiContent.substring(0, (isiContent.indexOf("<br>") + 4));
                var str2 = isiContent.substring((isiContent.indexOf("<br>") + 4), isiContent.length);
                var str3 = str2.substring(0, (str2.indexOf("<br>") + 4));
                return str1 + str3;
            }
        }


        return service;
    }

})();
