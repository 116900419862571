/**
 * Created by nikesh on 10/12/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Register')
        .controller('RegistrationCodeController', RegistrationCodeController);

    RegistrationCodeController.$inject = ['$log', '$state', 'RegisterView', 'HELP_TEXT_CONSTS', '$uibModal', 'REGISTER_CONSTS', 'RegistrationCodeService'];

    function RegistrationCodeController($log, $state, RegisterView, HELP_TEXT_CONSTS, $uibModal, REGISTER_CONSTS, RegistrationCodeService) {

        var vm = this;

        // data
        vm.registrationCode = null;
        vm.assistanceMessage = null;

        // Help Text for registration code tooltip
        vm.helpText = {
            register: HELP_TEXT_CONSTS.REGISTER.REGISTRATION_CODE
        };

        // Messages/text to display in template
        vm.messages = {
            registrationMessage: REGISTER_CONSTS.REGISTRATION_MESSAGE
        };
        vm.errorMessages = {
            registrationCode: {
                invalid: REGISTER_CONSTS.ERROR_MESSAGES.REGISTRATION_CODE.INVALID,
                required: 'Registration Code is required',
                mask: 'Registration code should be no less than ' + REGISTER_CONSTS.VALIDATION.REGISTRATION_CODE.MIN_LENGTH + ' characters'
            }
        };

        // functions
        vm.openRegistrationModal = openRegistrationModal;

        vm.validateRegistrationCode = validateRegistrationCode;

        init();

        function init() {
            RegisterView.configure({
                title: 'REGISTRATION CODE'
            });

            if (!vm.assistanceMessage) {
                // Also fetch modal(assistance) info for requesting registration code.
                RegistrationCodeService.fetchCustomerSupportInfo().then(
                    function(response) {
                        vm.assistanceMessage = response;
                    }
                );
            }
        }

        function openRegistrationModal() {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'partials/templates/request-registration-code-modal.html',
                controller: 'RequestRegistrationCodeModalController',
                controllerAs: 'requestRegistrationCode',
                appendTo: angular.element('body'),
                resolve: {
                    assistanceInfo: function() {
                        return vm.assistanceMessage;
                    }
                }
            });
            modalInstance.result.then(angular.noop, angular.noop);
        }

        function validateRegistrationCode() {
            // resetting validity status
            vm.registrationCodeForm.registrationCode.$setValidity('invalid', true);
            vm.registrationCodeInvalid = false;
            vm.registrationCodeForm.$setSubmitted(true);

            // bail if the form is invalid
            if (vm.registrationCodeForm.$invalid) {
                vm.registrationCodeInvalid = true;
                vm.registrationCodeForm.registrationCode.$setValidity('invalid', false);
                return false;
            }

            if (vm.registrationCode) {
                RegistrationCodeService.validateRegistrationCode(vm.registrationCode).then(
                    function(isValidCode) {
                        if (isValidCode) {
                            // Redirect to user agreement here...
                            $state.go('register.user-agreement');
                        } else {
                            vm.registrationCodeForm.registrationCode.$setValidity('invalid', isValidCode);
                        }
                        vm.registrationCodeInvalid = !isValidCode;
                    }
                ).catch(function(err) {
                    vm.registrationCodeForm.registrationCode.$setValidity('invalid', false);
                    vm.registrationCodeInvalid = true;
                });
            }
        }
    }

})();
