(function() {
    'use strict';

    var eaMbvRecentlyCompletedListWidget = {
        bindings: {
            sobErrorMessage: '=' // error message when unable to get sob pdf file
        },
        templateUrl: 'components/dashboard/widgets/mbv-recently-completed-list/mbv-recently-completed-list.html',
        controller: 'MbvRecentlyCompletedListController as mbvRecentlyCompletedList'
    };

    angular
        .module('eAccess.ProviderPortal.Widgets.MbvRecentlyCompletedList')
        .component('eaMbvRecentlyCompletedListWidget', eaMbvRecentlyCompletedListWidget);
})();
