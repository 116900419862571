(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Services.Policy')
        .factory('PolicyService', PolicyService);

    PolicyService.$inject = ['$log', 'Utility', 'STATUS_CONSTS', 'ELIGIBILITY_CONSTS', 'API', '$q', 'ERROR_MESSAGES', 'STYLES_CONSTS', 'BV_SELECTION_CONSTS'];

    function PolicyService($log, Utility, STATUS_CONSTS, ELIGIBILITY_CONSTS, API, $q, ERROR_MESSAGES, STYLES_CONSTS, BV_SELECTION_CONSTS) {
        var service = {
            getJCode: getJCode,
            getAdminCodes: getAdminCodes,
            getCopayText: getCopayText,
            getCoinsuranceText: getCoinsuranceText,
            getDeductibleText: getDeductibleText,
            getMOOPText: getMOOPText,
            copayApplies: copayApplies,
            coinsuranceApplies: coinsuranceApplies,
            deductibleApplies: deductibleApplies,
            moopApplies: moopApplies,
            getInsuranceStatus: getInsuranceStatus,
            isAppliesUnrecognized: isAppliesUnrecognized,
            getAppliesTextClass: getAppliesTextClass,
            getAppliesIconClass: getAppliesIconClass,
            getBuyBill: getBuyBill,
            getSpecialtyPharmacy: getSpecialtyPharmacy,
            getSpecialtyPharmacyDetails: getSpecialtyPharmacyDetails,
            getPriorAuthorization: getPriorAuthorization,
            getAccumulatorsValue: getAccumulatorsValue,
            setProductCoverageStatus: setProductCoverageStatus,
            isAdminCodeValid: isAdminCodeValid,
            getAdminCodeDescription: getAdminCodeDescription,
            getPALink: getPALink,
            getPAFormId: getPAFormId,
            getPAForm: getPAForm,
            getPbmOrPayerName: getPbmOrPayerName,
            isRxBvAdjudication: isRxBvAdjudication,
            setInsuranceStatus: setInsuranceStatus

        };

        function getJCode(policy) {
            var jcode = null;

            if(!policy.coverage) {
                return null;
            }

            // Use for each because policy.coverage is an object, not array
            // we want to return full Jcode, so Object.keys().filter will not work
            Object.keys(policy.coverage).forEach(function(key, index) {
                // Identify the JCODE
                if(policy.coverage[key].type === 'J_CODE') {
                    jcode = policy.coverage[key];
                }
            });

            return jcode;
        }

        function getAdminCodes(policy) {
            var adminCodes = [];

            if(!policy.coverage) {
                return adminCodes;
            }

            // Use for each because policy.coverage is an object, not array
            // we want to return full admin code, so Object.keys().filter will not work
            Object.keys(policy.coverage).forEach(function(key, index) {
                if(policy.coverage[key].type === 'ADMIN_CODE') {
                    adminCodes.push(policy.coverage[key]);
                }
            });

            return adminCodes;
        }

        function getCopayText(adminCode) {
            if(Utility.isEmpty(adminCode)
                || Utility.isEmpty(adminCode.costShare)
                || Utility.isEmpty(adminCode.costShare.copay)
                || Utility.lowerCase(adminCode.costShare.copay.applies) === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_UNDETERMINED)
                || service.isAppliesUnrecognized(Utility.lowerCase(adminCode.costShare.copay.applies))) {
                return ELIGIBILITY_CONSTS.CONS_UNDETERMINED;
            } else if(Utility.lowerCase(adminCode.costShare.copay.applies) === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_N_A)) {
                return ELIGIBILITY_CONSTS.CONS_N_A;
            }
            return Utility.lowerCase(adminCode.costShare.copay.applies) === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_YES) ? ELIGIBILITY_CONSTS.CONS_APPLIES : ELIGIBILITY_CONSTS.AMOUNT_ZERO;
        }

        function getCoinsuranceText(adminCode) {
            if(Utility.isEmpty(adminCode)
                || Utility.isEmpty(adminCode.costShare)
                || Utility.isEmpty(adminCode.costShare.coinsurance)
                || Utility.lowerCase(adminCode.costShare.coinsurance.applies) === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_UNDETERMINED)
                || service.isAppliesUnrecognized(Utility.lowerCase(adminCode.costShare.coinsurance.applies))) {
                return ELIGIBILITY_CONSTS.CONS_UNDETERMINED;
            } else if(Utility.lowerCase(adminCode.costShare.coinsurance.applies) === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_N_A)) {
                return ELIGIBILITY_CONSTS.CONS_N_A;
            }
            return Utility.lowerCase(adminCode.costShare.coinsurance.applies) === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_YES) ? ELIGIBILITY_CONSTS.CONS_APPLIES : ELIGIBILITY_CONSTS.AMOUNT_ZERO;
        }

        function getDeductibleText(adminCode) {
            if(Utility.isEmpty(adminCode)
                || Utility.isEmpty(adminCode.costShare)
                || Utility.isEmpty(adminCode.costShare.deductible)
                || Utility.lowerCase(adminCode.costShare.deductible.applies) === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_UNDETERMINED)
                || service.isAppliesUnrecognized(Utility.lowerCase(adminCode.costShare.deductible.applies))) {
                return ELIGIBILITY_CONSTS.CONS_UNDETERMINED;
            } else if(Utility.lowerCase(adminCode.costShare.deductible.applies) === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_N_A)) {
                return ELIGIBILITY_CONSTS.CONS_N_A;
            }
            return Utility.lowerCase(adminCode.costShare.deductible.applies) === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_YES) ? ELIGIBILITY_CONSTS.CONS_APPLIES : ELIGIBILITY_CONSTS.CONS_DOES_NOT_APPLIES;
        }

        function getMOOPText(adminCode) {
            if(Utility.isEmpty(adminCode)
                || Utility.isEmpty(adminCode.costShare)
                || Utility.isEmpty(adminCode.costShare.moop)
                || Utility.lowerCase(adminCode.costShare.moop.applies) === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_UNDETERMINED)
                || service.isAppliesUnrecognized(Utility.lowerCase(adminCode.costShare.moop.applies))) {
                return ELIGIBILITY_CONSTS.CONS_UNDETERMINED;
            } else if(Utility.lowerCase(adminCode.costShare.moop.applies) === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_N_A)) {
                return ELIGIBILITY_CONSTS.CONS_N_A;
            }
            return Utility.lowerCase(adminCode.costShare.moop.applies) === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_YES) ? ELIGIBILITY_CONSTS.CONS_APPLIES : ELIGIBILITY_CONSTS.CONS_DOES_NOT_APPLIES;
        }

        function copayApplies(adminCode) {
            if(Utility.isEmpty(adminCode)
                || Utility.isEmpty(adminCode.costShare)
                || Utility.isEmpty(adminCode.costShare.copay)) {
                return false;
            }
            return Utility.lowerCase(adminCode.costShare.copay.applies) === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_YES);
        }

        function coinsuranceApplies(adminCode) {
            if(Utility.isEmpty(adminCode)
                || Utility.isEmpty(adminCode.costShare)
                || Utility.isEmpty(adminCode.costShare.coinsurance)) {

                return false;
            }
            return Utility.lowerCase(adminCode.costShare.coinsurance.applies) === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_YES);
        }

        function deductibleApplies(adminCode) {
            if(Utility.isEmpty(adminCode)
                || Utility.isEmpty(adminCode.costShare)
                || Utility.isEmpty(adminCode.costShare.deductible)) {

                return false;
            }
            return Utility.lowerCase(adminCode.costShare.deductible.applies) === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_YES);
        }

        function moopApplies(adminCode) {
            if(Utility.isEmpty(adminCode)
                || Utility.isEmpty(adminCode.costShare)
                || Utility.isEmpty(adminCode.costShare.moop)) {

                return false;
            }
            return Utility.lowerCase(adminCode.costShare.moop.applies) === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_YES);
        }

        /**
         * @ngdoc function
         * @name getBuyBill
         * @methodOf eAccess.ProviderPortal.Service:PolicyService
         *
         * Returns the buy and bill description text of a policy. Each code has a
         * different Buy and Bill value, but this will only return the JCode's
         * buy and bill value.
         */
        function getBuyBill(policy) {
            var jcode = getJCode(policy);
            var displayText = ELIGIBILITY_CONSTS.CONS_UNDETERMINED;

            if(angular.isDefined(jcode)
                && jcode !== null
                && angular.isDefined(jcode.coverageDetails)
                && jcode.coverageDetails !== null
                && angular.isDefined(jcode.coverageDetails.buyBill)) {

                switch(Utility.lowerCase(jcode.coverageDetails.buyBill)) {
                    case Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_YES):
                        displayText = ELIGIBILITY_CONSTS.CONS_AVAILABLE;
                        break;
                    case Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_NO):
                        displayText = ELIGIBILITY_CONSTS.CONS_NOT_AVAILABLE;
                        break;
                    case Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_UNDETERMINED):
                        displayText = ELIGIBILITY_CONSTS.CONS_UNDETERMINED;
                        break;
                    case Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_N_A):
                        displayText = ELIGIBILITY_CONSTS.CONS_NOT_AVAILABLE;
                        break;
                }
            }
            return displayText;
        }

        /**
         * @ngdoc function
         * @name getSpecialtyPharmacy
         * @methodOf eAccess.ProviderPortal.Service:PolicyService
         *
         * Returns the specialty pharmacy description text of a policy. Each code has a
         * different Buy and Bill value, but this will only return the JCode's
         * buy and bill value.
         */
        function getSpecialtyPharmacy(policy) {
            var jcode = getJCode(policy);
            var displayText = ELIGIBILITY_CONSTS.CONS_UNDETERMINED;

            if(angular.isDefined(jcode)
                && jcode !== null
                && angular.isDefined(jcode.coverageDetails)
                && jcode.coverageDetails !== null
                && angular.isDefined(jcode.coverageDetails.specialtyPharmacy)) {
                var jCodeAvailable = Utility.lowerCase(jcode.coverageDetails.specialtyPharmacy.available);
                var jCodeRequired = Utility.lowerCase(jcode.coverageDetails.specialtyPharmacy.required);
                if(jCodeAvailable === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_NO) || jCodeAvailable === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_N_A))
                    displayText = ELIGIBILITY_CONSTS.CONS_NOT_AVAILABLE;
                else if(jCodeAvailable === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_UNDETERMINED) || jCodeRequired === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_UNDETERMINED))
                    displayText = ELIGIBILITY_CONSTS.CONS_UNDETERMINED;
                else if(jCodeAvailable === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_YES) && (jCodeRequired === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_NO) || jCodeRequired === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_N_A)))
                    displayText = ELIGIBILITY_CONSTS.CONS_AVAILABLE;
                else if(jCodeAvailable === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_YES) || jCodeRequired === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_YES))
                    displayText = ELIGIBILITY_CONSTS.CONS_REQUIRED;
            }

            return displayText;
        }

        /**
         * @ngdoc function
         * @name getSpecialtyPharmacyDetails
         * @methodOf eAccess.ProviderPortal.Components:PolicyCoverageListController
         *
         * Returns each specialty pharmacy details.
         */
        function getSpecialtyPharmacyDetails(policy) {
            var jcode = getJCode(policy);
            var specialtyPharmacy = [];

            if(angular.isDefined(jcode)
                && jcode !== null
                && angular.isDefined(jcode.coverageDetails)
                && jcode.coverageDetails !== null
                && angular.isDefined(jcode.coverageDetails.specialtyPharmacy)) {
                specialtyPharmacy = jcode.coverageDetails.specialtyPharmacy.pharmacies;
            }
            return specialtyPharmacy;
        }

        /**
         * @ngdoc function
         * @name getPriorAuthorization
         * @methodOf eAccess.ProviderPortal.Service:PolicyService
         *
         * Returns the BRA Prior Authorization eBV Response status .
         */
        function getPriorAuthorization(policy) {
            var jcode = getJCode(policy);
            var adminCodes = getAdminCodes(policy);
            var adminCodesPriorAuth = [];
            var displayText = ELIGIBILITY_CONSTS.CONS_UNDETERMINED;
            var jcodePriorAuth = '';
            var adminCodePriorAuth = '';

            for(var key in adminCodes) {
                if(Utility.isEmpty(adminCodes[key].coverageDetails)) {
                    // Added as part of EP-1328
                    // Pushing no when coverageDetails is not defined (i.e undefined or null)
                    adminCodesPriorAuth.push(Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_NO));
                }
                else {
                    adminCodesPriorAuth.push(Utility.lowerCase(adminCodes[key].coverageDetails.priorAuth));
                }
            }

            if (Utility.containValueInArray(adminCodesPriorAuth, Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_YES))) {
                adminCodePriorAuth = ELIGIBILITY_CONSTS.CONS_YES;
            } else if(Utility.containValueInArray(adminCodesPriorAuth, Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_UNDETERMINED))) {
                adminCodePriorAuth = ELIGIBILITY_CONSTS.CONS_UNDETERMINED;
            } else if(Utility.containEveryValueSameInArray(adminCodesPriorAuth, Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_NO))) {
                adminCodePriorAuth = ELIGIBILITY_CONSTS.CONS_NO;
            } else if(Utility.containEveryValueSameInArray(adminCodesPriorAuth, Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_N_A))) {
                adminCodePriorAuth = ELIGIBILITY_CONSTS.CONS_N_A;
            } else {
                // Default to undetermined
                adminCodePriorAuth = ELIGIBILITY_CONSTS.CONS_UNDETERMINED;
            }
            adminCodePriorAuth = Utility.lowerCase(adminCodePriorAuth);

            if(angular.isDefined(jcode)
                && jcode !== null
                && angular.isDefined(jcode.coverageDetails)
                && jcode.coverageDetails !== null
                && angular.isDefined(jcode.coverageDetails.priorAuth)) {
                jcodePriorAuth = Utility.lowerCase(jcode.coverageDetails.priorAuth);
            }

            if (jcodePriorAuth === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_YES) || adminCodePriorAuth === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_YES)) {
                // Return immediately because YES is highest priority
                return displayText = ELIGIBILITY_CONSTS.CONS_REQUIRED;
            }
            // No + N/A combinations
            else if((jcodePriorAuth === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_NO) && adminCodePriorAuth === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_NO))
                || (jcodePriorAuth === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_N_A) && adminCodePriorAuth === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_N_A))
                || (jcodePriorAuth === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_N_A) && adminCodePriorAuth === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_NO))
                || (jcodePriorAuth === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_NO) && adminCodePriorAuth === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_N_A))
            ) {
                displayText = ELIGIBILITY_CONSTS.CONS_NOT_REQUIRED;
            } else if(jcodePriorAuth === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_UNDETERMINED) || adminCodePriorAuth === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_UNDETERMINED)) {
                displayText = ELIGIBILITY_CONSTS.CONS_UNDETERMINED;
            }

            return displayText;
        }

        function getInsuranceStatus(plan) {
            if(!plan) {
                return null;
            }

            var status = Utility.lowerCase(plan.status);
            switch(status) {
                case STATUS_CONSTS.STATUS.INACTIVE:
                    return ELIGIBILITY_CONSTS.CONS_IN_ACITVE;
                case STATUS_CONSTS.STATUS.UNDETERMINED:
                    return ELIGIBILITY_CONSTS.CONS_UNDETERMINED;
                case STATUS_CONSTS.STATUS.INCOMPLETE:
                    return ELIGIBILITY_CONSTS.CONS_UNDETERMINED;
                case STATUS_CONSTS.STATUS.INELIGIBLE:
                    return ELIGIBILITY_CONSTS.CONS_INELIGIBLE;
                case STATUS_CONSTS.STATUS.ACTIVE:
                    return ELIGIBILITY_CONSTS.CONS_ACITVE;
            }
        }

        function setInsuranceStatus(policy, bvAdjudicationType) {
            if(isRxBvAdjudication(bvAdjudicationType)) {
                if(!Utility.isEmpty(policy) && !Utility.isEmpty(policy.plan) && !Utility.isEmpty(policy.plan.status) && !Utility.isEmpty(policy.plan.status.insuranceStatus)){
                    var insuranceStatus = {
                        status: policy.plan.status.insuranceStatus
                    };
                    return getInsuranceStatus(insuranceStatus);
                } else {
                    return null;
                }
            }
            return (!Utility.isEmpty(policy) && !Utility.isEmpty(policy.plan)) ? getInsuranceStatus(policy.plan) : null;
        }

        function isAppliesUnrecognized(applies) {
            return (applies !== STATUS_CONSTS.APPLIES.YES && applies !== STATUS_CONSTS.APPLIES.NO && applies !== STATUS_CONSTS.APPLIES.UNDETERMINED && applies !== STATUS_CONSTS.APPLIES.NOT_AVAILABLE);
        }

        function getAppliesTextClass(text) {
            switch(Utility.lowerCase(text)) {
                case STATUS_CONSTS.APPLIES.APPLIES:
                    return STYLES_CONSTS.STYLES.SUCCESS_MESSAGE;
                case STATUS_CONSTS.APPLIES.UNDETERMINED:
                    return STYLES_CONSTS.STYLES.WARNING_MESSAGE;
                default:
                    return '';
            }
        }

        function getAppliesIconClass(text) {
            switch (Utility.lowerCase(text)) {
                case STATUS_CONSTS.APPLIES.APPLIES:
                    return STYLES_CONSTS.STYLES.ICONS.FA_CHECK;
                case STATUS_CONSTS.APPLIES.UNDETERMINED:
                    return STYLES_CONSTS.STYLES.ICONS.FA_FA_EXCLAMATION_TRIANGLE;
                default:
                    return '';
            }
        }

        function getAccumulatorsValue(value) {
            if (value) {
                var valueToCompare = Utility.lowerCase(value);
                if(valueToCompare === STATUS_CONSTS.ACCUMULATORS.NOT_FOUND) {
                    return ELIGIBILITY_CONSTS.CONS_N_A;
                }
                return value;
            }
            return ELIGIBILITY_CONSTS.CONS_N_A;
        }

        /**
         * Looks into policy codes' PA values to set product coverage status.
         * @param policy
         */
        function setProductCoverageStatus(policy) {
            var coverageStatusList = [];

            var adminCodes = getAdminCodes(policy);

            for (var key in adminCodes) {
                if (!Utility.isEmpty(adminCodes[key].coverageDetails) && !Utility.isEmpty(adminCodes[key].coverageDetails.priorAuth)) {
                    coverageStatusList.push(Utility.lowerCase(adminCodes[key].coverageDetails.priorAuth));
                }
            }

            var jCode = getJCode(policy);

            // Push JCode PA Required value
            if(!Utility.isEmpty(jCode.coverageDetails)) {
                // If PA Required value for JCode is null, coverage status = Undetermined and end the process
                if(Utility.isEmpty(jCode.coverageDetails.priorAuth)) {
                    if(!Utility.isEmpty(policy.plan)) {
                        policy.plan.coverageStatus = STATUS_CONSTS.DISPLAY_TEXT.UNDETERMINED;
                        return;
                    }
                }
                coverageStatusList.push(Utility.lowerCase(jCode.coverageDetails.priorAuth));
            }

            if(!Utility.isEmpty(policy.plan)) {
                // Prior Authorization Required value is 'No' or 'N/A' for all codes (J-Code and/or Admin Codes), Coverage Status = Payer guidelines apply
                if (Utility.containEveryValueOneOrAnotherInArray(coverageStatusList, [Utility.lowerCase(STATUS_CONSTS.APPLIES.NO), Utility.lowerCase(STATUS_CONSTS.APPLIES.NOT_AVAILABLE)])) {
                    //instead of showing PAYER_GUIDLINE_APPLY return the response coming from transaunion -> https://caremetx.atlassian.net/browse/GP-4
                    return;
                } else if (Utility.containValueInArray(coverageStatusList, Utility.lowerCase(STATUS_CONSTS.APPLIES.UNDETERMINED))) { // Prior Authorization Required value is 'Undetermined' for any code (J-Code and/or Admin Codes), Coverage Status = Undetermined
                    policy.plan.coverageStatus = STATUS_CONSTS.DISPLAY_TEXT.UNDETERMINED;
                } else if (Utility.containValueInArray(coverageStatusList, Utility.lowerCase(STATUS_CONSTS.APPLIES.YES))) {  // Prior Authorization Required value is 'Yes' for any code (J-Code and/or Admin Codes), Coverage Status = Prior Authorization required
                    policy.plan.coverageStatus = STATUS_CONSTS.DISPLAY_TEXT.PRIOR_AUTH_REQUIRED;
                } else {
                    policy.plan.coverageStatus = STATUS_CONSTS.DISPLAY_TEXT.UNDETERMINED;
                }
            }
        }

        /**
         * This function checks id property for adminCode in requestAdminCodeList is 0, in which case this adminCode is invalid
         * @param adminCode the admin code to check
         * @param requestAdminCodeList list of adminCode list from the "request.service.adminCodeList"
         */
        function isAdminCodeValid(adminCode, requestAdminCodeList) {
            var valid = true;
            angular.forEach(requestAdminCodeList, function(c) {
                if(adminCode.code === c.code && c.id === 0) {
                    valid = false;
                }
            });
            return valid;
        }

        /**
         * Extracts out description for given adminCode from the request admin code list.
         *
         * @param adminCode
         * @param requestAdminCodeList
         */
        function getAdminCodeDescription(adminCode, requestAdminCodeList) {
            var result = '';

            if(!Utility.isEmpty(adminCode)) {
                angular.forEach(requestAdminCodeList, function(c) {
                    if(adminCode.code === c.code) {
                        result = c.description;
                    }
                });
            }

            return result;
        }

        /**
         * Function to get PA Link.
         *
         * If J-Code Prior Authorization field = Yes, and Client Custom field contains a URL then return the link
         * As per the AC for EP-1678, URL will be included in Client Custom index 0 field only
         *
         * @param policy
         */
        function getPALink(policy) {
            var jCode = getJCode(policy), paLink = null;
            if(!Utility.isEmpty(jCode) && !Utility.isEmpty(jCode.coverageDetails) && !Utility.isEmpty(jCode.coverageDetails.priorAuth)) {
                var priorAuth = Utility.lowerCase(jCode.coverageDetails.priorAuth);
                if(priorAuth === Utility.lowerCase(ELIGIBILITY_CONSTS.CONS_YES)) {
                    if(!Utility.isEmpty(jCode.coverageDetails.clientCustom)) {
                        var clientCustom1 = angular.copy(jCode.coverageDetails.clientCustom[0]);
                        clientCustom1 = Utility.addHttpsPrefixInUrl(clientCustom1);
                        if(Utility.isUrl(clientCustom1)) {
                            paLink = clientCustom1;
                        }
                    }
                }
            }
            return paLink;
        }

        /**
         * Function to get PA Form Id.
         *
         * If policy payer contain formsTemplateList then return the PA Form Id
         *
         * @param policy
         */
        function getPAFormId(policy) {
            return (!Utility.isEmpty(policy.payer) && !Utility.isEmpty(policy.payer.formTemplateId)) ? policy.payer.formTemplateId : null;
        }

        /**
         * Function to get PA Form.
         *
         * Fetch PAForm from remote
         *
         * @param paFormRequest
         */
        function getPAForm(paFormRequest) {
            var deferred = $q.defer();
            API.post('form/pa', paFormRequest).then(function(response) {
                deferred.resolve(response);
            }).catch(function(error) {
                deferred.reject(ERROR_MESSAGES.NO_RESPONSE);
            });
            return deferred.promise;
        }

        function getPbmOrPayerName(policy, bvAdjudicationType) {
            if(isRxBvAdjudication(bvAdjudicationType)) {
                return (!Utility.isEmpty(policy) && !Utility.isEmpty(policy.plan) && !Utility.isEmpty(policy.plan.pbmName)) ? policy.plan.pbmName : '';
            }
            return !Utility.isEmpty(policy) && !Utility.isEmpty(policy.payer) && !Utility.isEmpty(policy.payer.name) ? policy.payer.clientName : '';
        }

        function isRxBvAdjudication(bvAdjudicationType) {
            return !Utility.isEmpty(bvAdjudicationType) && Utility.lowerCase(bvAdjudicationType) === BV_SELECTION_CONSTS.METHOD_NAME.RX;
        }

        return service;
    }
})();
