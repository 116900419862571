(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Patient')
        .factory('PatientService', PatientService);

    PatientService.$inject = ['$http', '$q', '$log', 'PatientModel', 'API', 'PATIENT_CONSTS', 'Utility', 'ELIGIBILITY_CONSTS', 'BVSelectionService', 'CaseModel', 'moment', 'SEARCH_CONSTS', 'HELP_TEXT_CONSTS', 'BV_SELECTION_CONSTS', 'GLOBAL_CONSTS', 'DATA_LIMIT_CONSTS'];

    function PatientService($http, $q, $log, PatientModel, API, PATIENT_CONSTS, Utility, ELIGIBILITY_CONSTS, BVSelectionService, CaseModel, moment, SEARCH_CONSTS, HELP_TEXT_CONSTS, BV_SELECTION_CONSTS, GLOBAL_CONSTS, DATA_LIMIT_CONSTS) {
        var service = {
            _patients: [],
            loadPatients: loadPatients,
            getByPortalPatientId: getByPortalPatientId,
            _getPatientWithPayer: _getPatientWithPayer,
            _getPatientDataForEligibility: _getPatientDataForEligibility,
            _getPatientWithPayerAndPbm: _getPatientWithPayerAndPbm,
            updatePatientStatus: updatePatientStatus,
            getPatientByPatientId: getPatientByPatientId,
            getPatientCount: getPatientCount,
            getArrayFormattedDateString: getArrayFormattedDateString,
            getPatientAndInsuranceInfoByPatientId: getPatientAndInsuranceInfoByPatientId,
            getPatientWithBvs: getPatientWithBvs,
            savePatient: savePatient,
            mapPatientPolicyRequest: mapPatientPolicyRequest,
            updatePatientInsurance: updatePatientInsurance,
            updatePatientInfo: updatePatientInfo,
            isPatientSubscriber: isPatientSubscriber,
            setPatientGender: setPatientGender,
            getHoverContent: getHoverContent,
            preparePatientInfoData: preparePatientInfoData,
            setSsnOrMedIdValue: setSsnOrMedIdValue,
            getSsnOrMedIdDisplayValue: getSsnOrMedIdDisplayValue,
            getPatientRequestData: getPatientRequestData,
            hasPatientRunBV: hasPatientRunBV,
            isConsentCaptured: isConsentCaptured,
            validateZipCode: validateZipCode
        };

        function updatePatientInfo(patient) {
            return API.put('patients/update', preparePatientInfoData(patient));
        }

        function getByPortalPatientId(portalPatientId) {
            var patientList = service._patients.filter(function(obj) {
                return obj.portalPatientId === parseInt(portalPatientId);
            });
            if(patientList.length > 0) {
                return $q.when(patientList[0]);
            } else {
                return service.getPatientByPatientId(portalPatientId);
            }
        }

        function getPatientCount(status) {
            var deferred = $q.defer();

            var queryParams = {
                active: status === 'active',
                count: true,
                limit: 0
            };
            API.get('patients', queryParams).then(function(patientsResponse) {
                if(patientsResponse) {
                    deferred.resolve(patientsResponse.data.patientCount);
                }
            }).catch(function(err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function getPatientWithBvs(patientsResponse) {
            var deferred = $q.defer();
            var count = patientsResponse.patientCount ? patientsResponse.patientCount : null;
            var patientList = patientsResponse.patientList.map(function(data) {
                data.patient.active = !Utility.isEmpty(data.active) ? data.active : null;
                // As per EP-1402, practicePatientId is externalId
                data.patient.externalId = !Utility.isEmpty(data.externalId) ? data.externalId : null;
                return data.patient;
            });
            BVSelectionService.getBvMethod().then(function(bvMethod) {
                var patientPromises = patientList.map(service._getPatientWithPayer.bind(null, bvMethod));
                var allPatientPromise = $q.all(patientPromises).then(function(patients) {
                    return patients.filter(function(patient) {
                        return !!patient;
                    });
                });
                allPatientPromise.then(function(patients) {
                    service._patients = patients;
                    var patientObj = {
                        totalCount: count || DATA_LIMIT_CONSTS.DEFAULT_COUNT_VALUE,
                        responseList: service._patients || []
                    };
                    patientObj.link = (!Utility.isEmpty(patientsResponse.link)) ? patientsResponse.link : null;
                    deferred.resolve(patientObj);
                }).catch(function(err) {
                    $log.error('There was an error :', err);
                    deferred.reject(err);
                });

            }).catch(function(err) {
                $log.error('There was an error getting bvadjudication type', err);
                deferred.reject(err);
            });

            return deferred.promise;

        }

        function loadPatients(count, status, length, offset, sort, nextLink) {
            //this @Variable canceler is for monitoring network process and cancels network if the promise related this instance is canceled.
            var canceler = $q.defer();
            var deferred = $q.defer();
            // Fetch the full list of patients
            var queryParams = {
                active: status === 'active',
                count: count || DATA_LIMIT_CONSTS.DEFAULT_COUNT,
                limit: length || DATA_LIMIT_CONSTS.DEFAULT_LIMIT,
                offset: offset || DATA_LIMIT_CONSTS.DEFAULT_OFFSET,
                sort: sort || PATIENT_CONSTS.PATIENT_SORT.PATIENT_LAST_NAME
            };
            var patientApi = API.get('patients', queryParams, null, null, canceler, nextLink);
            patientApi.promise.then(function(patientsResponse) {
                var patientsResponseObject = {
                    patientCount: patientsResponse.data.patientCount,
                    patientList: patientsResponse.data.patientList
                };
                patientsResponseObject.link = !Utility.isEmpty(patientsResponse.link) ? patientsResponse.link : null;
                getPatientWithBvs(patientsResponseObject).then(function(patientWithBvs) {
                    deferred.resolve(patientWithBvs);
                }).catch(function(err) {
                    $log.error('There was an error while getting Bvs of patient', err);
                    deferred.reject(err);
                });
            }).catch(function(err) {
                $log.error('There was an error', err);
                deferred.reject(err);
            });

            return {
                promise: deferred.promise,
                canceler: patientApi.canceler
            };
        }

        function getPatientByPatientId(patientId, limit, sort, offset, count, nextLink) {
            var deferred = $q.defer();
            API.get('patients/combined/' + patientId).then(function(patientResponse) {
                patientResponse = patientResponse.patientPractice;
                BVSelectionService.getBvMethod().then(function(bvMethod) {
                    if(!Utility.isEmpty(patientResponse.active)) patientResponse.patient.active = patientResponse.active;
                    deferred.resolve(service._getPatientWithPayerAndPbm(patientResponse.patient, bvMethod, limit, sort, offset, count, nextLink));
                }).catch(function(err) {
                    $log.error('There was an error fetching bv method', err);
                    deferred.reject(err);
                });
                if(!Utility.isEmpty(patientResponse.patient.patientId)) patientResponse.patientId = patientResponse.patient.patientId;
            }).catch(function(err) {
                $log.error('There was an error fetching patient with patient id', err);
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function _getPatientWithPayer(bvAdjudicationType, patient) {
            $log.debug('patient:', patient);
            var deferred = $q.defer();
            var queryParamObject = {
                bvAdjudicationType: bvAdjudicationType,
                limit: PATIENT_CONSTS.PATIENT_FOR_ELIGIBILITY.LIMIT
            };
            var patientModel = new PatientModel(patient);
            API.get('ebv/patient/' + patient.patientId, queryParamObject).then(function(patientCaseData) {
                var benefitVerificationList = _isBenefitVerificationListAvailable(patientCaseData);
                var data = _isCoverageResponseDtoAvailable(benefitVerificationList);
                if(benefitVerificationList && data) {
                    patientModel.recentVerificationDate = data.request.requestDate;
                    patientModel.lastBV = data;
                    patientModel.product = data.request.service.product;
                    patientModel.bvType = benefitVerificationList.bvAdjudicationType;
                    patientModel.benefitVerificationType = benefitVerificationList.bvType;
                    patientModel.bvStatus = benefitVerificationList.status;
                    patientModel.rxType = benefitVerificationList.rxType;
                }
                return deferred.resolve(patientModel);
            }).catch(function(err) {
                return deferred.resolve(patientModel);
            });

            return deferred.promise;
        }

        function _getPatientWithPayerAndPbm(patient, bvMethod, length, sort, offset, count, nextLink) {
            var deferred = $q.defer();
            var queryParamObject = {
                bvAdjudicationType: bvMethod,
                limit: length,
                sort: sort,
                offset: offset,
                count: count
            };
            API.get('ebv/patient/' + patient.patientId, queryParamObject, null, null, null, nextLink).then(function(result) {
                var caseEbvResultObj = {
                    totalCount: result.data.benefitVerifcationCount,
                    responseList: result.data.benefitVerificationList.filter(function(data) {
                        return !Utility.isEmpty(data.coverageResponseDto);
                    }).map(mapCase)
                };
                caseEbvResultObj.link = (!Utility.isEmpty(result.link)) ? result.link : null;
                caseEbvResultObj.responseList[0].patient = new PatientModel(patient);
                return deferred.resolve(caseEbvResultObj);
            }).catch(function(err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function mapCase(bvCase) {
            bvCase.coverageResponseDto.data.mbvId = bvCase.mbvId;
            return new CaseModel(bvCase.coverageResponseDto.data, bvCase.responseDate, bvCase.bvType, bvCase.bvAdjudicationType, bvCase.status, bvCase.patientActive, bvCase.rxType);
        }

        function _getPatientDataForEligibility(patient, bvMethod, next) {
            var deferred = $q.defer(), data = {},
                isBvMethodMedical = Utility.isMedical(bvMethod),
                queryParamObject = {
                    bvAdjudicationType: bvMethod,
                    limit: PATIENT_CONSTS.PATIENT_FOR_ELIGIBILITY.LIMIT
                };
            API.get('ebv/patient/' + patient.patientId, queryParamObject).then(function(patientCaseData) {
                var benefitVerificationList = _isBenefitVerificationListAvailable(patientCaseData);
                if(benefitVerificationList) {
                    var coverageResponseDtoData = _isCoverageResponseDtoAvailable(benefitVerificationList);
                    if(coverageResponseDtoData && !Utility.isEmpty(benefitVerificationList.bvAdjudicationType)) {
                        coverageResponseDtoData.usedbvMethod = bvMethod;
                        return deferred.resolve(coverageResponseDtoData);
                    } else {
                        return _defaultPatientResponse(deferred, patient, data, isBvMethodMedical);
                    }
                } else {
                    return _defaultPatientResponse(deferred, patient, data, isBvMethodMedical);
                }
            }).catch(function(err) {
                if(next) {
                    var nextBVMethod = isBvMethodMedical ? BV_SELECTION_CONSTS.METHOD_NAME.RX : BV_SELECTION_CONSTS.METHOD_NAME.MEDICAL;
                    _getPatientDataForEligibility(patient, nextBVMethod).then(function (data) {
                        return deferred.resolve(data);
                    }).catch(function (errorMessage) {
                        _defaultPatientResponse(deferred, patient, data, isBvMethodMedical);
                    });
                } else {
                    return _defaultPatientResponse(deferred, patient, data, isBvMethodMedical);
                }
            });

            return deferred.promise;
        }

        /**
         * Function to check if benefitVerificationList available in patientCaseData Object
         * @param patientCaseData
         * @private
         */
        function _isBenefitVerificationListAvailable(patientCaseData) {
            if(!Utility.isEmpty(patientCaseData)
                && !Utility.isEmpty(patientCaseData.data)
                && !Utility.isEmpty(patientCaseData.data.benefitVerificationList)
                && !Utility.isEmpty(patientCaseData.data.benefitVerificationList)
                && !Utility.isEmpty(patientCaseData.data.benefitVerificationList[0])) {
                return patientCaseData.data.benefitVerificationList[0];
            }
            return null;
        }

        /**
         * Function to check if coverageResponseDto available in benefitVerificationList Object
         * @param benefitVerificationList
         * @private
         */
        function _isCoverageResponseDtoAvailable(benefitVerificationList) {
            if(!Utility.isEmpty(benefitVerificationList.coverageResponseDto) && !Utility.isEmpty(benefitVerificationList.coverageResponseDto.data)) {
                return benefitVerificationList.coverageResponseDto.data;
            }
            return null;
        }

        /**
         * If case ebv for requested patient is not found then return requested patient information only
         * @param deferred
         * @param patient
         * @param data
         * @param isBvMethodMedical
         * @private
         */
        function _defaultPatientResponse(deferred, patient, data, isBvMethodMedical) {
            var patientObject = angular.copy(patient);
            // delete patient.zip from patient object and move it to patient.address object
            delete patientObject.zip;
            // requested patient object have zip inside the patient object
            // while caseEbv response patient object should have zipCode inside the address object
            patientObject.address = {
                zipCode: patient.zip || patient.zipCode || _getPatientZipCode(patient)
            };
            data.request = {patient: angular.copy(patientObject)};
            data.usedbvMethod = isBvMethodMedical ? BV_SELECTION_CONSTS.METHOD_NAME.RX : BV_SELECTION_CONSTS.METHOD_NAME.MEDICAL;
            return deferred.resolve(data);
        }

        //This function is used because the data being returned from the backed is not stable
        //In the future once the application is stable we may not require this function.
        function _getPatientZipCode(patient) {
            if(!Utility.isEmpty(patient.address) && !Utility.isEmpty(patient.address.zipCode)) {
                return patient.address.zipCode;
            } else if(!Utility.isEmpty(patient.address) && !Utility.isEmpty(patient.address.zip)) {
                return patient.address.zip;
            } else {
                return null;
            }
        }

        function updatePatientStatus(data) {
            return API.put('patients/update/status', data);
        }

        function updatePatientInsurance(data) {
            return API.post('patients/insurance/update', data);
        }

        /**
         * Formats 01-01-1994 to [1, 1, 1994]
         * @param dateString
         * @returns {any[]}
         */
        function getArrayFormattedDateString(dateString) {
            var result = dateString.split('-').map(function(item) {
                return parseInt(item);
            });
            return result;
        }

        function getPatientAndInsuranceInfoByPatientId(patientId, patientOnly) {
            var params = {
                patientOnly: patientOnly
            };
            return API.get('patients/combined/' + patientId, params);
        }

        function savePatient(data) {
            data.patient = preparePatientInfoData(data.patient);
            return API.post('patients/create', data);
        }

        function mapPatientPolicyRequest(policy) {
            policy.subscriberPatient = true;
            policy.medicareDetails = {
                memberId: policy.medicareMemberId,
                supplementalLetter: (!Utility.isEmpty(policy.medSuppData) && !Utility.isEmpty(policy.medSuppData.planLetter)) ? policy.medSuppData.planLetter : null
            };
            if(!Utility.isEmpty(policy.subscriber) && !Utility.isEmpty(policy.subscriber.dob)) policy.subscriber.dob = moment(policy.subscriber.dob).format(SEARCH_CONSTS.DATE_FORMAT);
            if(!Utility.isEmpty(policy.payer) && !Utility.isEmpty(policy.payer.organizationPayerId)) policy.payer.clientId = policy.payer.organizationPayerId;
            delete policy.payerOrg;
            delete policy.medicareMemberId;
            delete policy.medSuppData;
            delete policy.patient;
            return policy;
        }

        function isPatientSubscriber(policy) {
            if(Utility.isEmpty(policy)) return true;
            return !(!Utility.isEmpty(policy.subscriberFirstName)
                || !Utility.isEmpty(policy.subscriberMemberId)
                || !Utility.isEmpty(policy.subscriberDob)
                || !Utility.isEmpty(policy.subscriberLastName)
                || !Utility.isEmpty(policy.subscriberGender));
        }

        function setPatientGender(patient) {
            if(Utility.isEmpty(patient) || Utility.isEmpty(patient.gender)) return null;
            return (Utility.lowerCase(patient.gender) === Utility.lowerCase(GLOBAL_CONSTS.GENDER.MALE.charAt(0)) || Utility.lowerCase(patient.gender) === Utility.lowerCase(GLOBAL_CONSTS.GENDER.MALE)) ? Utility.upperCase(GLOBAL_CONSTS.GENDER.MALE.charAt(0)) : Utility.upperCase(GLOBAL_CONSTS.GENDER.FEMALE.charAt(0));
        }

        function getHoverContent() {
            return {
                viewInsurance: {
                    policy: HELP_TEXT_CONSTS.PATIENT_PROFILE.VIEW_INSURANCE.POLICY,
                    memberId: HELP_TEXT_CONSTS.PATIENT_PROFILE.VIEW_INSURANCE.MEMBER_ID,
                    medicareMemberId: HELP_TEXT_CONSTS.PATIENT_PROFILE.VIEW_INSURANCE.MEDICARE_MEMBER_ID,
                    supplementalLetter: HELP_TEXT_CONSTS.PATIENT_PROFILE.VIEW_INSURANCE.SUPPLEMENTAL_PLAN_LETTER,
                    patientIsSubscriber: HELP_TEXT_CONSTS.PATIENT_PROFILE.VIEW_INSURANCE.PATIENT_IS_SUBSCRIBER,
                    subscriberName: HELP_TEXT_CONSTS.PATIENT_PROFILE.VIEW_INSURANCE.SUBSCRIBER_NAME
                }
            };
        }

        function preparePatientInfoData(patientInfo) {
            if(Utility.isEmpty(patientInfo) || Utility.isEmpty(patientInfo.ssnOrMedicareId)) {
                return patientInfo;
            }
            return patientInfo.ssnOrMedicareId.length === 4 ? setSsnOrMedIdValue(GLOBAL_CONSTS.SSN, patientInfo) : setSsnOrMedIdValue(GLOBAL_CONSTS.MEDICARE_ID, patientInfo);
        }

        function setSsnOrMedIdValue(key, patientInfo) {
            patientInfo[key] = patientInfo.ssnOrMedicareId;
            delete patientInfo.ssnOrMedicareId;
            key === GLOBAL_CONSTS.SSN ? delete patientInfo.medicareId : delete patientInfo.ssn;
            return patientInfo;
        }

        function getSsnOrMedIdDisplayValue(patientInfo) {
            if(Utility.isEmpty(patientInfo)) {
                return patientInfo;
            }

            return !Utility.isEmpty(patientInfo.ssn)
                ? patientInfo.ssn
                : !Utility.isEmpty(patientInfo.medicareId)
                    ? patientInfo.medicareId
                    : null;
        }

        function getPatientRequestData(patientId, bvMethod) {
            var deferred = $q.defer();
            var patient = {patientId: patientId};
            _getPatientDataForEligibility(patient, bvMethod).then(function (response) {
                if(!Utility.isEmpty(response) && !Utility.isEmpty(response.request)){
                    deferred.resolve(response.request);
                } else {
                    deferred.reject('');
                }
            }).catch(function (err) {
                deferred.reject(err);
            });
            return deferred.promise;
        }

        function hasPatientRunBV(patientId) {
            var deferred = $q.defer();
            var limit = PATIENT_CONSTS.PATIENT_PROFILE.COMPLETED_BV.LIMIT;
            var offset = PATIENT_CONSTS.PATIENT_PROFILE.COMPLETED_BV.LIMIT;
            // fetch no. of times BV is run for this patient
            service.getPatientByPatientId(patientId, limit, '-', offset, true).then(function (data) {
                if(!Utility.isEmpty(data.totalCount)) {
                    deferred.resolve(data.totalCount > 0);
                }
            }).catch(function () {
                deferred.resolve(false);
            });
            return deferred.promise;
        }

        function isConsentCaptured(consentData) {
            return (Utility.lowerCase(consentData.status) === PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_INVITE_STATUS.COMPLETE
                || Utility.lowerCase(consentData.status) === PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_INVITE_STATUS.INCOMPLETE
                || Utility.lowerCase(consentData.status) === PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_INVITE_STATUS.INITIATED
                || Utility.lowerCase(consentData.status) === PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_INVITE_STATUS.ACCEPTED
                || Utility.lowerCase(consentData.status) === PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_INVITE_STATUS.SENT
                || consentData.onfile)
                && !consentData.expired;
        }

        function validateZipCode(zip) {
            return API.get('zip/validate/' + zip);
        }

        return service;
    }
})();
