/**
 * @ngdoc service
 * @name eAccess.ProviderPortal.Components.Practice.service:PracticeService
 * @author Sterling Stokes
 * @requires $q
 * @requires $log
 * @requires PracticeModel
 * @requires API
 * @description
 *
 * This is the service for practice-specific API calls.
 **/

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .factory('PracticeService', PracticeService);

    PracticeService.$inject = ['$q', '$log', 'PracticeModel', 'API', 'PRACTICE_CONSTS'];

    function PracticeService($q, $log, PracticeModel, API, PRACTICE_CONSTS) {

        var service = {
            loadPractice: loadPractice,
            updatePractice: updatePractice,
            preparePracticeInfo: preparePracticeInfo,
            createPractice: createPractice,
            getValidationInfo: getValidationInfo,
            getErrorMessages: getErrorMessages
        };

        /**
         * @ngdoc function
         * @name loadPractice
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:PracticeService
         * @description
         *
         * Loads a practice based on the logged-in user.
         */

        function loadPractice() {
            var deferred = $q.defer();

            API.get('practices/user').then(function(response) {
                var practice = new PracticeModel(response.address1, response.address2, response.city, response.practiceId, response.practiceLegalName, response.mainFax, response.mainPhone, response.practiceName, response.npi, response.state, response.taxID, response.zipCode, response.active);
                deferred.resolve(practice);
            });

            return deferred.promise;
        }

        /**
         * @ngdoc function
         * @name preparePracticeInfo
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:PracticeService
         * @description
         *
         * Prepare the practice info data with the correct property names for the backend.
         *
         * @param practiceInfo
         */

        function preparePracticeInfo(practiceInfo) {
            return {
                practiceId: practiceInfo.id,
                practiceName: practiceInfo.name,
                practiceLegalName: practiceInfo.legalName,
                npi: practiceInfo.npi || null,
                taxID: practiceInfo.taxId || null,
                address1: practiceInfo.address1,
                address2: practiceInfo.address2 || null,
                city: practiceInfo.city,
                state: practiceInfo.state,
                zipCode: practiceInfo.zip,
                mainPhone: practiceInfo.mainPhone,
                mainFax: practiceInfo.mainFax || null
            };
        }

        /**
         * @ngdoc function
         * @name updatePractice
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:PracticeService
         * @description
         *
         * Save updated practice information.
         */

        function updatePractice(practiceInfo) {
            var deferred = $q.defer();

            API.put('practices/update', preparePracticeInfo(practiceInfo))
                .then(function(response) {
                    var practice = new PracticeModel(response.address1, response.address2, response.city, response.practiceId, response.practiceLegalName, response.mainFax, response.mainPhone, response.practiceName, response.npi, response.state, response.taxID, response.zipCode);
                    deferred.resolve(practice);
                })
                .catch(function(error) {
                    $log.error("Unable to update practice info ", error);
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        /**
         * @ngdoc function
         * @name createPractice
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:PracticeService
         * @description
         *
         * Save updated practice information.
         */
        function createPractice(practiceInfo) {
            return !(practiceInfo.id) ?
                API.post('practices/create', preparePracticeInfo(practiceInfo)) :
                service.updatePractice(practiceInfo);
        }

        function getValidationInfo() {
            return {
                name: {
                    minLength: PRACTICE_CONSTS.NAME_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.NAME_MAX_LENGTH
                },
                npi: {
                    minLength: PRACTICE_CONSTS.NPI_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.NPI_MAX_LENGTH
                },
                taxId: {
                    minLength: PRACTICE_CONSTS.TAX_ID_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.TAX_ID_MAX_LENGTH
                },
                practiceLocationId: {
                    minLength: PRACTICE_CONSTS.PRACTICE_LOCATION_ID_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.PRACTICE_LOCATION_ID_MAX_LENGTH
                },
                address1: {
                    minLength: PRACTICE_CONSTS.ADDRESS_1_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.ADDRESS_1_MAX_LENGTH
                },
                address2: {
                    minLength: PRACTICE_CONSTS.ADDRESS_2_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.ADDRESS_2_MAX_LENGTH
                },
                city: {
                    minLength: PRACTICE_CONSTS.CITY_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.CITY_MAX_LENGTH
                },
                zip: {
                    minLength: PRACTICE_CONSTS.ZIP_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.ZIP_MAX_LENGTH
                },
                mainPhone: {
                    minLength: PRACTICE_CONSTS.MAIN_PHONE_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.MAIN_PHONE_MAX_LENGTH
                },
                mainFax: {
                    minLength: PRACTICE_CONSTS.MAIN_FAX_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.MAIN_FAX_MAX_LENGTH
                },
                legalName: {
                    minLength: PRACTICE_CONSTS.LEGAL_NAME_MIN_LENGTH,
                    maxLength: PRACTICE_CONSTS.LEGAL_NAME_MAX_LENGTH
                }
            };
        }

        function getErrorMessages(validation) {
            return {
                name: {
                    minLength: 'Name must be greater than ' + validation.name.minLength + ' characters',
                    maxLength: 'Name can be no more than ' + validation.name.maxLength + ' characters',
                    required: 'Name is required',
                    unique: 'A Practice with this Practice Name already exists'
                },
                npiNumber: {
                    minLength: 'Practice NPI must be exactly ' + validation.npi.minLength + ' characters',
                    maxLength: 'Practice NPI must be exactly ' + validation.npi.maxLength + ' characters',
                    required: 'Practice NPI is required',
                    invalid: PRACTICE_CONSTS.ERROR_MESSAGES.INVALID_PRACTICE_NPI
                },
                taxIdNumber: {
                    // actual Tax ID length is 9, but this allows for the '-' character in mask / validation
                    minLength: 'Practice Tax ID must be exactly ' + (validation.taxId.minLength - 1) + ' characters',
                    maxLength: 'Practice Tax ID must be exactly ' + (validation.taxId.maxLength - 1) + ' characters',
                    required: 'Tax ID Number is required',
                    mask: 'Practice Tax ID must be exactly ' + (validation.taxId.minLength - 1) + ' characters'
                },
                practiceLocationId: {
                    minLength: 'Practice Location ID must be greater than ' + validation.practiceLocationId.minLength + ' characters',
                    maxLength: 'Practice Location ID can be no more than ' + validation.practiceLocationId.maxLength + ' characters',
                    unique: 'A location with this location ID already exists'
                },
                address1: {
                    minLength: 'Practice Address 1 must be greater than ' + validation.address1.minLength + ' characters',
                    maxLength: 'Practice Address 1 can be no more than ' + validation.address1.maxLength + ' characters',
                    required: 'Street Address is required'
                },
                address2: {
                    maxLength: 'Practice Address 2 can be no more than ' + validation.address2.maxLength + ' characters'
                },
                city: {
                    minLength: 'City must be greater than ' + validation.city.minLength + ' characters',
                    maxLength: 'City can be no more than ' + validation.city.maxLength + ' characters',
                    required: 'City is required'
                },
                zipCode: {
                    minLength: 'Zip Code must be exactly ' + validation.zip.minLength + ' characters',
                    maxLength: 'Zip Code must be exactly ' + validation.zip.maxLength + ' characters',
                    required: 'Zip Code is required'
                },
                mainPhoneNumber: {
                    mask: 'Invalid Telephone Number',
                    required: 'Main Phone Number is required'
                },
                mainFaxNumber: {
                    mask: 'Invalid main fax.'
                },
                legalName: {
                    minLength: 'Practice Legal Name must be greater than ' + validation.legalName.minLength + ' characters',
                    maxLength: 'Practice Legal Name can be no more than ' + validation.legalName.maxLength + ' characters',
                    required: 'Practice Legal Name is required'
                }

            };
        }

        return service;
    }
})();
