/**
 * Created by nikesh on 8/24/2017.
 */
(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('practice.locations.edit', {
                parent: 'practice',
                url: '/locations/edit',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Edit Practice Location'
                },
                params: {
                    id: null
                },
                views: {
                    'practiceContent': {
                        templateUrl: 'components/practice/locations/edit/practice-locations-edit.html',
                        controller: 'PracticeLocationEditController as editLocation'
                    }
                }
            });
    }

})();
