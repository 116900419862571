/**
 * Created by nikesh on 10/16/2017.
 */
(function() {
    'use strict';

    var VALUES = {
        REGISTRATION_MESSAGE: 'Please enter your Registration Code and click Continue to proceed to the Practice Registration process.',
        ERROR_MESSAGES: {
            REGISTRATION_CODE: {
                INVALID: 'Invalid registration code'
            },
            ELECTRONIC_SIGNATURE: {
                REQUIRED: 'Electronic Signature is required.',
                MAX_LENGTH: "Electronic Signature should not exceed 50 characters."
            },
            ATTESTATION: {
                I_AM_UNDER_SIGNED_REQUIRED: 'Please select the second checkbox in order to continue',
                I_AGREE_REQUIRED: 'Please select the first checkbox in order to continue',
                SELECT_BOTH: 'Please select both checkboxes in order to continue'
            }
        },
        VALIDATION: {
            REGISTRATION_CODE: {
                MIN_LENGTH: 12
            }
        },
        REGISTRATION_STEPS: {
            USER_AGREEMENT: 'User Agreement',
            HIPAA_BAA: 'HIPAA BAA',
            CREATE_USER_ACCOUNT: 'Create User Account',
            VALIDATE_USER: 'validateUser'
        },
        STATE:{
            CODE: 'register.code',
            USER_AGREEMENT: 'register.user-agreement'
        }
    };

    angular.module('eAccess.ProviderPortal').constant('REGISTER_CONSTS', VALUES);
})();
