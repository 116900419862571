/**
 * Created by nikesh on 3/11/2018.
 */
(function() {
    'use strict';

    var VALUES = {
        VALIDATION: {
            DAYS_SUPPLY_MIN_VALUE: 1,
            QUANTITY_MIN_VALUE: 1
        },
        ERROR_MESSAGES: {
            PRODUCT: {
                REQUIRED: 'Product is required'
            },
            DAYS_SUPPLY: {
                REQUIRED: 'Days Supply is required',
                MIN_LENGTH: 'Days Supply must be greater than or equal to #min'
            },
            QUANTITY: {
                REQUIRED: 'Quantity is required',
                MIN_LENGTH: 'Quantity must be greater than or equal to #min'
            },
            LOCATION: {
                REQUIRED: 'Location is required',
                LOCATION_NOT_ACTIVE: "Chosen location has been deactivated. Please choose another location"
            }
        }
    };

    angular.module('eAccess.ProviderPortal.Constants.RxServiceDeliveryForm', [])
        .constant('RX_SERVICE_DELIVER_FORM_CONSTS', VALUES);
})();
