/**
 * Created by girianish.
 */

(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Services.RxEligibilityResponse')
        .factory('RxProdCoverageService', RxProdCoverageService);


    RxProdCoverageService.$inject = ['$log', 'Utility', 'StatusStyles', 'ELIGIBILITY_CONSTS', 'STATUS_CONSTS', 'GLOBAL_CONSTS'];

    function RxProdCoverageService($log, Utility, StatusStyles, ELIGIBILITY_CONSTS, STATUS_CONSTS, GLOBAL_CONSTS) {
        var service = {
            getMinMaxCopay: getMinMaxCopay,
            getCopayTier: getCopayTier,
            getCopayMessage: getCopayMessage,
            getOutOfPocket: getOutOfPocket,
            getCoinsurance: getCoinsurance,
            getProductCoverage:getProductCoverage,
            getCopayCoinsuranceObj: getCopayCoinsuranceObj
        };


        function filterUnknown (filterQuery) {
            return filterQuery !== ELIGIBILITY_CONSTS.COPAY_COINSURANCE.UNKNOWN;
        }

        function getCopayCoinsuranceText(copay, minCopay, maxCopay) {
            return Utility.replaceText(Utility.replaceText(Utility.replaceText(ELIGIBILITY_CONSTS.COPAY_COINSURANCE.COPAY_TEMPLATE, /@ {MIN_COPAY}/g , minCopay),'@ {MAX_COPAY}', maxCopay), '@ {COPAY}', copay);
        }

        function getDaysSupplyPerCopayText(daySupply) {
            return Utility.replaceText(ELIGIBILITY_CONSTS.COPAY_COINSURANCE.DAY_SUPPLY_TEMPLATE,'@ {DAY_SUPPLY}', daySupply);
        }


         /**
         * @ngdoc function
         * @name getMinMaxCopay
         *
         *
         * @description
         * returns object which contains min max copay and day supply per copay
         *
         * @param {object} costShareObj costShare info object
         * @param costShareObj.copay contains  copay ($20)
         * @param costShareObj.minimumCopay contains minimum copay ($20)
         * @param costShareObj.maximumCopay contains maximum copay ($50)
         * @param costShareObj.daysSupplyPerCopay contains days supply per copay (90)
         **/
        function getMinMaxCopay (costShareObj) {

            if(!Utility.isEmpty(costShareObj)
                && !Utility.isEmpty(costShareObj.copay)
                && !Utility.isEmpty(costShareObj.minimumCopay)
                && !Utility.isEmpty(costShareObj.maximumCopay)
                && !Utility.isEmpty(costShareObj.daysSupplyPerCopay)) {

                var copayCoinsurance = [];
                var displayableCopay = {};
                var copayLowerCase = Utility.lowerCase(costShareObj.copay);
                var minimumCopayLowerCase = Utility.lowerCase(costShareObj.minimumCopay);
                var maximumCopayLowerCase = Utility.lowerCase(costShareObj.maximumCopay);
                var daysSupplyLowerCase = Utility.lowerCase(costShareObj.daysSupplyPerCopay);

                copayCoinsurance.push.apply(copayCoinsurance, [copayLowerCase, minimumCopayLowerCase, maximumCopayLowerCase, daysSupplyLowerCase]);

                var filteredUnknown = Utility.filterFromArray(copayCoinsurance, filterUnknown);

                if (filteredUnknown.length > 0) {
                    displayableCopay.copayMinMaxText = getCopayCoinsuranceText(costShareObj.copay, costShareObj.minimumCopay, costShareObj.maximumCopay);
                    displayableCopay.daySupplyText = getDaysSupplyPerCopayText(costShareObj.daysSupplyPerCopay);
                    return displayableCopay;
                } else {
                    return STATUS_CONSTS.COVERAGE.UNKNOWN;
                }

            } else {
                return GLOBAL_CONSTS.DASH;
            }
        }

         /**
         * @ngdoc function
         * @name getCoinsurance
         *
         *
         * @description
         * returns coinsurance if it is available else returns unknown
         *
         * @param {object} costShareObj costShare info object
         * @param costShareObj.coinsurance constains coinsurance
         *
         *
         **/
        function getCoinsurance (costShareObj) {
            if(!Utility.isEmpty(costShareObj)
            && !Utility.isEmpty(costShareObj.coinsurance)) {
                var coinsuranceLowerCase = Utility.lowerCase(costShareObj.coinsurance);
                return coinsuranceLowerCase !== ELIGIBILITY_CONSTS.COPAY_COINSURANCE.UNKNOWN ? costShareObj.coinsurance : STATUS_CONSTS.COVERAGE.UNKNOWN;
            } else {
                return GLOBAL_CONSTS.DASH;
            }
        }

         /**
         * @ngdoc function
         * @name getCopayMessage
         *
         *
         * @description
         * returns copay message if it is available or else it returns Unknown
         *
         * @param {object} costShareObj costShare info object
         * @param costShareObj.copayMessage contains copay message ("Example Copay message text")
         **/

        function getCopayMessage (costShareObj) {
            if(!Utility.isEmpty(costShareObj)
                && !Utility.isEmpty(costShareObj.copayMessage)) {
                var copayMessageLowerCase = Utility.lowerCase(costShareObj.copayMessage);
                return copayMessageLowerCase !== ELIGIBILITY_CONSTS.COPAY_COINSURANCE.UNKNOWN ? costShareObj.copayMessage : STATUS_CONSTS.COVERAGE.UNKNOWN;
            } else {
                return GLOBAL_CONSTS.DASH;
            }

        }

         /**
         * @ngdoc function
         * @name getCopayTier
         *
         *
         * @description
         * returns copay tier if it is available or else it returns Unknown
         *
         *
         * @param {object} costShareObj costShare object
         * @param costShareObj.copayTier contains copay tier (1)
         * @param costShareObj.maximumCopayTier contains maximum copay tier (4)
         *
         *
         **/

        function getCopayTier (costShareObj) {

            if(!Utility.isEmpty(costShareObj)
                && !Utility.isEmpty(costShareObj.copayTier)
                && !Utility.isEmpty(costShareObj.maximumCopayTier)) {

                var copayTierContainer = [];
                var copayTierLowerCase = Utility.lowerCase(costShareObj.copayTier);
                var maximumCopayTierLowerCase = Utility.lowerCase(costShareObj.maximumCopayTier);

                copayTierContainer.push.apply(copayTierContainer, [copayTierLowerCase, maximumCopayTierLowerCase]);

                var filteredUnknown = Utility.filterFromArray(copayTierContainer, filterUnknown);

                return filteredUnknown.length === 2 ? filteredUnknown.join(' of ') :  STATUS_CONSTS.COVERAGE.UNKNOWN;

            }else {
                return GLOBAL_CONSTS.DASH;
            }
        }

        /**
         * @ngdoc function
         * @name getOutOfPocket
         *
         *
         * @description
         * returns displayable OutOfPocket in the format {outOfPocketRangeStart} / {outOfPocketRangeEnd}
         *
         * @param {object} costShareObj costShare object
         * @param costShareObj.outOfPocketRangeStart contains out of pocket start range ($20)
         * @param costShareObj.outOfPocketRangeEnd contains out of pocket end range ($40)
         **/
        function getOutOfPocket (costShareObj) {

            if(!Utility.isEmpty(costShareObj)
                && !Utility.isEmpty(costShareObj.outOfPocketRangeStart)
                && !Utility.isEmpty(costShareObj.outOfPocketRangeEnd)) {
                var oopContainer = [];
                oopContainer.push.apply(oopContainer, [costShareObj.outOfPocketRangeStart, costShareObj.outOfPocketRangeEnd]);
                return oopContainer.join(' / ');
            } else {
                return GLOBAL_CONSTS.DASH;
            }

        }



        function getProductCoverage() {
             /*
                productCoverage: view model for product coverage
                productCoverage.{PharmacyType}.coinsurance : contains list of copay coninsurace viewmodal

            */

            var _copayCoinsurance = {
                copayCoinsurance: []
            };

            //  angular.copy is done to overcome refrencing issue
            return  {
                R: angular.copy(_copayCoinsurance),
                M: angular.copy(_copayCoinsurance),
                S: angular.copy(_copayCoinsurance),
                L: angular.copy(_copayCoinsurance)
            };
        }

        function getCopayCoinsuranceObj(){
            var _defaultObj =  {
                displayText: GLOBAL_CONSTS.DASH,
                visible : false
            };

            // angular.copy is done to overcome refrencing issue
            return {
                R : angular.copy(_defaultObj),
                M : angular.copy(_defaultObj),
                S : angular.copy(_defaultObj),
                L : angular.copy(_defaultObj)
            };

        }



        return service;
    }
})();
