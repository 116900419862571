(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilitySplitResponse')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('RxEligibilitySplitResponse', {
                url: '/eligibility/split/rx/{responseId}?{adjudicationType}',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Eligibility Rx Coverage Split Response'
                },
                params: {
                    'responseId': '',
                    'adjudicationType': null
                },
                views: {
                    'content@': {
                        templateUrl: 'components/eligibility/bv-selection/rx/split-response/rx-eligibility-split-response.html',
                        controller: 'RxEligibilitySplitResponseController as rxSplitResponseCtrl'
                    }
                },
                resolve: {
                    eligibilityResponse: ['Eligibility', '$stateParams', 'Utility', function(Eligibility, $stateParams, Utility) {
                        return Utility.getById.call(Eligibility.getResponseById($stateParams.responseId, $stateParams.adjudicationType), 'bvHistory');
                    }]
                }
            });

    }

})();
