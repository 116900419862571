/**
 * Created by pramesh on 1/17/2018.
 */
(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Modals')
        .controller('ResetPasswordModalController', ResetPasswordModalController);

    ResetPasswordModalController.$inject = ['$log', '$uibModalInstance', 'user', 'resetPasswordModal', 'PracticeUserService', 'Utility'];

    function ResetPasswordModalController($log, $uibModalInstance, user, resetPasswordModal, PracticeUserService, Utility) {

        var vm = this;

        vm.user = user;
        vm.resetPasswordModal = resetPasswordModal;

        vm.name = Utility.getFullName(vm.user);
        vm.sendingRequest = false;

        vm.closeModal = closeModal;
        vm.confirmAction = confirmAction;

        function closeModal() {
            $uibModalInstance.dismiss();
        }

        function confirmAction() {
            vm.sendingRequest = true;
            PracticeUserService.resetUserPassword(vm.user.userId)
                .then(function() {
                    $uibModalInstance.close(true);
                }).catch(function(error) {
                    $log.error("Unable to reset password ", error);
                    $uibModalInstance.close(false);
                }).finally(function() {
                    vm.sendingRequest = false;
                });
        }

    }

})();
