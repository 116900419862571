(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilitySplitResponse', [
            'eAccess.ProviderPortal.Services.RxEligibilityResponse',
            'eAccess.ProviderPortal.Services.StatusStyles',
            'eAccess.ProviderPortal.Services.Policy',
            'eAccess.ProviderPortal.Services.PolicyPlanDetails',
            'eAccess.ProviderPortal.Services.CoverageSummary'
        ]);
})();
