/**
 * Created by pramesh on 12/1/2017.
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.MBVView',[]);
})();
