/**
 * Created by kailash.shrestha on 11/09/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ConsentMessage')
        .controller('ConsentMessageController', ConsentMessageController);

    ConsentMessageController.$inject = ['$log', '$window', 'DashboardService', '$scope', 'PATIENT_CONSTS', '$stateParams', 'Utility', 'HELP_TEXT_CONSTS', 'ContentStoreService', 'CONTENT_STORE_CONSTS', 'ConfigurationService', 'StatusStyles', 'ConsentService', 'moment', 'SEARCH_CONSTS', 'ToastNotificationService', 'GLOBAL_CONSTS', 'PatientService'];

    function ConsentMessageController($log, $window, DashboardService, $scope, PATIENT_CONSTS, $stateParams, Utility, HELP_TEXT_CONSTS, ContentStoreService, CONTENT_STORE_CONSTS, ConfigurationService, StatusStyles, ConsentService, moment, SEARCH_CONSTS, ToastNotificationService, GLOBAL_CONSTS, PatientService) {

        var vm = this;
        //data
        vm.consentDisplay = false;
        vm.consentFullMessage = "";
        vm.consentMessage = "";
        vm.showMoreConsent = false;
        vm.showLessConsent = false;
        vm.consentErrorMessage = PATIENT_CONSTS.CONSENT_ERROR.MESSAGE;
        vm.consentTooltip = HELP_TEXT_CONSTS.BV_REQUEST.PATIENT_INFO.CONSENT;
        vm.displayEHipaaConsent = 0;
        vm.profileViewPage = false;
        vm.ehipaaIncompleteStatusArray = [
            PATIENT_CONSTS.CONSENT_TEXT.STATUS.ACCEPTED,
            PATIENT_CONSTS.CONSENT_TEXT.STATUS.SENT,
            PATIENT_CONSTS.CONSENT_TEXT.STATUS.ENGAGED,
            PATIENT_CONSTS.CONSENT_TEXT.STATUS.INITIATED
        ];
        vm.loadConsentUI = loadConsentUI;
        vm.displayPDFLink = false;
        vm.sendingInvitation = false;

        vm.displayEHipaaConsent = Utility.isTruthy(ConfigurationService.getOrganizationProperty('enableEhipaaConsent')) || 0;
        vm.displayEHipaaConsentTitle = true;
        vm.displayEHipaaForm = true;
        vm.consentTitle = PATIENT_CONSTS.CONSENT_TEXT.TITLE;
        vm.consentTitleSubText = PATIENT_CONSTS.CONSENT_TEXT.TITLE_SUB_TEXT;
        vm.consentLabel = PATIENT_CONSTS.CONSENT_TEXT.CONSENT_LABEL;
        vm.consentLabelSubText = PATIENT_CONSTS.CONSENT_TEXT.CONSENT_LABEL_SUB_TEXT;
        vm.eHipaaLabel = PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_LABEL;
        vm.eHipaaLabelSubText = PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_LABEL_SUB_TEXT;
        vm.eHipaaText = PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_DEFAULT_TEXT;
        vm.placeholder = PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_INPUT_PLACEHOLDER;
        vm.btnText = PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_BUTTON_TEXT;
        vm.sendingBtnText = PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_BUTTON_SENDING_TEXT;
        vm.validation = {};
        vm.getValidationInfo = getValidationInfo;
        vm.pdfLinkText = PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_PDF_LINK_TEXT;
        vm.gskHCPText = "";
        vm.errorMessages = {
            required: 'Confirmation of patient consent is required to continue.'
        };

        //functions
        vm.showMore = showMore;
        vm.showLess = showLess;
        vm.patientInfoFormIsValid = patientInfoFormIsValid;
        vm.setConsentEhipaaStatusMessage = setConsentEhipaaStatusMessage;
        vm.setDisplayTextIcon = StatusStyles.setDisplayTextIcon;
        vm.setDisplayTextClass = StatusStyles.setDisplayTextClass;
        vm.getConsentStatus = getConsentStatus;
        vm.openEhipaaPdf = openEhipaaPdf;
        vm.setEHipaaConsentContent = setEHipaaConsentContent;
        vm.displayCurrentStatus = displayCurrentStatus;
        vm.displayCheckForUpdate = false;

        vm.changeConsent = changeConsent;
        vm.sendInvitationToPatient = sendInvitationToPatient;
        vm.setDisplayEhipaaConsentFlag = setDisplayEhipaaConsentFlag;
        vm.setPatientObj = setPatientObj;

        vm.errorTitle = PATIENT_CONSTS.CONSENT_TEXT.ERROR_MESSAGE.HEADER;
        vm.errorMessage = PATIENT_CONSTS.CONSENT_TEXT.ERROR_MESSAGE.MESSAGE;

        function showMore() {
            vm.consentMessage = vm.consentFullMessage;
            vm.showMoreConsent = false;
            vm.showLessConsent = true;
        }

        function showLess() {
            vm.consentMessage = vm.consentFullMessage.substring(0, 1200);
            vm.showMoreConsent = true;
            vm.showLessConsent = false;
        }

        vm.$onInit = function() {
            vm.setPatientObj();
            setDisplayEhipaaConsentFlag();
            DashboardService.isConsentDisplay().then(function(value) {
                vm.consentDisplay = value;
                vm.validation = vm.getValidationInfo();
                vm.loadConsentUI();
                if(Utility.isEmpty(vm.consent) || Utility.isEmpty(vm.consent.text)) {
                    ContentStoreService.getContentStoreData(CONTENT_STORE_CONSTS.KEYS.PATIENT_CONSENT_TEXT).then(function(value) {
                        setConsentMessage(value);
                    }).catch(function(err){
                        $log.error("Unable to retrieve default consent message", err);
                    });
                } else {
                    setConsentMessage(vm.consent.text);
                }
                vm.setConsentEhipaaStatusMessage();
            }).catch(function(err) {
                $log.error("Unable to retrieve consent display data", err);
            });
        };

        vm.$onChanges = function () {
            if(vm.displayEHipaaConsent) {
                setConsentEhipaaStatusMessage();
                setEHipaaConsentContent();
            }
        };

        function setPatientObj() {
            if(Utility.isEmpty(vm.patientInfo)) {
                vm.patientInfo = {
                    patientConsent: '',
                    patientId: '',
                    address: ''
                };
            }
        }

        function setConsentMessage(value) {
            vm.consentFullMessage = value;
            setEHipaaConsentContent();
            vm.gskHCPText = value;
            if(value.length > PATIENT_CONSTS.CONSENT_MAX_TEXT) {
                vm.consentMessage = value.substring(0, PATIENT_CONSTS.CONSENT_MAX_TEXT);
                vm.showMoreConsent = true;
            } else {
                vm.consentMessage = value;
                vm.showMoreConsent = false;
            }
        }

        function setConsentEhipaaStatusMessage() {
            if (!vm.profileViewPage || Utility.isEmpty(vm.consent)) {
                return;
            }
            if (vm.getConsentStatus() === PATIENT_CONSTS.CONSENT_TEXT.STATUS.COMPLETE && !vm.consent.expired) {
                vm.displayCheckForUpdate = false;
                vm.displayPDFLink = true;
                var formattedSignedDate = vm.consent.initiateDate ? moment(vm.consent.initiateDate).format(GLOBAL_CONSTS.DATE_FORMAT.MM_DD_YYYY.SLASH) : GLOBAL_CONSTS.DASH;
                vm.patientEhipaaConsentMessage = PATIENT_CONSTS.CONSENT_TEXT.MESSAGE.COMPLETE.replace('__signedDate__', formattedSignedDate);
            } else {
                vm.patientEhipaaConsentMessage = PATIENT_CONSTS.CONSENT_ERROR.MESSAGE;
                vm.displayCheckForUpdate = !vm.consent.onfile && vm.displayEHipaaConsent;
            }
        }

        function getConsentStatus() {
            if (!Utility.isEmpty(vm.consent) && !Utility.isEmpty(vm.consent.status)) {
                return vm.ehipaaIncompleteStatusArray.indexOf(Utility.lowerCase(vm.consent.status)) >= 0
                    ? Utility.lowerCase(PATIENT_CONSTS.CONSENT_TEXT.STATUS.INCOMPLETE)
                    : Utility.lowerCase(vm.consent.status);
            } else {
                return null;
            }
        }

        function setEHipaaConsentContent() {
            if(Utility.isEmpty(vm.consent)) {
                return;
            }
            if (vm.consent.onfile) {
                vm.patientConsent.type = PATIENT_CONSTS.CONSENT_TEXT.TYPE.HCP;
                return;
            }

            if(vm.consent.expired) {
                var formattedExpiryDate = vm.consent.expiryDate ? moment(vm.consent.expiryDate).format(GLOBAL_CONSTS.DATE_FORMAT.MM_DD_YYYY.SLASH) : GLOBAL_CONSTS.DASH;
                vm.eHipaaText = Utility.updateConstantWithValue(PATIENT_CONSTS.CONSENT_TEXT.MESSAGE.CONSENT_EXPIRED, {
                    '__expiry-date__': formattedExpiryDate
                });
                vm.patientConsent.type = PATIENT_CONSTS.CONSENT_TEXT.TYPE.EHIPAA;
                vm.consent.status = PATIENT_CONSTS.CONSENT_TEXT.STATUS.CONSENT_EXPIRED;
                vm.isEhipaa = true;
                return;
            }

            switch (vm.getConsentStatus()) {
                case PATIENT_CONSTS.CONSENT_TEXT.STATUS.COMPLETE:
                    var formattedSignedDate = vm.consent.initiateDate ? moment(vm.consent.initiateDate).format(GLOBAL_CONSTS.DATE_FORMAT.MM_DD_YYYY.SLASH) : GLOBAL_CONSTS.DASH;
                    formattedExpiryDate = vm.consent.expiryDate ? moment(vm.consent.expiryDate).format(GLOBAL_CONSTS.DATE_FORMAT.MM_DD_YYYY.SLASH) : GLOBAL_CONSTS.DASH;
                    var replaceObj = {
                        '__signed-date__': formattedSignedDate,
                        '__expiry-date__': formattedExpiryDate
                    };
                    vm.eHipaaText = Utility.updateConstantWithValue(PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_COMPLETED_TEXT, replaceObj);
                    vm.displayEHipaaConsentTitle = false;
                    vm.displayPDFLink = true;
                    vm.displayEHipaaForm = false;
                    vm.patientConsent.type = PATIENT_CONSTS.CONSENT_TEXT.TYPE.EHIPAA;
                    vm.isEhipaa = true;
                    break;

                case PATIENT_CONSTS.CONSENT_TEXT.STATUS.EXPIRED:
                    var formattedSentDate = vm.consent.expiryDate ? moment(vm.consent.initiateDate).format(GLOBAL_CONSTS.DATE_FORMAT.MM_DD_YYYY.SLASH) : GLOBAL_CONSTS.DASH;
                    replaceObj = {
                        '__method__': vm.consent.channel || GLOBAL_CONSTS.DASH,
                        '__sentDate__': formattedSentDate
                    };
                    vm.eHipaaText = Utility.updateConstantWithValue(PATIENT_CONSTS.CONSENT_TEXT.MESSAGE.INVITATION_EXPIRED, replaceObj);
                    vm.patientConsent.type = PATIENT_CONSTS.CONSENT_TEXT.TYPE.EHIPAA;
                    vm.patientConsent.type = PATIENT_CONSTS.CONSENT_TEXT.TYPE.EHIPAA;
                    vm.isEhipaa = true;
                    break;

                case PATIENT_CONSTS.CONSENT_TEXT.STATUS.INCOMPLETE:
                    formattedSentDate = vm.consent.expiryDate ? moment(vm.consent.initiateDate).format(GLOBAL_CONSTS.DATE_FORMAT.MM_DD_YYYY.SLASH) : GLOBAL_CONSTS.DASH;
                    replaceObj = {
                        '__method__': vm.consent.channel || GLOBAL_CONSTS.DASH,
                        '__sentdate__': formattedSentDate
                    };
                    vm.eHipaaText = Utility.updateConstantWithValue(PATIENT_CONSTS.CONSENT_TEXT.MESSAGE.INCOMPLETE, replaceObj);
                    vm.patientConsent.type = PATIENT_CONSTS.CONSENT_TEXT.TYPE.EHIPAA;
                    vm.patientConsent.type = PATIENT_CONSTS.CONSENT_TEXT.TYPE.EHIPAA;
                    vm.isEhipaa = true;
                    break;

                default :
                    vm.eHipaaText = PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_DEFAULT_TEXT;
                    vm.displayEHipaaForm = true;
                    vm.displayEHipaaConsentTitle = true;
                    vm.isEhipaa = false;
                    vm.displayPDFLink = false;
            }
        }

        /**
         * Handle loading of ehipaa consent form,
         * sets values for disabling submit button of parent component
         * and setting isEhippa for validation related to ehipaa
         */
        function loadConsentUI() {
            if(!vm.displayEHipaaConsent || vm.profileViewPage) { return false; }

            if(angular.isDefined(vm.isEhipaa) && angular.isDefined(vm.parentDisableSubmit) && angular.isDefined(vm.patientConsent)) {
                vm.parentDisableSubmit = true;
                vm.patientInfo.patientConsent = {
                    type: ''
                };

                if(_consentIsEhipaa()) {
                    vm.patientConsent.type = PATIENT_CONSTS.CONSENT_TEXT.TYPE.EHIPAA;
                    vm.patientConsent.eHipaaStatus = vm.consent.status ? vm.consent.status : '';
                } else if(_consentIsHcp()) {
                    vm.patientConsent.type = PATIENT_CONSTS.CONSENT_TEXT.TYPE.HCP;
                }

                vm.changeConsent();
            } else {
                $log.error('could not load dependency for displaying e-hipaa consent');
            }
        }

        function _consentIsEhipaa() {
            return angular.isDefined(vm.consent)
                && angular.isDefined(vm.consent.onFile)
                && !Utility.isTruthy(vm.consent.onFile);
        }

        function _consentIsHcp() {
            return angular.isDefined(vm.consent)
                && angular.isDefined(vm.consent.onFile)
                && Utility.isTruthy(vm.consent.onFile);
        }


        function changeConsent() {
            if(Utility.lowerCase(vm.patientConsent.type) === Utility.lowerCase(PATIENT_CONSTS.CONSENT_TEXT.TYPE.HCP)) {
                vm.isEhipaa = false;
                _setProcessing(false);
                _setConsent(PATIENT_CONSTS.CONSENT_TEXT.TYPE_API.HCP);
                vm.parentDisableSubmit = false;
            } else if(Utility.lowerCase(vm.patientConsent.type) === Utility.lowerCase(PATIENT_CONSTS.CONSENT_TEXT.TYPE.EHIPAA)){
                vm.isEhipaa = true;
                _setProcessing(true);
                _setConsent(PATIENT_CONSTS.CONSENT_TEXT.TYPE_API.EHIPAA);
                vm.parentDisableSubmit = (angular.isUndefined(vm.allowedEhipaaStatus) && vm.patientConsent.eHipaaStatus) ||
                    (angular.isDefined(vm.allowedEhipaaStatus) && Utility.lowerCase(vm.patientConsent.eHipaaStatus) === Utility.lowerCase(vm.allowedEhipaaStatus)) ? false : true;
            } else {
                vm.isEhipaa = false;
                vm.parentDisableSubmit = true;
            }
        }

        function _setProcessing(value) {
            if(angular.isDefined(vm.ehipaaFormProcessing)) {
                vm.ehipaaFormProcessing = value;
            }
        }

        /**
         * This is for passing value through API
         * in order to change patient consent
         */
        function _setConsent(value) {
            vm.patientInfo.patientConsent.type = value;
        }

        function sendInvitationToPatient() {
            var req = _generateSendInvitationRequest();
            vm.sendingInvitation = true;
            ConsentService.sendInvitationToPatient(req).then(function (res) {
                _setProcessing(false);
                if(!vm.patientInfo.patientId && res.patientId) {
                    vm.patientInfo.patientId = res.patientId;
                }
                vm.consent = res;
                vm.setEHipaaConsentContent();
                vm.patientConsent.eHipaaStatus = Utility.lowerCase(res.status);
                if(angular.isDefined(vm.allowedEhipaaStatus)) {
                    vm.parentDisableSubmit = Utility.lowerCase(res.status) === Utility.lowerCase(vm.allowedEhipaaStatus) ?  false: true;
                } else {
                    vm.parentDisableSubmit = false;
                }
            }).catch(function (error) {
                ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(
                    true,
                    vm.errorTitle,
                    vm.errorMessage,
                    GLOBAL_CONSTS.TOAST_TYPES.ERROR
                ));
                $log.error(error);
            }).finally(function () {
                vm.sendingInvitation = false;
            });
        }

        function patientInfoFormIsValid() {
            PatientService.validateZipCode(vm.patientInfo.address.zipCode).then(function (validity) {
                vm.parentForm.zipCode.$setValidity('zipcode', validity);
            }).catch(function (err) {
                $log.info('could not validate zipCode');
            }).finally(function () {
                if(!(vm.parentForm.patientFirstName.$invalid
                    || vm.parentForm.patientLastName.$invalid
                    || vm.parentForm.dob.$invalid
                    || vm.parentForm.genderGroup.$invalid
                    || vm.parentForm.zipCode.$invalid
                    || vm.parentForm.ssnAndMedicareId.$invalid
                    || vm.parentForm.emailOrMobile.$invalid)) {
                    sendInvitationToPatient();
                } else {
                    $log.error('invalid form');
                    vm.parentForm.$submitted = true;
                }
            });
        }

        function _generateSendInvitationRequest() {
            var req = _getEmailOrMobile(vm.patientConsent.emailOrMobile);
            var patient = angular.copy(vm.patientInfo);
            var ssnOrMedicareId = _getSsnOrMedicareId(patient.ssnOrMedicareId);

            req.patient = {
                "patientId" : patient.patientId ? patient.patientId : "",
                "address": patient.address,
                "firstName": patient.firstName,
                "middleName": "",
                "lastName": patient.lastName,
                "ssn": ssnOrMedicareId.ssn,
                "gender": patient.gender,
                "dob": patient.dob,
                "medicareId": ssnOrMedicareId.medicareId,
                "practicePatientId": patient.practicePatientId ? patient.practicePatientId : "",
            };

            if(!Utility.isEmpty(req.patient.dob)) req.patient.dob = moment(req.patient.dob).format(SEARCH_CONSTS.DATE_FORMAT);

            return req;
        }

        function _getEmailOrMobile(emailOrMobile) {
            var obj = {};
            obj[(isNaN(emailOrMobile) ? 'email' : 'phone')] = emailOrMobile;
            return obj;
        }

        function _getSsnOrMedicareId(ssnOrMedicareId) {
            if(!Utility.isEmpty(ssnOrMedicareId)) {
                return {
                    'ssn': ssnOrMedicareId.length > 4 ? '' : ssnOrMedicareId,
                    'medicareId': ssnOrMedicareId.length > 4 ? ssnOrMedicareId : ''
                };
            }
            return {
                'ssn': '',
                'medicareId': ''
            };
        }

        function getValidationInfo() {
            return {
                invalidEmail: PATIENT_CONSTS.CONSENT_TEXT.VALIDATION.INVALID_EMAIL,
                invalidMobile: PATIENT_CONSTS.CONSENT_TEXT.VALIDATION.INVALID_MOBILE,
                emailOrMobileRequired: PATIENT_CONSTS.CONSENT_TEXT.VALIDATION.EMAIL_OR_MOBILE_REQUIRED,
            };
        }
        function openEhipaaPdf() {
            ConsentService.getEhipaaPdf(vm.patientId).then(function (pdfStringValue) {
                Utility.openPdf(Utility.base64ToArrayBuffer(pdfStringValue));
            }).catch(function (err) {
                $log.info(err);
            });
        }

        function displayCurrentStatus() {
            if(vm.consent && vm.consent.expired) {
                vm.displayCheckForUpdate = true;
                return ' - ' + PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_DISPLAY_STATUS.CONSENT_EXPIRED;
            }

            if(Utility.isEmpty(vm.consent && vm.consent.status)) {
                return;
            }

            if(!Utility.isEmpty(PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_DISPLAY_STATUS[Utility.upperCase(vm.consent.status)])) {
                vm.displayCheckForUpdate = true;
                return ' - ' + PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_DISPLAY_STATUS[Utility.upperCase(vm.consent.status)];
            } else {
                vm.displayCheckForUpdate = false;
                return null;
            }
        }

        function setDisplayEhipaaConsentFlag() {
            if(!Utility.isTruthy(ConfigurationService.getOrganizationProperty('enableEhipaaConsent'))) {
                ConfigurationService.fetchPortalConfiguration().then(function (response) {
                    vm.displayEHipaaConsent = Utility.isTruthy(response.orgPropertiesMap.enableEhipaaConsent) || 0;
                }).catch(function () {
                    vm.displayEHipaaConsent = 0;
                });
            } else {
                vm.displayEHipaaConsent = Utility.isTruthy(ConfigurationService.getOrganizationProperty('enableEhipaaConsent')) || 0;
            }
        }
    }
})();
