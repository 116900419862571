/**
 * Created by nikesh on 10/31/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PracticeSetup')
        .factory('PracticeSetupAddService', PracticeSetupAddService);

    PracticeSetupAddService.$inject = ['$log', 'PRACTICE_SETUP_CONSTS', 'Utility'];

    function PracticeSetupAddService($log, PRACTICE_SETUP_CONSTS, Utility) {

        // Breadcrumb conf
        var practiceSetupConfig = {
            currentStep: 1,
            totalNoOfSteps: 3,
            currentStepName: PRACTICE_SETUP_CONSTS.PRACTICE_SETUP_STEPS.ADD_PRACTICE,
            stepNames: [PRACTICE_SETUP_CONSTS.PRACTICE_SETUP_STEPS.ADD_PRACTICE, PRACTICE_SETUP_CONSTS.PRACTICE_SETUP_STEPS.ADD_LOCATION, PRACTICE_SETUP_CONSTS.PRACTICE_SETUP_STEPS.ADD_PROVIDER],
            breadcrumbInfo: [
                {isActive: true, name: PRACTICE_SETUP_CONSTS.PRACTICE_SETUP_STEPS.ADD_PRACTICE},
                {isActive: false, name: PRACTICE_SETUP_CONSTS.PRACTICE_SETUP_STEPS.ADD_LOCATION},
                {isActive: false, name: PRACTICE_SETUP_CONSTS.PRACTICE_SETUP_STEPS.ADD_PROVIDER}
            ],
            savingInformation: false
        };

        var service = {
            getPracticeSetupFlowConfig: getPracticeSetupFlowConfig,
            isNpiError: isNpiError
        };


        /**
         * @ngdoc function
         * @name getPracticeSetupFlowConfig
         * @methodOf eAccess.ProviderPortal.Components.PracticeSetup.service:PracticeSetupService
         * @description
         *
         * Gets practice setup flow configuration.
         *
         */
        function getPracticeSetupFlowConfig() {
            return angular.copy(practiceSetupConfig);
        }

        /**
         *
         * @param error
         * @returns {boolean}
         * @description checks message received in error parameter and returns true if any one of the
         * message matches the stored NPI error message
         */
        function isNpiError(error) {
            return !Utility.isEmpty(error.data)
                && !Utility.isEmpty(error.data.message.trim())
                && (error.data.message.trim() === PRACTICE_SETUP_CONSTS.NPI_TYPE_2_ERROR_MESSAGE
                    || error.data.message.trim() === PRACTICE_SETUP_CONSTS.UNABLE_TO_FIND_NPI_MESSAGE
                    || error.data.message.trim() === PRACTICE_SETUP_CONSTS.NPI_TYPE_1_ERROR_MESSAGE);
        }

        return service;
    }

})();
