/**
 * @ngdoc Controller
 * @module eAccess.ProviderPortal.Components.RxEligibilityResponse
 * @name ProductCoverageResponseController
 * @kind Controller
 *
 * @description
 * Controller for the product coverage section
 * @author girianish
 */

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .controller('ProductCoverageResponseController', ProductCoverageResponse);

    ProductCoverageResponse.$inject = ['$log', 'StatusStyles', 'Utility', 'RxProdCoverageService', 'ELIGIBILITY_CONSTS', 'STATUS_CONSTS'];

    function  ProductCoverageResponse($log, StatusStyles, Utility, RxProdCoverageService, ELIGIBILITY_CONSTS, STATUS_CONSTS) {
        var vm = this;

        vm.productCoverage = RxProdCoverageService.getProductCoverage();
        vm.copay = RxProdCoverageService.getCopayCoinsuranceObj();
        vm.coinsurance = RxProdCoverageService.getCopayCoinsuranceObj();


        vm.copayCoInsuranceUnknown = STATUS_CONSTS.COVERAGE.UNKNOWN;

        //functions
        vm.setProductCoverageStatus  = StatusStyles.setProductCoverageStatus;
        vm.getMinMaxCopay = RxProdCoverageService.getMinMaxCopay;
        vm.getCoinsurance = RxProdCoverageService.getCoinsurance;
        vm.setProductCoverageIconClass = StatusStyles.setProductCoverageIconClass;
        vm.getCopayTier = RxProdCoverageService.getCopayTier;
        vm.getCopayMessage = RxProdCoverageService.getCopayMessage;
        vm.getOutOfPocket = RxProdCoverageService.getOutOfPocket;
        vm.isObject = Utility.isObject;
        vm.checkIfAllUnknown = checkIfAllUnknown;
        vm.prepareCopayCoinsuranceObj = prepareCopayCoinsuranceObj;
        vm.prepareCopayObj = prepareCopayObj;
        vm.prepareCoinsuranceObj = prepareCoinsuranceObj;
        vm.checkVisibility = checkVisibility;

        vm.$onInit = function () {
            vm.prepareCopayCoinsuranceObj(vm.coverage);
        };


        function prepareCopayCoinsuranceObj(coverage) {
            if(!Utility.isEmpty(coverage)) {
                var costShareKeys = Object.keys(coverage);
                angular.forEach(costShareKeys,function(costShareKey){
                    angular.forEach(coverage[costShareKey], function (costShare){
                        var minMaxCopayCoinsurance = {};

                        var minMaxCopay =  RxProdCoverageService.getMinMaxCopay(costShare);
                        var coinsurance =  RxProdCoverageService.getCoinsurance(costShare);

                        // prepares the text and visiblity status for copay/coinsurance
                        vm.prepareCopayObj(minMaxCopay, costShareKey);
                        vm.prepareCoinsuranceObj(coinsurance, costShareKey);

                        /* hard copy the copay coinsurace on to the data model to be pushed in copay coinsurance
                          view model
                        */
                        minMaxCopayCoinsurance.copay = angular.copy(vm.copay[costShareKey]);
                        minMaxCopayCoinsurance.coinsurance = angular.copy(vm.coinsurance[costShareKey]);

                        vm.productCoverage[costShareKey].copayCoinsurance.push(minMaxCopayCoinsurance);

                    });
                });
            }

            $log.log(vm.productCoverage, "productcoverage");
        }

        function prepareCopayObj (minMaxCopay, costShareKey) {
            if(Utility.isObject(minMaxCopay)) {
                vm.copay[costShareKey].displayText = minMaxCopay;
                vm.copay[costShareKey].visible = true;
            } else {
                vm.copay[costShareKey].displayText = minMaxCopay;
                vm.copay[costShareKey].visible = false;
            }
        }

        function prepareCoinsuranceObj (coinsurance, costShareKey) {
            vm.coinsurance[costShareKey].displayText = coinsurance;
            if(Utility.lowerCase(coinsurance) !== ELIGIBILITY_CONSTS.COPAY_COINSURANCE.UNKNOWN) {
                vm.coinsurance[costShareKey].visible = true;
            } else {
                vm.coinsurance[costShareKey].visible = false;
            }
        }

        function checkIfAllUnknown(query) {
            var visibleCopayCoinsurance = Utility.filterFromArray(vm.productCoverage[query].copayCoinsurance, checkVisibility);
            return visibleCopayCoinsurance.length;

        }

        function checkVisibility(productCoverageObj) {
            return productCoverageObj.copay.visible || productCoverageObj.coinsurance.visible;
        }

    }

})()
;
