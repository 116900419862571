(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Widgets', [
            'eAccess.ProviderPortal.Widgets.MbvList',
            'eAccess.ProviderPortal.Widgets.MbvRecentlyCompletedList',
            'eAccess.ProviderPortal.Widgets.RecentlyCompletedList',
            'eAccess.ProviderPortal.Widgets.WeeklyCompletedBvs',
            'eAccess.ProviderPortal.Widgets.MonthlyCompletedBvs',
            'eAccess.ProviderPortal.Widgets.RecentlyExpiredBvList'
        ]);
})();
