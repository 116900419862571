/**
 * Created by mcaputo on 1/23/17.
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .controller('InsurancePolicyListController', InsurancePolicyListController);

    InsurancePolicyListController.$inject = ['$log', 'InsurancePolicyService', 'InsurancePolicy', 'ELIGIBILITY_CONSTS', 'ERROR_MESSAGES', 'HELP_TEXT_CONSTS', 'Utility'];

    function InsurancePolicyListController($log, InsurancePolicyService, InsurancePolicy, ELIGIBILITY_CONSTS, ERROR_MESSAGES, HELP_TEXT_CONSTS, Utility) {
        var vm = this;
        var _possibleRanks = InsurancePolicyService.getPossibleRanks();

        // Controller values
        vm.loadingPayers = false;
        vm.errorMessages = {
            payer: {
                required: 'Payer is required'
            },
            memberId: {
                required: 'Member ID is required'
            },
            providerOutOfNetwork: {
                required: 'Provider is in network is required',
            },
            subscriber: {
                required: 'Subscriber status is required',
                firstName: {
                    required: 'Subscriber First Name is required',
                    invalidLength: 'Subscriber First Name must be less than ' + ELIGIBILITY_CONSTS.FIRST_NAME_MAX_LENGTH + ' characters.'
                },
                lastName: {
                    required: 'Subscriber Last Name is required',
                    invalidLength: 'Subscriber Last Name must be less than ' + ELIGIBILITY_CONSTS.LAST_NAME_MAX_LENGTH + ' characters.'
                },
                dob: {
                    required: 'Subscriber Date of Birth is required',
                    future: ERROR_MESSAGES.DATE_IN_FUTURE
                },
                memberId: {
                    required: 'Subscriber Member ID is required'
                },
                gender: {
                    required: 'Subscriber Gender is required'
                }
            }
        };
        vm.subscriberFirstNameMaxLength = ELIGIBILITY_CONSTS.FIRST_NAME_MAX_LENGTH;
        vm.subscriberLastNameMaxLength = ELIGIBILITY_CONSTS.LAST_NAME_MAX_LENGTH;
        vm.datePicker = {
            format: 'MMddyyyy',
            opened: [{status: false}],
            options: {
                formatYear: 'yy',
                startingDay: 1,
                initDate: new Date('1991'),
                maxDate: new Date(),
                showWeeks: false
            }
        };

        vm.helpText = InsurancePolicyService.getHelpText();

        vm.possiblePlanLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'];

        // Controller functions
        vm.loadPayers = loadPayers;
        vm.addPolicy = addPolicy;
        vm.removePolicy = removePolicy;
        vm.canAddPolicy = canAddPolicy;
        vm.getPossibleRanks = getPossibleRanks;
        vm.triggerRankChange = triggerRankChange;
        vm.subscriberRequired = subscriberRequired;
        vm.openDatePicker = openDatePicker;
        vm.toggleIsSubscriber = toggleIsSubscriber;
        vm.isNewPatient = isNewPatient;
        vm.isPolicyRequired = isPolicyRequired;
        vm.isMemberIdRequired = isMemberIdRequired;
        vm.payerSelected = payerSelected;
        vm.isRequired = isRequired;

        // This function is called as soon as the InsurancePolicyComponent initializes the controller
        // Do any setup here
        vm.$onInit = function() {
            if(!isNewPatient()) vm.insurancePolicies[0].rank = Utility.firstCharacterUpperCase(ELIGIBILITY_CONSTS.POLICY_RANK.PRIMARY);
            loadPayers();
        };

        function isNewPatient() {
            return !Utility.isEmpty(vm.newPatient) && vm.newPatient === true;
        }

        /**
         * @ngdoc function
         * @name triggerRankChange
         * @methodOf eAccess.ProviderPortal.controller:InsurancePolicyListController
         * @returns {boolean}
         * @description
         *
         * Determines if any 2+ policies in the current policy list have the same rank
         */
        function triggerRankChange() {
            var isValidConfiguration = true;

            // clear all invalid-unique attributes
            angular.forEach(vm.insurancePolicies, function(policy, index) {
                vm.parentForm['policyRank_' + index].$setValidity('unique', true);
            });

            var usedRanks = {};
            vm.insurancePolicies.map(function(policy, index) {
                // Calculate the number of times each rank is used
                if(usedRanks[policy.rank]) {
                    usedRanks[policy.rank] += 1;
                    //sets policy rank validation for unique to false
                    vm.parentForm['policyRank_' + index].$setValidity('unique', false);
                    isValidConfiguration = false;
                } else {
                    usedRanks[policy.rank] = 1;
                }
                // always return true so .map continues to the next policy
                return true;
            });
            return isValidConfiguration;
        }

        /**
         * @ngdoc function
         * @name addPolicy
         * @methodOf eAccess.ProviderPortal.controller:InsurancePolicyListController
         * @description
         *
         * Adds a new, blank policy to the list of policies displayed
         */
        function addPolicy() {
            var newPolicy = new InsurancePolicy();

            /*  This is added as part of EP-3039 
                As per comment in Jira :
                We should hide the Patient Is Subscriber 
                field in the UI. At a later point, 
                we could remove the field also but 
                this is a safer step to keep defaulting 
                it to "Yes" for now.
            */
            newPolicy.subscriberPatient = true;

            // We can set up any policy initializations we want here, like preset a rank
            // Set rank
            newPolicy.rank = _possibleRanks[vm.insurancePolicies.length];
            //Closing all date picker
            _closeAllDatePickers();
            vm.insurancePolicies.push(newPolicy);
            //Adding default opened status to new policy subscriber date picker
            vm.datePicker.opened.push({status: false});
        }

        /**
         * @ngdoc function
         * @name removePolicy
         * @methodOf eAccess.ProviderPortal.controller:InsurancePolicyListController
         * @param policyToRemove
         * @description
         *
         * Removes the passed policy from the list of policies displayed.
         */
        function removePolicy(policyToRemove) {
            for(var i = vm.insurancePolicies.length; i >= 0; i--) {
                if(vm.insurancePolicies[i] === policyToRemove)
                    vm.insurancePolicies.splice(i, 1);
            }
            //Removing last opened status
            vm.datePicker.opened.slice(-1, 1);
            //Closing all date picker
            _closeAllDatePickers();
        }

        function _closeAllDatePickers(){
            vm.datePicker.opened.forEach(function(obj) {
                obj.status = false;
            });
        }

        /**
         * @ngdoc function
         * @name canAddPolicy
         * @methodOf eAccess.ProviderPortal.controller:InsurancePolicyListController
         * @description
         *
         * Determines whether or not another policy can be added
         */
        function canAddPolicy() {
            // Check that all current policies have been filled out correctly
            var hasIncompletePolicy = vm.insurancePolicies.some(function(provider) {
                if(!provider.payer || (provider.isMemberIdRequired && !provider.memberId) || !provider.rank) {
                    return true;
                }
                return false;
            });

            // Check that we have not added the max number of policies
            var maxPoliciesAdded = vm.insurancePolicies.length >= ELIGIBILITY_CONSTS.MAX_POLICIES_ALLOWED;

            return !(hasIncompletePolicy || maxPoliciesAdded);
        }

        /**
         * @ngdoc function
         * @name loadPayers
         * @methodOf eAccess.ProviderPortal.controller:InsurancePolicyListController
         * @description
         *
         * Attempts to load the possible payers for the payer drop down
         */
        function loadPayers() {
            vm.loadingPayers = true;
            InsurancePolicyService.loadPayers()
                .then(function(payers) {
                    vm.possiblePayers = payers;

                    // Determine if member id is required for pre-populated Insurance Carrier
                    setMemberRequiredFlagForPolicies();
                })
                .catch(function(err) {
                    $log.error(err);
                    // Set an empty array if nothing was found
                    vm.possiblePayers = [];
                })
                .finally(function() {
                    vm.loadingPayers = false;
                });
        }

        /**
         * @ngdoc function
         * @name getPossibleRanks
         * @methodOf eAccess.ProviderPortal.controller:InsurancePolicyListController
         * @returns {Array.<string>}
         * @description
         *
         * Slices the list of possible ranks to the number of policies currently added.
         * ie - For a list of one, response is ['Primary']
         *      For a list of two, response is ['Primary', 'Secondary']
         */
        function getPossibleRanks() {
            return _possibleRanks.slice(0, vm.insurancePolicies.length);
        }

        /**
         * @ngdoc function
         * @name subscriberRequired
         * @methodof eAccess.ProviderPortal.controller:InsurancePolicyListController
         * @description
         *
         * Determines if a policy's subscriber fields are required. All fields
         * in subscriber for a given policy are required iff any subscriber field
         * has a value in that policy.
         */
        function subscriberRequired(policy) {
            // If patient is the subscriber or no data entered, subscriber fields are not required
            if(policy.subscriberPatient !== false || !policy.subscriber) {
                return false;
            }

            // If any field has data entered, subscriber fields are required
            return policy.subscriber.firstName || policy.subscriber.lastName || policy.subscriber.dob || policy.subscriber.memberId || policy.subscriber.gender;
        }

        function openDatePicker(index) {
            vm.datePicker.opened[index].status = true;
        }

        function toggleIsSubscriber(policy) {
            if(policy.subscriberPatient) {
                delete policy.subscriber;
            } else {
                policy.subscriber = {};
            }
        }

        function isPolicyRequired(policy) {
            return !isNewPatient()
                || (!Utility.isEmpty(policy.rank) && policy.rank !== '')
                || (!Utility.isEmpty(policy.payer) && policy.payer !== '')
                || (!Utility.isEmpty(policy.memberId) && policy.memberId !== '')
                || (!Utility.isEmpty(policy.subscriberPatient) && policy.subscriberPatient !== '');
        }

        function isMemberIdRequired(policy) {
            return !Utility.isEmpty(policy.payer) && !Utility.isEmpty(policy.payer.requireMemberId) && policy.payer.requireMemberId === true;
        }

        function payerSelected(policy) {
            policy.isMemberIdRequired = vm.isMemberIdRequired(policy);
        }

        /**
         * For each pre-populated policy from vm.insurancePolicies list, finds the payer in vm.possiblePayers and uses its
         * requiredMemberId value to determine if member id for particular policy is required or not.
         *
         * NOTE: this is done to fix the inconsistency issue in Member Id label's optional text for cases like
         * BV re-verification and manage patient where Insurance Carrier field is pre-populated.
         */
        function setMemberRequiredFlagForPolicies() {
            angular.forEach(vm.insurancePolicies, function(policy) {
                if(!Utility.isEmpty(policy) && !Utility.isEmpty(policy.payer)) {
                    var selectedPayer = vm.possiblePayers.find(function(payers) {
                        return payers.id === policy.payer.id;
                    });
                    policy.isMemberIdRequired = !Utility.isEmpty(selectedPayer) && selectedPayer.requireMemberId;
                }
            });
        }
        
        function isRequired() {
            if(angular.isUndefined(vm.ehipaaFormProcessing)) {
                return true;
            }

            // form is not required till ehipaa
            // ehipaa consent form is submitted
            return (vm.ehipaaFormProcessing ? false : true);
        }


        vm.$onChanges = function(changes) {
            if(!Utility.isEmpty(changes.insurancePolicies) && changes.insurancePolicies.previousValue !== changes.insurancePolicies.currentValue) {
                setMemberRequiredFlagForPolicies();
            }
        };
    }
})();
