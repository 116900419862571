/**
 * @ngdoc service
 * @name eAccess.ProviderPortal.service:Auth
 * @requires $log
 * @requires $q
 * @description
 *
 * This is the service for handling Auth calls.
 **/

(function() {
    'use strict';

    angular.module('eAccess.ProviderPortal').factory('Auth', Auth);

    Auth.$inject = [
        '$rootScope',
        '$log',
        '$q',
        '$timeout',
        '$window',
        'API',
        'Storage',
        'Okta',
        'STATUS_CONSTS',
        'OKTA_CONSTS',
        'Utility',
        'USER_CONSTS',
        '$injector'
    ];

    function Auth($rootScope, $log, $q, $timeout, $window, API, Storage, Okta, STATUS_CONSTS, OKTA_CONSTS, Utility, USER_CONSTS, $injector) {

        var service = {
            login: login,
            logout: logout,
            isLoggedIn: isLoggedIn,
            isPracticeDefined: isPracticeDefined,
            refreshSession: refreshSession,
            isUserAccountInActive: isUserAccountInActive
        };

        /**
         * @ngdoc function
         * @name login
         * @methodOf eAccess.ProviderPortal.service:Auth
         * @description
         *
         * Returns the id and access tokens of a valid user.
         * Sets id token in id_token and access token in auth_token header.
         *
         * @param credentials
         */

        function login(credentials) {
            return $q(function(resolve, reject) {
                Okta.signIn(credentials)
                    .then(function(transaction) {
                        if(!Utility.isEmpty(transaction.status)) {
                            if(transaction.status === STATUS_CONSTS.ACCOUNT_STATUS.LOCKED || transaction.status === STATUS_CONSTS.ACCOUNT_STATUS.PASSWORD_EXPIRED) {
                                resolve(transaction);
                            } else {
                                handleReturnedTransaction(transaction)
                                    .then(function(tokens) {
                                        var auth_token = tokens[1].accessToken;
                                        var id_token = tokens[0].idToken;
                                        var expiresAt = tokens[1].expiresAt;
                                        var issuer = tokens[0].issuer;
                                        // adding user detail from okta to tokens object, which is require to display password expiring modal
                                        tokens.push(transaction.user);
                                        $log.debug(tokens);
                                        _saveTokenAndExpiresAt(auth_token, id_token, expiresAt);
                                        _saveIssuer(issuer, expiresAt);
                                        _saveLoggedInUserInfo(transaction);
                                        _saveUserRole(auth_token);
                                        resolve(tokens);
                                    })
                                    .catch(function(error) {
                                        $log.error('Sign in failed:', error);
                                        reject(error);
                                    });
                            }
                        }
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            });
        }

        function isUserAccountInActive(username) {
            var deferred = $q.defer();
            API.get('users/status/' + username).then(function(response) {
                return deferred.resolve(response);
            }).catch(function(err) {
                $log.error(err);
                deferred.reject(err);
            });
            return deferred.promise;
        }

        function _saveTokenAndExpiresAt(auth_token, id_token, expiresAt) {
            _saveState('auth_token', auth_token);
            _saveState('id_token', id_token);
            _saveState('session_expiration', expiresAt);
        }

        /**
         * function to save issuer, timer for idle_time_event callback and session_time_frame
         * @param issuer
         * @param expiresAt
         * @private
         */
        function _saveIssuer(issuer, expiresAt) {
            _saveState('issuer', issuer);
            // while first login idle time event call value will be same to session expire at value
            _saveState('idle_time_event', expiresAt);
            // calculate current local time in seconds
            var currentSeconds = Math.floor((new Date()) / 1000);
            _saveState('session_time_frame', (expiresAt - currentSeconds));
        }

        /**
         * @ngdoc function
         * @name refresh
         * @methodOf eAccess.ProviderPortal.service:Auth
         * @description
         *
         * Refresh the current session and then get the refreshed id_token and auth_token of the user
         * Sets id token in id_token, access token in auth_token header and expiresAt in session_expiration.
         *
         */
        function refreshSession() {
            var idToken = Storage.getSessionItem('id_token'),
                authToken = Storage.getSessionItem('auth_token'),
                issuer = Storage.getSessionItem('issuer'),
                refreshTimeConfig = Storage.getSessionItem('refresh_time_config');
            if (!Utility.isEmpty(idToken) && !Utility.isEmpty(authToken)) {
                return $q(function (resolve, reject) {
                    $q.all([_refreshSession(), _refreshIdToken(idToken, issuer), _refreshAuthToken(authToken, issuer)])
                        .then(function (data) {
                            var idTokenObject = Okta.tokenDecode(data[1].idToken);
                            _saveTokenAndExpiresAt(data[2].accessToken, data[1].idToken, data[2].expiresAt);
                            //timer for session_refresh_event callback
                            _saveState('session_refresh_time', (data[2].expiresAt - refreshTimeConfig));
                            _saveState('nonce', idTokenObject.payload.nonce);
                            resolve(true);
                        }).catch(function (e) {
                            reject(e);
                            $log.error("Unable to refresh session " + e);
                        });
                });
            }
        }

        function _refreshSession() {
            return Okta.refreshSession();
        }

        function _refreshIdToken(idToken, issuer) {
            return Okta.refreshIdToken(idToken, issuer);
        }

        function _refreshAuthToken(authToken, issuer) {
            return Okta.refreshAuthToken(authToken, issuer);
        }

        function isPracticeDefined() {
            var deferred = $q.defer();
            API.get('users/user').then(function(response) {
                if(response.practice === null) {
                    return deferred.resolve(false);
                } else {
                    return deferred.resolve(true);
                }
            }).catch(function(err) {
                deferred.reject(err);
            });
            return deferred.promise;
        }

        /**
         * @ngdoc function
         * @name handleReturnedTransaction
         * @methodOf eAccess.ProviderPortal.service:Auth
         * @description
         *
         * Handles the returned Okta transaction and fetches tokens
         * if authentication is successful. Otherwise, throw an error.
         *
         * @param transaction
         */

        function handleReturnedTransaction(transaction) {
            $log.debug('Handle transaction:', transaction.data);
            return $q(function(resolve, reject) {
                if(transaction.status === 'SUCCESS') {
                    Okta.getTokens(transaction.sessionToken).then(function(tokens) {
                        resolve(tokens);
                    }).catch(function(error) {
                        $log.error('Error retrieving tokens:', error);
                        reject(error);
                    });
                } else {
                    // Password Expired
                    if(transaction.status === OKTA_CONSTS.ERROR_CODES.PASSWORD_EXPIRED) {
                        return reject(transaction.data);
                    }
                    else {
                        return reject('We cannot handle the ' + transaction.status + ' status');
                    }
                }
            });
        }

        /**
         * @ngdoc function
         * @name login
         * @methodOf eAccess.ProviderPortal.service:Auth
         * @description
         *
         * Logs user out through Okta. Clears local storage session.
         */

        function logout() {
            Okta.signOut();
            Storage.clearAll();
            var userSessionEventService = $injector.get('UserSessionEvent');
            if(userSessionEventService) {
                userSessionEventService.clearAllEvents();
            }
        }

        function isLoggedIn() {
            return $q(function(resolve, reject) {
                Okta.isLoggedIn()
                    .then(function(response) {
                        $log.debug('isLoggedIn:', response);
                    })
                    .catch(function(error) {
                        $log.error('Error checking login status:', error);
                    });
            });
        }

        function _saveState(stateKey, stateValue) {
            Storage.saveToSession(stateKey, stateValue);
        }

        function _saveLoggedInUserInfo(userInfo) {
            _saveState(USER_CONSTS.FIRST_NAME_KEY, userInfo.user.profile.firstName);
            _saveState(USER_CONSTS.LAST_NAME_KEY, userInfo.user.profile.lastName);
            _saveState(USER_CONSTS.USER_ID_KEY, userInfo.user.id);
        }

        function _saveUserRole(accessToken) {
            var accessTokenObject = Okta.tokenDecode(accessToken);
            var userRole = [];
            if(angular.isDefined(accessTokenObject.payload.groupsForAccess)) {
                angular.forEach(accessTokenObject.payload.groupsForAccess, function(value, key) {
                    userRole.push(_parseString(value));
                });
            }
            _saveState('user_role', userRole);
        }

        /*
         parse the string and if it contain colon, return value after colon
         eg: "Practice A:user" then return "user"
         */
        function _parseString(str) {
            if(str.indexOf(':') > -1) {
                var array = str.split(':');
                return array[1];
            }
            return str;
        }

        return service;
    }

})();
