/**
 * Created by mcaputo on 1/27/17.
 */

(function () {
    'use strict';

    angular.module('eAccess.ProviderPortal.Directives').directive('eaDigitsOnly', EaDigitsOnly);

    EaDigitsOnly.$inject = ['$log'];

    // This directive limits an input to only digits
    function EaDigitsOnly($log) {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function (scope, element, attrs, modelCtrl) {
                modelCtrl.$parsers.push(function (inputValue) {
                    if (inputValue === null || inputValue === '') {
                        return '';
                    }
                    var validDigitsRegex = new RegExp('[^0-9]');
                    var cleanInputValue = inputValue.replace(validDigitsRegex, '');

                    if (cleanInputValue !== inputValue) {
                        modelCtrl.$setViewValue(cleanInputValue);
                        modelCtrl.$render();
                    }

                    return cleanInputValue;
                });
            }
        };
    }


})();
