/**
 * Created by mcaputo on 1/26/17.
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.MedEligibility', [
            'eAccess.ProviderPortal.Components.InsurancePolicy',
            'eAccess.ProviderPortal.Components.AdminCode',
            'eAccess.ProviderPortal.Components.Search',
            'eAccess.ProviderPortal.Components.Modals',
            'eAccess.ProviderPortal.Constants.Pharmacy'
        ]);
})();
