(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice', [
            'eAccess.ProviderPortal.Constants.InputFormValidation',
            'eAccess.ProviderPortal.Constants.TableName'
        ]);

})();
