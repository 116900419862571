/**
 * @ngdoc Controller
 * @module eAccess.ProviderPortal.Components.RxEligibilitySplitResponse
 * @name CoverageDetailController
 * @kind Controller
 *
 * @description
 * Controller for the coverage details section
 * @author Bikash Shrestha
 */

(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilitySplitResponse')
        .controller('CoverageDetailController', CoverageDetail);

    CoverageDetail.$inject = [];

    function CoverageDetail() {
        var vm = this;

        vm.toggleCoverageDetail = toggleCoverageDetail;

        function toggleCoverageDetail() {
            vm.coverageDetailExpanded = !vm.coverageDetailExpanded;
        }
    }
})();
