(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.SOBHeader')
        .controller('SOBHeaderController', SOBHeaderController);

    SOBHeaderController.$inject = ['$log', 'RxEligibilityService', 'Utility', 'SOBHeaderService', 'ERROR_MESSAGES'];

    function SOBHeaderController($log, RxEligibilityService, Utility, SOBHeaderService, ERROR_MESSAGES) {
        var vm = this;

        // data
        vm.isSOBDisplayable = false;

        // functions
        vm.openPdf = openPdf;

        vm.$onInit = function () {
            // If bvType is MANUAL and bvStatus is COMPLETE and sobIdentifier is present then'll SOB link is displayable
            vm.isSOBDisplayable = RxEligibilityService.isBvStatusCompleted(vm.bvStatus)
                && RxEligibilityService.isManualBv(vm.bvType)
                && !Utility.isEmpty(vm.documentIdentifiers)
                && !Utility.isEmpty(vm.documentIdentifiers.sobIdentifier);
        };

        function openPdf() {
            if (!Utility.isEmpty(vm.mbvId)) {
                var param = {
                    bvAdjudicationType: vm.bvAdjudicationType
                };
                SOBHeaderService.getSobFile(vm.mbvId, param).then(function (response) {
                    var data = Utility.base64ToArrayBuffer(response);
                    Utility.openPdf(data);
                }).catch(function (err) {
                    $log.debug('Error while fetching sob file', err);
                    var errorMessage = (!Utility.isEmpty(err.data) && !Utility.isEmpty(err.data.message)) ? err.data.message : ERROR_MESSAGES.GENERIC_ERROR;
                    vm.sobErrorMessageCallback(errorMessage);
                });
            }
        }
    }
})();
