(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PolicyCoverageList')
        .controller('PolicyCoverageListController', PolicyCoverageListController);

    PolicyCoverageListController.$inject = ['$uibModal', 'ELIGIBILITY_CONSTS', 'Product', 'StatusStyles', 'PolicyService', 'STATUS_CONSTS', 'Utility', 'HELP_TEXT_CONSTS', 'Eligibility', 'PAFormRequestModel', 'BV_SELECTION_CONSTS', 'RxEligibilityService', 'GLOBAL_CONSTS'];

    function PolicyCoverageListController($uibModal, ELIGIBILITY_CONSTS, Product, StatusStyles, PolicyService, STATUS_CONSTS, Utility, HELP_TEXT_CONSTS, Eligibility, PAFormRequestModel, BV_SELECTION_CONSTS, RxEligibilityService, GLOBAL_CONSTS) {
        var vm = this;

        // controller values
        vm.selectedDrawer = null;
        // TODO: These should be returned in the policy
        vm.paLink = {
            text: 'View PA Policy',
            url: 'http://google.com'
        };
        vm.policyLink = {
            text: 'View VirMedicaine Medical Policy',
            url: 'http://google.com'
        };
        vm.initiatePaButtonText = ELIGIBILITY_CONSTS.INITIATE_PA_BUTTON_TEXT;
        vm.coverageError = ELIGIBILITY_CONSTS.COVERAGE_ERROR_TEXT;
        vm.moopTooltipText = HELP_TEXT_CONSTS.BENEFIT_VERIFICATION.MOOP;
        vm.patientCostShare = {
            copay: {
                applies: false
            },
            coinsurance: {
                applies: true,
                patientAmount: '20%'
            },
            deductible: {
                applies: true,
                planDeductible: {
                    individual: 1500,
                    family: 'N/A'
                },
                amountMet: {
                    individual: 1500,
                    family: 'N/A'
                }
            },
            moop: {
                applies: true,
                planMOOP: {
                    individual: 3000,
                    family: 'N/A'
                },
                amountMet: {
                    individual: 1500,
                    family: 'N/A'
                }
            }
        };
        vm.paLinkLabel = ELIGIBILITY_CONSTS.PA_LINK_LABEL;
        vm.paFormLabel = ELIGIBILITY_CONSTS.PA_FORM_LABEL;
        vm.helpText = Eligibility.getMedBVResponseTooltip();

        vm.selectedProduct = Product.getCurrentProduct();

        // controller functions
        vm.selectPolicyDrawer = selectPolicyDrawer;
        vm.policyIsCovered = policyIsCovered;
        vm.coverageStatusIsCovered = coverageStatusIsCovered;
        vm.policyIsActive = policyIsActive;
        vm.showPlanDetails = showPlanDetails;
        vm.getAppliesText = getAppliesText;
        vm.showSOBModal = showSOBModal;
        vm.setActiveStatusClass = StatusStyles.setActiveStatusClass;
        vm.setIconClass = StatusStyles.setIconClass;
        vm.setCoverageDetailsClass = StatusStyles.setCoverageDetailsClass;
        vm.getJCode = PolicyService.getJCode;
        vm.getCopayText = PolicyService.getCopayText;
        vm.getCoinsuranceText = PolicyService.getCoinsuranceText;
        vm.getDeductibleText = PolicyService.getDeductibleText;
        vm.getMOOPText = PolicyService.getMOOPText;
        vm.copayApplies = PolicyService.copayApplies;
        vm.coinsuranceApplies = PolicyService.coinsuranceApplies;
        vm.deductibleApplies = PolicyService.deductibleApplies;
        vm.moopApplies = PolicyService.moopApplies;
        vm.getBuyBill = PolicyService.getBuyBill;
        vm.getSpecialtyPharmacy = PolicyService.getSpecialtyPharmacy;
        vm.getSpecialtyPharmacyDetails = PolicyService.getSpecialtyPharmacyDetails;
        vm.getPriorAuthorization = PolicyService.getPriorAuthorization;
        vm.getInsuranceStatus = getInsuranceStatus;
        vm.setTransactionErrorClass = StatusStyles.setTransactionErrorClass;
        vm.getAppliesTextClass = PolicyService.getAppliesTextClass;
        vm.getAppliesIconClass = PolicyService.getAppliesIconClass;
        vm.setDisplayTextIcon = setDisplayTextIcon;
        vm.setDisplayTextClass = StatusStyles.setDisplayTextClass;
        vm.getAccumulatorsValue = PolicyService.getAccumulatorsValue;
        vm.isAdminCodeValid = PolicyService.isAdminCodeValid;
        vm.getAdminCodeDescription = PolicyService.getAdminCodeDescription;
        vm.hasPaButton = hasPaButton;
        vm.hasPaLink = hasPaLink;
        vm.getPAForm = getPAForm;
        vm.paFormErrorMessage = {
            toastType: GLOBAL_CONSTS.TOAST_TYPES.ERROR,
            header: ELIGIBILITY_CONSTS.PA_FORM_MESSAGE.ERROR.HEADER,
            message: ELIGIBILITY_CONSTS.PA_FORM_MESSAGE.ERROR.MESSAGE
        };
        vm.isManualAndComplete= RxEligibilityService.isManualAndComplete;
        vm.getAdminCodes= PolicyService.getAdminCodes;
        vm.getDisplayValue = getDisplayValue;
        vm.isBlank = Utility.isBlank;

        vm.$onInit = function() {
            // firstIndex set to -1 to collapse policy section by default OR 0 to select the 1st policy by default
            var firstIndex = vm.insurancePolicies.length >= 1 ? 0 : -1;
            vm.selectedDrawer = firstIndex;
        };

        /**
         * @ngdoc function
         * @name selectPolicyDrawer
         * @methodOf eAccess.ProviderPortal.Components.PolicyCoverageList.controller:PolicyCoverageList
         * @description
         *
         * Sets a policy drawer as selected. If the selected drawer is already open,
         * selectPolicyDrawer removes that drawer as the selected drawer, otherwise the drawer
         * is set as selected.
         *
         * @param policyIndex
         */
        function selectPolicyDrawer(policyIndex) {
            vm.selectedDrawer = _policyAlreadyOpen(policyIndex) ? null : policyIndex;
        }

        function policyIsCovered(policy) {
            if(!policy.plan.covered) {
                return false;
            }
            return policy.plan.covered;

        }

        function coverageStatusIsCovered(policy) {
            var jcode = vm.getJCode(policy);
            if(!jcode.coverageStatus) {
                return false;
            }
            return Utility.lowerCase(jcode.coverageStatus) === STATUS_CONSTS.COVERAGE.COVERED;
        }

        function policyIsActive(policy) {
            if(!policy.plan.status) {
                return false;
            }
            return Utility.lowerCase(policy.plan.status) === 'active';
        }

        /**
         * @ngdoc function
         * @name showPlanDetails
         * @methodOf eAccess.ProviderPortal.Components.PolicyCoverageList.controller:PolicyCoverageList
         * @description
         *
         *  Display the plan details within a pop up modal
         *
         * @param policy
         */
        function showPlanDetails(policy) {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'plan-details-title',
                arialDescribedBy: 'plan-details-content',
                templateUrl: 'partials/templates/plan-details-modal.html',
                controller: 'PlanDetailsModalController',
                controllerAs: 'planDetails',
                appendTo: angular.element('body'),
                resolve: {
                    plan: function() {
                        return policy.plan;
                    }
                }
            });
        }

        function _policyAlreadyOpen(policyIndex) {
            return vm.selectedDrawer === policyIndex;
        }

        function getAppliesText(element) {
            if(!element) {
                return 'Does not apply';
            }
            return element.applies ? 'Applies' : 'Does not apply';
        }

        /**
         * @ngdoc function
         * @name showSOBModal
         * @methodOf eAccess.ProviderPortal.Components:PolicyCoverageListController
         * @description
         *
         * Displays the Statement of Benefits modal
         **/
        function showSOBModal(policy) {
            // Only show modal if sob was incomplete
            // if(policy.sob) {
            //     return;
            // }
            var sobModal = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'sob-title',
                arialDescribedBy: 'sob-content',
                templateUrl: 'partials/templates/sob-modal.html',
                controller: 'SOBModalController',
                controllerAs: 'sobCtrl',
                appendTo: angular.element('body'),
                resolve: {
                    sobComplete: function() {
                        return false;
                    }
                }
            });

            sobModal.result.then(function(result) {
                vm.manualBvSubmitted = result;
            }, function() {
            });

            return sobModal;
        }

        function hasPaButton(policy) {
            return PolicyService.getPAFormId(policy);
        }

        function hasPaLink(policy) {
            return PolicyService.getPALink(policy);
        }

        function getPAForm(policy) {
            var formId = PolicyService.getPAFormId(policy);
            var paFormRequest = new PAFormRequestModel({
                adjudicationMethod: BV_SELECTION_CONSTS.METHOD_NAME.MEDICAL,
                bvType: vm.bvType,
                responseId: vm.responseId,
                formTemplateId: formId,
                policyRank: policy.rank
            });
            vm.paFormCallback(PolicyService.getPAForm(paFormRequest));
        }

        function getInsuranceStatus(policy) {
            return Eligibility.getInsuranceStatus(policy, vm.bvType, vm.bvStatus);
        }

        function getDisplayValue(value, returnValue) {
            return Eligibility.getDisplayValue(value, returnValue, vm.bvType, vm.bvStatus);
        }

        function setDisplayTextIcon(statusText) {
            return Eligibility.setDisplayTextIcon(statusText, vm.bvType, vm.bvStatus);
        }


        /*
        function getJCode(policy) {
            var jcode = null;

            if(!policy.coverage){
                return null;
            }

            Object.keys(policy.coverage).forEach(function(key, index) {
                // Identify the JCODE
                if (policy.coverage[key].type === 'J_CODE') {
                    jcode = policy.coverage[key];
                }
            });

            return jcode;
        }

        function getCopayText(adminCode) {
            if(adminCode === null || angular.isUndefined(adminCode.costShare) || angular.isUndefined(adminCode.costShare.copay) || adminCode === null) {
                return 'Undetermined';
            }
            return vm.copayApplies(adminCode) ? 'Applies' : 'Does not apply';
        }

        function getCoinsuranceText(adminCode) {
            if(adminCode === null || angular.isUndefined(adminCode.costShare) || angular.isUndefined(adminCode.costShare.coinsurance) || adminCode === null) {
                return 'Undetermined';
            }
            return vm.coinsuranceApplies(adminCode) ? 'Applies' : 'Does not apply';
        }

        function getDeductibleText(adminCode) {
            if(adminCode === null || angular.isUndefined(adminCode.costShare) || angular.isUndefined(adminCode.costShare.deductible) || adminCode === null) {
                return 'Undetermined';
            }
            return vm.deductibleApplies(adminCode) ? 'Applies' : 'Does not apply';
        }

        function getMOOPText(adminCode) {
            if(adminCode === null || angular.isUndefined(adminCode.costShare) || angular.isUndefined(adminCode.costShare.moop) || adminCode === null) {
                return 'Undetermined';
            }
            return vm.moopApplies(adminCode) ? 'Applies' : 'Does not apply';
        }

        function getRuleID(adminCode) {
            if(adminCode === null || angular.isUndefined(adminCode)) {
                return 'Undetermined';
            }
            return adminCode.ruleId;
        }

        function copayApplies(adminCode) {
            if (adminCode === null || angular.isUndefined(adminCode) || angular.isUndefined(adminCode.costShare) || angular.isUndefined(adminCode.costShare.copay)) {
                return false;
            }
            return angular.lowercase(adminCode.costShare.copay.applies) === 'yes';
        }

        function coinsuranceApplies(adminCode) {
            if (adminCode === null || angular.isUndefined(adminCode) || angular.isUndefined(adminCode.costShare) || angular.isUndefined(adminCode.costShare.coinsurance)) {
                return false;
            }
            return angular.lowercase(adminCode.costShare.coinsurance.applies) === 'yes';
        }

        function deductibleApplies(adminCode) {
            if (adminCode === null || angular.isUndefined(adminCode) || angular.isUndefined(adminCode.costShare) || angular.isUndefined(adminCode.costShare.deductible)) {
                return false;
            }
            return angular.lowercase(adminCode.costShare.deductible.applies) === 'yes';
        }

        function moopApplies(adminCode) {
            if (adminCode === null || angular.isUndefined(adminCode) || angular.isUndefined(adminCode.costShare) || angular.isUndefined(adminCode.costShare.moop)) {
                return false;
            }
            return angular.lowercase(adminCode.costShare.moop.applies) === 'yes';
        }*/
    }
})();
