(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Services.ebvResponseMapper')
        .factory('ebvResponseMapper', ebvResponseMapper);

    ebvResponseMapper.$inject = ['$log', '$q', 'PolicyService', 'STATUS_CONSTS', 'Utility', 'ELIGIBILITY_CONSTS'];

    function ebvResponseMapper($log, $q, PolicyService, STATUS_CONSTS, Utility, ELIGIBILITY_CONSTS) {
        var service = {
            mapResponse: mapResponse
        };

        function mapResponse(response) {
            var responseData = angular.copy(response);

            var policies = responseData.policies;
            policies.map(_mapPolicies);
            if(angular.isDefined(response.responseDate)) {
                responseData.responseDate = response.responseDate;
            }
            if(!Utility.isEmpty(response.bvType)) {
                responseData.bvType = response.bvType;
            }
            if(!Utility.isEmpty(response.bvAdjudicationType)) {
                responseData.bvAdjudicationType = response.bvAdjudicationType;
            }
            return $q.when(responseData);
        }

        function _mapPolicies(policy) {
            var mappedPolicy;

            // separate JCodes and Admin Codes
            var jcode = PolicyService.getJCode(policy);
            var adminCodes = PolicyService.getAdminCodes(policy);

            // map JCode
            var mappedJCode = _mapAdminCode(jcode, policy.amounts);
            $log.debug(mappedJCode);

            // map Admin Codes
            adminCodes.map(function(code) {
                return _mapAdminCode(code, policy.amounts);
            });

            return mappedPolicy;
        }

        function _mapAdminCode(code, amounts) {
            var costShare = {};

            if(angular.isUndefined(code) || code === null) {
                return {};
            }

            if(angular.isUndefined(code.applies) || code.applies === null) {
                code.applies = {};
            }

            // copay
            costShare.copay = {
                applies: code.applies.copay,
                pcp: Utility.isApplied(code.applies.copay) ? amounts.copayPCP : ELIGIBILITY_CONSTS.CONS_N_A,
                specialist: Utility.isApplied(code.applies.copay) ? !Utility.isBlank(amounts.productCopaySpecialist) ? amounts.productCopaySpecialist : !Utility.isBlank(amounts.copaySpecialist) ? amounts.copaySpecialist : amounts.copayPCP : ELIGIBILITY_CONSTS.CONS_N_A,
                adminCopaySpecialist: Utility.isApplied(code.applies.copay) ? !Utility.isBlank(amounts.adminCopaySpecialist) ? amounts.adminCopaySpecialist : !Utility.isBlank(amounts.copaySpecialist) ? amounts.copaySpecialist : !Utility.isBlank(amounts.copayPCP) ? amounts.copayPCP : STATUS_CONSTS.COVERAGE.NOT_FOUND : ELIGIBILITY_CONSTS.CONS_N_A
            };

            // coinsurance
            costShare.coinsurance = {
                applies: code.applies.coinsurance,
                patientAmount: Utility.isApplied(code.applies.coinsurance) ? !Utility.isBlank(amounts.productCoinsurance) && Utility.lowerCase(amounts.productCoinsurance) !== STATUS_CONSTS.ACCUMULATORS.UNAVAILABLE  ? amounts.productCoinsurance : amounts.coinsurance : ELIGIBILITY_CONSTS.CONS_N_A,
                adminAmount: Utility.isApplied(code.applies.coinsurance) ? _isValueNotBlankAndUnavailable(amounts.adminCoinsurance) ? amounts.adminCoinsurance : _isValueNotBlankAndUnavailable(amounts.coinsurance) ? amounts.coinsurance : STATUS_CONSTS.COVERAGE.NOT_FOUND : ELIGIBILITY_CONSTS.CONS_N_A
            };

            // deductible
            costShare.deductible = {
                applies: code.applies.deductible,
                individual: {
                    amount: (amounts.individualDeductible && Utility.isApplied(code.applies.deductible)) ? amounts.individualDeductible : ELIGIBILITY_CONSTS.CONS_N_A,
                    amountMet: (amounts.individualDeductibleMet && Utility.isApplied(code.applies.deductible)) ? amounts.individualDeductibleMet : ELIGIBILITY_CONSTS.CONS_N_A,
                    applies: code.applies.individualDeductible || ELIGIBILITY_CONSTS.CONS_N_A
                },
                family: {
                    amount: (amounts.familyDeductible && Utility.isApplied(code.applies.deductible)) ? amounts.familyDeductible : ELIGIBILITY_CONSTS.CONS_N_A,
                    amountMet: (amounts.familyDeductibleMet && Utility.isApplied(code.applies.deductible)) ? amounts.familyDeductibleMet : ELIGIBILITY_CONSTS.CONS_N_A,
                    applies: code.applies.familyDeductible || ELIGIBILITY_CONSTS.CONS_N_A
                }
            };

            // moop
            costShare.moop = {
                applies: code.applies.moop,
                individual: {
                    amount: (amounts.individualMoop && Utility.isApplied(code.applies.moop)) ? amounts.individualMoop : ELIGIBILITY_CONSTS.CONS_N_A,
                    amountMet: (amounts.individualMoopMet && Utility.isApplied(code.applies.moop)) ? amounts.individualMoopMet : ELIGIBILITY_CONSTS.CONS_N_A,
                    applies: code.applies.individualMoop || ELIGIBILITY_CONSTS.CONS_N_A
                },
                family: {
                    amount: (amounts.familyMoop && Utility.isApplied(code.applies.moop)) ? amounts.familyMoop : ELIGIBILITY_CONSTS.CONS_N_A,
                    amountMet: (amounts.familyMoopMet && Utility.isApplied(code.applies.moop)) ? amounts.familyMoopMet : ELIGIBILITY_CONSTS.CONS_N_A,
                    applies: code.applies.familyMoop || ELIGIBILITY_CONSTS.CONS_N_A
                }
            };

            code.costShare = costShare;
            return code;
        }

        function _isValueNotBlankAndUnavailable(value) {
            return !Utility.isBlank(value) && Utility.lowerCase(value) !== STATUS_CONSTS.ACCUMULATORS.UNAVAILABLE;
        }

        return service;
    }
})();
