/**
 * @ngdoc service
 * @name eAccess.ProviderPortal.Components.Practice.service:PracticeView
 * @author Sterling Stokes
 * @description
 *
 * This is the service for view functions that apply to all practice views.
 **/

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .factory('PracticeView', PracticeView);

    PracticeView.$inject = ['Utility'];

    function PracticeView(Utility) {

        var DEFAULTS = {
            TITLE: 'My Practice | Practice Information',
            TAB_INDEX: 0,
            displayPracticeTabs: true
        };

        var service = {
            configure: configure,
            setTabInfo: setTabInfo,
            setPageTitle: setPageTitle,
            setPracticeViewBtn: setPracticeViewBtn,
            setActiveTab: setActiveTab,
            setResetPasswordLink: setResetPasswordLink,
            setChangePasswordLink: setChangePasswordLink,
            setPracticeTabsVisbility:setPracticeTabsVisbility,
            setContentTitle: setContentTitle
        };

        return service;

        /**
         * @ngdoc function
         * @name configure
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:PracticeView
         * @description
         *
         * Configures all info for Practice view based on passed config object.
         *
         * @param config
         * @example configure({
         *     title: '',
         *     tabIndex: '',
         *     buttonText: '',
         *     buttonHref: '',
         * })
         */

        function configure(config) {
            setTabInfo(config.title, config.contentTitle, config.tabIndex);
            setPracticeViewBtn(config.buttonText, config.buttonHref);
            setPracticeEditLink(config.editText, config.editHref, config.editId, config.editViewableStatus);
            setResetPasswordLink(config.resetPasswordText, config.user, config.passwordChanged);
            setChangePasswordLink(config.changePasswordText, config.changePasswordHref, config.passwordChanged);
            setPracticeTabsVisbility(config.displayPracticeTabs);
        }


        /**
         * @ngdoc function
         * @name setTabInfo
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:PracticeView
         * @description
         *
         * Sets the tab info for a practice view, including setting the page title
         * and changing the active tab.
         *
         * @param title
         * @param tabIndex
         */

        function setTabInfo(title, contentTitle, tabIndex) {
            setPageTitle(title);
            setContentTitle(contentTitle);
            setActiveTab(tabIndex);
        }

        /**
         * @ngdoc function
         * @name setPageTitle
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:PracticeView
         * @description
         *
         * Sets the page title on the practice view.
         *
         * @param title
         */

        function setPageTitle(title) {
            service.pageTitle = title || DEFAULTS.TITLE;
        }

        /**
         * @ngdoc function
         * @name setContentTitle
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:PracticeView
         * @description
         *
         * Sets the content title on the practice view.
         *
         * @param contentTitle
         */
        function setContentTitle(contentTitle) {
            service.contentTitle = contentTitle || null;
        }

        /**
         * @ngdoc function
         * @name setActiveTab
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:PracticeView
         * @description
         *
         * Sets the active tab on practice view.
         *
         * @param tabIndex
         */

        function setActiveTab(tabIndex) {
            service.activeTab = tabIndex || DEFAULTS.TAB_INDEX;
        }

        /**
         * @ngdoc function
         * @name setPracticeViewBtn
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:PracticeView
         * @description
         *
         * Sets the text and href for the page button on the practice view.
         *
         * @param buttonText
         * @param href
         */

        function setPracticeViewBtn(buttonText, href) {
            service.practiceViewBtn = {
                buttonText: buttonText || null,
                href: href || null
            };
        }

        /**
         * @ngdoc function
         * @name setPracticeEditLink
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:PracticeView
         * @description
         *
         * Sets the text and href for the page edit on the practice view.
         *
         * @param editText
         * @param href
         * @param editId
         * @param viewableStatus used to determine if edit link is to be displayed
         */

        function setPracticeEditLink(editText, href, editId, viewableStatus) {
            service.practiceEditLink = {
                editText: editText || null,
                editHref: href || null,
                editId: editId || null,
                editViewableStatus: viewableStatus || false
            };
        }

        /**
         * @ngdoc function
         * @name setResetPasswordLink
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:PracticeView
         * @description
         *
         * Sets the text and id for the user reset password link on the practice view.
         *
         * @param resetPasswordText
         * @param user
         * @param passwordChanged
         */

        function setResetPasswordLink(resetPasswordText, user, passwordChanged) {
            service.resetPasswordLink = {
                resetPasswordText: resetPasswordText || null,
                user: user || null,
                passwordChanged: passwordChanged || null
            };
        }

        /**
         * @ngdoc function
         * @name setChangePasswordLink
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:PracticeView
         * @description
         *
         * Sets the text and id for the user change password link on the practice view.
         *
         * @param changePasswordText
         * @param changePasswordHref
         * @param passwordChanged
         */

        function setChangePasswordLink(changePasswordText, changePasswordHref, passwordChanged) {
            service.changePasswordLink = {
                changePasswordText: changePasswordText || null,
                changePasswordHref: changePasswordHref || null,
                passwordChanged: passwordChanged || null
            };
        }

        function setPracticeTabsVisbility(isPracticeTabVisible) {
            service.displayPracticeTabs = !Utility.isEmpty(isPracticeTabVisible) ? isPracticeTabVisible : DEFAULTS.displayPracticeTabs;
        }

    }

})();
