/**
 * Created by aayush.regmi on 9/9/2017.
 */
(function() {
    'use strict';

    var PAGE_TITLES = {
        PRACTICE: {
            BASE: 'My Practice | ',
            DEFAULT: 'My Practice | Practice Information |',
            EDIT: 'Edit Practice Information'
        },
        USER: {
            USERS: 'Users',
            MY_ACCOUNT: 'My Account',
            EDIT_ACCOUNT: 'Edit Account',
            EDIT_USER: 'Edit User'
        },
    };

    angular.module('eAccess.ProviderPortal').constant('PAGE_TITLES', PAGE_TITLES);

})();
