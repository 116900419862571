(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Services.ReactivateDeactivate', [])
        .factory('ReactivateDeactivate', ReactivateDeactivate);

    ReactivateDeactivate.$inject = ['$log', 'CannotDeactivateAllService', '$uibModal', 'STATUS_CONSTS', 'Utility', 'GLOBAL_CONSTS'];

    function ReactivateDeactivate($log, CannotDeactivateAllService, $uibModal, STATUS_CONSTS, Utility, GLOBAL_CONSTS) { 
        var defaultSettings = {
            reactivate : null,
            deactivate: null,
            cannotDeactivate: null
        };

        // constructor
        function reactivateDeactivate(settings) {
            angular.extend(this, defaultSettings, settings);

            //dynamic modal Settings
            this.modalSubTitle = null;
            this.modalTitle = null;
            this.viewableStatus = null;
            this.list = null;
        }

       // private stuffs       
        function _setModalTitle(title) {
            var action = this.viewableStatus === STATUS_CONSTS.STATUS.ACTIVE ? GLOBAL_CONSTS.DEACTIVATE : GLOBAL_CONSTS.REACTIVATE;
            var singularOrPlural = this.list.length <= 1 ? title : title.concat('s');
            this.modalTitle = action.concat(' ').concat(singularOrPlural);
        }

        function _getMessageForDeactivate() {
            return this.list.length > 1 
                        ? this
                            .deactivate
                            .multipleMessage
                            .replace(this.deactivate.replaceString, this.list.length + '') 
                        : this.deactivate.singleMessage;
        }

        function _getMessageForReactivate() {
            return this.list.length > 1 
                        ? this
                            .reactivate
                            .multipleMessage
                            .replace(this.reactivate.replaceString, this.list.length + '')
                        : this.reactivate.singleMessage;
        }

        function _openUIBModal() {
            var curentModalSettings = this;
            var changeUserStatusModal = $uibModal.open({
                animation: true,
                templateUrl: 'partials/templates/deactivate-reactivate-item-modal.html',
                controller: 'DeactivateReactivateItemModalController',
                controllerAs: 'deactivateReactivateItem',
                appendTo: angular.element('body'),
                resolve: {
                    titleText: function() {
                        return curentModalSettings.modalTitle;
                    },
                    subTitleText: function() {
                        return curentModalSettings.modalSubTitle;
                    },
                    message: function() {
                        return curentModalSettings.viewableStatus === STATUS_CONSTS.STATUS.ACTIVE 
                                    ? _getMessageForDeactivate.call(curentModalSettings) 
                                    : _getMessageForReactivate.call(curentModalSettings);
                    },
                    isDeactivate: function() {
                        return curentModalSettings.viewableStatus === STATUS_CONSTS.STATUS.ACTIVE;
                    }
                }
            });
            return changeUserStatusModal.result;
        }

        // these are available functions to users

        reactivateDeactivate.prototype.setCurrentState = function (list, viewableStatus) {
            this.viewableStatus = viewableStatus;
            this.list = list;
        }; 

        reactivateDeactivate.prototype.openReactivateDeactivateModal = function () {
            return _openUIBModal.call(this);  
        };

        reactivateDeactivate.prototype.setModalTitle = function (title) {
            _setModalTitle.call(this, title);
        };

        reactivateDeactivate.prototype.openCannotReactivateDeactivateModal = function () {
            CannotDeactivateAllService.show({
                modalTitle: this.cannotDeactivate.modalTitle,
                modalBody: this.cannotDeactivate.modalBody,
                displaySecondaryButton: this.cannotDeactivate.displaySecondaryButton
            });
        };

        reactivateDeactivate.prototype.resetDynamicSettings = function () {
            this.modalSubTitle = null;
            this.modalTitle = null;
            this.viewableStatus = null;
            this.list = null;
        };

        return reactivateDeactivate;

    }

})()
;
