/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.controller:StyleguideController
 * @author Anna Lee
 * @requires $log
 * @description
 *
 * This is the controller for the Styleguide page.
 **/


(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.DocsStyleguide')
        .controller('DocsStyleguideController',DocsStyleguideController);

    DocsStyleguideController.$inject = ['$log'];

    function DocsStyleguideController ($log) {
        var vm = this;

        // data
        vm.hardCodedColors = [
            {
                label: 'Virmedica blue',
                value: '#2861a4',
                variableName: '$vm-blue'
            },
            {
                label: 'Virmedica teal',
                value: '#2ab0e8',
                variableName: '$vm-teal'
            },
            {
                label: 'Green',
                value: '#008000',
                variableName: '$green'
            },
            {
                label: 'Orange',
                value: '#ffa500',
                variableName: '$orange'
            },
            {
                label: 'Red',
                value: '#ff0000',
                variableName: '$red'
            },
            {
                label: 'Light blue',
                value: '#e3edf9',
                variableName: '$light-blue'
            },
            {
                label: 'White',
                value: '#fff',
                variableName: '$white'
            },
            {
                label: 'Gray lighter',
                value: '#eeeeee',
                variableName: '$gray-lighter'
            },
            {
                label: 'Gray-light',
                value: '#777777',
                variableName: '$gray-light'
            },
            {
                label: 'Gray',
                value: '#555555',
                variableName: '$gray'
            },
            {
                label: 'Gray dark',
                value: '#333333',
                variableName: '$gray-dark'
            },
            {
                label: 'Gray darker',
                value: '#222222',
                variableName: '$gray-darker'
            },
            {
                label: 'Black',
                value: '#000',
                variableName: '$black'
            }
        ];

        vm.colorAliases = [
            {
                label: 'Primary',
                value: '#2861a4',
                variableName: '$primary',
                correspondingColor: '$vm-blue'
            },
            {
                label: 'Secondary',
                value: '#2ab0e8',
                variableName: '$secondary',
                correspondingColor: '$vm-teal'
            },
            {
                label: 'Tertiary',
                value: '#333333',
                variableName: '$tertiary',
                correspondingColor: '$gray-dark'
            },
            {
                label: 'Success',
                value: '#008000',
                variableName: '$success',
                correspondingColor: '$green'
            },
            {
                label: 'Warning',
                value: '#ffa500',
                variableName: '$warning',
                correspondingColor: '$orange'
            },
            {
                label: 'Danger',
                value: '#ff0000',
                variableName: '$danger',
                correspondingColor: '$red'
            }
        ];
    }
})();
