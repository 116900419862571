/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.controller:HelpController
 * @author Mike Caputo, Sterling Stokes
 * @requires $log
 * @description
 *
 * This is the controller for the Help page.
 **/


(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .controller('HelpController', HelpController);

    HelpController.$inject = ['$log'];

    function HelpController ($log) {

        var vm = this;

        $log.debug(vm);

    }

})();
