/**
 * Created by nikesh on 10/31/2017.
 */
(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PracticeSetup')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('practice-setup.add', {
                parent: 'practice-setup',
                url: '/add',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Practice Setup Add'
                },
                views: {
                    'practiceSetupContent': {
                        templateUrl: 'components/practice-setup/add/practice-setup-add.html',
                        controller: 'PracticeSetupAddController as practiceSetupAdd'
                    }
                }
            });
    }

})();
