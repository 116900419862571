(function() {
    'use strict';

    var eaRecentlyCompletedListWidget = {
        bindings: {
            sobErrorMessage: '=' // error message when unable to get sob pdf file
        },
        templateUrl: 'components/dashboard/widgets/recently-completed-bvs/recently-completed-list.html',
        controller: 'RecentlyCompletedListController as recentlyCompletedList'
    };

    angular
        .module('eAccess.ProviderPortal.Widgets.RecentlyCompletedList')
        .component('eaRecentlyCompletedListWidget', eaRecentlyCompletedListWidget);
})();
