(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Widgets.MbvRecentlyCompletedList')
        .controller('MbvRecentlyCompletedListController', MbvRecentlyCompletedListController);

    MbvRecentlyCompletedListController.$inject = ['$log', '$state', 'CaseBVService', 'Eligibility', 'ELIGIBILITY_CONSTS', 'StatusStyles', 'Utility', 'ConfigurationService', '$anchorScroll', 'GLOBAL_CONSTS', 'ToastNotification', 'ToastNotificationService', 'ERROR_MESSAGES', 'STATUS_CONSTS'];

    function MbvRecentlyCompletedListController($log, $state, CaseBVService, Eligibility, ELIGIBILITY_CONSTS, StatusStyles, Utility, ConfigurationService, $anchorScroll, GLOBAL_CONSTS, ToastNotification, ToastNotificationService, ERROR_MESSAGES, STATUS_CONSTS) {
        var vm = this;

        // data
        vm.recentManuals = [];
        vm.recentManualsLoaded = true;

        // functions
        vm.fetchRecentManuals = fetchRecentManuals;
        vm.viewBVResponse = viewBVResponse;
        vm.getIconClass = StatusStyles.getAdjudicationIconClass;
        vm.getIconTooltipInfo = StatusStyles.getIconTooltipInfo;
        vm.changeState = Utility.shouldChangeState;
        vm.patientManagementStatus = ConfigurationService.getPatientManagementStatus;
        vm.sobCallbackErrorMessage = setSobErrorMessage;
        vm.getUserIconClass = getUserIconClass;

        vm.$onInit = function() {
            vm.fetchRecentManuals();
        };

        function fetchRecentManuals() {
            vm.recentManualsLoaded = false;
            CaseBVService.fetchRecentManualBVList(null, null, ELIGIBILITY_CONSTS.DEFAULT_BV_LIST_LIMIT).then(function(manualBVList) {
                //data are mapped to CaseModal
                if(manualBVList && manualBVList.data && manualBVList.data.benefitVerificationList) {
                    vm.recentManuals = manualBVList.data.benefitVerificationList
                        .map(CaseBVService.mapCase)
                        .sort(function(a, b) {
                            return new Date(a.dateCompleted) < new Date(b.dateCompleted);
                        });
                }
            }).catch(function(err) {
                $log.error('Error getting recent manual bv list:', err);
                vm.recentManuals = [];
            }).finally(function () {
                vm.recentManualsLoaded = true;
            });
        }

        function viewBVResponse(responseId, bvAdjudicationType) {
            var redirectTo = '';
            if(Utility.isMedical(bvAdjudicationType)) {
                redirectTo = ELIGIBILITY_CONSTS.STATE.MED_ELIGIBILITY_RESPONSE;
            } else if(Utility.isRx(bvAdjudicationType)) {
                redirectTo = ELIGIBILITY_CONSTS.STATE.RX_ELIGIBILITY_RESPONSE;
            }
            Eligibility.fetchResponseById(responseId, bvAdjudicationType).then(function(response) {
                var responseId = response.responseId;
                $state.go(redirectTo, {
                    responseId: responseId,
                    status: response.status,
                    showAutoMBV: false,
                    adjudicationType: bvAdjudicationType
                });
            }).catch(function(err) {
                $log.error('error getting coverage', err);
            });
        }

        function setSobErrorMessage(errorMessage) {
            ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, ERROR_MESSAGES.SOB_ERROR.TITLE, errorMessage, GLOBAL_CONSTS.TOAST_TYPES.ERROR));
            $anchorScroll();
        }

        function getUserIconClass(status) {
            return (!Utility.isEmpty(status) && status === false) ? STATUS_CONSTS.STATUS.INACTIVE : STATUS_CONSTS.STATUS.ACTIVE;
        }
    }
})();
