(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Patient')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('patient.add', {
                url: '/add',
                parent: 'patient',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Add Patient'
                },
                views: {
                    'patientContent': {
                        templateUrl: 'components/patient/add/patient-add.html',
                        controller: 'PatientAddController as addPatient'
                    }
                }
            });
    }
})();
