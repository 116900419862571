(function() {
    'use strict';

    var VALUES = {
        SEARCH_TYPE: {
            PATIENT: 'patient',
            PROVIDER: 'provider',
            LOCATION: 'location',
            PATIENT_MODAL: 'patientModal',
            DASHBOARD: 'dashboard',
            USER: 'user',
            BV: 'bv',
            REVERIFICATION: 'reverification',
            PATIENT_LAST_NAME: 'Patient Last Name',
            PRACTICE_PATIENT_ID: 'Practice Patient ID',
            PRACTICE_PATIENT_ID_PLACEHOLDER: 'practice patient ID',
            PAYER_NAME: 'Payer Name',
            PBM_NAME_PLACEHOLDER: 'PBM name',
            PBM_NAME: 'PBM Name',
            DAY: 'day',
            DATE: 'date'
        },
        SEARCH_FACETS: {
            USER_SEARCH_CATEGORY: {
                USER_LAST_NAME: 'User Last Name',
                USER_LAST_NAME_VALUE: 'lastName',
                USER_ROLE: 'User Role',
                USER_ROLE_VALUE: 'userRole'

            },
            FACET_VALUES: {
                LAST_NAME: 'lastName',
                PAYER_NAME: 'payerName',
                PRACTICE_PATIENT_ID: 'patientId',
                PROVIDER_LAST_NAME: 'providerName',
                PROVIDER_NPI_NUMBER: 'npi',
                PRACTICE_PROVIDER_ID: 'practiceProviderId',
                DATE_OF_BIRTH: 'dob',
                PRACTICE_PATIENT_ID_MODAL: 'externalId',
                PBM_NAME: 'pbmName',
                PATIENT_NAME: 'patientName'
            },
            PROVIDER: {
                SEARCH_CATEGORY: {
                    PROVIDER_LAST_NAME: 'Provider Last Name',
                    PROVIDER_NPI_NUMBER: 'Provider NPI Number',
                    PRACTICE_PROVIDER_ID: 'Practice Provider ID'
                },
                PLACE_HOLDER_TEXT: {
                    PROVIDER_LAST_NAME: 'provider last name',
                    PROVIDER_NPI_NUMBER: 'provider NPI number',
                    PRACTICE_PROVIDER_ID: 'practice provider ID'
                },
                VALUE: {
                    PROVIDER_LAST_NAME: 'providerName',
                    PROVIDER_NPI_NUMBER: 'npi',
                    PRACTICE_PROVIDER_ID: 'practiceProviderId'
                }
            },
            LOCATION: {
                SEARCH_CATEGORY: {
                    LOCATION_NAME: 'Location Name',
                    PRACTICE_LOCATION_ID: 'Practice Location ID'
                },
                PLACE_HOLDER_TEXT: {
                    LOCATION_NAME: 'location name',
                    PRACTICE_LOCATION_ID: 'practice location ID'
                },
                VALUE: {
                    LOCATION_NAME: 'locationName',
                    PRACTICE_LOCATION_ID: 'practiceLocationId'
                }
            },
            PATIENT: {
                SEARCH_CATEGORY: {
                    PATIENT_LAST_NAME: 'Patient Last Name',
                    PRACTICE_PATIENT_ID: 'Practice Patient ID',
                    DATE_OF_BIRTH: 'Patient Date of Birth',
                    DOB: 'Date of Birth'
                },
                PLACE_HOLDER_TEXT: {
                    LAST_NAME: 'patient last name',
                    PRACTICE_PATIENT_ID: 'practice patient ID',
                    DATE_OF_BIRTH: 'mm/dd/yyyy'
                }
            }
        },
        SORT_VALUES: {
            PATIENT_NAME: 'patientName',
            PRACTICE_PATIENT_ID: 'externalId'
        },
        DATE_FORMAT: 'YYYY-MM-DD',
        SEARCH_INSTRUCTION: {
            EMPTY_CASE: 'Your search for "@CATEGORY" of "@QUERY" did not return any items.',
        },
        RESULTS: 'results',
        ACTIVE_PATIENTS_ONLY: 'Search results contain active patients only (inactive patients are omitted).',
        FILTER_BV: {
            FILTER_OPTIONS : {
                ALL: {
                    TEXT: 'Show ALL Patients',
                    VALUE: 'ALL'
                },
                ACTIVE: {
                    TEXT: 'Show ACTIVE Patients',
                    VALUE: true
                },
                INACTIVE: {
                    TEXT: 'Show INACTIVE Patients',
                    VALUE: false
                }
            }
        },
        EMPTY_SEARCH_WARNING_MESSAGE: 'Sorry. The search returned no results because there was no input provided. Please enter your search criteria in the search bar above to continue.',
        NO_DATA_FOUND_MESSAGE: 'There are no @ {viewableBvStatus} benefit verifications.',
        NO_DATA_FOUND_REVERIFICATION: 'There are no reverifications.',
        SEARCH_CATEGORY : {
            PARAMETER : 'parameter',
            DATE: 'date',
            EMPTY: 'empty',
            DAY: 'day'
        },
        SEARCH_BY_DAYS_TEXT: 'Search by # of Days',
        SEARCH_BY_PARAMETER_TEXT: 'Search by parameter'
    };

    angular.module('eAccess.ProviderPortal').constant('SEARCH_CONSTS', VALUES);
})();
