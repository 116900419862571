(function() {
    'use strict';

    var VALUES = {
        ERROR_MESSAGES: {
            IS_NOT_UNIQUE: 'A Provider with this Name, NPI, State and Practice Provider ID already exists.',
            PRACTICE_PROVIDER_ID_IS_NOT_UNIQUE: 'A Provider with that Practice Provider ID already exists',

            FIRST_NAME_MIN_LENGTH: 1,
            FIRST_NAME_MAX_LENGTH: 15,
            MIDDLE_NAME_MIN_LENGTH: 1,
            MIDDLE_NAME_MAX_LENGTH: 15,
            LAST_NAME_MIN_LENGTH: 1,
            LAST_NAME_MAX_LENGTH: 15,
            NPI_MIN_LENGTH: 10,
            NPI_MAX_LENGTH: 10,
            // actual Tax ID length is 9, but this allows for the '-' character in mask / validation
            TAX_ID_MIN_LENGTH: 10,
            // actual Tax ID length is 9, but this allows for the '-' character in mask / validation
            TAX_ID_MAX_LENGTH: 10,
            PRACTICE_PROVIDER_ID_MIN_LENGTH: 1,
            PRACTICE_PROVIDER_ID_MAX_LENGTH: 30,
            FIRST_NAME_MIN_LENGTH_MSG: 'First Name must be greater than #minLength characters',
            FIRST_NAME_MAX_LENGTH_MSG: 'First Name can be no more than #maxLength characters',
            FIRST_NAME_REQUIRED: 'First Name is required',
            MIDDLE_NAME_MIN_LENGTH_MSG: 'Middle Name must be greater than #minLength characters',
            MIDDLE_NAME_MAX_LENGTH_MSG: 'Middle Name can be no more than #maxLength characters',
            LAST_NAME_MIN_LENGTH_MSG: 'Last Name must be greater than #minLength characters',
            LAST_NAME_MAX_LENGTH_MSG: 'Last Name can be no more than #maxLength characters',
            LAST_NAME_REQUIRED: 'Last Name is required',
            NPI_MIN_LENGTH_MSG: 'Provider NPI must be exactly #minLength characters',
            NPI_MAX_LENGTH_MSG: 'Provider NPI must be exactly #maxLength characters',
            NPI_REQUIRED: 'Provider NPI is required',
            TAX_ID_MASK_MSG: 'Provider Tax Id must be exactly #mask characters',
            TAX_ID_MIN_LENGTH_MSG: 'Provider Tax Id must be greater than #minLength characters',
            TAX_ID_MAX_LENGTH_MSG: 'Provider Tax Id must be exactly #maxLength characters',
            TAX_ID_REQUIRED: 'Provider Tax Id is required',
            PRACTICE_PROVIDER_ID_MIN_LENGTH_MSG: 'Practice Provider Id must be greater than #minLength characters',
            PRACTICE_PROVIDER_ID_MAX_LENGTH_MSG: 'Practice Provider Id must be exactly #maxLength characters',
            LOAD_PRACTICE_ERROR: 'There was an error loading the practice. Please try again.',
            PROVIDER_REQUIRED:'Provider is required',
            PLEASE_SELECT_PROVIDER: 'Please select a Provider',
            PROVIDER_ID_IS_NOT_UNIQUE_TITLE: 'Provider ID Is Not Unique',
            PROVIDER_ALREADY_EXISTS_TITLE: 'Provider Already Exists',
            PROVIDER_ALREADY_EXISTS_MESSAGE: 'A Provider with this Name, NPI, State, and Practice Provider ID already exists.',
            PROVIDER_NO_LONGER_ACTIVE: 'Provider is no longer active. Please choose another provider',
            NPI_INVALID: 'Invalid Provider NPI'
        },
        FILTER_BY: {
            PROVIDER_ID: 'providerId'
        },
        SUCCESS_MESSAGE: {
            PROVIDER_ADDED_TITLE: 'Provider Successfully Added',
            PROVIDER_ADDED_MESSAGE: 'The provider has been added. You may view the provider by clicking the Provider Name in your provider list.',
            DEACTIVATE_ONE_PROVIDER_TITLE: 'Provider Successfully Deactivated',
            DEACTIVATE_MULTIPLE_PROVIDER_TITLE: 'Providers Successfully Deactivated',
            DEACTIVATE_ONE_PROVIDER_MESSAGE: "The provider was deactivated and removed from your practice's provider list. You may view and/or reactivate the provider by selecting the View Inactive Providers link on the provider list page.",
            DEACTIVATE_MULTIPLE_PROVIDER_MESSAGE: "The selected providers were deactivated and removed from your practice's provider list. You may view and/or reactivate providers by selecting the View Inactive Providers link on the provider list page.",
            REACTIVATE_ONE_PROVIDER_TITLE: 'Provider Successfully Reactivated',
            REACTIVATE_MULTIPLE_PROVIDER_TITLE: 'Providers Successfully Reactivated',
            REACTIVATE_ONE_PROVIDER_MESSAGE: "The provider was reactivated and returned to your practice's providers list. You may view and/or deactivate the provider by selecting the View Active Providers link on the provider list page.",
            REACTIVATE_MULTIPLE_PROVIDER_MESSAGE: "The selected providers were reactivated and returned to your practice's providers list. You may view and/or deactivate providers by selecting the View Active Providers link on the provider list page."
        },
        CONFIRMATION_MESSAGE: {
            DEACTIVATE_ONE_PROVIDER: "You are about to change this provider to inactive.  Inactive providers don't appear in your practice's Active Providers list, or display in active provider search results. Are you sure you wish to continue?",
            DEACTIVATE_MULTIPLE_PROVIDER: "You are about to change #providers providers to inactive.  Inactive providers don't appear in your practice's Active Provider list, or display in active provider search results. Are you sure you wish to continue?",
            REACTIVATE_ONE_PROVIDER: "You are about to change this provider to active. Active providers appear in your practice's Active Providers list, and display in provider search results. Are you sure you wish to continue?",
            REACTIVATE_MULTIPLE_PROVIDER: "You are about to change #providers providers to active.  Active providers appear in your practice's Active Providers list, and display in provider search results. Are you sure you wish to continue?"
        },
        STATUS: {
            INACTIVE_TEXT: 'view inactive providers',
            ACTIVE_TEXT: 'view active providers'
        },
        DEACTIVATE: {
            PROVIDER: 'Deactivate Provider',
            PROVIDERS: 'Deactivate Providers'
        },
        REACTIVATE: {
            PROVIDER: 'Reactivate Provider',
            PROVIDERS: 'Reactivate Providers'
        },
        MODAL: {
            DEACTIVATE:  {
                TITLE: 'Cannot Deactivate All Providers',
                BODY: 'At least one provider must remain active. Please choose at least one provider that will remain active and deselect that checkbox in order to continue.'
            }
        },
        PROVIDER_SORT: {
            PROVIDER_NAME: 'providerName',
            LAST_NAME: 'lastName',
            PROVIDER_NPI_NUMBER: 'npi',
            PRACTICE_PROVIDER_ID: 'practiceProviderId'
        },
        NO_PROVIDER_MESSAGE: 'Your Practice does not have any {STATUS} Providers at this time.',
        REACTIVATE_DEACTIVATE_MODAL_TITLE: 'Provider'
    };

    angular.module('eAccess.ProviderPortal').constant('PROVIDER_CONSTS', VALUES);
})();
