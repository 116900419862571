/**
 * Created by aayush regmi on 4/29/18.
 */
/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.controller:MBVMessageController
 * @author aayush regmi
 * @description
 *
 * This is the controller for viewing the MBV message component.
 **/
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.MBVMessage')
        .controller('MBVMessageController', MBVMessageController);

    MBVMessageController.$inject = ['RxEligibilityService'];

    function MBVMessageController(RxEligibilityService) {
        var vm = this;

        vm.isManualBv = RxEligibilityService.isManualBv;
        vm.isBvStatusCompleted = RxEligibilityService.isBvStatusCompleted;

    }
})();
