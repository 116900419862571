 /**
 * https://github.com/devatwork/angular-cancelable-q/blob/master/src/cancelableq.js
 */

(function() {

    'use strict';

    /**
     * This modules adds a cancel option to promises created by the {ng.$q} service.
     */
    angular.module('eAccess.ProviderPortal.Services.CancelableQ')
    /**
     * @ngdoc service
     * @name cancelableQ
     *
     * @description
     * Adds a cancel method to promises created by the Angular's ng.$q service.
     *
     * **Methods**
     * - 'cancel(reason)' - Cancels the given promise. This will reject the promise with the given reason or 'canceled' if no reason is given.
     */
        .factory('CancelableQ', CancelableQ);

    CancelableQ.$inject = ['$q', '$log', 'ERROR_MESSAGES'];

    function CancelableQ($q, $log, ERROR_MESSAGES) {


        var service = {
            //preserves the last promise
            promiseObj: $q.defer().promise,
            reset: _resetPromiseObject,
            cancel: _cancelCurrentPromise,
            /**
             * Wraps the given promise into a cancelable promise.
             * @param  {Promise}           original The promise which to wrap.
             * @return {CancelablePromise}          Returns the wrapped {CancelablePromise}.
             */
            'wrap': _wrap
        };

        function _wrap(original) {
            // if the original promise already contains a cancel method, do not override it
            if(angular.isFunction(original.cancel)) return original;

            // create a cancel deferred, a combined promise of original an cancelled and a wrapped defered which hides the combined api
            var cancelDeferred = $q.defer(),
                combined = $q.all([original, cancelDeferred.promise]),
                wrapped = $q.defer();

            // resolve the cancelDeferred if the original promise is resolved
            original.then(function(result) {
                cancelDeferred.resolve();
            });

            // hide the $q.all behavior for the consumer
            combined.then(function(results) {
                // resolve the wrapped promise using the result of the original promise
                wrapped.resolve(results[0]);
            }, function(reason) {
                // reject the wrapped promise using the given reason
                wrapped.reject(reason);
            });

            // add the cancel function
            wrapped.promise.cancel = function(reason) {
                reason = reason || 'canceled';
                cancelDeferred.reject(reason);
            };

            // return the wrapped promise
            return wrapped.promise;
        }

        //reseting last promise object and initializing new promise object
        function _resetPromiseObject() {
            service.promiseObj = $q.defer().promise;
        }

        //canceling last promise called.
        function _cancelCurrentPromise() {
            service.promiseObj.cancel(ERROR_MESSAGES.FORCE_CANCEL);
            _resetPromiseObject();
        }

        return service;
    }
})();
