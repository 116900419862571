(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Header')
        .controller('HeaderController', HeaderController);


    HeaderController.$inject = ['$window', '$state', 'Auth', '$scope', 'ConfigurationService', 'UserInfoService', 'Utility', 'BVSelectionService', 'BV_SELECTION_CONSTS', 'WarnOnLeave', 'HeaderService', 'ChangePasswordService', 'BRAND_IMAGE_CONSTS', 'ENV'];

    function HeaderController($window, $state, Auth, $scope, ConfigurationService, UserInfoService, Utility, BVSelectionService, BV_SELECTION_CONSTS, WarnOnLeave, HeaderService, ChangePasswordService, BRAND_IMAGE_CONSTS, ENV) {
        var vm = this;



        // functions
        vm.logout = logout;
        vm.openVerticalNav = openVerticalNav;
        vm.closeVerticalNav = closeVerticalNav;
        vm.accountInfo = UserInfoService;
        vm.bvSelectionService = BVSelectionService;
        vm.redirectBv = redirectBv;
        vm.bvSelectionConsts = BV_SELECTION_CONSTS;
        vm.displayBvSubNav = displayBvSubNav;
        vm.changeState = Utility.shouldChangeState;
        vm.patientManagementStatus = ConfigurationService.getPatientManagementStatus;
        vm.headerService = HeaderService;


        vm.$onInit = function() {

            vm.headerLogo = ENV.API_URL + BRAND_IMAGE_CONSTS.API + BRAND_IMAGE_CONSTS.KEYS.PUBLIC_HEADER_LOGO;
            vm.interiorHeaderLogo = ENV.API_URL + BRAND_IMAGE_CONSTS.API + BRAND_IMAGE_CONSTS.KEYS.INTERIOR_HEADER_LOGO;

            // Fetch and set bvMethod if doesn't exists already
            BVSelectionService.getBvMethod().then(angular.noop)
                .catch(angular.noop)
                .finally(function() {
                    // This is just in case user refreshes the whole page
                    if(Utility.isEmpty(vm.accountInfo.firstName) || vm.accountInfo.firstName === '') {
                        UserInfoService.setCurrentUserInfo();
                    }
                });
            HeaderService.changeLoggedInUserInfo();
        };

        vm.importantSafetyInformation = {
            isiHeaderContent: '',
            isiPageContent: ''
        };

        vm.verticalNav = {
            opened: false
        };

        $scope.$on('$stateChangeSuccess', function() {
            vm.isVisible = true;
            HeaderService.changeLoggedInUserInfo();
        });

        function openVerticalNav() {
            vm.verticalNav.opened = true;
        }

        function closeVerticalNav() {
            vm.verticalNav.opened = false;
        }

        function logout() {
            $state.go('login');
            if(!WarnOnLeave.isFormDirty) {
                Auth.logout();
                ConfigurationService.resetPortalConfig();
                HeaderService.changeLoggedInUserInfo();
            }
        }

        ChangePasswordService.setChangedPasswordCallBack(HeaderService.changeLoggedInUserInfo);

        function redirectBv(bvMethod) {
            $state.go(BVSelectionService.getStateNameFromMethod(bvMethod));
        }

        function displayBvSubNav(bvMethodName) {
            return vm.bvSelectionService.bvMethod === bvMethodName || vm.bvSelectionService.bvMethod === BV_SELECTION_CONSTS.METHOD_NAME.COMBINED;
        }
    }
})();
