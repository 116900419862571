(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Patient')
        .factory('PatientModel', PatientModel);

    PatientModel.$inject = ['$log', 'moment', 'Utility', 'PATIENT_CONSTS', 'STATUS_CONSTS', 'SEARCH_CONSTS'];

    function PatientModel($log, moment, Utility, PATIENT_CONSTS, STATUS_CONSTS, SEARCH_CONSTS) {

        var _defaultPatientSettings = {
            firstName: null, middleName: null, lastName: null, gender: null, practicePatientId: null, portalPatientId: null, dob: null, ssn: null, addressOne: null, addressTwo: null, city: null, state: null, zipCode: null, homeTelephone: null, mobileTelephone: null, payer: null, benefitStatus: null, coverageDetails: null, recentVerificationDate: null, status: null
        };

        function PatientModelInstance(options) {
            angular.extend(this, _defaultPatientSettings, options);

            // Clean up values that don't map exactly
            if (options) {
                this.dob = setDoB(options.dob);
                this.gender = setGender(options.gender);
                this.status = !options.active ? STATUS_CONSTS.STATUS.INACTIVE : STATUS_CONSTS.STATUS.ACTIVE;
                this.practicePatientId = options.externalId;
                this.portalPatientId = options.patientId;
            }
        }

        function setGender(gender) {
            if (Utility.lowerCase(gender) === PATIENT_CONSTS.GENDER.FEMALE.charAt(0)) {
                return PATIENT_CONSTS.GENDER.FEMALE;
            }

            else if (Utility.lowerCase(gender) === PATIENT_CONSTS.GENDER.MALE.charAt(0)) {
                return PATIENT_CONSTS.GENDER.MALE;
            }

            return null;
        }

        function setDoB(dob) {
            if (dob) {
                return new Date(moment(dob, SEARCH_CONSTS.DATE_FORMAT));
            }
            return null;
        }

        return PatientModelInstance;
    }
})();
