/**
 * Created by pramesh on 12/15/2017.
 */
(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('practice.users.view', {
                parent: 'practice',
                url: '/users/{userId}',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Practice User'
                },
                views: {
                    'practiceContent': {
                        templateUrl: 'components/practice/users/view/practice-user-view.html',
                        controller: 'PracticeUserViewController as userView'
                    }
                },
                resolve: {
                    userResponse: ['UserInfoService', '$stateParams', function(UserInfoService, $stateParams) {
                        return UserInfoService.getUserResponseById($stateParams.userId);
                    }]
                }
            });
    }

})();
