/**
 * Created by aayush.regmi on 10/17/2017.
 */

(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PracticeSetup')
        .config(router);

    router.$inject = ['$stateProvider'];

    function router($stateProvider) {
        $stateProvider
            .state('practice-setup.complete', {
                url: '/complete',
                data: {
                    authorities: [],
                    pageTitle: 'Practice Setup Complete'
                },
                views: {
                    'practiceSetupContent': {
                        templateUrl: 'components/practice-setup/complete/complete.html',
                        controller: 'PracticeSetupCompleteController as setupComplete'
                    }
                }
            });
    }
})();
