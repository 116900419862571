/**
 * @ngdoc Component
 * @module eAccess.ProviderPortal.Components.RxEligibilitySplitResponse
 * @name eaCoverageSummary
 * @kind Component
 *
 *
 * Basic Usage
 * <ea-coverage-summary response="passCoverageResponse"></ea-coverage-summary>
 *
 * @author bikashshrestha
 */
(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilitySplitResponse')
        .component('eaCoverageSummary', {
            bindings: {
                policy: '<'
            },
            templateUrl: 'components/eligibility/bv-selection/rx/split-response/coverage-summary/coverage-summary.html',
            controller: 'CoverageSummaryController as coverageSummary'
        });
})();
