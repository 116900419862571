/**
 * Created by pramesh on 10/9/2017.
 */
(function() {
    'use strict';

    var VALUES = {
        ERROR_MESSAGES: {
            GENERIC_ERROR: 'There was an issue creating your user',

            FIRST_NAME_MIN_LENGTH: 1,
            FIRST_NAME_MAX_LENGTH: 15,
            MIDDLE_NAME_MIN_LENGTH: 1,
            MIDDLE_NAME_MAX_LENGTH: 15,
            LAST_NAME_MIN_LENGTH: 1,
            LAST_NAME_MAX_LENGTH: 15,
            USER_NAME_MIN_LENGTH: 5,
            USER_NAME_MAX_LENGTH: 50,
            PHONE_NUMBER_MIN_LENGTH: 10,
            PHONE_NUMBER_MAX_LENGTH: 10,
            FAX_NUMBER_MIN_LENGTH: 10,
            FAX_NUMBER_MAX_LENGTH: 10
        },
        USER_ROLES: {
            ADMIN: 'Admin',
            USER: 'User'
        },
        SUCCESS: {
            TITLE: 'User Successfully Added',
            MESSAGE: 'The user has been added. You may view the user by clicking the User Name in your user list.',
        },
        STATUS_TEXT: {
            VIEW_INACTIVE_USER: 'view inactive users',
            VIEW_ACTIVE_USER: 'view active users'
        },
        ACTION_TEXT: {
            DEACTIVATE_SELECTED: 'Deactivate Selected',
            REACTIVATE_SELECTED: 'Reactivate Selected'
        },
        RESET_PASSWORD: 'Reset Password',
        CHANGE_PASSWORD: 'Change Password',
        STATUS: {
            DEPROVISIONED: 'deprovisioned'
        },
        RESET_PASSWORD_TOAST: {
            HEADER: 'Password Successfully Reset',
            MESSAGE: 'You have successfully reset this user\'s password. Please notify the user and advise them to look for an email with further instructions for completing this process.',
        },
        RESET_PASSWORD_MODAL: {
            HEADER: 'Reset User Password',
            MESSAGE: 'You are about to reset this user\'s password. Are you sure you wish to continue?',
            PRIMARY_BUTTON_LABEL: 'Yes, reset password',
            SECONDARY_BUTTON_LABEL: 'No, cancel'
        },
        CONFIRMATION_MESSAGE: {
            DEACTIVATE_ONE_USER: "You are about to change this user to inactive. Inactive users cannot log into this portal, and don't appear in your practice's Active Users list or display in active user search results. Are you sure you wish to continue?",
            REACTIVATE_ONE_USER: "You are about to change this user to active. Active users appear in your practice's Active Users list, and display in user search results. Are you sure you wish to continue?",
            DEACTIVATE_MULTIPLE_USER: "You are about to change #users users to inactive. Inactive users cannot log into this portal, and don't appear in your practice's Active Users list or display in active user search results. Are you sure you wish to continue?",
            REACTIVATE_MULTIPLE_USER: "You are about to change #users users to active. Active users appear in your practice's Active Users list, and display in user search results. Are you sure you wish to continue?"
        },
        WARNING_MESSAGE: {
            LOGGED_IN_USER: {
                TITLE: 'Wait!',
                MESSAGE: "You cannot deactivate your own User account. Please contact another portal Admin User in your Practice to complete this task for you."
            }
        },
        SUCCESS_MESSAGE: {
            DEACTIVATE: {
                SINGLE: {
                    TITLE: 'User Successfully Deactivated',
                    MESSAGE: "The user was deactivated and removed from your practice's users list. You may view and/or reactivate the user by selecting the View Inactive Users link on the users list page."
                },
                MULTIPLE: {
                    TITLE: 'Users Successfully Deactivated',
                    MESSAGE: "The selected users were deactivated and removed from your practice's users list. You may view and/or reactivate users by selecting the View Inactive Users link on the users list page."
                }
            },
            REACTIVATE: {
                SINGLE: {
                    TITLE: 'User Successfully Reactivated',
                    MESSAGE: "The user was reactivated and returned to your practice's users list. You may view and/or deactivate the user by selecting the View Active Users link on the users list page."
                },
                MULTIPLE: {
                    TITLE: 'Users Successfully Reactivated',
                    MESSAGE: "The selected users were reactivated and returned to your practice's users list. You may view and/or deactivate users by selecting the View Active Users link on the users list page."
                }
            }
        },
        ERROR_MESSAGE: {
            UPDATE_FAILED: {
                MESSAGE: "Your request for the following Users was not processed due to a system error: #usernames. Please try again. If you continue to receive this message, please contact Customer Support."
            }
        },
        USER_ROLE: {
            ADMIN: 'practiceadmin',
            USER: 'user'
        },
        USER_ID_KEY: "user_id",
        FIRST_NAME_KEY: "first_name",
        LAST_NAME_KEY: "last_name",
        USER_SORT: {
            USER_LAST_NAME_AND_USERNAME: 'lastName,userName'
        },
        LOADING_USER_WARNING_MESSAGE: 'Your Practice does not have any @STATUS Users at this time.',
        REACTIVATE_DEACTIVATE_MODAL_TITLE: 'User'
    };

    angular.module('eAccess.ProviderPortal.Constants.User', []).constant('USER_CONSTS', VALUES);
})();
