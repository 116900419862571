/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.controller:ServiceDeliveryController
 * @author Sterling Stokes
 * @requires ELIGIBILITY_CONSTS
 * @description
 *
 * This is the controller for the Service Delivery component.
 **/

(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ServiceDeliveryInformation')
        .controller('ServiceDeliveryController', ServiceDeliveryController);

    ServiceDeliveryController.$inject = ['$log', '$scope', '$rootScope', 'Product', 'DiagnosisCode', 'PlaceOfService', 'PracticeLocationService', 'HELP_TEXT_CONSTS', 'DiagnosisCodeModel', 'ConfigurationService', 'LOCATION_CONSTS', 'Utility', 'DATA_LIMIT_CONSTS'];

    function ServiceDeliveryController($log, $scope, $rootScope, Product, DiagnosisCode, PlaceOfService, PracticeLocationService, HELP_TEXT_CONSTS, DiagnosisCodeModel, ConfigurationService, LOCATION_CONSTS, Utility, DATA_LIMIT_CONSTS) {

        var vm = this;

        vm.selectedProductChanged = selectedProductChanged;
        vm.$onInit = $onInit;
        vm.$onDestroy = $onDestroy;
        vm.clearPrimaryDiagnosisCode = clearPrimaryDiagnosisCode;
        vm.clearSecondaryDiagnosisCode = clearSecondaryDiagnosisCode;
        vm.locationSelectOnChange = locationSelectOnChange;

        vm.icdCodeRegex = '(?=^.{5,9}$)([a-zA-Z]\\d*\\.\\d+)';

        vm.$onChanges = function(bindingChanges) {
            if(bindingChanges.selectedProduct) {
                vm.selectedProduct = bindingChanges.selectedProduct.currentValue;
                vm.selectedProductChanged();
            }

            if(vm.selectedLocation && vm.locations) {
                _isLocationAvailable(vm.selectedLocation.id);
            }
        };

        vm.errorMessages = {
            product: {
                required: 'Product is required'
            },
            primaryDiagnosisCode: {
                required: 'Primary Diagnosis is required'
            },
            placeOfService: {
                required: 'Place of Service is required'
            },
            productAdminCode: {
                required: 'Service Code is required'
            },
            location: {
                required: 'Location is required.',
                locationNotActive: "Chosen location has been deactivated. Please choose another location"
            },
            primaryOtherCode: {
                required: 'Primary Other Code is required',
                pattern: 'Primary Other Code is invalid'
            },
            secondaryOtherCode: {
                required: 'Secondary Other Code is required',
                pattern: 'Secondary Other Code is invalid'
            }
        };

        vm.helpText = {
            practiceLocation: HELP_TEXT_CONSTS.BV_REQUEST.SERVICE_DELIVERY.PRACTICE_LOCATION,
            secondaryDiagnosis: HELP_TEXT_CONSTS.BV_REQUEST.SERVICE_DELIVERY.SECONDARY_DIAGNOSIS,
            primaryDiagnosis: HELP_TEXT_CONSTS.BV_REQUEST.SERVICE_DELIVERY.PRIMARY_DIAGNOSIS,
            placeOfService: HELP_TEXT_CONSTS.BV_REQUEST.SERVICE_DELIVERY.PLACE_OF_SERVICE,
            adminCode:  HELP_TEXT_CONSTS.BV_REQUEST.SERVICE_DELIVERY.ADMIN_CODE
        };

        vm.isRequired = isRequired;

        function $onDestroy() {
            vm.onAdministationChange();
        }
        /**
         * @ngdoc function
         * @name $onInit
         * @methodOf eAccess.ProviderPortal.controller:ServiceDeliveryController
         * @description
         *
         * Initialize ServiceDeliveryController. Gets lists for Service Delivery Information dropdowns.
         **/

        function $onInit() {
            vm.onAdministationChange =  $rootScope.$on('AdministationChange', function(event,data) {
                if(!Utility.isEmpty(vm.serviceDeliveryInformation)) {
                    if(vm.placesOfService.length === 1) {
                        vm.serviceDeliveryInformation.placeOfService = vm.placesOfService[0];
                    } else {
                        if(data.isAio ==='true'){
                            vm.serviceDeliveryInformation.placeOfService = vm.placesOfService.filter(function (x) {
                                return x.id === 11;
                            })[0];
                        }
                        else{
                            vm.serviceDeliveryInformation.placeOfService = vm.placesOfService.filter(function (x) {
                                return x.id === 22;
                            })[0];
                        }
                    }
                }
            });

            // Fetch portal configuration to get bvMethod and call fetch products accordingly
            ConfigurationService.fetchPortalConfiguration().then(
                function(response) {
                    vm.bvMethod = response.portalBVMethod;
                }
            ).catch(function(err) {
                $log.debug(err);
            }).finally(function() {
                getProducts();
                getLocations();
                getDiagnosisCodes();
                getPlacesOfService();
            });


            if(isRerunCheck) {
                selectedProductChanged();
            }
        }

        /**
         * @ngdoc function
         * @name getProducts
         * @methodOf eAccess.ProviderPortal.controller:ServiceDeliveryController
         * @description
         *
         * Get products for the Service Delivery Information product dropdown.
         **/

        function getProducts() {
            vm.loadingProducts = true;
            Product.get(vm.bvMethod)
                .then(function (products) {
                    // filter the unique products by name and remove redundancy
                    var tempArray = [];
                    vm.products = products.filter(function (value) {
                        if(tempArray.indexOf(value.name) === -1){
                            tempArray.push(value.name);
                            return {
                                id: value.id,
                                name: value.name,
                                requiresAdminCode: value.requiresAdminCode,
                                productMethod: value.productMethod,
                                defaultDays: value.defaultDays,
                                defaultQuantity: value.defaultQuantity,
                                ndc: value.ndc
                            };
                        }
                    });
                    // vm.selectedProduct= vm.products[0];
                    // vm.selectedProductChanged();
                })
                .catch(function (err) {
                    $log.error(err);
                    vm.products = [];
                })
                .finally(function () {
                    vm.loadingProducts = false;

                    // Set the selected product if there is one
                    if(vm.selectedProduct) {
                        vm.products.some(function(product) {
                            if(product.id === vm.selectedProduct.id) {
                                vm.selectedProduct = product;
                            }

                            return product === vm.selectedProduct;
                        });
                    }
                });
        }

        /**
         * @ngdoc function
         * @name getLocations
         * @methodOf eAccess.ProviderPortal.controller:ServiceDeliveryController
         * @description
         *
         * Get locations for the Service Delivery Information product dropdown.
         **/

        function getLocations() {
            vm.loadingLocations = true;
            var showActiveLocations = true;
            var fetchCount = false;
            PracticeLocationService.getLocationsInformation(showActiveLocations, DATA_LIMIT_CONSTS.DEFAULT_LIMIT_ALL, DATA_LIMIT_CONSTS.DEFAULT_OFFSET, LOCATION_CONSTS.LOCATION_SORT.LOCATION_NAME, fetchCount)
                .then(function(locations) {
                    vm.locations = locations.responseList;
                    if(vm.selectedLocation && vm.locations) {
                        _isLocationAvailable(vm.selectedLocation.id);
                    }
                })
                .catch(function(err) {
                    $log.error(err);
                    vm.locations = [];
                })
                .finally(function() {
                    vm.loadingLocations = false;

                    // Set the selected product if there is one
                    if(vm.selectedLocation) {
                        vm.locations.some(function(location) {
                            if(location.id === vm.selectedLocation.id) {
                                vm.selectedLocation = location;
                            }

                            return location === vm.selectedLocation;
                        });
                    }
                });
        }

        /**
         * @ngdoc function
         * @name getDiagnosisCodes
         * @methodOf eAccess.ProviderPortal.controller:ServiceDeliveryController
         * @description
         *
         * Get diagnosis codes for the Service Delivery Information diagnosis code dropdown.
         **/
        function getDiagnosisCodes() {
            vm.loadingDiagnosisCodes = true;
            DiagnosisCode.getByProduct(vm.selectedProduct.id)
                .then(function(diagnosisCodes) {
                    vm.diagnosisCodes = diagnosisCodes;
                    vm.serviceDeliveryInformation.primaryDiagnosisCode = diagnosisCodes[0];
                    vm.diagnosisCodes.push(new DiagnosisCodeModel(0, "Other", "Other"));
                })
                .catch(function (err) {
                    $log.error(err);
                    vm.diagnosisCodes = [];
                })
                .finally(function () {
                    vm.loadingDiagnosisCodes = false;
                });
        }

        /**
         * @ngdoc function
         * @name getPlacesOfService
         * @methodOf eAccess.ProviderPortal.controller:ServiceDeliveryController
         * @description
         *
         * Get places of service for the Service Delivery Information place of service dropdown.
         **/

        function getPlacesOfService() {
            vm.loadingPlacesOfService = true;

            PlaceOfService.getByProduct(vm.selectedProduct.id)
                .then(function(placesOfService) {
                    vm.placesOfService = placesOfService;
                })
                .catch(function(err) {
                    $log.error(err);
                    vm.placesOfService = [];
                })
                .finally(function() {
                    vm.loadingPlacesOfService = false;
                });
        }

        function clearPrimaryDiagnosisCode() {
            vm.serviceDeliveryInformation.primaryDiagnosisCode = null;
        }

        function clearSecondaryDiagnosisCode() {
            vm.serviceDeliveryInformation.secondaryDiagnosisCode = null;
        }

        /**
         * @ngdoc function
         * @name selectedProductChanged
         * @methodOf eAccess.ProviderPortal.controller:ServiceDeliveryController
         * @description
         *
         * Kicks off required changes after a new product is selected
         **/
        function selectedProductChanged() {
            // bail if there is no selected product
            if(!vm.selectedProduct) {
                return false;
            }

            getPlacesOfService();
            getDiagnosisCodes();
            vm.changeSelectedProduct = vm.selectedProduct;
            Product.setCurrentProduct(vm.selectedProduct);
        }

        function isRerunCheck() {
            return angular.isDefined(vm.selectedProduct);
        }

        function isRequired() {
            if(angular.isUndefined(vm.ehipaaFormProcessing)) {
                return true;
            }

            // form is not required till ehipaa
            // ehipaa consent form is submitted
            return (vm.ehipaaFormProcessing ? false : true);
        }

        /**
         * function to check if current patient provider is available in provider dropdown list or not
         * if provider is not available in dropdown list, set require error message in form
         */
        function _isLocationAvailable(locationId) {
            if(!Utility.containValueInObjectList(vm.locations, locationId)) {
                vm.parentForm.location.$invalid = true;
                vm.parentForm.location.$error.locationNotActive = true;
                vm.parentForm.location.$viewValue = null;
                vm.selectedLocation = undefined;
            } else {
                delete vm.parentForm.location.$error.locationNotActive;
                vm.parentForm.location.$invalid = false;
            }
        }

        function locationSelectOnChange() {
            // vm.showProviderError = false;
            if(vm.selectedLocation && vm.locations) {
                _isLocationAvailable(vm.selectedLocation.id);
            }
        }
    }

})();
