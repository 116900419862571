/**
 * Created by sstokes on 1/10/17.
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .factory('AdminCode', AdminCode);

    AdminCode.$inject = ['$http', '$timeout', '$log', 'API', 'AdminCodeModel'];

    function AdminCode($http, $timeout, $log, API, AdminCodeModel) {
        var service = {
            getByProduct: getByProduct,
            getPossibleCodes: getPossibleCodes,
            selectCode: selectCode
        };

        var _adminCodeList = [];
        var _selectedCodes = [];

        function getByProduct(productId) {
            return API.get('adminCodes/productId/' + productId).then(function(adminCodesJSON) {
                _adminCodeList = adminCodesJSON.map(function(adminResponseCodes) {
                    return new AdminCodeModel(adminResponseCodes.adminCodeId, adminResponseCodes.description, adminResponseCodes.externalCode);
                });
                return _adminCodeList;
            });
        }

        function selectCode(adminCode) {
            _selectedCodes.push(adminCode);
            _adminCodeList.splice(_adminCodeList.indexOf(adminCode));
        }

        function getPossibleCodes() {
            return angular.copy(_adminCodeList);
        }

        return service;
    }
})();
