/**
 * Created by nikesh on 3/14/2018.
 */
// Used to validate form input with given minimum value
(function() {
    'use strict';

    angular.module('eAccess.ProviderPortal.Directives').directive('eaMinValue', eaMinValue);

    eaMinValue.$inject = ['$log'];

    function eaMinValue() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, elem, attr, ctrl) {
                scope.$watch(attr.minValue, function() {
                    ctrl.$setViewValue(ctrl.$viewValue);
                });
                var minValidator = function(value) {
                    var min = scope.$eval(attr.minValue) || 0;

                    var isEmpty = angular.isUndefined(value) || value === '' || value === null;

                    if(!isEmpty && value < min) {
                        ctrl.$setValidity('minValue', false);
                        return undefined;
                    } else {
                        ctrl.$setValidity('minValue', true);
                        return value;
                    }
                };

                ctrl.$parsers.push(minValidator);
                ctrl.$formatters.push(minValidator);
            }
        };
    }


})();
