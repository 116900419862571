(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PolicyListSummary')
        .controller('PolicyListSummaryController', PolicyListSummaryController);

    PolicyListSummaryController.$inject = ['$log', 'StatusStyles', 'PolicyService', 'Eligibility', 'Utility', 'PatientStyles', 'STYLES_CONSTS', 'STATUS_CONSTS', 'ELIGIBILITY_CONSTS'];

    function PolicyListSummaryController($log, StatusStyles, PolicyService, Eligibility, Utility, PatientStyles, STYLES_CONSTS, STATUS_CONSTS, ELIGIBILITY_CONSTS) {
        var vm = this;

        vm.setActiveStatusClass = StatusStyles.setActiveStatusClass;
        vm.setCoverageIconClass = StatusStyles.setDisplayTextIcon;
        vm.getInsuranceStatus = PolicyService.getInsuranceStatus;
        vm.getPbmOrPayerName = PolicyService.getPbmOrPayerName;
        vm.setInsuranceStatus = setInsuranceStatus;
        vm.setPayerPbmStatusClass = setPayerPbmStatusClass;
        vm.isProductSummaryVisible = isProductSummaryVisible;
        vm.getProductCoverageStatus = getProductCoverageStatus;
        vm.getProductCoverageStatusClass = getProductCoverageStatusClass;
        vm.getProductCoverageIconClass = getProductCoverageIconClass;
        vm.isBvNotFoundMessageVisible = isBvNotFoundMessageVisible;
        vm.noBvMessage = STATUS_CONSTS.NO_BV_MESSAGE;

        vm.$onInit = function() {
            if(!Utility.isEmpty(vm.policies)){
                vm.policies.map(function(policy) {
                    return Eligibility.mapPolicy(policy);
                });
            }
        };

        function setInsuranceStatus(policy) {
            var insuranceStatus = PolicyService.setInsuranceStatus(policy, vm.bvAdjudicationType);

            insuranceStatus = Utility.getValueForPendingManualVerification(vm.bvType, vm.bvStatus, insuranceStatus);

            return insuranceStatus;
        }

        function setPayerPbmStatusClass(policy) {
            if(!Utility.isEmpty(vm.patientStatus) && vm.patientStatus.toLowerCase() !== STATUS_CONSTS.STATUS.ACTIVE) return STYLES_CONSTS.STYLES.DISABLED;
            if(!Utility.isEmpty(policy) && !Utility.isEmpty(policy.plan) && !Utility.isEmpty(policy.plan.status)) {
                if(PolicyService.isRxBvAdjudication(vm.bvAdjudicationType)) {
                    return !Utility.isEmpty(policy.plan.status.insuranceStatus) ? StatusStyles.setActiveStatusClassByValue(policy.plan.status.insuranceStatus) : null;
                }else {
                    return StatusStyles.setActiveStatusClass(policy.plan);
                }
            }
            return null;
        }


        function isProductSummaryVisible(policy) {
            return !Utility.isEmpty(policy) &&
                !Utility.isEmpty(policy.plan) &&
                ( // for Rx we get coverage status in productCoverageStatus and for med we are getting it in coverageStatus
                    (!Utility.isEmpty(policy.plan.status) && !Utility.isEmpty(policy.plan.status.productCoverageStatus)) || // for RX
                    !Utility.isEmpty(policy.plan.coverageStatus) ||  // for MED
                    (!Utility.isEmpty(policy.coverageSummary) &&
                        (!Utility.isEmpty(policy.coverageSummary.mail90 ||
                            !Utility.isEmpty(policy.coverageSummary.retail30 ||
                                !Utility.isEmpty(policy.coverageSummary.retail90))))) // for Split Rx
                ) &&
                !Utility.isEmpty(vm.product.name);
        }

        function getProductCoverageStatus(policy) {
            var productCoverageStatus = '';
            if(!Utility.isEmpty(policy) && !Utility.isEmpty(policy.plan)) {
                if(PolicyService.isRxBvAdjudication(vm.bvAdjudicationType)) {
                    if (Utility.lowerCase(vm.rxType) === ELIGIBILITY_CONSTS.RX_API_TYPES.COMBINED) {
                        productCoverageStatus = !Utility.isEmpty(policy.plan.status) && !Utility.isEmpty(policy.plan.status.productCoverageStatus) ? policy.plan.status.productCoverageStatus : null;
                    } else {
                        productCoverageStatus = _getProductCoverageStatusForSplitResponse(policy);
                    }
                }else {
                    productCoverageStatus = !Utility.isEmpty(policy.plan.coverageStatus) ? policy.plan.coverageStatus : null;
                }
            } else {
                return null;
            }

            // Set product coverage status value to 'Pending Manual Verification' if bvType is Manual, bvStatus is Incomplete and productCoverageStatus value is Undetermined
            productCoverageStatus = Utility.getValueForPendingManualVerification(vm.bvType, vm.bvStatus, productCoverageStatus);

            return productCoverageStatus;
        }

        function getProductCoverageStatusClass(policy) {
            if(!Utility.isEmpty(vm.patientStatus) && vm.patientStatus.toLowerCase() !== STATUS_CONSTS.STATUS.ACTIVE) return STYLES_CONSTS.STYLES.DISABLED;
            var productCoverageStatus = getProductCoverageStatus(policy);
            if(!Utility.isEmpty(productCoverageStatus)) {
                return StatusStyles.setProductCoverageStatus(productCoverageStatus);
            }
            return null; //default
        }

        function getProductCoverageIconClass(policy) {
            if(!Utility.isEmpty(vm.patientStatus) && vm.patientStatus.toLowerCase() !== STATUS_CONSTS.STATUS.ACTIVE) return STYLES_CONSTS.STYLES.DISABLED;
            var productCoverageStatus = getProductCoverageStatus(policy);
            if(!Utility.isEmpty(productCoverageStatus)) {
                return PatientStyles.setCoverageDetailsIcon(productCoverageStatus);
            }
            return null; //default
        }

        function isBvNotFoundMessageVisible(policy) {
            if(!Utility.isEmpty(policy) && !Utility.isEmpty(policy.plan) && !Utility.isEmpty(policy.plan.status)) {
                if(PolicyService.isRxBvAdjudication(vm.bvAdjudicationType)) {
                    return  !Utility.isEmpty(policy.plan.status.insuranceStatus) && !Utility.isEmpty(policy.plan.pbmName);
                }
                return !Utility.isEmpty(policy.payer) && !Utility.isEmpty(policy.payer.name);
            }
            return false;
        }

        function _getProductCoverageStatusForSplitResponse(policy) {
            if(!Utility.isEmpty(policy) && !Utility.isEmpty(policy.coverageSummary)) {
                var retail30CoverageStatus = _getCoverageSummaryCoverageStatus(policy.coverageSummary.retail30);
                var retail90CoverageStatus = _getCoverageSummaryCoverageStatus(policy.coverageSummary.retail90);
                var mail90CoverageStatus = _getCoverageSummaryCoverageStatus(policy.coverageSummary.mail90);

                if(_isCoverageStatusCovered(retail30CoverageStatus) ||
                    _isCoverageStatusCovered(retail90CoverageStatus) ||
                    _isCoverageStatusCovered(mail90CoverageStatus)) {
                    return Utility.firstCharacterUpperCase(STATUS_CONSTS.COVERAGE.COVERED);
                }
                return retail30CoverageStatus || retail90CoverageStatus || mail90CoverageStatus || STATUS_CONSTS.COVERAGE.UNKNOWN;
            }
            return null;
        }

        function _getCoverageSummaryCoverageStatus(summaryObject) {
            if(!Utility.isEmpty(summaryObject)) {
                if(!Utility.isEmpty(summaryObject.coverageStatus)) {
                    return summaryObject.coverageStatus;
                }
            }
            return null;
        }

        function _isCoverageStatusCovered(status) {
            return Utility.lowerCase(status) === STATUS_CONSTS.COVERAGE.COVERED;
        }

    }
})();
