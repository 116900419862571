(function() {
    'use strict';

    var eaMbvForm = {
        bindings: {
            bvResponse: '<',
            bvRequest: '<',
            manualBvSubmitted: '=',
            manualBvSubmittedOpen: '=',
            manualBvError: '=',
            bvMethod: '<',
            requestDate: '=',
            resetAutoMbvToastMessage: '<',
            showMbvToast: '<',
            reloadData: '<'
        },
        templateUrl: 'components/mbv/mbv.html',
        controller: 'MBVFormController as mbvForm'
    };

    angular
        .module('eAccess.ProviderPortal.Components.MBVForm')
        .component('eaMbvForm', eaMbvForm);

})();
