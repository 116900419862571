/**
 * Created by aayush.regmi on 10/16/2017.
 */
/**
 * Created by aayush.regmi on 10/12/2017.
 */
(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Components.HipaaBaa', []);
})();
