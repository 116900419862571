/**
 * Created by aayush.regmi on 9/12/2017.
 */
(function() {
    angular
        .module('eAccess.ProviderPortal.Components.ToastNotification')
        .controller('ToastNotificationController', ToastNotificationController);

    ToastNotificationController.$inject = ['GLOBAL_CONSTS', 'ToastNotificationService', 'Utility'];

    function ToastNotificationController(GLOBAL_CONSTS, ToastNotificationService, Utility) {
        var vm = this;

        vm.possibleToastTypes = ['success', 'error', 'warning'];
        vm.alertBackgroundClasses = ['alert-box__background alert-box__background--success', 'alert-box__background alert-box__background--danger', 'alert-box__background alert-box__background--warning'];
        vm.headerClasses = ['alert-box__icon alert-box__icon--success', 'alert-box__icon alert-box__icon--danger', 'alert-box__icon alert-box__icon--warning'];
        vm.iconClasses = ['fa fa-check-circle', 'fa fa-times-circle', 'fa fa-exclamation-circle'];
        vm.alertBoxTitleClasses = ['alert-box__title alert-box__title--success', 'alert-box__title alert-box__title--danger', 'alert-box__title alert-box__title--warning'];

        // Functions
        vm.getToastNotification = ToastNotificationService.getToastNotification;
        vm.clearToastNotification = ToastNotificationService.clearToastNotification;
        vm.isSuccess = isSuccess;
        vm.getAlertBackground = getAlertBackground;
        vm.getHeaderClass = getHeaderClass;
        vm.getIconClass = getIconClass;
        vm.getAlertBoxTitleClass = getAlertBoxTitleClass;

        function isSuccess() {
            return vm.getToastNotification().toastType === GLOBAL_CONSTS.TOAST_TYPES.SUCCESS;
        }

        function getAlertBackground() {
            return Utility.getClassNameByIndex(getIndexOfToastType(), vm.alertBackgroundClasses);
        }

        function getHeaderClass() {
            return Utility.getClassNameByIndex(getIndexOfToastType(), vm.headerClasses);
        }

        function getIconClass() {
            return Utility.getClassNameByIndex(getIndexOfToastType(), vm.iconClasses);
        }

        function getAlertBoxTitleClass() {
            return Utility.getClassNameByIndex(getIndexOfToastType(), vm.alertBoxTitleClasses);
        }

        function getIndexOfToastType() {
            return vm.possibleToastTypes.indexOf(vm.getToastNotification().toastType);
        }

    }

})();
