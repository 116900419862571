/**
 * Created by aayush.regmi on 11/15/2017.
 */
(function() {
    'use strict';

    var VALUES = {
        USEFUL_LINKS: {
            LABEL: {
                BENEFIT_VERIFICATION: 'Benefit Verification Guide',
                DASHBOARD_GUIDE: 'Dashboard Guide'
            },
            API_ENDPOINT: {
                BENEFIT_VERIFICATION: 'benefitverificationhelp',
                DASHBOARD_GUIDE: 'dashboardguide'
            }
        },
        ONE: '1',
        WEEKLY: 'weekly',
        MONTHLY: 'monthly'
    };

    angular
        .module('eAccess.ProviderPortal.Constants.DashboardConstants', [])
        .constant('DASHBOARD_CONSTS', VALUES);
})();
