(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Services.ConfigurationService', [])
        .factory('ConfigurationService', ConfigurationService);

    ConfigurationService.$inject = ['$http', '$q', 'ENV', '$window', 'API', 'Storage', 'Utility', 'TABLE_NAME_CONSTS', '$location', 'HeaderService', 'IsiService', 'Okta', 'ContentStoreService', 'CONTENT_STORE_CONSTS', '$log'];

    function ConfigurationService($http, $q, ENV, $window, API, Storage, Utility, TABLE_NAME_CONSTS, $location, HeaderService, IsiService, Okta, ContentStoreService, CONTENT_STORE_CONSTS, $log) {
        var service = {
            fetchConfiguration: fetchConfiguration,
            fetchRegistrationConfiguration: fetchRegistrationConfiguration,
            fetchPDFData: fetchPDFData,
            isUserWithUserRole: isUserWithUserRole,
            fetchPortalConfiguration: fetchPortalConfiguration,
            fetchDefaultTablePageSize: fetchDefaultTablePageSize,
            portalConfig: undefined,
            footerConfig: {},
            resetPortalConfig: resetPortalConfig,
            getPatientManagementStatus: getPatientManagementStatus,
            getAutoManualStatus: getAutoManualStatus,
            getOrganizationProperty: getOrganizationProperty
        };

        var _publicConfig = null,
            _patientManagementStatus = true,
            _portalCSS = null,
            _registrationConfig = null,
            _autoManualStatus = true;

        function fetchConfiguration() {
            if(_publicConfig) {
                return $q.when(_publicConfig);
            }

            var deferred = $q.defer();

            // Set the hostname before sending to the backend
            // We have to use $window.location here rather than $location
            // because $location isn't available until post-bootstrapping
            var requestConfig = {
                headers: {
                    'host_name': $window.location.hostname
                }
            };

            $http.get(ENV.API_URL + 'portals/login', requestConfig).then(function(response) {
                response.data.loginCss = _setPortalCSS(response.data);
                response.data.overbrandLinks = [];
                response.data.footerLinks = [];

                if(response.data.overbrandLink1Label && response.data.overbrandLink1Url) {
                    response.data.overbrandLinks.push({
                        label: response.data.overbrandLink1Label,
                        href: response.data.overbrandLink1Url
                    });
                    delete response.data.overbrandLink1Label;
                    delete response.data.overbrandLink1Url;
                }
                if(response.data.overbrandLink2Label && response.data.overbrandLink2Url) {
                    response.data.overbrandLinks.push({
                        label: response.data.overbrandLink2Label,
                        href: response.data.overbrandLink2Url
                    });
                    delete response.data.overbrandLink2Label;
                    delete response.data.overbrandLink2Url;
                }
                if(response.data.overbrandLink3Label && response.data.overbrandLink3Url) {
                    response.data.overbrandLinks.push({
                        label: response.data.overbrandLink3Label,
                        href: response.data.overbrandLink3Url
                    });
                    delete response.data.overbrandLink3Label;
                    delete response.data.overbrandLink3Url;
                }
                if(response.data.footerLink1Label) {
                    response.data.footerLinks.push({
                        label: response.data.footerLink1Label,
                        href: response.data.footerLink1Url
                    });
                    delete response.data.footerLink1Label;
                    delete response.data.footerLink1Url;
                }
                if(response.data.footerLink2Label) {
                    response.data.footerLinks.push({
                        label: response.data.footerLink2Label,
                        href: response.data.footerLink2Url
                    });
                    delete response.data.footerLink2Label;
                    delete response.data.footerLink2Url;
                }
                if(response.data.footerLink3Label) {
                    response.data.footerLinks.push({
                        label: response.data.footerLink3Label,
                        href: response.data.footerLink3Url
                    });
                    delete response.data.footerLink3Label;
                    delete response.data.footerLink3Url;

                }
                if(response.data.footerLink4Label) {
                    response.data.footerLinks.push({
                        label: response.data.footerLink4Label,
                        href: response.data.footerLink4Url
                    });
                    delete response.data.footerLink4Label;
                    delete response.data.footerLink4Url;

                }
                _publicConfig = angular.copy(response.data);
                _initializePublicConfig(_publicConfig);
                return deferred.resolve(response.data);
            }).catch(function(err) {
                return deferred.reject(err);
            });

            return deferred.promise;
        }

        function _setPortalCSS(data) {
            if(!Utility.isEmpty(_portalCSS)) {
                return _portalCSS;
            } else {
                // sometimes data is an empty string, catch that case
                data = angular.isString(data) || angular.isUndefined(data) ? {} : data;
                data.loginCss = data.loginCss !== null && angular.isDefined(data.loginCss) ? _checkCssFile(data.loginCss) : './css/main.css';
                data.loginCss = _ensureFormattingOfPath(data.loginCss);
                return _portalCSS = data.loginCss;
            }
        }

        // Checking if the provided CSS file exists else sets default css.
        function _checkCssFile(cssFile) {
            var link = $location.absUrl();
            var url = link.substr(0, link.indexOf("#/")) + cssFile;
            var request = new XMLHttpRequest();
            request.onreadystatechange = function() {
                if(this.readyState === this.DONE && this.status === 404) {
                    cssFile = './css/main.css';
                }
            };
            request.open("HEAD", url, false);
            request.send();
            return cssFile;
        }

        /*
         * This function is used to ensure that the css path is relative.
         * If the path stored in the db starts with a '/', we will append
         * a '.' in front of it to make it relative.
         */
        function _ensureFormattingOfPath(path) {
            var formattedPath = '',
                firstChar = path.charAt(0);
            if(firstChar !== '.') {
                // check for /path/to/file case
                if(firstChar === '/') {
                    formattedPath = '.' + path;
                }

                // check for path/to/file case
                else {
                    formattedPath = './' + path;
                }
            } else {
                // Properly formatted
                formattedPath = path;
            }

            return formattedPath;
        }

        function fetchRegistrationConfiguration() {
            if(_registrationConfig) {
                return $q.when(_registrationConfig);
            }
            var deferred = $q.defer();
            var requestConfig = {
                headers: {
                    'host_name': $window.location.hostname
                }
            };
            $http.get(ENV.API_URL + 'portals/registration', requestConfig)
                .then(function(response) {
                    _registrationConfig = angular.copy(response.data);
                    return deferred.resolve(response.data);
                })
                .catch(function(err) {
                    return deferred.reject(err);
                });
            return deferred.promise;
        }

        function fetchPDFData(fetchDataFor) {
            var deferred = $q.defer();
            var requestConfig = {
                params: {
                    'host': $window.location.host
                },
                responseType: 'arraybuffer'
            };
            $http.get(ENV.API_URL + 'files/' + fetchDataFor, requestConfig)
                .then(function(response) {
                    return deferred.resolve(response.data);
                })
                .catch(function(err) {
                    return deferred.reject(err);
                });
            return deferred.promise;
        }

        function isUserWithUserRole() {
            var userRoleString = Storage.getSessionItem('user_role');
            return (!Utility.isEmpty(userRoleString) && userRoleString.indexOf('user') !== -1);
        }

        function fetchPortalConfiguration() {

            if(service.portalConfig) {
                return $q.when(service.portalConfig);
            }
            var deferred = $q.defer();

            API.get('portals/portal').then(
                function(response) {
                    //    response.portalBVMethod = "Combined";   //Rx or Medical or Combined
                    service.portalConfig = angular.copy(response);
                    if(!Utility.isEmpty(service.portalConfig.orgPropertiesMap.patientManagementEnabled)) {
                        _patientManagementStatus = service.portalConfig.orgPropertiesMap.patientManagementEnabled === '1';
                        _autoManualStatus = service.portalConfig.orgPropertiesMap.autoSubmitManualBv === '1';
                    }
                    return deferred.resolve(response);
                }
            ).catch(function(err) {
                return deferred.reject(err);
            });
            return deferred.promise;
        }

        function fetchDefaultTablePageSize(tableName) {
            var deferred = $q.defer();
            service.fetchPortalConfiguration().then(function(portalConfig) {
                if(!Utility.isEmpty(portalConfig) && !Utility.isEmpty(portalConfig.orgPropertiesMap)) {
                    var properties = portalConfig.orgPropertiesMap;
                    var limit = !Utility.isEmpty(properties.defaultTablePageSize) ? parseInt(properties.defaultTablePageSize) : null;
                    switch(tableName) {
                        case TABLE_NAME_CONSTS.PROVIDER_TABLE:
                            limit = !Utility.isEmpty(properties.providerListPageSize) ? parseInt(properties.providerListPageSize) : limit;
                            break;
                        case TABLE_NAME_CONSTS.BV_TABLE:
                            limit = !Utility.isEmpty(properties.bvListPageSize) ? parseInt(properties.bvListPageSize) : limit;
                            break;
                        case TABLE_NAME_CONSTS.LOCATION_TABLE:
                            limit = !Utility.isEmpty(properties.locationListPageSize) ? parseInt(properties.locationListPageSize) : limit;
                            break;
                        case TABLE_NAME_CONSTS.USER_TABLE:
                            limit = !Utility.isEmpty(properties.userListPageSize) ? parseInt(properties.userListPageSize) : limit;
                            break;
                        case TABLE_NAME_CONSTS.PATIENT_TABLE:
                            limit = !Utility.isEmpty(properties.patientListPageSize) ? parseInt(properties.patientListPageSize) : limit;
                            break;
                        case TABLE_NAME_CONSTS.REVERIFICATION_TABLE:
                            limit = !Utility.isEmpty(properties.reverificationListPageSize) ? parseInt(properties.reverificationListPageSize) : limit;
                            break;
                    }
                    return deferred.resolve(limit);
                }
            });
            return deferred.promise;
        }

        function resetPortalConfig() {
            service.portalConfig = undefined;
        }

        function getPatientManagementStatus() {
            return _patientManagementStatus;
        }

        function _setFooterContent(publicData) {
            service.footerConfig = {
                footerContent: publicData.footerContent,
                footerCustomerSupport: publicData.footerCustomerSupport,
                footerLinks: publicData.footerLinks
            };
        }

        function _initializePublicConfig(publicData) {
            HeaderService.setHeaderContent(publicData);
            _setFooterContent(publicData);
            ContentStoreService.getContentStoreData(CONTENT_STORE_CONSTS.KEYS.ISI_PAGE_CONTENT).then(function (response) {
                publicData.isiPageContent = response;
                return ContentStoreService.getContentStoreData(CONTENT_STORE_CONSTS.KEYS.ISI_DISCLAIMER);
            }).catch(function(err) {
                $log.error(err);
            }).then(function (disclaimer) {
                publicData.isiDisclaimer = disclaimer;
                IsiService.loadIsiHeaderAndContent(publicData);
            });
            Okta.loadOktaConfiguraton(publicData);
        }

        function getAutoManualStatus() {
            return _autoManualStatus;
        }

        function getOrganizationProperty(key) {
            return service.portalConfig ? service.portalConfig.orgPropertiesMap[key] : null;
        }

        return service;
    }
})();
