/**
 * Created by aayush.regmi on 10/30/2017.
 */

(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.SetPassword')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('set-password', {
                url: '/set-password',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Set Initial Password'
                },
                views: {
                    'content@': {
                        templateUrl: 'components/set-password/set-password.html',
                        controller: 'SetPasswordController as setPassword'
                    }
                }
            });
    }
})();

