/**
 * Created by aayush.regmi on 10/29/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.SetPassword')
        .controller('SetPasswordController', SetPasswordController);


    SetPasswordController.$inject = ['$log', 'SetPasswordService', 'Storage', '$state', 'Auth', 'API', 'ConfigurationService', 'HeaderService', '$location', '$anchorScroll', 'CHANGE_PASSWORD_CONSTS', 'UserSessionEvent', 'SystemNotificationService'];

    function SetPasswordController($log, SetPasswordService, Storage, $state, Auth, API, ConfigurationService, HeaderService, $location, $anchorScroll, CHANGE_PASSWORD_CONSTS, UserSessionEvent, SystemNotificationService) {
        var vm = this;

        vm.password = '';

        vm.confirmPassword = '';

        vm.isPasswordMismatch = false;

        vm.clickedSaveButton = false;

        vm.errorMessage = '';

        vm.savingPassword = false;

        vm.isPasswordMatching = isPasswordMatching;

        vm.savePassword = savePassword;

        vm.resetForm = resetForm;

        vm.resetForm();

        vm.validationError = CHANGE_PASSWORD_CONSTS;


        vm.$onInit = function() {
            _setUsernameDomain();
        };

        function isPasswordMatching() {
            return vm.password === vm.confirmPassword;
        }

        function savePassword() {
            vm.errorMessage = '';
            vm.clickedSaveButton = true;
            vm.isPasswordMismatch = false;
            if(!isPasswordMatching()) {
                if(!vm.password || !vm.confirmPassword) {
                    return false;
                }
                vm.isPasswordMismatch = true;
                vm.setPasswordForm.confirmPassword.$setValidity('mismatch', false);
                $location.hash('input-confirm-pw');
                return false;
            }

            if(!vm.password && !vm.confirmPassword) {
                return false;
            }
            vm.setPasswordForm.confirmPassword.$setValidity('mismatch', true);
            vm.savingPassword = true;

            SetPasswordService.savePassword({registrationGuid: Storage.getSessionItem('guid'), password: vm.password})
                .then(
                    function(response) {
                        _loginUser(response)
                            .then(_userInfo)
                            .then(_fetchPortalConfig)
                            .catch(function (e) {
                                $log.error("Unable login user ", e);
                            }).finally(function() {
                                Storage.clearSessionData('guid');
                                vm.setPasswordForm.$dirty = false;
                            });
                    }
                )
                .catch(function(err) {
                    $log.error("Unable to set password ", err);
                    vm.savingPassword = false;
                    vm.errorMessage = err.message;
                });
        }

        /**
         * function to login user
         */
        function _loginUser(response) {
            var usernameWithDomain = response.userName + vm.usernameDomain;
            return Auth.login({username: usernameWithDomain, password: vm.password});
        }

        /**
         * function to get login user information
         */
        function _userInfo() {
            return API.get('users/user');
        }

        /**
         * function to get portal configuration
         * if practice is setup then redirect to dashboard
         * else redirect to practice setup window
         */
        function _fetchPortalConfig(userInfo) {
            ConfigurationService.fetchPortalConfiguration().then(function(portalConfigResponse) {
                UserSessionEvent.initialize();
                UserSessionEvent.saveSessionRefreshConfig(portalConfigResponse.orgPropertiesMap.refreshBeforeTimeout);
                vm.savingPassword = false;
                if(userInfo.practice) {
                    HeaderService.changeLoggedInUserInfo();
                    SystemNotificationService.showPortalNotification = true;
                    $state.go('dashboard');
                } else {
                    Storage.saveToSession('isPracticeSetup', true);
                    $state.go('practice-setup.welcome');
                }
                $anchorScroll();
            });
        }

        function resetForm() {
            vm.password = '';
            vm.confirmPassword = '';
        }

        function _setUsernameDomain() {
            ConfigurationService.fetchConfiguration().then(function(publicData) {
                vm.usernameDomain = publicData.usernameDomain;
                $log.debug('header publicData', publicData);
            }).catch(function(err) {
                $log.debug('failed to extract username domain.');
            });
        }

    }
})();

