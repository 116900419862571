/**
 * @ngdoc Controller
 * @module eAccess.ProviderPortal.Components.RxEligibilityResponse
 * @name RestrictionResponseController
 * @kind Controller
 *
 * @description
 * Controller for the restriction section
 * @author girianish
 */

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .controller('RestrictionResponseController', RestrictionResponse);

    RestrictionResponse.$inject = ['STATUS_CONSTS', 'Utility', 'STYLES_CONSTS'];

    function RestrictionResponse(STATUS_CONSTS, Utility, STYLES_CONSTS) {
        var vm = this;

        vm.getStatusClass = getStatusClass;

        function getStatusClass(status) {
            switch(Utility.lowerCase(status)) {
                case STATUS_CONSTS.STATUS.UNDETERMINED:
                case STATUS_CONSTS.STATUS.ON_FORMULARY:
                    return STYLES_CONSTS.STYLES.WARNING_MESSAGE;
                case STATUS_CONSTS.STATUS.REQUIRED:
                case STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.NOT_COVERED:
                    return STYLES_CONSTS.STYLES.ERROR_MESSAGE;
                case STATUS_CONSTS.STATUS.NOT_APPLICABLE:
                case STATUS_CONSTS.STATUS.NOT_REQUIRED:
                default:
                    return;
            }
        }
    }

})()
;
