(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Payer')
        .factory('Payer', Payer);

    Payer.$inject = [];

    function Payer() {

        var _defaultPayerSettings = {
            id: null, name: null, clientId: null, clientName: null, organizationPayerId: null
        };

        function PayerInstance(options) {
            angular.extend(this, _defaultPayerSettings, options);

            // Clean up values that don't map exactly
            if (options) {
                this.id = options.payer.payerId;
                this.name = options.payer.payerName;
                this.clientId = options.crosswalkId;
                this.clientName = options.clientPayerName;
            }
        }

        return PayerInstance;
    }
})();
