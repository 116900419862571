(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components')
        .component('eaProviderInformation', {
            templateUrl: 'components/provider/provider-information.html',
            controller: 'ProviderPracticeInformationController as provider',
            bindings: {
                parentForm: '=',
                provider: '=',
                showProviderDropdown: '=',
                providerExists: '=',
                providerExistsMessage: '<',
                showProviderError: '<',
                patientProvider: '<',
                ehipaaFormProcessing: '=',
            }
        });

})();
