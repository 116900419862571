(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('practice.providers.add', {
                parent: 'practice',
                url: '/providers/add',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Add Practice Providers'
                },
                views: {
                    'practiceContent': {
                        templateUrl: 'components/practice/providers/add/practice-provider-add.html',
                        controller: 'PracticeProviderAddController as providerAdd'
                    }
                }
            });
    }

})();
