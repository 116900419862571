/**
 * Created by mcaputo on 1/27/17.
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Directives', []);
})();
