/**
 * Created by mcaputo on 1/13/17.
 */

(function() {
    'use strict';

    var VALUES = {
        SSN_MAX_LENGTH: 4,
        SSN_MIN_LENGTH: 4,
        PATIENT_ID_MIN_LENGTH: 2,
        PATIENT_ID_MAX_LENGTH: 30,
        PATIENT_FIRST_NAME_MIN_LENGTH: 1,
        PATIENT_FIRST_NAME_MAX_LENGTH: 15,
        PATIENT_MIDDLE_NAME_MIN_LENGTH: 1,
        PATIENT_MIDDLE_NAME_MAX_LENGTH: 15,
        PATIENT_LAST_NAME_MIN_LENGTH: 1,
        PATIENT_LAST_NAME_MAX_LENGTH: 15,
        PROVIDER_ID_MIN_LENGTH: 1,
        PROVIDER_ID_MAX_LENGTH: 30,
        PROVIDER_NPI_LENGTH: 10,
        PROVIDER_FIRST_NAME_MIN_LENGTH: 1,
        PROVIDER_FIRST_NAME_MAX_LENGTH: 15,
        PROVIDER_MIDDLE_NAME_MIN_LENGTH: 1,
        PROVIDER_MIDDLE_NAME_MAX_LENGTH: 15,
        PROVIDER_LAST_NAME_MIN_LENGTH: 1,
        PROVIDER_LAST_NAME_MAX_LENGTH: 15,
        PROVIDER_TAX_ID_MIN_LENGTH: 10,
        PROVIDER_TAX_ID_MAX_LENGTH: 10,
        MAX_POLICIES_ALLOWED: 3,
        VALID_ZIP_LENGTHS: [0, 5],
        FIRST_NAME_MIN_LENGTH: 1,
        FIRST_NAME_MAX_LENGTH: 15,
        LAST_NAME_MIN_LENGTH: 1,
        LAST_NAME_MAX_LENGTH: 15,
        INITIATE_PA_BUTTON_TEXT: 'Initiate PA',
        COVERAGE_ERROR_TEXT: "This product is not covered under the patient's medical plan.",
        REQUEST_DETAILS_TOOLTIP_TEXT: 'The information in this section reflects the information that was entered on the Check Eligibility Request form for this response.',
        CONS_YES: 'Yes',
        CONS_NO: 'No',
        CONS_UNDETERMINED: 'Undetermined',
        CONS_N_A: 'N/A',
        CONS_REQUIRED: 'Required',
        CONS_NOT_REQUIRED: 'Not Required',
        CONS_AVAILABLE: 'Available',
        CONS_NOT_AVAILABLE: 'Not Available',
        CONS_INELIGIBLE: 'Ineligible',
        CONS_ACITVE: 'Active',
        CONS_IN_ACITVE: 'Inactive',
        CONS_APPLIES: 'Applies',
        CONS_DOES_NOT_APPLIES: 'Does not apply',
        AMOUNT_ZERO: '$0.00',
        MANUAL_BV_MESSAGE: 'This response contains manually verified benefits',
        DEFAULT_BV_LIST_LIMIT: 10,
        PA_LINK_LABEL: 'PA Form Available Here',
        PA_FORM_LABEL: 'Form Available',
        FLAG: {
            NEW: 'New!'
        },
        BV_TYPE: {
            MANUAL: 'manual',
            ELECTRONIC: 'electronic',
            MEDICAL: 'Medical',
            RX: 'Rx'
        },
        MAX_NEW_MBV_DATE: 7,
        ADMIN_OTHER_CODE: 'adminOtherCode_',
        ADMIN_CODE: 'adminCode_',

        BV_SORT: {
            DATE_COMPLETED: 'dateCompleted',
            RESPONSE_ID: 'responseId',
            PATIENT_NAME: 'patientName',
            PROVIDER_NAME: 'providerName',
            PRACTICE_PATIENT_ID: 'externalId',
            PAYER_NAME: 'payerName',
            PBM_NAME: 'pbmName',
            RECENT_BV: 'daysSinceLastBV'
        },
        STATE: {
            RX_DATA_CONFLICT: 'rxDataConflict',
            RX_ELIGIBILITY_RESPONSE: 'RxEligibilityResponse',
            MED_ELIGIBILITY_RESPONSE: 'eligibilityResponse',
            RX_ELIGIBILITY_SPLIT_RESPONSE: 'RxEligibilitySplitResponse'
        },
        REQUEST_DETAIL_SECTION_ID: 'request-details',
        MANUAL_BV_FORM_SECTION_ID: 'manual-bv',
        POLICY_COVERAGE_SECTION_ID: 'policy-coverage-section',
        MBV_ERROR_MESSAGE: 'A system error has occurred, and your request has not been submitted. Try clicking the Reverify Benefits link to resubmit your request. If you continue to receive this message, please contact Customer Support.',
        MBV_SUCCESS_MESSAGE: 'A manual benefit verification request has been submitted and is currently in progress. We will notify you when your response is ready for review - usually within one to two business days.',
        MBV_ERROR_HEADER_MESSAGE: 'Manual Benefit Verification Request Not Sent',
        MBV_SUCCESS_HEADER_MESSAGE: 'Manual Benefit Verification Request Successfully Sent',
        MBV_SUCCESS_BODY_MESSAGE: 'A manual benefit verification request has been submitted and is currently in progress. We will notify you when your response is ready for review - usually within 24-48 hours.',

        ELIGIBILITY_RESPONSE: 'eligibility-response',
        COPAY_COINSURANCE: {
            UNKNOWN: "unknown",
            N_A: "N/A",
            COPAY_TEMPLATE: "@ {COPAY} (min: @ {MIN_COPAY} | max: @ {MAX_COPAY})",
            DAY_SUPPLY_TEMPLATE: "per @ {DAY_SUPPLY} days supply"
        },
        POLICY_RANK: {
            PRIMARY: 'primary',
            SECONDARY: 'secondary',
            TERTIARY: 'tertiary'
        },
        PA_FORM_MESSAGE: {
            ERROR: {
                HEADER: 'Unable to Render Form',
                MESSAGE: 'An error occurred while rendering your form. Please try clicking the Form Available button again and if you continue to receive this message, please contact Customer Support.'
            }
        },
        STEP_THERAPY_DRUG_LIST_SORT_PROPERTY: 'order',
        AUTO_MED_MBV_RESPONSE: {
            SUCCESS: {
                TOAST: {
                    HEADER: 'Manual Benefit Verification Request Successfully Sent',
                    MESSAGE: 'A manual benefit verification request has been submitted and is currently in progress. We will notify you when your response is ready for review - usually within one to two business days.',
                },
                RESPONSE_MESSAGE: 'We are unable to deliver a complete coverage response for this patient. A manual benefit verification request was automatically submitted for this response on _responseDate_ and is currently in progress. We will notify you when your response is ready for review – usually within one to two business days.'
            },
            ERROR: {
                TOAST: {
                    HEADER: 'Manual Benefit Verification Request Not Sent',
                },
                WARNING_CODE: '107'
            }
        },
        AUTO_RX_MBV_RESPONSE: {
            SUCCESS: {
                TOAST: {
                    HEADER: 'Manual Benefit Verification Request Successfully Sent',
                    MESSAGE: 'A manual benefit verification request has been submitted and is currently in progress. We will notify you when your response is ready for review - usually within one to two business days.'
                },
                RESPONSE_MESSAGE: 'We are unable to deliver a complete coverage response for this patient. A manual benefit verification request was automatically submitted for this response on __response_date__ and is currently in progress. We will notify you when your response is ready for review – usually within one to two business days.',
                RESPONSE_MESSAGE_VERIFY: 'If you would like to verify medical benefits for this patient, click the Verify Med button.'
            },
            ERROR: {
                TOAST: {
                    HEADER: 'Manual Benefit Verification Request Not Sent',
                    MESSAGE: 'A system error has occurred, and we are unable to deliver a complete coverage response or submit a manual benefit verification request on your behalf. Try verifying this patient\'s benefits again. If you continue to receive this message, please contact Customer Support.'
                },
                WARNING_CODE: '662'
            }
        },
        POSSIBLE_POLICY_RANKS: ['Primary', 'Secondary', 'Tertiary'],
        DIAGNOSIS_INFORMATION: {
            PRIMARY: 'primary',
            SECONDARY: 'secondary'
        },
        VERIFY_BV : {
            ERROR: {
                TITLE: {
                    GENERIC_TITLE: 'Electronic Benefit Verification Error',
                },
                MESSAGE: {
                    INACTIVE_PATIENT: 'We are unable to check this patient\'s coverage because this patient has been deactivated. Please go to your Inactive Patient List and reactivate the patient. If you need assistance, please contact Customer Support.',
                    INVALID_DATA: 'SHINGRIX is not covered by your plan. For further assistance in confirming individual patient coverage please contact the GSK Vaccines Reimbursement Support Center at 1-855-636-8291, or find information online at: https://gskpro.com/enus/therapyareas/vaccines/coding/resources'
                },
                GENERIC_ERROR_MESSAGE: 'We are unable to find Medical Benefit Coverage or Pharmacy Benefit Coverage for this patient. If you feel this is in error, you may initiate another verification',
                SELECT_PAYER_RERUN_MED: 'No coverage found for Vaccine Benefit Verification. Please select Payer and re-run Medical Benefit Verification to find coverage.',
                TOAST: {
                    TYPE: ''
                }
            }
        },
        REVERIFICATION: {
            DAY_AGO: 'day ago',
            DAYS_AGO: 'days ago',
            LINK_TO_SEARCH: {
                BY_PARAMETER: 'Search by Parameter',
                BY_DAYS: 'Search by # of Days'
            }
        },
        RX_API_TYPES: {
            COMBINED: 'combined',
            SPLIT: 'split'
        },
        PLAN_COVERAGE_MESSAGE: 'This information represents the patient’s plan benefits and could differ from coverage as it is applied when a prescription is filled.',
        RX_PLAN_COVERAGE_SUB_SECTION_TITLE: {
            "M": "Mail Order Pharmacy",
            "R": "Retail Pharmacy",
            "S": "Specialty Pharmacy",
            "L": "Long-term Care"
        },
        COVERAGE_PHASE_MESSAGE_PATTERN: new RegExp('^(This patient is enrolled in a Low-Income Subsidy Level [1-4] plan)$'),
        SECTION_TITLE: {
            COVERAGE_SUMMARY: "COVERAGE SUMMARY",
            PHARMACY_COVERAGE_SUMMARY: "PHARMACY COVERAGE SUMMARY"
        },
        NOTE: 'NOTE: Results are for this dose only',
        ADDITIONAL_NOTE: 'The GSK VBV provides general reimbursement information only and does not guarantee reimbursement. While the website provides the best information based on our current knowledge, coverage and reimbursement information is subject to change by the payer without notice. Current requirements and policies should be verified with the payer. VBV provides in-network benefits only. It is the provider\'s responsibility to understand their network status.'
    };

    angular.module('eAccess.ProviderPortal').constant('ELIGIBILITY_CONSTS', VALUES);
})();
