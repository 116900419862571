/**
 * Created by aayush.regmi on 10/4/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ISIContent')
        .component('eaIsi', {
            templateUrl: 'components/isi-content/isi-content.html',
            controller: 'ISIContentController as isi'
        });
})();
