(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.SOB')
        .factory('SOBService', SOBService);

    SOBService.$inject = ['$log', 'API'];

    function SOBService($log, API) {
        var service = {
            getSobFile: getSobFile
        };

        function getSobFile(mbvId, param) {
            return API.get('sob/generate/' + mbvId, param);
        }

        return service;
    }

})();
