/**
 * Created by nikesh on 10/25/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.LinkExpired')
        .factory('LinkExpiredService', LinkExpiredService);

    LinkExpiredService.$inject = ['$log', 'API', 'Storage'];

    function LinkExpiredService($log, API, Storage) {

        var service = {
            fetchCustomerSupportInfo: fetchCustomerSupportInfo,
            resendLink: resendLink
        };

        function fetchCustomerSupportInfo() {
            return API.get('portals/login').then(
                function(response) {
                    return response.customerSupport;
                }
            );
        }

        function resendLink() {
            var guid = {
                "registrationGUID" : Storage.getSessionItem('guid') 
            };
            return API
                .post('users/resend', guid)
                .then(
                    function(response) {
                        if (response.messageText === 'Registration Code has been Resend') {
                            return true;
                        } else {
                            return false;
                        }
                    });
        }

        return service;
    }
})();
