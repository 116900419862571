/**
 * Created by mcaputo on 1/25/17.
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('eligibilityResponse', {
                url: '/eligibility/{responseId}?{adjudicationType}',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Eligibility Coverage Response'
                },
                params: {
                    'responseId': '',
                    'status': '',
                    'bvType': '',
                    'showAutoMBV': true,
                    'adjudicationType': null
                },
                views: {
                    'content@': {
                        templateUrl: 'components/eligibility/bv-selection/med/response/eligibility-response.html',
                        controller: 'EligibilityResponseController as eligibility'
                    },
                    // Cached toast template file, this is necessary to display toast after browser offline
                    // http://cmikavac.net/2015/12/01/pre-caching-html-templates-with-angularjs-ui-router-directive/
                    cachedToastTemplate: {
                        templateUrl: 'components/toast-notification/toast-notification.html'
                    }
                },
                resolve: {
                    eligibilityResponse: ['Eligibility', '$stateParams', 'Utility', function(Eligibility, $stateParams, Utility) {
                        return Utility.getById.call(Eligibility.getResponseById($stateParams.responseId, $stateParams.adjudicationType), 'bvHistory');
                    }]
                }
            });

    }

})();
