/**
 * @ngdoc Controller
 * @module eAccess.ProviderPortal.Components.RxEligibilityResponse
 * @name PolicyResponseHeaderController
 * @kind Controller
 *
 * @description
 * Controller for the policy list header
 * @author girianish
 */

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .controller('RxPolicyResponseHeaderController', RxPolicyResponseHeader);

    RxPolicyResponseHeader.$inject = ['StatusStyles', 'RxPolicyResponseHeaderService', 'Eligibility', 'Utility', 'PolicyService', 'STATUS_CONSTS', 'ELIGIBILITY_CONSTS', 'RxEligibilityService'];

    function RxPolicyResponseHeader(StatusStyles, RxPolicyResponseHeaderService, Eligibility, Utility, PolicyService, STATUS_CONSTS, ELIGIBILITY_CONSTS, RxEligibilityService) {
        var vm = this;

        // data
        vm.displayOnScreenInstruction = true;
        vm.insuranceStatus = null;
        vm.toolTip = RxPolicyResponseHeaderService.getPolicyResponseHeaderToolTip();

        //function
        vm.setProductCoverageClass = RxPolicyResponseHeaderService.setProductCoverageClass;
        vm.setFormularyTextClass = StatusStyles.setFormularyTextClass;
        vm.pharmacyDetailsDisplayable = Eligibility.pharmacyDetailsDisplayable;
        vm.getAddress = Utility.getAddress;
        vm.displayTransactionMessage = RxEligibilityService.displayTransactionMessage;
        vm.gotoContent = RxEligibilityService.gotoContent;
        vm.setActiveStatusClass = StatusStyles.setActiveStatusClassByValue;
        vm.getInsuranceStatus = PolicyService.getInsuranceStatus;
        vm.isProductEligible = isProductEligible;
        vm.isDisplayRestrictionApplyButton = isDisplayRestrictionApplyButton;
        vm.focusOnCostShareSection = focusOnCostShareSection;
        vm.setTransactionMessageClass = StatusStyles.setTransactionMessageClass;
        vm.setTransactionIconClass = StatusStyles.setTransactionIconClass;
        vm.disclaimerContent = '';
        vm.manualBVCompletedMessage = ELIGIBILITY_CONSTS.MANUAL_BV_MESSAGE;
        vm.getTrimmedString = Utility.getTrimmedString;

        vm.$onInit = function (){
            // Prepare disclaimer content with response date.
            vm.disclaimerContent= RxEligibilityService.getFormattedDisclaimerContent(vm.responseDate);
        };

        vm.$onChanges = function () {
            vm.bvStatus = vm.bvIncompleteStatus ? STATUS_CONSTS.STATUS.INCOMPLETE : STATUS_CONSTS.STATUS.COMPLETE;
            vm.bvType = vm.bvStatus === STATUS_CONSTS.STATUS.INCOMPLETE ? ELIGIBILITY_CONSTS.BV_TYPE.MANUAL : vm.bvType;
        };

        function isProductEligible(status) {
            if(Utility.isEmpty(status) || Utility.isEmpty(status.productCoverageStatus)) return false;
            var productStatus = Utility.lowerCase(status.productCoverageStatus);
            return productStatus !== STATUS_CONSTS.STATUS.UNDETERMINED && productStatus !== STATUS_CONSTS.COVERAGE.NOT_COVERED;
        }

        function isDisplayRestrictionApplyButton(policy) {
            if(!Utility.isEmpty(policy)) {
                var priorAuthRequired = !Utility.isEmpty(policy.plan) && !Utility.isEmpty(policy.plan.status) && !Utility.isEmpty(policy.plan.status.priorAuthorizationStatus) && policy.plan.status.priorAuthorizationStatus.toLowerCase() === STATUS_CONSTS.STATUS.REQUIRED;
                return priorAuthRequired || (!Utility.isEmpty(policy.drugCoverageLimitationMessage) && policy.drugCoverageLimitationMessage.length);
            }

            return false;
        }

        function focusOnCostShareSection() {
            // Focus the first drawer (index 0)
            vm.selectedPolicyCoverageDrawer = 0;

            vm.gotoContent(ELIGIBILITY_CONSTS.POLICY_COVERAGE_SECTION_ID);
        }
    }
})();
