/**
 * Created by aayush.regmi on 9/12/2017.
 */
(function() {
    'use strict';

    var eaToastNotificationComponent = {
        templateUrl: 'components/toast-notification/toast-notification.html',
        controller: 'ToastNotificationController as toastNotification'
    };

    angular
        .module('eAccess.ProviderPortal.Components.ToastNotification')
        .component('eaToastNotificationComponent', eaToastNotificationComponent);
})();
