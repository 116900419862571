/**
 * Created by mcaputo on 1/10/17.
 */

(function() {
    'use strict';

    angular.module('eAccess.ProviderPortal.Directives').directive('eaLimitedChars', eaLimitedChars);

    eaLimitedChars.$inject = ['$log'];

    // This directive limits the allowed characters in an input field.
    // If an invalid character is found, this directive replaces it immediately
    // with white space.
    //
    // Default allowed characters:
    // a-z
    // A-Z
    // \s
    //
    // Any other allowed special characters must be passed to the directive using
    // an array. ie - ['-','_','.']
    function eaLimitedChars($log) {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function(scope, element, attrs, modelCtrl) {
                var allowedCharsArr = angular.fromJson(attrs.allowedchars);
                modelCtrl.$parsers.push(function(inputValue) {
                    if (inputValue === null) {
                        return '';
                    }
                    var digits = (attrs.restrictDigits) ? '' : '0-9';
                    var validCharRegexPrefix = '[^a-zA-Z' + digits + '\s';
                    var validCharRegexSuffix = ']';
                    var userDefinedRegex = '';
                    angular.forEach(allowedCharsArr, function(char) {
                        userDefinedRegex += '\\' + char;
                    });
                    var validCharsRegex = new RegExp(validCharRegexPrefix + userDefinedRegex + validCharRegexSuffix, 'gi');
                    var cleanInputValue = inputValue.replace(validCharsRegex, '');
                    if (cleanInputValue !== inputValue) {
                        modelCtrl.$setViewValue(cleanInputValue);
                        modelCtrl.$render();
                    }
                    return cleanInputValue;
                });
            }
        };
    }


})();
