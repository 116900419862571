/**
 * Created by nikesh on 11/2/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.AddLocation')
        .component('eaAddLocation', {
            templateUrl: 'components/add-location/add-location.html',
            controller: 'AddLocationController as addLocation',
            bindings: {
                parentForm: '=',
                location: '=',
                nextCallback: '=',
                previousCallback: '=',
                genericErrorMessage: '<',
                savingPractice: '<',
                btnText: '<'
            }
        });

})();
