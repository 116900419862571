(function () {
    'use strict';

    var VALUES = {
        PAGE_TITLE: 'YOUR PASSWORD EXPIRED',
        ERROR_MESSAGE: 'Your password has expired, and you must set a new one.',
        MESSAGE: 'We have sent you an email with instructions for setting your password. If you do not see the email in your inbox in a few minutes, check your junk or spam mailbox.',
        EMAIL_INSTRUCTION : ' If you would like us to resend the email,',
        REQUEST_STATUS: {
            PASSWORD_EXPIRED: 'PASSWORD_EXPIRED'
        },
        EXPIRING_PASSWORD: {
            CHANGE_PASSWORD_BUTTON: 'Yes, change my password',
            CANCEL_CHANGE_PASSWORD_BUTTON: 'No, not now'
        },
        NOTIFY_BEFORE_DAYS: 3,
        DISPLAY_TEXT: {
            PASSWORD_EXPIRING_TEXT: 'Your password will expire on __password_expiry_date__. Would you like to change it now?',
            PASSWORD_EXPIRING_SOON: 'Password Expiring Soon'
        }

    };

    angular.module('eAccess.ProviderPortal')
        .constant('PASSWORD_EXPIRED_CONSTS', VALUES);
})();
