/**
 * Created by mcaputo on 2/2/17.
 */

(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Components.PolicyCoverageList', [
            'eAccess.ProviderPortal.Components.Modals',
            'eAccess.ProviderPortal.Services.StatusStyles',
            'eAccess.ProviderPortal.Services.ebvResponseMapper'
        ]);
})();
