/**
 * Created by nikesh on 10/19/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Register')
        .factory('RegistrationCodeService', RegistrationCodeService);

    RegistrationCodeService.$inject = ['$log', 'API', '$q', 'STATUS_CONSTS', 'ConfigurationService'];

    function RegistrationCodeService($log, API, $q, STATUS_CONSTS, ConfigurationService) {

        var service = {

            validateRegistrationCode: validateRegistrationCode,
            fetchCustomerSupportInfo: fetchCustomerSupportInfo
        };

        return service;

        function validateRegistrationCode(regCode) {
            var deferred = $q.defer();

            API.get('portals/registration/' + regCode).then(
                function(response) {
                    if(response.messageText === STATUS_CONSTS.SUCCESS) {
                        deferred.resolve(true);
                    } else {
                        deferred.resolve(false);
                    }
                }
            ).catch(function(err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function fetchCustomerSupportInfo() {
            return ConfigurationService.fetchRegistrationConfiguration().then(
                function(response) {
                    return response.customerSupport;
                }
            );
        }
    }

})();
