/**
 * Created by nikesh on 12/14/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .controller('PracticeUserAddController', PracticeUserAddController);

    PracticeUserAddController.$inject = ['$log', 'PracticeView'];

    function PracticeUserAddController($log, PracticeView) {
        var vm = this;

        // data
        vm.user = {};


        init();

        // functions
        function init() {
            $log.debug('init');
            PracticeView.configure({
                title: 'Users | Add User',
                tabIndex: 3
            });
        }

    }
})();
