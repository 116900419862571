/**
 * @ngdoc service
 * @name eAccess.ProviderPortal.Components.Practice.service:ProviderService
 * @author Sterling Stokes
 * @requires $http
 * @requires $q
 * @requires $log
 * @requires ProviderModel
 * @requires API
 * @description
 *
 * This is the service for provider-specific API calls.
 **/

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components')
        .factory('ProviderService', ProviderService);

    ProviderService.$inject = ['$q', '$log', 'API', 'ProviderModel', 'PROVIDER_CONSTS', 'Utility', 'DATA_LIMIT_CONSTS'];

    function ProviderService($q, $log, API, ProviderModel, PROVIDER_CONSTS, Utility, DATA_LIMIT_CONSTS) {

        var service = {
            addProvider: addProvider,
            getProvidersByStatus: getProvidersByStatus,
            updateProviderStatus: updateProviderStatus,
            getProviderByProviderId: getProviderByProviderId,
            updateProvider: updateProvider,
            getValidationInfo: getValidationInfo,
            getErrorMessages: getErrorMessages,
            getProvidersInformation: getProvidersInformation
        };

        /**
         * @ngdoc function
         * @name addProvider
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:ProviderService
         * @description
         *
         * Adds a new provider if it does not exist.
         *
         * @param provider
         */

        function addProvider(provider) {
            return API.post('providers/create', new ProviderModel(provider, true));
        }

        /**
         * @ngdoc function
         * @name getProvidersByStatus
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:ProviderService
         * @description
         *
         * Gets all providers.
         *
         * @param showActive
         * @param count
         * @param length
         * @param offset
         * @param sort
         */
        function getProvidersByStatus(showActive, count, length, offset, sort, nextLink) {
            var canceler = $q.defer();
            var deferred = $q.defer();

            var queryParams = _getQueryParams(showActive, count, length, offset, sort);

            var providerApi = API.get('providers', queryParams, null, null, canceler, nextLink);
            providerApi.promise.then(_mapProviderResponse.bind(null, deferred)).catch(_handleError.bind(null, deferred));

            return {
                promise: deferred.promise,
                canceler: providerApi.canceler
            };

        }

        /**
         * function to map provider response to provider list for view model
         * @param deferred
         * @param response
         * @private
         */
        function _mapProviderResponse(deferred, response) {
            var providers = response.data.providerList.map(function (provider) {
                return new ProviderModel(provider);
            });
            var providerObj = {
                totalCount: response.data.providerCount || DATA_LIMIT_CONSTS.DEFAULT_COUNT_VALUE,
                responseList: providers || []
            };
            providerObj.link = (!Utility.isEmpty(response.link)) ? response.link : null;
            deferred.resolve(providerObj);
        }

        function _handleError(deferred, err) {
            $log.error('There was an error', err);
            deferred.reject(err);
        }

        /**
         * function to set query param object
         * @param showActive
         * @param count
         * @param length
         * @param offset
         * @param sort
         * @returns {queryParamObject}
         * @private
         */
        function _getQueryParams(showActive, count, length, offset, sort) {
            return {
                active: showActive,
                count: count || DATA_LIMIT_CONSTS.DEFAULT_COUNT,
                limit: !Utility.isEmpty(length) ? length : DATA_LIMIT_CONSTS.DEFAULT_LIMIT,
                offset: offset || DATA_LIMIT_CONSTS.DEFAULT_OFFSET,
                // sort by provider last name ascending order
                sort: sort || PROVIDER_CONSTS.PROVIDER_SORT.LAST_NAME
            };
        }

        /**
         * @ngdoc function
         * @name getProvidersInformation
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:ProviderService
         * @description
         *
         * Gets all providers information.
         *
         * @param showActive
         * @param count
         * @param length
         * @param offset
         * @param sort
         */
        function getProvidersInformation(showActive, count, length, offset, sort) {
            var deferred = $q.defer();

            var queryParams = _getQueryParams(showActive, count, length, offset, sort);

            API.get('providers', queryParams).then(_mapProviderResponse.bind(null, deferred)).catch(_handleError.bind(null, deferred));

            return deferred.promise;
        }

        /**
         * @ngdoc function
         * @name getProviderByProviderId
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:ProviderService
         * @description
         *
         * Get provider by provider id.
         *
         * @param providerId
         */
        function getProviderByProviderId(providerId) {
            var deferred = $q.defer();

            API.get('providers/' + providerId)
                .then(function(response) {
                    var provider = new ProviderModel(response);
                    deferred.resolve(provider);
                })
                .catch(function(err) {
                    deferred.reject(err);
                });

            return deferred.promise;
        }

        /**
         * @ngdoc function
         * @name updateProvider
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:ProviderService
         * @description
         *
         * Update provider.
         *
         * @param provider
         */

        function updateProvider(provider) {
            return API.put('providers/update', new ProviderModel(provider, true));
        }

        /**
         * @ngdoc function
         * @name updateProviderStatus
         * @methodOf eAccess.ProviderPortal.Components.Practice.service:ProviderService
         * @description
         *
         * Update provider status.
         *
         * @param providers
         */
        function updateProviderStatus(providers) {
            return API.put('providers/update/status', providers);
        }

        function getValidationInfo() {
            return {
                firstName: {
                    minLength: PROVIDER_CONSTS.ERROR_MESSAGES.FIRST_NAME_MIN_LENGTH,
                    maxLength: PROVIDER_CONSTS.ERROR_MESSAGES.FIRST_NAME_MAX_LENGTH
                },
                middleName: {
                    minLength: PROVIDER_CONSTS.ERROR_MESSAGES.MIDDLE_NAME_MIN_LENGTH,
                    maxLength: PROVIDER_CONSTS.ERROR_MESSAGES.MIDDLE_NAME_MAX_LENGTH
                },
                lastName: {
                    minLength: PROVIDER_CONSTS.ERROR_MESSAGES.LAST_NAME_MIN_LENGTH,
                    maxLength: PROVIDER_CONSTS.ERROR_MESSAGES.LAST_NAME_MAX_LENGTH
                },
                npi: {
                    minLength: PROVIDER_CONSTS.ERROR_MESSAGES.NPI_MIN_LENGTH,
                    maxLength: PROVIDER_CONSTS.ERROR_MESSAGES.NPI_MAX_LENGTH
                },
                taxId: {
                    minLength: PROVIDER_CONSTS.ERROR_MESSAGES.TAX_ID_MIN_LENGTH,
                    maxLength: PROVIDER_CONSTS.ERROR_MESSAGES.TAX_ID_MAX_LENGTH
                },
                practiceProviderId: {
                    minLength: PROVIDER_CONSTS.ERROR_MESSAGES.PRACTICE_PROVIDER_ID_MIN_LENGTH,
                    maxLength: PROVIDER_CONSTS.ERROR_MESSAGES.PRACTICE_PROVIDER_ID_MAX_LENGTH
                }
            };
        }

        function getErrorMessages(validation) {
            return {
                loadPractice: {
                    display: false,
                    text: PROVIDER_CONSTS.ERROR_MESSAGES.LOAD_PRACTICE_ERROR
                },
                provider: {
                    required: PROVIDER_CONSTS.ERROR_MESSAGES.PROVIDER_REQUIRED,
                    selectProvider: PROVIDER_CONSTS.ERROR_MESSAGES.PLEASE_SELECT_PROVIDER,
                    providerNotActive: PROVIDER_CONSTS.ERROR_MESSAGES.PROVIDER_NO_LONGER_ACTIVE
                },
                firstName: {
                    minLength: PROVIDER_CONSTS.ERROR_MESSAGES.FIRST_NAME_MIN_LENGTH_MSG.replace('#minLength', validation.firstName.minLength),
                    maxLength: PROVIDER_CONSTS.ERROR_MESSAGES.FIRST_NAME_MAX_LENGTH_MSG.replace('#maxLength', validation.firstName.maxLength),
                    required: PROVIDER_CONSTS.ERROR_MESSAGES.FIRST_NAME_REQUIRED
                },
                middleName: {
                    minLength: PROVIDER_CONSTS.ERROR_MESSAGES.MIDDLE_NAME_MIN_LENGTH_MSG.replace('#minLength', validation.middleName.minLength),
                    maxLength: PROVIDER_CONSTS.ERROR_MESSAGES.MIDDLE_NAME_MAX_LENGTH_MSG.replace('#maxLength', validation.middleName.maxLength)
                },
                lastName: {
                    minLength: PROVIDER_CONSTS.ERROR_MESSAGES.LAST_NAME_MIN_LENGTH_MSG.replace('#minLength', validation.lastName.minLength),
                    maxLength: PROVIDER_CONSTS.ERROR_MESSAGES.LAST_NAME_MAX_LENGTH_MSG.replace('#maxLength', validation.lastName.maxLength),
                    required: PROVIDER_CONSTS.ERROR_MESSAGES.LAST_NAME_REQUIRED
                },
                npi: {
                    minLength: PROVIDER_CONSTS.ERROR_MESSAGES.NPI_MIN_LENGTH_MSG.replace('#minLength', validation.npi.minLength),
                    maxLength: PROVIDER_CONSTS.ERROR_MESSAGES.NPI_MAX_LENGTH_MSG.replace('#maxLength', validation.npi.maxLength),
                    required: PROVIDER_CONSTS.ERROR_MESSAGES.NPI_REQUIRED,
                    invalid: PROVIDER_CONSTS.ERROR_MESSAGES.NPI_INVALID
                },
                taxId: {
                    // actual Tax ID length is 9, but this allows for the '-' character in mask / validation
                    mask: PROVIDER_CONSTS.ERROR_MESSAGES.TAX_ID_MASK_MSG.replace('#mask', validation.taxId.minLength - 1),
                    minLength: PROVIDER_CONSTS.ERROR_MESSAGES.TAX_ID_MIN_LENGTH_MSG.replace('#minLength', validation.taxId.minLength),
                    maxLength: PROVIDER_CONSTS.ERROR_MESSAGES.TAX_ID_MAX_LENGTH_MSG.replace('#maxLength', validation.taxId.maxLength),
                    required: PROVIDER_CONSTS.ERROR_MESSAGES.TAX_ID_REQUIRED
                },
                practiceProviderId: {
                    maxLength: PROVIDER_CONSTS.ERROR_MESSAGES.PRACTICE_PROVIDER_ID_MAX_LENGTH_MSG.replace('#maxLength', validation.practiceProviderId.maxLength),
                    minLength: PROVIDER_CONSTS.ERROR_MESSAGES.PRACTICE_PROVIDER_ID_MIN_LENGTH_MSG.replace('#minLength', validation.practiceProviderId.minLength),
                    unique: PROVIDER_CONSTS.ERROR_MESSAGES.PRACTICE_PROVIDER_ID_IS_NOT_UNIQUE
                }
            };
        }

        return service;
    }
})();
