/**
 * Created by nikesh on 10/26/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Register')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('register.user-agreement', {
                parent: 'register',
                url: '/user-agreement',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Terms & Conditions'
                },
                views: {
                    'registerContent': {
                        templateUrl: 'components/register/user-agreement/user-agreement.html',
                        controller: 'UserAgreementController as userAgreement'
                    }
                }
            });
    }

})();
