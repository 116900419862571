/**
 * Router Config
 */
angular
    .module('eAccess.ProviderPortal')
    .config(routerConfig);

routerConfig.$inject = ['$stateProvider', '$urlRouterProvider', '$locationProvider', '$qProvider'];

function routerConfig ($stateProvider, $urlRouterProvider, $locationProvider, $qProvider) {
    // override '!' hash prefix
    $locationProvider.hashPrefix('');
    $urlRouterProvider.otherwise('/');
    $stateProvider.state('site', {
        'abstract': true
    });

    // Throwing an error on possibly unhandled exceptions causes a bug with
    // ui-router. This bug also causes karma tests to fail when promises are
    // triggered.
    //
    // https://github.com/angular-ui/ui-router/issues/2889
    $qProvider.errorOnUnhandledRejections(false);
}
