/**
 * Created by aayush.regmi on 10/16/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.HipaaBaa')
        .component('eaHipaaBaa', {
            templateUrl: 'components/hipaa-baa/hipaa-baa.html',
            controller: 'HipaaBaaController as hipaa',
            bindings: {
                parentForm: '=',
                hipaaBaa: '=',
                registrationConfig: '=',
                nextCallback: '=',
                previousCallback: '='
            }
        });

})();
