/**
 * Log Config
 */
angular
    .module('eAccess.ProviderPortal')
    .config(logConfig);

logConfig.$inject = ['$logProvider', 'ENV'];

function logConfig ($logProvider, ENV) {
    if (ENV.SHOW_LOGS) {
        $logProvider.debugEnabled(true);
    } else {
        $logProvider.debugEnabled(false);
    }
}
