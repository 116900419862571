/**
 * Created by mcaputo on 4/7/17
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.DiagnosisCode')
        .factory('DiagnosisCodeModel', DiagnosisCodeModel);

    DiagnosisCodeModel.$inject = ['$log'];

    function DiagnosisCodeModel() {
        function DiagnosisCodeInstance(id, code, description) {
            this.id = (angular.isDefined(id) && id !== null)? id : null;
            this.code = code || null;
            this.diagnosis = description || null;
            this.rank = 1; // Only one diagnosis code is allowed currently, so it always has rank 1
        }

        return DiagnosisCodeInstance;
    }
})();
