(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Patient.Profile')
        .controller('PatientProfileController', PatientProfileController);

    PatientProfileController.$inject = ['$log', 'PatientStyles', 'PatientService', '$stateParams', '$uibModal', 'STATUS_CONSTS', 'PATIENT_CONSTS', 'StatusStyles', 'ELIGIBILITY_CONSTS', 'PaginationService', 'Utility', 'InsurancePolicy', 'HELP_TEXT_CONSTS', 'PatientModel', 'Eligibility', 'moment', 'SEARCH_CONSTS', 'InsurancePolicyService', 'WarnOnLeave', 'ConfigurationService', 'BV_SELECTION_CONSTS', 'CacheDataService', '$state', 'BVSelectionService', 'USER_CONSTS', '$anchorScroll', 'ConsentService', 'ERROR_MESSAGES', 'patientData', 'ToastNotificationService', 'GLOBAL_CONSTS', 'DATA_LIMIT_CONSTS'];

    function PatientProfileController($log, PatientStyles, PatientService, $stateParams, $uibModal, STATUS_CONSTS, PATIENT_CONSTS, StatusStyles, ELIGIBILITY_CONSTS, PaginationService, Utility, InsurancePolicy, HELP_TEXT_CONSTS, PatientModel, Eligibility, moment, SEARCH_CONSTS, InsurancePolicyService, WarnOnLeave, ConfigurationService, BV_SELECTION_CONSTS, CacheDataService, $state, BVSelectionService, USER_CONSTS, $anchorScroll, ConsentService, ERROR_MESSAGES, patientData, ToastNotificationService, GLOBAL_CONSTS, DATA_LIMIT_CONSTS) {

        var vm = this;

        //data
        vm.requestDrawerCollapsed = false;
        vm.loadingBVs = false;
        vm.patientBVCases = [];
        vm.limit = PATIENT_CONSTS.PATIENT_PROFILE.COMPLETED_BV.LIMIT;
        /**
         *  By default the patient's bv is sorted by date completed
         *  Negative sign indicates descending order
         * */
        vm.sortBy = "-" + ELIGIBILITY_CONSTS.BV_SORT.DATE_COMPLETED;
        vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
        vm.fetchBvCount = true;
        vm.totalBVCount = DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE;
        vm.noDataFound = false;
        vm.showConsentErrorMessage = false;
        vm.displayCheckForUpdate = true;

        vm.benfitVerificationsDrawerCollapsed = true;
        vm.editPatientInfo = false;
        vm.isSavingPatient = false;
        vm.insurancePolicyDrawerCollapsed = true;
        vm.insurancePolicies = [];
        vm.editingInsuranceInfo = false;
        vm.patientInfoEditable = {};
        vm.portalBVMethod = null;
        vm.isPatientInactive = true;
        vm.disableInput = false;

        vm.dataSortOrders = {
            responseId: {isAscending: true},
            providerName: {isAscending: false},
            dateCompleted: {isAscending: false}
        };
        vm.nextLink = null;

        //functions
        vm.setBenefitStatusClass = PatientStyles.setBenefitStatusClass;
        vm.setCoverageDetailsClass = PatientStyles.setCoverageDetailsClass;
        vm.setCoverageDetailsIcon = PatientStyles.setCoverageDetailsIcon;
        vm.patientInfo = null;
        vm.updatePatientStatus = updatePatientStatus;
        vm.updateInsuranceInfo = updateInsuranceInfo;
        vm.openReactivateDeactivateModal = openReactivateDeactivateModal;
        vm.getPatientInfoByPatientId = getPatientInfoByPatientId;
        vm.getIconTooltipInfo = StatusStyles.getIconTooltipInfo;
        vm.getIconClass = StatusStyles.getAdjudicationIconClass;
        vm.isPatientSubscriber = PatientService.isPatientSubscriber;
        vm.getSubscriberName = getSubscriberName;
        vm.sortBVData = sortBVData;
        vm.verifyBenefits = verifyBenefits;
        vm.getPatientName = getPatientName;

        vm.consentTooltip = HELP_TEXT_CONSTS.BV_REQUEST.PATIENT_INFO.CONSENT;
        vm.patientIdTooltip = HELP_TEXT_CONSTS.BV_REQUEST.PATIENT_INFO.PRACTICE_PATIENT_ID;
        vm.ssnToolTip = HELP_TEXT_CONSTS.BV_REQUEST.PATIENT_INFO.SOCIAL_SECURITY;
        vm.editInsuranceInfo = editInsuranceInfo;
        vm.cancelInsuranceInfoUpdate = cancelInsuranceInfoUpdate;
        vm.editPatient = editPatient;
        vm.updatePatientInfo = updatePatientInfo;
        vm.checkCoverage = checkCoverage;
        vm.getPatientInfoByPatientId($stateParams.portalPatientId);
        vm.viewBVResponse = Eligibility.viewBVResponse;
        vm.checkGender = Utility.checkGender;
        vm.getFullName = getFullName;
        vm.cancelPatientInfoUpdate = cancelPatientInfoUpdate;
        vm.setSobErrorMessage = setSobErrorMessage;
        vm.patientfullName = null;
        vm.checkConsentUpdate = checkConsentUpdate;
        vm.validateAndUpdatePatient = validateAndUpdatePatient;

        /* For displaying E-Hipaa Consent*/
        vm.disableSubmit = false;
        vm.isEhipaa = false;
        // vm.allowedEhipaaStatus = PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_INVITE_STATUS.COMPLETE;
        vm.patientConsent = {
            type: '',
            emailOrMobile: '',
            eHipaaStatus: '',
        };
        vm.displayCheckForUpdateToast = false;

        vm.consentMessage = {
            onFile: false
        };

        vm.$onInit = function() {
            vm.hoverContent = PatientService.getHoverContent();

            if(!Utility.isEmpty(patientData)) {
                _setPatientData(patientData);
            }

            ConfigurationService.fetchPortalConfiguration().then(function(config) {
                vm.portalBVMethod = config.portalBVMethod.toLowerCase();
            }).catch(function(err) {
                $log.error("Unable to set portal BV method", err);
            });
            // Get patient insurance information by patientId
            var fetchPatientOnly = false;
            vm.getPatientInfoByPatientId($stateParams.portalPatientId);
            PatientService.getPatientAndInsuranceInfoByPatientId($stateParams.portalPatientId, fetchPatientOnly).then(
                function(data) {
                    vm.consentMessage = data.patientConsent;
                    vm.consentMessage.onFile = vm.consentMessage.onfile;
                    vm.showConsentErrorMessage = vm.consentMessage.onfile === false || vm.consentMessage.expired;
                    vm.insurancePolicies = data.patientPayers;
                    if(vm.insurancePolicies.length === 0) {
                        vm.insurancePolicyDrawerCollapsed = false;
                    }
                    vm.disableInput = PatientService.isConsentCaptured(vm.consentMessage);
                }
            ).catch(function(err) {
                $log.error(err);
                vm.insurancePolicyDrawerCollapsed = false;
                vm.showConsentErrorMessage = true;
            }).finally(function () {
                _fetchPatientConsent($stateParams.portalPatientId);
            });
        };

        function _setPatientData(patientData) {
            (patientData instanceof PatientModel) ? vm.patientInfo = patientData : vm.patientInfo = patientData.responseList[0].patient;
            vm.patientfullName = (!Utility.isEmpty(vm.patientInfo.firstName) && !Utility.isEmpty(vm.patientInfo.lastName)) ? vm.getFullName(vm.patientInfo) : null;
            vm.patientInfo.address = {zipCode: vm.patientInfo.zip || null};
            vm.patientInfo.ssnOrMedicareId = PatientService.getSsnOrMedIdDisplayValue(vm.patientInfo);
            vm.patientInfoEditable = angular.copy(vm.patientInfo);
            vm.isPatientInactive = (Utility.isEmpty(vm.patientInfo.status) || Utility.lowerCase(vm.patientInfo.status) === STATUS_CONSTS.STATUS.INACTIVE);
            if(vm.isPatientInactive) _setToastNotificationMessage(PATIENT_CONSTS.PATIENT_INACTIVE_TOAST_MESSAGE.HEADER, PATIENT_CONSTS.PATIENT_INACTIVE_TOAST_MESSAGE.MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
        }

        function editPatient() {
            vm.editPatientInfo = true;
            vm.patientInfoEditable = angular.copy(vm.patientInfo);
            vm.patientInfoEditable.gender = Utility.upperCase(vm.patientInfoEditable.gender[0]);
            vm.patientInfoEditable.dob = new Date(vm.patientInfoEditable.dob);

            // We only want our checkbox to be checked if consent is onFile and hasn't expired
            vm.consentMessage.onFile = vm.consentMessage.onFile && !vm.consentMessage.expired;
            vm.patientInfoEditable.patientConsent = {
                onFile: false
            };
        }


        function preparePatientInfo(patient) {
            patient.dob = moment(patient.dob).format(GLOBAL_CONSTS.DATE_FORMAT.YYYY_MM_DD.DASH);
            /**
             * We are doing this because Backend will not throw error
             * until external patient id is unique. And UI never
             * makes any changes to that value. If we delete and
             * return the value the update functionality is working
             * as expected.
             */
            delete patient.externalPatientId;
            return patient;
        }

        function _updateViewPatientInfo() {
            vm.patientInfo.firstName = vm.patientInfoEditable.firstName;
            vm.patientInfo.middleName = vm.patientInfoEditable.middleName;
            vm.patientInfo.lastName = vm.patientInfoEditable.lastName;
            vm.patientInfo.dob = vm.patientInfoEditable.dob;
            vm.patientInfo.gender = Utility.checkGender(vm.patientInfoEditable.gender);
            vm.patientInfo.address = {zipCode: vm.patientInfoEditable.address.zipCode};
            vm.patientInfo.ssnOrMedicareId = vm.patientInfoEditable.ssnOrMedicareId;
            vm.patientInfo.practicePatientId = vm.patientInfoEditable.practicePatientId;
            vm.patientfullName = Utility.getFullName(vm.patientInfo);
        }

        function validateAndUpdatePatient() {
            PatientService.validateZipCode(vm.patientInfoEditable.address.zipCode).then(function (validity) {
                vm.checkCoverageForm.zipCode.$setValidity('zipcode', validity);
            }).catch(function (err) {
                $log.info('could not validate zipCode');
            }).finally(function () {
                updatePatientInfo();
            });
        }

        function updatePatientInfo() {
            if(vm.checkCoverageForm.$invalid) {
                return false;
            }
            vm.isSavingPatient = true;
            ToastNotificationService.clearToastNotification();
            var patientToUpdate = preparePatientInfo(angular.copy(vm.patientInfoEditable));
            PatientService.updatePatientInfo(patientToUpdate).then(function(response) {
                vm.isSavingPatient = false;
                vm.editPatientInfo = false;
                _updateViewPatientInfo();
                vm.consentMessage = response.patientConsent;
                vm.consentMessage.onFile = vm.consentMessage.onfile;
                vm.showConsentErrorMessage = vm.consentMessage.onfile === false;
                ToastNotificationService.setGlobalToastInfo(GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
            }).catch(function(error) {
                vm.isSavingPatient = false;
                ToastNotificationService.setGlobalToastInfo(error.status);
            }).finally(function() {
                $anchorScroll();
            });
        }

        function updatePatientStatus() {
            var requestData = {
                patients: [{
                    patientId: vm.patientInfo.patientId,
                    active: vm.patientInfo.status === STATUS_CONSTS.STATUS.INACTIVE
                }]
            };
            ToastNotificationService.clearToastNotification();
            PatientService.updatePatientStatus(requestData).then(function(response) {
                if(vm.patientInfo.status === STATUS_CONSTS.STATUS.ACTIVE) {
                    _setToastNotificationMessage(PATIENT_CONSTS.SUCCESS_MESSAGE.DEACTIVATED_HEADER, PATIENT_CONSTS.SUCCESS_MESSAGE.DEACTIVATED_MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
                } else {
                    _setToastNotificationMessage(PATIENT_CONSTS.SUCCESS_MESSAGE.REACTIVATE_ONE_PATIENT_TITLE, PATIENT_CONSTS.SUCCESS_MESSAGE.ACTIVATED_MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
                }
                vm.patientInfo.status = vm.patientInfo.status === STATUS_CONSTS.STATUS.ACTIVE ? STATUS_CONSTS.STATUS.INACTIVE : STATUS_CONSTS.STATUS.ACTIVE;
                vm.isPatientInactive = !Utility.isStatusActive(vm.patientInfo.status);
                _fetchPatientConsent(vm.patientInfo.patientId);
            }).catch(function(err) {
                $log.error('error', err);
                var errorMessage = !Utility.isEmpty(err) &&
                                    !Utility.isEmpty(err.data) &&
                                    !Utility.isEmpty(err.data.message) ? err.data.message : GLOBAL_CONSTS.TOAST_MESSAGE.SYSTEM_MESSAGE;
                _setToastNotificationMessage(GLOBAL_CONSTS.TOAST_TITLE.SYSTEM_ERROR, errorMessage, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
            });
        }

        function _fetchPatientConsent(patientId) {
            //This is to retrieve patient consent details
            ConsentService.getConsentData(patientId).then(function(data) {
                vm.consentMessage = data;
                vm.consentMessage.onFile = vm.consentMessage.onfile;
                vm.showConsentErrorMessage = vm.consentMessage.onfile === false;
            }).catch(function(err) {
                ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(
                    vm.displayCheckForUpdateToast,
                    PATIENT_CONSTS.CONSENT_TEXT.ERROR_MESSAGE.HEADER,
                    PATIENT_CONSTS.CONSENT_TEXT.ERROR_MESSAGE.MESSAGE,
                    GLOBAL_CONSTS.TOAST_TYPES.ERROR
                ));
                $log.error("Could not retrieve patient consent: ", err);
            });
        }


        function updateInsuranceInfo() {
            ToastNotificationService.clearToastNotification();
            vm.savingInsuranceInfo = true;
            if(vm.editInsuranceInfoForm.$invalid) {
                vm.savingInsuranceInfo = false;
                return false;
            }

            var requestData = {
                patient: {
                    patientId: vm.patientInfo.patientId
                },
                policyList: angular.copy(vm.editInsurancePolicies).map(_mapPatientPolicyRequest)
            };

            PatientService.updatePatientInsurance(requestData).then(function(response) {
                vm.insurancePolicies = response;
                vm.editingInsuranceInfo = false;
                ToastNotificationService.setGlobalToastInfo(GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
            }).catch(function(err) {
                $log.error('Unable to update patient insurance info', err);
                ToastNotificationService.setGlobalToastInfo(err.status);
            }).finally(function() {
                vm.savingInsuranceInfo = false;
                $anchorScroll();
            });
        }

        function _mapPatientPolicyRequest(policy) {
            policy.medicareDetails = {
                memberId: policy.medicareMemberId,
                supplementalLetter: (!Utility.isEmpty(policy.medSuppData) && !Utility.isEmpty(policy.medSuppData.planLetter)) ? policy.medSuppData.planLetter : null
            };
            if(!Utility.isEmpty(policy.subscriber) && !Utility.isEmpty(policy.subscriber.dob)) policy.subscriber.dob = moment(policy.subscriber.dob).format(SEARCH_CONSTS.DATE_FORMAT);
            policy.payer.clientId = policy.payer.organizationPayerId;

            delete policy.payerOrg;
            delete policy.medicareMemberId;
            delete policy.medSuppData;
            delete policy.patient;
            delete policy.subscriberDob;
            delete policy.subscriberFirstName;
            delete policy.subscriberLastName;
            delete policy.subscriberGender;
            delete policy.subscriberMemberId;
            delete policy.subscriberMiddleName;
            delete policy.subscriberPatient;

            return policy;
        }

        function openReactivateDeactivateModal() {
            var changePatientStatusModal = $uibModal.open({
                animation: true,
                templateUrl: 'partials/templates/deactivate-reactivate-item-modal.html',
                controller: 'DeactivateReactivateItemModalController',
                controllerAs: 'deactivateReactivateItem',
                appendTo: angular.element('body'),
                resolve: {
                    titleText: function() {
                        return getModalTitle();
                    },
                    subTitleText: function() {
                        return getPatientName();
                    },
                    message: function() {
                        return getModalMessage();
                    },
                    isDeactivate: function() {
                        return vm.patientInfo.status === STATUS_CONSTS.STATUS.ACTIVE;
                    }
                }
            });
            changePatientStatusModal.result.then(
                function(update) {
                    if(update) {
                        vm.updatePatientStatus();
                    }
                }
            );

            return changePatientStatusModal;
        }

        function getModalTitle() {
            var reactivateDeactivate = vm.patientInfo.status === STATUS_CONSTS.STATUS.ACTIVE ? PATIENT_CONSTS.DEACTIVATE : PATIENT_CONSTS.ACTIVATE;
            return reactivateDeactivate + ' Patient';
        }

        function getPatientName() {
            var name = '';
            if(vm.patientInfo.middleName) {
                name = vm.patientInfo.firstName + ' ' + vm.patientInfo.middleName + ' ' + vm.patientInfo.lastName;
            } else {
                name = vm.patientInfo.firstName + ' ' + vm.patientInfo.lastName;
            }
            return name;
        }

        function getModalMessage() {
            return vm.patientInfo.status === STATUS_CONSTS.STATUS.ACTIVE ? PATIENT_CONSTS.DEACTIVATE_PATIENT_MODAL_MESSAGE : PATIENT_CONSTS.ACTIVATE_PATIENT_MODAL_MESSAGE;
        }

        function _setToastNotificationMessage(header, message, toastType) {
            ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, header, message, toastType));
        }

        function getPatientInfoByPatientId(patientId) {
            var resultListEqualsTotalCount = vm.totalBVCount === vm.patientBVCases.length;
            if(!Utility.shouldCallAPI(vm.loadingBVs, resultListEqualsTotalCount, vm.noDataFound)) return;
            vm.loadingBVs = true;
            PatientService.getPatientByPatientId(patientId, vm.limit, vm.sortBy, vm.offset, vm.fetchBvCount, vm.nextLink).then(function(response) {
                var paginationResponse = PaginationService.getPaginationResponse(response, vm.patientBVCases, 'responseId', vm.offset, vm.limit);
                vm.totalBVCount = paginationResponse.totalCount;
                vm.nextLink = paginationResponse.nextLink;
                vm.patientBVCases = paginationResponse.list;
                if(vm.totalBVCount > 0) {
                    vm.disableInput = true;
                }
            }).catch(function(err) {
                $log.error("Error getting patient's BV list", err);
                if(!vm.patientBVCases.length) {
                    vm.noDataFound = true;
                    vm.errorMessage.loadingBVs.display = true;
                    vm.loadingPatientsBVsWarningMessage = err.data.message;
                }
            }).finally(function() {
                vm.loadingBVs = false;
            });
        }

        function checkCoverage() {
            if(vm.checkCoverageForm.$invalid) return false;
        }

        function getSubscriberName(policy) {
            var nameObj = {
                firstName: policy.subscriberFirstName,
                middleName: policy.subscriberMiddleName,
                lastName: policy.subscriberLastName
            };
            return Utility.getFullName(nameObj);
        }

        function editInsuranceInfo() {
            // Container to store modified insurance info.
            vm.editInsurancePolicies = ((!Utility.isEmpty(vm.insurancePolicies) && vm.insurancePolicies.length > 0)
                ? angular.copy(vm.insurancePolicies).map(InsurancePolicyService.mapPatientInsurancePolicies)
                : [new InsurancePolicy()]);
            vm.editingInsuranceInfo = true;
        }

        function cancelPatientInfoUpdate() {
            var warnModal = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'warn-on-leave-title',
                arialDescribedBy: 'warn-on-leave-content',
                templateUrl: 'partials/templates/warn-on-leave-modal.html',
                controller: 'WarnOnBackModalController',
                controllerAs: 'warnOnLeave',
                appendTo: angular.element('body')
            });
            warnModal.result.then(function() {
                vm.editPatientInfo = false;
            });
        }

        function cancelInsuranceInfoUpdate() {
            var warnModal = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'warn-on-leave-title',
                arialDescribedBy: 'warn-on-leave-content',
                templateUrl: 'partials/templates/warn-on-leave-modal.html',
                controller: 'WarnOnBackModalController',
                controllerAs: 'warnOnLeave',
                appendTo: angular.element('body')
            });
            warnModal.result.then(function() {
                vm.editingInsuranceInfo = false;
            });
        }

        function sortBVData(sortBy) {
            vm.patientBVCases = [];
            vm.offset = PATIENT_CONSTS.PATIENT_PROFILE.COMPLETED_BV.INITIAL_OFFSET;
            vm.nextLink = null;
            switch(sortBy) {
                case ELIGIBILITY_CONSTS.BV_SORT.RESPONSE_ID:
                    vm.dataSortOrders.responseId.isAscending = !vm.dataSortOrders.responseId.isAscending;
                    //negative sign indicates descending order
                    vm.sortBy = vm.dataSortOrders.responseId.isAscending ? ELIGIBILITY_CONSTS.BV_SORT.RESPONSE_ID : '-' + ELIGIBILITY_CONSTS.BV_SORT.RESPONSE_ID;
                    break;
                case ELIGIBILITY_CONSTS.BV_SORT.PROVIDER_NAME:
                    vm.dataSortOrders.providerName.isAscending = !vm.dataSortOrders.providerName.isAscending;
                    //negative sign indicates descending order
                    vm.sortBy = vm.dataSortOrders.providerName.isAscending ? ELIGIBILITY_CONSTS.BV_SORT.PROVIDER_NAME : '-' + ELIGIBILITY_CONSTS.BV_SORT.PROVIDER_NAME;
                    break;
                case ELIGIBILITY_CONSTS.BV_SORT.DATE_COMPLETED:
                    vm.dataSortOrders.dateCompleted.isAscending = !vm.dataSortOrders.dateCompleted.isAscending;
                    //negative sign indicates descending order
                    vm.sortBy = vm.dataSortOrders.dateCompleted.isAscending ? ELIGIBILITY_CONSTS.BV_SORT.DATE_COMPLETED : '-' + ELIGIBILITY_CONSTS.BV_SORT.DATE_COMPLETED;
                    break;
            }
            vm.getPatientInfoByPatientId($stateParams.portalPatientId);
        }

        function verifyBenefits() {
            BVSelectionService.redirectBenefitVerify(vm.portalBVMethod, _deletePatientInfo(vm.patientInfo));
        }

        function getFullName(patientInfo) {
            var name = {
                firstName: patientInfo.firstName,
                middleName: patientInfo.middleName,
                lastName: patientInfo.lastName
            };
            return Utility.getFullName(name);
        }

        function _deletePatientInfo(patient) {
            var newPatient = angular.copy(patient);
            delete newPatient.addressOne;
            delete newPatient.addressTwo;
            delete newPatient.city;
            delete newPatient.state;
            delete newPatient.zipCode;
            delete newPatient.homeTelephone;
            delete newPatient.mobileTelephone;
            delete newPatient.payer;
            delete newPatient.benefitStatus;
            delete newPatient.coverageDetails;
            delete newPatient.recentVerificationDate;
            delete newPatient.status;
            delete newPatient.organization;
            delete newPatient.deleted;
            delete newPatient.generatedExtId;
            delete newPatient.active;
            delete newPatient.externalPatientId;
            delete newPatient.externalId;
            delete newPatient.portalPatientId;
            return newPatient;
        }

        function setSobErrorMessage(errorMessage) {
            _setToastNotificationMessage(ERROR_MESSAGES.SOB_ERROR.TITLE, errorMessage, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
            $anchorScroll();
        }

        function checkConsentUpdate(patientId) {
            vm.displayCheckForUpdateToast = true;
            _fetchPatientConsent(patientId);
        }
    }
})();
