(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ChangePassword')
        .controller('ChangePasswordController', ChangePasswordController);

    ChangePasswordController.$inject = ['$log', 'CHANGE_PASSWORD_CONSTS', 'WarnOnLeave', 'ChangePasswordService', 'Storage', 'Utility', 'ConfigurationService', 'Auth', '$stateParams', 'UserInfoService', 'PracticeView', '$state', 'UserSessionEvent', 'SystemNotificationService'];

    function ChangePasswordController($log, CHANGE_PASSWORD_CONSTS, WarnOnLeave, ChangePasswordService, Storage, Utility, ConfigurationService, Auth, $stateParams, UserInfoService, PracticeView, $state, UserSessionEvent, SystemNotificationService) {

        var vm = this;

        // data
        vm.currentPassword = null;
        vm.newPassword = null;
        vm.confirmNewPassword = null;
        vm.changePasswordForm = {};
        vm.toastNotification = {};
        vm.savingPassword = false;

        // error values
        vm.currentErrorType = '';
        vm.errorMessages = ChangePasswordService.getErrorMessage();
        vm.errorTypes = CHANGE_PASSWORD_CONSTS.ERROR_TYPES;

        //Stating UserInfoService to get current logged in user id
        vm.accountInfo = UserInfoService;
        vm.practiceViewService = PracticeView;

        // functions
        vm.changePassword = changePassword;
        vm.cancel = cancel;
        vm.getErrorMessage = getErrorMessage;
        vm.resetToastNotification = resetToastNotification;
        vm.usernameDomain = '';

        vm.$onInit = function() {
            ConfigurationService.fetchConfiguration().then(function(publicData) {
                $log.debug('header publicData');
                vm.usernameDomain = publicData.usernameDomain;
            });
        };

        function _requestDataForChangePassword() {
            var requestData = {
                newPassword: vm.newPassword,
                oldPassword: vm.currentPassword
            };
            var registrationGuid = Storage.getSessionItem('guid');
            if(!Utility.isEmpty(registrationGuid)) {
                requestData.registrationGuid = registrationGuid;
            }
            return requestData;
        }

        function changePassword() {
            vm.currentErrorType = '';
            if(!vm.currentPassword || !vm.newPassword || !vm.confirmNewPassword) return;
            if(!_matchPassword()) return;
            vm.savingPassword = true;
            var requestData = _requestDataForChangePassword();
            ChangePasswordService.changePassword(requestData)
                .then(_userLogin.bind(null, requestData))
                .then(function() {
                    _setUserSessionEvents();
                    vm.changePasswordForm.$setPristine();
                    ChangePasswordService.executeChangedPasswordCallBacks();
                    // navigate to my account page and show toast notification
                    vm.practiceViewService.setChangePasswordLink();
                    vm.practiceViewService.changePasswordLink.passwordChanged = true;
                    if (!$stateParams.fromMyAccount) {
                        SystemNotificationService.showPortalNotification = true;
                    }
                    $state.go('practice.users.view', {userId: vm.accountInfo.userId});
                }).catch(function(error) {
                    $log.info("Unable to change password " + error);
                    _handleChangePasswordError(error);
                }).finally(function() {
                    vm.savingPassword = false;
                });
        }

        function _userLogin(requestData, response) {
            var username = response.userName + vm.usernameDomain;
            var password = requestData.newPassword;
            return Auth.login({username: username, password: password});
        }

        /**
         * In our current implementation we have login the user after password reset
         * so we need to reset our session events of current user
         * @private
         */
        function _setUserSessionEvents() {
            UserSessionEvent.clearAllEvents();
            UserSessionEvent.initialize();
        }

        function _handleChangePasswordError(error) {
            if(Utility.isEmpty(error.data) || Utility.isEmpty(error.data.message)) {
                vm.currentErrorType = CHANGE_PASSWORD_CONSTS.ERROR_TYPES.GENERIC_ERROR;
                return;
            }
            switch(error.data.message) {
                case CHANGE_PASSWORD_CONSTS.INCORRECT_PASSWORD_RESPONSE:
                    vm.currentErrorType = CHANGE_PASSWORD_CONSTS.ERROR_TYPES.INCORRECT_CURRENT_PASSWORD;
                    break;
                case CHANGE_PASSWORD_CONSTS.COMPLEXITY_NOT_MET:
                    vm.currentErrorType = CHANGE_PASSWORD_CONSTS.ERROR_TYPES.COMPLEXITY_NOT_MET;
                    break;
                case CHANGE_PASSWORD_CONSTS.CURRENT_PASSWORD:
                    vm.currentErrorType = CHANGE_PASSWORD_CONSTS.ERROR_TYPES.CURRENT_PASSWORD;
                    break;
                case CHANGE_PASSWORD_CONSTS.RECENTLY_USED_PASSWORD:
                    vm.currentErrorType = CHANGE_PASSWORD_CONSTS.ERROR_TYPES.RECENTLY_USED_PASSWORD;
                    break;
                case CHANGE_PASSWORD_CONSTS.CURRENT_CHANGED:
                    vm.currentErrorType = CHANGE_PASSWORD_CONSTS.ERROR_TYPES.CURRENT_CHANGED;
                    break;
                default:
                    vm.currentErrorType = CHANGE_PASSWORD_CONSTS.ERROR_TYPES.GENERIC_ERROR;
            }

        }

        function cancel() {
            var state = {name: 'login'},
                authToken = Storage.getSessionItem('auth_token');
            if ($stateParams.fromMyAccount || !Utility.isEmpty(authToken)) {
                state = {
                    name: 'practice.users.view',
                    params: {userId: vm.accountInfo.userId}
                };
            }
            WarnOnLeave.show({form: vm.changePasswordForm, toState: state});
        }

        function _matchPassword() {
            var passwordMatched = vm.newPassword === vm.confirmNewPassword;
            if(!passwordMatched)
                vm.currentErrorType = CHANGE_PASSWORD_CONSTS.ERROR_TYPES.PASSWORD_NOT_MATCHED;
            return passwordMatched;
        }

        function getErrorMessage() {
            return CHANGE_PASSWORD_CONSTS[vm.currentErrorType];
        }

        function resetToastNotification() {
            vm.passwordChanged = false;
            vm.toastNotification = {};
        }
    }
})();
