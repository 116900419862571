(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .factory('VerifyUserService', VerifyUserService);

    VerifyUserService.$inject = ['FORBIDDEN_API_CONSTS', 'Storage', 'Utility', 'USER_CONSTS'];

    function VerifyUserService(FORBIDDEN_API_CONSTS, Storage, Utility, USER_CONSTS) {

        var service = {
            forbiddenAPI: [
                FORBIDDEN_API_CONSTS.FORBIDDEN_API.DEACTIVATE_LOCATION,
                FORBIDDEN_API_CONSTS.FORBIDDEN_API.DEACTIVATE_PROVIDER,
                FORBIDDEN_API_CONSTS.FORBIDDEN_API.DEACTIVATE_USER,
                FORBIDDEN_API_CONSTS.FORBIDDEN_API.ADD_USER,
                FORBIDDEN_API_CONSTS.FORBIDDEN_API.RESET_USER_PASSWORD
            ],
            isAPIForbidden: isAPIForbidden,
            isRoleAdmin: isRoleAdmin
        };

        /**
         * This function returns true if endpoint is present in forbiddenAPI list
         * @param endpoint
         * @returns {boolean}
         */
        function isAPIForbidden(endpoint) {
            return service.forbiddenAPI.indexOf(endpoint) >= 0;
        }

        /*see if user is admin*/
        function isRoleAdmin() {
            var userRoleString = Storage.getSessionItem('user_role');
            return !Utility.isEmpty(userRoleString) && userRoleString.indexOf(USER_CONSTS.USER_ROLE.ADMIN) !== -1;
        }
        return service;
    }
})();
