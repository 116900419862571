/**
 * Created by mcaputo on 03/14/17.
 */
(function() {
    'use strict';

    angular.module('eAccess.ProviderPortal.Services.StorageService', []).factory('Storage', Storage);

    Storage.$inject = ['$log', '$window', '$cookies'];

    function Storage($log, $window, $cookies) {

        var service = {
            saveToSession: saveToSession,
            saveToLocal: saveToLocal,
            getSessionItem: getSessionItem,
            getLocalItem: getLocalItem,
            clearCookies: clearCookies,
            clearLocalData: clearLocalData,
            clearSessionData: clearSessionData,
            clearAll: clearAll
        };

        function getSessionItem(key) {
            return $window.sessionStorage.getItem(key);
        }

        function getLocalItem(key) {
            return $window.localStorage.getItem(key);
        }

        function saveToSession(key, value) {
            $window.sessionStorage.setItem(key, value);
        }

        function saveToLocal(key, value) {
            $window.localStorage.setItem(key, value);
        }

        function clearCookies() {
            for(var cookie in $cookies.getAll()) {
                $cookies.remove(cookie);
            }
        }

        function clearLocalData(key) {
            if(key) {
                $window.localStorage.removeItem(key);
            } else {
                $window.localStorage.clear();
            }
        }

        function clearSessionData(key) {
            if(key) {
                $window.sessionStorage.removeItem(key);
            } else {
                $window.sessionStorage.clear();
            }
        }

        function clearAll() {
            clearCookies();
            clearLocalData();
            clearSessionData();
        }

        return service;
    }
})();
