(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Components.PatientDataConflict')
        .controller('PatientDataConflictController', PatientDataConflictController);

    PatientDataConflictController.$inject = ['$log', 'Eligibility', '$stateParams', 'Utility', '$state', 'CacheDataService', 'GLOBAL_CONSTS'];

    function PatientDataConflictController($log, Eligibility, $stateParams, Utility, $state, CacheDataService, GLOBAL_CONSTS) {

        var vm = this;

        vm.responsePatientList = [];
        vm.requestPatientObj = {};
        vm.requestObj = {};
        vm.responseId = null;
        vm.rxEbvId = null;
        vm.showDeclineSection = null;
        // function
        vm.checkGender = Utility.checkGender;
        vm.toggleView = toggleView;
        vm.resolvePatientConflict = resolvePatientConflict;
        vm.redirectToEdit = redirectToEdit;
        vm.getAddress = getAddress;
        vm.getCityAndState = getCityAndState;

        vm.$onInit = function() {
            vm.responseId = $stateParams.responseId;
            vm.showDeclineSection = false;
            Eligibility.getResponseById(vm.responseId).then(function(response) {
                if(!Utility.isEmpty(response)) {
                    vm.rxEbvId = response.rxEbvId;
                    if(!Utility.isEmpty(response.patientConflicts)) {
                        vm.responsePatientList = response.patientConflicts;
                    }

                    if(!Utility.isEmpty(response.request) && !Utility.isEmpty(response.request.patient)) {
                        vm.requestPatientObj = response.request.patient;
                        vm.requestObj = response.request;
                    }
                }
            }).catch(function(err) {
                $log.error(err);
            });
        };

        function resolvePatientConflict(declinedStatus) {
            var data = {
                rxEbvId: vm.rxEbvId,
                declined: declinedStatus
            };
            Eligibility.resolveRxPatientConflict(data).then(function(response) {
                $state.go('RxEligibilityResponse', {responseId: response.responseId, declined: declinedStatus});
            }).catch(function(err) {
                $log.debug(err);
            });
        }

        function toggleView() {
            vm.showDeclineSection = !vm.showDeclineSection;
        }

        function redirectToEdit() {
            var rxBvDataObj = {
                patient: vm.requestPatientObj,
                provider: vm.requestObj.provider,
                showProviderDropdown: true,
                rxServiceDeliveryInformation: vm.requestObj.service,
                selectedProduct: vm.requestObj.service.product,
                selectedLocation: vm.requestObj.location
            };

            CacheDataService.setRxBvCacheData(rxBvDataObj);
            $state.go('rxEligibility', {patientId: vm.responseId});
        }

        function getAddress(addressObj) {
            var addressArray = [addressObj.address1, addressObj.address2];
            var delimiter = ', ';
            return Utility.concatValues(addressArray, delimiter, GLOBAL_CONSTS.DASH);
        }

        function getCityAndState(addressObj) {
            var cityArray = [addressObj.city, addressObj.state];
            var delimiter = ', ';
            return Utility.concatValues(cityArray, delimiter, null);
        }
    }
})();
