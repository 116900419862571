/**
 * Created by aayush.regmi on 10/31/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.SetPassword', [
            'eAccess.ProviderPortal.Services.UserSession'
        ]);

})();
