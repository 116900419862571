/**
 * Created by mcaputo on 4/6/17
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.AdminCode')
        .factory('AdminCodeModel', AdminCodeModel);

    AdminCodeModel.$inject = ['$log'];

    function AdminCodeModel($log) {
        function AdminCodeInstance(id, code, externalCode, otherCode) {
            this.id = (angular.isDefined(id) && id !== null)? id : null;
            this.code = externalCode || null;
            this.description = code || null;
            this.otherCode = otherCode || null;
        }
        return AdminCodeInstance;
    }
})();
