/**
 * @ngdoc service
 * @name eAccess.ProviderPortal.service:ForceLogout
 * @requires Storage
 * @requires $log
 * @requires $state
 * @description
 *
 * This is the service for handling Force logout user.
 **/

(function () {
    'use strict';

    angular.module('eAccess.ProviderPortal').factory('ForceLogout', ForceLogout);

    ForceLogout.$inject = ['Storage', '$log', '$state', '$injector'];

    function ForceLogout(Storage, $log, $state, $injector) {

        var service = {
            logout: logout
        };

        /**
         * Redirect to login state
         * @param isExpired
         */
        function logout(isExpired) {
            $log.debug('forcing logout');
            Storage.clearAll();
            var userSessionEventService = $injector.get('UserSessionEvent');
            if(userSessionEventService) {
                userSessionEventService.clearAllEvents();
            }
            $state.go('login', {sessionExpired: isExpired});
        }

        return service;
    }

})();
