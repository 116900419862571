/**
 * @ngdoc service
 * @name eAccess.ProviderPortal.Components.Patient.service:PatientView
 * @author Anna Lee
 * @description
 *
 * This is the service for view functions that apply to all patient views.
 **/

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Patient')
        .factory('PatientView', PatientView);

    PatientView.$inject = [];

    function PatientView() {

        var service = {
            setTabInfo: setTabInfo,
            setPageTitle: setPageTitle,
            setActiveTab: setActiveTab
        };

        return service;

        /**
         * @ngdoc function
         * @name setTabInfo
         * @methodOf eAccess.ProviderPortal.Components.Patient.service:PatientView
         * @description
         *
         * Sets the tab info for a patient view, including setting the page title
         * and changing the active tab.
         *
         * @param title
         * @param tabIndex
         */

        function setTabInfo(title, tabIndex) {
            setPageTitle(title || 'Patients | All Patients');
            setActiveTab(tabIndex || 0);
        }

        /**
         * @ngdoc function
         * @name setPageTitle
         * @methodOf eAccess.ProviderPortal.Components.Patient.service:PatientView
         * @description
         *
         * Sets the page title on the patient view.
         *
         * @param title
         */

        function setPageTitle(title) {
            service.pageTitle = title;
        }

        /**
         * @ngdoc function
         * @name setActiveTab
         * @methodOf eAccess.ProviderPortal.Components.Patient.service:PatientView
         * @description
         *
         * Sets the active tab on patient view.
         *
         * @param tabIndex
         */

        function setActiveTab(tabIndex) {
            service.activeTab = tabIndex;
        }

    }

})();
