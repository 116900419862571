(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('account-locked', {
                url: '/account-locked',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Account Locked'
                },
                views: {
                    'content': {
                        templateUrl: 'components/account-locked/accountLocked.html',
                        controller: 'AccountLockedController as accountLock'
                    }
                },
                params: {
                    accountLockedResendEmailObject: null
                }
            });
    }

})();
