(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Search')
        .factory('SearchPaginationModel', SearchPaginationModel);

    SearchPaginationModel.$inject = ['DATA_LIMIT_CONSTS', 'Utility'];

    function SearchPaginationModel(DATA_LIMIT_CONSTS, Utility) {
        function SearchPaginationInstance(fetchCount, offset, limit, sortOrder, nextLink) {
            this.fetchCount = !Utility.isEmpty(fetchCount) ? fetchCount : true;
            this.offset = offset || DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
            this.limit = limit || null;
            this.sortOrder = sortOrder || null;
            this.nextLink = nextLink || null;
        }

        return SearchPaginationInstance;
    }

})();
