/**
 * @ngdoc Controller
 * @module eAccess.ProviderPortal.Components.RxEligibilityResponse
 * @name ServiceInformationController
 * @kind Controller
 *
 * @description
 * Controller for the service informations section
 *
 * @author girianish
 */

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .controller('ServiceInformationResponseController', ServiceInformationResponse);

    ServiceInformationResponse.$inject = ['HELP_TEXT_CONSTS'];

    function ServiceInformationResponse(HELP_TEXT_CONSTS) {
        var vm = this;

        vm.helpText = {
            practiceLocation: HELP_TEXT_CONSTS.BV_REQUEST.SERVICE_DELIVERY.PRACTICE_LOCATION,
            daysSupply: HELP_TEXT_CONSTS.SERVICE_DELIVERY.DAYS_SUPPLY,
            quantity: HELP_TEXT_CONSTS.SERVICE_DELIVERY.QUANTITY
        };
    }


})();
