/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.Components.Practice.Provider.Edit.controller:PracticeProviderEditController
 * @author Pramesh Bhattarai
 * @requires $log
 * @requires PracticeView
 * @requires $state
 * @requires ProviderService
 * @requires  PROVIDER_CONSTS,
 * @requires HELP_TEXT_CONSTS
 * @description
 *
 * This is the controller for the Practice Provider Edit page.
 */
(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .controller('PracticeProviderEditController', PracticeProviderEditController);

    PracticeProviderEditController.$inject = ['$log', 'PracticeView', '$state', 'ProviderService', 'PROVIDER_CONSTS', 'HELP_TEXT_CONSTS', 'providerResponse', 'ToastNotificationService', 'GLOBAL_CONSTS', 'PracticeSetupAddService'];

    function PracticeProviderEditController($log, PracticeView, $state, ProviderService,  PROVIDER_CONSTS, HELP_TEXT_CONSTS, providerResponse, ToastNotificationService, GLOBAL_CONSTS, PracticeSetupAddService) {
        var vm = this;

        // data
        vm.provider = {};

        vm.savingPracticeProvider = false;

        vm.loadingProvider = false;

        // Help text for help icon tooltips
        vm.helpText = {
            state: HELP_TEXT_CONSTS.PROVIDER.STATE,
            npi: HELP_TEXT_CONSTS.PROVIDER.NPI,
            taxId: HELP_TEXT_CONSTS.PROVIDER.TAX_ID,
            practiceProviderId: HELP_TEXT_CONSTS.PROVIDER.PRACTICE_PROVIDER_ID
        };
        vm.savePracticeProvider = savePracticeProvider;

        // function
        vm.$onInit = function() {
            vm.provider = providerResponse;
            init();
        };


        function init() {
            PracticeView.configure({
                title: 'My Practice | Providers',
                contentTitle: 'EDIT PROVIDER',
                tabIndex: 2
            });
        }

        /**
         * @ngdoc function
         * @name savePractice
         * @methodOf eAccess.ProviderPortal.Components.Practice.Providers.Edit.controller:PracticeProviderEditController
         * @description
         *
         * Saves updated practice provider.
         */

        function savePracticeProvider() {
            vm.practiceForm.practiceProviderId.$setValidity('unique', true);
            vm.practiceForm.npi.$setValidity('npiType1', true);
            // bail if the form is invalid
            if (vm.practiceForm.$invalid) {
                return false;
            }

            vm.savingPracticeProvider = true;
            ProviderService.updateProvider(vm.provider)
                .then(function () {
                    vm.practiceForm.$setPristine();
                    $state.go('practice.providers');
                    ToastNotificationService.setGlobalToastInfo(GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
                })
                .catch(function (error) {
                    var errorDisplayed = 0;
                    if (error.data.message.trim() === PROVIDER_CONSTS.ERROR_MESSAGES.IS_NOT_UNIQUE) {
                        ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, PROVIDER_CONSTS.ERROR_MESSAGES.PROVIDER_ID_IS_NOT_UNIQUE_TITLE, error.data.message, GLOBAL_CONSTS.TOAST_TYPES.ERROR));
                        errorDisplayed = 1;
                    }
                    if (error.data.message.trim() === PROVIDER_CONSTS.ERROR_MESSAGES.PRACTICE_PROVIDER_ID_IS_NOT_UNIQUE) {
                        vm.practiceForm.practiceProviderId.$setValidity('unique', false);
                        errorDisplayed = 1;
                    }
                    if(PracticeSetupAddService.isNpiError(error)) {
                        vm.practiceForm.npi.$setValidity('npiType1', false);
                        errorDisplayed = 1;
                    }
                    if (!errorDisplayed) {
                        ToastNotificationService.setGlobalToastInfo(error.status);
                    }
                })
                .finally(function () {
                    vm.savingPracticeProvider = false;
                });
        }

    }
})();
