(function() {
    'use strict';

    var eaPasswordComplexity = {
        templateUrl: 'components/password-complexity/password-complexity.html',
        controller: 'PasswordComplexityController as vm'
    };

    angular
        .module('eAccess.ProviderPortal.Components.PasswordComplexity')
        .component('eaPasswordComplexity', eaPasswordComplexity);
})();
