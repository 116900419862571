/**
 * Created by nikesh on 10/17/2017.
 */
(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PracticeSetup')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('practice-setup.welcome', {
                parent: 'practice-setup',
                url: '/welcome',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Practice Setup Welcome'
                },
                views: {
                    'practiceSetupContent': {
                        templateUrl: 'components/practice-setup/welcome/welcome.html',
                        controller: 'WelcomeController as welcome'
                    }
                }
            });
    }

})();
