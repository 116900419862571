/**
 * Created by aayush regmi on 4/29/18.
 */

(function () {
    'use strict';

    var eaMbvMessage = {
        bindings: {
            bvType: '<',
            status: '<',
            responseDate: '<',
            requestDate: '<'
        },
        templateUrl: 'components/mbv-message/mbv-message.html',
        controller: 'MBVMessageController as mbvMessage'
    };

    angular
        .module('eAccess.ProviderPortal.Components.MBVMessage')
        .component('eaMbvMessage', eaMbvMessage);

})();
