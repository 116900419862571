/**
 * Created by mcaputo on 1/25/17.
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Eligibility')
        .controller('EligibilityResponseController', EligibilityResponseController);

    EligibilityResponseController.$inject = ['$log', '$state', '$stateParams', '$location', '$anchorScroll', 'Eligibility', 'ELIGIBILITY_CONSTS', 'PolicyService', 'StatusStyles', 'Utility', 'STATUS_CONSTS', 'CacheDataService', 'BV_SELECTION_CONSTS', 'PAFormRequestModel', 'RxEligibilityService', 'ERROR_MESSAGES', 'PATIENT_CONSTS', 'eligibilityResponse', 'ToastNotificationService', 'GLOBAL_CONSTS'];

    function EligibilityResponseController($log, $state, $stateParams, $location, $anchorScroll, Eligibility, ELIGIBILITY_CONSTS, PolicyService, StatusStyles, Utility, STATUS_CONSTS, CacheDataService, BV_SELECTION_CONSTS, PAFormRequestModel, RxEligibilityService, ERROR_MESSAGES, PATIENT_CONSTS, eligibilityResponse, ToastNotificationService, GLOBAL_CONSTS) {

        var vm = this;

        // scope data
        vm.coverageResponse = null;
        vm.requestDrawerCollapsed = true;
        vm.requestId = null;
        vm.productDetails = null;
        vm.tooltipText = ELIGIBILITY_CONSTS.REQUEST_DETAILS_TOOLTIP_TEXT;
        vm.manualBvSubmitted = false;
        vm.manualBvSubmittedOpen = false;
        vm.manualBvError = false;
        vm.bvIncompleteStatus = false;

        vm.diagnosisCodeList = [];
        vm.diagnosisCode = ["Primary", "Secondary"];
        vm.locationName = null;
        vm.paLink = null;
        vm.paLinkLabel = ELIGIBILITY_CONSTS.PA_LINK_LABEL;
        vm.paFormLabel = ELIGIBILITY_CONSTS.PA_FORM_LABEL;

        vm.bvType = null;
        vm.patientFullName = null;
        vm.autoManualSectionDisplayable = false;
        vm.paFormError = false;

        // functions
        vm.rerunRequest = rerunRequest;
        vm.getPriorAuthorization = PolicyService.getPriorAuthorization;
        vm.getJCode = PolicyService.getJCode;
        vm.getCopayText = PolicyService.getCopayText;
        vm.getCoinsuranceText = PolicyService.getCoinsuranceText;
        vm.getDeductibleText = PolicyService.getDeductibleText;
        vm.getMOOPText = PolicyService.getMOOPText;
        vm.copayApplies = PolicyService.copayApplies;
        vm.coinsuranceApplies = PolicyService.coinsuranceApplies;
        vm.deductibleApplies = PolicyService.deductibleApplies;
        vm.moopApplies = PolicyService.moopApplies;
        vm.gotoPolicy = gotoPolicy;
        vm.setActiveStatusClass = StatusStyles.setActiveStatusClass;
        vm.setCoverageDetailsClass = StatusStyles.setCoverageDetailsClass;
        vm.getInsuranceStatus = getInsuranceStatus;
        vm.setDisplayTextIcon = setDisplayTextIcon;
        vm.setDisplayTextClass = StatusStyles.setDisplayTextClass;
        vm.manualBVCompletedMessage = ELIGIBILITY_CONSTS.MANUAL_BV_MESSAGE;
        vm.getDisplayValue = getDisplayValue;

        vm.getCobMessage = getCobMessage;

        vm.hasPaLink = hasPaLink;
        vm.hasPaButton = hasPaButton;
        vm.getPAForm = getPAForm;
        vm.isManualBvFormNotDisplayable = isManualBvFormNotDisplayable;
        vm.helpText = Eligibility.getMedBVResponseTooltip();
        vm.checkAutoManual = checkAutoManual;
        vm.showAutoMBV = $stateParams.showAutoMBV;
        vm.getGender = Utility.checkGender;
        vm.getDiagnosisTooltip = getDiagnosisTooltip;
        vm.showVerifyBvError = showVerifyBvError;
        vm.isManualAndComplete = RxEligibilityService.isManualAndComplete;
        vm.showMbvToast = showMbvToast;
        vm.setSobErrorMessage = setSobErrorMessage;
        vm.callbackPaForm = callbackPaForm;
        vm.note = ELIGIBILITY_CONSTS.NOTE;
        vm.addedNote = ELIGIBILITY_CONSTS.ADDITIONAL_NOTE;

        function init() {
            vm.coverageResponse = eligibilityResponse;

            initializePageData();
        }

        function _setToastMessageForPatient() {
            if(!vm.isPatientActive) {
                _setToastMessage(
                    PATIENT_CONSTS.DANGER_TOAST_MESSAGE.PATIENT_INACTIVE_HEADER,
                    PATIENT_CONSTS.DANGER_TOAST_MESSAGE.PATIENT_INACTIVE_MESSAGE,
                    GLOBAL_CONSTS.TOAST_TYPES.ERROR);
            }
        }

        function rerunRequest() {
            Eligibility.reverifyCoverage(vm.patient.patientId, BV_SELECTION_CONSTS.METHOD_NAME.MEDICAL);
        }

        init();

        /**
         * @ngdoc function
         * @name handleTransactionMessages
         * @methodOf eAccess.ProviderPortal.controller:EligibilityResponseController
         * @description
         *
         * If the policy object has a transaction object that is not null, show the request details and hide the policy drawer.
         */
        function handleTransactionMessages(policy) {
            if(policy.transaction && policy.transaction.transactionErrorMessage) {
                vm.selectedDrawer = null;
            }
        }

        function gotoPolicy(policyIndex) {
            $location.hash('policy-' + policyIndex);
            vm.selectedDrawer = policyIndex;
            $anchorScroll();
        }

        /**
         * Return the cob message for the eBV response
         */
        function getCobMessage() {
            // safety check
            if(Utility.isEmpty(vm.coverageResponse)) {
                return '';
            }

            return vm.coverageResponse.cobMessage;
        }

        function hasPaButton(policy) {
            return PolicyService.getPAFormId(policy);
        }

        function hasPaLink(policy) {
            return PolicyService.getPALink(policy);
        }

        function getPAForm(policy) {
            var formId = PolicyService.getPAFormId(policy);

            // We're assigning vm.requestId to responseId property because
            // vm.requestId holds the responseId property that we get from response
            var paFormRequest = new PAFormRequestModel({
                adjudicationMethod: BV_SELECTION_CONSTS.METHOD_NAME.MEDICAL,
                bvType: vm.bvType,
                responseId: vm.requestId,
                formTemplateId: formId,
                policyRank: policy.rank
            });
            vm.callbackPaForm(PolicyService.getPAForm(paFormRequest));
        }

        function callbackPaForm(paFormResponse) {
            vm.paFormError = false;
            paFormResponse.then(function(response) {
                var data = Utility.base64ToArrayBuffer(response);
                Utility.openPdf(data);
            }).catch(function(err) {
                vm.paFormError = true;
                _setToastMessage(ELIGIBILITY_CONSTS.PA_FORM_MESSAGE.ERROR.HEADER,
                    ELIGIBILITY_CONSTS.PA_FORM_MESSAGE.ERROR.MESSAGE,
                    GLOBAL_CONSTS.TOAST_TYPES.ERROR);
                $log.error('Unable to open PDF', err);
            });

        }


        function isManualBvFormNotDisplayable() {
            if(vm.manualBvSubmitted && !vm.manualBvError) {
                vm.bvType = ELIGIBILITY_CONSTS.BV_TYPE.MANUAL;
                vm.bvStatus = STATUS_CONSTS.STATUS.INCOMPLETE;
            }
            return RxEligibilityService.isManualBv(vm.bvType) && !Utility.isEmpty(vm.bvStatus) && (Utility.lowerCase(vm.bvStatus) === STATUS_CONSTS.STATUS.COMPLETE || Utility.lowerCase(vm.bvStatus) === STATUS_CONSTS.STATUS.INCOMPLETE);
        }

        function checkAutoManual() {
            // If autoManual flag is true then display success toast
            if(vm.coverageResponse.autoManual && Utility.lowerCase(vm.bvStatus) === STATUS_CONSTS.STATUS.INCOMPLETE ) {
                vm.autoManualSectionDisplayable = true;
                vm.requestDrawerCollapsed = false;
                if(vm.showAutoMBV) {
                    _setToastMessage(ELIGIBILITY_CONSTS.AUTO_MED_MBV_RESPONSE.SUCCESS.TOAST.HEADER,
                        ELIGIBILITY_CONSTS.AUTO_MED_MBV_RESPONSE.SUCCESS.TOAST.MESSAGE,
                        GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
                }

            } else {    // Otherwise check if there exists errorMessage, if so then display error toast
                if(!Utility.isEmpty(vm.coverageResponse.warnings)) {
                    vm.autoManualSectionDisplayable = true;
                    vm.requestDrawerCollapsed = false;
                    if(vm.showAutoMBV) {
                        _setToastMessage(ELIGIBILITY_CONSTS.AUTO_MED_MBV_RESPONSE.ERROR.TOAST.HEADER,
                            Utility.getAutoMbvWarningMessage(ELIGIBILITY_CONSTS.AUTO_MED_MBV_RESPONSE.ERROR.WARNING_CODE, vm.coverageResponse.warnings),
                            GLOBAL_CONSTS.TOAST_TYPES.ERROR);
                    }
                }
            }
        }

        function getDiagnosisTooltip(diagnosisType) {
            switch(Utility.lowerCase(diagnosisType)) {
                case ELIGIBILITY_CONSTS.DIAGNOSIS_INFORMATION.PRIMARY:
                    return vm.tooltip.primaryDiagnosis;
                case ELIGIBILITY_CONSTS.DIAGNOSIS_INFORMATION.SECONDARY:
                    return vm.tooltip.secondaryDiagnosis;
                default:
                    return null;
            }
        }

        function showVerifyBvError(errorMessageSettings) {
            _setToastMessage(errorMessageSettings.title,
                errorMessageSettings.body,
                errorMessageSettings.type);

        }

        function showMbvToast(displaySettings) {
            if(displaySettings.manualBvSubmitted && displaySettings.manualBvSubmittedOpen) {
                if(displaySettings.manualBvError) {
                    _setToastMessage(ELIGIBILITY_CONSTS.MBV_ERROR_HEADER_MESSAGE,
                        ELIGIBILITY_CONSTS.MBV_ERROR_MESSAGE,
                        GLOBAL_CONSTS.TOAST_TYPES.ERROR);
                } else {
                    _setToastMessage(ELIGIBILITY_CONSTS.MBV_SUCCESS_HEADER_MESSAGE,
                        ELIGIBILITY_CONSTS.MBV_SUCCESS_BODY_MESSAGE,
                        GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
                    Utility.getById.call(Eligibility.fetchResponseById($stateParams.responseId, $stateParams.adjudicationType), 'bvHistory')
                        .then(function(response) {
                            vm.coverageResponse = response;
                            initializePageData();
                        });
                }
            }
        }

        function setSobErrorMessage(errorMessage) {
            _setToastMessage(ERROR_MESSAGES.SOB_ERROR.TITLE,
                errorMessage,
                GLOBAL_CONSTS.TOAST_TYPES.ERROR);
        }

        function _setToastMessage(header, message, toastType) {
            ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, header, message, toastType));
            $anchorScroll();
        }

        function getInsuranceStatus(policy) {
            return Eligibility.getInsuranceStatus(policy, vm.coverageResponse.bvType, vm.coverageResponse.status);
        }

        function getDisplayValue(value, returnValue) {
            return Eligibility.getDisplayValue(value, returnValue, vm.coverageResponse.bvType, vm.coverageResponse.status);
        }

        function setDisplayTextIcon(statusText) {
            return Eligibility.setDisplayTextIcon(statusText, vm.coverageResponse.bvType, vm.coverageResponse.status);
        }

        function initializePageData() {
            vm.tooltip = Eligibility.getTooltip();
            if($stateParams.status && Utility.lowerCase($stateParams.status) === STATUS_CONSTS.STATUS.INCOMPLETE) {
                vm.bvIncompleteStatus = true;
            }

            vm.selectedDrawer = 0;

            if(!Utility.isEmpty(vm.coverageResponse)) {
                if(!Utility.isEmpty(vm.coverageResponse.request)) {
                    vm.request = vm.coverageResponse.request;

                    vm.productDetails = vm.request.productDetails;
                    if(vm.request.service) {
                        vm.serviceDeliveryInformation = vm.request.service;
                        if(vm.request.service.diagnosisCodeList) vm.diagnosisCodeList = vm.request.service.diagnosisCodeList;
                    }
                    vm.provider = vm.request.provider;
                    if(vm.request.patient) {
                        vm.patient = vm.request.patient;
                        vm.patient.gender = Utility.checkGender(vm.patient.gender);
                        vm.patientFullName = Utility.getFullName({
                            firstName: vm.patient.firstName,
                            middleName: vm.patient.middleName,
                            lastName: vm.patient.lastName
                        });
                    }
                    if(vm.request.location) vm.locationName = vm.request.location.name || vm.request.location.locationName;
                }
                if(!Utility.isEmpty(vm.coverageResponse.policies)) {
                    vm.insurancePolicyList = vm.coverageResponse.policies;
                    vm.coverageResponse.policies.forEach(handleTransactionMessages);
                }

                if(!Utility.isEmpty(vm.coverageResponse.responseDate)) vm.responseDate = vm.coverageResponse.responseDate;
                vm.requestId = vm.coverageResponse.responseId;

                // we will not get bvType in medEligibility verify benefit
                // so we are passing bvType in the stateParam if we are redirecting from medEligibility state to eligibilityResponse state
                if(!Utility.isEmpty($stateParams.bvType)) {
                    vm.bvType = $stateParams.bvType;
                }
                if(!Utility.isEmpty(vm.coverageResponse.bvType)) {
                    vm.bvType = vm.coverageResponse.bvType;
                }
                if(!Utility.isEmpty(vm.coverageResponse.bvAdjudicationType)) {
                    vm.adjudicationMethod = vm.coverageResponse.bvAdjudicationType;
                }
                if(!Utility.isEmpty(vm.coverageResponse.mbvId)) {
                    vm.mbvId = vm.coverageResponse.mbvId;
                }
                vm.bvStatus = vm.coverageResponse.status;
                if(vm.coverageResponse.policies && vm.coverageResponse.policies[0] && vm.coverageResponse.policies[0].plan) {
                    vm.coverageStatus = vm.coverageResponse.policies[0].plan.coverageStatus;
                }

                vm.isPatientActive = !(!Utility.isEmpty(vm.coverageResponse.patientActive) && vm.coverageResponse.patientActive === false);
            }
            vm.checkAutoManual();
            _setToastMessageForPatient();
        }

    }
})();
