(function() {
    'use strict';

    var eaPolicyCoverage = {
        bindings: {
            insurancePolicies: '<',
            manualBvSubmitted: '=',
            requestDate: '<',
            productDetails: '<',
            responseDate: '<',
            request: '<',
            requestDetailsExpanded: '=',
            coverageResponse: '<',
            selectedDrawer: '=',
            adjudicationMethod: '<',
            getDisplayValue: '<'
        },
        templateUrl: 'components/eligibility/bv-selection/rx/rx-response/policy-coverage/policy-coverage.html',
        controller: 'PolicyCoverageController as policyCoverageCtrl'
    };

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .component('eaPolicyCoverage', eaPolicyCoverage);
})();
