/**
 * @ngdoc service
 * @name eAccess.ProviderPortal.Components.Register.service:RegisterView
 * @author Nikesh Maharjan
 * @description
 *
 * This is the service for view functions that apply to all register views.
 **/

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Register')
        .factory('RegisterView', RegisterView);

    RegisterView.$inject = [];

    function RegisterView() {

        var DEFAULTS = {
            LINK_TEXT: 'Home',
            LINK_HREF: '#'
        };

        var service = {
            configure: configure,
            setPageTitle: setPageTitle,
            setHomeLink: setHomeLink
        };

        return service;

        /**
         * @ngdoc function
         * @name configure
         * @methodOf eAccess.ProviderPortal.Components.Register.service:RegisterView
         * @description
         *
         * Configures all info for Register view based on passed config object.
         *
         * @param config
         * @example configure({
         *     title: '',
         *     linkText: '',
         *     linkHref: '',
         * })
         */
        function configure(config) {
            setPageTitle(config.title);
            setHomeLink(config.linkText, config.linkHref);
        }

        /**
         * @ngdoc function
         * @name setPageTitle
         * @methodOf eAccess.ProviderPortal.Components.Register.service:RegisterView
         * @description
         *
         * Sets the page title on the register view.
         *
         * @param title
         */
        function setPageTitle(title) {
            service.pageTitle = title || null;
        }

        /**
         * @ngdoc function
         * @name setHomeLink
         * @methodOf eAccess.ProviderPortal.Components.Register.service:RegisterView
         * @description
         *
         * Sets the text and href for the page link on the register view.
         *
         * @param linkText
         * @param linkHref
         */
        function setHomeLink(linkText, linkHref) {
            service.homeLink = {
                text: linkText || DEFAULTS.LINK_TEXT,
                href: linkHref || DEFAULTS.LINK_HREF
            };
        }
    }

})();
