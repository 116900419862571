/**
 * Created by nikesh on 10/11/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Breadcrumb')
        .controller('BreadcrumbController', BreadcrumbController);

    BreadcrumbController.$inject = ['$log'];

    function BreadcrumbController($log) {

        var vm = this;


        // functions
        vm.getCrumbClass = getCrumbClass;

        vm.crumbClicked = crumbClicked;


        function getCrumbClass(index) {
            if(vm.currentStep > (index + 1)) {
                return 'visited';
            } else if(vm.currentStep === (index + 1)) {
                return 'current';
            } else {
                return '';
            }
        }

        function crumbClicked(item, index) {
            var clickedOnStep = (index + 1);
            if(vm.currentStep > clickedOnStep && vm.callOnClick) {
                vm.callOnClick(item, clickedOnStep);
            }
        }
    }

})();
