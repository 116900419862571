(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .controller('PracticeLocationViewController', PracticeLocationViewController);

    PracticeLocationViewController.$inject = ['$log', '$stateParams', 'PracticeView', 'PracticeLocationService', 'HELP_TEXT_CONSTS', 'ERROR_MESSAGES', '$state', 'locationResponse'];

    function PracticeLocationViewController($log, $stateParams, PracticeView, PracticeLocationService, HELP_TEXT_CONSTS, ERROR_MESSAGES, $state, locationResponse) {
        var vm = this;

        // data
        vm.location = {};
        vm.isAddressDisplayable = false;
        vm.helpText = {
            npiNumber: HELP_TEXT_CONSTS.PRACTICE_LOCATION.NPI,
            taxIdNumber: HELP_TEXT_CONSTS.PRACTICE_LOCATION.TAX_ID,
            practiceLocationId: HELP_TEXT_CONSTS.PRACTICE_LOCATION.PRACTICE_LOCATION_ID
        };
        vm.NOT_AVAILABLE = ERROR_MESSAGES.NOT_AVAILABLE;

        vm.$onInit = function() {
            prepareLocationData(locationResponse);
        };

        function prepareLocationData(location) {
            vm.location = location;
            // Configure common view elements
            PracticeView.configure({
                title: 'My Practice | Locations',
                contentTitle: location.locationName,
                tabIndex: 1,
                editText: 'edit',
                editHref: 'practice.locations.edit',
                editId: vm.location.locationId,
                editViewableStatus: vm.location.active
            });

            // Set 'isAddressDisplayable' property
            vm.isAddressDisplayable = vm.location.address1 || vm.location.city || vm.location.zipCode;
        }
    }
})();
