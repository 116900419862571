/**
 * Created by nikesh on 8/25/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .controller('PracticeLocationEditController', PracticeLocationAddController);

    PracticeLocationAddController.$inject = ['$log', '$state', 'PracticeView', 'PracticeLocationService', 'PRACTICE_CONSTS', '$stateParams', 'PracticeLocationModel', 'INPUT_FORM_VALIDATION', 'ToastNotificationService', 'GLOBAL_CONSTS', '$anchorScroll', 'PracticeSetupAddService'];

    function PracticeLocationAddController($log, $state, PracticeView, PracticeLocationService, PRACTICE_CONSTS, $stateParams, PracticeLocationModel, INPUT_FORM_VALIDATION, ToastNotificationService, GLOBAL_CONSTS, $anchorScroll, PracticeSetupAddService) {
        var vm = this;

        // data
        vm.location = {};
        vm.btnText = 'Save';
        // 'id' is a generic property provided for Edit link value by practice view service
        // that'll be used as state param while redirecting on click
        vm.locationId = $stateParams.id;

        vm.savingPracticeLocation = false;

        // functions
        vm.updatePracticeLocation = updatePracticeLocation;
        vm.loadPracticeLocation = loadPracticeLocation;

        vm.$onInit = function() {
            PracticeView.configure({
                title: 'My Practice | Locations',
                contentTitle: 'Edit Location',
                tabIndex: 1
            });
            loadPracticeLocation();
        };


        function loadPracticeLocation() {
            PracticeLocationService.getPracticeLocation(vm.locationId)
                .then(function(location) {
                    vm.location = new PracticeLocationModel(location);
                })
                .catch(function(error) {
                    $log.debug('error', error);
                });
        }

        function updatePracticeLocation() {
            // reset practiceLocationName(i.e name) validity
            vm.practiceLocationForm.locationName.$setValidity(INPUT_FORM_VALIDATION.VALIDATE.UNIQUE, true);
            vm.practiceLocationForm.practiceLocationId.$setValidity(INPUT_FORM_VALIDATION.VALIDATE.UNIQUE, true);
            vm.practiceLocationForm.npiNumber.$setValidity(INPUT_FORM_VALIDATION.VALIDATE.NPI_TYPE_2, true);

            // bail if the form is invalid
            if(vm.practiceLocationForm.$invalid) {
                return false;
            }

            vm.savingPracticeLocation = true;

            PracticeLocationService.updatePracticeLocation(vm.location, vm.locationId)
                .then(function(response) {
                    vm.practiceLocationForm.$setPristine();
                    $state.go('practice.locations', {locationUpdated: true});
                    ToastNotificationService.setGlobalToastInfo(GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
                })
                .catch(function(error) {
                    var displayedError = 0;
                    if(error.data.message === PRACTICE_CONSTS.ERROR_MESSAGES.LOCATION_NAME_IS_NOT_UNIQUE) {
                        vm.practiceLocationForm.locationName.$setValidity(INPUT_FORM_VALIDATION.VALIDATE.UNIQUE, false);
                        displayedError = 1;
                    }

                    if(error.data.message === PRACTICE_CONSTS.ERROR_MESSAGES.LOCATION_ID_NOT_UNIQUE) {
                        vm.practiceLocationForm.practiceLocationId.$setValidity(INPUT_FORM_VALIDATION.VALIDATE.UNIQUE, false);
                        displayedError = 1;
                    }

                    if(PracticeSetupAddService.isNpiError(error)) {
                        vm.practiceLocationForm.npiNumber.$setValidity(INPUT_FORM_VALIDATION.VALIDATE.NPI_TYPE_2, false);
                        displayedError = 1;
                    }

                    if(!displayedError) {
                        ToastNotificationService.setGlobalToastInfo(error.status);
                    }
                })
                .finally(function() {
                    vm.savingPracticeLocation = false;
                    $anchorScroll();
                });
        }

    }
})();
