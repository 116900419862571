/**
 * Created by aayush.regmi on 10/12/2017.
 */
(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .config(router);

    router.$inject = ['$stateProvider'];

    function router($stateProvider) {
        $stateProvider
            .state('terms-and-conditions', {
                url: '/terms-and-conditions',
                data: {
                    authorities: [],
                    pageTitle: 'Register - User Agreement'
                },
                views: {
                    'content@': {
                        templateUrl: 'components/terms-and-conditions/terms-and-conditions.html',
                        controller: 'TermsAndConditionsController as terms'
                    }
                }
            });
    }
})();
