/**
 * Created by mcaputo on 1/17/17.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.InsurancePolicy')
        .factory('InsurancePolicy', InsurancePolicy);

    InsurancePolicy.$inject = [];

    function InsurancePolicy() {
        var _defaultSettings = {
            payer: null,
            memberId: null,
            subscriberPatient: null,
            providerOutOfNetwork: null,
            medicareMemberId: null,
            supplementalLetter: null
        };

        function InsurancePolicyInstance(options) {
            angular.extend(this, _defaultSettings, options);
            this.providerOutOfNetwork = this.providerOutOfNetwork || false;
        }

        return InsurancePolicyInstance;
    }

})();
