/**
 * Created by aayush.regmi on 10/9/2017.
 */
(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .config(router);

    router.$inject = ['$stateProvider'];

    function router($stateProvider) {
        $stateProvider
            .state('hipaa-baa', {
                url: '/hipaa-baa',
                data: {
                    authorities: [],
                    pageTitle: 'Register - HIPAA BAA'
                },
                views: {
                    'content@': {
                        templateUrl: 'components/hipaa-baa/hipaa.html',
                        controller: 'HipaaBaaController as hipaa'
                    }
                }
            });
    }
})();
