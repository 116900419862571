/**
 * Created by nikesh on 10/26/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Register')
        .factory('UserAgreementService', UserAgreementService);

    UserAgreementService.$inject = ['$log', 'REGISTER_CONSTS'];

    function UserAgreementService($log, REGISTER_CONSTS) {

        var genericConfig = {
            currentStep: 1,
            currentStepName: REGISTER_CONSTS.REGISTRATION_STEPS.USER_AGREEMENT
        };

        // Breadcrum conf for non hipaa config
        var nonHipaaConfig = {
            isHIPAAPresent: false,
            totalNoOfSteps: 2,
            currentStep: genericConfig.currentStep,
            currentStepName: genericConfig.currentStepName,
            stepNames: [REGISTER_CONSTS.REGISTRATION_STEPS.USER_AGREEMENT, REGISTER_CONSTS.REGISTRATION_STEPS.CREATE_USER_ACCOUNT]
        };
        var hipaaConfig = {
            isHIPAAPresent: true,
            totalNoOfSteps: 3,
            currentStep: genericConfig.currentStep,
            currentStepName: genericConfig.currentStepName,
            stepNames: [REGISTER_CONSTS.REGISTRATION_STEPS.USER_AGREEMENT, REGISTER_CONSTS.REGISTRATION_STEPS.HIPAA_BAA, REGISTER_CONSTS.REGISTRATION_STEPS.CREATE_USER_ACCOUNT],
            breadcrumbInfo: [
                {isActive: true, name: REGISTER_CONSTS.REGISTRATION_STEPS.USER_AGREEMENT},
                {isActive: false, name: REGISTER_CONSTS.REGISTRATION_STEPS.HIPAA_BAA},
                {isActive: false, name: REGISTER_CONSTS.REGISTRATION_STEPS.CREATE_USER_ACCOUNT}
            ]
        };

        var service = {
            getUserAgreementFlowConfig: getUserAgreementFlowConfig
        };

        return service;

        /**
         * @ngdoc function
         * @name getUserAgreementFlowConfig
         * @methodOf eAccess.ProviderPortal.Components.Register.service:UserAgreementService
         * @description
         *
         * Gets user agreement flow configuration.
         *
         * @param displayHipaa
         */
        function getUserAgreementFlowConfig(displayHipaa) {
            if(displayHipaa) {
                return angular.copy(hipaaConfig);
            } else {
                return angular.copy(nonHipaaConfig);
            }
        }
    }

})();
