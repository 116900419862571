(function() {
    'use strict';

    var eaCobHeader = {
        bindings: {
            cobMessage: '<'
        },
        templateUrl: 'components/cob-header/cob-header.html',
        controller: 'COBHeaderController as cob'
    };

    angular
        .module('eAccess.ProviderPortal.Components.COBHeader', [])
        .component('eaCobHeader', eaCobHeader);
})();
