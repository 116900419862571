/**
 * Created by mcaputo on 12/16/16.
 */
/**
 * Created by mcaputo on 12/14/16.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .factory('MockService', MockService);

    MockService.$inject = ['$rootScope', '$log', '$q', '$timeout'];

    function MockService ($rootScope, $log, $q, $timeout) {
        var ASYNC_TIMEOUT = 0;

        var service = {
            getRandomText: getRandomText,
            mockAsyncCall: mockAsyncCall
        };

        function getRandomText(textOptions) {
            var response = textOptions[Math.floor(Math.random() * textOptions.length)];
            return response;
        }

        function mockAsyncCall(response) {
            var deferred = $q.defer();

            $timeout(function() {
                deferred.resolve(angular.toJson(response));
            }, ASYNC_TIMEOUT);

            return deferred.promise;
        }

        return service;
    }

})();
