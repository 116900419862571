/* eslint-disable */
(function() {
    angular
        .module('eAccess.ProviderPortal', [
            'ui.router',
            'ui.bootstrap',
            'ui.mask',
            'ngResource',
            'ngMessages',
            'ngAnimate',
            'ngCookies',
            'ngSanitize',
            'angularMoment',
            'readMore',
            'tableSort',
            'eAccess.ProviderPortal.Constants.Global',
            'eAccess.ProviderPortal.Constants.ApplicationStartup',
            'eAccess.ProviderPortal.Components',
            'eAccess.ProviderPortal.Components.BVHistory',
            'eAccess.ProviderPortal.Components.Dashboard',
            'eAccess.ProviderPortal.Components.DocsStyleguide',
            'eAccess.ProviderPortal.Components.InsurancePolicy',
            'eAccess.ProviderPortal.Components.Patient',
            'eAccess.ProviderPortal.Components.Practice',
            'eAccess.ProviderPortal.Components.Reverifications',
            'eAccess.ProviderPortal.Components.ServiceDeliveryInformation',
            'eAccess.ProviderPortal.Services.ConfigurationService',
            'eAccess.ProviderPortal.Services.StorageService',
            'eAccess.ProviderPortal.Services.WarnOnLeaveService',
            'eAccess.ProviderPortal.Services.CannotDeactivateAll',
            'eAccess.ProviderPortal.Services.ebvResponseMapper',
            'eAccess.ProviderPortal.Services.ForbiddenAPI',
            'eAccess.ProviderPortal.Services.AllowedState',
            'eAccess.ProviderPortal.Services.SystemNotification',
            'eAccess.ProviderPortal.Services.ReactivateDeactivate',
            'eAccess.ProviderPortal.Directives',
            'eAccess.ProviderPortal.Filters',
            'eAccess.ProviderPortal.Components.ToastNotification',
            'eAccess.ProviderPortal.Components.Register',
            'eAccess.ProviderPortal.Services.ConfigurationService',
            'eAccess.ProviderPortal.Services.ApplicationStartup',
            'eAccess.ProviderPortal.Components.Breadcrumb',
            'eAccess.ProviderPortal.Components.TermsAndConditions',
            'eAccess.ProviderPortal.Components.HipaaBaa',
            'eAccess.ProviderPortal.Components.InitialUserInformation',
            'eAccess.ProviderPortal.Components.PracticeSetup',
            'eAccess.ProviderPortal.Components.LinkExpired',
            'eAccess.ProviderPortal.Components.LocationForm',
            'eAccess.ProviderPortal.Components.ProviderForm',
            'eAccess.ProviderPortal.Components.ChangePassword',
            'eAccess.ProviderPortal.Components.PasswordExpired',
            'config'
        ])
        .config(appConfig)
        .run(appRun);

    appConfig.$inject = ['$urlRouterProvider'];

    function appConfig($urlRouterProvider) {
        $urlRouterProvider.deferIntercept();
    }

    appRun.$inject = ['$rootScope', '$log', '$state', 'ApplicationStartup'];

    function appRun($rootScope, $log, $state, ApplicationStartup) {
        $log.debug('Bootstrapping app');

        ApplicationStartup.setApplicationStyles();
       
        // global options for ui-mask
        $rootScope.maskOptions = {
            clearOnBlur: false,
            allowInvalidValue: true
        };

        // expose $state
        $rootScope.$state = $state;

       
        /**
         * url check is done on $locationChangeStart 
         * this will provide us the url before the application get its state
         * were as when application will already have its state when $stateChangeStart is triggered
         */
        $rootScope.$on('$locationChangeStart', function(event, next){
            // check if state url is ending with '/'
            ApplicationStartup.removeTrailingSlashFromUrl(next);
        });

        // On state change start, check auth
        var deregisterStart = $rootScope.$on('$stateChangeStart', function(event, toState, toStateParams, fromState, fromParams, $stateParams) {
            ApplicationStartup.deregisterStartCallback(toState, fromState, $stateParams, event);
        });

        // On state change success
        var deregisterSuccess = $rootScope.$on('$stateChangeSuccess', function(event, toState) {
            ApplicationStartup.deRegisterCallBack(event , toState)
        });
        $rootScope.$on('$destroy', deregisterSuccess);
        $rootScope.$on('$destroy', deregisterStart);
    }

  
})();
