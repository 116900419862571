(function() {
    'use strict';

    var VALUES = {
        VALIDATE: {
            UNIQUE: 'unique',
            NPI_TYPE_2: 'npiType2'
        },
        PHONE_NUMBER_VALIDATION_MESSAGE: "A valid 10 digit phone number is required."
    };

    angular.module('eAccess.ProviderPortal.Constants.InputFormValidation', []).constant('INPUT_FORM_VALIDATION', VALUES);
})();

