/**
 * Created by nikesh on 9/12/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Services.Dashboard', [])
        .factory('DashboardService', DashboardService);

    DashboardService.$inject = ['$q', '$log', 'REGISTRATION_CONSTS', 'ConfigurationService', 'Utility'];

    function DashboardService($q, $log, REGISTRATION_CONSTS, ConfigurationService, Utility) {
        var service = {
            consentDisplay: false,
            consentMessage: "",
            consentInitialize: false,
            fetchUsefulLinks: fetchUsefulLinks,
            isConsentDisplay: isConsentDisplay,
            getConsentMessage: getConsentMessage
        };

        function isConsentDisplay() {
            var deferred = $q.defer();
            if(service.consentInitialize) {
                deferred.resolve(service.consentDisplay);
            } else {
                ConfigurationService.fetchPortalConfiguration().then(function(response) {
                    if(!Utility.isEmpty(response) && !Utility.isEmpty(response.orgPortalEntity)) {
                        setConsentData(response.orgPortalEntity);
                    }
                    deferred.resolve(service.consentDisplay);
                });
            }
            return deferred.promise;
        }

        function setConsentData(response) {
            try {
                if(response.complianceMethod.complianceCode === REGISTRATION_CONSTS.COMPLIANCE_METHOD.PATIENT_CONSENT) {
                    service.consentDisplay = true;
                    service.consentMessage = response.patientConsentText;
                } else {
                    service.consentDisplay = false;
                    service.consentMessage = "";
                }
                service.consentInitialize = true;
            } catch(error) {
                service.consentDisplay = false;
                service.consentMessage = "";
                $log.error("Error getting Compliance Method: ", error);
            }
        }

        function getConsentMessage() {
            var deferred = $q.defer();
            if(service.consentInitialize) {
                deferred.resolve(service.consentMessage);
            } else {
                ConfigurationService.fetchPortalConfiguration().then(function(response) {
                    if(!Utility.isEmpty(response) && !Utility.isEmpty(response.orgPortalEntity)) {
                        setConsentData(response.orgPortalEntity);
                    }
                    deferred.resolve(service.consentMessage);
                });
            }
            return deferred.promise;
        }

        function fetchUsefulLinks() {
            var deferred = $q.defer();
            ConfigurationService.fetchPortalConfiguration().then(function(response) {
                var usefulLinks = [];
                if(!Utility.isEmpty(response) && !Utility.isEmpty(response.orgPortalEntity)) {
                    // Can have maximum of only four links
                    usefulLinks.push(_getLinkObject(response.orgPortalEntity.dashboardLink1Label, response.orgPortalEntity.dashboardLink1Url));
                    usefulLinks.push(_getLinkObject(response.orgPortalEntity.dashboardLink2Label, response.orgPortalEntity.dashboardLink2Url));
                    usefulLinks.push(_getLinkObject(response.orgPortalEntity.dashboardLink3Label, response.orgPortalEntity.dashboardLink3Url));
                    usefulLinks.push(_getLinkObject(response.orgPortalEntity.dashboardLink4Label, response.orgPortalEntity.dashboardLink4Url));
                }

                deferred.resolve(usefulLinks);
            });

            return deferred.promise;
        }

        function _getLinkObject(label, url) {
            return {label: label || null, url: url || null};
        }

        return service;
    }
})();
