(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .controller('AccountLockedController', AccountLockedController);

    AccountLockedController.$inject = ['$log', 'ACCOUNT_LOCKED_CONSTS', 'ConfigurationService', 'GLOBAL_CONSTS', '$stateParams', 'EmailService', 'Utility'];

    function AccountLockedController($log, ACCOUNT_LOCKED_CONSTS, ConfigurationService, GLOBAL_CONSTS, $stateParams, EmailService, Utility) {

        var vm = this;

        vm.alert = ACCOUNT_LOCKED_CONSTS.ALERT_MESSAGE;
        vm.information = ACCOUNT_LOCKED_CONSTS.INFORMATION;
        vm.additionalMessage = GLOBAL_CONSTS.EMAIL.USER_NOTIFICATION;
        vm.emailResendMessage = GLOBAL_CONSTS.EMAIL.RESENT_MESSAGE;
        // Flag to indicate if email has resent
        vm.isResent = false;

        vm.resendEmail = resendEmail;

        init();

        function init() {
            getCustomerSupport();
        }

        function getCustomerSupport() {
            ConfigurationService.fetchRegistrationConfiguration()
                .then(function(response) {
                    vm.customerSupport = response.customerSupport;
                })
                .catch(function(err) {
                    vm.customerSupport = '';
                });
        }

        function resendEmail() {
            // Safe check needed only if page is accessed directly from entering url:
            // Since account locked page will be trigger when user tries to login using his/her username (locked).
            // We know that $stateParams.accountLockedResendEmailObject will not be null for normal flow but if
            // accessed directly by entering url it will be null.
            // Please refer to login.controller.js function _prepareEmailRequestObject for
            // accountLockedResendEmailObject creation logic
            if(!Utility.isEmpty($stateParams.accountLockedResendEmailObject)) {
                EmailService.sendEmail($stateParams.accountLockedResendEmailObject).then(function(response) {
                    vm.isResent = true;
                }).catch(function(err) {
                    vm.isResent = false;
                });
            }
        }
    }

})();
