/**
 * Created by aayush.regmi on 10/10/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.HipaaBaa')
        .factory('HipaaBaaService', HipaaBaaService);

    HipaaBaaService.$inject = ['$q', '$log', '$http', '$window', 'ENV'];

    function HipaaBaaService($q, $log, $http, $window, ENV) {

        var service = {
            getHipaaConfiguration: getHipaaConfiguration
        };

        function getHipaaConfiguration() {
            var deferred = $q.defer();
            var requestConfig = {
                headers: {
                    'host_name': $window.location.hostname
                }
            };
            $http.get(ENV.API_URL + 'portals/registration', requestConfig)
                .then(function(response) {
                    return deferred.resolve(response.data);
                })
                .catch(function(err) {
                    return deferred.reject(err);
                });
            return deferred.promise;
        }

        return service;
    }
})();
