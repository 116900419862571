// NOTE: Any changes made in this constant should reflect in allowed-state.service.js as well
(function() {
    'use strict';

    var VALUES = {
        DASHBOARD: 'dashboard',
        MED_ELIGIBILITY: 'medEligibility',
        ELIGIBILITY_RESPONSE: 'eligibilityResponse',
        RX_ELIGIBILITY: 'rxEligibility',
        RX_ELIGIBILITY_RESPONSE: 'RxEligibilityResponse',
        RX_ELIGIBILITY_SPLIT_RESPONSE: 'RxEligibilitySplitResponse',
        RX_DATA_CONFLICT: 'rxDataConflict',
        BV_SELECTION: 'bvSelection',
        BV_HISTORY: 'bvHistory',
        REVERIFICATION: 'reverifications',
        PATIENT_ALL: 'patient.all',
        PRACTICE_INFORMATION: 'practice.information',
        ADD_LOCATION: 'practice.locations.add',
        EDIT_LOCATION: 'practice.locations.edit',
        LIST_LOCATION: 'practice.locations',
        VIEW_LOCATION: 'practice.locations.view',
        ADD_PROVIDER: 'practice.providers.add',
        EDIT_PROVIDER: 'practice.providers.edit',
        VIEW_PROVIDER: 'practice.providers.view',
        LIST_PROVIDER: 'practice.providers',
        ADD_PATIENT: 'patient.add',
        PATIENT_PROFILE: 'patient.profile',
        USER_VIEW: 'practice.users.view',
        CHANGE_PASSWORD: 'changePassword',
        USER_EDIT: 'practice.users.edit',
        LOGIN: 'login'
    };

    angular.module('eAccess.ProviderPortal.Constants.AllowedState', []).constant('ALLOWED_STATE_CONSTS', VALUES);
})();

