(function() {
    'use strict';

    var VALUES = {
        URLS : {
            COMPLETE: '/complete',
            DASHBOARD: '/',
            LOGIN: '/login',
            BLANK: '^'
        },
        PUBLIC_URLS: ['/login', '/code', '/register', '/user-agreement', '/terms-and-conditions', '/hipaa-baa', '/link/verify?token', '/set-password', '/link-expired', '/account-locked', '/forgotPassword', '/passwordExpired', '/change/link/verify?token', '/changePassword'],
        REGISTRATION_URLS: ['/practice-setup', '/welcome', '/add', '/complete'],
        DEFAULT_TITLE: 'VirMedica Portal',
        PORTAL_STYLES: {
            DEFAULT_FILE_PATH: './css/main.css',
            TYPE: 'text/css',
            REL: 'stylesheet'
        }
    };

    angular.module('eAccess.ProviderPortal.Constants.ApplicationStartup', []).constant('APPLICATION_STARTUP_CONSTS', VALUES);
})();


