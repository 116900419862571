/**
 * Created by nikesh on 11/3/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.AddProvider')
        .component('eaAddProvider', {
            templateUrl: 'components/add-provider/add-provider.html',
            controller: 'AddProviderController as addProvider',
            bindings: {
                parentForm: '=',
                provider: '=',
                nextCallback: '=',
                previousCallback: '=',
                savingPractice: '<'
            }
        });

})();
