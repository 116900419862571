/**
 * @ngdoc Controller
 * @module eAccess.ProviderPortal.Components.RxEligibilitySplitResponse
 * @name CoverageSummaryController
 * @kind Controller
 *
 * @description
 * Controller for the coverage summary section
 * @author Bikash Shrestha
 */

(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilitySplitResponse')
        .controller('CoverageSummaryController', CoverageSummary);

    CoverageSummary.$inject = ['StatusStyles', 'ELIGIBILITY_CONSTS', 'Utility', 'ConfigurationService', 'STATUS_CONSTS', 'CoverageSummaryService'];

    function CoverageSummary(StatusStyles, ELIGIBILITY_CONSTS, Utility, ConfigurationService, STATUS_CONSTS, CoverageSummaryService) {
        var vm = this;
        vm.coverageSummary = {};
        vm.retail = {};
        vm.setProductCoverageStatus = StatusStyles.setProductCoverageStatus;
        vm.coverageSummaryExpanded = true;
        vm.matchesPattern = false;

        vm.toggleCoverageSummary = toggleCoverageSummary;
        vm.setCoverageSectionTitle = setCoverageSectionTitle;
        vm.setCoverageSummaryRetail = setCoverageSummaryRetail;
        vm.isValueEmpty = Utility.isBlank;


        vm.$onInit = function () {
            vm.coverageSummary = vm.policy.coverageSummary;

            vm.phaseMessageExist = !Utility.isBlank(vm.coverageSummary.coveragePhaseMessage);
            vm.matchesPattern = vm.phaseMessageExist && vm.coverageSummary.coveragePhaseMessage.match(ELIGIBILITY_CONSTS.COVERAGE_PHASE_MESSAGE_PATTERN);
            vm.drugCoverageMessage = !vm.matchesPattern && vm.policy.drugCoverageLimitationMessage && vm.policy.drugCoverageLimitationMessage.length > 0;
            vm.displaySingleCostShareOnly =  Utility.isTruthy(ConfigurationService.getOrganizationProperty('displaySingleCostShareOnly') || 0);
            vm.setCoverageSectionTitle();
            vm.setCoverageSummaryRetail();
        };

        function toggleCoverageSummary() {
            vm.coverageSummaryExpanded = !vm.coverageSummaryExpanded;
        }

        function setCoverageSectionTitle() {
            vm.coverageSectionTitle = vm.displaySingleCostShareOnly ? ELIGIBILITY_CONSTS.SECTION_TITLE.PHARMACY_COVERAGE_SUMMARY : ELIGIBILITY_CONSTS.SECTION_TITLE.COVERAGE_SUMMARY;
        }
        function setCoverageSummaryRetail() {
            if(!vm.displaySingleCostShareOnly) { return false; }

            var validRetail = CoverageSummaryService.getValidRetail(vm.coverageSummary);
            vm.retail = vm.coverageSummary[validRetail];
        }
    }


})();
