/**
 * @ngdoc Controller
 * @module eAccess.ProviderPortal.Components.RxEligibilityResponse
 * @name RequestDetailsController
 * @kind Controller
 *
 * @description
 * Controller for the request details
 * @author girianish
 */

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .controller('RequestDetailsController', RequestDetails);

    RequestDetails.$inject = ['CacheDataService', 'Utility', 'Eligibility', 'BV_SELECTION_CONSTS'];

    function RequestDetails(CacheDataService, Utility, Eligibility, BV_SELECTION_CONSTS) {
        var vm = this;

        vm.rerunRequest = rerunRequest;

        vm.$onInit = function() {
            if(!Utility.isEmpty(vm.locationInformation) && !Utility.isEmpty(vm.locationInformation.locationName)) vm.locationName = vm.locationInformation.locationName;
        };

        function rerunRequest() {
            Eligibility.reverifyCoverage(vm.patientInformation.patientId, BV_SELECTION_CONSTS.METHOD_NAME.RX);
        }
    }


})();
