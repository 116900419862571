(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.SOB')
        .component('eaSob', {
            templateUrl: 'components/sob/sob.html',
            controller: 'SOBController as sobCtrl',
            bindings: {
                bvType: '<',
                bvStatus: '<',
                coverageStatus: '<',
                // Object that contains sobIdentifier object
                documentIdentifiers: '<',
                mbvId: '<',
                bvAdjudicationType: '<', // Medical or Rx
                sobErrorMessageCallback: '<' // call back function for set sob error message
            }
        });

})();
