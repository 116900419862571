(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .controller('PolicyCoverageController', PolicyCoverageController);

    PolicyCoverageController.$inject = ['$log', 'StatusStyles', 'STATUS_CONSTS', 'Utility', 'PolicyPlanDetailsModalService', '$location', '$anchorScroll', 'ELIGIBILITY_CONSTS', 'RxEligibilityService'];

    function PolicyCoverageController($log, StatusStyles, STATUS_CONSTS, Utility, PolicyPlanDetailsModalService, $location, $anchorScroll, ELIGIBILITY_CONSTS, RxEligibilityService) {

        var vm = this;

        vm.selectedDrawer = null;
        vm.disclaimerContent = '';
        vm.selectPolicyDrawer = selectPolicyDrawer;
        vm.setActiveStatusClass = setActiveStatusClass;
        vm.setIconClass = StatusStyles.setIconClass;
        vm.setCoverageDetaidlsClass = StatusStyles.setCoverageDetailsClass;
        vm.setProductCoverageClass = StatusStyles.setProductCoverageStatus;
        vm.setDisplayTextIcon = StatusStyles.setDisplayTextIcon;
        vm.setDisplayTextClass = StatusStyles.setDisplayTextClass;
        vm.isUndeterminedOrInactive = isUndeterminedOrInactive;
        vm.getAddress = Utility.getAddress;
        vm.isNotCovered = isNotCovered;
        vm.showPolicyDetailModal = showPolicyDetailModal;
        vm.displayTransactionMessage = RxEligibilityService.displayTransactionMessage;
        vm.gotoContent = RxEligibilityService.gotoContent;
        vm.costShareSectionDisplayable = costShareSectionDisplayable;
        vm.checkProdCoverageStatus = checkProdCoverageStatus;
        vm.transactionMessageDisplayed = false;

        vm.toolTip = RxEligibilityService.getPolicyCoverageToolTip();
        vm.getTrimmedString = Utility.getTrimmedString;

        vm.$onInit = function() {
            // firstIndex is set to null in order the collapse the detail section in default
            var firstIndex = null;
            vm.selectedDrawer = firstIndex;
            vm.transactionMessageDisplayed = vm.displayTransactionMessage(vm.insurancePolicies);
            // Expand request details section if conditions are met
            // Added as part of EP-1831
            if(vm.transactionMessageDisplayed && vm.insurancePolicies.length > 1) {
                vm.requestDetailsExpanded = true;
            }

            // Prepare disclaimer content with response date.
            vm.disclaimerContent = RxEligibilityService.getFormattedDisclaimerContent(vm.responseDate);
        };

        function selectPolicyDrawer(policyIndex) {
            vm.selectedDrawer = _policyAlreadyOpen(policyIndex) ? null : policyIndex;
        }

        function _policyAlreadyOpen(policyIndex) {
            return vm.selectedDrawer === policyIndex;
        }

        function isUndeterminedOrInactive(status) {
            return !Utility.isEmpty(status) && (Utility.lowerCase(status) === STATUS_CONSTS.STATUS.UNDETERMINED || Utility.lowerCase(status) === STATUS_CONSTS.STATUS.INACTIVE);
        }

        function setActiveStatusClass(status) {
            var element = {status: status.insuranceStatus};
            return StatusStyles.setActiveStatusClass(element);
        }

        function showPolicyDetailModal(index) {
            var responseData = {
                coverageResponse: vm.coverageResponse,
                responseDate: vm.responseDate,
                policyIndex: index
            };
            PolicyPlanDetailsModalService.show(responseData);
        }

        function isNotCovered(policy) {
            var propertyExists = !Utility.isEmpty(policy) && !Utility.isEmpty(policy.plan) && !Utility.isEmpty(policy.plan.status) && !Utility.isEmpty(policy.plan.status.productCoverageStatus);
            var productCoverageStatus = policy.plan.status.productCoverageStatus;
            return propertyExists && Utility.lowerCase(productCoverageStatus) === STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.NOT_COVERED;
        }

        function costShareSectionDisplayable(policy) {
            return  !vm.transactionMessageDisplayed 
                        && !vm.checkProdCoverageStatus(policy, STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.NOT_COVERED) 
                        && !vm.checkProdCoverageStatus(policy, STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.UNDETERMINED);          
        }

        function checkProdCoverageStatus(policy, status) {
            var prodCoverageStatusExists = !Utility.isEmpty(policy) 
                                            && !Utility.isEmpty(policy.plan)
                                            && !Utility.isEmpty(policy.plan.status) 
                                            && !Utility.isEmpty(policy.plan.status.productCoverageStatus);

            return prodCoverageStatusExists && Utility.lowerCase(policy.plan.status.productCoverageStatus) === status;
        }
    }
})();
