(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ProviderForm')
        .component('eaProviderForm', {
            templateUrl: 'components/provider-form/provider-form.html',
            controller: 'ProviderFormController as providerForm',
            bindings: {
                submitCallback: '<',
                form: '=',
                showSavingSpinner: '<',
                provider: '=',
                showFooterLinks: "<",
                showHeader: "<",
                isSingleStep: "<",
                nextCallback: "=",
                previousCallback: "=",
                goBack: "<"
            }
        });
})();
