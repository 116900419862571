/**
 * Created by nikesh on 3/11/2018.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxServiceDeliveryForm')
        .component('eaRxServiceDeliveryForm', {
            templateUrl: 'components/rx-service-delivery-form/rx-service-delivery-form.html',
            controller: 'RxServiceDeliveryFormController as rxService',
            bindings: {
                parentForm: '=',
                serviceDeliveryInformation: '=',
                selectedProduct: '=',
                selectedLocation: '='
            }
        });

})();
