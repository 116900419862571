(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .factory('PracticeModel', PracticeModel);

    PracticeModel.$inject = [];

    function PracticeModel() {

        function PracticeModelInstance(address1, address2, city, id, legalName, mainFax, mainPhone, name, npi, state, taxId, zip, active) {
            this.address1 = address1 || null;
            this.address2 = address2 || null;
            this.city = city || null;
            this.id = id || null;
            this.legalName = legalName || null;
            this.mainFax = mainFax || null;
            this.mainPhone = mainPhone || null;
            this.name = name || null;
            this.npi = npi || null;
            this.state = state || null;
            this.taxId = taxId || null;
            this.zip = zip || null;
            this.active = active || false;
        }

        return PracticeModelInstance;

    }

})();
