/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.controller:PatientController
 * @requires $log
 * @description
 *
 * This is the controller for the Patients page.
 **/

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Patient')
        .controller('PatientController', PatientController);

    PatientController.$inject = ['$log', 'PatientView'];

    function PatientController ($log, PatientView) {
        var vm = this;

        // data
        vm.viewService = PatientView;

        init();

        function init() {
            PatientView.setTabInfo();
        }
    }
})();
