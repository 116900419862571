(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.BVHistory')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('bvHistory', {
                url: '/bv-history',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - BV History'
                },
                views: {
                    'content@': {
                        templateUrl: 'components/bv-history/bv-history.html',
                        controller: 'BVHistoryController as bvHistory'
                    }
                },
                params: {
                    status: null,
                    invalidUrl: false
                }
            });
    }
})();
