(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilitySplitResponse')
        .controller('PlanInformationController', PlanInformationController);

    PlanInformationController.$inject = ['$log', 'Utility', 'moment'];

    function PlanInformationController($log, Utility, moment) {
        var vm = this;

        // holds the data for template
        vm.data = {};

        vm.$onInit = function() {
            if(!Utility.isEmpty(vm.policy.plan)) {
                var plan = vm.policy.plan;
                vm.data.planName = plan.planName;
                vm.data.pbmName = plan.pbmName;
                vm.data.planType = plan.pbmPlanType;
                vm.data.planEffectiveDate = !Utility.isEmpty(plan.planEffectiveDate) ? moment(plan.planEffectiveDate):null;
                vm.data.groupName = plan.groupName;
                vm.data.groupNumber = plan.groupNumber;
                vm.data.pbmId = plan.pbmId;
                vm.data.bin = plan.binLocationNumber;
                vm.data.pcn = plan.pcnNumber;
            }

            if(!Utility.isEmpty(vm.policy.subscriber)) {
                var subscriber = vm.policy.subscriber;
                vm.data.relationship = subscriber.relationship;
                vm.data.personCode = subscriber.personCode;
                vm.data.cardHolderId = subscriber.cardHolderId;
            }
        };
    }
})();
