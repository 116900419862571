(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.SOBHeader')
        .factory('SOBHeaderService', SOBHeaderService);

    SOBHeaderService.$inject = ['$log', 'API'];

    function SOBHeaderService($log, API) {
        var service = {
            getSobFile: getSobFile
        };

        function getSobFile(mbvId, param) {
            return API.get('documents/sob/' + mbvId, param);
        }

        return service;
    }

})();
