(function() {
    'use strict';

    var eaPolicyListSummary = {
        bindings: {
            policies: '<',
            product: '<',
            bvAdjudicationType : '<',
            patientStatus : '<',
            recentVerificationDate: '<',
            bvType: '<',
            bvStatus: '<',
            rxType: '<'
        },
        templateUrl: 'components/policy-list-summary/policy-list-summary.html',
        controller: 'PolicyListSummaryController as policyListSummary'
    };

    angular
        .module('eAccess.ProviderPortal.Components.PolicyListSummary')
        .component('eaPolicyListSummary', eaPolicyListSummary);
})();
