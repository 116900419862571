/**
 * Created by pratik budhathoki on 2/28/2018.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.BvSelection')
        .controller('BvSelectionController', BvSelectionController);

    BvSelectionController.$inject = ['bvMethod', '$state', 'BVSelectionService', 'Product', '$stateParams', 'Utility', 'CacheDataService', 'BV_SELECTION_CONSTS'];

    function BvSelectionController(bvMethod, $state, BVSelectionService, Product, $stateParams, Utility, CacheDataService, BV_SELECTION_CONSTS) {
        var vm = this;

        vm.products = [];

        vm.changeStateByBVMethod = changeStateByBVMethod;
        vm.fetchProduct = fetchProduct;
        vm.verifyBenefits = verifyBenefits;

        vm.$onInit = function() {
            vm.changeStateByBVMethod(bvMethod);
            vm.fetchProduct(bvMethod);
            vm.patient = $stateParams.patient;
        };

        vm.bvMethods = {
            rx: BV_SELECTION_CONSTS.METHOD_NAME.RX,
            medical: BV_SELECTION_CONSTS.METHOD_NAME.MEDICAL
        };

        var stateName = {
            medical: BV_SELECTION_CONSTS.STATE_NAME.MEDICAL,
            rx: BV_SELECTION_CONSTS.STATE_NAME.RX
        };

        function changeStateByBVMethod(methodName) {
            $state.go(BVSelectionService.getStateNameFromMethod(methodName));
        }

        function verifyBenefits(methodName) {
            methodName = Utility.lowerCase(methodName);
            if(!Utility.isEmpty($stateParams.patient) && $stateParams.patient !== '') {
                BVSelectionService.redirectBenefitVerify(methodName, vm.patient);
            } else {
                $state.go(stateName[Utility.lowerCase(methodName)]);
            }
        }

        function fetchProduct(methodName) {
            Product.get(methodName)
                .then(function(products) {
                    vm.products = products;
                })
                .catch(function() {
                    vm.products = [];
                });
        }

    }
})();
