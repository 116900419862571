/**
 * Created by sstokes on 1/10/17.
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .factory('DiagnosisCode', DiagnosisCode);

    DiagnosisCode.$inject = ['$http', '$q', '$timeout', '$log', 'API', 'DiagnosisCodeModel'];

    function DiagnosisCode($http, $q, $timeout, $log, API, DiagnosisCodeModel) {
        var service = {
            get: get,
            getByProduct: getByProduct
        };

        /**
         * Fetch all diagnosis codes
         */
        function get() {
            return API.get('diagnosis').then(function(diagnosisCodesJSON) {
                return diagnosisCodesJSON.map(function(dCode) {
                    return new DiagnosisCodeModel(dCode.diagnosisCodeId, dCode.diagnosisCode, dCode.description);
                }).slice(0, 100); // TODO: Remove the slice. This prevents the full list from hanging the frontend
            }).catch(function(err) {
                $log.error('error fetching diagnosis list');
                $log.error(err);
                return err;
            });
        }

        /**
         * Fetch all diagnosis codes for the given product
         */
        function getByProduct(productId) {
            return API.get('diagnosis/productId/' + productId).then(function(diagnosisCodesJSON) {
                return diagnosisCodesJSON.map(function(dCode) {
                    return new DiagnosisCodeModel(dCode.diagnosisCodeId, dCode.diagnosisCode, dCode.description);
                });
            }).catch(function(err) {
                $log.error('error fetching diagnosis list by product');
                $log.error(err);
                return err;
            });
        }

        return service;
    }
})();
