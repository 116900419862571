(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ToastNotification')
        .factory('ToastNotificationService', ToastNotificationService);

    ToastNotificationService.$inject = ['ToastNotification', 'Utility', 'GLOBAL_CONSTS', 'STATUS_CODE', 'ERROR_MESSAGES'];

    function ToastNotificationService(ToastNotification, Utility, GLOBAL_CONSTS, STATUS_CODE, ERROR_MESSAGES) {

        var service = {
            toastNotification: new ToastNotification(),
            getToastNotification: getToastNotification,
            setToastNotification: setToastNotification,
            clearToastNotification: clearToastNotification,
            buildToastNotification: buildToastNotification,
            setGlobalToastInfo: setGlobalToastInfo
        };

        function getToastNotification() {
            return service.toastNotification;
        }

        function setToastNotification(toastNotification) {
            service.toastNotification = toastNotification;
        }

        function clearToastNotification() {
            service.toastNotification = new ToastNotification();
        }

        function buildToastNotification(display, title, message, toastType) {
            return new ToastNotification(
                display,
                title,
                message,
                toastType
            );
        }

        function setGlobalToastInfo(toastType) {
            if(!toastType || Utility.isBlank(String(toastType))) {
                return false;
            }
            var toastInfo = new ToastNotification(true);
            switch(toastType) {
                case GLOBAL_CONSTS.TOAST_TYPES.SUCCESS:
                    toastInfo.title = GLOBAL_CONSTS.TOAST_TITLE.SAVE_SUCCESSFUL;
                    toastInfo.message = GLOBAL_CONSTS.TOAST_MESSAGE.SAVE_SUCCESSFUL;
                    toastInfo.toastType = GLOBAL_CONSTS.TOAST_TYPES.SUCCESS;
                    break;
                case STATUS_CODE["409_CONFLICT"]:
                    toastInfo.title = GLOBAL_CONSTS.TOAST_TITLE.BAD_REQUEST_409;
                    toastInfo.message = GLOBAL_CONSTS.TOAST_MESSAGE.BAD_REQUEST_409;
                    toastInfo.toastType = GLOBAL_CONSTS.TOAST_TYPES.ERROR;
                    break;
                case STATUS_CODE["500_INTERNAL_SERVER_ERROR"]:
                    toastInfo.title = GLOBAL_CONSTS.TOAST_TITLE.BAD_REQUEST_500;
                    toastInfo.message = GLOBAL_CONSTS.TOAST_MESSAGE.BAD_REQUEST_500;
                    toastInfo.toastType = GLOBAL_CONSTS.TOAST_TYPES.ERROR;
                    break;
                default:
                    toastInfo.title = GLOBAL_CONSTS.TOAST_TITLE.SYSTEM_ERROR;
                    toastInfo.message = ERROR_MESSAGES.GENERIC_ERROR;
                    toastInfo.toastType = GLOBAL_CONSTS.TOAST_TYPES.ERROR;
            }
            setToastNotification(toastInfo);
        }

        return service;
    }
})();
