/**
 * Created by aayush regmi on 4/12/19.
 */

(function() {
    'use strict';

    angular.module('eAccess.ProviderPortal.Directives').directive('eaContentStore', eaContentStore);

    eaContentStore.$inject = ['$window', 'ContentStoreService', '$log', 'Utility'];

    function eaContentStore($window, ContentStoreService, $log, Utility) {
        return {
            restrict: 'E',
            scope: {
                key: '<' //key for which we need to render a content
            },
            link: function(scope, element) {
                ContentStoreService.getContentStoreData(scope.key).then(function (response) {
                    element.html(response);
                    // get and open pdf for terms and conditions
                    angular.element('#pdf-link').mousedown(function () {
                        Utility.openPdf(Utility.getFileURLForKey('useragreementpdf'), true);
                    });
                });
            }
        };
    }
})();
