(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Modals')
        .controller('WarnOnLeaveModalController', WarnOnLeaveModalController);

    WarnOnLeaveModalController.$inject = ['$log', '$uibModalInstance', 'WarnOnLeave'];

    function WarnOnLeaveModalController($log, $uibModalInstance, WarnOnLeave) {

        var vm = this;

        vm.closeModal = closeModal;
        vm.leave = leave;

        function closeModal() {
            WarnOnLeave.isFormDirty = false;
            $uibModalInstance.close();
        }

        function leave() {
            WarnOnLeave.leave();
            closeModal();
        }

    }

})();
