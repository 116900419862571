/**
 * Created by bikash.shrestha on 3/15/2018.
 */
(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Services.CacheData')
        .factory('CacheDataService', CacheData);

    CacheData.$inject = ['Utility', 'InsurancePolicy'];

    function CacheData(Utility, InsurancePolicy) {
        var service = {
            clearBVCache: clearBVCache,
            setRxBvCacheData: setRxBvCacheData,
            setMedBvCacheData: setMedBvCacheData,
            patient: {
                address: {}
            },
            consentMessage: {
                onfile: false,
                expired: false
            },
            providerInfo: {
                showProviderDropdown: true,
                provider: null
            },
            medServiceDeliveryInformation: {
                selectedProduct: null,
                selectedLocation: null,
                adminCodeList: [null],
                diagnosisCodeList: [null]
            },
            medPolicies: [new InsurancePolicy()],
            rxServiceDeliveryInformation: {
                selectedProduct: null,
                selectedLocation: null
            },
            rxDisclaimerContent: null
        };

        function clearBVCache() {
            service.patient = {
                address: {}
            };
            service.consentMessage = {
                onfile: false
            };
            service.providerInfo = {
                showProviderDropdown: true,
                provider: null
            };
            service.medServiceDeliveryInformation = {
                selectedProduct: null,
                selectedLocation: null,
                adminCodeList: [null],
                diagnosisCodeList: [null]
            };
            service.medPolicies = [new InsurancePolicy()];
            service.rxServiceDeliveryInformation = {
                selectedProduct: null,
                selectedLocation: null
            };
        }

        function setRxBvCacheData(rxBvDataObj) {
            if(Utility.isEmpty(rxBvDataObj)) {
                return false;
            }
            service.patient = rxBvDataObj.patient;
            service.providerInfo.provider = rxBvDataObj.provider;
            service.providerInfo.showProviderDropdown = rxBvDataObj.showProviderDropdown;
            service.rxServiceDeliveryInformation = rxBvDataObj.rxServiceDeliveryInformation;
            if(!Utility.isEmpty(service.rxServiceDeliveryInformation)) {
                service.rxServiceDeliveryInformation.selectedProduct = rxBvDataObj.selectedProduct;
                service.rxServiceDeliveryInformation.selectedLocation = rxBvDataObj.selectedLocation;
            }
        }

        function setMedBvCacheData(medBvDataObj) {
            if(Utility.isEmpty(medBvDataObj)) {
                return false;
            }
            service.patient = medBvDataObj.patient;
            service.medPolicies = medBvDataObj.medPolicies;
            service.providerInfo.provider = medBvDataObj.provider;
            service.providerInfo.showProviderDropdown = medBvDataObj.showProviderDropdown;
            if(!Utility.isEmpty(medBvDataObj.medServiceDeliveryInformation)) {
                service.medServiceDeliveryInformation = medBvDataObj.medServiceDeliveryInformation;
                service.medServiceDeliveryInformation.selectedProduct = medBvDataObj.selectedProduct;
                service.medServiceDeliveryInformation.selectedLocation = medBvDataObj.selectedLocation;
                if(Utility.isEmpty(service.medServiceDeliveryInformation.adminCodeList) || service.medServiceDeliveryInformation.adminCodeList.length === 0) {
                    service.medServiceDeliveryInformation.adminCodeList = [null];
                }
            }
        }

        return service;
    }
})();
