/**
 * Created by aayush.regmi on 10/12/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.TermsAndConditions')
        .factory('TermsAndConditionsService', TermsAndConditionsService);

    TermsAndConditionsService.$inject = ['$q', '$log', 'ENV', '$window', '$http'];

    function TermsAndConditionsService($q, $log, ENV, $window, $http) {

        var service = {
            getTermsAndConditionsConfiguration: getTermsAndConditionsConfiguration
        };

        function getTermsAndConditionsConfiguration() {
            var deferred = $q.defer();
            var requestConfig = {
                headers: {
                    'host_name': $window.location.hostname
                }
            };
            $http.get(ENV.API_URL + 'portals/registration', requestConfig)
                .then(function(response) {
                    return deferred.resolve(response.data);
                })
                .catch(function(err) {
                    return deferred.reject(err);
                });
            return deferred.promise;
        }

        return service;
    }
})();
