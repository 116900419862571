/**
 * Created by pramesh on 10/24/2017.
 */
(function () {
    'use strict';

    var VALUES = {
        PAGE_TITLE: 'FORGOT YOUR PASSWORD?',
        MESSAGE: 'Please enter your Username and click submit. We will email you instructions for setting a new password.',
        SUCCESS_MESSAGE: 'We just sent you an email with instructions for setting your password. If you do not see the email in your inbox in a few minutes, check your junk or spam mailbox.',
        RESEND_EMAIL: 'If you would like us to resend the email,',
        TYPE: 'FORGET_PASSWORD',
        ERROR_MESSAGES: {
            INVALID_USER_NAME: 'We don\'t recognize that Username. Please try again.'
        },
        MODAL_MESSAGE: {
            TITLE_TEXT: 'Forgot Your Username?',
            MESSAGE: 'If you don\'t know your Username, contact an Admin User at your practice.'
        }
    };

    angular.module('eAccess.ProviderPortal')
        .constant('FORGOT_PASSWORD_CONSTS', VALUES);
})();
