/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.controller:ProviderPracticeInformationController
 * @author Sterling Stokes
 * @requires ProviderService
 * @requires ELIGIBILITY_CONSTS
 * @requires HELP_TEXT_CONSTS
 * @description
 *
 * This is the controller for the Provider Information component.
 **/

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components')
        .controller('ProviderPracticeInformationController', ProviderPracticeInformationController);

    ProviderPracticeInformationController.$inject = ['ProviderService', '$log', 'WarnOnLeave', '$window', 'HELP_TEXT_CONSTS', 'Utility', 'GLOBAL_CONSTS'];

    function ProviderPracticeInformationController(ProviderService, $log, WarnOnLeave, $window, HELP_TEXT_CONSTS, Utility, GLOBAL_CONSTS) {

        var vm = this;

        vm.showActive = true;

        // Help text for help icon tooltips
        vm.helpText = {
            npi: HELP_TEXT_CONSTS.BV_REQUEST.PROVIDER_INFO.NPI,
            taxId: HELP_TEXT_CONSTS.BV_REQUEST.PROVIDER_INFO.TAX_ID,
            practiceProviderId: HELP_TEXT_CONSTS.BV_REQUEST.PROVIDER_INFO.PRACTICE_PROVIDER_ID,
            state: HELP_TEXT_CONSTS.BV_REQUEST.PROVIDER_INFO.STATE,
            selectProvider: HELP_TEXT_CONSTS.BV_REQUEST.PROVIDER_INFO.SELECT_YOUR_PROVIDER,
            newProviderLink: HELP_TEXT_CONSTS.BV_REQUEST.PROVIDER_INFO.NEW_PROVIDER_LINK,
            chooseExistingProvider: HELP_TEXT_CONSTS.BV_REQUEST.PROVIDER_INFO.CHOOSE_EXISITING_PROVIDER
        };

        vm.validation = ProviderService.getValidationInfo();
        vm.errorMessages = ProviderService.getErrorMessages(vm.validation);
        vm.providerSelectOnChange = providerSelectOnChange;

        vm.toggleProviderUI = toggleProviderUI;
        vm.isRequired = isRequired;

        vm.$onInit = function() {
            loadProvidersByStatus(vm.showActive);
        };

        vm.$onChanges = function(changed) {
            if(vm.provider && vm.providers && vm.showProviderDropdown) {
                _isProviderAvailable(vm.provider.id);
            }
        };


        function loadProvidersByStatus(showActive) {
            vm.loadingProviders = true;
            ProviderService.getProvidersInformation(showActive, false, -1)
                .then(function(response) {
                    vm.providers = response.responseList.map(concatFullNameNPI);
                    if(vm.provider && vm.providers) {
                        _isProviderAvailable(vm.provider.id);
                    }
                })
                .catch(function(err) {
                    $log.error(err);
                })
                .finally(function() {
                    vm.loadingProviders = false;
                });
        }

        function concatFullNameNPI(provider) {
            var middleName = (provider.middleName) ? ' ' + provider.middleName : '';
            provider.fullNameNpi = provider.lastName + ', ' + provider.firstName + middleName + ' | ' + provider.npi;
            return provider;
        }

        function toggleProviderUI(showProviderDropdown) {
            if(!providerIsEmpty() && showProviderDropdown) {
                loadProvidersByStatus(vm.showActive);
                WarnOnLeave.setLeaveCallBack(leaveCallBack);
                WarnOnLeave.show();
            } else {
                setShowProviderDropdown(showProviderDropdown);
            }
        }

        function providerIsEmpty() {
            var isEmpty = true;
            angular.forEach(vm.provider, function(value, key) {
                if(key !== '$$hashKey') {
                    if(angular.isDefined(value)) {
                        isEmpty = false;
                    }
                }
            });
            return isEmpty;
        }

        function setShowProviderDropdown(showProviderDropdown) {
            vm.provider = undefined;
            vm.showProviderDropdown = showProviderDropdown;
        }

        function leaveCallBack() {
            vm.providerExists = false;
            setShowProviderDropdown(true);
            $window.onbeforeunload = null;
        }

        function isRequired() {
            if(angular.isUndefined(vm.ehipaaFormProcessing)) {
                return true;
            }

            // form is not required till ehipaa
            // ehipaa consent form is submitted
            return (vm.ehipaaFormProcessing ? false : true);
        }

        function providerSelectOnChange() {
            vm.showProviderError = false;
            if(vm.provider && vm.providers) {
                _isProviderAvailable(vm.provider.id);
            }
        }

        /**
         * function to check if current patient provider is available in provider dropdown list or not
         * if provider is not available in dropdown list, set require error message in form
         */
        function _isProviderAvailable(providerId) {
            vm.provider = Utility.getObjectFromList(vm.providers, GLOBAL_CONSTS.KEYS.ID, providerId);
            if (Utility.isEmpty(vm.provider)) {
                vm.parentForm.providers.$invalid = true;
                vm.parentForm.providers.$error.providerNotActive = true;
                vm.parentForm.providers.$viewValue = null;
            } else {
                delete vm.parentForm.providers.$error.providerNotActive;
                vm.parentForm.providers.$invalid = false;
            }
        }
    }

})();
