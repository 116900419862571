/**
 * Created by nikesh on 9/1/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Services.CannotDeactivateAll')
        .factory('CannotDeactivateAllService', CannotDeactivateAllService);

    CannotDeactivateAllService.$inject = ['$log', '$uibModal'];

    function CannotDeactivateAllService($log, $uibModal) {

        var service = {
            show: show
        };

        function show(config) {
            var cannotDeactivateAllModal = $uibModal.open({
                animation: true,
                templateUrl: 'partials/templates/cannot-deactivate-all-modal.html',
                controller: 'CannotDeactivateAllModalController as cannotDeactivateAll',
                appendTo: angular.element('body'),
                resolve: {
                    titleText: function() {
                        return config.modalTitle;
                    },
                    message: function() {
                        return config.modalBody;
                    },
                    displaySecondaryButton: function() {
                        return config.displaySecondaryButton;
                    }
                }
            });
            cannotDeactivateAllModal.result.then(angular.noop, angular.noop);
            return cannotDeactivateAllModal;
        }

        return service;

    }

})();
