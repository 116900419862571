/**
 * Created by pramesh on 11/2/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.InitialUserInformation')
        .factory('ValidateUserService', ValidateUserService);

    ValidateUserService.$inject = ['$http', '$q', '$log', 'API'];

    function ValidateUserService($http, $q, $log, API) {

        var service = {
            resendEmailLink: resendEmailLink
        };

        /**
         * @ngdoc function
         * @name addInitialUser
         * @description
         *
         * Sends new activation link for the created user.
         *
         * @param user
         */

        function resendEmailLink(registrationGuid) {
            var guid = {
                "registrationGUID" : registrationGuid 
            };
            return API.post('users/resend', guid);
        }

        return service;
    }
})();
