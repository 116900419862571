/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.Components.Practice.controller:PracticeUserController
 * @author Sterling Stokes
 * @requires $log
 * @requires PracticeView
 * @requires STATUS_CONSTS
 * @requires PracticeUserService
 * @requires PatientStyles
 * @requires USER_CONSTS
 * @requires $stateParams
 * @description
 *
 * This is the controller for the Practice Users page.
 **/

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .controller('PracticeUserController', PracticeUserController);

    PracticeUserController.$inject = ['$log', 'PracticeView', 'STATUS_CONSTS', 'PracticeUserService', 'PatientStyles', 'USER_CONSTS', '$stateParams', 'SEARCH_CONSTS', 'Utility', 'PaginationService', 'TABLE_NAME_CONSTS', 'ConfigurationService', 'Search', 'DATA_LIMIT_CONSTS', 'SearchPaginationModel', 'SearchModel', 'ToastNotificationService', 'CancelableQ', 'ERROR_MESSAGES', 'PracticeUserReactivateDeactivate', 'GLOBAL_CONSTS'];

    function PracticeUserController($log, PracticeView, STATUS_CONSTS, PracticeUserService, PatientStyles, USER_CONSTS, $stateParams, SEARCH_CONSTS, Utility, PaginationService, TABLE_NAME_CONSTS, ConfigurationService, Search, DATA_LIMIT_CONSTS, SearchPaginationModel, SearchModel, ToastNotificationService, CancelableQ, ERROR_MESSAGES, PracticeUserReactivateDeactivate, GLOBAL_CONSTS) {
        var vm = this;

        //date
        vm.viewableUsersStatus = STATUS_CONSTS.STATUS.ACTIVE;
        vm.userMap = {
            active: [],
            inactive: []
        };
        vm.loadingUsers = false;
        vm.searchType = SEARCH_CONSTS.SEARCH_TYPE.USER;

        vm.dataSortOrders = {lastName: {isAscending: true}};
        vm.searchFacets = [
            {
                searchCategory: SEARCH_CONSTS.SEARCH_FACETS.USER_SEARCH_CATEGORY.USER_LAST_NAME,
                placeholderText: SEARCH_CONSTS.SEARCH_FACETS.USER_SEARCH_CATEGORY.USER_LAST_NAME,
                value: SEARCH_CONSTS.SEARCH_FACETS.USER_SEARCH_CATEGORY.USER_LAST_NAME_VALUE
            },
            {
                searchCategory: SEARCH_CONSTS.SEARCH_FACETS.USER_SEARCH_CATEGORY.USER_ROLE,
                placeholderText: SEARCH_CONSTS.SEARCH_FACETS.USER_SEARCH_CATEGORY.USER_ROLE,
                value: SEARCH_CONSTS.SEARCH_FACETS.USER_SEARCH_CATEGORY.USER_ROLE_VALUE
            }
        ];

        // Holds list of items that has been checked (from the user list table)
        vm.listOfCheckedUserItems = [];
        vm.selectedAll = false; // value for select all checkbox
        vm.loggedInUser = null;
        vm.loggedInUserSelected = false;
        vm.fetchPracticeUserCount = DATA_LIMIT_CONSTS.DEFAULT_COUNT;
        vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
        vm.sortBy = USER_CONSTS.USER_SORT.USER_LAST_NAME_AND_USERNAME;
        vm.noDataFound = false;
        vm.userCount = DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE;
        vm.currentSearchQuery = '';
        vm.searchQuery = '';
        vm.userReactivateDeactivate = null;
        vm.systemError = false;
        vm.nextLink = null;
        vm.invalidUrl = $stateParams.invalidUrl;

        //Preserving promise object to cancel, if new data are requested.
        vm.cancelableService = CancelableQ;
        vm.isPromiseCalled = false;

        // functions
        vm.toggleViewableStatus = toggleViewableStatus;
        vm.setBenefitStatusClass = PatientStyles.setBenefitStatusClass;
        vm.getUserStatusToggleText = getUserStatusToggleText;
        vm.changeUserStatus = changeUserStatus;
        vm.getUserActionText = getUserActionText;
        vm.userSearchCallback = userSearchCallback;
        vm.clearAllCallback = clearSearchCallback;
        vm.loadPracticeUsersByStatus = loadPracticeUsersByStatus;
        vm.fetchLoggedInUserInfo = fetchLoggedInUserInfo;
        vm.updateUserStatus = updateUserStatus;
        vm.sortPracticeUser = sortPracticeUser;

        vm.$onInit = function() {
            if(vm.invalidUrl) {
                _setInvalidURLNotification();
            }

            PracticeView.configure({
                title: 'My Practice |',
                contentTitle: 'Users',
                tabIndex: 3,
                buttonText: 'Add New User',
                buttonHref: 'practice.users.add'
            });
            vm.userReactivateDeactivate = new PracticeUserReactivateDeactivate();
            vm.loadingUsers = true;
            ConfigurationService.fetchDefaultTablePageSize(TABLE_NAME_CONSTS.USER_TABLE).then(function(pageLimit) {
                vm.limit = !Utility.isEmpty(pageLimit) ? pageLimit : DATA_LIMIT_CONSTS.DEFAULT_LIMIT;
            }).catch(function(err) {
                // this is just in case if 'defaultLimit' not returned from the API
                vm.limit = DATA_LIMIT_CONSTS.DEFAULT_LIMIT;
                $log.error('Unable to fetch portal data', err);
            }).finally(function() {
                vm.loadingUsers = false;
                loadPracticeUsersByStatus(vm.viewableUsersStatus);
                if($stateParams.userCreated) {
                    _setToastNotification(USER_CONSTS.SUCCESS.TITLE, USER_CONSTS.SUCCESS.MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
                }
                vm.fetchLoggedInUserInfo();
            });
        };

        function _setInvalidURLNotification() {
            var toastInfo = Utility.getBadRequestToastInfo();
            _setToastNotification(toastInfo.title, toastInfo.message, toastInfo.type);
        }

        function loadPracticeUsersByStatus() {
            var resultListEqualsTotalCount = vm.userCount === vm.userMap[vm.viewableUsersStatus].length;
            if(!Utility.shouldCallAPI(vm.loadingUsers, resultListEqualsTotalCount, vm.noDataFound)) return;

            if(!Utility.isEmpty(vm.currentSearchQuery) && vm.currentSearchQuery !== '') {
                _userSearchLoadMoreCallback(vm.viewableUsersStatus);
                return;
            }

            //Canceling previous call if in progress
            if(vm.isPromiseCalled) {
                vm.cancelableService.cancel();
                vm.listUser.canceler.resolve();
            }

            vm.loadingUsers = true;
            vm.isPromiseCalled = true;

            vm.listUser = PracticeUserService.getUsersByStatus(vm.fetchPracticeUserCount, _isViewableStatusActive(), vm.limit, vm.offset, vm.sortBy, vm.nextLink);
            vm.cancelableService.promiseObj = vm.cancelableService.wrap(vm.listUser.promise);

            //this is to execute finally block if multiple promise does not exits and last promise has not been canceled.
            var isPromiseCanceled = false;

            vm.cancelableService.promiseObj.then(function(users) {
                var paginationResponse = PaginationService.getPaginationResponse(users, vm.userMap[vm.viewableUsersStatus], 'userId', vm.offset, vm.limit);
                vm.userCount = paginationResponse.totalCount;
                vm.nextLink = paginationResponse.nextLink;
                vm.userMap[vm.viewableUsersStatus] = paginationResponse.list;
            }).catch(function(err) {
                //Recording flag if exception occurred due to force cancellation of promise to avoid executing finally block.
                isPromiseCanceled = err === ERROR_MESSAGES.FORCE_CANCEL;

                $log.error('Could not fetch users:', err);
                //This value is set only if the first API call fails
                if(!vm.userMap[vm.viewableUsersStatus].length) {
                    vm.noDataFound = true;
                }

            }).finally(function() {
                //if promise is force canceled, finally block should not run.
                if(!isPromiseCanceled) {
                    vm.loadingUsers = false;
                    _setUsersWarningMessage();
                    vm.isPromiseCalled = false;
                }
            });
        }

        function _setUsersWarningMessage() {
            if(vm.userMap[vm.viewableUsersStatus].length === 0) {
                vm.noDataFound = true;
                vm.loadingUsersWarningMessage = (!Utility.isEmpty(vm.currentSearchQuery) && vm.currentSearchQuery !== '') ? Search.getSearchResultText(vm.userCount, vm.selectedFacet.searchCategory, vm.currentSearchQuery) : Utility.replaceText(USER_CONSTS.LOADING_USER_WARNING_MESSAGE, '@STATUS', vm.viewableUsersStatus);
            } else {
                vm.noDataFound = false;
            }
        }

        function _resetSelectedList() {
            vm.listOfCheckedUserItems = [];
            vm.loggedInUserSelected = false;
        }

        function toggleViewableStatus() {
            vm.loadingUsers = false;
            _resetSelectedList();
            ToastNotificationService.clearToastNotification();
            vm.viewableUsersStatus = vm.viewableUsersStatus === STATUS_CONSTS.STATUS.ACTIVE ? STATUS_CONSTS.STATUS.INACTIVE : STATUS_CONSTS.STATUS.ACTIVE;
            _resetSearchAndSortParams();
            _resetPaginationSettings();
        }

        function _resetPaginationSettings() {
            vm.userMap = {
                active: [],
                inactive: []
            };
            vm.fetchPracticeUserCount = DATA_LIMIT_CONSTS.DEFAULT_COUNT;
            vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
            vm.userCount = DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE;
            vm.noDataFound = false;
            loadPracticeUsersByStatus(vm.viewableUsersStatus);
        }

        function getUserStatusToggleText() {
            return _isViewableStatusActive() ? USER_CONSTS.STATUS_TEXT.VIEW_INACTIVE_USER : USER_CONSTS.STATUS_TEXT.VIEW_ACTIVE_USER;
        }

        function changeUserStatus() {
            ToastNotificationService.clearToastNotification();
            //First check if any users are selected or not
            if(vm.listOfCheckedUserItems.length === 0) {
                return false;
            }
            _prepareNewSelectedList();
            //check if logged in user has been selected
            if(vm.loggedInUserSelected) {
                return vm.userReactivateDeactivate.openCannotReactivateDeactivateModal();
            }
            return _prepareAndOpenReactivateDeactivateModal();
        }

        function _prepareAndOpenReactivateDeactivateModal() {
            vm.userReactivateDeactivate.resetDynamicSettings();
            vm.userReactivateDeactivate.setCurrentState(vm.listOfCheckedUserItems, vm.viewableUsersStatus);
            vm.userReactivateDeactivate.setModalTitle(USER_CONSTS.REACTIVATE_DEACTIVATE_MODAL_TITLE);
            vm.userReactivateDeactivate.setModalSubTitle();
            vm.userReactivateDeactivate.openReactivateDeactivateModal().then(function(response) {
                if(response) {
                    var userToBeChanged = {
                        userList: vm.listOfCheckedUserItems,
                        activate: vm.viewableUsersStatus !== STATUS_CONSTS.STATUS.ACTIVE
                    };
                    vm.updateUserStatus(userToBeChanged);
                }
            });
        }

        function getUserActionText() {
            return _isViewableStatusActive() ? USER_CONSTS.ACTION_TEXT.DEACTIVATE_SELECTED : USER_CONSTS.ACTION_TEXT.REACTIVATE_SELECTED;
        }

        function userSearchCallback(searchCallbackModel) {
            vm.selectedAll = false;
            vm.userMap[vm.viewableUsersStatus] = [];
            vm.searchResultText = null;
            if(Utility.isEmpty(searchCallbackModel.search)) {
                vm.loadingUsersWarningMessage = SEARCH_CONSTS.EMPTY_SEARCH_WARNING_MESSAGE;
                vm.noDataFound = true;
                return;
            }
            vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
            vm.loadingUsers = true;
            vm.selectedFacet = searchCallbackModel.selectedFacet;
            vm.sortBy = searchCallbackModel.pagination.sortOrder;
            vm.currentSearchQuery = angular.copy(searchCallbackModel.query);
            resetSortOrder();
            searchCallbackModel.search.then(function(response) {
                var paginationResponse = PaginationService.getPaginationResponse(response, vm.userMap[vm.viewableUsersStatus], 'userId', vm.offset, vm.limit);
                vm.userCount = paginationResponse.totalCount;
                vm.nextLink = paginationResponse.nextLink;
                vm.userMap[vm.viewableUsersStatus] = paginationResponse.list;
            }).catch(function(err) {
                vm.userCount = DATA_LIMIT_CONSTS.DEFAULT_COUNT_VALUE;
                $log.error('Could not fetch users:', err);
            }).finally(function() {
                _setSearchResultText();
                vm.loadingUsers = false;
                _setUsersWarningMessage();
            });
        }

        function _userSearchLoadMoreCallback(status) {
            vm.loadingUsers = true;
            vm.searchResultText = null;

            Search.userSearch(_getSearchParam()).then(function(response) {
                var paginationResponse = PaginationService.getPaginationResponse(response, vm.userMap[status], 'userId', vm.offset, vm.limit);
                vm.userCount = paginationResponse.totalCount;
                vm.userMap[status] = paginationResponse.list;
                vm.nextLink = paginationResponse.nextLink;
                _setSearchResultText();
            }).catch(function(err) {
                $log.error('Could not fetch users:', err);
            }).finally(function() {
                vm.loadingUsers = false;
                _setUsersWarningMessage();
            });
        }

        function _getSearchParam() {
            var pagination = new SearchPaginationModel(true, vm.offset, vm.limit, vm.sortBy, vm.nextLink);
            return new SearchModel(pagination, vm.currentSearchQuery, vm.selectedFacet, vm.viewableUsersStatus);
        }

        function _setSearchResultText() {
            vm.searchResultText = Search.getInstructionTextForSearch(vm.userCount, vm.selectedFacet.searchCategory, vm.currentSearchQuery);
        }

        function clearSearchCallback() {
            vm.userMap = {
                active: [],
                inactive: []
            };
            _resetSearchAndSortParams();
            _resetSelectedList();
            loadPracticeUsersByStatus();
        }

        function resetSortOrder() {
            vm.dataSortOrders = {lastName: {isAscending: true}};
            vm.sortBy = USER_CONSTS.USER_SORT.USER_LAST_NAME_AND_USERNAME;
        }

        function fetchLoggedInUserInfo() {
            PracticeUserService.fetchLoggedInUser()
                .then(function(response) {
                    vm.loggedInUser = response;
                })
                .catch(function(err) {
                    $log.log('Not able to fetch logged in user', err);
                    vm.loggedInUser = null;
                });
        }

        function _prepareNewSelectedList() {
            var newSelectedList = [];
            vm.loggedInUserSelected = false;
            angular.forEach( vm.listOfCheckedUserItems, function (user) {
                var userIdToCompare = Utility.isObject(user) ? user.userId : user;
                if(userIdToCompare === vm.loggedInUser.userId) vm.loggedInUserSelected = true;
                newSelectedList.push(Utility.getItemFromArray(vm.userMap[vm.viewableUsersStatus], 'userId', userIdToCompare));
            });
            vm.listOfCheckedUserItems = newSelectedList;
        }

        function updateUserStatus(updateRequest) {
            PracticeUserService.updateUserStatus(updateRequest).then(function(response) {
                if(!Utility.isEmpty(response.userList)) {
                    _getErrorMessage(response.userList);
                } else {
                    _getSuccessMessage();
                }
                vm.searchResultText = '';
                vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
                vm.nextLink = null;
                vm.userMap = {
                    active: [],
                    inactive: []
                };
                vm.selectedAll = false;
                _resetSelectedList();
                loadPracticeUsersByStatus();
            }).catch(function(err) {
                $log.log(err);
                _setToastNotification(GLOBAL_CONSTS.TOAST_TITLE.SYSTEM_ERROR, GLOBAL_CONSTS.TOAST_MESSAGE.SYSTEM_MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
            });
        }

        function _getSuccessMessage() {
            var notificationType = GLOBAL_CONSTS.TOAST_TYPES.SUCCESS;
            if(_isViewableStatusActive()) {
                if(vm.listOfCheckedUserItems.length > 1) {
                    _setToastNotification(USER_CONSTS.SUCCESS_MESSAGE.DEACTIVATE.MULTIPLE.TITLE, USER_CONSTS.SUCCESS_MESSAGE.DEACTIVATE.MULTIPLE.MESSAGE, notificationType);
                } else {
                    _setToastNotification(USER_CONSTS.SUCCESS_MESSAGE.DEACTIVATE.SINGLE.TITLE, USER_CONSTS.SUCCESS_MESSAGE.DEACTIVATE.SINGLE.MESSAGE, notificationType);
                }
            } else {
                if(vm.listOfCheckedUserItems.length > 1) {
                    _setToastNotification(USER_CONSTS.SUCCESS_MESSAGE.REACTIVATE.MULTIPLE.TITLE, USER_CONSTS.SUCCESS_MESSAGE.REACTIVATE.MULTIPLE.MESSAGE, notificationType);
                } else {
                    _setToastNotification(USER_CONSTS.SUCCESS_MESSAGE.REACTIVATE.SINGLE.TITLE, USER_CONSTS.SUCCESS_MESSAGE.REACTIVATE.SINGLE.MESSAGE, notificationType);
                }
            }
        }

        function _getErrorMessage(userList) {
            var userNames = '';
            angular.forEach(userList, function(user) {
                if(userNames) {
                    userNames += ", " + _getFullName(user);
                } else {
                    userNames = _getFullName(user);
                }
            });
            _setToastNotification(GLOBAL_CONSTS.TOAST_TITLE.SYSTEM_ERROR, USER_CONSTS.ERROR_MESSAGE.UPDATE_FAILED.MESSAGE.replace("#usernames", userNames), GLOBAL_CONSTS.TOAST_TYPES.ERROR);
        }

        function _getFullName(userObj) {
            if(userObj.middleName) {
                return userObj.firstName + ' ' + userObj.middleName + ' ' + userObj.lastName;
            }
            return userObj.firstName + ' ' + userObj.lastName;
        }

        function sortPracticeUser(sortBy) {
            vm.selectedAll = false;
            vm.nextLink = null;
            vm.userMap = {
                active: [],
                inactive: []
            };
            vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;

            if(sortBy === USER_CONSTS.USER_SORT.USER_LAST_NAME_AND_USERNAME) {
                vm.dataSortOrders.lastName.isAscending = !vm.dataSortOrders.lastName.isAscending;
                vm.sortBy = vm.dataSortOrders.lastName.isAscending ? USER_CONSTS.USER_SORT.USER_LAST_NAME_AND_USERNAME : '-' + USER_CONSTS.USER_SORT.USER_LAST_NAME_AND_USERNAME;
            } else {
                vm.sortBy = USER_CONSTS.USER_SORT.USER_LAST_NAME_AND_USERNAME;
            }

            vm.loadPracticeUsersByStatus();
        }

        function _resetSearchAndSortParams() {
            vm.nextLink = null;
            vm.selectedAll = false;
            vm.searchQuery = '';
            vm.selectedFacet = null;
            vm.currentSearchQuery = '';
            vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
            vm.userCount = DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE;
            vm.noDataFound = false;
            vm.searchResultText = null;
            resetSortOrder();
        }

        function _isViewableStatusActive() {
            return vm.viewableUsersStatus === STATUS_CONSTS.STATUS.ACTIVE;
        }

        function _setToastNotification(header, message, toastType) {
            ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, header, message, toastType));
        }
    }

})();
