(function() {
    'use strict';

    var OKTA_CONSTS = {
        ERROR_CODES: {
            'UNAUTHORIZED': 'E0000004',
            PASSWORD_EXPIRED: 'PASSWORD_EXPIRED'
        },
        DEFAULT_USER_ROLE: "Everyone",
        RESPONSE_TYPE: ['id_token', 'token'], // id token and access token
        TOKEN_SCOPES: ["openid", "profile", "email", "phone", "address", "groups"],
        AUTHORIZE_URL_SUFFIX: '/v1/authorize',
        BEFORE_SESSION_REFRESH_TIME: 120 // default time in second i.e 2 minutes
    };

    angular.module('eAccess.ProviderPortal.Constants.Okta', []).constant('OKTA_CONSTS', OKTA_CONSTS);
})();
