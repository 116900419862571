/**
 * Created by nikesh on 10/17/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PracticeSetup')
        .controller('WelcomeController', WelcomeController);

    WelcomeController.$inject = ['$log', 'PracticeSetupView', 'PRACTICE_SETUP_CONSTS'];

    function WelcomeController($log, PracticeSetupView, PRACTICE_SETUP_CONSTS) {

        var vm = this;

        // data
        vm.viewService = PracticeSetupView;
        vm.confirmationMessage = PRACTICE_SETUP_CONSTS.SUCCESS_MESSAGE;
        vm.instructionText = PRACTICE_SETUP_CONSTS.INSTRUCTION_TEXT;
        vm.preRequired = {
            addPractice: PRACTICE_SETUP_CONSTS.ADD_PRACTICE,
            addLocation: PRACTICE_SETUP_CONSTS.ADD_LOCATION,
            addProvider: PRACTICE_SETUP_CONSTS.ADD_PROVIDER
        };

        // functions

        init();

        function init() {
            PracticeSetupView.configure({
                title: 'WELCOME!'
            });
        }
    }

})();
