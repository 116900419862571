/**
 * Created by nikesh on 10/24/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.LinkExpired', [
            'eAccess.ProviderPortal.constants.LinkExpired'
        ]);
})();
