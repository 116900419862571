(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ChangePassword')
        .factory('ChangePasswordService', ChangePasswordService);

    ChangePasswordService.$inject = ['$log', 'API', 'CHANGE_PASSWORD_CONSTS', 'Utility', 'Storage'];

    function ChangePasswordService($log, API, CHANGE_PASSWORD_CONSTS, Utility, Storage) {
        var service = {
            changePassword: changePassword,
            getErrorMessage: getErrorMessage,
            setChangedPasswordCallBack: setChangedPasswordCallBack,
            executeChangedPasswordCallBacks: executeChangedPasswordCallBacks
        };

        // error message
        var errorMessages = {
            passwordNotMatch: CHANGE_PASSWORD_CONSTS.PASSWORD_NOT_MATCH,
            complexityNotMet: CHANGE_PASSWORD_CONSTS.COMPLEXITY_NOT_MET,
            incorrectPassword: CHANGE_PASSWORD_CONSTS.INCORRECT_PASSWORD,
            recentlyUsedPassword: CHANGE_PASSWORD_CONSTS.RECENTLY_USED_PASSWORD,
            generic: CHANGE_PASSWORD_CONSTS.GENERIC_ERROR,
            required: {
                currentPassword: CHANGE_PASSWORD_CONSTS.REQUIRED.CURRENT_PASSWORD,
                newPassword: CHANGE_PASSWORD_CONSTS.REQUIRED.NEW_PASSWORD,
                confirmPassword: CHANGE_PASSWORD_CONSTS.REQUIRED.CONFIRM_PASSWORD
            }
        };

        var ChangedPasswordCallBacks  = [];

        function changePassword(data) {
            // check if user is logged in
            if(!Utility.isEmpty(Storage.getSessionItem('auth_token'))) {
                return API.post('users/change/password/myAccount', data);
            }
            // api call to change password when expired
            return API.post('users/change/password', data);
        }

        function setChangedPasswordCallBack(callBack) {
            ChangedPasswordCallBacks.push(callBack);
        }

        function executeChangedPasswordCallBacks() {
            angular.forEach(ChangedPasswordCallBacks, function(callBack) {
                callBack();
            });
        }

        function getErrorMessage(errorType) {
            return errorMessages;
        }

        return service;
    }


})();
