/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.Components.Practice.controller:PracticeProviderController
 * @author Sterling Stokes
 * @requires $log
 * @requires PracticeView
 * @description
 *
 * This is the controller for the Practice Providers page.
 **/

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .controller('PracticeProviderController', PracticeProviderController);

    PracticeProviderController.$inject = ['$log', 'PracticeView', 'PatientStyles', 'ProviderService', 'STATUS_CONSTS', 'PROVIDER_CONSTS', '$stateParams', 'ConfigurationService', 'Utility', 'TABLE_NAME_CONSTS', 'PaginationService', 'Search', 'DATA_LIMIT_CONSTS', 'SEARCH_CONSTS', 'SearchPaginationModel', 'SearchModel', 'ToastNotificationService', 'CancelableQ', 'ERROR_MESSAGES', 'ProviderReactivateDeactivate', 'GLOBAL_CONSTS'];

    function PracticeProviderController($log, PracticeView, PatientStyles, ProviderService, STATUS_CONSTS, PROVIDER_CONSTS, $stateParams, ConfigurationService, Utility, TABLE_NAME_CONSTS, PaginationService, Search, DATA_LIMIT_CONSTS, SEARCH_CONSTS, SearchPaginationModel, SearchModel, ToastNotificationService, CancelableQ, ERROR_MESSAGES, ProviderReactivateDeactivate,  GLOBAL_CONSTS) {
        var vm = this;

        // data
        vm.viewableProvidersStatus = STATUS_CONSTS.STATUS.ACTIVE;
        vm.loadingProviders = false;
        vm.searchType = 'provider';
        vm.providers = [];
        vm.deactivatedList = [];
        vm.reactivatedList = [];
        vm.listOfCheckedProviderItems = [];

        vm.providerSubmitted = $stateParams.providerSubmitted;
        vm.selectedAll = false;
        vm.isRoleWithUser = false;

        vm.providerMap = [];
        vm.providerMap[STATUS_CONSTS.STATUS.ACTIVE] = [];
        vm.providerMap[STATUS_CONSTS.STATUS.INACTIVE] = [];
        vm.providerCount = DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE;
        vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
        vm.fetchProviderCount = DATA_LIMIT_CONSTS.DEFAULT_COUNT;
        vm.noDataFound = false;
        vm.disableDeactivateLink = true;
        /**
         *  By default the provider list is sorted in ascending order, default sort by lastName
         */
        vm.sortBy = PROVIDER_CONSTS.PROVIDER_SORT.LAST_NAME;
        vm.searchOffset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
        vm.currentSearchQuery = '';
        vm.filterBy = PROVIDER_CONSTS.FILTER_BY.PROVIDER_ID; // this value is used to filter the arraylist in paginationService

        vm.searchFacets = Search.getSearchFacetForProvider();
        vm.providerReactivateDeactivate = null;
        vm.selectedFacet = null;
        vm.notificationToast = {};
        vm.invalidUrl = $stateParams.invalidUrl;
        vm.nextLink = null;

        //Preserving promise object to cancel, if new data are requested.
        vm.cancelableService = CancelableQ;
        vm.isPromiseCalled = false;

        // function
        vm.toggleViewableStatus = toggleViewableStatus;
        vm.setBenefitStatusClass = PatientStyles.setBenefitStatusClass;
        vm.getProviderStatusToggleText = getProviderStatusToggleText;
        vm.providerSearchCallback = providerSearchCallback;
        vm.clearAllCallback = clearSearchCallback;
        vm.getProviderActionText = getProviderActionText;
        vm.changeProviderStatus = changeProviderStatus;
        vm.updateProviderStatus = updateProviderStatus;
        vm.filterProviderData = filterProviderData;
        vm.getSuccessMessage = getSuccessMessage;
        vm.getErrorMessage = getErrorMessage;
        vm.loadProviders = loadProviders;
        vm.sortProviderData = sortProviderData;


        vm.$onInit = function() {
            if(vm.invalidUrl) {
                _setInvalidURLNotification();
            }

            PracticeView.configure({
                title: 'My Practice |',
                contentTitle: 'Providers',
                tabIndex: 2,
                buttonText: 'Add New Provider',
                buttonHref: 'practice.providers.add'
            });

            vm.providerReactivateDeactivate = new ProviderReactivateDeactivate();

            // check for new provider added
            if (vm.providerSubmitted) {
                _setMessageObject(PROVIDER_CONSTS.SUCCESS_MESSAGE.PROVIDER_ADDED_TITLE, PROVIDER_CONSTS.SUCCESS_MESSAGE.PROVIDER_ADDED_MESSAGE);
            }
            resetSortOrder(); // initially reset sort order
            _fetchUserRole();
            getDefaultPageSize();
        };

        function _setInvalidURLNotification() {
            var toastInfo = Utility.getBadRequestToastInfo();
            _setToastNotification(toastInfo.title, toastInfo.message, toastInfo.type);
        }


        function getDefaultPageSize() {
            vm.loadingProviders = true;
            ConfigurationService.fetchDefaultTablePageSize(TABLE_NAME_CONSTS.PROVIDER_TABLE).then(function(defaultLimit) {
                vm.limit = !Utility.isEmpty(defaultLimit) ? defaultLimit : DATA_LIMIT_CONSTS.DEFAULT_LIMIT;
            }).catch(function(err) {
                vm.limit = DATA_LIMIT_CONSTS.DEFAULT_LIMIT; // this is just in case if 'defaultLimit' not returned from the API
                $log.error('Unable to fetch portal data', err);
            }).finally(function() {
                //even if we do not get the default table page size, we call the loadProviders
                vm.loadingProviders = false;
                loadProviders();
            });
        }

        function _fetchUserRole() {
            vm.isRoleWithUser = ConfigurationService.isUserWithUserRole();
        }

        function toggleViewableStatus() {
            vm.deactivatedList = [];
            vm.reactivatedList = [];
            vm.viewableProvidersStatus = vm.viewableProvidersStatus === STATUS_CONSTS.STATUS.ACTIVE ? STATUS_CONSTS.STATUS.INACTIVE : STATUS_CONSTS.STATUS.ACTIVE;
            vm.selectedAll = false;
            vm.disableDeactivateLink = true;
            vm.loadingProviders = false;
            ToastNotificationService.clearToastNotification();
            clearSearchCallback();
        }

        function clearSearchCallback() {
            vm.searchResultText = null;
            vm.searchQuery = null;
            vm.currentSearchQuery = null;
            vm.providerCount = DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE;
            vm.providerMap[vm.viewableProvidersStatus] = [];
            vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
            vm.nextLink = null;
            vm.searchOffset = DATA_LIMIT_CONSTS.ZERO_LIMIT;
            vm.noDataFound = false;
            vm.sortBy = PROVIDER_CONSTS.PROVIDER_SORT.LAST_NAME; // default sort by lastName
            vm.selectedFacet = null;
            vm.selectedAll = false;
            resetSortOrder();
            vm.loadProviders();
        }

        function getProviderStatusToggleText() {
            return vm.viewableProvidersStatus === STATUS_CONSTS.STATUS.ACTIVE ? PROVIDER_CONSTS.STATUS.INACTIVE_TEXT : PROVIDER_CONSTS.STATUS.ACTIVE_TEXT;
        }

        function loadProviders() {
            var resultListEqualsTotalCount = vm.providerCount === vm.providerMap[vm.viewableProvidersStatus].length;

            if(!Utility.shouldCallAPI(vm.loadingProviders, resultListEqualsTotalCount, vm.noDataFound)) return;
            if(Utility.shouldCallSearchCall(vm.currentSearchQuery)) {
                callForSearchParam();
                return;
            }

            //Canceling previous call if in progress
            if(vm.isPromiseCalled) {
                vm.cancelableService.cancel();
                vm.listProvider.canceler.resolve();
            }

            vm.loadingProviders = true;
            vm.isPromiseCalled = true;

            vm.listProvider = ProviderService.getProvidersByStatus(_isViewableStatusActive(), vm.fetchProviderCount, vm.limit, vm.offset, vm.sortBy, vm.nextLink);
            vm.cancelableService.promiseObj = vm.cancelableService.wrap(vm.listProvider.promise);

            //this is to execute finally block if multiple promise does not exits and last promise has not been canceled.
            var isPromiseCanceled = false;

            //this checks if the deactive/reactivate link should be disabled or enabled while the providers are being loaded
            _checkDeactivateReactivateProviderLink();

            vm.cancelableService.promiseObj.then(function(providerObj) {
                var paginationResponse = PaginationService.getPaginationResponse(providerObj, vm.providerMap[vm.viewableProvidersStatus], vm.filterBy, vm.offset, vm.limit);
                vm.providerMap[vm.viewableProvidersStatus] = paginationResponse.list;
                vm.providerCount = vm.totalProviderCount = paginationResponse.totalCount;
                vm.nextLink = paginationResponse.nextLink;
            }).catch(function(err) {
                //Recording flag if exception occurred due to force cancellation of promise to avoid executing finally block.
                isPromiseCanceled = err === ERROR_MESSAGES.FORCE_CANCEL;

                $log.error('Could not fetch providers:', err);
                //This value is set only if the first API call fails
                if(!vm.providerMap[vm.viewableProvidersStatus].length) {
                    vm.noDataFound = true;
                }

            }).finally(function() {
                //if promise is force canceled, finally block should not run.
                if(!isPromiseCanceled) {
                    vm.loadingProviders = false;
                    _setProviderWarningMessage();
                    vm.isPromiseCalled = false;
                }
            });
        }

        function callForSearchParam() {
            if(vm.loadingProviders) return;
            vm.loadingProviders = true;
            vm.searchResultText = null;
            //this checks if the deactive/reactivate link should be disabled or enabled while the providers are being loaded
            _checkDeactivateReactivateProviderLink();

            Search.providerSearch(_getSearchParam()).then(function(providerObj) {
                var paginationResponse = PaginationService.getPaginationResponse(providerObj, vm.providerMap[vm.viewableProvidersStatus], vm.filterBy, vm.searchOffset, vm.limit);
                vm.providerCount = paginationResponse.totalCount;
                vm.nextLink = paginationResponse.nextLink;
                vm.providerMap[vm.viewableProvidersStatus] = paginationResponse.list;
                vm.searchResultText = Search.getSearchResultText(vm.providerCount, vm.selectedFacet.searchCategory, vm.currentSearchQuery);
            }).catch(function(err) {
                $log.error('something went wrong searching for provider ', err);
            }).finally(function() {
                vm.loadingProviders = false;
                _setProviderWarningMessage();
            });
        }

        function _isViewableStatusActive() {
            return Utility.lowerCase(vm.viewableProvidersStatus) === STATUS_CONSTS.STATUS.ACTIVE;
        }

        function _getSearchParam() {
            var pagination = new SearchPaginationModel(true, vm.searchOffset, vm.limit, vm.sortBy, vm.nextLink);
            return new SearchModel(pagination, vm.currentSearchQuery, vm.selectedFacet, vm.viewableProvidersStatus);
        }

        function providerSearchCallback(searchCallbackModel) {
            vm.selectedAll = false;
            vm.sortBy = searchCallbackModel.pagination.sortOrder;
            vm.noDataFound = false;
            vm.providerCount = null;
            vm.searchResultText = null;
            vm.selectedFacet = searchCallbackModel.selectedFacet;
            vm.providerMap[vm.viewableProvidersStatus] = [];
            vm.searchOffset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;

            if(Utility.isEmpty(searchCallbackModel.search)) {
                vm.warningMessage = SEARCH_CONSTS.EMPTY_SEARCH_WARNING_MESSAGE;
                vm.noDataFound = true;
                return;
            }

            vm.loadingProviders = true;
            resetSortOrder();
            vm.currentSearchQuery = searchCallbackModel.query;

            searchCallbackModel.search.then(function(providerObj) {
                var paginationResponse = PaginationService.getPaginationResponse(providerObj, vm.providerMap[vm.viewableProvidersStatus], vm.filterBy, vm.searchOffset, vm.limit);
                vm.providerCount = paginationResponse.totalCount;
                vm.nextLink = paginationResponse.nextLink;
                vm.providerMap[vm.viewableProvidersStatus] = paginationResponse.list;
            }).catch(function(err) {
                vm.providerCount = DATA_LIMIT_CONSTS.DEFAULT_COUNT_VALUE;
                $log.error('something went wrong searching for providers', err);
            }).finally(function() {
                vm.searchResultText = Search.getInstructionTextForSearch(vm.providerCount, vm.selectedFacet.searchCategory, vm.currentSearchQuery);
                vm.loadingProviders = false;
                _setProviderWarningMessage();
            });
        }

        function _setProviderWarningMessage() {
            if(vm.providerMap[vm.viewableProvidersStatus].length === 0) {
                vm.noDataFound = true;
                vm.warningMessage = (!Utility.isEmpty(vm.currentSearchQuery) && vm.currentSearchQuery !== '') ?
                    Search.getSearchResultText(vm.providerCount, vm.selectedFacet.searchCategory, vm.currentSearchQuery) :
                    Utility.replaceText(PROVIDER_CONSTS.NO_PROVIDER_MESSAGE,'{STATUS}', vm.viewableProvidersStatus);
            } else {
                vm.noDataFound = false;
            }
        }

        function sortProviderData(sortBy) {
            vm.selectedAll = false;
            vm.nextLink = null;
            vm.providerMap[vm.viewableProvidersStatus] = [];
            // set default offset for both searchOffset and offset
            vm.offset = vm.searchOffset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
            // we might have sorting provider by other field also, so we used switch-case here
            switch(sortBy) {
                case PROVIDER_CONSTS.PROVIDER_SORT.PROVIDER_NAME:
                    vm.dataSortOrders.providerName.isAscending = !vm.dataSortOrders.providerName.isAscending;
                    //negative sign indicates descending order
                    vm.sortBy = vm.dataSortOrders.providerName.isAscending ? PROVIDER_CONSTS.PROVIDER_SORT.LAST_NAME : '-' + PROVIDER_CONSTS.PROVIDER_SORT.LAST_NAME;
                    break;
            }
            vm.loadProviders();
        }

        function getProviderActionText() {
            return _isViewableStatusActive() ? STATUS_CONSTS.ACTION_TEXT.DEACTIVATE_SELECTED : STATUS_CONSTS.ACTION_TEXT.REACTIVATE_SELECTED;
        }

        function changeProviderStatus() {
            _prepareNewSelectedList();
            ToastNotificationService.clearToastNotification();

            /**
             *  Check whether all providers has been selected for deactivate purpose
             *  if yes then display cannot deactivate all modal panel
             * */
            if(_isViewableStatusActive()) {
                var totalCount;
                _getTotalProviderCount().then(function(count) {
                    totalCount = count;
                }).catch(function(error) {
                    totalCount = -1;
                    $log.info("Unable to fetch location total count", error);
                }).finally(function() {
                    if(totalCount === -1 || (vm.listOfCheckedProviderItems.length >= totalCount)) {
                        vm.providerReactivateDeactivate.openCannotReactivateDeactivateModal();
                        return;
                    }
                    _prepareAndOpenReactivateDeactivateModal();
                });
            } else {
                _prepareAndOpenReactivateDeactivateModal();
            }
        }

        function _getTotalProviderCount() {
            var searchProvider = ProviderService.getProvidersInformation(_isViewableStatusActive(), DATA_LIMIT_CONSTS.DEFAULT_COUNT_VALUE, DATA_LIMIT_CONSTS.DEFAULT_LIMIT, DATA_LIMIT_CONSTS.DEFAULT_OFFSET);
            return Search.getTotalCount(searchProvider);
        }

        function _prepareAndOpenReactivateDeactivateModal() {
            vm.providerReactivateDeactivate.resetDynamicSettings();
            vm.providerReactivateDeactivate.setCurrentState(vm.listOfCheckedProviderItems, vm.viewableProvidersStatus);
            vm.providerReactivateDeactivate.setModalTitle(PROVIDER_CONSTS.REACTIVATE_DEACTIVATE_MODAL_TITLE);
            vm.providerReactivateDeactivate.setModalSubTitle();
            vm.providerReactivateDeactivate.openReactivateDeactivateModal().then(function(response) {
                if(response) {
                    var providerToBeChanged = vm.filterProviderData();
                    vm.updateProviderStatus(providerToBeChanged);
                }
            });
        }

        function _prepareNewSelectedList() {
            var newSelectedList = [];
            angular.forEach( vm.listOfCheckedProviderItems, function (provider) {
                var providerToCompare = Utility.isObject(provider) ? provider.providerId : provider;
                newSelectedList.push(Utility.getItemFromArray(vm.providerMap[vm.viewableProvidersStatus], 'providerId', providerToCompare));
            });
            vm.listOfCheckedProviderItems = newSelectedList;
        }

        /**
         *  Function responsible for preparing data to update provider status.
         *  This function filters the provider information to fetch only provider ID and active 'false' for deactivate and 'true' for reactivate
         */
        function filterProviderData() {
            var filteredProviders = {
                providers: []
            };
            var status = vm.viewableProvidersStatus !== STATUS_CONSTS.STATUS.ACTIVE;
            vm.listOfCheckedProviderItems.forEach(function (provider) {
                Utility.pushObjectInCollection({providerId: provider.id, active: status}, filteredProviders.providers);
            });

            return filteredProviders;
        }

        function getSuccessMessage() {
            if (_isViewableStatusActive())
                (vm.listOfCheckedProviderItems.length > 1) ? _setMessageObject(PROVIDER_CONSTS.SUCCESS_MESSAGE.DEACTIVATE_MULTIPLE_PROVIDER_TITLE, PROVIDER_CONSTS.SUCCESS_MESSAGE.DEACTIVATE_MULTIPLE_PROVIDER_MESSAGE) : _setMessageObject(PROVIDER_CONSTS.SUCCESS_MESSAGE.DEACTIVATE_ONE_PROVIDER_TITLE, PROVIDER_CONSTS.SUCCESS_MESSAGE.DEACTIVATE_ONE_PROVIDER_MESSAGE);
            else
                (vm.listOfCheckedProviderItems.length > 1) ? _setMessageObject(PROVIDER_CONSTS.SUCCESS_MESSAGE.REACTIVATE_MULTIPLE_PROVIDER_TITLE, PROVIDER_CONSTS.SUCCESS_MESSAGE.REACTIVATE_MULTIPLE_PROVIDER_MESSAGE) : _setMessageObject(PROVIDER_CONSTS.SUCCESS_MESSAGE.REACTIVATE_ONE_PROVIDER_TITLE, PROVIDER_CONSTS.SUCCESS_MESSAGE.REACTIVATE_ONE_PROVIDER_MESSAGE);
        }

        function _setMessageObject(title, message) {
            _setToastNotification(title, message, GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
        }

        //Error message when both reactivate and deactivate request fails
        function getErrorMessage() {
            _setToastNotification(GLOBAL_CONSTS.TOAST_TITLE.SYSTEM_ERROR, GLOBAL_CONSTS.TOAST_MESSAGE.SYSTEM_MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
        }

        function updateProviderStatus(providerToBeChanged) {
            ProviderService.updateProviderStatus(providerToBeChanged)
                .then(function (response) {
                    vm.getSuccessMessage();
                    vm.reactivatedList = [];
                    vm.deactivatedList = [];
                    vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
                    vm.nextLink = null;
                    vm.searchOffset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
                    vm.providerMap[vm.viewableProvidersStatus] = [];
                    vm.selectedAll = false;
                    vm.loadProviders();
                }).catch(function (errorResponse) {
                    vm.getErrorMessage();
                });
        }

        function resetSortOrder() {
            vm.dataSortOrders = {
                providerName: {isAscending: true}
            };
        }

        /**
         * Function to disable the deactivate and reactivate provider link
         */
        function _checkDeactivateReactivateProviderLink() {
            vm.disableDeactivateLink = vm.listOfCheckedProviderItems.length === 0;
        }

        function _setToastNotification(header, message, type) {
            ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, header, message, type));
        }
    }
})();
