(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Login')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$timeout', '$log', '$state', 'Auth', 'OKTA_CONSTS', 'ERROR_MESSAGES', 'ConfigurationService', 'STATUS_CONSTS', '$stateParams', 'EmailService', 'PASSWORD_EXPIRED_CONSTS', 'BVSelectionService', 'HeaderService', 'LOGIN_CONSTS', 'Utility', '$uibModal', 'moment', 'GLOBAL_CONSTS', 'UserSessionEvent', 'Storage', '$injector', 'ToastNotificationService', 'SystemNotificationService', 'SystemNotification', 'ENV', 'BRAND_IMAGE_CONSTS'];

    function LoginController($rootScope, $timeout, $log, $state, Auth, OKTA_CONSTS, ERROR_MESSAGES, ConfigurationService, STATUS_CONSTS, $stateParams, EmailService, PASSWORD_EXPIRED_CONSTS, BVSelectionService, HeaderService, LOGIN_CONSTS, Utility, $uibModal, moment, GLOBAL_CONSTS, UserSessionEvent, Storage, $injector, ToastNotificationService, SystemNotificationService, SystemNotification, ENV, BRAND_IMAGE_CONSTS) {
        var vm = this;

        // functions
        vm.login = login;
        vm.init = init;

        // data
        vm.bannerText = 'Welcome!';
        vm.password = null;
        vm.username = null;
        vm.usernameWithDomain = null;
        vm.loginButtonText = getLoginButtonText();
        vm.loggingIn = false;
        vm.errorMessage = 'Something went wrong. Please try again later.';
        vm.invalidCredentials = false;
        vm.invalidRegistrationCode = false;
        vm.passwordExpiryDay = null;
        vm.heroImage = ENV.API_URL + BRAND_IMAGE_CONSTS.API + BRAND_IMAGE_CONSTS.KEYS.HERO_IMAGE;
        vm.init();

        function init() {
            var errorMessage = $stateParams.errorMessage;
            if(!Utility.isEmpty($stateParams.sessionExpired) && $stateParams.sessionExpired) {
                ToastNotificationService.setToastNotification(
                    ToastNotificationService.buildToastNotification(
                        true,
                        LOGIN_CONSTS.SESSION_EXPIRE.TITLE,
                        LOGIN_CONSTS.SESSION_EXPIRE.MESSAGE,
                        GLOBAL_CONSTS.TOAST_TYPES.ERROR
                    )
                );
            }
            if(errorMessage) {
                vm.invalidRegistrationCode = true;
                vm.errorMessage = errorMessage;
            }
            fetchConfiguration();
        }

        function fetchConfiguration() {
            ConfigurationService.fetchConfiguration().then(function(publicData) {
                $log.debug('header publicData', publicData);
                assignPublicData(publicData);
                SystemNotificationService.displayPublicNotification();
            });
        }

        function assignPublicData(publicData) {
            vm.welcome = {
                welcomeBanner: publicData.welcomeBanner,
                welcomeHeaderContent: publicData.welcomeHeaderContent,
                welcomePageContent: publicData.welcomePageContent
            };
            vm.usernameDomain = publicData.usernameDomain;
        }

        // Focus the username field on load
        $timeout(function() {
            angular.element('#username').focus();
        });

        function login() {
            // Safety check username and password exists
            // in case a browser does not support `required`
            // looking at you Safari
            if(!vm.username || !vm.password) {
                vm.errorMessage = ERROR_MESSAGES.UNAUTHORIZED;
                vm.invalidCredentials = true;
                return;
            }
            vm.loggingIn = true;
            var prevButtonText = vm.loginButtonText;
            vm.loginButtonText = LOGIN_CONSTS.DISPLAY_TEXT.LOGGING_IN_TEXT;
            vm.usernameWithDomain = vm.username + vm.usernameDomain;
            _resetErrorMessage();
            Auth.login({username: vm.usernameWithDomain, password: vm.password}).then(function(user) {
                // Clear the cached data every time a user logs in
                _clearCachedValues();
                if(user.status === STATUS_CONSTS.ACCOUNT_STATUS.LOCKED) {
                    goToAccountLocked(user.status, prevButtonText);
                } else if(user.status === STATUS_CONSTS.ACCOUNT_STATUS.PASSWORD_EXPIRED) {
                    goToPasswordExpired(user.status, prevButtonText);
                } else {
                    _isPasswordExpiring(user, prevButtonText);
                }
            }).catch(function(err) {
                _isUserAccountInActive(err, prevButtonText);
            });
        }

        function _resetLoginButtonText(prevButtonText) {
            vm.loginButtonText = prevButtonText;
            vm.loggingIn = false;
        }

        function _isUserAccountInActive(err, prevButtonText) {
            Auth.isUserAccountInActive(vm.username).then(function (status) {
                if(!status) {
                    showAccountDeactivatedModal(prevButtonText);
                    return;
                }
                $log.debug("User status is active but unable to login");
                _handleLoginError(err);
            }).catch(function(e) {
                $log.error("Unable to get user status ", e);
                // handle the error response from previous call
                _handleLoginError(err);
            }).finally(function () {
                _resetLoginButtonText(prevButtonText);
            });
        }

        function _handleLoginError(err) {
            $log.error('error ', err);

            if (err.errorCode === OKTA_CONSTS.ERROR_CODES.UNAUTHORIZED) {
                vm.errorMessage = ERROR_MESSAGES.UNAUTHORIZED;
            } else if (err.status === OKTA_CONSTS.ERROR_CODES.PASSWORD_EXPIRED) {
                vm.errorMessage = ERROR_MESSAGES.PASSWORD_EXPIRED;
            } else {
                vm.errorMessage = ERROR_MESSAGES.DEFAULT_LOGIN_ERROR;
            }
            vm.invalidCredentials = true;
        }

        /**
         * function to open password expiration warning, if password is expiring with in 3 days
         * @param user
         * @param prevButtonText
         * @private
         */
        function _isPasswordExpiring(user, prevButtonText) {
            // Fetch expiration date
            ConfigurationService.fetchPortalConfiguration().then(function(portalConfigResponse) {
                vm.passwordExpiryDay = parseInt(portalConfigResponse.orgPropertiesMap.maxPasswordAge);

                UserSessionEvent.saveSessionRefreshConfig(portalConfigResponse.orgPropertiesMap.refreshBeforeTimeout);

                // Date: when the password will expire
                var passwordExpiringDate = _getPasswordExpiringDate(user[2].passwordChanged);
                // No. of minutes from current date to expiry date
                var remainingMinutesForPasswordExpire = Utility.getDifferenceBetweenTwoDate(new Date(), passwordExpiringDate);

                // Date: 3 days before expiry date (Date to notify on)
                var passwordExpiryNotificationDate = new Date(passwordExpiringDate);
                passwordExpiryNotificationDate.setDate(passwordExpiryNotificationDate.getDate() - PASSWORD_EXPIRED_CONSTS.NOTIFY_BEFORE_DAYS);
                // No. of minutes from date to notify on to expiry date
                var remainingMinutesToNotify = Utility.getDifferenceBetweenTwoDate(passwordExpiryNotificationDate, passwordExpiringDate);

                if (vm.passwordExpiryDay && (remainingMinutesForPasswordExpire <= remainingMinutesToNotify && remainingMinutesForPasswordExpire >= 0))
                    showPasswordExpiringModal(passwordExpiringDate, prevButtonText);
                else
                    getBVData(prevButtonText);
            }).catch(function(err) {
                $log.debug('failed to fetch portal configuration', err);
                getBVData(prevButtonText);
            });
        }

        /**
         * function to return the password expiring date
         * @param passwordChanged
         * @returns {Date}
         * @private
         */
        function _getPasswordExpiringDate(passwordChanged) {
            var passwordExpiringDate = new Date(passwordChanged);
            passwordExpiringDate.setDate(passwordExpiringDate.getDate() + vm.passwordExpiryDay);
            return passwordExpiringDate;
        }

        /**
         * function to show password expiring modal if user password is going to be expired
         * @param passwordExpiringDate
         * @param prevButtonText
         */
        function showPasswordExpiringModal(passwordExpiringDate, prevButtonText) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'partials/templates/password-expiry-modal.html',
                controller: 'PasswordExpiryController',
                controllerAs: 'passwordExpiry',
                appendTo: angular.element('body'),
                resolve: {
                    modalTitle: function() {
                        return PASSWORD_EXPIRED_CONSTS.DISPLAY_TEXT.PASSWORD_EXPIRING_SOON;
                    },
                    modalBody: function() {
                        var formattedDate = moment(passwordExpiringDate).format(GLOBAL_CONSTS.DATE_FORMAT.MM_DD_YYYY.SLASH);
                        return PASSWORD_EXPIRED_CONSTS.DISPLAY_TEXT.PASSWORD_EXPIRING_TEXT.replace('__password_expiry_date__', formattedDate);
                    },
                    okButtonText: function() {
                        return PASSWORD_EXPIRED_CONSTS.EXPIRING_PASSWORD.CHANGE_PASSWORD_BUTTON;
                    },
                    cancelButtonText: function() {
                        return PASSWORD_EXPIRED_CONSTS.EXPIRING_PASSWORD.CANCEL_CHANGE_PASSWORD_BUTTON;
                    }
                }
            });

            modalInstance.result.then(
                function() {
                    _resetLoginButtonText(prevButtonText);
                    $state.go('changePassword');
                },
                function() {
                    getBVData(prevButtonText);
                }
            );
        }

        function getBVData(prevButtonText) {
            // Fetch required configuration data
            BVSelectionService.getBvMethod().then(angular.noop).catch(angular.noop)
                .finally(function() {
                    Auth.isPracticeDefined().then(function(data) {
                        (data === true) ? goToDashboard() : goToPracticeSetup();
                    }).catch(function(error) {
                        $log.error("Error Getting User Practice", error);
                        goToDashboard();
                    }).finally(function() {
                        afterRedirect();
                    });
                });
        }

        function showAccountDeactivatedModal(prevButtonText) {
            _resetLoginButtonText(prevButtonText);
            $uibModal.open({
                animation: true,
                templateUrl: 'partials/templates/user-account-deactivated-modal.html',
                controller: 'AccountDeactivatedController',
                controllerAs: 'accountDeactivated',
                appendTo: angular.element('body'),
                resolve: {
                    modalTitle: function() {
                        return LOGIN_CONSTS.ACCOUNT_DEACTIVATED.HEADER;
                    },
                    modalBody: function() {
                        return LOGIN_CONSTS.ACCOUNT_DEACTIVATED.MESSAGE;
                    }
                }
            });
        }

        function goToAccountLocked(status, prevButtonText) {
            var emailRequest = _prepareEmailRequestObject(status);
            EmailService.sendEmail(emailRequest).then(function(response) {
                $state.go('account-locked', {accountLockedResendEmailObject: emailRequest});
            }).catch(function(err) {
                $log.error('Unable to send email ', err);
                _handleLoginError(err);
            }).finally(function() {
                _resetLoginButtonText(prevButtonText);
            });
        }

        function goToPasswordExpired(status, prevButtonText) {
            var emailRequest = _prepareEmailRequestObject(status);
            EmailService.sendEmail(emailRequest).then(function(response) {
                $state.go('passwordExpired', {passwordExpiredResponseObject: emailRequest});
            }).catch(function(err) {
                $log.error('Unable to send email ', err);
                _handleLoginError(err);
            }).finally(function() {
                _resetLoginButtonText(prevButtonText);
            });
        }

        function goToDashboard() {
            UserSessionEvent.initialize();
            SystemNotificationService.showPortalNotification = true;
            $state.go('dashboard');
        }

        function goToPracticeSetup() {
            UserSessionEvent.initialize();
            Storage.saveToSession('isPracticeSetup', true);
            $state.go("practice-setup.welcome");
        }

        function getLoginButtonText() {
            return LOGIN_CONSTS.DISPLAY_TEXT.LOG_IN_TEXT;
        }

        function afterRedirect() {
            HeaderService.changeLoggedInUserInfo();
            vm.invalidCredentials = false;
            vm.invalidRegistrationCode = false;
            $rootScope.invalidCredentials = vm.invalidCredentials;
        }

        function _resetErrorMessage() {
            vm.errorMessage = '';
        }

        function _prepareEmailRequestObject(status) {
            var requestFor = '';
            switch(status) {
                case STATUS_CONSTS.ACCOUNT_STATUS.LOCKED:
                    requestFor = STATUS_CONSTS.EMAIL_TYPE.ACCOUNT_LOCKED;
                    break;

                case PASSWORD_EXPIRED_CONSTS.REQUEST_STATUS.PASSWORD_EXPIRED:
                    requestFor = STATUS_CONSTS.EMAIL_TYPE.PASSWORD_EXPIRED;
                    break;
            }

            return {
                userName: vm.username,
                type: requestFor
            };
        }

        /*
         * Function responsible for clearing the cached data's
         */
        function _clearCachedValues() {
            var productService = $injector.get('Product');
            if (productService){
                productService.clearProductList();
            }
            BVSelectionService.setBvMethod(null);
        }
    }
})();
