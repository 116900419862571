/**
 * @ngdoc Controller
 * @module eAccess.ProviderPortal.Components.RxEligibilityResponse
 * @name PatientInformationResponseController
 * @kind Controller
 *
 * @description
 * Controller for the patient informations section
 * @author girianish
 */

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .controller('PatientInformationResponseController', PatientInformationResponse);

    PatientInformationResponse.$inject = ['HELP_TEXT_CONSTS', 'Utility', 'PatientService'];

    function PatientInformationResponse(HELP_TEXT_CONSTS, Utility, PatientService) {
        var vm = this;

        vm.$onInit = function () {
            vm.patientFullName = Utility.getFullName({
                firstName: vm.patientResponse.firstName,
                middleName: vm.patientResponse.middleName,
                lastName: vm.patientResponse.lastName
            });
        };

        vm.helpText = {
            practicePatientId: HELP_TEXT_CONSTS.BV_REQUEST.PATIENT_INFO.PRACTICE_PATIENT_ID,
            ssn: HELP_TEXT_CONSTS.BV_REQUEST.PATIENT_INFO.SOCIAL_SECURITY
        };

        vm.isMedicareIdDisplayable = isMedicareIdDisplayable;
        vm.ssnOrMedicareId = PatientService.getSsnOrMedIdDisplayValue(vm.patientResponse);

        function isMedicareIdDisplayable() {
            return (Utility.isRx(vm.bvMethod) && Utility.isRxApiTypeSplit(vm.rxApiType));
        }
    }


})();
