(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilitySplitResponse')
        .component('eaPolicyHeaderDetails', {
            bindings: {
                response: '<',
                policy: '<',
                displayPharmacy: '<'
            },
            templateUrl: 'components/eligibility/bv-selection/rx/split-response/policy-header-details/policy-header-details.html',
            controller: 'PolicyHeaderDetailsController as policyHeaderDetailsCtrl'
        });
})();
