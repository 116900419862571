(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components')
        .factory('ProviderModel', ProviderModel);

    ProviderModel.$inject = [];

    function ProviderModel() {

        function ProviderModelInstance(provider, isAddProvider) {
            this.providerId = provider.providerId || null;
            this.active = provider.active || null;
            this.firstName = provider.firstName || null;
            this.lastName = provider.lastName || null;
            this.middleName = provider.middleName || null;
            this.npi = provider.npi || null;
            this.practice = provider.practice || null;
            this.practiceProviderId = provider.practiceProviderId || null;
            this.id = provider.providerId || null;
            this.state = provider.state || null;
            this.taxId = provider.taxId || null;
            if(!isAddProvider)
                this.fullNameNpi = this.lastName + ', ' + this.firstName + (this.middleName ? ' ' + this.middleName : ' ') + ' | ' + this.npi;
        }

        return ProviderModelInstance;

    }

})();
