(function() {
    'use strict';

    var ERROR_MESSAGES = {
        // HTTP Errors
        BAD_REQUEST: 'Invalid request could not be processed',
        UNAUTHORIZED: 'Invalid Username and/or Password. Please try again.',
        FORBIDDEN: 'Forbidden',

        // App errors
        DATE_IN_FUTURE: 'Date of Birth must be in the past',
        DEFAULT_LOGIN_ERROR: 'We were unable to log you in at this time. Please try again later.',
        // Adding this in case this text changes
        NOT_AVAILABLE: 'N/A',
        EBV_ERROR_GENERIC: 'An error has occurred while running the eBV. Please correct any errors in the entered information.',
        BV_ERROR: {
            TITLE: 'Request Not Sent',
            MESSAGE: 'A system error has occurred, and your request has not been submitted. Try clicking the Verify Benefits button again to resubmit your request. If you continue to receive this message, please contact Customer Support.',
            MESSAGE_SUPPORT_DETAILS: 'Request not sent. For further assistance in confirming individual patient coverage when the portal does not provide a response, please contact the GSK Vaccines Reimbursement Support Center at 1-855-636-8291, or find information online at:\n' +
                '\n' +
                'https://gskpro.com/enus/therapyareas/vaccines/coding/resources'
        },
        PASSWORD_EXPIRED: 'Your password has expired. Please contact customer support to reset your password.',
        //REGISTRATION TOKEN ERRORS
        INVALID_REGISTRATION_CODE: 'Invalid registration code.',
        NO_ACCESS_MESSAGE: 'You do not have access to do this. Talk to your Practice Admin for further details.',
        ACCESS_DENIED: 'Access Denied',
        NO_RESPONSE: 'No response was returned',
        PRACTICE_SET_MESSAGE: 'Your user account has already been created, and your practice has been set up. Please log in to continue.',
        PASSWORD_SET_MESSAGE: 'Your User account has already been created. Please log in to finish setting up your practice.',
        EMAIL_LINK_404: 'An error has occurred. Please enter your Username and Password to log in. If you have forgotten your Password, please click the Forgot Password link. If you need additional assistance, please contact Customer Support.',
        GENERIC_ERROR: 'Your request was not processed due to a system error. Please try again. If you continue to receive this message, please contact Customer Support.',
        PRODUCT_NOT_COVERED_MESSAGE: "This drug is not covered under this plan because it is inactive.",
        SOB_ERROR: {
            TYPE: 'error',
            TITLE: 'System Error'
        },
        NOT_COVERED_ERROR: {
            TITLE:'Coverage Not Found',
            MESSAGE:'We were unable to find Medical Benefit Coverage or Pharmacy Benefit Coverage for this patient. If you feel this is an error, you may initiate another verification.',
        },
        COVERED_ERROR: {
            TITLE:'Coverage Found',
            MESSAGE:'We have found the coverage for this patient. This transaction was automatically initiated to find the best coverage for the patient. If you feel this is in error, you may initiate another verification.'
        },
        FORCE_CANCEL: 'forceCancel',
        NOT_COVERED_WITH_NULL_PAYER_ERROR: {
            TITLE: 'Coverage Not Found',
            MESSAGE: 'We are unable to find Pharmacy Benefit Coverage. For verifying Medical Benefits, make sure you provide Insurance Information'
        }
    };

    angular.module('eAccess.ProviderPortal').constant('ERROR_MESSAGES', ERROR_MESSAGES);
})();
