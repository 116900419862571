/**
 * Created by aayush.regmi on 10/19/2017.
 */
(function() {
    'use strict';

    var VALUES = {
        REGISTRATION_METHOD: {
            NO_REGISTRATION: '1000001',
            UNIQUE_CODE:'1000002',
            SINGLE_GENERIC_CODE:'1000003'
        },
        COMPLIANCE_METHOD: {
            HIPAA_BAA: 'HIPAA_BAA',
            PATIENT_CONSENT: 'PATIENT_CONSENT'
        }
    };

    angular.module('eAccess.ProviderPortal').constant('REGISTRATION_CONSTS', VALUES);
})();
