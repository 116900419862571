(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.BvHeader')
        .component('eaBvHeader', {
            templateUrl: 'components/bv-header/bv-header.html',
            controller: 'BvHeaderController as bvHeader',
            bindings: {
                bvMethod: '<',
                helpText: '<'
            }
        });

})();
