/**
 * Created by aayush.regmi on 10/17/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PracticeSetup')
        .factory('PracticeSetupCompleteService', PracticeSetupCompleteService);

    PracticeSetupCompleteService.$inject = ['$log', '$q', 'API'];

    function PracticeSetupCompleteService($log, $q, API) {
        var service = {
            fetchUserDetails: fetchUserDetails
        };

        function fetchUserDetails() {
            return API.get('/users/me').then(function(response) {
                return response;
            });
        }

        return service;
    }
})();
