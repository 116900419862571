(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .factory('PracticeUserReactivateDeactivate', PracticeUserReactivateDeactivate);

    PracticeUserReactivateDeactivate.$inject = ['$log', 'ReactivateDeactivate', 'USER_CONSTS', 'Utility'];

    function PracticeUserReactivateDeactivate($log, ReactivateDeactivate, USER_CONSTS, Utility) {
        
        var userReactivateDeactivateData = {
            reactivate : {
                multipleMessage: USER_CONSTS.CONFIRMATION_MESSAGE.REACTIVATE_MULTIPLE_USER,
                singleMessage: USER_CONSTS.CONFIRMATION_MESSAGE.REACTIVATE_ONE_USER,
                replaceString: "#users"
            },
            deactivate: {
                multipleMessage: USER_CONSTS.CONFIRMATION_MESSAGE.DEACTIVATE_MULTIPLE_USER,
                singleMessage: USER_CONSTS.CONFIRMATION_MESSAGE.DEACTIVATE_ONE_USER,
                replaceString: "#users"
            },
            cannotDeactivate: {
                modalTitle: USER_CONSTS.WARNING_MESSAGE.LOGGED_IN_USER.TITLE,
                modalBody: USER_CONSTS.WARNING_MESSAGE.LOGGED_IN_USER.MESSAGE,
                displaySecondaryButton: true
            }
        };

        function PracticeUserReactivateDeactivateInstance() {
            ReactivateDeactivate.call(this, userReactivateDeactivateData);
        }

        function _setSubTitleText() {
            this.modalSubTitle = (this.list.length === 1) ?
                Utility.getFullName(this.list[0]) : '';
            
        }

        PracticeUserReactivateDeactivateInstance.prototype = Object.create(ReactivateDeactivate.prototype);

        PracticeUserReactivateDeactivateInstance.prototype.setModalSubTitle = function () {
            _setSubTitleText.call(this);
        };

        return PracticeUserReactivateDeactivateInstance;
    }
})();
