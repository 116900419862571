(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .component('eaServiceDeliveryInformation', {
            templateUrl: 'components/service-delivery/service-delivery.html',
            controller: 'ServiceDeliveryController as service',
            bindings: {
                parentForm: '=',
                serviceDeliveryInformation: '=',
                selectedProduct: '<',
                changeSelectedProduct: '=',
                selectedLocation: '=',
                isAio: '=',
                ehipaaFormProcessing: '=',
            }
        });

})();
