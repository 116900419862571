(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Modals.PharmacyListModal', [])
        .controller('PharmacyListModalController', PharmacyListModalController);

    PharmacyListModalController.$inject = ['$uibModalInstance', 'pharmacyList', 'PHARMACY_CONSTS', 'PharmacyService', 'Storage'];

    function PharmacyListModalController($uibModalInstance, pharmacyList, PHARMACY_CONSTS, PharmacyService, Storage) {
        var vm = this;

        // data
        vm.closeModal = closeModal;
        vm.currentPage = PHARMACY_CONSTS.PAGING_CONFIG.PAGE_NUMBER;
        vm.pageSize = PHARMACY_CONSTS.PAGING_CONFIG.PAGE_SIZE;
        vm.pageSizeOptions = PHARMACY_CONSTS.PAGING_CONFIG.PAGE_SIZE_OPTIONS;
        vm.isPageSizeChanged = false;
        vm.maxSize = 5;

        // functions
        vm.getPharmacyList = getPharmacyList;
        vm.searchAgain = searchAgain;
        vm.pageChanged = pageChanged;
        vm.pageSizeChanged = pageSizeChanged;
        vm.selectedPharmacy = selectedPharmacy;
        vm.getSearchFilters = getSearchFilters;
        vm.getPharmacyRequestObject = getPharmacyRequestObject;

        vm.$onInit = function () {
            vm.pharmacyErrorResponse = pharmacyList.searchPharmacyResponse.pharmacyResponseStatus.message || '';
            vm.pharmacyList = pharmacyList.searchPharmacyResponse.response;
            vm.totalItems = pharmacyList.totalCount;
        };

        function closeModal(event) {
            // pass (if) selected pharmacy to parent controller to display in radio button
            $uibModalInstance.close({
                event: event === PHARMACY_CONSTS.SEARCH_AGAIN ? PHARMACY_CONSTS.SEARCH_AGAIN : '' ||
                    angular.isObject(event) ? PHARMACY_CONSTS.PHARMACY_SELECTED : '', pharmacyObject: event
            });
        }

        function getPharmacyList(params) {
            PharmacyService.getPharmacies(params).then(function (pharmacyResponse) {
                vm.pharmacyList = pharmacyResponse.searchPharmacyResponse.response;
            });
        }

        function searchAgain(event) {
            closeModal(event);
        }

        function pageChanged() {
            // get filters from local storage
            var searchFilters = getSearchFilters();
            var params = getPharmacyRequestObject(searchFilters);
            getPharmacyList(params);
        }

        function pageSizeChanged() {
            vm.isPageSizeChanged = true;
            var searchFilters = getSearchFilters();
            var params = getPharmacyRequestObject(searchFilters);
            getPharmacyList(params);
            vm.isPageSizeChanged = false;
        }

        function selectedPharmacy(pharmacy) {
            closeModal(pharmacy);
        }

        function getSearchFilters() {
            var searchItems = Storage.getLocalItem(PHARMACY_CONSTS.SEARCH_ITEMS);
            return angular.fromJson(searchItems);
        }

        function getPharmacyRequestObject(searchFilters) {
            // since spread operator is not allowed by the linter, so using the alternative.
            // since Object.assign is not allowed by our spec test using the alternative.
            var pharmacyRequestObject = angular.copy(searchFilters);
            pharmacyRequestObject.maxResult = vm.pageSize;
            pharmacyRequestObject.pageNumber = vm.isPageSizeChanged ? 0 : vm.currentPage - 1;
            vm.currentPage = vm.isPageSizeChanged ? 0 : vm.currentPage;
            return pharmacyRequestObject;
        }
    }
})();
