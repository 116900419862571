/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.Components.Practice.Provider.View.controller:PracticeProviderViewController
 * @author Pramesh Bhattarai
 * @requires $log
 * @requires PracticeView
 * @description
 *
 * This is the controller for the Practice Provider Add page.
 **/

(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .controller('PracticeProviderViewController', PracticeProviderViewController);

    PracticeProviderViewController.$inject = ['$log', 'PracticeView', 'HELP_TEXT_CONSTS', 'ERROR_MESSAGES', 'providerResponse'];

    function PracticeProviderViewController($log, PracticeView, HELP_TEXT_CONSTS, ERROR_MESSAGES, providerResponse) {
        var vm = this;

        // data
        vm.provider = {};
        vm.loadingProvider = false;
        vm.errorMessages = {
            loadProvider: {
                display: false,
                text: 'There was an error loading the provider. Please try again.'
            },
            notAvailable: ERROR_MESSAGES.NOT_AVAILABLE
        };

        // Help text for help icon tooltips
        vm.helpText = {
            state: HELP_TEXT_CONSTS.PROVIDER.STATE,
            npi: HELP_TEXT_CONSTS.PROVIDER.NPI,
            taxId: HELP_TEXT_CONSTS.PROVIDER.TAX_ID,
            practiceProviderId: HELP_TEXT_CONSTS.PROVIDER.PRACTICE_PROVIDER_ID
        };

        // function
        vm.$onInit = function() {
            _loadProvider(providerResponse);
        };

        function _loadProvider(provider) {
            vm.provider = provider;
            vm.provider.name = {};
            vm.provider.name = vm.provider.firstName + " " + vm.provider.lastName;
            if (angular.isDefined(vm.provider.middleName) && vm.provider.middleName !== null) {
                vm.provider.name = vm.provider.firstName + " " + vm.provider.middleName + " " + vm.provider.lastName;
            }
            PracticeView.configure({
                title: 'My Practice | Providers',
                contentTitle: vm.provider.name,
                tabIndex: 2,
                editText: 'edit',
                editHref: 'practice.providers.edit',
                editId: vm.provider.providerId,
                editViewableStatus: vm.provider.active
            });
            vm.errorMessages.loadProvider.display = true;
        }

    }
})();
