/**
 * Created by nikesh on 3/11/2018.
 */
(function() {
    'use strict';

    var VALUES = {
        VALIDATION: {
            SSN_MAX_LENGTH: 4,
            SSN_MIN_LENGTH: 4,
            PATIENT_ID_MIN_LENGTH: 2,
            PATIENT_ID_MAX_LENGTH: 30,
            PATIENT_FIRST_NAME_MIN_LENGTH: 1,
            PATIENT_FIRST_NAME_MAX_LENGTH: 35,
            PATIENT_MIDDLE_NAME_MIN_LENGTH: 1,
            PATIENT_MIDDLE_NAME_MAX_LENGTH: 15,
            PATIENT_LAST_NAME_MIN_LENGTH: 1,
            PATIENT_LAST_NAME_MAX_LENGTH: 35,
            MEDICARE_ID_REGEX: new RegExp('^([0-9]{4}|[1-9][AC-HJKMNP-RT-Yac-hjkmnp-rt-y][AC-HJKMNP-RT-Yac-hjkmnp-rt-y0-9][0-9]-?[AC-HJKMNP-RT-Yac-hjkmnp-rt-y][AC-HJKMNP-RT-Yac-hjkmnp-rt-y0-9][0-9]-?[AC-HJKMNP-RT-Yac-hjkmnp-rt-y]{2}\\d{2}|\\d{3}-?\\d{2}-?\\d{4}-?[A-Za-z0-9]{1,2})$'),
            SSN_LENGTH: 4
        },
        ERROR_MESSAGES: {
            SSN_MIN_LENGTH: 'SSN must be exactly #maxLength digits',
            SSN_MAX_LENGTH: 'SSN must be exactly #maxLength digits',
            PATIENT_ID_MIN_LENGTH: 'Patient ID must be between #minLength and #maxLength characters',
            PATIENT_ID_MAX_LENGTH: 'Patient ID must be between #minLength and #maxLength characters',
            PATIENT_ID_DUPLICATE: 'A Patient with that Practice Patient ID already exists.',
            PATIENT_FIRST_NAME_MAX_LENGTH: 'First Name can be no more than #maxLength characters',
            PATIENT_FIRST_NAME_REQUIRED: 'First Name is required',
            PATIENT_MIDDLE_NAME_MAX_LENGTH: 'Middle Name can be no more than #maxLength characters',
            PATIENT_LAST_NAME_MAX_LENGTH: 'Last Name can be no more than #maxLength characters',
            PATIENT_LAST_NAME_REQUIRED: 'Last Name is required',
            PATIENT_DOB_REQUIRED: 'Date of Birth is required',
            PATIENT_DOB_MASK: 'Date format must be mm/dd/yyyy and include 8 digits',
            PATIENT_DOB_IN_PAST: 'Date of Birth must be 01/01/1900 or later',
            PATIENT_DOB_INVALID_DATE: 'Invalid Date of Birth',
            PATIENT_ZIP_CODE_INVALID_LENGTH: 'Invalid Zip Code',
            PATIENT_ZIP_CODE_REQUIRED: 'Zip Code is required',
            PATIENT_GENDER_REQUIRED: 'Gender is required',
            MEDICARE_ID_INVALID: 'Invalid Medicare Beneficiary ID',
            SSN_LENGTH: 'SSN must be exactly #minLength digits',
            PATIENT_SSN_OR_MEDICARE_ID_REQUIRED: 'Medicare Beneficiary ID or Last 4 Digits of SSN is required',
        }
    };

    angular.module('eAccess.ProviderPortal.Constants.PatientInfoConstants', [])
        .constant('PATIENT_INFO_CONSTS', VALUES);
})();
