(function() {
    'use strict';

    var VALUES = {
        API: 'files/',
        QUERY: '?host=',
        KEYS: {
            PUBLIC_HEADER_LOGO: 'publicheaderlogo',
            PUBLIC_FOOTER_LOGO: 'publicfooterlogo',
            INTERIOR_HEADER_LOGO: 'interiorheaderlogo',
            INTERIOR_FOOTER_LOGO: 'interiorfooterlogo',
            HERO_IMAGE: 'heroimage',
            DASHBOARD_WIDGET_LOGO: 'dashboardwidgetlogo'
        }
    };

    angular.module('eAccess.ProviderPortal').constant('BRAND_IMAGE_CONSTS', VALUES);
})();
