(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ToastNotification')
        .factory('ToastNotification', ToastNotification);

    ToastNotification.$inject = ['Utility'];

    function ToastNotification(Utility) {
        var _defaultSettings = {
            display: false,
            title: '',
            message: '',
            toastType: ''
        };

        function ToastNotificationInstance(display, title, message, toastType) {
            this.display = Utility.isEmpty(display) ? _defaultSettings.display : display;
            this.title = title || _defaultSettings.title;
            this.message = message || _defaultSettings.message;
            this.toastType = toastType || _defaultSettings.toastType;
        }

        return ToastNotificationInstance;
    }

})();
