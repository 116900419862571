/**
* Created by alee on 2/27/17.
*/
(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('docs', {
                url: '/docs',
                abstract: true,
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Docs'
                },
                views: {
                    'content@': {
                        template: '<ui-view>'
                    }
                }
            });
    }
})();

