/**
 * Created by pramesh on 6/12/2018.
 */
(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .factory('ServiceInformationModel', ServiceInformationModel);

    ServiceInformationModel.$inject = ['Utility'];

    function ServiceInformationModel(Utility) {
        function ServiceInformationInstance(provider, placeOfService, patientDiagnosis) {

            this.provider = {};
            if (!Utility.isEmpty(provider)) {
                this.provider.firstName = provider.firstName;
                this.provider.middleName = provider.middleName;
                this.provider.lastName = provider.lastName;
                this.provider.id = provider.providerId;
                this.provider.npi = provider.providerNPI;
                this.provider.state = provider.providerState;
            }

            if (!Utility.isEmpty(placeOfService)) {
                this.placeOfService = placeOfService;
            }

            if (!Utility.isEmpty(patientDiagnosis)) {
                this.patientDiagnosis = patientDiagnosis;
            }
        }

        return ServiceInformationInstance;
    }
})();
