/**
 * Created by pratik.budhathoki on 2/28/2018.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.BvSelection')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('bvSelection', {
                url: '/eligibility',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Select BV Method'
                },
                params: {
                    'patient': '',
                },
                views: {
                    'content@': {
                        templateUrl: 'components/eligibility/bv-selection/bv-selection.html',
                        controller: 'BvSelectionController as bvSelection'
                    }
                },
                resolve: {
                    bvMethod: ['$log', 'ConfigurationService', 'Utility', function($log, ConfigurationService, Utility) {
                        return ConfigurationService.fetchPortalConfiguration()
                            .then(
                                function(response) {
                                    if(!Utility.isEmpty(response)) {
                                        return response.portalBVMethod;
                                    }
                                }
                            ).catch(function(err) {
                                $log.debug(err);
                            });
                    }]
                }
            });
    }
})();
