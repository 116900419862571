(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Header')
        .component('eaHeader', {
            templateUrl: 'components/header/header.html',
            controller: 'HeaderController as header'
        });
})();
