(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Modals')
        .controller('PolicyPlanDetailsModalController', PolicyPlanDetailsModalController);

    PolicyPlanDetailsModalController.$inject = ['$log', '$uibModalInstance', 'responseData', 'Utility', 'RxEligibilityService', 'ELIGIBILITY_CONSTS', 'GLOBAL_CONSTS'];

    function PolicyPlanDetailsModalController($log, $uibModalInstance, responseData, Utility, RxEligibilityService, ELIGIBILITY_CONSTS, GLOBAL_CONSTS) {

        var vm = this;
        vm.policy = responseData.coverageResponse.policies[responseData.policyIndex];
        vm.responseId = responseData.coverageResponse.responseId;
        vm.patient = responseData.coverageResponse.request.patient;
        vm.plan = vm.policy.plan;
        vm.subscriber = vm.policy.subscriber;
        vm.responseDate = responseData.responseDate;
        vm.toolTip = RxEligibilityService.getPolicyCoverageToolTip();

        // functions
        vm.limitApplies = RxEligibilityService.limitApplies;
        vm.getStepDrugOrderProperty = getStepDrugOrderProperty;

        vm.closeModal = closeModal;
        vm.checkGender = Utility.checkGender;

        vm.patientFullName = Utility.getFullName(vm.patient);
        vm.getMaskDataForCardHolder = Utility.maskData(GLOBAL_CONSTS.CARDHOLDER.MASK.NUMBER_OF_CHARS_TO_SHOW_IN_START, GLOBAL_CONSTS.CARDHOLDER.MASK.NUMBER_OF_CHARS_TO_SHOW_IN_END, vm.subscriber.cardHolderId);

        function closeModal() {
            $uibModalInstance.close();
        }

        /**
         * Checks 'order' property of each element for any value,
         * if every item's 'order' property is not null or undefined
         * the order property will be returned otherwise empty string.
         */
        function getStepDrugOrderProperty() {
            var orderValueAvailable = false;
            if(!Utility.isEmpty(vm.plan) &&
                !Utility.isEmpty(vm.plan.planCoverageLimitations) &&
                !Utility.isEmpty(vm.plan.planCoverageLimitations.stepDrugList)) {
                orderValueAvailable = vm.plan.planCoverageLimitations.stepDrugList.every(function(stepDrug) {
                    return !Utility.isEmpty(stepDrug.order);
                });
            }

            // If 'order' property value is present in every element then orderBy 'order' property otherwise don't filter
            return orderValueAvailable ? ELIGIBILITY_CONSTS.STEP_THERAPY_DRUG_LIST_SORT_PROPERTY : '';
        }

    }

})();
