/**
 * Created by aayush.regmi on 10/30/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.UserAuthEmailLink')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('auth-email-link', {
                url: '/link/verify?token',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Verification'
                },
                views: {
                    'content@': {
                        controller: 'UserAuthEmailLinkController as authEmailLink'
                    }
                },
                resolve: {
                    status: ['$stateParams', 'UserAuthEmailLinkService', 'USER_AUTH_EMAIL_LINK_CONSTS', 'Storage', 'Utility', function($stateParams, UserAuthEmailLinkService, USER_AUTH_EMAIL_LINK_CONSTS, Storage, Utility) {
                        return UserAuthEmailLinkService.validateToken($stateParams.token).then(function(response) {
                            if(!Utility.isEmpty(response.messageId) && response.messageId !== USER_AUTH_EMAIL_LINK_CONSTS.LINK_RESPONSE_MESSAGE.NOT_FOUND) {
                                Storage.saveToSession('guid', $stateParams.token);
                            }
                            response.linkType = USER_AUTH_EMAIL_LINK_CONSTS.LINK_TYPE.OTHERS;
                            return response;
                        });
                    }]
                }
            })
            .state('auth-email-link-change-pw', {
                url: '/change/link/verify?token',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Verification'
                },
                views: {
                    'content@': {
                        controller: 'UserAuthEmailLinkController as authEmailLink'
                    }
                },
                resolve: {
                    status: ['$stateParams', 'UserAuthEmailLinkService', 'USER_AUTH_EMAIL_LINK_CONSTS', 'Storage', 'Utility', function($stateParams, UserAuthEmailLinkService, USER_AUTH_EMAIL_LINK_CONSTS, Storage, Utility) {
                        return UserAuthEmailLinkService.validateToken($stateParams.token).then(function(response) {
                            if(!Utility.isEmpty(response.messageId) && response.messageId !== USER_AUTH_EMAIL_LINK_CONSTS.LINK_RESPONSE_MESSAGE.NOT_FOUND) {
                                Storage.saveToSession('guid', $stateParams.token);
                            }
                            response.linkType = USER_AUTH_EMAIL_LINK_CONSTS.LINK_TYPE.CHANGE_PASSWORD;
                            return response;
                        });
                    }]

                }
            });
    }
})();
