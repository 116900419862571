/**
 * Created by pramesh on 2/2/2018.
 */
(function() {
    'use strict';

    angular.module('eAccess.ProviderPortal.Directives').directive('eaInfiniteScroll', eaInfiniteScroll);

    eaInfiniteScroll.$inject = ['$window', 'Utility', 'DATA_LIMIT_CONSTS', '$timeout'];

    function eaInfiniteScroll($window, Utility, DATA_LIMIT_CONSTS, $timeout) {
        return {
            restrict: 'A',
            scope: {
                callBack: '&',
                wait: '=',
                distance: '<?',
                bindTo: '<?',
                nextLink: '<',
                currentLimit: '<',
                dynamicHeight: '<?'
            },
            link: function(scope, element, attrs) {
                var previousScroll = DATA_LIMIT_CONSTS.INITIAL_SCROLL_POSITION,
                    currentScroll = DATA_LIMIT_CONSTS.INITIAL_SCROLL_POSITION,
                    distance = scope.distance,
                    bindTo = scope.bindTo,
                    dynamicHeight = scope.dynamicHeight,
                    useScrollY = Utility.isEmpty(bindTo);   // If bindTo is undefined or null, defaults to use $window which will provide scrollY value, otherwise use scrollTop because it uses an element
               
                distance = Utility.isEmpty(distance) ? (Utility.isEmpty(bindTo) ? DATA_LIMIT_CONSTS.INFINITE_SCROLL_DISTANCE : null) : Math.abs(distance);
                // wait for clearing stack to get the value of limit from server
                $timeout(function(){
                    if(!Utility.isEmpty(bindTo) && !Utility.isEmpty(scope.currentLimit) && !Utility.isEmpty(dynamicHeight)) {
                        var tableWrapperHeight = (scope.currentLimit < DATA_LIMIT_CONSTS.SCREEN_HEIGHT_LIMIT) ?  
                        DATA_LIMIT_CONSTS.DEFAULT_ROW_HEIGHT * (parseInt(scope.currentLimit) + 1) 
                        : DATA_LIMIT_CONSTS.DEFAULT_TABLE_HEIGHT * DATA_LIMIT_CONSTS.DEFAULT_ROW_HEIGHT;
                        distance = tableWrapperHeight;
                        angular.element(dynamicHeight).css('max-height', tableWrapperHeight + 'px');
                        angular.element(bindTo).css('max-height', tableWrapperHeight + 'px');

                    }
                });      
                bindTo = Utility.isEmpty(bindTo) ? angular.element($window) : angular.element(bindTo);
                bindTo.scroll(function() {
                    previousScroll = currentScroll;

                    if(useScrollY) {
                        currentScroll = this.scrollY || this.pageYOffset;
                    } else {
                        currentScroll = this.scrollTop;
                    }

                    var elementHeight = element[0].offsetHeight,
                        elementOffsetTop = element.prop('offsetTop'),
                        elementBottomPosition = elementHeight + elementOffsetTop;

                    if(!scope.wait && (previousScroll < currentScroll)) {
                        if(currentScroll >= (elementBottomPosition - distance) 
                            && currentScroll <= elementBottomPosition 
                            && !Utility.isEmpty(scope.nextLink)) 
                            scope.callBack();
                        
                            
                          
                    }
                });
            }
        };
    }
})();
