/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.Components.Practice.controller:PracticeController
 * @author Sterling Stokes
 * @requires $log
 * @requires PracticeView
 * @requires $state
 * @requires $rootScope
 * @requires ConfigurationService
 * @requires $uibModal
 * @requires USER_CONSTS
 * @description
 *
 * This is the controller for the overall Practice page.
 **/

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .controller('PracticeController', PracticeController);

    PracticeController.$inject = ['$log', 'PracticeView', '$state', '$rootScope', 'ConfigurationService', '$uibModal', 'USER_CONSTS', 'Utility', 'VerifyUserService', 'UserInfoService', 'ToastNotificationService', 'GLOBAL_CONSTS'];

    function PracticeController($log, PracticeView, $state, $rootScope, ConfigurationService, $uibModal, USER_CONSTS, Utility, VerifyUserService, UserInfoService, ToastNotificationService, GLOBAL_CONSTS) {

        var vm = this;

        // data
        vm.viewService = PracticeView;
        vm.accountInfo = UserInfoService;
        vm.showUserTab = null;
        // holds the index of deselected tab index
        vm.deselectedTabIndex = 0;  // 0 because our preselected tab index is 0

        // functions
        vm.navigateToState = navigateToState;
        vm.navigateToEditState = navigateToEditState;
        vm.tabDeselected = tabDeselected;
        vm.resetPassword = resetPassword;
        vm.navigateToChangePassword = navigateToChangePassword;
        vm.isResetPassword = isResetPassword;
        vm.isChangePassword = isChangePassword;

        vm.$onInit = function() {
            vm.showUserTab = VerifyUserService.isRoleAdmin();
            PracticeView.setTabInfo();
            fetchUserRole();
        };

        function isResetPassword() {
            return !Utility.isEmpty(vm.viewService.resetPasswordLink) && !Utility.isEmpty(vm.viewService.resetPasswordLink.resetPasswordText);
        }

        function isChangePassword() {
            return !Utility.isEmpty(vm.viewService.changePasswordLink) && !Utility.isEmpty(vm.viewService.changePasswordLink.changePasswordText);

        }

        function navigateToState() {
            $state.go(vm.viewService.practiceViewBtn.href);
        }

        function navigateToEditState() {
            $state.go(vm.viewService.practiceEditLink.editHref, {id: vm.viewService.practiceEditLink.editId});
        }

        function resetPassword() {
            var resetPasswordModal = $uibModal.open({
                animation: true,
                templateUrl: 'partials/templates/reset-password-modal.html',
                controller: 'ResetPasswordModalController',
                controllerAs: 'resetPassword',
                appendTo: angular.element('body'),
                resolve: {
                    user: function() {
                        return vm.viewService.resetPasswordLink.user;
                    },
                    resetPasswordModal: function() {
                        return {
                            header: USER_CONSTS.RESET_PASSWORD_MODAL.HEADER,
                            message: USER_CONSTS.RESET_PASSWORD_MODAL.MESSAGE,
                            primaryButtonLabel: USER_CONSTS.RESET_PASSWORD_MODAL.PRIMARY_BUTTON_LABEL,
                            secondaryButtonLabel: USER_CONSTS.RESET_PASSWORD_MODAL.SECONDARY_BUTTON_LABEL
                        };
                    }
                }
            });
            resetPasswordModal.result.then(function(changedPassword) {
                if(changedPassword) {
                    ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, USER_CONSTS.RESET_PASSWORD_TOAST.HEADER,
                        USER_CONSTS.RESET_PASSWORD_TOAST.MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.SUCCESS));
                }
            }, angular.noop);
        }

        function navigateToChangePassword() {
            $state.go(vm.viewService.changePasswordLink.changePasswordHref, {
                fromMyAccount: true
            });
        }

        /**
         * Sets the deselectedTabIndex variable
         * @param event
         * @param selectedIndex
         * @param deselectedTabIndex
         */
        function tabDeselected($event,deselectedTabIndex) {
            //prevent default tab change
            if($event) {
                $event.preventDefault();
            }
            vm.deselectedTabIndex = deselectedTabIndex;
        }

        function fetchUserRole() {
            vm.isRoleWithUser = ConfigurationService.isUserWithUserRole();
        }

    }

})();
