(function() {
    'use strict';

    var eaMbvListWidget = {
        bindings: {
            className: '<'
        },
        templateUrl: 'components/dashboard/widgets/mbv-list/mbv-list.html',
        controller: 'MbvListWidgetController as mbvListWidget'
    };

    angular
        .module('eAccess.ProviderPortal.Widgets.MbvList')
        .component('eaMbvListWidget', eaMbvListWidget);
})();
