(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.CaseBV')
        .factory('CaseBVService', CaseBVService);

    CaseBVService.$inject = ['$log', '$q', 'API', 'CaseModel', 'Utility', 'ELIGIBILITY_CONSTS', 'DATA_LIMIT_CONSTS', 'DASHBOARD_CONSTS'];

    function CaseBVService($log, $q, API, CaseModel, Utility, ELIGIBILITY_CONSTS, DATA_LIMIT_CONSTS, DASHBOARD_CONSTS) {
        var service = {
            fetchRecentBVList: fetchRecentBVList,
            fetchRecentManualBVList: fetchRecentManualBVList,
            fetchManualBVInProgress: fetchManualBVInProgress,
            getBvByResponseId: getBvByResponseId,
            mapCase: mapCase,
            fetchManualBVInProgressCount: fetchManualBVInProgressCount,
            fetchWeeklyCompletedBvsCount: fetchWeeklyCompletedBvsCount,
            fetchMonthlyCompletedBvsCount: fetchMonthlyCompletedBvsCount,
            mapReverificationCase: mapReverificationCase
        };

        function fetchRecentBVList(count, length, offset, sort) {
            var queryParams = {
                count: count || DATA_LIMIT_CONSTS.CASEBV_DEFAULT_COUNT,
                offset: offset || DATA_LIMIT_CONSTS.CASEBV_DEFAULT_OFFSET,
                limit: length || DATA_LIMIT_CONSTS.CASEBV_LIMIT, // defaults to first 100 data, -1 represents all data
                sort: sort || "-" + ELIGIBILITY_CONSTS.BV_SORT.DATE_COMPLETED // negative sign indicates descending order
            };
            return API.get('ebv/combined', queryParams).then(function(result) {
                var caseEbvResultObj = {
                    totalCount: result.data.benefitVerifcationCount,
                    responseList: result.data.benefitVerificationList.filter(function(data) {
                        return !Utility.isEmpty(data.coverageResponseDto);
                    }).map(mapCase)
                };
                if(!Utility.isEmpty(result.link)) {
                    caseEbvResultObj.link = result.link;
                }
                return caseEbvResultObj;
            });
        }


        //To fetch recent manual BV list
        //Default limit is 10
        function fetchRecentManualBVList(count, offset, limit) {
            var params = {
                count: count || false,
                offset: offset || DATA_LIMIT_CONSTS.DEFAULT_OFFSET,
                limit: limit || ELIGIBILITY_CONSTS.DEFAULT_BV_LIST_LIMIT,
                complete: true
            };
            return API.get('manual/combined', params);
        }

        function fetchManualBVInProgress(count, length, offset, sort) {
            var queryParams = {
                complete: DATA_LIMIT_CONSTS.CASEBV_INCOMPLETE,
                count: count || DATA_LIMIT_CONSTS.CASEBV_DEFAULT_COUNT,
                offset: offset || DATA_LIMIT_CONSTS.CASEBV_DEFAULT_OFFSET,
                limit: length ? length : length === 0 ? length : DATA_LIMIT_CONSTS.CASEBV_LIMIT_ALL,
                sort: sort || "-" + ELIGIBILITY_CONSTS.BV_SORT.RESPONSE_ID // negative sign indicates descending order
            };
            return API.get('manual/combined', queryParams).then(function(result) {
                $log.debug('manual result', result);

                if(Utility.isEmpty(result) || Utility.isEmpty(result.data) || Utility.isEmpty(result.data.benefitVerificationList)) {
                    return [];
                }
                var caseEbvResultObj = {
                    totalCount: result.data.benefitVerifcationCount,
                    responseList: result.data.benefitVerificationList.filter(function(data) {
                        return !Utility.isEmpty(data.coverageResponseDto);
                    }).map(mapCase)
                };
                if(!Utility.isEmpty(result.link)) {
                    caseEbvResultObj.link = result.link;
                }
                return caseEbvResultObj;
            }).catch(function(err) {
                $log.error('Failed to get in progress manuals', err);
            });
        }

        function mapCase(bvCase) {
            bvCase.coverageResponseDto.data.mbvId = bvCase.mbvId;
            return new CaseModel(bvCase.coverageResponseDto.data, bvCase.responseDate, bvCase.bvType, bvCase.bvAdjudicationType, bvCase.status, bvCase.patientActive, bvCase.rxType);
        }

        function getBvByResponseId(responseId) {
            return API.get('caseEbv/response/' + responseId).then(function(result) {
                if(angular.isUndefined(result)) {
                    return [];
                }

                return result;
            });
        }

        function fetchManualBVInProgressCount(bvAdjudication) {
            var queryParams = {
                bvAdjudication: bvAdjudication
            };
            return API.get('manual/counts', queryParams).then(function(result) {
                if(Utility.isEmpty(result)) {
                    return {};
                }
                var caseEbvResultObj = {
                    medManualBv: result.medicalBvCount,
                    rxManualBv: result.rxBvCount
                };
                return caseEbvResultObj;
            }).catch(function(err) {
                $log.error('Failed to get in progress manuals', err);
            });
        }

        function fetchWeeklyCompletedBvsCount(bvAdjudication) {
            return _fetchCompletedBvsCount(_getParamsForCompletedBvsCount(bvAdjudication, DASHBOARD_CONSTS.WEEKLY));
        }

        function fetchMonthlyCompletedBvsCount(bvAdjudication) {
            return _fetchCompletedBvsCount(_getParamsForCompletedBvsCount(bvAdjudication, DASHBOARD_CONSTS.MONTHLY));
        }

        function _getParamsForCompletedBvsCount(bvAdjudication, type) {
            return {
                bvAdjudicationType: 'Combined',
                currentDate: new Date(),
                type: type
            };
        }

        function _fetchCompletedBvsCount(params){
            return API.get('ebv/counts', params).then(function(result) {
                if(Utility.isEmpty(result)) {
                    return {};
                }
                var caseEbvCountObj = {
                    medManualBv: result.medicalBvCount,
                    rxManualBv: result.rxBvCount
                };
                return caseEbvCountObj;
            }).catch(function(err) {
                $log.error('Failed to get completed bvs count', err);
                return err;
            });
        }

        function mapReverificationCase(bvCase) {
            // No. of days from most recent bv.
            bvCase.coverageResponseDto.data.noOfDays = Utility.getDateDifferenceInDays(bvCase.responseDate, new Date());
            return service.mapCase(bvCase);
        }

        return service;
    }
})();
