(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .factory('Utility', Utility);

    Utility.$inject = ['$log', '$window', '$state', '$location', 'ELIGIBILITY_CONSTS', 'moment', 'BV_SELECTION_CONSTS', 'ERROR_MESSAGES', 'GLOBAL_CONSTS', 'STATUS_CONSTS', 'ENV'];

    function Utility($log, $window, $state, $location, ELIGIBILITY_CONSTS, moment, BV_SELECTION_CONSTS, ERROR_MESSAGES, GLOBAL_CONSTS, STATUS_CONSTS, ENV) {

        var service = {
            generateUUID: generateUUID,
            stripUrlQueryStrings: stripUrlQueryStrings,
            isEmpty: isEmpty,
            containValueInArray: containValueInArray,
            containEveryValueSameInArray: containEveryValueSameInArray,
            compareDateGreaterThanOrEqual: compareDateGreaterThanOrEqual,
            containEveryValueOneOrAnotherInArray: containEveryValueOneOrAnotherInArray,
            parseDateToReadableForm: parseDateToReadableForm,
            getFullName: getFullName,
            checkUniqueArray: checkUniqueArray,
            getNextOffsetFromLink: getNextOffsetFromLink,
            shouldCallAPI: shouldCallAPI,
            shouldCallSearchCall: shouldCallSearchCall,
            pushObjectInCollection: pushObjectInCollection,
            isUrl: isUrl,
            addHttpsPrefixInUrl: addHttpsPrefixInUrl,
            containValueInCollection: containValueInCollection,
            getAddress: getAddress,
            checkGender: checkGender,
            filterFromArray: filterFromArray,
            replaceText: replaceText,
            isObject: isObject,
            openPdf: openPdf,
            base64ToArrayBuffer: base64ToArrayBuffer,
            concatValues: concatValues,
            firstCharacterUpperCase: firstCharacterUpperCase,
            lowerCase: lowerCase,
            upperCase: upperCase,
            getAutoMbvWarningMessage: getAutoMbvWarningMessage,
            shouldChangeState: shouldChangeState,
            getTrimmedString: getTrimmedString,
            deleteAttributesFromObject: deleteAttributesFromObject,
            isObjectActive: isObjectActive,
            checkStatusCode: checkStatusCode,
            getDateByFormat: getDateByFormat,
            getDifferenceBetweenTwoDate: getDifferenceBetweenTwoDate,
            isBlank: isBlank,
            getItemFromArray: getItemFromArray,
            getCharacterAt: getCharacterAt,
            fromHTMLToPdfMake: fromHTMLToPdfMake,
            extend: extend,
            getNextLink: getNextLink,
            getDateDifferenceInDays: getDateDifferenceInDays,
            isCombined: isCombined,
            isRx: isRx,
            isMedical: isMedical,
            getRecentBvInDays: getRecentBvInDays,
            isStatusActive: isStatusActive,
            getById: getById,
            getBadRequestToastInfo: _getBadRequestToastInfo,
            getObjectFromList: _getObjectFromList,
            containValueInObjectList: containValueInObjectList,
            getFileURLForKey: getFileURLForKey,
            isIndexValid: isIndexValid,
            getClassNameByIndex: getClassNameByIndex,
            isApplied: _isApplied,
            getValueForPendingManualVerification: getValueForPendingManualVerification,
            maskData: maskData,
            isRxApiTypeSplit: isRxApiTypeSplit,
            appendSymbol: appendSymbol,
            isTruthy: isTruthy,
            updateConstantWithValue: updateConstantWithValue
        };


        /**
         * @description
         * returns string with lower case if exists else null
         *
         * @param {String} value
         */
        function lowerCase(value) {
            return !isEmpty(value) ? value.toLowerCase() : null;
        }

        /**
         * @description
         * returns string with upper case if exists else null
         *
         * @param {string} value
         */
        function upperCase(value) {
            return !isEmpty(value) ? value.toUpperCase() : null;
        }


        // Public Domain/MIT
        // https://stackoverflow.com/a/8809472/1306982
        function generateUUID() {
            var d = new Date().getTime();
            if(angular.isDefined(typeof performance) && angular.isFunction(typeof performance.now)) {
                d += performance.now(); //use high-precision timer if available
            }
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        }

        function stripUrlQueryStrings() {
            $log.debug('Before:', $location.search());
            $location.search('code', null);
            $location.search('state', null);
            $log.debug('After:', $location.search());
        }

        function isEmpty(obj) {
            return angular.isUndefined(obj) || obj === null;
        }

        /**
         * @description
         * Checks if the value is blank
         * returns boolean
         *
         * @param {string} value
         */
        function isBlank(value) {
            return isEmpty(value) || (!isObject(value) ? getTrimmedString(value).length === 0 : false);
        }


        function containValueInArray(collection, val) {
            return collection.indexOf(val) !== -1;
        }

        function containEveryValueSameInArray(collection, val) {
            return collection.every(function(priorAuth) {
                return priorAuth === val;
            });
        }

        function compareDateGreaterThanOrEqual(date1, date2) {
            return (date1 >= date2);
        }

        function getFullName(name) {
            return (!this.isEmpty(name.middleName)) ? name.firstName + " " + name.middleName + " " + name.lastName : name.firstName + " " + name.lastName;
        }

        /**
         * Check if every element of an array contains any element of another array.
         * NOTE: EVERY element of first array should match SOME element of second array.
         *
         * @param collection the array to search
         * @param values items to check for in collection
         */
        function containEveryValueOneOrAnotherInArray(collection, values) {
            return collection.every(function(priorAuth) {
                return values.some(function(value) {
                    return priorAuth === value;
                });
            });
        }

        /**
         * Function to parse the date in this form : yyyy-mm-dd
         * */
        function parseDateToReadableForm(date) {
            var dateValue = new Date(date);

            var year = dateValue.getFullYear(),
                month = dateValue.getMonth() + 1,
                day = dateValue.getDate();
            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;

            return year + '-' + month + '-' + day;
        }

        /**
         * Function to check if a given array contains same values or not
         * arr is the list which needs to be checked for uniqueness
         * key is the value by which the uniqueness is checked
         * */
        function checkUniqueArray(arr, key) {
            var values = {};
            return arr.filter(function(item) {
                var responseId = item[key];
                var exists = values[responseId];
                values[responseId] = true;
                return !exists;
            });
        }

        function getNextOffsetFromLink(links) {
            //If there are multiple links.
            var splittedLinks = links.split(',');
            var nextOffset = 0;
            var nextLinkObj = '';
            var offset = '';
            //First fetch only the obj for next link
            splittedLinks.forEach(function(link) {
                if(link.indexOf('next') !== -1) {
                    nextLinkObj = link;
                }
            });
            if(nextLinkObj) {
                var nextLink = nextLinkObj.split(';')[0];
                var URLAndQueryParam = nextLink.split('?');
                var queryParamsArray = URLAndQueryParam[1].split('&');
                queryParamsArray.forEach(function(queryParam) {
                    if(queryParam.indexOf('offset') !== -1) {
                        offset = queryParam.split('&');
                    }
                });
                if(offset) {
                    nextOffset = offset[0].split('=')[1];
                }
            }
            return parseInt(nextOffset);
        }

        function getNextLink(links) {
            if (!angular.isString(links)) return null;

            var parsedLinks =  {};
            var splittedLinks = links.split(',<');
            for(var i=0; i<splittedLinks.length; i++) {
                var section = splittedLinks[i].split(';');
                if (section.length !== 2) {
                    throw new Error("section could not be split on ';'");
                }
                var url = section[0].replace(/<(.*)>/, '$1').trim();
                var name = section[1].replace(/rel="(.*)"/, '$1').trim();
                if(name.indexOf(",") !== -1) name = name.replace(",","");
                parsedLinks[name] = url;
            }
            return parsedLinks;
        }

        /**
         * Check need to call api or not while scrolling
         *
         * @param loading data loading from endpoint
         * @param totalCountEqualToCurrentDataList size of count from api is equal to current size of list
         * @param dataNotFound flag for data not found
         * @returns {boolean} or ''
         */
        function shouldCallAPI(loading, totalCountEqualToCurrentDataList, dataNotFound) {
            return (!(loading || totalCountEqualToCurrentDataList || dataNotFound));
        }

        /**
         * Check need to call api or not while scrolling
         *
         * @param searchQuery search query for search call back
         * @returns {boolean}
         */
        function shouldCallSearchCall(searchQuery) {
            return (!isEmpty(searchQuery) && searchQuery !== '');
        }

        /**
         * Function to push object in collection
         * @param object
         * @param collection
         */
        function pushObjectInCollection(object, collection) {
            collection.push(object);
        }

        /**
         * Function to check provided string is URL
         * @param string
         * @returns {boolean}
         */
        function isUrl(string) {
            // (https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})
            var pattern = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/);
            return pattern.test(string);
        }

        /**
         * Function to return URL with prefix
         *
         * if provided string does not contain prefix 'http://' or 'https://' then append 'https://'
         * @param string
         * @returns string
         */
        function addHttpsPrefixInUrl(string) {
            if(!isEmpty(string) && !/^https?:\/\//i.test(string)) {
                string = "https://" + string;
            }
            return string;
        }

        /**
         * Function that returns formatted address string using the supplied addressObject
         * @param addressObject
         * @returns {string} address1, address2, city, state zipCode
         */
        function getAddress(addressObject) {
            if(service.isEmpty(addressObject)) {
                return '';
            }
            var result = '';

            if(!service.isEmpty(addressObject.address1) && addressObject.address1 !== '') {
                result += addressObject.address1 + ', ';
            }

            if(!service.isEmpty(addressObject.address2) && addressObject.address2 !== '') {
                result += addressObject.address2 + ', ';
            }

            if(!service.isEmpty(addressObject.city) && addressObject.city !== '') {
                result += addressObject.city + ', ';
            }

            if(!service.isEmpty(addressObject.state) && addressObject.state !== '') {
                result += addressObject.state + ' ';
            }

            if(!service.isEmpty(addressObject.zipCode) && addressObject.zipCode !== '') {
                result += addressObject.zipCode;
            }

            return result.trim();
        }

        function checkGender(genderValue) {
            if(service.isEmpty(genderValue)) {
                return firstCharacterUpperCase(GLOBAL_CONSTS.GENDER.UNKNOWN);
            }
            switch(genderValue.toLowerCase()) {
                case GLOBAL_CONSTS.GENDER.MALE.charAt(0):
                case GLOBAL_CONSTS.GENDER.MALE:
                    return firstCharacterUpperCase(GLOBAL_CONSTS.GENDER.MALE);
                case GLOBAL_CONSTS.GENDER.FEMALE.charAt(0):
                case GLOBAL_CONSTS.GENDER.FEMALE:
                    return firstCharacterUpperCase(GLOBAL_CONSTS.GENDER.FEMALE);
                case GLOBAL_CONSTS.GENDER.UNKNOWN.charAt(0):
                case GLOBAL_CONSTS.GENDER.UNKNOWN:
                    return firstCharacterUpperCase(GLOBAL_CONSTS.GENDER.UNKNOWN);
                default:
                    return firstCharacterUpperCase(GLOBAL_CONSTS.GENDER.UNKNOWN);
            }
        }

        function containValueInCollection(collection, condition) {
            return collection.some(condition);
        }

        /**
         * Replaces the token portion from text with the replacementText
         * @param text
         * @param token
         * @param replacementText
         */
        function replaceText(text, token, replacementText) {
            if(service.isEmpty(text)) {
                return '';
            }
            return text.replace(token, replacementText);
        }

        /**
         * @ngdoc function
         * @name filterFromArray
         *
         * @description
         * returns filtered array with given condition
         *
         * @param { array }  array Array which is to be filtered
         * @param {function } condition conditional function for filteration
         */
        function filterFromArray(array, condition) {
            return array.filter(condition);
        }

        /**
         * @ngdoc function
         * @name isObject
         *
         * @description
         * checks if the the passed parameter is object or not
         *
         * @param obj
         */

        function isObject(obj) {
            return angular.isObject(obj);
        }

        function openPdf(data, isObjectURL) {
            if(isEmpty(isObjectURL) || isObjectURL === false) {
                var file = new Blob([data], {
                    type: 'application/pdf'
                });
                if($window.navigator && $window.navigator.msSaveOrOpenBlob) {
                    $window.navigator.msSaveOrOpenBlob(file);
                } else {
                    $window.open(URL.createObjectURL(file));
                }
            } else {
                $window.open(data);
            }


        }

        function base64ToArrayBuffer(base64String) {
            var binary_string = $window.atob(base64String),
                len = binary_string.length,
                bytes = new Uint8Array(len);
            for(var i = 0; i < len; i++) {
                bytes[i] = binary_string.charCodeAt(i);
            }
            return bytes.buffer;
        }


        /**
         *
         * @param argArray
         * @param delimiter
         * @param defaultReturnValue
         * @returns {string|*}
         *
         * separates argArray value with delimiter
         */
        function concatValues(argArray, delimiter, defaultReturnValue) {
            argArray = argArray || [];
            delimiter = delimiter || ',';
            var concatenatedValue = argArray.filter(
                function(data) {
                    return data;
                }).join(delimiter);
            return concatenatedValue.length ? concatenatedValue : defaultReturnValue;
        }

        function firstCharacterUpperCase(string) {
            if(!isEmpty(string))
                return upperCase(string.charAt(0)) + lowerCase(string.slice(1));
            return string;
        }

        function getAutoMbvWarningMessage(code, warningMessageArrays) {
            var message = '';
            for(var i = 0; i < warningMessageArrays.length; i++) {
                if(!isEmpty(warningMessageArrays[i].code) && warningMessageArrays[i].code === code) {
                    message = !isEmpty(warningMessageArrays[i].message) ? warningMessageArrays[i].message : message;
                    break;
                }
            }
            return message;
        }

        function shouldChangeState(state, displayableStatus, stateParameters) {
            stateParameters = stateParameters || {};
            if(!isEmpty(displayableStatus) && displayableStatus) {
                $state.go(state, stateParameters);
            } else {
                return;
            }
        }

        /**
         * Returns trimmed version of the given string. If supplied parameter is undefined or null, the function simply
         * returns it.
         * @param str
         */
        function getTrimmedString(str) {
            return isEmpty(str) ? str : str.trim();
        }

        /**
         * Function to delete the attribute of object
         * @param object {Object}
         * @param attributes {Array}
         */
        function deleteAttributesFromObject(object, attributes) {
            attributes.forEach(function(attribute) {
                delete object[attribute];
            });
        }

        function isObjectActive(objectToCheck) {
            return !isEmpty(objectToCheck)
                && !isEmpty(objectToCheck.active)
                && objectToCheck.active;
        }


        /**
         * Function to check if the status code exist
         * @param err {Object}
         * @param statusToCompare {Array}
         */

        function checkStatusCode(err, statusToCompare) {
            return !isEmpty(err)
                && !isEmpty(err.status)
                && !isEmpty(statusToCompare)
                && statusToCompare.indexOf(err.status) >= 0;
        }


        /**
         * Function to return data by format
         * @param dateString
         * @param format
         * @returns date {Object}
         */
        function getDateByFormat(dateString, format) {
            return !service.isEmpty(dateString) ? moment(dateString, format) : null;
        }

        /**
         * function to return different between two dates in minutes
         * @param date_1
         * @param date_2
         * @returns {number} number of minutes
         */
        function getDifferenceBetweenTwoDate(date_1, date_2) {
            var date1 = new Date(date_1), date2 = new Date(date_2);
            var timeDiff = date2.getTime() - date1.getTime();
            return Math.ceil(timeDiff / (1000 * 60));
        }

        /**
         * function to check if bvAdjudication type is Combined or not
         * @param bvAdjudication
         * @returns {boolean}
         */
        function isCombined(bvAdjudication) {
            return BV_SELECTION_CONSTS.METHOD_NAME.COMBINED === 'combined';
        }

        /**
         * function to check if bvAdjudication type is Rx or not
         * @param bvAdjudication
         * @returns {boolean}
         */
        function isRx(bvAdjudication) {
            return BV_SELECTION_CONSTS.METHOD_NAME.RX === service.lowerCase(bvAdjudication);
        }

        /**
         * function to check if bvAdjudication type is Medical or not
         * @param bvAdjudication
         * @returns {boolean}
         */
        function isMedical(bvAdjudication) {
            return BV_SELECTION_CONSTS.METHOD_NAME.MEDICAL === service.lowerCase(bvAdjudication);
        }

        /**
         * returns specific positions character from the provided string
         * returns null if provided value is not string
         * entertains (space) ' ' as string. String is not trimed
         * @param stringValue is input string
         * @param index is character position to be returned
         */
        function getCharacterAt(stringValue, index) {
            if(angular.isString(stringValue) && angular.isNumber(index) && !service.isBlank(stringValue) && index >= 0 && index < stringValue.length) {
                return stringValue.charAt(index);
            } else {
                return null;
            }
        }

        /**
         * returns difference between two dates in minutes
         * @param oldDate
         * @param newDate
         */
        function getDateDifferenceInDays(oldDate, newDate) {
            var minutesDiff = service.getDifferenceBetweenTwoDate(oldDate, newDate);

            return Math.floor(minutesDiff / (60 * 24));
        }

        function fromHTMLToPdfMake(rootNode) {
            // Safe Check
            if(service.isEmpty(rootNode) || !angular.isObject(rootNode)) {
                return '';
            }

            var container = []; // holds the list of final objects to be supplied to pdfmake
            var children = rootNode.childNodes;
            angular.forEach(children, function(node) {
                createPdfMakeObject(node, container);
            });

            return container;
        }

        function createPdfMakeObject(node, container) {
            var object = {
                stack: []
            };

            switch(service.lowerCase(node.nodeName)) {
                case 'ul':
                    // Extract that elements childrens which will be the list
                    var lists = node.childNodes;
                    var ulObj = {
                        ul: []
                    };
                    angular.forEach(lists, function(item) {
                        ulObj.ul.push(item.textContent);
                    });
                    object.stack.push(ulObj);
                    break;
                case 'ol':
                    var olObj = {
                        ol: []
                    };
                    // Extract that elements childrens which will be the list
                    angular.forEach(node.childNodes, function(item) {
                        olObj.ol.push(item.textContent);
                    });
                    object.stack.push(olObj);
                    break;
                case 'p':
                    var textObj = {
                        "text": []
                    };
                    if(node.hasChildNodes()) {
                        // Support for immediate i, u tag following p tag
                        angular.forEach(node.childNodes, function(item) {
                            switch (service.lowerCase(item.nodeName)) {
                                case 'a':
                                    textObj.text.push({
                                        "text": item.text,
                                        "nodeName": "A",
                                        "color": "blue",
                                        "decoration": [
                                            "underline"
                                        ],
                                        "style": [
                                            "html-a",
                                            "html-p",
                                            "html-div"
                                        ],
                                        "link": item.href
                                    });
                                    break;
                                case '#text':
                                    textObj.text.push({
                                        text: item.textContent,
                                        "margin": [
                                            0,
                                            5,
                                            0,
                                            10
                                        ],
                                        "style": [
                                            "html-p",
                                            "html-div"
                                        ]
                                    });
                                    // Support for style attribute of p tag
                                    if(!service.isEmpty(node.attributes.style)) {
                                        if(node.attributes.style.nodeValue.search('underline') >= 0) {
                                            textObj.text[0].style.push('underline');
                                        }
                                        if(node.attributes.style.nodeValue.search('italics') >= 0) {
                                            textObj.text[0].style.push('italics');
                                        }
                                    }
                                    break;
                                case 'i':
                                    textObj.text.push({
                                        "text": item.textContent,
                                        "nodeName": "I",
                                        "style": [
                                            'italics'
                                        ]
                                    });
                                    break;
                                case 'u':
                                    textObj.text.push({
                                        "text": item.text,
                                        "nodeName": "U",
                                        "decoration": [
                                            "underline"
                                        ]
                                    });
                                    break;
                                default:
                                    object.stack.push({
                                        text: item.text
                                    });
                            }
                        });
                        object.stack.push(textObj);
                    }
                    break;
                case 'pagebreak':
                    object = {text: '', pageBreak: 'after'};
                    break;
                default:
                    object = {text: node.textContent};

            }

            // Justify alignment of every object
            object.alignment = 'Justify';

            container.push(object);
        }

        /**
         * returns no of days between last bv and current date wrapped with appropriate suffix
         * @param bv
         * @returns
         */
        function getRecentBvInDays(bv) {
            if(service.isEmpty(bv) || service.isEmpty(bv.noOfDays)) {
                return ERROR_MESSAGES.NOT_AVAILABLE;
            }
            var suffix = ' ' + ELIGIBILITY_CONSTS.REVERIFICATION.DAYS_AGO;
            if(bv.noOfDays === 1) {
                suffix = ' ' + ELIGIBILITY_CONSTS.REVERIFICATION.DAY_AGO;
            }
            return bv.noOfDays + suffix;
        }

        //TODO: Need to use this function wherever we are comparing the value for active status
        function isStatusActive(status) {
            return service.lowerCase(status) === STATUS_CONSTS.STATUS.ACTIVE;
        }

        /**
         * function to get response by id and if unable to get response then redirect to provided state
         * @param redirectStateName
         * @returns {promise.Promise<T | never> | Q.Promise<any> | * | void}
         */
        function getById(redirectStateName) {
            var promiseObject = this;
            return promiseObject.then(function(response) {
                return response;
            }).catch(function(error) {
                $log.error('unable to get response by id', error);
                $state.go(redirectStateName, {invalidUrl: true}, {reload: true});
                event.preventDefault();
                return false;
            });
        }

        function _getBadRequestToastInfo() {
            return {
                title: GLOBAL_CONSTS.TOAST_TITLE.BAD_REQUEST,
                message: GLOBAL_CONSTS.TOAST_MESSAGE.BAD_REQUEST,
                type: GLOBAL_CONSTS.TOAST_TYPES.ERROR
            };
        }

        /*
        * function to return item from and array
        * @param myObjects
        * @param prop
        * @param valu
        * @returns any
        * */
        function getItemFromArray(myObjects, prop, valu) {
            var i = myObjects.length;
            while (i--) {
                if (myObjects[i][prop] === valu) {
                    return myObjects[i];
                }
            }
            return null;
        }

       /**
        * Extends one class with another.
        *
        * @param {Function} destination The class that should be inheriting things.
        * @param {Function} source The parent class that should be inherited from.
        * @return {Object} The prototype of the parent.
        */
        function extend(destination, source) {
            destination.prototype = Object.create(source.prototype);
            destination.prototype.constructor = destination;
            return source.prototype;
        }

        /**
         * Function to get first single matched object from array
         *
         * @param [list] -  Array to fetch object from.
         * @param prop - to search
         * @param value - matchValue to match value with key
         * @return {object} single object from array
         */
        function _getObjectFromList(list, prop, value) {
            var length = list.length;
            if(!list || !length || !prop || isEmpty(value)) {
                return null;
            } else {
                for(var i = 0; i < length; i++) {
                    var obj = list[i];
                    if(obj[prop] === value) return obj;
                }
                return null;
            }
        }

        function containValueInObjectList(objectList, value) {
            for(var i = 0; i < objectList.length; i++) {
                var listOfValues = [];
                var innerObject = objectList[i];
                Object.keys(innerObject).forEach(function (key) {
                    listOfValues.push(innerObject[key]);
                });
                if(service.containValueInArray(listOfValues, value)) {
                    return true;
                }
            }
            return false;
        }

        function getFileURLForKey(key) {
            var link = ENV.API_URL + 'files/' + key;

            return link;
        }

        function isIndexValid(index, list) {
            return index >=0 && index < list.length;
        }

        /**
         * Returns a value from the list at given index. Empty string otherwise.
         * @param index
         * @param list
         */
        function getClassNameByIndex(index, list) {
            if(service.isIndexValid(index, list)) {
                return list[index];
            }
            return '';
        }

        function _isApplied(value){
            return !isBlank(value) && (lowerCase(value) === lowerCase(ELIGIBILITY_CONSTS.CONS_YES));
        }

        function getValueForPendingManualVerification(bvType, bvStatus, value) {
            if (service.lowerCase(bvType) === ELIGIBILITY_CONSTS.BV_TYPE.MANUAL && service.lowerCase(bvStatus) === STATUS_CONSTS.STATUS.INCOMPLETE && service.lowerCase(value) === STATUS_CONSTS.STATUS.UNDETERMINED) {
                return STATUS_CONSTS.PENDING_MANUAL_VERIFICATION;
            }
            return value;
        }

        function maskData(numberOfCharactersToShowInStart, numberOfCharactersToShowInEnd, number) {
            if (isBlank(number)) {
                return null;
            }
            var beginningCharacters = number.substring(0, numberOfCharactersToShowInStart);
            var endCharacters = number.substring(number.length - numberOfCharactersToShowInEnd);
            var maskCharacters = number.substring(numberOfCharactersToShowInStart, number.length - numberOfCharactersToShowInEnd).replace(/\d/g, "#");
            return beginningCharacters + maskCharacters + endCharacters;
        }

        function isRxApiTypeSplit(rxApiType) {
            return ELIGIBILITY_CONSTS.RX_API_TYPES.SPLIT === service.lowerCase(rxApiType);
        }

        function appendSymbol(value, symbol) {
            if(!service.isBlank(value)) {
                return value + symbol;
            }

            return value;
        }

        /**
         * Checks if given value is truthy. Converts the value into number before converting into boolean.
         * Works for all types.
         * @param value
         * @return {boolean}
         */
        function isTruthy(value) {
            return !!+value;
        }

        /**
         * Returns a string after replacing value in a provided string
         * @param strValue
         * @param replaceObj
         *
         * example
         * replaceObj = {'__sum__': 19}
         * strValue: 'This 9 + 10 = __sum__ which is the sum'
         * returns  'This 9 + 10 = 19 which is the sum'
         */
        function updateConstantWithValue(strValue, replaceObj) {
            if(!service.isEmpty(strValue) && !service.isEmpty(replaceObj)) {
                return strValue.replace(RegExp(Object.keys(replaceObj).join("|"), "gi"), function(matched) {
                    return replaceObj[matched];
                });
            }
            return null;
        }

        return service;
    }

})();
