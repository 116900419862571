(function() {
    'use strict';

    var eaAdditionalFinancialDetails = {
        bindings: {
            coverageSummary: '<'
        },
        templateUrl: 'components/eligibility/bv-selection/rx/split-response/coverage-summary/additional-financial-details/additional-financial-details.html',
        controller: 'AdditionalFinancialDetailsController as additionalFinancialDetails'
    };

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilitySplitResponse')
        .component('eaAdditionalFinancialDetails', eaAdditionalFinancialDetails);
})();
