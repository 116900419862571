/**
 * Created by nikesh on 10/11/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Breadcrumb', []);

})();
