/**
 * Created by aayush.regmi on 10/30/2017.
 */

(function() {
    'use strict';

    var VALUES = {
        LINK_RESPONSE_MESSAGE: {
            LINK_EXPIRED: 100,
            NOT_FOUND: 101,
            SUCCESS: 200,
            PRACTICE_SET: 103,
            PASSWORD_SET: 102
        },
        LINK_TYPE: {
            CHANGE_PASSWORD: 'change-password',
            OTHERS: 'others'
        }
    };

    angular.module('eAccess.ProviderPortal.Constants.Link', []).constant('USER_AUTH_EMAIL_LINK_CONSTS', VALUES);
})();
