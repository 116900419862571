/**
 * Created by pramesh on 10/24/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .factory('ForgotPasswordService', ForgotPasswordService);

    ForgotPasswordService.$inject = ['$log', 'API'];

    function ForgotPasswordService($log, API) {

        var service = {
            requestForgotPassword: requestForgotPassword
        };

        /**
         * @ngdoc function
         * @name requestForgotPassword
         * @description
         *
         * Request for new password for provided username, if it exist.
         *
         * @param requestObj
         */

        function requestForgotPassword(requestObj) {
            return API.post('users/notifications', requestObj);
        }

        return service;
    }
})();
