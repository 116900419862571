(function() {
    'use strict';

    var VALUES = {
        FORBIDDEN_API: {
            DEACTIVATE_LOCATION: 'locations/update/status',
            DEACTIVATE_PROVIDER: 'providers/update/status',
            DEACTIVATE_USER: 'users/update/status',
            ADD_USER: 'users/create',
            RESET_USER_PASSWORD: 'users/reset/password'
        }
    };

    angular.module('eAccess.ProviderPortal.Constants.ForbiddenAPI', []).constant('FORBIDDEN_API_CONSTS', VALUES);
})();

