/**
 * Created by aayush.regmi on 10/12/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.TermsAndConditions')
        .controller('TermsAndConditionsController', TermsAndConditionsController);

    TermsAndConditionsController.$inject = ['$log', 'TermsAndConditionsService', 'REGISTER_CONSTS', 'Storage', '$uibModal', '$state', '$sce'];

    function TermsAndConditionsController($log, TermsAndConditionsService, REGISTER_CONSTS, Storage, $uibModal, $state, $sce) {
        var vm = this;

        vm.attestations = [];
        vm.termsAndConditionsContent = '';
        vm.esignature = '';
        vm.clickedSaveButton = false;
        vm.isScrolledToBottom = false;
        vm.showErrorMessage = false;
        vm.isESignatureMaxLengthExceed = false;
        vm.errorMessages = {
            required: REGISTER_CONSTS.ERROR_MESSAGES.ELECTRONIC_SIGNATURE.REQUIRED,
            maxLength: REGISTER_CONSTS.ERROR_MESSAGES.ELECTRONIC_SIGNATURE.MAX_LENGTH,
            attestation: {
                undersignedRequired: REGISTER_CONSTS.ERROR_MESSAGES.ATTESTATION.I_AM_UNDER_SIGNED_REQUIRED,
                iAgreeRequired: REGISTER_CONSTS.ERROR_MESSAGES.ATTESTATION.I_AGREE_REQUIRED,
                bothRequired: REGISTER_CONSTS.ERROR_MESSAGES.ATTESTATION.SELECT_BOTH
            }
        };

        vm.getTermsAndConditionsContent = getTermsAndConditionsContent;
        vm.getTermsAndConditionsConfiguration = getTermsAndConditionsConfiguration;
        vm.changeTermsAndConditionsValue = changeTermsAndConditionsValue;
        vm.isFormInvalid = isFormInvalid;
        vm.saveTermsAndConditions = saveTermsAndConditions;
        vm.scrolledToBottom = scrolledToBottom;
        vm.isAttestationSelected = isAttestationSelected;
        vm.getErrorMessage = getErrorMessage;
        vm.openWarnOnLeave = openWarnOnLeave;
        vm.fetchTermsAndConditionFromSession = fetchTermsAndConditionFromSession;

        vm.$onInit = function() {
            vm.getTermsAndConditionsConfiguration();
            vm.fetchTermsAndConditionFromSession();
        };

        function getTermsAndConditionsContent() {
            return vm.termsAndConditionsContent;
        }

        function getTermsAndConditionsConfiguration() {
            vm.termsAndConditionsContent = $sce.trustAsHtml(vm.registrationConfig.userAgreementTermsConditions);
            vm.attestations.push({label: vm.registrationConfig.userIAgreeText, selected: false});
            vm.attestations.push({label: vm.registrationConfig.userIAmUndersignedText, selected: false});
        }

        function changeTermsAndConditionsValue(index) {
            if(!vm.isScrolledToBottom) {
                vm.showErrorMessage = true;
                vm.attestations[index].selected = false;
                return false;
            }
            return true;
        }

        function isFormInvalid() {
            return (!vm.esignature || !vm.attestations[0].selected || !vm.attestations[1].selected);
        }

        function saveTermsAndConditions() {
            vm.clickedSaveButton = true;
            if(!isFormInvalid()) {
                Storage.saveToSession('userAgreementEsignature', vm.esignature);
                Storage.saveToSession('userAgreementSignedDate', new Date());
                Storage.saveToSession('userAgreementAttestation1', vm.attestations[0].label);
                Storage.saveToSession('userAgreementAttestation2', vm.attestations[1].label);
                vm.nextCallback();
            } else {
                return false;
            }
        }

        function scrolledToBottom() {
            vm.isScrolledToBottom = true;
        }

        function isAttestationSelected(index) {
            return vm.attestations[index].selected;
        }

        function getErrorMessage(index) {
            if(index === 0) {
                return vm.errorMessages.attestation.iAgreeRequired;
            } else {
                return vm.errorMessages.attestation.undersignedRequired;
            }
        }

        function openWarnOnLeave() {
            var warnModal = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'warn-on-leave-title',
                arialDescribedBy: 'warn-on-leave-content',
                templateUrl: 'partials/templates/warn-on-leave-modal.html',
                controller: 'WarnOnBackModalController',
                controllerAs: 'warnOnLeave',
                appendTo: angular.element('body')
            });
            warnModal.result.then(function() {
                Storage.clearSessionData('userAgreementEsignature');
                Storage.clearSessionData('userAgreementSignedDate');
                Storage.clearSessionData('userAgreementAttestation1');
                Storage.clearSessionData('userAgreementAttestation2');
                //Setting form dirty to false to prevent warn-on-leave modal from displaying twice.
                vm.termsAndConditions.$dirty = false;
                $state.go('login');
            });
        }

        function fetchTermsAndConditionFromSession() {
            var esignature = Storage.getSessionItem('userAgreementEsignature');
            if(esignature) {
                vm.esignature = esignature;
                vm.isScrolledToBottom = true;
                vm.attestations[0].selected = true;
                vm.attestations[1].selected = true;
            }
        }
    }

})();
