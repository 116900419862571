/**
 * Created by bikash.shrestha on 5/6/2018.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Header')
        .factory('HeaderService', HeaderService);

    HeaderService.$inject = ['UserInfoService', '$state', '$window', '$sce', '$injector'];

    function HeaderService(UserInfoService, $state, $window, $sce, $injector) {

        var service = {
            changeLoggedInUserInfo: changeLoggedInUserInfo,
            isLoggedInHeader: false,
            isRoleWithUser: null,
            setHeaderContent: _setHeaderContent,
            overbrandContent: null,
            overbrandLinks: null,
            publicCustomLinks: []
        };

        function changeLoggedInUserInfo() {
            UserInfoService.setCurrentUserInfo();
            _checkLoggedInStatusAndSetHeader();
        }

        function _checkLoggedInStatusAndSetHeader() {
            var currentUrlName = $state.current.name;
            service.isLoggedInHeader = $window.sessionStorage.getItem('auth_token');
            // If auth_token is available, check if current state url is one of public url.
            if(service.isLoggedInHeader) {
                // Hide nav if current url is associated with initial practice setup (registration) process.
                service.isLoggedInHeader = (currentUrlName.search("practice-setup") === -1
                    && currentUrlName.search("login") === -1
                    && currentUrlName.search("register") === -1
                    && currentUrlName.search('set-password') === -1);
            }
            _fetchUserRole();
        }

        function _fetchUserRole() {
            var configurationService = $injector.get('ConfigurationService');
            service.isRoleWithUser = configurationService.isUserWithUserRole();
        }

        function _setHeaderContent(publicData) {
            service.overbrandContent = $sce.trustAsHtml(publicData.overbrandContent);
            service.overbrandLinks = publicData.overbrandLinks;
            service.publicCustomLinks = _populateHeaderLinks(publicData);
        }

        function _populateHeaderLinks(publicData) {
            var publicCustomLinks = [];
            var headerObj = {label: '', href: ''};
            publicData.headerLink1Label ? headerObj.label = publicData.headerLink1Label : '';
            publicData.headerLink1Url ? headerObj.href = publicData.headerLink1Url : '';
            publicCustomLinks.push(headerObj);
            headerObj = {label: '', href: ''};
            publicData.headerLink2Label ? headerObj.label = publicData.headerLink2Label : '';
            publicData.headerLink2Url ? headerObj.href = publicData.headerLink2Url : '';
            publicCustomLinks.push(headerObj);
            return publicCustomLinks;
        }

        return service;
    }

})();
