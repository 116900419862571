/**
 * Created by aayush.regmi on 2/11/2018.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Services.PaginationService', [])
        .factory('PaginationService', PaginationService);

    PaginationService.$inject = ['Utility'];

    function PaginationService(Utility) {
        var service = {
            getPaginationResponse: getPaginationResponse
        };

        function getPaginationResponse(response, currentList, filterBy) {
            var totalCount = response.totalCount;
            var nextLink = null;
            currentList = filterBy ? Utility.checkUniqueArray(currentList.concat(response.responseList), filterBy) : currentList.concat(response.responseList);

            if(!Utility.isEmpty(response.link)) {
                if(response.link.indexOf('next') !== -1) {
                    nextLink = Utility.getNextLink(response.link);
                } else {
                    /**
                     * if we do not get the next link from the API
                     * that would mean we have reached the end
                     * therefore setting the total count to the bv list count
                     * */
                    totalCount = currentList.length;
                }
            } 
            return {
                list: currentList,
                totalCount: totalCount,
                nextLink: !Utility.isEmpty(nextLink) ? nextLink['next'] : nextLink
            };
        }

        return service;
    }
})();
