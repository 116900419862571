(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Eligibility')
        .component('eaMedAutoMbvResponse', {
            bindings: {
                insurancePolicies: '<',
                responseDate: '<',
                errorMessage: '<',
                productName: '<',
                requestDate: '<',
                requestId: '<'
            },
            templateUrl: 'components/eligibility/bv-selection/med/response/auto-mbv-response/auto-mbv-response.html',
            controller: 'AutoMbvResponseController as autoMbvResponseCtrl'
        });
})();
