(function () {
    'use strict';

    var eaAdminCodeList = {
        bindings: {
            adminCodeList: '=',
            selectedProduct: '<',
            parentForm: '=',
            helpText: '<'
        },
        templateUrl: 'components/admin-code/admin-code-list.html',
        controller: 'AdminCodeListController as adminCodeList'
    };

    angular
        .module('eAccess.ProviderPortal.Components.AdminCodeList', [
            'eAccess.ProviderPortal.Components.AdminCode'
        ])
        .component('eaAdminCodeList', eaAdminCodeList);
})();
