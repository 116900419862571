/**
 * Created by aayush.regmi on 2/28/2018.
 */
(function() {
    'use strict';

    var VALUES = {
        METHOD_NAME: {
            MEDICAL: 'medical',
            RX: 'rx',
            COMBINED: 'combined'
        },
        STATE_NAME: {
            MEDICAL: 'medEligibility',
            RX: 'rxEligibility',
            DASHBOARD: 'dashboard',
            BV_SELECTION: 'bvSelection'
        },
        URL_NAME: {
            MEDICAL: '/eligibility/med',
            RX: '/eligibility/rx'
        },
        TOOLTIP_MESSAGE: {
            RX: "Click here to verify Rx benefits for this patient.",
            MEDICAL: "Click here to verify Medical benefits for this patient."
        }
    };

    angular.module('eAccess.ProviderPortal.Constants.BvSelection', []).constant('BV_SELECTION_CONSTS', VALUES);
})();
