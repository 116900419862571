/**
 * Created by giri@nish.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.VerifyBv')
        .controller('VerifyBvController', VerifyBvController);

    VerifyBvController.$inject = ['$log', 'ELIGIBILITY_CONSTS', 'BV_SELECTION_CONSTS', '$state', 'BVSelectionService', 'CacheDataService', 'Utility', 'PatientService', 'Eligibility', 'Product', '$stateParams', 'moment', '$q', 'ProviderService', 'ERROR_MESSAGES', 'STATUS_CODE', 'GLOBAL_CONSTS'];

    function VerifyBvController($log, ELIGIBILITY_CONSTS, BV_SELECTION_CONSTS, $state, BVSelectionService, CacheDataService, Utility, PatientService, Eligibility, Product, $stateParams, moment, $q, ProviderService, ERROR_MESSAGES, STATUS_CODE, GLOBAL_CONSTS) {
        var vm = this;
        vm.methodName = null;
        // if bvMethod is 'Combined' and provided 'Product' exists in both Rx & Medical product list then
        // isBvVerifyAvailable will be true, else it will be false
        vm.verifyButtonTxt = null;
        vm.product = null;
        vm.patient = null;
        vm.service = null;
        vm.provider = null;
        vm.location = null;
        vm.formData = null;

        // Holds patientInfo data extracted from /patients/combined api call
        vm.patientInfo = null;
        vm.errorMessageSetting = {
            title : ERROR_MESSAGES.BV_ERROR.TITLE,
            body : ERROR_MESSAGES.BV_ERROR.MESSAGE,
            type : GLOBAL_CONSTS.TOAST_TYPES.ERROR
        };

        // functions
        vm.setBVMethodName = setBVMethodName;
        vm.getButtonText = getButtonText;
        vm.verifyBv = verifyBv;
        vm.verifyMed = verifyMed;


        vm.$onInit = function() {

            if(!Utility.isEmpty(vm.request)
                && !Utility.isEmpty(vm.request.patient)
                && !Utility.isEmpty(vm.request.service)
                && !Utility.isEmpty(vm.request.service.product)
                && !Utility.isEmpty(vm.request.provider)) {

                // set the data required for request
                vm.product = vm.request.service.product;
                vm.patient = vm.request.patient;
                vm.service = vm.request.service;
                vm.provider = vm.request.provider;
                vm.location = vm.request.location;
                vm.setBVMethodName(vm.bvMethod);
                vm.verifyButtonTxt = getButtonText();
                vm.tooltipMessage = BVSelectionService.getTooltipMessageForStateName(vm.methodName);
            }
        };

        function setBVMethodName(methodName) {
            vm.methodName = BVSelectionService.getBvMethodName(methodName);
        }

        function getButtonText() {
            var buttonText = BVSelectionService.getSwitchButtonText(vm.methodName);
            if(!Utility.isEmpty(buttonText)) {
                return buttonText.substr(0, 3);
            }
        }

        function verifyBv() {
            if(Utility.isMedical(vm.methodName)) {
                _isProviderActive(vm.provider.providerId)
                    .then(_getPatientData)
                    .then(_setPatientInfoAndLoadProduct)
                    .then(_prepareDataAndCheckEligibility)
                    .then(_parseResponseAndRedirect)
                    .catch(function(err) {
                        $log.error('error getting coverage', err);

                        if (!Utility.checkStatusCode(err, [STATUS_CODE['500_INTERNAL_SERVER_ERROR'], STATUS_CODE['-1_OFFLINE']]) // -1 status code is for offline
                            && !Utility.isEmpty(err.providerActive)
                            && !err.providerActive) {
                            _cacheRequestDataAndRedirect();
                        } else {
                            vm.showErrorToast(vm.errorMessageSetting);
                        }
                    });
            } else if(Utility.isRx(vm.methodName)) {
                _isProviderActive(vm.provider.providerId)
                    .then(function(providerStatusInfo) {
                        vm.verifyMed(providerStatusInfo.providerActive);
                    })
                    .catch(function(err) {
                        $log.error('Provider is inactive:', err);
                        _switchToVerifyMed(false);
                    });
            }
        }

        function _getPatientData() {
            var fetchPatientOnly = false;
            return PatientService.getPatientAndInsuranceInfoByPatientId(vm.patient.patientId, fetchPatientOnly);
        }

        function _cacheRequestDataAndRedirect() {
            Product.getProductById(vm.service.product.id).then(function(product) {
                var rxBvDataObj = {
                    patient: vm.patient,
                    provider: undefined,
                    showProviderDropdown: true,
                    rxServiceDeliveryInformation: _getServiceDeliveryInfo(vm.service, product),
                    selectedProduct: vm.product,
                    selectedLocation: vm.location
                };
                CacheDataService.setRxBvCacheData(rxBvDataObj);
                $state.go(BV_SELECTION_CONSTS.STATE_NAME.RX, {patientId: $stateParams.responseId, showProviderError: true});

            }).catch(function(err) {
                $log.log('Unable to get product data', err);
                vm.showErrorToast(vm.errorMessageSetting);
            });
        }

        function _prepareRequestData(requestObject, patientPractice, productInfo) {
            var patient = _preparePatientData(patientPractice);

            if(!Utility.isEmpty(patient.dob)) patient.dob = moment(patient.dob).format(GLOBAL_CONSTS.DATE_FORMAT.YYYY_MM_DD.DASH);

            return {
                patient: patient,
                service: _getServiceDeliveryInfo(vm.service, productInfo),
                provider: requestObject.provider,
                location: vm.location
            };
        }

        function _getServiceDeliveryInfo(service, productInfo) {
            service.product.productMethod = !Utility.isEmpty(productInfo)
                                            && !Utility.isEmpty(productInfo.bvAdjudication)
                                            && !Utility.isEmpty(productInfo.bvAdjudication.bvAdjudicationMethod)
                ? productInfo.bvAdjudication.bvAdjudicationMethod
                : service.product.productMethod;
            return {
                product: service.product,
                daysSupply: productInfo.defaultDays,
                quantity: productInfo.defaultQuantity,
                placeOfService: service.placeOfService
            };
        }

        function _prepareDataAndCheckEligibility(productInfo) {
            var requestData = _prepareRequestData(vm.request, vm.patientInfo.patientPractice, productInfo);
            requestData.patient.gender = PatientService.setPatientGender(requestData.patient);
            return Eligibility.checkRxSplitCoverage(requestData);
        }

        function _parseResponseAndRedirect(eligibilityResponse) {
            var redirectToState = ELIGIBILITY_CONSTS.STATE.RX_ELIGIBILITY_SPLIT_RESPONSE;
            if(!Utility.isEmpty(eligibilityResponse.patientConflicts) && eligibilityResponse.patientConflicts.length > 0) {
                redirectToState = ELIGIBILITY_CONSTS.STATE.RX_DATA_CONFLICT;
            }
            $state.go(redirectToState, {
                responseId: eligibilityResponse.responseId,
                adjudicationType: BVSelectionService.getSwitchBvMethodName(vm.methodName)
            });
        }

        function _setPatientInfoAndLoadProduct(patientResponse) {
            vm.patientInfo = patientResponse;
            return Product.getProductById(vm.service.product.id);
        }


        function _isProviderActive(providerId) {
            var deferred = $q.defer();
            ProviderService.getProviderByProviderId(providerId).then(function(provider) {
                Utility.isObjectActive(provider) ? deferred.resolve({providerActive: true})
                    : deferred.reject({status: STATUS_CODE['404_NOT_FOUND'], providerActive: false});
            }).catch(function(err) {
                $log.log('Unable to get Provider data:', err);
                !Utility.checkStatusCode(err, [STATUS_CODE['500_INTERNAL_SERVER_ERROR'], STATUS_CODE['-1_OFFLINE']])
                    ? deferred.reject({status: STATUS_CODE['404_NOT_FOUND'], providerActive: false})
                    : deferred.reject(err) ;
            });
            return deferred.promise;
        }

        function verifyMed(providerActiveStatus) {
            // try getting medical request data
            PatientService.getPatientRequestData(vm.patient.patientId, BV_SELECTION_CONSTS.METHOD_NAME.MEDICAL).then(function (response) {
                vm.formData = BV_SELECTION_CONSTS.METHOD_NAME.MEDICAL;
                _runMedCoverage(response, providerActiveStatus);
            }).catch(function (err) {
                // if medical data is not available get rx request data
                PatientService.getPatientRequestData(vm.patient.patientId, BV_SELECTION_CONSTS.METHOD_NAME.RX).then(function (response) {
                    vm.formData = BV_SELECTION_CONSTS.METHOD_NAME.RX;
                    _runMedCoverage(response, providerActiveStatus);
                }).catch(function (err) {
                    _switchToVerifyMed(providerActiveStatus);
                });
            });
        }

        function _runMedCoverage(response, providerActiveStatus) {
            Eligibility.checkMedAdvCoverage(response).then(function (response) {
                $state.go('eligibilityResponse', {
                    responseId: response.responseId,
                    bvType: Utility.lowerCase(ELIGIBILITY_CONSTS.BV_TYPE.ELECTRONIC),
                    adjudicationType: response.bvAdjudicationType
                });
            }).catch(function () {
                _switchToVerifyMed(providerActiveStatus);
            });
        }

        function _switchToVerifyMed(providerActiveStatus) {
            Eligibility.prepareMedEligibilityFormData(vm.patient.patientId, vm.formData).then(function(requestFormData) {
                requestFormData.provider = providerActiveStatus ? requestFormData.provider : null;
                requestFormData.selectedProduct = vm.request.service.product;

                var redirectParameter = {showProviderError: true};
                // If providerActiveStatus is true then we'll set flag to false
                if(providerActiveStatus) {
                    redirectParameter.showProviderError = false;
                }

                CacheDataService.setMedBvCacheData(requestFormData);
                $state.go(BV_SELECTION_CONSTS.STATE_NAME.MEDICAL, redirectParameter);
            });
        }

        function _preparePatientData(patientPractice) {
            return {
                patientId: patientPractice.patient.patientId || null,
                externalPatientId: patientPractice.patient.externalPatientId || null,
                practicePatientId: patientPractice.externalId || null,
                firstName: patientPractice.patient.firstName || null,
                middleName: patientPractice.patient.middleName || null,
                lastName: patientPractice.patient.lastName || null,
                dob: patientPractice.patient.dob || null,
                gender: patientPractice.patient.gender || null,
                address: {
                    zipCode: patientPractice.patient.zip || null
                }
            };
        }
    }
})();
