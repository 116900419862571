(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Patient')
        .controller('PatientAllController', PatientAllController);

    PatientAllController.$inject = ['$log', 'PatientView', 'PatientService', 'PatientStyles', 'STATUS_CONSTS', 'SEARCH_CONSTS', '$rootScope', 'PolicyService', 'StatusStyles', 'Utility', 'ConfigurationService', 'DATA_LIMIT_CONSTS', 'PaginationService', 'TABLE_NAME_CONSTS', 'Search', 'PATIENT_CONSTS', 'STYLES_CONSTS', 'PatientInfoService', 'CancelableQ', 'ERROR_MESSAGES', 'SearchModel', 'SearchPaginationModel', 'moment', '$stateParams', 'ToastNotificationService', 'GLOBAL_CONSTS', 'PatientReactivateDeactivate'];

    function PatientAllController($log, PatientView, PatientService, PatientStyles, STATUS_CONSTS, SEARCH_CONSTS, $rootScope, PolicyService, StatusStyles, Utility, ConfigurationService, DATA_LIMIT_CONSTS, PaginationService, TABLE_NAME_CONSTS, Search, PATIENT_CONSTS, STYLES_CONSTS, PatientInfoService, CancelableQ, ERROR_MESSAGES, SearchModel, SearchPaginationModel, moment, $stateParams, ToastNotificationService, GLOBAL_CONSTS, PatientReactivateDeactivate) {

        var vm = this;

        // data
        vm.viewService = PatientView;
        vm.errorMessages = {
            loadPatients: {
                display: false,
                text: 'There was an error loading the patient information. Please try again.'
            }
        };
        vm.viewablePatientStatus = STATUS_CONSTS.STATUS.ACTIVE;

        vm.searchType = SEARCH_CONSTS.SEARCH_TYPE.PATIENT;
        // Default sort by patient's lastName
        vm.sortBy = PATIENT_CONSTS.PATIENT_SORT.PATIENT_LAST_NAME;
        vm.limit = null;
        vm.patientCount = DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE;
        vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
        vm.currentSearchQuery = '';
        vm.searchQuery = '';

        vm.loadPatientWarningMessage = '';

        vm.patients = [];
        // this flag is used set when no data found from the endpoint in initial call, by default it is false
        vm.noDataFound = false;
        vm.fetchPatientCount = DATA_LIMIT_CONSTS.DEFAULT_COUNT;
        vm.deactivatedList = [];
        vm.reactivatedList = [];
        vm.listOfCheckedPatientsItems = [];
        vm.statusChangeResponseMessage = {};
        vm.linkDisabled = true;
        vm.selectedAll = false;
        vm.patientReactivateDeactivate = null;
        vm.nextLink =  null;
        vm.invalidUrl = $stateParams.invalidUrl;

        //function
        vm.loadPatients = loadPatients;
        vm.setFacet = setFacet;
        vm.setBenefitStatusClass = PatientStyles.setBenefitStatusClass;
        vm.setCoverageDetailsClass = PatientStyles.setCoverageDetailsClass;
        vm.setCoverageDetailsIcon = PatientStyles.setCoverageDetailsIcon;
        vm.patientSearchCallback = patientSearchCallback;
        vm.getPatientStatusToggleText = getPatientStatusToggleText;
        vm.toggleViewableStatus = toggleViewableStatus;
        vm.clearAllCallback = clearAllCallback;
        vm.setActiveStatusClass = StatusStyles.setActiveStatusClass;
        vm.getInsuranceStatus = PolicyService.getInsuranceStatus;
        vm.patientSearchLoadMoreCallback = patientSearchLoadMoreCallback;
        vm.sortPatientData = sortPatientData;
        vm.setInsuranceStatusClass = setInsuranceStatusClass;
        vm.getCoverageDetailIconClass = getCoverageDetailIconClass;
        vm.getCoverageStatusClass = getCoverageStatusClass;
        vm.getPatientActionText = getPatientActionText;
        vm.changePatientStatus = changePatientStatus;
        vm.updatePatientStatus = updatePatientStatus;
        vm.getSuccessMessage = getSuccessMessage;

        vm.searchFacets = PatientInfoService.getSearchFacetOptions();
        vm.selectedFacet = vm.searchFacets[0].searchCategory;
        vm.facetPlaceholder = vm.searchFacets[0].placeholderText;

        vm.changeState = Utility.shouldChangeState;
        vm.patientManagementStatus = ConfigurationService.getPatientManagementStatus;

        //Preserving promise object to cancel, if new data are requested.
        vm.cancelableService = CancelableQ;
        vm.isPromiseCalled = false;

        vm.$onInit = function() {
            if(vm.invalidUrl) {
                _setInvalidURLNotification();
            }

            if($stateParams.patientAdded) {
                _setPatientAddedNotification();
            }
            // initially reset sort order
            resetSortOrder();
            vm.patientReactivateDeactivate = new PatientReactivateDeactivate();
            vm.loadingPatients = true;
            ConfigurationService.fetchDefaultTablePageSize(TABLE_NAME_CONSTS.PATIENT_TABLE).then(function(pageLimit) {
                vm.limit = !Utility.isEmpty(pageLimit) ? pageLimit : DATA_LIMIT_CONSTS.DEFAULT_LIMIT;
            }).catch(function(err) {
                // this is just in case if 'defaultLimit' not returned from the API
                vm.limit = DATA_LIMIT_CONSTS.DEFAULT_LIMIT;
                $log.error('Unable to fetch portal data', err);
            }).finally(function() {
                vm.loadingPatients = false;
                if(Utility.isEmpty($rootScope.searchType) && Utility.isEmpty($rootScope.searchQuery)) {
                    loadPatients();
                }
            });
        };

        function _setInvalidURLNotification() {
            var toastInfo = Utility.getBadRequestToastInfo();
            _setMessageObject(toastInfo.title, toastInfo.message, toastInfo.type);
        }

        function loadPatients() {
            var resultListEqualsTotalCount = vm.patientCount === vm.patients.length;
            if(!Utility.shouldCallAPI(vm.loadingPatients, resultListEqualsTotalCount, vm.noDataFound)) return;
            if(!Utility.isEmpty(vm.currentSearchQuery) && vm.currentSearchQuery !== '') {
                vm.patientSearchLoadMoreCallback();
                return;
            }
            //Canceling previous call if in progress
            if(vm.isPromiseCalled) {
                vm.cancelableService.cancel();
                vm.listPatient.canceler.resolve();
            }
            vm.loadingPatients = true;
            vm.isPromiseCalled = true;

            vm.listPatient = PatientService.loadPatients(vm.fetchPatientCount, vm.viewablePatientStatus, vm.limit, vm.offset, vm.sortBy, vm.nextLink);
            vm.cancelableService.promiseObj = vm.cancelableService.wrap(vm.listPatient.promise);
            //this is to execute finally block if multiple promise does not exits and last promise has not been canceled.
            var isPromiseCanceled= false;
            vm.cancelableService.promiseObj.then(function(patientObj) {
                var paginationResponse = PaginationService.getPaginationResponse(patientObj, vm.patients, PATIENT_CONSTS.DATA_FILTER_KEYS.EXTERNAL_PATIENT_ID, vm.offset, vm.limit);
                vm.patientCount = paginationResponse.totalCount;
                vm.nextLink = paginationResponse.nextLink;
                vm.patients = paginationResponse.list;
                vm.noDataFound = false;
            }).catch(function(err) {
                //Recording flag if exception occurred due to force cancellation of promise to avoid executing finally block.
                isPromiseCanceled = err === ERROR_MESSAGES.FORCE_CANCEL;
                $log.error('Error loading the patient information:', err);
                //This value is set only if the first API call fails
                if(!vm.patients.length) {
                    vm.noDataFound = true;
                    vm.errorMessages.loadPatients.display = true;
                }
            }).finally(function() {
                //if promise is force canceled, finally block should not run.
                if(!isPromiseCanceled) {
                    vm.loadingPatientsWarningMessage = PATIENT_CONSTS.NO_PATIENT_WARNING_MESSAGE.replace(/__viewable_patient_status__/i, vm.viewablePatientStatus);
                    vm.loadingPatients = false;
                    vm.isPromiseCalled = false;
                }
            });
        }


        function setFacet(facet) {
            vm.selectedFacet = facet.searchCategory;
            vm.facetPlaceholder = facet.placeholderText;
        }

        function patientSearchCallback(searchModel) {
            vm.patients = [];
            vm.selectedAll = false;
            vm.searchResultText = null;

            if(Utility.isEmpty(searchModel.search)) {
                vm.noDataFound = true;
                vm.loadingPatientsWarningMessage = SEARCH_CONSTS.EMPTY_SEARCH_WARNING_MESSAGE;
                return;
            }
            vm.sortBy = searchModel.pagination.sortOrder;
            vm.selectedFacet = searchModel.selectedFacet;
            vm.loadingPatients = true;
            // query will be modified due to which we need to make copy of it
            vm.currentSearchQuery = angular.copy(searchModel.query);

            resetSortOrder();
            searchModel.search.then(function(response) {
                var paginationResponse = PaginationService.getPaginationResponse(response, vm.patients, PATIENT_CONSTS.DATA_FILTER_KEYS.EXTERNAL_PATIENT_ID, vm.offset, vm.limit);
                vm.patientCount = paginationResponse.totalCount;
                vm.patients = paginationResponse.list;
                vm.nextLink = paginationResponse.nextLink;
            }).catch(function() {
                //only set this value if there are no data previously
                if(!vm.patients.length) {
                    vm.noDataFound = true;
                }
                vm.patientCount = DATA_LIMIT_CONSTS.ZERO_LIMIT;
            }).finally(function() {
                vm.loadingPatients = false;
                vm.searchQuery = searchModel.query;
                vm.loadingPatientsWarningMessage = Search.getSearchResultText(vm.patientCount, vm.selectedFacet.searchCategory, vm.searchQuery);
                vm.searchResultText = Search.getInstructionTextForSearch(vm.patientCount, vm.selectedFacet.searchCategory, vm.searchQuery);
            });
        }

        function sortPatientData(sortBy) {
            vm.patients = [];
            // set default offset
            vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
            vm.nextLink = null;
            switch(sortBy) {
                case PATIENT_CONSTS.PATIENT_SORT.DATE_OF_BIRTH:
                    vm.dataSortOrders.dob.isAscending = !vm.dataSortOrders.dob.isAscending;
                    //negative sign indicates descending order
                    vm.sortBy = vm.dataSortOrders.dob.isAscending ? PATIENT_CONSTS.PATIENT_SORT.DATE_OF_BIRTH : '-' + PATIENT_CONSTS.PATIENT_SORT.DATE_OF_BIRTH;
                    break;
                case PATIENT_CONSTS.PATIENT_SORT.PATIENT_NAME:
                    vm.dataSortOrders.patientName.isAscending = !vm.dataSortOrders.patientName.isAscending;
                    //negative sign indicates descending order
                    vm.sortBy = vm.dataSortOrders.patientName.isAscending ? PATIENT_CONSTS.PATIENT_SORT.PATIENT_LAST_NAME : '-' + PATIENT_CONSTS.PATIENT_SORT.PATIENT_LAST_NAME;
                    break;
                default:
                    vm.sortBy = PATIENT_CONSTS.PATIENT_SORT.PATIENT_LAST_NAME;
            }
            vm.loadPatients();
        }

        function getPatientStatusToggleText() {
            return Utility.lowerCase(vm.viewablePatientStatus) === STATUS_CONSTS.STATUS.ACTIVE ? PATIENT_CONSTS.STATUS.INACTIVE_TEXT : PATIENT_CONSTS.STATUS.ACTIVE_TEXT;
        }

        function toggleViewableStatus() {
            ToastNotificationService.clearToastNotification();
            vm.selectedAll = false;
            vm.viewablePatientStatus = Utility.lowerCase(vm.viewablePatientStatus) === STATUS_CONSTS.STATUS.ACTIVE ? STATUS_CONSTS.STATUS.INACTIVE : STATUS_CONSTS.STATUS.ACTIVE;
            vm.deactivatedList = [];
            vm.reactivatedList = [];
            vm.selectedAll = false;
            vm.linkDisabled = true;
            vm.loadingPatients = false;
            vm.searchQuery = '';
            vm.currentSearchQuery = '';
            clearAllCallback();
        }

        function resetSortOrder() {
            vm.dataSortOrders = {patientName: {isAscending: true}, dob: {isAscending: false}};
        }

        function clearAllCallback() {
            vm.patients = [];
            vm.searchQuery = '';
            vm.searchResultText = null;
            vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
            vm.patientCount = DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE;
            vm.noDataFound = false;
            vm.currentSearchQuery = '';
            vm.nextLink = null;
            vm.sortBy = PATIENT_CONSTS.PATIENT_SORT.PATIENT_LAST_NAME;
            vm.selectedAll = false;
            resetSortOrder();
            loadPatients();
        }

        function patientSearchLoadMoreCallback() {
            vm.loadingPatients = true;

            var searchParam = Search.getSearchCategoryValue(vm.searchFacets, vm.selectedFacet.searchCategory);

            var searchQuery = angular.copy(vm.searchQuery);
            if(searchParam === PATIENT_CONSTS.PATIENT_SORT.DATE_OF_BIRTH) {
                searchQuery = PatientService.getArrayFormattedDateString(moment(vm.searchQuery).format(SEARCH_CONSTS.DATE_FORMAT));
            }
            var pagination = new SearchPaginationModel(null, vm.offset, vm.limit, vm.sortBy, vm.nextLink);
            var searchModel = new SearchModel(pagination, searchQuery, vm.selectedFacet, vm.viewablePatientStatus);

            Search.patientSearch(searchModel)
                .then(function(response) {
                    var paginationResponse = PaginationService.getPaginationResponse(response, vm.patients, PATIENT_CONSTS.DATA_FILTER_KEYS.EXTERNAL_PATIENT_ID, vm.offset, vm.limit);
                    vm.patientCount = paginationResponse.totalCount;
                    vm.patients = paginationResponse.list;
                    vm.nextLink = paginationResponse.nextLink;
                    (vm.patients.length === 0) ? vm.loadingPatientsWarningMessage = Search.getSearchResultText(vm.patientCount, vm.selectedFacet.searchCategory, vm.searchQuery) : vm.searchResultText = Search.getSearchResultText(vm.patientCount,vm.selectedFacet.searchCategory, vm.searchQuery);
                })
                .catch(function() {
                    //only set this value if there are no data previously
                    if(!vm.patients.length) {
                        vm.noDataFound = true;
                    }
                })
                .finally(function() {
                    vm.loadingPatients = false;
                });
        }

        function setInsuranceStatusClass(patientStatus, policy) {
            if(!isPatientActive(patientStatus)) return STYLES_CONSTS.STYLES.DISABLED;

            return !Utility.isEmpty(policy)
                    && !Utility.isEmpty(policy.plan)
                ? StatusStyles.setActiveStatusClass(policy.plan) : null;
        }

        function getCoverageStatusClass(patientStatus, policy) {
            if(!isPatientActive(patientStatus)) return STYLES_CONSTS.STYLES.DISABLED;

            return !Utility.isEmpty(policy)
                    && !Utility.isEmpty(policy.plan)
                    && !Utility.isEmpty(policy.plan.coverageStatus)
                ? PatientStyles.setCoverageDetailsClass(policy.plan.coverageStatus) : null;
        }

        function getCoverageDetailIconClass(patientStatus, policy) {
            if(!isPatientActive(patientStatus)) return STYLES_CONSTS.STYLES.DISABLED;

            return !Utility.isEmpty(policy)
                    && !Utility.isEmpty(policy.plan)
                    && !Utility.isEmpty(policy.plan.coverageStatus)
                ? PatientStyles.setCoverageDetailsIcon(policy.plan.coverageStatus) : null;
        }

        function isPatientActive(patientStatus){
            return !Utility.isEmpty(patientStatus)
                    && patientStatus.toLowerCase() === STATUS_CONSTS.STATUS.ACTIVE;
        }

        function getPatientActionText() {
            return vm.viewablePatientStatus === STATUS_CONSTS.STATUS.ACTIVE ? STATUS_CONSTS.ACTION_TEXT.DEACTIVATE_SELECTED : STATUS_CONSTS.ACTION_TEXT.REACTIVATE_SELECTED;
        }

        function changePatientStatus() {
            if (vm.listOfCheckedPatientsItems.length === 0) return false;
            _prepareNewSelectedList();
            return _prepareAndOpenReactivateDeactivateModal();
        }

        function _prepareAndOpenReactivateDeactivateModal() { 
            vm.patientReactivateDeactivate.resetDynamicSettings();  
            vm.patientReactivateDeactivate.setCurrentState(vm.listOfCheckedPatientsItems, vm.viewablePatientStatus);
            vm.patientReactivateDeactivate.setModalTitle(PATIENT_CONSTS.REACTIVATE_DEACTIVATE_MODAL_TITLE);
            vm.patientReactivateDeactivate.setModalSubTitle();
            vm.patientReactivateDeactivate.openReactivateDeactivateModal().then(function(response) {
                if(response) {
                    var patientsToBeUpdated = _filterPatientData(vm.listOfCheckedPatientsItems, (vm.viewablePatientStatus !== STATUS_CONSTS.STATUS.ACTIVE));
                    vm.updatePatientStatus(patientsToBeUpdated);
                }
            });
        }

        function _prepareNewSelectedList() {
            var newSelectedList = [];
            angular.forEach( vm.listOfCheckedPatientsItems, function (patient) {
                var patientToCompare = Utility.isObject(patient) ? patient.portalPatientId : patient;
                newSelectedList.push(Utility.getItemFromArray(vm.patients, 'portalPatientId', parseInt(patientToCompare)));
            });
            vm.listOfCheckedPatientsItems = newSelectedList;
        }

        /**
         *  Function responsible for preparing data to update patient status.
         *  This function filters the patient information to fetch only patient ID and active 'false' for deactivate and 'true' for reactivate
         */
        function _filterPatientData(patientList, status) {
            var filteredPatient = {
                patients: []
            };
            patientList.forEach(function (patientObject) {
                Utility.pushObjectInCollection({patientId: patientObject.patientId, active: status}, filteredPatient.patients);
            });
            return filteredPatient;
        }

        function updatePatientStatus(patientsToBeUpdated) {
            PatientService.updatePatientStatus(patientsToBeUpdated)
                .then(function () {
                    vm.getSuccessMessage();
                    vm.reactivatedList = [];
                    vm.deactivatedList = [];
                    vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
                    vm.nextLink = null;
                    vm.patients = [];
                    vm.selectedAll = false;
                    vm.searchResultText = '';
                    vm.loadPatients();
                }).catch(function (errorResponse) {
                    $log.error("Unable to update patients status " + errorResponse);
                    //Error message when both reactivate and deactivate request fails
                    _setMessageObject(GLOBAL_CONSTS.TOAST_TITLE.SYSTEM_ERROR, GLOBAL_CONSTS.TOAST_MESSAGE.SYSTEM_MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
                });
        }

        function getSuccessMessage() {
            if (vm.viewablePatientStatus === STATUS_CONSTS.STATUS.ACTIVE) {
                if(vm.listOfCheckedPatientsItems.length > 1) {
                    _setMessageObject(PATIENT_CONSTS.SUCCESS_MESSAGE.DEACTIVATE_MULTIPLE_PATIENT_TITLE, PATIENT_CONSTS.SUCCESS_MESSAGE.DEACTIVATE_MULTIPLE_PATIENT_MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
                } else {
                    _setMessageObject(PATIENT_CONSTS.SUCCESS_MESSAGE.DEACTIVATE_ONE_PATIENT_TITLE, PATIENT_CONSTS.SUCCESS_MESSAGE.DEACTIVATE_ONE_PATIENT_MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
                }
            } else {
                if(vm.listOfCheckedPatientsItems.length > 1) {
                    _setMessageObject(PATIENT_CONSTS.SUCCESS_MESSAGE.REACTIVATE_MULTIPLE_PATIENT_TITLE, PATIENT_CONSTS.SUCCESS_MESSAGE.REACTIVATE_MULTIPLE_PATIENT_MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
                } else {
                    _setMessageObject(PATIENT_CONSTS.SUCCESS_MESSAGE.REACTIVATE_ONE_PATIENT_TITLE, PATIENT_CONSTS.SUCCESS_MESSAGE.REACTIVATE_ONE_PATIENT_MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
                }
            }
        }

        function _setMessageObject(title, message, toastType) {
            ToastNotificationService.setToastNotification(
                ToastNotificationService.buildToastNotification(
                    true,
                    title,
                    message,
                    toastType
                )
            );
        }

        function _setPatientAddedNotification() {
            ToastNotificationService.setToastNotification(
                ToastNotificationService.buildToastNotification(
                    true,
                    PATIENT_CONSTS.SUCCESS_MESSAGE.PATIENT_ADDED_HEADER,
                    PATIENT_CONSTS.SUCCESS_MESSAGE.PATIENT_ADDED_BODY,
                    GLOBAL_CONSTS.TOAST_TYPES.SUCCESS
                )
            );
        }
    }
})();
