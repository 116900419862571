/**
 * Created by pratik budhathoki on 2/28/2018.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibility')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('rxEligibility', {
                url: '/eligibility/rx?patientId',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - RxEligibility'
                },
                params: {
                    showProviderError: false
                },
                views: {
                    'content@': {
                        templateUrl: 'components/eligibility/bv-selection/rx/rx-eligibility.html',
                        controller: 'RxEligibilityController as rxCtrl'
                    },
                    // Cached toast template file, this is necessary to display toast after browser offline
                    // http://cmikavac.net/2015/12/01/pre-caching-html-templates-with-angularjs-ui-router-directive/
                    cachedToastTemplate: {
                        templateUrl: 'components/toast-notification/toast-notification.html'
                    }
                }
            });
    }
})();
