/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.controller:BvHistoryController
 * @author Anna Lee
 * @requires $log
 * @description
 *
 * This is the controller for the BV History page.
 **/

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.BVHistory')
        .controller('BVHistoryController', BVHistoryController);


    BVHistoryController.$inject = ['$log', '$q', '$state', 'BVHistoryView', 'CaseBVService', 'STATUS_CONSTS', 'Eligibility', 'SEARCH_CONSTS', 'Utility', 'ELIGIBILITY_CONSTS', 'Search', 'ConfigurationService', 'PaginationService', 'DATA_LIMIT_CONSTS', 'HELP_TEXT_CONSTS', 'StatusStyles', 'BVSelectionService', 'ERROR_MESSAGES', '$anchorScroll', 'SearchModel', '$stateParams', 'ToastNotificationService', 'GLOBAL_CONSTS', 'SearchPaginationModel', 'CancelableQ'];

    function BVHistoryController($log, $q, $state, BVHistoryView, CaseBVService, STATUS_CONSTS, Eligibility, SEARCH_CONSTS, Utility, ELIGIBILITY_CONSTS, Search, ConfigurationService, PaginationService, DATA_LIMIT_CONSTS, HELP_TEXT_CONSTS, StatusStyles, BVSelectionService, ERROR_MESSAGES, $anchorScroll, SearchModel, $stateParams, ToastNotificationService, GLOBAL_CONSTS, SearchPaginationModel, CancelableQ) {
        var vm = this;

        // data
        vm.viewService = BVHistoryView;
        vm.viewableBvStatus = STATUS_CONSTS.BV_STATUS.COMPLETED;
        vm.showParameterSearch = false;
        vm.searchType = SEARCH_CONSTS.SEARCH_TYPE.BV;
        vm.searchFacets = BVHistoryView.getDefaultSearchFacets();
        vm.changeState = Utility.shouldChangeState;
        vm.patientManagementStatus = ConfigurationService.getPatientManagementStatus;

        vm.dataSortOrders = {
            responseId: {isAscending: true},
            patientName: {isAscending: true},
            providerName: {isAscending: true},
            dateCompleted: {isAscending: false}
        };

        vm.limit = null;
        vm.recentBVList = [];
        vm.totalBVCount = DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE; //initialized to negative as we do not want the bv list and the total bv count to be of same size in the beginning
        vm.fetchBVHistoryCount = true;
        /**
         *  By default the bv history is sorted by date completed
         *  Negative sign indicates descending order
         * */
        vm.sortBy = "-" + ELIGIBILITY_CONSTS.BV_SORT.DATE_COMPLETED;

        vm.searchOffset = DATA_LIMIT_CONSTS.ZERO_LIMIT;
        vm.selectedFacet = null;
        vm.currentSearchQuery = '';

        vm.bvFilterOptions = SEARCH_CONSTS.FILTER_BV.FILTER_OPTIONS;
        vm.filterQuery = SEARCH_CONSTS.FILTER_BV.FILTER_OPTIONS.ALL.VALUE;
        vm.filtered = false;
        vm.invalidUrl = $stateParams.invalidUrl;

        vm.nextLink = null;
        //Preserving promise object to cancel, if new data are requested.
        vm.cancelableService = CancelableQ;
        vm.isPromiseCalled = false;

        // functions
        vm.getBvHistoryStatusToggleText = getBvHistoryStatusToggleText;
        vm.isStatusComplete = isStatusComplete;
        vm.loadBvList = loadBvList;
        vm.setBenefitStatusClass = setBenefitStatusClass;
        vm.toggleViewableStatus = toggleViewableStatus;
        vm.sortRow = sortRow;
        vm.viewBVResponse = Eligibility.viewBVResponse;
        vm.clearAllCallback = clearSearchCallback;
        vm.dateRangeSearchCallback = dateRangeSearchCallback;
        vm.bvParameterSearchCallback = bvParameterSearchCallback;
        vm.setResultTextForSearch = setResultTextForSearch;
        vm.toggleParameterSearchBy = toggleParameterSearchBy;
        vm.getSearchText = getSearchText;
        vm.getIconTooltipInfo = StatusStyles.getIconTooltipInfo;
        vm.newMbvResponseFlag = newMbvResponseFlag;
        vm.sortBVData = sortBVData;
        vm.getIconClass = StatusStyles.getAdjudicationIconClass;
        vm.setToastErrorMessage = setToastErrorMessage;
        vm.filterBvList = filterBvList;
        vm.callForSearchParam = callForSearchParam;
        vm.getSearchWarningMessage = getSearchWarningMessage;
        vm.isAutoManualTriggered = isAutoManualTriggered;


        init();

        function init() {
            if(vm.invalidUrl) {
                _setInvalidURLNotification();
            }
            vm.loadingBvs = false;
            vm.noDataFound = false;
            BVHistoryView.configure({
                title: 'Benefit Verifications | Benefit Verification History',
                tabIndex: 0
            });

            if($state.params.status) {
                $log.debug('$state', $state.params);
                vm.viewableBvStatus = $state.params.status;
            }
            _fetchDefaultTablePageSize();
            BVSelectionService.getBvMethod().then(function(bvMethod) {
                vm.searchFacets.push.apply(vm.searchFacets, BVHistoryView.getSearchFacets(bvMethod));
            }).catch(function(err) {
                $log.debug(err);
            });
        }

        function _setInvalidURLNotification() {
            var toastInfo = Utility.getBadRequestToastInfo();
            ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, toastInfo.title, toastInfo.message, toastInfo.type));
        }

        function setBenefitStatusClass(status) {
            switch(status) {
                case STATUS_CONSTS.BV_STATUS.COMPLETED:
                    return 'success-message';
                case STATUS_CONSTS.BV_STATUS.IN_PROGRESS:
                    return 'warning-message';
            }
        }

        function getBvHistoryStatusToggleText() {
            var completedText = 'view in progress BVs';
            var inProgressText = 'view completed BVs';

            return Utility.lowerCase(vm.viewableBvStatus) === STATUS_CONSTS.BV_STATUS.COMPLETED ? completedText : inProgressText;
        }

        function loadBvList() {
            if(!_shouldCallAPI()) {
                return;
            }
            vm.searchBy = (!Utility.isEmpty(vm.selectedFacet) && !Utility.isEmpty(vm.selectedFacet.searchCategory) ? SEARCH_CONSTS.SEARCH_CATEGORY.PARAMETER : SEARCH_CONSTS.SEARCH_CATEGORY.DATE);

            if(!Utility.isEmpty(vm.currentSearchQuery) && vm.currentSearchQuery !== '') {
                callForSearchParam();
                return;
            }

            //Canceling previous call if in progress
            if(vm.isPromiseCalled) {
                vm.cancelableService.cancel();
                vm.bvList.canceler.resolve();
            }

            vm.loadingBvs = true;
            vm.isPromiseCalled = true;

            var bvSearchModel = _getSearchModel();

            vm.bvList = Search.bvSearchByCanceler(bvSearchModel);
            vm.cancelableService.promiseObj = vm.cancelableService.wrap(vm.bvList.promise);

            //this is to execute finally block if multiple promise does not exits and last promise has not been canceled.
            var isPromiseCanceled = false;

            vm.cancelableService.promiseObj
                .then(_mapResponseToBvList.bind(null, _setBvFilteredMessage.bind(null, bvSearchModel)))
                .catch(function (err) {
                    //Recording flag if exception occurred due to force cancellation of promise to avoid executing finally block.
                    isPromiseCanceled = err === ERROR_MESSAGES.FORCE_CANCEL;
                    _handleErrorForSearchBv.apply(null, err);
                })
                .finally(function () {
                    //if promise is force canceled, finally block should not run.
                    if(!isPromiseCanceled) {
                        _finallySearchBv();
                        vm.isPromiseCalled = false;
                    }
                });

        }

        function _searchAndSetBvData(setBvSettings) {
            Search.bvSearch(setBvSettings)
                .then(_mapResponseToBvList.bind(null, _setBvFilteredMessage.bind(null, setBvSettings)))
                .catch(_handleErrorForSearchBv)
                .finally(_finallySearchBv);
        }

        function _handleErrorForSearchBv(err) {
            $log.error("Error getting BV list", err);
            if (!vm.recentBVList.length) {
                vm.noDataFound = true;
            }
        }

        function _finallySearchBv() {
            vm.loadingBvs = false;
            vm.filtered = false;
        }

        function _mapResponseToBvList(setFilteredMessage, bvResponse) {
            var paginationResponse = PaginationService.getPaginationResponse(bvResponse, vm.recentBVList, 'responseId', vm.searchOffset, vm.limit);
            vm.totalBVCount = paginationResponse.totalCount;
            vm.nextLink = paginationResponse.nextLink;
            vm.recentBVList = paginationResponse.list;
            setFilteredMessage.call();
        }

        function _setBvFilteredMessage(setBvSettings) {
            if(!vm.filtered)
                vm.searchResultText = null;

            var searchResult = {};

            if(vm.showParameterSearch && !Utility.isBlank(setBvSettings.query) && !Utility.isBlank(setBvSettings.selectedFacet.searchCategory)) {
                searchResult = {
                    searchType: SEARCH_CONSTS.SEARCH_CATEGORY.PARAMETER,
                    param1: setBvSettings.query,
                    param2: setBvSettings.selectedFacet.searchCategory
                };
                vm.setResultTextForSearch(searchResult);
            }

            if(!vm.showParameterSearch
                    && !Utility.isEmpty(setBvSettings.query)
                    && !Utility.isBlank(setBvSettings.query.startDate)
                    && !Utility.isBlank(setBvSettings.query.endDate)) {
                searchResult = {
                    searchType: SEARCH_CONSTS.SEARCH_CATEGORY.DATE,
                    param1: setBvSettings.query.startDate,
                    param2: setBvSettings.query.endDate
                };
                vm.setResultTextForSearch(searchResult);
            }
        }

        function callForSearchParam() {
            if(vm.loadingBvs) {
                return;
            }
            vm.loadingBvs = true;
            _searchAndSetBvData(_getSearchModel());
        }

        function _getPaginationDetails(){
            return new SearchPaginationModel(vm.fetchBVHistoryCount, vm.searchOffset, vm.limit, vm.sortBy, vm.nextLink);
        }

        function _getSearchModel() {
            return new SearchModel(_getPaginationDetails(), vm.currentSearchQuery, vm.selectedFacet, vm.viewableBvStatus, null, vm.filterQuery, vm.searchBy);
        }

        function toggleViewableStatus() {
            vm.viewableBvStatus = Utility.lowerCase(vm.viewableBvStatus) === STATUS_CONSTS.BV_STATUS.COMPLETED ? STATUS_CONSTS.BV_STATUS.IN_PROGRESS : STATUS_CONSTS.BV_STATUS.COMPLETED;
            vm.filterQuery = SEARCH_CONSTS.FILTER_BV.FILTER_OPTIONS.ALL.VALUE;
            vm.nextLink = null;
            clearSearchCallback();
            _resetSortData();
        }

        function sortRow(bv) {
            var date = new Date(bv.dateCompleted);
            return date;
        }

        function newMbvResponseFlag(date, bvType) {
            if(!Utility.isEmpty(bvType) && Utility.lowerCase(bvType) === ELIGIBILITY_CONSTS.BV_TYPE.MANUAL && !Utility.isEmpty(date) && Utility.lowerCase(vm.viewableBvStatus) === STATUS_CONSTS.BV_STATUS.COMPLETED) {
                var responseDate = new Date(date);
                var addingResponseDate = new Date(responseDate.setDate(responseDate.getDate() + ELIGIBILITY_CONSTS.MAX_NEW_MBV_DATE));
                if(Utility.compareDateGreaterThanOrEqual(addingResponseDate, new Date())) {
                    return ELIGIBILITY_CONSTS.FLAG.NEW;
                }
            }
        }

        function clearSearchCallback() {
            vm.searchResultText = null;
            vm.searchQuery = null;
            vm.startDate = null;
            vm.endDate = null;
            vm.totalBVCount = DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE;
            vm.recentBVList = [];
            vm.searchOffset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
            vm.nextLink = null;
            vm.noDataFound = false;
            /**
             *  If the viewable status is completed then we sort the data by date completed
             *  else by response id
             *  Negative sign here indicates descending order
             * */
            vm.sortBy = vm.isStatusComplete() ? "-" + ELIGIBILITY_CONSTS.BV_SORT.DATE_COMPLETED : "-" + ELIGIBILITY_CONSTS.BV_SORT.RESPONSE_ID;
            vm.selectedFacet = null;
            vm.currentSearchQuery = '';
            vm.loadingBvs = false;
            vm.loadBvList();
            vm.searchWarningMessage = '';
        }

        /**
         * function to check if search callback promise object is null,
         * if null then set warning message for empty search query
         * @param searchCallBackModel
         * @param setSearchResult
         * @returns {boolean}
         * @private
         */
        function _isSearchCallBackEmpty(searchCallBackModel, setSearchResult) {
            if(Utility.isEmpty(searchCallBackModel) || Utility.isEmpty(searchCallBackModel.search)) {
                vm.selectedFacet = null;
                vm.currentSearchQuery = null;
                vm.noDataFound = true;
                setSearchResult.call();
                return true;
            }
            return false;
        }

        function dateRangeSearchCallback(searchCallBackModel) {
            vm.noDataFound = false;
            vm.recentBVList = [];
            vm.selectedFacet = null;

            var bound = setResultTextForSearch.bind(null, {searchType: SEARCH_CONSTS.SEARCH_CATEGORY.EMPTY});
            // check if the search is performed with empty or blank values
            if(_isSearchCallBackEmpty(searchCallBackModel, bound)) {
                return;
            }

            vm.loadingBvs = true;
            var searchResult = {
                searchType: SEARCH_CONSTS.SEARCH_CATEGORY.DATE,
                param1: searchCallBackModel.formattedStartDate,
                param2: searchCallBackModel.formattedEndDate
            };

            var currentSearchQuery = {
                startDate: searchCallBackModel.formattedStartDate,
                endDate: searchCallBackModel.formattedEndDate
            };

            _handleSearchCallback(searchCallBackModel, currentSearchQuery, setResultTextForSearch.bind(null, searchResult));

        }

        function bvParameterSearchCallback(searchCallBackModel) {
            vm.noDataFound = false;
            vm.recentBVList = [];
            vm.selectedFacet = null;

            var bound = setResultTextForSearch.bind(null, {searchType: SEARCH_CONSTS.SEARCH_CATEGORY.EMPTY});
            // check if the search is performed with empty or blank values
            if(_isSearchCallBackEmpty(searchCallBackModel, bound)) {
                return;
            }

            vm.loadingBvs = true;
            var searchResult = {
                searchType: SEARCH_CONSTS.SEARCH_CATEGORY.PARAMETER,
                param1: searchCallBackModel.query,
                param2: searchCallBackModel.selectedFacet.searchCategory
            };

            _handleSearchCallback(searchCallBackModel, searchCallBackModel.query, setResultTextForSearch.bind(null, searchResult));

        }

        function _handleSearchCallback(searchCallBackModel, currentSearchQuery, setSearchResult) {
            vm.selectedFacet = searchCallBackModel.selectedFacet;
            vm.sortBy = searchCallBackModel.pagination.sortOrder;
            searchCallBackModel.search.then(function(bvsFound) {
                var paginationResponse = PaginationService.getPaginationResponse(bvsFound, vm.recentBVList, 'responseId', vm.searchOffset, vm.limit);
                vm.totalBVCount = paginationResponse.totalCount;
                vm.recentBVList = paginationResponse.list;
                vm.nextLink = paginationResponse.nextLink;
                vm.currentSearchQuery = currentSearchQuery;
            }).catch(function(err) {
                $log.error('something went wrong searching for bv', err);
                vm.noDataFound = true;
                vm.totalBVCount = DATA_LIMIT_CONSTS.DEFAULT_COUNT_VALUE;
            }).finally(function() {
                setSearchResult.call();
                vm.loadingBvs = false;
            });
        }

        function setResultTextForSearch(searchResult) {
            var messageMap = Eligibility.getSearchResultAndWarningMessage.call(searchResult, vm.totalBVCount);
            vm.searchResultText = messageMap.searchResultText;
            vm.searchWarningMessage = messageMap.searchWarningMessage;
        }

        function isStatusComplete() {
            return Utility.lowerCase(vm.viewableBvStatus) === STATUS_CONSTS.BV_STATUS.COMPLETED;
        }

        function toggleParameterSearchBy() {
            vm.showParameterSearch = !vm.showParameterSearch;
            vm.currentSearchQuery = null;
            vm.selectedFacet = null;
        }

        function getSearchText() {
            return vm.showParameterSearch ? 'Search by Date' : 'Search by Parameter';
        }

        function _fetchDefaultTablePageSize() {
            vm.loadingBvs = true;
            ConfigurationService.fetchPortalConfiguration()
                .then(
                    function(response) {
                        if(!Utility.isEmpty(response) && !Utility.isEmpty(response.orgPropertiesMap)) {
                            if(!Utility.isEmpty(response.orgPropertiesMap.bvListPageSize)) {
                                vm.limit = parseInt(response.orgPropertiesMap.bvListPageSize);
                            } else if(!Utility.isEmpty(response.orgPropertiesMap.defaultTablePageSize)) {
                                vm.limit = parseInt(response.orgPropertiesMap.defaultTablePageSize);
                            } else {
                                vm.limit = DATA_LIMIT_CONSTS.DEFAULT_LIMIT; // This is just in case 'defaultTablePageSize' is not returned from the API
                            }
                        } else {
                            vm.limit = DATA_LIMIT_CONSTS.DEFAULT_LIMIT; // This is just in case 'defaultTablePageSize' is not returned from the API
                        }
                    }
                )
                .catch(
                    function(err) {
                        vm.limit = DATA_LIMIT_CONSTS.DEFAULT_LIMIT; // This is just in case 'defaultTablePageSize' is not returned from the API
                        $log.error('Unable to fetch portal data', err);
                    }
                )
                .finally(function() {
                    vm.loadingBvs = false;
                    //even if we do not get the default table page size, we call the loadBV
                    vm.loadBvList();
                });
        }

        function _shouldCallAPI() {
            return !vm.loadingBvs && vm.totalBVCount !== vm.recentBVList.length && !vm.noDataFound;
        }

        function sortBVData(sortBy) {
            vm.recentBVList = [];
            vm.nextLink = null;
            switch(sortBy) {
                case ELIGIBILITY_CONSTS.BV_SORT.RESPONSE_ID:
                    vm.dataSortOrders.responseId.isAscending = !vm.dataSortOrders.responseId.isAscending;
                    //negative sign indicates descending order
                    vm.sortBy = vm.dataSortOrders.responseId.isAscending ? ELIGIBILITY_CONSTS.BV_SORT.RESPONSE_ID : '-' + ELIGIBILITY_CONSTS.BV_SORT.RESPONSE_ID;
                    break;
                case ELIGIBILITY_CONSTS.BV_SORT.PATIENT_NAME:
                    vm.dataSortOrders.patientName.isAscending = !vm.dataSortOrders.patientName.isAscending;
                    //negative sign indicates descending order
                    vm.sortBy = vm.dataSortOrders.patientName.isAscending ? ELIGIBILITY_CONSTS.BV_SORT.PATIENT_NAME : '-' + ELIGIBILITY_CONSTS.BV_SORT.PATIENT_NAME;
                    break;
                case ELIGIBILITY_CONSTS.BV_SORT.PROVIDER_NAME:
                    vm.dataSortOrders.providerName.isAscending = !vm.dataSortOrders.providerName.isAscending;
                    //negative sign indicates descending order
                    vm.sortBy = vm.dataSortOrders.providerName.isAscending ? ELIGIBILITY_CONSTS.BV_SORT.PROVIDER_NAME : '-' + ELIGIBILITY_CONSTS.BV_SORT.PROVIDER_NAME;
                    break;
                case ELIGIBILITY_CONSTS.BV_SORT.DATE_COMPLETED:
                    vm.dataSortOrders.dateCompleted.isAscending = !vm.dataSortOrders.dateCompleted.isAscending;
                    //negative sign indicates descending order
                    vm.sortBy = vm.dataSortOrders.dateCompleted.isAscending ? ELIGIBILITY_CONSTS.BV_SORT.DATE_COMPLETED : '-' + ELIGIBILITY_CONSTS.BV_SORT.DATE_COMPLETED;
                    break;
            }
            vm.loadBvList();
        }

        function _resetSortData() {
            /**
             * Saving the value of the function into a variable so that we don't have to call the function multiple times
             *  By default COMPLETED BV should sort the data by dateCompleted and
             *  IN PROGRESS BV should sort the data by responseId
             * */
            var isStatusComplete = !vm.isStatusComplete();
            vm.dataSortOrders = {
                responseId: {isAscending: !isStatusComplete},
                patientName: {isAscending: isStatusComplete},
                providerName: {isAscending: isStatusComplete},
                dateCompleted: {isAscending: isStatusComplete}
            };
        }

        function setToastErrorMessage(errorMessage) {
            ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, ERROR_MESSAGES.SOB_ERROR.TITLE, errorMessage, ERROR_MESSAGES.SOB_ERROR.TYPE));
            $anchorScroll();
        }

        function filterBvList() {
            _resetPageSetting();
            _resetSortData();
            vm.filtered = true;
            vm.callForSearchParam();
        }

        function _resetPageSetting() {
            vm.recentBVList = [];
            vm.noDataFound = false;
            vm.searchResultText = null;
            vm.searchWarningMessage = null;
            vm.nextLink = null;
        }

        function getSearchWarningMessage() {
            return (vm.searchWarningMessage) ? vm.searchWarningMessage : Utility.replaceText(SEARCH_CONSTS.NO_DATA_FOUND_MESSAGE,'@ {viewableBvStatus}', vm.viewableBvStatus);
        }

        function isAutoManualTriggered() {
            return ConfigurationService.getAutoManualStatus();
        }
    }
})();
