(function () {
    'use strict';

    angular.module('eAccess.ProviderPortal.Directives').directive('eaDecimals', EaDecimals);

    EaDecimals.$inject = ['DATA_LIMIT_CONSTS'];

    // This directive limits an input to only digits
    function EaDecimals(DATA_LIMIT_CONSTS) {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function (scope, element, attrs, modelCtrl) {
                modelCtrl.$parsers.push(function (inputValue) {
                    if (inputValue === null || inputValue === '') {
                        return '';
                    }
                    var decimalPrecision = attrs.decimalPrecision || DATA_LIMIT_CONSTS.DECIMAL_PRECISION;
                    var validDigitsRegex = new RegExp('([0-9]+)(\\.?[0-9]{0,' + decimalPrecision + '})');

                    var cleanInputValue = validDigitsRegex.test(inputValue) ? inputValue.match(validDigitsRegex)[0] : null;
                    
                    if (cleanInputValue !== inputValue) {
                        modelCtrl.$setViewValue(cleanInputValue);
                        modelCtrl.$render();
                    }
                    return cleanInputValue;
                });
            }
        };
    }
})();
