(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.State')
        .component('eaStateList', {
            templateUrl: 'components/statelist/state-list.html',
            bindings: {
                ngModel: '=',
                ngRequired: '=',
                name: '=',
                id: '=',
                label: '=',
                form: '=',
                state: '=',
                errorMessage: '=',
                stateId: '=',
                helpText: '='
            },
            controller: 'StateListController as ctrl'
        });
})();
