/**
 * Created by pramesh on 7/19/2018.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Modals')
        .controller('AccountDeactivatedController', AccountDeactivatedController);

    AccountDeactivatedController.$inject = ['$log', '$uibModalInstance', 'modalTitle', 'modalBody'];

    function AccountDeactivatedController($log, $uibModalInstance, modalTitle, modalBody) {

        var vm = this;

        vm.titleText = modalTitle;
        vm.message = modalBody;

        vm.closeModal = closeModal;

        function closeModal() {
            $uibModalInstance.dismiss();
        }
    }
})();
