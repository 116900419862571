/**
 * Created by nikesh on 3/11/2018.
 */
(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Components.PatientInfo', [
            'eAccess.ProviderPortal.Constants.PatientInfoConstants'
        ]);
})();
