/**
 * This service holds list of organization for which system notification will be shown.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Services.SystemNotification')
        .factory('SystemNotificationService', SystemNotificationService);

    SystemNotificationService.$inject = ['Utility', '$uibModal', 'API', 'GLOBAL_CONSTS', 'SystemNotification'];

    function SystemNotificationService(Utility, $uibModal, API, GLOBAL_CONSTS, SystemNotification) {

        var service = {
            showPortalNotification: false,
            openSystemNotification: openSystemNotification,
            displayPublicNotification: _displayPublicNotification,
            displayInteriorNotification: _displayInteriorNotification,
            _fetchPublicNotification: _fetchPublicNotification,
            _fetchInteriorNotification: _fetchInteriorNotification
        };

        function openSystemNotification(settings) {
            if(settings.display && !Utility.isEmpty(settings.message)) {
                $uibModal.open({
                    templateUrl: 'partials/templates/warning-notification-modal.html',
                    controller: 'WarningNotificationModalController',
                    controllerAs: 'warningNotificationModal',
                    appendTo: angular.element('body'),
                    resolve: {
                        messageSetting: function() {
                            return {
                                title: settings.title,
                                content: settings.message,
                                btnTxt: settings.btnText
                            };
                        }
                    }
                });
            }
        }

        /*
         * Function responsible for fetching public notification.
         * @param notificationType : notification type to fetch | default: 'PUBLIC'
        */
        function _fetchPublicNotification(notificationType) {
            var notification = notificationType || Utility.upperCase(GLOBAL_CONSTS.PUBLIC);
            return API.get('notifications?notificationType=' + notification);
        }

        /*
         * Function responsible for fetching secured notification.
         * @param notificationType : notification type to fetch | default: 'INTERIOR'
        */
        function _fetchInteriorNotification(notificationType) {
            var notification = notificationType || Utility.upperCase(GLOBAL_CONSTS.INTERIOR);
            return API.get('notifications/interior?notificationType=' + notification);
        }

        /*
         * Function responsible for displaying public portal notification.
         * @param systemStatus : for system notification | default: false
         * @param notificationType : for custom notification type | default 'PUBLIC'
         * @param btnText : for custom button name
         */
        function _displayPublicNotification(systemStatus, notificationType, btnText) {
            systemStatus = systemStatus || false;
            notificationType = notificationType || null;
            service._fetchPublicNotification(notificationType).then(function(response) {
                var portalNotification = new SystemNotification(Utility.getObjectFromList(response, GLOBAL_CONSTS.SYSTEM_NOTIFICATION.KEYS.SYSTEM_NOTIFICATION,
                    systemStatus), btnText);
                openSystemNotification(portalNotification);
            });
        }

        /*
         * Function responsible for displaying secured portal notification.
         * @param systemStatus : for system notification | default: false
         * @param notificationType : for custom notification type | default 'INTERIOR'
         * @param btnText : for custom button name
         */
        function _displayInteriorNotification(systemStatus, notificationType, btnText) {
            systemStatus = systemStatus || false;
            notificationType = notificationType || null;
            service._fetchInteriorNotification(notificationType).then(function(response) {
                var portalNotification = new SystemNotification(Utility.getObjectFromList(response, GLOBAL_CONSTS.SYSTEM_NOTIFICATION.KEYS.SYSTEM_NOTIFICATION,
                    systemStatus), btnText);
                openSystemNotification(portalNotification);
                service.showPortalNotification = false;
            });
        }

        return service;
    }
})();
