/**
 * @ngdoc service
 * @name eAccess.ProviderPortal.Components.Reverifications.service:ReverificationsView
 * @author Anna Lee
 * @description
 *
 * This is the service for view functions that apply to reverifications view.
 **/

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Reverifications')
        .factory('ReverificationsView', ReverificationsView);

    ReverificationsView.$inject = [];

    function ReverificationsView() {

        var DEFAULTS = {
            TITLE: 'Benefit Verifications | Reverifications',
            TAB_INDEX: 1
        };

        var service = {
            configure: configure,
            setTabInfo: setTabInfo,
            setPageTitle: setPageTitle,
            setActiveTab: setActiveTab
        };

        return service;

        /**
         * @ngdoc function
         * @name configure
         * @methodOf eAccess.ProviderPortal.Components.Reverifications.service:ReverificationsView
         * @description
         *
         * Configures info for the Reverifications view based on passed config object.
         *
         * @param config
         * @example configure({
         *     title: '',
         *     tabIndex: '',
         * })
         */

        function configure(config) {
            setTabInfo(config.title, config.tabIndex);
        }


        /**
         * @ngdoc function
         * @name setTabInfo
         * @methodOf eAccess.ProviderPortal.Components.Reverifications.service:ReverificationsView
         * @description
         *
         * Sets the tab info for the reverifications view, including setting the page title
         * and changing the active tab.
         *
         * @param title
         * @param tabIndex
         */

        function setTabInfo(title, tabIndex) {
            setPageTitle(title);
            setActiveTab(tabIndex);
        }

        /**
         * @ngdoc function
         * @name setPageTitle
         * @methodOf eAccess.ProviderPortal.Components.Reverifications.service:ReverificationsView
         * @description
         *
         * Sets the page title on the reverifications view.
         *
         * @param title
         */

        function setPageTitle(title) {
            service.pageTitle = title || DEFAULTS.TITLE;
        }

        /**
         * @ngdoc function
         * @name setActiveTab
         * @methodOf eAccess.ProviderPortal.Components.Reverifications.service:ReverificationsView
         * @description
         *
         * Sets the active tab on reverification view.
         *
         * @param tabIndex
         */

        function setActiveTab(tabIndex) {
            service.activeTab = tabIndex || DEFAULTS.TAB_INDEX;
        }
    }
})();
