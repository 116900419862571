/**
 * @ngdoc Component
 * @module eAccess.ProviderPortal.Components.RxEligibilitySplitResponse
 * @name eaCoverageDetail
 * @kind Component
 *
 *
 * Basic Usage
 * <ea-coverage-detail response="passCoverageDetailResponse"></ea-coverage-detail>
 *
 * @author bikashshrestha
 */
(function () {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilitySplitResponse')
        .component('eaCoverageDetail', {
            bindings: {
                policy: '<',
                coverageDetailExpanded: '=',
            },
            templateUrl: 'components/eligibility/bv-selection/rx/split-response/coverage-details/coverage-detail.html',
            controller: 'CoverageDetailController as coverageDetail'
        });
})();
