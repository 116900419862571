/**
 * Created by aayush.regmi on 2/28/2018.
 */

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.BvSelection')
        .factory('BVSelectionService', BVSelectionService);

    BVSelectionService.$inject = ['BV_SELECTION_CONSTS', 'Utility', 'ConfigurationService', '$q', '$state', '$window', '$injector', '$log', 'CacheDataService', 'ELIGIBILITY_CONSTS'];

    function BVSelectionService(BV_SELECTION_CONSTS, Utility, ConfigurationService, $q, $state, $window, $injector, $log, CacheDataService, ELIGIBILITY_CONSTS) {

        var service = {
            getStateNameFromMethod: getStateNameFromMethod,
            getBvMethod: getBvMethod,
            setBvMethod: setBvMethod,
            isBVMethodAvailableToRedirect: isBVMethodAvailableToRedirect,
            redirectBenefitVerify: redirectBenefitVerify,
            getBvMethodName: getBvMethodName,
            getSwitchButtonText: getSwitchButtonText,
            getSwitchStateName: getSwitchStateName,
            getSwitchBvMethodName: getSwitchBvMethodName,
            getTooltipMessageForStateName: getTooltipMessageForStateName
        };

        var bvTypeObject = {
            medical: {
                methodName: ELIGIBILITY_CONSTS.BV_TYPE.MEDICAL,
                switchMethodName: ELIGIBILITY_CONSTS.BV_TYPE.RX,
                switchButtonText: ELIGIBILITY_CONSTS.BV_TYPE.RX,
                switchStateName: BV_SELECTION_CONSTS.STATE_NAME.RX,
                tooltipMessage: BV_SELECTION_CONSTS.TOOLTIP_MESSAGE.RX
            },
            rx: {
                methodName: ELIGIBILITY_CONSTS.BV_TYPE.RX,
                switchMethodName: ELIGIBILITY_CONSTS.BV_TYPE.MEDICAL,
                switchButtonText: ELIGIBILITY_CONSTS.BV_TYPE.MEDICAL,
                switchStateName: BV_SELECTION_CONSTS.STATE_NAME.MEDICAL,
                tooltipMessage: BV_SELECTION_CONSTS.TOOLTIP_MESSAGE.MEDICAL
            }
        };

        return service;

        function getStateNameFromMethod(methodName) {

            if(Utility.isEmpty(methodName)) {
                return BV_SELECTION_CONSTS.STATE_NAME.DASHBOARD;
            }

            switch(Utility.lowerCase(methodName).trim()) {
                case BV_SELECTION_CONSTS.METHOD_NAME.MEDICAL:
                    return BV_SELECTION_CONSTS.STATE_NAME.MEDICAL;
                case BV_SELECTION_CONSTS.METHOD_NAME.RX:
                    return BV_SELECTION_CONSTS.STATE_NAME.RX;
                case BV_SELECTION_CONSTS.METHOD_NAME.COMBINED:
                    return BV_SELECTION_CONSTS.STATE_NAME.BV_SELECTION;
                default:
                    return BV_SELECTION_CONSTS.STATE_NAME.DASHBOARD;
            }
        }

        function getBvMethod() {
            //Safe check to make sure we do not call the API until user has been logged in
            if(Utility.isEmpty($window.sessionStorage.getItem('auth_token'))) return $q.reject();
            if(!Utility.isEmpty(service.bvMethod)) {
                return $q.when(service.bvMethod);
            }
            var deferred = $q.defer();

            ConfigurationService.fetchPortalConfiguration().then(function(response) {
                service.bvMethod = Utility.lowerCase(response.portalBVMethod);
                return deferred.resolve(service.bvMethod);
            }).catch(function(err) {
                return deferred.reject(err);
            });

            return deferred.promise;
        }

        function setBvMethod(bvMethod) {
            service.bvMethod = Utility.lowerCase(bvMethod);
        }

        /**
         * @param url
         * @param event
         *
         * @description if BV Adjudication Method is configured for only Med or RX then,
         * it will be redirect to dashboard with forbidden message if user try to redirect to not allowed method
         */
        function isBVMethodAvailableToRedirect(url, event) {
            var isNavigatingToMed = (url.indexOf(BV_SELECTION_CONSTS.URL_NAME.MEDICAL) !== -1),
                isNavigatingToRx = (url.indexOf(BV_SELECTION_CONSTS.URL_NAME.RX) !== -1);
            if((isNavigatingToMed || isNavigatingToRx) &&
                !Utility.isEmpty(service.bvMethod) &&
                !Utility.isCombined(service.bvMethod)) {

                var forbiddenForRx = (isNavigatingToRx && Utility.isMedical(service.bvMethod)),
                    forbiddenForMed = (isNavigatingToMed && Utility.isRx(service.bvMethod));

                if(forbiddenForRx || forbiddenForMed) {
                    $state.go('dashboard', {forbidden: true});
                    event.preventDefault();
                }
            }
        }

        function redirectBenefitVerify(portalBvMethod, patientInfo) {
            var patient = null;
            if(portalBvMethod === BV_SELECTION_CONSTS.METHOD_NAME.COMBINED) {
                patient = (!Utility.isEmpty(patientInfo.lastBV) && !Utility.isEmpty(patientInfo.lastBV.patient)) ? patientInfo.lastBV.patient : patientInfo;
                CacheDataService.patient = patientInfo;
                $state.go(BV_SELECTION_CONSTS.STATE_NAME.BV_SELECTION, {
                    patient: patient,
                });
            } else {
                var eligibilityService = $injector.get('Eligibility');
                eligibilityService.prepareMedEligibilityFormData(patientInfo.patientId, portalBvMethod).then(function(formData) {
                    if(Utility.isMedical(portalBvMethod)) {
                        CacheDataService.setMedBvCacheData(formData);
                        $state.go(BV_SELECTION_CONSTS.STATE_NAME.MEDICAL, patient);
                    } else {
                        CacheDataService.setRxBvCacheData(formData);
                        $state.go(BV_SELECTION_CONSTS.STATE_NAME.RX, patient);
                    }
                });
            }
        }

        function getBvMethodName(bvType) {
            return bvTypeObject[Utility.lowerCase(bvType)].methodName;
        }

        function getSwitchBvMethodName(bvType) {
            return bvTypeObject[Utility.lowerCase(bvType)].switchMethodName;
        }

        function getSwitchButtonText(bvType) {
            return bvTypeObject[Utility.lowerCase(bvType)].switchButtonText;
        }

        function getSwitchStateName(bvType) {
            return bvTypeObject[Utility.lowerCase(bvType)].switchStateName;
        }

        function getTooltipMessageForStateName(bvType) {
            return bvTypeObject[Utility.lowerCase(bvType)].tooltipMessage;
        }

    }

})();
