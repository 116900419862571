/**
 * Created by nikesh on 11/2/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.AddLocation')
        .controller('AddLocationController', AddLocationController);

    AddLocationController.$inject = ['$log', '$state', 'PracticeLocationService', '$uibModal', 'Storage', 'HELP_TEXT_CONSTS', 'WarnOnLeave'];

    function AddLocationController($log, $state, PracticeLocationService, $uibModal, Storage, HELP_TEXT_CONSTS, WarnOnLeave) {
        var vm = this;

        // Data
        // validation criteria
        vm.validation = PracticeLocationService.getValidationInfo();
        // error messages for fields
        vm.errorMessages = PracticeLocationService.getErrorMessages(vm.validation);
        // help text messages
        vm.tooltipInfo = HELP_TEXT_CONSTS.PRACTICE_LOCATION;

        // functions
        vm.goBack = goBack;
        vm.saveLocation = saveLocation;
        vm.usePracticeDetailInfo = usePracticeDetailInfo;
        vm.usePracticeNpiAndTaxInfo = usePracticeNpiAndTaxInfo;
        vm.usePracticeContactInfo = usePracticeContactInfo;
        vm.showWarnOnLeaveOnCancel = showWarnOnLeaveOnCancel;

        vm.$onInit = function() {
            vm.usePracticeNpiAndTaxInfo();
        };

        /**
         * Set practice npi number and tax info when initializing add location page
         */
        function usePracticeNpiAndTaxInfo() {
            var practice = Storage.getSessionItem("practice");
            if(practice) {
                var data = angular.fromJson(practice);
                vm.location.npi = data.npi;
                vm.location.taxId = data.taxId;
            }
        }

        /**
         * Function that retrieves practice detail info from session and fills in.
         */
        function usePracticeDetailInfo() {
            var practice = Storage.getSessionItem("practice");
            if(practice) {
                var data = angular.fromJson(practice);
                vm.location.locationName = data.name;
                vm.usePracticeNpiAndTaxInfo();
                usePracticeContactInfo();
            }
        }

        /**
         * Function that retrieves practice contact info from session and fills in.
         */
        function usePracticeContactInfo() {
            var practice = Storage.getSessionItem("practice");
            if(practice) {
                var data = angular.fromJson(practice);
                vm.location.address1 = data.address1;
                vm.location.address2 = data.address2;
                vm.location.city = data.city;
                vm.location.state = data.state;
                vm.location.state = data.state;
                vm.location.zipCode = data.zip;
                vm.location.mainPhone = data.mainPhone;
                vm.location.mainFax = data.mainFax;
            }
        }

        /**
         * Function that will show warn on leave modal and takes user one step back if he/she agrees to leave.
         */
        function goBack() {
            var warnModal = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'warn-on-leave-title',
                arialDescribedBy: 'warn-on-leave-content',
                templateUrl: 'partials/templates/warn-on-leave-modal.html',
                controller: 'WarnOnBackModalController',
                controllerAs: 'warnOnLeave',
                appendTo: angular.element('body')
            });
            warnModal.result.then(function() {

                // We have to explicitly set object property to null
                // instead of assigning location with new empty object {}
                _resetLocation();

                vm.previousCallback();
            });
        }

        function saveLocation() {
            vm.parentForm.locationName.$setValidity('unique', true);

            if(vm.parentForm.$invalid) {
                return false;
            }

            vm.nextCallback(PracticeLocationService.createNewPracticeLocation(vm.location));
        }

        function showWarnOnLeaveOnCancel() {
            WarnOnLeave.show({form: undefined, toState: {name: 'login'}});
        }

        function _resetLocation() {
            vm.location.locationName = null;
            vm.location.npi = null;
            vm.location.taxId = null;
            vm.location.practiceLocationId = null;
            vm.location.address1 = null;
            vm.location.address2 = null;
            vm.location.city = null;
            vm.location.state = null;
            vm.location.zipCode = null;
            vm.location.mainFax = null;
            vm.location.mainPhone = null;
        }

    }

})();
