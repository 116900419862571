(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Search')
        .factory('Search', Search);

    Search.$inject = ['$log', 'API', 'PatientModel', 'ProviderModel', 'PracticeLocationModel', 'PatientService', 'PracticeUserModel', '$q', 'CaseModel', 'SEARCH_CONSTS', 'DATA_LIMIT_CONSTS', 'STATUS_CONSTS', 'Utility', 'ELIGIBILITY_CONSTS', 'PROVIDER_CONSTS', 'PATIENT_CONSTS', 'CaseBVService', 'LOCATION_CONSTS'];

    function Search($log, API, PatientModel, ProviderModel, PracticeLocationModel, PatientService, PracticeUserModel, $q, CaseModel, SEARCH_CONSTS, DATA_LIMIT_CONSTS, STATUS_CONSTS, Utility, ELIGIBILITY_CONSTS, PROVIDER_CONSTS, PATIENT_CONSTS, CaseBVService, LOCATION_CONSTS) {
        var service = {
            patientSearch: patientSearch,
            providerSearch: providerSearch,
            search: search,
            locationSearch: locationSearch,
            userSearch: userSearch,
            bvSearch: bvSearch,
            mapCase: mapCase,
            getSortValueForSelectedFaceted: getSortValueForSelectedFaceted,
            getSearchKeyForCategoryName: getSearchKeyForCategoryName,
            getSearchFacetForProvider: getSearchFacetForProvider,
            getSearchCategoryValue: getSearchCategoryValue,
            getSearchResultText: getSearchResultText,
            getInstructionTextForSearch: getInstructionTextForSearch,
            getTotalCount: getTotalCount,
            reverificationSearch: reverificationSearch,
            bvSearchByCanceler: bvSearchByCanceler
        };

        function _performSearch(query, category, endpoint, searchParams, queryParams, nextLink) {
            searchParams = angular.isUndefined(searchParams) ? {} : searchParams;
            searchParams[category] = query;
            var config = null;

            return API.post(endpoint + '/search', searchParams, config, queryParams, null, nextLink);
        }

        function search(query, category) {
            var searchParams = {};
            searchParams[category] = query;
            return API.post('patients/search', searchParams).then(function(response) {
                return response;
            });
        }

        function patientSearch(searchParam) {
            var queryParams;
            queryParams = {
                count: searchParam.pagination.fetchCount,
                offset: searchParam.pagination.offset || DATA_LIMIT_CONSTS.DEFAULT_OFFSET,
                limit: searchParam.pagination.limit || DATA_LIMIT_CONSTS.DEFAULT_LIMIT,
                sort: searchParam.pagination.sortOrder,
                active: searchParam.viewableStatus === STATUS_CONSTS.STATUS.ACTIVE
            };


            var patientResultObj = {
                totalCount: DATA_LIMIT_CONSTS.DEFAULT_PATIENT_RESULT_COUNT,
                responseList: []
            };
            var emptyObject = {};

            if(searchParam.includePayer) {
                var deferred = $q.defer();
                _performSearch(searchParam.query, searchParam.selectedFacet.value, 'patients', emptyObject, queryParams, searchParam.pagination.nextLink).then(function(response) {
                    PatientService.getPatientWithBvs(response).then(function(patientWithBvs) {
                        deferred.resolve(patientWithBvs);
                    });
                }).catch(function(err) {
                    deferred.reject(err);
                });

                return deferred.promise;
            } else {
                return _performSearch(searchParam.query, searchParam.selectedFacet.value, 'patients', emptyObject, queryParams, searchParam.pagination.nextLink).then(function(response) {
                    patientResultObj.totalCount = response.patientCount;
                    patientResultObj.responseList = response.patientList.map(function(patientData) {
                        return new PatientModel(patientData);
                    });
                    if(!Utility.isEmpty(response.link)) {
                        patientResultObj.link = response.link;
                    }

                    return patientResultObj;
                });
            }
        }

        function providerSearch(searchModel) {

            var queryParams = {
                count: searchModel.pagination.fetchCount,
                offset: searchModel.pagination.offset || DATA_LIMIT_CONSTS.DEFAULT_OFFSET,
                limit: searchModel.pagination.limit || DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE, //-1 represents all data,
                active: Utility.lowerCase(searchModel.viewableStatus) === STATUS_CONSTS.STATUS.ACTIVE,
                sort: searchModel.pagination.sortOrder || PROVIDER_CONSTS.PROVIDER_SORT.PROVIDER_NAME
            };

            return _performSearch(searchModel.query, searchModel.selectedFacet.value, 'providers', {}, queryParams, searchModel.pagination.nextLink).then(function(response) {
                var providers = response.providerList.map(function(provider) {
                    return new ProviderModel(provider);
                });
                var providerObj = {
                    totalCount: response.providerCount || DATA_LIMIT_CONSTS.DEFAULT_COUNT_VALUE,
                    responseList: providers || []
                };
                providerObj.link = (!Utility.isEmpty(response.link)) ? response.link : null;
                return providerObj;
            });
        }

        function locationSearch(searchModel) {
            var queryParams = {
                active: searchModel.viewableStatus === STATUS_CONSTS.STATUS.ACTIVE,
                count: searchModel.pagination.fetchCount,
                offset: searchModel.pagination.offset || DATA_LIMIT_CONSTS.DEFAULT_OFFSET,
                limit: searchModel.pagination.limit || DATA_LIMIT_CONSTS.DEFAULT_LIMIT,
                sort: searchModel.pagination.sortOrder || LOCATION_CONSTS.LOCATION_SORT.LOCATION_NAME
            };

            var deferred = $q.defer();
            _performSearch(searchModel.query, searchModel.selectedFacet.value, 'locations', {}, queryParams, searchModel.pagination.nextLink).then(function(response) {
                var locationObject = {
                    totalCount: response.locationCount,
                    responseList: response.locationList.map(function(location) {
                        return new PracticeLocationModel(location);
                    })
                };
                if(!Utility.isEmpty(response.link)) {
                    locationObject.link = response.link;
                }
                deferred.resolve(locationObject);
            }).catch(function(err) {
                deferred.reject(err);
            });
            return deferred.promise;
        }

        function userSearch(searchModel) {
            var queryParams = {
                count: searchModel.pagination.fetchCount,
                offset: searchModel.pagination.offset || DATA_LIMIT_CONSTS.DEFAULT_OFFSET,
                limit: searchModel.pagination.limit || DATA_LIMIT_CONSTS.DEFAULT_LIMIT,
                sort: searchModel.pagination.sortOrder,
                active: Utility.lowerCase(searchModel.viewableStatus) === STATUS_CONSTS.STATUS.ACTIVE
            };
            var deferred = $q.defer();
            _performSearch(searchModel.query, searchModel.selectedFacet.value, 'users', {}, queryParams, searchModel.pagination.nextLink).then(function(response) {
                var userObject = {
                    totalCount: response.userCount,
                    responseList: response.userList.map(function(user) {
                        return new PracticeUserModel(user);
                    })
                };
                if(!Utility.isEmpty(response.link)) {
                    userObject.link = response.link;
                }
                deferred.resolve(userObject);
            }).catch(function(err) {
                deferred.reject(err);
            });
            return deferred.promise;
        }

        function bvSearchByCanceler(bvSearchModel) {
            var canceler = $q.defer();
            var deferred = $q.defer();

            var queryParams = _getBvQueryParams(bvSearchModel);
            var searchParam = _getBvSearchParams(bvSearchModel);

            var endpoint = _getEndpoint(bvSearchModel);

            var bvApi = API.post(endpoint, searchParam, null, queryParams, canceler, bvSearchModel.pagination.nextLink);
            bvApi.promise.then(_mapBvResponse.bind(null, deferred))
                .catch(_handleBvError.bind(null, deferred));

            return {
                promise: deferred.promise,
                canceler: bvApi.canceler
            };
        }

        function _handleBvError(deferred, err) {
            $log.error('There was on getting bv list', err);
            deferred.reject(err);
        }

        function _getBvQueryParams(bvSearchModel) {
            return {
                count: bvSearchModel.pagination.fetchCount,
                offset: bvSearchModel.pagination.offset || DATA_LIMIT_CONSTS.DEFAULT_OFFSET,
                limit: bvSearchModel.pagination.limit || DATA_LIMIT_CONSTS.CASEBV_LIMIT, //-1 represents all data,
                complete: Utility.lowerCase(bvSearchModel.viewableStatus) === STATUS_CONSTS.BV_STATUS.COMPLETED,
                sort: bvSearchModel.pagination.sortOrder || "-" + ELIGIBILITY_CONSTS.BV_SORT.DATE_COMPLETED // negative sign indicates descending order
            };
        }

        function _getBvSearchParams(bvSearchModel) {
            var searchParam = {};

            if(bvSearchModel.searchBy === SEARCH_CONSTS.SEARCH_TYPE.DATE
                && !Utility.isBlank(bvSearchModel.query)
                && !Utility.isBlank(bvSearchModel.query.startDate)
                && !Utility.isBlank(bvSearchModel.query.endDate)) {
                searchParam = bvSearchModel.query;
            } else if(!Utility.isEmpty(bvSearchModel.selectedFacet) && !Utility.isEmpty(bvSearchModel.selectedFacet.searchCategory)) {
                switch(bvSearchModel.selectedFacet.searchCategory) {
                    case SEARCH_CONSTS.SEARCH_TYPE.PATIENT_LAST_NAME:
                        searchParam.patientLastName = bvSearchModel.query;
                        break;
                    case SEARCH_CONSTS.SEARCH_TYPE.PRACTICE_PATIENT_ID:
                        searchParam.externalId = bvSearchModel.query;
                        break;
                    case SEARCH_CONSTS.SEARCH_TYPE.PAYER_NAME:
                        searchParam.payerName = bvSearchModel.query;
                        break;
                    case SEARCH_CONSTS.SEARCH_TYPE.PBM_NAME:
                        searchParam.pbmName = bvSearchModel.query;
                        break;
                }
            }

            if(!Utility.isEmpty(bvSearchModel.filterQuery) && !Utility.isEmpty(searchParam))
                searchParam.patientActive = bvSearchModel.filterQuery;

            return searchParam;
        }

        /**
         * function to map bv response to result object for view model
         * @param deferred
         * @param response
         * @private
         */
        function _mapBvResponse(deferred, response) {
            $log.info("response", arguments);
            var resultObject = {
                totalCount: ELIGIBILITY_CONSTS.DEFAULT_TOTAL_COUNT,
                responseList: []
            };
            resultObject.totalCount = response.benefitVerifcationCount;
            resultObject.responseList = response.benefitVerificationList.filter(function(data) {
                return !Utility.isEmpty(data.coverageResponseDto);
            }).map(CaseBVService.mapCase);
            if(!Utility.isEmpty(response.link)) {
                resultObject.link = response.link;
            }
            deferred.resolve(resultObject);
        }

        function bvSearch(bvSearchModel) {
            var queryParams = _getBvQueryParams(bvSearchModel);
            var searchParam = _getBvSearchParams(bvSearchModel);

            var endpoint = _getEndpoint(bvSearchModel);
            var deferred = $q.defer();

            API.post(endpoint, searchParam, null, queryParams, null, bvSearchModel.pagination.nextLink)
                .then(_mapBvResponse.bind(null, deferred))
                .catch(_handleBvError.bind(null, deferred));

            return deferred.promise;
        }

        /**
         * function to provide endpoint for bv list (Complete or In Progress)
         * @param bvSearchModel
         * @returns {string}
         * @private
         */
        function _getEndpoint(bvSearchModel) {
            return Utility.lowerCase(bvSearchModel.viewableStatus) === STATUS_CONSTS.BV_STATUS.COMPLETED ?
                'ebv/combined/search' : 'manual/combined/search';
        }

        function getTotalCount(searchPromise) {
            var deferred = $q.defer();
            searchPromise.then(function(data) {
                deferred.resolve(data.totalCount);
            }).catch(function(error) {
                $log.info("Unable to fetch data: ", error);
                deferred.reject();
            });
            return deferred.promise;

        }

        function reverificationSearch(searchModel) {
            var queryParams = {
                count: searchModel.pagination.fetchCount || false,
                offset: searchModel.pagination.offset || DATA_LIMIT_CONSTS.DEFAULT_OFFSET,
                limit: searchModel.pagination.limit || DATA_LIMIT_CONSTS.CASEBV_LIMIT, //-1 represents all data,
                sort: searchModel.pagination.sortOrder || "-" + ELIGIBILITY_CONSTS.BV_SORT.RECENT_BV // negative sign indicates descending order
            };
            var searchParam = {};

            var endpoint = 'ebv/combined/rever/search';
            var resultObject = {
                totalCount: DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE,
                responseList: []
            };

            var deferred = $q.defer();

            if(searchModel.searchBy === SEARCH_CONSTS.SEARCH_TYPE.DAY
                && !Utility.isBlank(searchModel.query)
                && !Utility.isBlank(searchModel.query.minDays)
                && !Utility.isBlank(searchModel.query.maxDays)) {
                searchParam = searchModel.query;
            } else {
                switch(searchModel.selectedFacet.searchCategory) {
                    case SEARCH_CONSTS.SEARCH_TYPE.PATIENT_LAST_NAME:
                        searchParam.patientLastName = searchModel.query;
                        break;
                    case SEARCH_CONSTS.SEARCH_TYPE.PRACTICE_PATIENT_ID:
                        searchParam.practicePatientId = searchModel.query;
                        break;
                    case SEARCH_CONSTS.SEARCH_TYPE.PAYER_NAME:
                        searchParam.payerName = searchModel.query;
                        break;
                    case SEARCH_CONSTS.SEARCH_TYPE.PBM_NAME:
                        searchParam.pbmName = searchModel.query;
                        break;
                }
            }

            API.post(endpoint, searchParam, null, queryParams, null, searchModel.pagination.nextLink).then(
                function(response) {
                    resultObject.totalCount = response.benefitVerifcationCount;
                    resultObject.responseList = response.benefitVerificationList.filter(function(data) {
                        return !Utility.isEmpty(data.coverageResponseDto);
                    }).map(CaseBVService.mapReverificationCase);
                    if(!Utility.isEmpty(response.link)) {
                        resultObject.link = response.link;
                    }
                    deferred.resolve(resultObject);
                }
            ).catch(function(err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function mapCase(bvCase) {
            if(!Utility.isEmpty(bvCase) && !Utility.isEmpty(bvCase.data)) {
                return new CaseModel(bvCase.data);
            } else if(!Utility.isEmpty(bvCase) && !Utility.isEmpty(bvCase.coverageResponseDto) && !Utility.isEmpty(bvCase.coverageResponseDto.data)) {
                return new CaseModel(bvCase.coverageResponseDto.data);
            }
        }

        /**
         * Returns sort value for given selected faceted value
         * @param selectedFacetedValue
         * @returns {string}
         */
        function getSortValueForSelectedFaceted(selectedFacetedValue) {
            switch(selectedFacetedValue) {
                case SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.LAST_NAME: // patient last name
                case SEARCH_CONSTS.SEARCH_FACETS.USER_SEARCH_CATEGORY.USER_ROLE_VALUE: // practice user role
                case SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.DATE_OF_BIRTH: // patient dob
                    return PATIENT_CONSTS.PATIENT_SORT.PATIENT_LAST_NAME;

                case SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.PRACTICE_PATIENT_ID_MODAL: // external id
                case SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.PRACTICE_PATIENT_ID:   // practice patient id
                    return ELIGIBILITY_CONSTS.BV_SORT.PRACTICE_PATIENT_ID;

                case SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.PAYER_NAME:   // payer name
                    return ELIGIBILITY_CONSTS.BV_SORT.PAYER_NAME;

                case SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.PROVIDER_LAST_NAME:   // provider last name
                case SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.PROVIDER_NPI_NUMBER:   // npi number
                case SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.PRACTICE_PROVIDER_ID:   // practice provider id
                    return PROVIDER_CONSTS.PROVIDER_SORT.LAST_NAME;

                case SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.PBM_NAME:   // pbm name
                    return ELIGIBILITY_CONSTS.BV_SORT.PBM_NAME;

                case SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.PATIENT_NAME: // patient name (for bv history we use 'patientName')
                    return PATIENT_CONSTS.PATIENT_SORT.PATIENT_NAME;

                default:
                    return '';
            }
        }

        /**
         * Returns the search key for the provided category name
         * @param categoryName
         * @returns {string}
         */
        function getSearchKeyForCategoryName(categoryName) {
            switch(categoryName) {
                case SEARCH_CONSTS.SEARCH_FACETS.PROVIDER.SEARCH_CATEGORY.PROVIDER_LAST_NAME:   // provider last name
                    return SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.PROVIDER_LAST_NAME;

                case SEARCH_CONSTS.SEARCH_FACETS.PROVIDER.SEARCH_CATEGORY.PROVIDER_NPI_NUMBER:   // npi number
                    return SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.PROVIDER_NPI_NUMBER;

                case SEARCH_CONSTS.SEARCH_FACETS.PROVIDER.SEARCH_CATEGORY.PRACTICE_PROVIDER_ID:   // practice provider id
                    return SEARCH_CONSTS.SEARCH_FACETS.FACET_VALUES.PRACTICE_PROVIDER_ID;

                case SEARCH_CONSTS.SEARCH_FACETS.LOCATION.SEARCH_CATEGORY.LOCATION_NAME:   // location name
                    return SEARCH_CONSTS.SEARCH_FACETS.LOCATION.VALUE.LOCATION_NAME;

                case SEARCH_CONSTS.SEARCH_FACETS.LOCATION.SEARCH_CATEGORY.PRACTICE_LOCATION_ID:   // practice location id
                    return SEARCH_CONSTS.SEARCH_FACETS.LOCATION.VALUE.PRACTICE_LOCATION_ID;

                default:
                    return '';
            }
        }

        function getSearchFacetForProvider() {
            return [
                {
                    searchCategory: SEARCH_CONSTS.SEARCH_FACETS.PROVIDER.SEARCH_CATEGORY.PROVIDER_LAST_NAME,
                    placeholderText: SEARCH_CONSTS.SEARCH_FACETS.PROVIDER.PLACE_HOLDER_TEXT.PROVIDER_LAST_NAME,
                    value: SEARCH_CONSTS.SEARCH_FACETS.PROVIDER.VALUE.PROVIDER_LAST_NAME
                },
                {
                    searchCategory: SEARCH_CONSTS.SEARCH_FACETS.PROVIDER.SEARCH_CATEGORY.PROVIDER_NPI_NUMBER,
                    placeholderText: SEARCH_CONSTS.SEARCH_FACETS.PROVIDER.PLACE_HOLDER_TEXT.PROVIDER_NPI_NUMBER,
                    value: SEARCH_CONSTS.SEARCH_FACETS.PROVIDER.VALUE.PROVIDER_NPI_NUMBER
                },
                {
                    searchCategory: SEARCH_CONSTS.SEARCH_FACETS.PROVIDER.SEARCH_CATEGORY.PRACTICE_PROVIDER_ID,
                    placeholderText: SEARCH_CONSTS.SEARCH_FACETS.PROVIDER.PLACE_HOLDER_TEXT.PRACTICE_PROVIDER_ID,
                    value: SEARCH_CONSTS.SEARCH_FACETS.PROVIDER.VALUE.PRACTICE_PROVIDER_ID
                }
            ];
        }

        /**
         * Searches in searchFacetOptions list for an item with category attribute value matching facetCategory. If found returns its corresponding value other ''
         * @param searchFacetOptions
         * @param facetCategory
         * @returns {string}
         */
        function getSearchCategoryValue(searchFacetOptions, facetCategory) {
            var value = '';
            searchFacetOptions.forEach(function(item) {
                if(item.searchCategory === facetCategory) {
                    value = item.value;
                }
            });

            return value;
        }

        /**
         * Helps to create search result text based on supplied parameter.
         * @param resultCount
         * @param searchCategory
         * @param searchQuery
         */
        function getSearchResultText(resultCount, searchCategory, searchQuery) {
            var resultText = '';
            if(resultCount <= 0) {
                resultText = Utility.replaceText(Utility.replaceText(SEARCH_CONSTS.SEARCH_INSTRUCTION.EMPTY_CASE, "@CATEGORY", searchCategory), '@QUERY', searchQuery);
            } else {
                resultText = getInstructionTextForSearch(resultCount, searchCategory, searchQuery);
            }

            return resultText;
        }

        function getInstructionTextForSearch(resultCount, searchCategory, searchQuery) {
            var singularPlural = resultCount > 1 ? SEARCH_CONSTS.RESULTS : SEARCH_CONSTS.RESULTS.substring(0, SEARCH_CONSTS.RESULTS.length - 1);
            return resultCount + ' ' + singularPlural + ' for "' + searchCategory + '" of "' + searchQuery + '"';
        }

        return service;
    }

})();
