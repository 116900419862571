/**
 * Created by nikesh on 9/15/2017.
 */
(function() {
    'use strict';

    var VALUES = {
        SUCCESS_MESSAGE: {
            UPDATED_HEADER: 'Patient Successfully Updated',
            UPDATED_MESSAGE: 'The patient information was successfully updated.',
            DEACTIVATED_HEADER: 'Patient Successfully Deactivated',
            DEACTIVATED_MESSAGE: 'The patient was successfully deactivated. You may reactivate this patient by clicking the Reactivate link.',
            DEACTIVATE_ONE_PATIENT_TITLE: 'Patient Successfully Deactivated',
            DEACTIVATE_MULTIPLE_PATIENT_TITLE: 'Patients Successfully Deactivated',
            DEACTIVATE_ONE_PATIENT_MESSAGE: "The patient was deactivated and removed from your practice's Active Patient list. You may view and/or reactivate the patient by selecting the View Inactive Patients link on the patient list page.",
            DEACTIVATE_MULTIPLE_PATIENT_MESSAGE: "The selected patients were deactivated and removed from your practice's patient list. You may view and/or reactivate patients by selecting the View Inactive Patients link on the patient list page.",
            REACTIVATE_ONE_PATIENT_TITLE: 'Patient Successfully Reactivated',
            REACTIVATE_MULTIPLE_PATIENT_TITLE: 'Patients Successfully Reactivated',
            REACTIVATE_ONE_PATIENT_MESSAGE: "The patient was reactivated and returned to your practice's patient list. You may view and/or deactivate the patient by selecting the View Active Patients link on the patient list page.",
            REACTIVATE_MULTIPLE_PATIENT_MESSAGE: "The selected patients were reactivated and returned to your practice's patient list. You may view and/or deactivate patients by selecting the View Active Patients link on the patient list page.",
            ACTIVATED_MESSAGE: 'The patient was successfully activated. You may deactivate this patient by clicking the Deactivate link.',
            INSURANCE_UPDATED_MESSAGE: 'The patient\'s insurance information was successfully updated.',
            PATIENT_ADDED_BODY: 'The patient has been successfully added to your practice.',
            PATIENT_ADDED_HEADER: 'Patient Successfully Added'
        },
        PATIENT_ALREADY_EXISTS: {
            HEADER: 'Patient Already Exists',
            MESSAGE: 'The patient could not be added because they are already in the system. Please contact Customer Service if they are not and this problem persists.'
        },
        PATIENT_NOT_ADDED: {
            HEADER: 'Patient Not Added',
            MESSAGE: 'The patient was not successfully added to your practice. Please try to "Save" again. If the error persists, please contact Customer Service.'
        },
        CONSENT_TEXT: {
            TITLE: "Patient Consent",
            TITLE_SUB_TEXT: "(one of the following is required for Benefit Verification processing)",
            CONSENT_LABEL: "Health Care Provider",
            CONSENT_LABEL_SUB_TEXT: "(or other individual authorized by covered entity to make certification)",
            E_HIPAA_LABEL: "Electronic Patient Consent",
            E_HIPAA_LABEL_SUB_TEXT: "(eHIPAA)",
            E_HIPAA_DEFAULT_TEXT: "Enter a valid email or mobile phone number for the patient. They will receive an invitation with a link from eHIPAA.com to proceed.",
            E_HIPAA_COMPLETED_TEXT: "Patient electronically signed consent on __signed-date__ and expires on __expiry-date__.",
            E_HIPAA_INPUT_PLACEHOLDER: "Email Address or Mobile Phone Number",
            E_HIPAA_BUTTON_TEXT: "Send Invitation to Patient",
            E_HIPAA_BUTTON_SENDING_TEXT: "Sending Invitation to Patient",
            E_HIPAA_PDF_LINK_TEXT: "Click HERE to view.",
            CHECK_UPDATE_TEXT: "Check for Updates",
            E_HIPAA_INVITE_STATUS: {
                ACCEPTED: "accepted",
                SENT: "sent",
                ENGAGED: "engaged",
                COMPLETE: "complete",
                INVALID: "invalid",
                EXPIRED: "expired"
            },
            TYPE: {
                HCP: 'hcp',
                EHIPAA: 'ehipaa'
            },
            TYPE_API: {
                HCP: 'FILE',
                EHIPAA: 'EHIPAA'
            },
            VALIDATION: {
                INVALID_EMAIL: 'Enter a valid email address',
                INVALID_MOBILE: 'Enter a valid 10-digit mobile phone number',
                EMAIL_OR_MOBILE_REQUIRED: 'Email Address or Mobile Phone Number is required'
            },
            STATUS: {
                COMPLETE: 'complete',
                INCOMPLETE: 'incomplete',
                INITIATED: 'initiated',
                ACCEPTED: 'accepted',
                SENT: 'sent',
                ENGAGED: 'engaged',
                INVALID: 'invalid',
                EXPIRED: 'expired',
                CONSENT_EXPIRED: 'consent-expired'
            },
            MESSAGE: {
                COMPLETE: 'Patient electronically signed consent on __signedDate__.',
                INVITATION_EXPIRED: "Electronic invite sent to __method__ on __sentDate__ has expired.\n" +
                    "Input the patient's preferred contact method and send a new invitation.",
                INCOMPLETE: "Electronic invite sent to __method__ on __sentdate__. Invite expires after 24 hrs. Click \"Check for Updates\" above to check the status of the invitation or input the patient's preferred contact method and send a new invitation.",
                CONSENT_EXPIRED: "Patient consent expired on __expiry-date__.\n Input the patient's preferred contact method and send a new invitation."
            },
            ERROR_MESSAGE: {
                HEADER: 'Sorry, something went wrong.',
                MESSAGE: 'There was an error with your eHIPAA request. Please try to resend. If the error persists please come back and try again later.'
            },
            E_HIPAA_DISPLAY_STATUS: {
                SENT: 'CONSENT INCOMPLETE',
                ACCEPTED : 'CONSENT INCOMPLETE',
                INITIATED : 'CONSENT INCOMPLETE',
                ENGAGED: 'CONSENT INCOMPLETE',
                EXPIRED: 'INVITATION EXPIRED',
                CONSENT_EXPIRED: 'CONSENT EXPIRED'
            }
        },
        CONSENT_ERROR: {
            MESSAGE: 'Patient Consent is not on file for this patient. Click the Edit link to activate a Patient Consent for this patient.'
        },
        TOAST_TYPES: {
            PATIENT_ACTIVATED: 'patientactivated'
        },
        ACTIVATE: 'Activate',
        DEACTIVATE: 'Deactivate',
        DEACTIVATE_PATIENT_MODAL_MESSAGE: 'You are about to change this patient to inactive. Inactive patients do not appear in your Practice Patient List, or display in patient search results. Are you sure you wish to continue?',
        ACTIVATE_PATIENT_MODAL_MESSAGE: 'You are about to change this patient to active. Active patients appear in your Practice Patient List and display in patient search results. Are you sure you wish to continue?',
        CONSENT_MAX_TEXT: 1200,
        DATA_FILTER_KEYS: {
            PATIENT_PRACTICE_ID: 'patientPracticeId',
            EXTERNAL_PATIENT_ID: 'externalPatientId'
        },

        STATUS: {
            INACTIVE_TEXT: 'view inactive patients',
            ACTIVE_TEXT: 'view active patients'
        },
        NO_PATIENT_WARNING_MESSAGE: 'Your Practice does not have any __viewable_patient_status__ Patients at this time',
        NO_SEARCH_PATIENT_WARNING_MESSAGE: 'Your search for " __category__ " of " __query__ " did not return any items.',
        SEARCH_RESULT_MESSAGE: '__length__ results for __category__ of __query__',
        PATIENT_SORT: {
            DATE_OF_BIRTH: 'dob',
            PATIENT_NAME: 'patientName',
            PATIENT_LAST_NAME: 'lastName'
        },
        PATIENT_FOR_ELIGIBILITY: {
            LIMIT: 1
        },
        PATIENT_PROFILE: {
            COMPLETED_BV: {
                LIMIT: 10,
                INITIAL_OFFSET: 0
            }
        },
        ZIP_MASK: {
            START_INDEX: 0,
            MIN_LIMIT: 5
        },
        GENDER: {
            FEMALE: 'female',
            MALE: 'male'
        },
        TITLE_TEXT: {
            DEACTIVATE: {
                PATIENT: 'Deactivate Patient',
                PATIENTS: 'Deactivate Patients'
            },
            REACTIVATE: {
                PATIENT: 'Reactivate Patient',
                PATIENTS: 'Reactivate Patients'
            }
        },
        CONFIRMATION_MESSAGE: {
            DEACTIVATE_ONE_PATIENT: "You are about to change this patient to inactive. Inactive patients do not appear in your Practice Patient List, or display in patient search results. Are you sure you wish to continue?",
            DEACTIVATE_MULTIPLE_PATIENT: "You are about to change #patients patients to inactive. Inactive patients do not appear in your Practice Patient List, or display in patient search results. Are you sure you wish to continue?",
            REACTIVATE_ONE_PATIENT: "You are about to change this patient to active. Active patients appear in your Practice Patient List, and display in patient search results. Are you sure you wish to continue?",
            REACTIVATE_MULTIPLE_PATIENT: "You are about to change #patients patients to active. Active patients appear in your Practice Patient List, and display in patient search results. Are you sure you wish to continue?"
        },
        TOAST_NOTIFICATION: {
            FOR: {
                UPDATE: 'update',
                STATUS: 'status',
                DANGER: 'danger',
                INSURANCE: 'insurance',
                INACTIVE_PATIENT: 'inactive'
            }
        },
        DANGER_TOAST_MESSAGE: {
            ERROR_MESSAGE: 'We are unable to retrieve information because this patient has been deactivated. To view this patient\'s profile, please go to your Inactive Patient List and reactivate the patient. If you need assistance, please contact Customer Support.',
            PATIENT_INACTIVE_HEADER: 'Patient is Inactive',
            PATIENT_INACTIVE_MESSAGE: 'This patient has been deactivated. If you wish to verify benefits and/or edit patient information, please go to your Inactive Patient List and reactivate the patient. If you need assistance, please contact Customer Support.',
        },
        STATE_NAME: 'patient',
        PATIENT_INACTIVE_TOAST_MESSAGE: {
            HEADER: 'Patient is Inactive',
            MESSAGE: 'This patient has been deactivated. If you wish to verify benefits and/or edit patient information, please click the Activate Patient link. If you need assistance, please contact Customer Support.'
        },
        REACTIVATE_DEACTIVATE_MODAL_TITLE: 'Patient'
    };

    angular.module('eAccess.ProviderPortal.Constants.Patients', []).constant('PATIENT_CONSTS', VALUES);
})();
