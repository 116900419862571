/**
 * Created by nikesh on 9/1/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Services.PolicyPlanDetails')
        .factory('PolicyPlanDetailsModalService', PolicyPlanDetailsModalService);

    PolicyPlanDetailsModalService.$inject = ['$log', '$uibModal'];

    function PolicyPlanDetailsModalService($log, $uibModal) {

        var service = {
            show: show
        };

        function show(responseData) {
            var modal = $uibModal.open({
                size: 'lg',
                animation: true,
                templateUrl: 'partials/templates/policy-plan-details.html',
                controller: 'PolicyPlanDetailsModalController as policyPlanDetailsCtrl',
                appendTo: angular.element('body'),
                resolve: {
                    responseData: function() {
                        return responseData;
                    }
                }
            });
            modal.result.then(angular.noop, angular.noop);
            return modal;
        }

        return service;

    }

})();
