(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Footer')
        .controller('FooterController', FooterController);

    FooterController.$inject = ['$scope', '$log', 'PORTAL_VERSION', 'ENV', 'ConfigurationService', 'HeaderService', 'BRAND_IMAGE_CONSTS', '$window', 'GLOBAL_CONSTS', 'Utility'];

    function FooterController($scope, $log, PORTAL_VERSION, ENV, ConfigurationService, HeaderService, BRAND_IMAGE_CONSTS, $window, GLOBAL_CONSTS, Utility) {
        var vm = this;

        vm.isTermsOfUse = isTermsOfUse;
        vm.openPDF = openPDF;

        vm.$onInit = function() {

            vm.footerLogo = ENV.API_URL + BRAND_IMAGE_CONSTS.API + BRAND_IMAGE_CONSTS.KEYS.PUBLIC_FOOTER_LOGO;
            vm.interiorFooterLogo = ENV.API_URL + BRAND_IMAGE_CONSTS.API + BRAND_IMAGE_CONSTS.KEYS.INTERIOR_FOOTER_LOGO;

            vm.configurationService = ConfigurationService;
            vm.portalVersion = PORTAL_VERSION;
            vm.showPortalVersion = ENV.SHOW_VERSION;
            vm.headerService = HeaderService;

            $log.debug('version:', vm.showPortalVersion);

            $scope.$on('$stateChangeSuccess', function () {
                vm.isVisible = true;
            });

        };

        function isTermsOfUse(label) {
            return label === GLOBAL_CONSTS.FOOTER.TERMS_OF_USE_LABEL;
        }

        function openPDF(link) {
            if(!link.href) {
                var fetchPDFFor = '';
                switch(link.label) {
                    case GLOBAL_CONSTS.FOOTER.TERMS_OF_USE_LABEL:
                        fetchPDFFor = GLOBAL_CONSTS.FOOTER.TERMS_OF_USE_ENDPOINT;
                        break;
                    case GLOBAL_CONSTS.FOOTER.PRIVACY_POLICY_LABEL:
                        fetchPDFFor = GLOBAL_CONSTS.FOOTER.PRIVACY_POLICY_LABEL_ENDPOINT;
                        break;
                    default:
                        return false;
                }
                var url = Utility.getFileURLForKey(fetchPDFFor);
                Utility.openPdf(url, true);
                $log.log('PDF opened');
            } else {
                Utility.openPdf(link.href, true);
                $log.log('URL opened');
            }
        }

    }
})();
