(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Modals.PlanDetailsModal', [])
        .controller('PlanDetailsModalController', PlanDetailsModalController);

    PlanDetailsModalController.$inject = ['$log', '$uibModalInstance', 'plan'];

    function PlanDetailsModalController($log, $uibModalInstance, plan) {
        var vm = this;

        vm.plan = plan;
        vm.closeModal = closeModal;

        function closeModal() {
            $uibModalInstance.close();
        }
    }
})();
