(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.BVHistory', [
            'eAccess.ProviderPortal.Components.CaseBV',
            'eAccess.ProviderPortal.Components.PolicyListSummary',
            'eAccess.ProviderPortal.Services.PaginationService'
        ]);

})();
