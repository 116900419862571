/**
 * Created by mcaputo on 1/17/17.
 */

(function () {
    'use strict';

    var eaInsurancePolicyList = {
        bindings: {
            insurancePolicies: '<',
            parentForm: '=',
            // Flag that specifies if this component is being used from patient-profile page
            // We need this to change field display if its patient-profile page
            isForPatientProfile: '<',
            newPatient: '<',
            isBv: '=',
            isAio: '=',
            ehipaaFormProcessing: '=',
        },
        templateUrl: 'components/insurance-policy/insurance-policy-list.html',
        controller: 'InsurancePolicyListController as insurancePolicyList'
    };

    angular
        .module('eAccess.ProviderPortal.Components.InsurancePolicy')
        .component('eaInsurancePolicyList', eaInsurancePolicyList);
})();
