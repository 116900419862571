/**
 * Created by sstokes on 1/10/17.
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .factory('Product', Product);

    Product.$inject = ['$log', '$http', '$q', '$timeout', 'ProductModel', 'API', 'Utility', 'CacheDataService', 'ELIGIBILITY_CONSTS'];

    function Product($log, $http, $q, $timeout, ProductModel, API, Utility, CacheDataService, ELIGIBILITY_CONSTS) {
        var service = {
            get: get,
            getCurrentProduct: getCurrentProduct,
            setCurrentProduct: setCurrentProduct,
            getProductById: getProductById,
            clearProductList: clearProductList
        };

        var _currentProduct = null;
        var _productList = null;

        function get(bvMethod) {

            if(_productList) {
                return $q.when(_productList);
            }

            var deferred = $q.defer();
            var products = [];
            API.get('products/user?bvmethod=' + bvMethod).then(function(productResponseJSON) {
                productResponseJSON.bvAdjudicationList.forEach(function(bvProducts) {
                    bvProducts.productAdjudicationList.forEach(function(product) {
                        var productModel = new ProductModel(product);
                        products.push(productModel);
                        //caching disclaimer content data if bv method is rx
                        if(!Utility.isEmpty(productModel.productMethod) && (Utility.lowerCase(productModel.productMethod) === Utility.lowerCase(ELIGIBILITY_CONSTS.BV_TYPE.RX))) CacheDataService.rxDisclaimerContent = product.disclaimer;
                    });
                });
                _productList = angular.copy(products);
                deferred.resolve(products);
            }).catch(function(err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function getCurrentProduct() {
            return _currentProduct;
        }

        function setCurrentProduct(selectedProduct) {
            _currentProduct = selectedProduct;
        }

        function getProductById(productId) {
            return API.get('products/combined/' + productId);
        }

        function clearProductList(){
            _productList = null;
        }

        return service;
    }
})();
