/**
 * Created by nikesh on 3/11/2018.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PatientInfo')
        .component('eaPatientInfo', {
            templateUrl: 'components/patient-info/patient-info.html',
            controller: 'PatientInfoController as patientInfo',
            bindings: {
                patientInfo: '=',
                parentForm: '=',
                searchEligibilityCallback: '<',
                bvMethod: '<',
                showExistingPatientLink: '<',
                rxApiType: '<',
                isEhipaa: '=',
                disableInputForUpdate: '<'
            }
        });
})();
