/**
 * Created by nikesh on 9/8/2017.
 */

(function() {
    'use strict';

    var VALUES = {
        REACTIVATE: 'Reactivate',
        DEACTIVATE: 'Deactivate',
        CANNOT_DEACTIVATE_ALL_MODAL: {
            TITLE: 'Cannot Deactivate All Locations',
            BODY: 'At least one location must remain active. Please choose at least one location that will remain active and deselect that checkbox in order to continue.'
        },
        TOAST: {
            DEACTIVATE: {
                SINGLE: {
                    SUCCESS: {
                        HEADER: 'Location Successfully Deactivated',
                        MESSAGE: 'The location was deactivated and removed from your practice\'s location list. You may view and/or reactivate the location by selecting the View Inactive Locations link on the location list page.'
                    }
                },
                MULTIPLE: {
                    SUCCESS: {
                        HEADER: 'Locations Successfully Deactivated',
                        MESSAGE: 'The selected locations were deactivated and removed from your practice\'s locations list. You may view and/or reactivate locations by selecting the View Inactive Locations link on the location list page.'
                    }
                }
            },
            REACTIVATE: {
                SINGLE: {
                    SUCCESS: {
                        HEADER: 'Location Successfully Reactivated',
                        MESSAGE: 'The location was reactivated and returned to your practice\'s locations list. You may view and/or deactivate the location by selecting the View Active Locations link on the location list page.'
                    }
                },
                MULTIPLE: {
                    SUCCESS: {
                        HEADER: 'Locations Successfully Reactivated',
                        MESSAGE: 'The selected locations were reactivated and returned to your practice\'s locations list. You may view and/or deactivate locations by selecting the View Active Locations link on the locations list page.'
                    }
                }
            },
            ADDED: {
                HEADER: 'Location Successfully Added',
                MESSAGE: 'The location has been added. You may view the location by clicking the Location Name in your locations list.'
            }
        },
        CONFIRMATION_MESSAGE: {
            DEACTIVATE_ONE_LOCATION: "You are about to change this location to inactive. Inactive locations don't appear in your practice's Active Locations list, or display in active location search results. Are you sure you wish to continue?",
            DEACTIVATE_MULTIPLE_LOCATION: "You are about to change #locations locations to inactive. Inactive locations don't appear in your practice's Active Locations list, or display in active location search results. Are you sure you wish to continue?",
            REACTIVATE_ONE_LOCATION: "You are about to change this location to active. Active locations appear in your practice's Active Locations list, and display in location search results. Are you sure you wish to continue?",
            REACTIVATE_MULTIPLE_LOCATION: "You are about to change #locations locations to active. Active locations appear in your practice's Active Locations list, and display in location search results. Are you sure you wish to continue?"
        },
        LOCATION_SORT: {
            LOCATION_NAME: 'locationName'
        },
        NO_LOCATION_MESSAGE: 'Your Practice does not have any @ {STATUS} Locations at this time.',
        VIEW_ACTIVE_TEXT: 'view active locations',
        VIEW_INACTIVE_TEXT: 'view inactive locations',
        REACTIVATE_DEACTIVATE_MODAL_TITLE: 'Location'
    };

    angular.module('eAccess.ProviderPortal').constant('LOCATION_CONSTS', VALUES);
})();
