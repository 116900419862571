/**
 * Created by pramesh on 10/9/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.InitialUserInformation', [
            'eAccess.ProviderPortal.Constants.User'
        ]);

})();
