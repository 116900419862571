/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.Components.Practice.controller:PracticeLocationController
 * @author Sterling Stokes
 * @requires $log
 * @requires PracticeView
 * @requires PracticeLocationService
 * @description
 *
 * This is the controller for the Practice Locations page.
 **/

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .controller('PracticeLocationController', PracticeLocationController);

    PracticeLocationController.$inject = ['$log', '$stateParams', 'PracticeView', 'PracticeLocationService', 'PatientStyles', 'CannotDeactivateAllService', '$uibModal', 'SEARCH_CONSTS', 'STATUS_CONSTS', 'LOCATION_CONSTS', 'Storage', 'Utility', 'ConfigurationService', 'TABLE_NAME_CONSTS', 'PaginationService', 'Search', 'SearchPaginationModel', 'SearchModel', 'GLOBAL_CONSTS', 'ToastNotificationService', 'CancelableQ', 'ERROR_MESSAGES', 'DATA_LIMIT_CONSTS', 'PracticeLocationReactivateDeactivate'];

    function PracticeLocationController($log, $stateParams, PracticeView, PracticeLocationService, PatientStyles, CannotDeactivateAllService, $uibModal, SEARCH_CONSTS, STATUS_CONSTS, LOCATION_CONSTS, Storage, Utility, ConfigurationService, TABLE_NAME_CONSTS, PaginationService, Search, SearchPaginationModel, SearchModel, GLOBAL_CONSTS, ToastNotificationService, CancelableQ, ERROR_MESSAGES, DATA_LIMIT_CONSTS, PracticeLocationReactivateDeactivate) {
        var vm = this;

        vm.locationUpdated = $stateParams.locationUpdated;

        vm.errorMessages = {
            loadPracticeLocations: {
                display: false,
                text: 'There was an error loading the practice locations. Please try again.'
            }
        };

        vm.searchFacets = [
            {
                searchCategory: SEARCH_CONSTS.SEARCH_FACETS.LOCATION.SEARCH_CATEGORY.LOCATION_NAME,
                placeholderText: SEARCH_CONSTS.SEARCH_FACETS.LOCATION.PLACE_HOLDER_TEXT.LOCATION_NAME,
                value: SEARCH_CONSTS.SEARCH_FACETS.LOCATION.VALUE.LOCATION_NAME
            },
            {
                searchCategory: SEARCH_CONSTS.SEARCH_FACETS.LOCATION.SEARCH_CATEGORY.PRACTICE_LOCATION_ID,
                placeholderText: SEARCH_CONSTS.SEARCH_FACETS.LOCATION.PLACE_HOLDER_TEXT.PRACTICE_LOCATION_ID,
                value: SEARCH_CONSTS.SEARCH_FACETS.LOCATION.VALUE.PRACTICE_LOCATION_ID
            }
        ];

        vm.locationsMap = {
            active: [],
            inactive: []
        };
        vm.searchQuery = '';
        vm.currentSearchQuery = '';
        vm.locationCount = DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE;
        vm.fetchPracticeLocationCount = DATA_LIMIT_CONSTS.DEFAULT_COUNT;
        vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
        vm.sortBy = LOCATION_CONSTS.LOCATION_SORT.LOCATION_NAME;
        vm.count = DATA_LIMIT_CONSTS.DEFAULT_COUNT_VALUE;
        vm.loadingLocations = true;
        vm.selectedFacet = vm.searchFacets[0].searchCategory;
        vm.facetPlaceholder = vm.searchFacets[0].placeholderText;
        vm.viewableLocationStatus = STATUS_CONSTS.STATUS.ACTIVE;
        // Holds list of items that has been checked (from the location list table)
        vm.listOfCheckedLocationItems = [];
        vm.searchType = SEARCH_CONSTS.SEARCH_TYPE.LOCATION;
        vm.isNameAscending = true;
        vm.selectedAll = false;
        vm.isRoleWithUser = false;
        vm.noDataFound = false;
        vm.locationReactivateDeactivate = null;
        vm.nextLink = null;
        vm.invalidUrl = $stateParams.invalidUrl;

        //Preserving promise object to cancel, if new data are requested.
        vm.cancelableService = CancelableQ;
        vm.isPromiseCalled = false;

        // functions
        vm.locationSearchCallback = locationSearchCallback;
        vm.loadPracticeLocationsByStatus = loadPracticeLocationsByStatus;
        vm.setBenefitStatusClass = PatientStyles.setBenefitStatusClass;
        vm.getLocationStatusToggleText = getLocationStatusToggleText;
        vm.toggleViewableStatus = toggleViewableStatus;
        vm.clearAllCallback = clearAllCallback;
        vm.openDeactivateReactivateModal = openDeactivateReactivateModal;
        vm.formatLocationDataToUpdate = formatLocationDataToUpdate;
        vm.getLocationActionText = getLocationActionText;
        vm.setDefaultForRefetch = setDefaultForRefetch;
        vm.sortLocationData = sortLocationData;
        vm.getSuccessMessage = getSuccessMessage;
        vm.getErrorMessage = getErrorMessage;

        vm.$onInit = function() {
            if(vm.invalidUrl) {
                _setInvalidURLNotification();
            }
            if(!Utility.isEmpty($stateParams.locationSubmitted) && $stateParams.locationSubmitted) {
                _setMessageObject(LOCATION_CONSTS.TOAST.ADDED.HEADER, LOCATION_CONSTS.TOAST.ADDED.MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
            }
            PracticeView.configure({
                title: 'My Practice |',
                contentTitle: 'Locations',
                tabIndex: 1,
                buttonText: 'Add New Location',
                buttonHref: 'practice.locations.add'
            });

            vm.locationReactivateDeactivate = new PracticeLocationReactivateDeactivate();

            vm.loadingLocations = true;
            ConfigurationService.fetchDefaultTablePageSize(TABLE_NAME_CONSTS.LOCATION_TABLE).then(function(pageLimit) {
                vm.limit = !Utility.isEmpty(pageLimit) ? pageLimit : DATA_LIMIT_CONSTS.DEFAULT_LIMIT;
            }).catch(function(err) {
                // this is just in case if 'defaultLimit' not returned from the API
                vm.limit = DATA_LIMIT_CONSTS.DEFAULT_LIMIT;
                $log.error('Unable to fetch portal data', err);
            }).finally(function() {
                vm.loadingLocations = false;
                loadPracticeLocationsByStatus();
                _fetchUserRole();
            });
        };

        function _setInvalidURLNotification() {
            var toastInfo = Utility.getBadRequestToastInfo();
            _setMessageObject(toastInfo.title, toastInfo.message, toastInfo.type);
        }

        function _fetchUserRole() {
            var userRoleString = Storage.getSessionItem('user_role');
            if(!Utility.isEmpty(userRoleString) && userRoleString.indexOf('user') !== -1) {
                vm.isRoleWithUser = true;
            }
        }

        function loadPracticeLocationsByStatus() {
            var resultListEqualsTotalCount = vm.locationCount === vm.locationsMap[vm.viewableLocationStatus].length;
            if(!Utility.shouldCallAPI(vm.loadingLocations, resultListEqualsTotalCount, vm.noDataFound)) return;
            //Searches for more locations if the search query is provided
            if(!Utility.isEmpty(vm.currentSearchQuery) && vm.currentSearchQuery !== ''){
                _locationSearchLoadMoreLocations();
                return;
            }

            //Canceling previous call if in progress
            if(vm.isPromiseCalled) {
                vm.cancelableService.cancel();
                vm.listLocation.canceler.resolve();
            }

            vm.loadingLocations = true;
            vm.isPromiseCalled = true;

            vm.listLocation = PracticeLocationService.loadPracticeLocationsByStatus(_isViewableStatusActive(), vm.limit, vm.offset, vm.sortBy, vm.count, vm.nextLink);
            vm.cancelableService.promiseObj = vm.cancelableService.wrap(vm.listLocation.promise);

            //this is to execute finally block if multiple promise does not exits and last promise has not been canceled.
            var isPromiseCanceled = false;

            vm.cancelableService.promiseObj.then(function(locations) {
                var paginationResponse = PaginationService.getPaginationResponse(locations, vm.locationsMap[vm.viewableLocationStatus], 'locationId', vm.offset, vm.limit);
                vm.paginationResponse = paginationResponse;
                vm.locationCount = paginationResponse.totalCount;
                vm.nextLink = paginationResponse.nextLink;
                vm.locationsMap[vm.viewableLocationStatus] = paginationResponse.list;
            }).catch(function(err) {
                //Recording flag if exception occurred due to force cancellation of promise to avoid executing finally block.
                isPromiseCanceled = err === ERROR_MESSAGES.FORCE_CANCEL;

                $log.error('Error loading the practice location information:', err);
            }).finally(function() {
                //if promise is force canceled, finally block should not run.
                if(!isPromiseCanceled) {
                    vm.loadingLocations = false;
                    _setLocationWarningMessage();
                    vm.isPromiseCalled = false;
                }
            });
        }

        function locationSearchCallback(searchCallbackModel) {
            vm.locationsMap[vm.viewableLocationStatus] = [];
            vm.selectedAll = false;
            vm.searchResultText = null;
            vm.locationCount = null;
            vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;

            if(Utility.isEmpty(searchCallbackModel.search)) {
                vm.noDataFound = true;
                vm.loadingLocationsWarningMessage = SEARCH_CONSTS.EMPTY_SEARCH_WARNING_MESSAGE;
                return;
            }

            vm.loadingLocations = true;
            vm.currentSearchQuery = angular.copy(searchCallbackModel.query);
            vm.selectedFacet = searchCallbackModel.selectedFacet;

            searchCallbackModel.search.then(function(response) {
                var paginationResponse = PaginationService.getPaginationResponse(response, vm.locationsMap[vm.viewableLocationStatus], 'locationId', vm.offset, vm.limit);
                vm.locationCount = paginationResponse.totalCount;
                vm.nextLink = paginationResponse.nextLink;
                vm.locationsMap[vm.viewableLocationStatus] = paginationResponse.list;
            }).catch(function(err) {
                vm.locationCount = DATA_LIMIT_CONSTS.DEFAULT_COUNT_VALUE;
                $log.error('Error searching the practice location information:', err);
            }).finally(function() {
                _setSearchResultText();
                vm.loadingLocations = false;
                _setLocationWarningMessage();
            });
        }

        function _locationSearchLoadMoreLocations() {
            vm.loadingLocations = true;
            vm.searchResultText = null;

            Search.locationSearch(_getSearchParam()).then(function(response) {
                var paginationResponse = PaginationService.getPaginationResponse(response, vm.locationsMap[vm.viewableLocationStatus], 'locationId', vm.offset, vm.limit);
                vm.locationCount = paginationResponse.totalCount;
                vm.nextLink = paginationResponse.nextLink;
                vm.locationsMap[vm.viewableLocationStatus] = paginationResponse.list;
                _setSearchResultText();
            }).catch(function(err) {
                $log.error('Error searching the practice location information:', err);
            }).finally(function() {
                vm.loadingLocations = false;
                _setLocationWarningMessage();
            });
        }

        function _setSearchResultText() {
            vm.searchResultText = Search.getInstructionTextForSearch(vm.locationCount, vm.selectedFacet.searchCategory, vm.currentSearchQuery);
        }

        function _getSearchParam() {
            var pagination = new SearchPaginationModel(true, vm.offset, vm.limit, vm.sortBy, vm.nextLink);
            return new SearchModel(pagination, vm.currentSearchQuery, vm.selectedFacet, vm.viewableLocationStatus);
        }

        function _setLocationWarningMessage() {
            if(vm.locationsMap[vm.viewableLocationStatus].length === 0) {
                vm.noDataFound = true;
                vm.loadingLocationsWarningMessage = (!Utility.isEmpty(vm.currentSearchQuery) && vm.currentSearchQuery !== '') ?
                    Search.getSearchResultText(vm.locationCount, vm.selectedFacet.searchCategory, vm.currentSearchQuery) :
                    Utility.replaceText(LOCATION_CONSTS.NO_LOCATION_MESSAGE,'@ {STATUS}', vm.viewableLocationStatus);
            } else {
                vm.noDataFound = false;
            }
        }

        function getLocationStatusToggleText() {
            return Utility.lowerCase(vm.viewableLocationStatus) === STATUS_CONSTS.STATUS.ACTIVE ? LOCATION_CONSTS.VIEW_INACTIVE_TEXT : LOCATION_CONSTS.VIEW_ACTIVE_TEXT;
        }

        function toggleViewableStatus() {
            vm.viewableLocationStatus = Utility.lowerCase(vm.viewableLocationStatus) === STATUS_CONSTS.STATUS.ACTIVE ? STATUS_CONSTS.STATUS.INACTIVE : STATUS_CONSTS.STATUS.ACTIVE;
            vm.listOfCheckedLocationItems = [];
            vm.selectedAll = false;
            vm.searchResultText = null;
            vm.searchQuery = null;
            vm.currentSearchQuery = null;
            vm.nextLink = null;
            ToastNotificationService.clearToastNotification();
            vm.setDefaultForRefetch();
            vm.loadPracticeLocationsByStatus();
        }

        function _isViewableStatusActive() {
            return vm.viewableLocationStatus === STATUS_CONSTS.STATUS.ACTIVE;
        }


        /**
         * Function to open a deactivate-reactivate-item modal
         */
        function openDeactivateReactivateModal() {
            _prepareNewSelectedList();
            ToastNotificationService.clearToastNotification();
            // If every row checkbox is selected and current viewable status is 'active'
            // Then show cannot deactivate all modal

            if(_isViewableStatusActive()) {
                var totalCount;
                _getTotalLocationCount().then(function(count) {
                    totalCount = count;
                }).catch(function(error) {
                    totalCount = -1;
                    $log.info("Unable to fetch location total count", error);
                }).finally(function() {
                    if(totalCount === -1 || (vm.listOfCheckedLocationItems.length >= totalCount)) {
                        vm.locationReactivateDeactivate.openCannotReactivateDeactivateModal();
                        return;
                    }
                    _prepareAndOpenReactivateDeactivateModal();
                });
            } else {
                _prepareAndOpenReactivateDeactivateModal();
            }
        }


        function _prepareNewSelectedList() {
            var newSelectedList = [];
            vm.loggedInUserSelected = false;
            angular.forEach( vm.listOfCheckedLocationItems, function (location) {
                var locationToCompare = Utility.isObject(location) ? location.locationId : location;
                newSelectedList.push(Utility.getItemFromArray(vm.locationsMap[vm.viewableLocationStatus], 'locationId', locationToCompare));
            });
            vm.listOfCheckedLocationItems = newSelectedList;
        }

        function _prepareAndOpenReactivateDeactivateModal() {
            vm.locationReactivateDeactivate.resetDynamicSettings();
            vm.locationReactivateDeactivate.setCurrentState(vm.listOfCheckedLocationItems, vm.viewableLocationStatus);
            vm.locationReactivateDeactivate.setModalTitle(LOCATION_CONSTS.REACTIVATE_DEACTIVATE_MODAL_TITLE);
            vm.locationReactivateDeactivate.setModalSubTitle(vm.locationsMap);
            vm.locationReactivateDeactivate.openReactivateDeactivateModal().then(
                function(response) {
                    if(response) {
                        var requestData = formatLocationDataToUpdate();
                        PracticeLocationService.updateLocationStatus(requestData)
                            .then(
                                function() {
                                    vm.getSuccessMessage();
                                    vm.listOfCheckedLocationItems = [];
                                    vm.nextLink = null;
                                    vm.setDefaultForRefetch();
                                    vm.loadPracticeLocationsByStatus();
                                    vm.selectedAll = false;
                                })
                            .catch(
                                function() {
                                    vm.getErrorMessage();
                                });
                    }
                }
            );


        }


        function _getTotalLocationCount() {
            var searchLocation = PracticeLocationService.getLocationsInformation(_isViewableStatusActive(), DATA_LIMIT_CONSTS.DEFAULT_COUNT_VALUE, DATA_LIMIT_CONSTS.DEFAULT_OFFSET, '', DATA_LIMIT_CONSTS.DEFAULT_COUNT);
            return Search.getTotalCount(searchLocation);
        }

        /**
         * Function that prepares data for location status update.
         */
        function formatLocationDataToUpdate() {
            var resultList = [];

            var activeStatus = !(_isViewableStatusActive());

            vm.listOfCheckedLocationItems.forEach(function(location) {
                resultList.push({locationId: location.locationId, active: activeStatus});
            });

            return {locations: resultList};
        }

        function clearAllCallback() {
            vm.searchQuery = '';
            vm.currentSearchQuery = '';
            vm.searchResultText = null;
            vm.selectedAll = false;
            vm.nextLink = null;
            setDefaultForRefetch();
            loadPracticeLocationsByStatus();
        }

        function getLocationActionText() {
            return _isViewableStatusActive() ? STATUS_CONSTS.ACTION_TEXT.DEACTIVATE_SELECTED : STATUS_CONSTS.ACTION_TEXT.REACTIVATE_SELECTED;
        }

        function setDefaultForRefetch() {
            vm.locationsMap = {
                active: [],
                inactive: []
            };
            vm.locationCount = DATA_LIMIT_CONSTS.DEFAULT_TOTAL_COUNT_VALUE;
            vm.offset = DATA_LIMIT_CONSTS.DEFAULT_OFFSET;
            vm.sortBy = LOCATION_CONSTS.LOCATION_SORT.LOCATION_NAME;
            vm.count = DATA_LIMIT_CONSTS.DEFAULT_COUNT;
            vm.noDataFound = false;
            vm.loadingLocations = false;
            vm.isNameAscending = true;
        }

        function sortLocationData() {
            vm.selectedAll = false;
            vm.nextLink = null;
            var isAscending = !vm.isNameAscending;
            vm.setDefaultForRefetch();
            vm.isNameAscending = isAscending;
            vm.sortBy = vm.isNameAscending ? LOCATION_CONSTS.LOCATION_SORT.LOCATION_NAME : '-' + LOCATION_CONSTS.LOCATION_SORT.LOCATION_NAME;
            vm.loadPracticeLocationsByStatus();
        }

        function getSuccessMessage() {
            var toastType = GLOBAL_CONSTS.TOAST_TYPES.SUCCESS;
            if(_isViewableStatusActive()) {
                (vm.listOfCheckedLocationItems.length > 1) ?
                    _setMessageObject(LOCATION_CONSTS.TOAST.DEACTIVATE.MULTIPLE.SUCCESS.HEADER, LOCATION_CONSTS.TOAST.DEACTIVATE.MULTIPLE.SUCCESS.MESSAGE, toastType) :
                    _setMessageObject(LOCATION_CONSTS.TOAST.DEACTIVATE.SINGLE.SUCCESS.HEADER, LOCATION_CONSTS.TOAST.DEACTIVATE.SINGLE.SUCCESS.MESSAGE, toastType);
            } else {
                (vm.listOfCheckedLocationItems.length > 1) ?
                    _setMessageObject(LOCATION_CONSTS.TOAST.REACTIVATE.MULTIPLE.SUCCESS.HEADER, LOCATION_CONSTS.TOAST.REACTIVATE.MULTIPLE.SUCCESS.MESSAGE, toastType) :
                    _setMessageObject(LOCATION_CONSTS.TOAST.REACTIVATE.SINGLE.SUCCESS.HEADER, LOCATION_CONSTS.TOAST.REACTIVATE.SINGLE.SUCCESS.MESSAGE, toastType);
            }
        }

        function getErrorMessage() {
            _setMessageObject(GLOBAL_CONSTS.TOAST_TITLE.SYSTEM_ERROR, GLOBAL_CONSTS.TOAST_MESSAGE.SYSTEM_MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
        }

        function _setMessageObject(title, message, toastType) {
            ToastNotificationService.setToastNotification(ToastNotificationService.buildToastNotification(true, title, message, toastType));
        }

    }
})();
