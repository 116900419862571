/**
 * Created by aayush.regmi on 10/12/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.TermsAndConditions')
        .component('eaTermsAndConditions', {
            templateUrl: 'components/terms-and-conditions/terms-and-conditions.html',
            controller: 'TermsAndConditionsController as terms',
            bindings: {
                parentForm: '=',
                termsAndConditions: '=',
                registrationConfig: '=',
                nextCallback: '='
            }
        });

})();
