/**
* Created by alee on 2/23/17.
*/
(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.DocsStyleguide')
        .config(router);

    router.$inject = ['$stateProvider', 'GLOBAL_CONSTS'];

    function router($stateProvider, GLOBAL_CONSTS) {
        $stateProvider
            .state('docs.styleguide', {
                url: '/styleguide',
                parent: 'docs',
                data: {
                    authorities: [],
                    pageTitle: GLOBAL_CONSTS.PORTAL_PAGE_TITLE + ' - Styleguide'
                },
                views: {
                    'content@': {
                        templateUrl: 'components/docs/styleguide/docs-styleguide.html',
                        controller: 'DocsStyleguideController as styleguide'
                    }
                }
            });
    }
})();
