/* eslint-disable */
/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.controller:MedEligibilityController
 * @author Mike Caputo, Sterling Stokes
 * @requires $log
 * @requires Eligibility
 * @requires Product
 * @requires DiagnosisCode
 * @requires AdminCode
 * @description
 *
 * This is the controller for the Check Medicare Advantage Eligibility page.
 **/

(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.MedEligibility')
        .controller('MedEligibilityController', MedEligibilityController);

    MedEligibilityController.$inject = ['$log', '$q', '$rootScope', '$state', '$anchorScroll', '$location', 'moment', 'Eligibility', 'Product', 'DiagnosisCode', 'AdminCode', 'AdminCodeModel', 'ERROR_MESSAGES', 'ELIGIBILITY_CONSTS', 'InsurancePolicy', 'ProviderService', 'HELP_TEXT_CONSTS', 'Utility', 'ProviderModel', 'CacheDataService', '$scope', 'PatientService', 'ConsentService', 'STATUS_CODE', 'GLOBAL_CONSTS', 'ToastNotificationService', 'PHARMACY_CONSTS', 'STATUS_CONSTS', 'PlaceOfService', 'PATIENT_CONSTS', 'PracticeSetupAddService'];

    function MedEligibilityController($log, $q, $rootScope, $state, $anchorScroll, $location, moment, Eligibility, Product, DiagnosisCode, AdminCode, AdminCodeModel, ERROR_MESSAGES, ELIGIBILITY_CONSTS, InsurancePolicy, ProviderService, HELP_TEXT_CONSTS, Utility, ProviderModel, CacheDataService, $scope, PatientService, ConsentService, STATUS_CODE, GLOBAL_CONSTS, ToastNotificationService, PHARMACY_CONSTS, STATUS_CONSTS, PlaceOfService, PATIENT_CONSTS, PracticeSetupAddService) {
        var vm = this;
        var insurancePolicies = [new InsurancePolicy()];
        insurancePolicies[0].rank = 'primary';

        /*  This is added as part of EP-3039
            As per comment in Jira :
            We should hide the Patient Is Subscriber
            field in the UI. At a later point,
            we could remove the field also but
            this is a safer step to keep defaulting
            it to "Yes" for now.
        */
        insurancePolicies[0].subscriberPatient = true;

        // functions
        vm.checkCoverage = checkCoverage;

        vm.AdministationChange = AdministationChange;

        vm.prepareCheckData = prepareCheckData; // adding to the scope for testing reasons
        vm.reset = reset;
        vm.patientSearchEligibilityCallback = patientSearchEligibilityCallback;
        vm.addProvider = addProvider;
        vm.resetErrorMessage = resetErrorMessage;
        vm.isStatusCoveredOrPriorAuthorizationRequired = isStatusCoveredOrPriorAuthorizationRequired;
        vm.validateZipAndCheckCoverage = validateZipAndCheckCoverage;

        // data
        vm.coverageResponse = null;
        vm.gettingCoverage = false;
        vm.insurancePolicies = [new InsurancePolicy()];
        vm.showProviderDropdown = true;
        vm.providerExists = false;
        vm.ranMedBv = false;
        vm.ranRxBv = false;
        vm.displayCheckForUpdate = false;
        vm.checkConsentUpdate = checkConsentUpdate;
        vm.disableInput = false;
        vm.hasAlreadyRunBV = false;

        vm.datePicker = {
            options: {
                initDate: null
            }
        };

        vm.errorMessages = {
            insuranceInformation: {
                payer: {
                    required: 'Payer is required'
                },
                memberId: {
                    required: 'Member ID is required'
                },
                subscriberPatient: {
                    required: 'Subscriber is required'
                }
            }
        };

        vm.requestData = {};
        vm.editConsent = true;

        vm.isFormDirty = false;
        vm.bvAdjudicationMethod = ELIGIBILITY_CONSTS.BV_TYPE.RX;
        vm.requestPolicies = null;
        vm.helpText = {
            consent: HELP_TEXT_CONSTS.BV_REQUEST.PATIENT_INFO.CONSENT,
            switchToRx: HELP_TEXT_CONSTS.BV_REQUEST.SWITCH_RX_BUTTON
        };
        vm.selectedPharmacyId = "";
        vm.selectedPharmacyType = "";
        vm.pharmacyNCPDPID = "";
        vm.splitMedicareIdAndSSN = splitMedicareIdAndSSN;
        vm.setObjectAndDateFormat = setObjectAndDateFormat;

        /* For displaying E-Hipaa Consent*/
        vm.disableSubmit = false;
        vm.isEhipaa = false;
        vm.allowedEhipaaStatus = PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_INVITE_STATUS.COMPLETE;
        vm.checkForUpdateText = PATIENT_CONSTS.CONSENT_TEXT.CHECK_UPDATE_TEXT;
        vm.ehipaaFormProcessing = false;
        vm.displayCheckForUpdateToast = false;
        vm.patientConsent = {
            type: '',
            emailOrMobile: '',
            eHipaaStatus: '',
        };

        $scope.$on('$stateChangeSuccess', function (ev, to, toParams, from) {
            if (from.name === ELIGIBILITY_CONSTS.STATE.RX_ELIGIBILITY_SPLIT_RESPONSE && toParams.showProviderError) {
                vm.showProviderError = toParams.showProviderError;
            }
        });

        vm.$onInit = function () {
            _initCachedData();
        };

        // When we are using reverify benefit then we are mapping the policy object from coverageResponse.request.policyList object
        // where we are getting medicareMemberId and supplementalLetter inside medicareDetails object from coverageResponse.request.policyList
        // so we need to map the medicare data to policy while displaying it in the insurance information section
        function _mapMedicareDetailToPolicyObject() {

            vm.insurancePolicies = vm.insurancePolicies.map(function (policy) {
                /* This is added as part of EP-3039
                   As per comment in Jira:
                   We should hide the Patient Is Subscriber
                   field in the UI. At a later point,
                   we could remove the field also but
                   this is a safer step to keep defaulting
                   it to "Yes" for now.
               */
                policy.subscriberPatient = true;

                if (!Utility.isEmpty(policy.medicareDetails)) {
                    policy.medicareMemberId = policy.medicareDetails.memberId;
                    policy.medSuppData = {
                        planLetter: policy.medicareDetails.supplementalLetter
                    }
                }
                if (!Utility.isEmpty(policy.subscriber) && !Utility.isEmpty(policy.subscriber.dob)) policy.subscriber.dob = moment(policy.subscriber.dob, 'YYYY-MM-DD').toDate();
                return policy;
            });
        }

        function _setServiceDeliveryInformation(serviceDeliveryInformation) {
            // For initializing primary and secondary diagnosis code.
            if (!Utility.isEmpty(serviceDeliveryInformation) && !Utility.isEmpty(serviceDeliveryInformation.diagnosisCodeList)) {
                if (!Utility.isEmpty(serviceDeliveryInformation.diagnosisCodeList[0])) vm.serviceDeliveryInformation.primaryDiagnosisCode = serviceDeliveryInformation.diagnosisCodeList[0];
                if (!Utility.isEmpty(serviceDeliveryInformation.diagnosisCodeList[1])) vm.serviceDeliveryInformation.secondaryDiagnosisCode = serviceDeliveryInformation.diagnosisCodeList[1];
            }
            if (!Utility.isEmpty(serviceDeliveryInformation) && !Utility.isEmpty(serviceDeliveryInformation.adminCodeList)) {
                angular.forEach(serviceDeliveryInformation.adminCodeList, function (adminCode) {
                    if (!Utility.isEmpty(adminCode) && !Utility.isEmpty(adminCode.description) && Utility.lowerCase(adminCode.description) === Utility.lowerCase(GLOBAL_CONSTS.OTHER)) {
                        adminCode.otherCode = adminCode.code;
                    }
                });
            }
        }

        function _isAnyPatientInfoFilled() {
            return ((!Utility.isEmpty(vm.patient.address) && !Utility.isEmpty(vm.patient.address.zipCode)) ||
                !Utility.isEmpty(vm.patient.firstName) ||
                !Utility.isEmpty(vm.patient.lastName) ||
                !Utility.isEmpty(vm.patient.middleName) ||
                !Utility.isEmpty(vm.patient.dob) ||
                !Utility.isEmpty(vm.patient.gender));
        }

        /**
         * @ngdoc function
         * @name setProductForCheck
         * @methodOf eAccess.ProviderPortal.controller:MedEligibilityController
         * @description
         *
         * Sets the product name from selected product object for submitting the eligibility check request.
         **/

        function setProductForCheck() {
            // bail if there is no selected product
            if (!vm.serviceDeliveryInformation || !vm.serviceDeliveryInformation.selectedProduct) {
                return false;
            }
            vm.serviceDeliveryInformation.product = vm.serviceDeliveryInformation.selectedProduct;
        }

        function setServiceCheck() {
            return {
                adminCodeList: vm.serviceDeliveryInformation.adminCodeList,
                placeOfService: vm.serviceDeliveryInformation.placeOfService,
                product: vm.serviceDeliveryInformation.product,
                diagnosisCodeList: vm.serviceDeliveryInformation.diagnosisCodeList
            };
        }

        /**
         * @ngdoc function
         * @name prepareCheckData
         * @methodOf eAccess.ProviderPortal.controller:MedEligibilityController
         * @description
         *
         * Prepares data object for submitting the eligibility check request.
         **/

        function prepareCheckData() {
            setProductForCheck();
            prepareAdminCodesForCheck();
            prepareDiagnosisCodesForCheck();
            prepareInsurancePoliciesForCheck();
            setLocationForCheck();

            return {
                patient: vm.patient,
                service: setServiceCheck(),
                provider: vm.providerInfo.provider,
                policyList: vm.requestPolicies,
                location: vm.location
            };
        }

        /**
         * @ngdoc function
         * @name setAdminCodesForCheck
         * @description
         *
         * Generates the list of admin codes for the eligibility check.
         **/

        function prepareAdminCodesForCheck() {
            angular.forEach(vm.serviceDeliveryInformation.adminCodeList, function (adminCode) {
                if (adminCode !== null && adminCode.otherCode !== null) {
                    adminCode.code = adminCode.otherCode;
                    delete adminCode.otherCode;
                } else {
                    //When no admin code is selected then the admin code list sets the value to null
                    //This is why we are checking for the index of null value and removing from the list once a null object is obtained.
                    var index = vm.serviceDeliveryInformation.adminCodeList.indexOf(null);
                    if (index >= 0) {
                        vm.serviceDeliveryInformation.adminCodeList.splice(index, 1);
                    }
                }
            });
            delete vm.serviceDeliveryInformation.adminCode;
        }

        function prepareDiagnosisCodesForCheck() {
            var primaryDiagnosisCode = vm.serviceDeliveryInformation.primaryDiagnosisCode;
            var secondaryDiagnosisCode = vm.serviceDeliveryInformation.secondaryDiagnosisCode;

            if (angular.isUndefined(primaryDiagnosisCode)) {
                return;
            }

            if (angular.isDefined(primaryDiagnosisCode) && primaryDiagnosisCode.id === 0) {
                // over-write the code by primary other code
                primaryDiagnosisCode.code = vm.serviceDeliveryInformation.primaryOtherCode;
            }

            if (angular.isDefined(secondaryDiagnosisCode) && angular.isDefined(secondaryDiagnosisCode.code) && secondaryDiagnosisCode.id === 0) {
                // over-write the code by secondary other code
                secondaryDiagnosisCode.code = vm.serviceDeliveryInformation.secondaryOtherCode;
            }

            // set the ranks
            primaryDiagnosisCode.rank = 1;
            vm.serviceDeliveryInformation.diagnosisCodeList = [primaryDiagnosisCode];

            if (angular.isDefined(secondaryDiagnosisCode)) {
                secondaryDiagnosisCode.rank = 2;
                vm.serviceDeliveryInformation.diagnosisCodeList.push(secondaryDiagnosisCode);
            }
            delete vm.serviceDeliveryInformation.primaryDiagnosisCode;
            delete vm.serviceDeliveryInformation.secondaryDiagnosisCode;
            delete vm.serviceDeliveryInformation.primaryOtherCode;
            delete vm.serviceDeliveryInformation.secondaryOtherCode;
        }

        /**
         * @ngdoc function
         * @name prepareInsurancePoliciesForCheck
         * @description
         *
         * Removes unused items from insurance policies. This is hacky right now
         * and we should figure out a cleaner way to do it.
         */
        function prepareInsurancePoliciesForCheck() {
            vm.requestPolicies = angular.copy(vm.insurancePolicies);
            vm.requestPolicies = vm.requestPolicies.map(function (policy) {

                policy.medicareDetails = {
                    memberId: policy.medicareMemberId,
                    supplementalLetter: (!Utility.isEmpty(policy.medSuppData) && !Utility.isEmpty(policy.medSuppData.planLetter)) ? policy.medSuppData.planLetter : null
                };
                // to reduce the size of policy object while requesting BV we remove
                // the policy attribute which are already mapped to policy.medicareDetails
                Utility.deleteAttributesFromObject(policy, ['medicareMemberId', 'medSuppData']);

                // If there is a subscriber, format their DoB
                if (!policy.subscriberPatient) {
                    if (policy.subscriber && policy.subscriber.dob) {
                        policy.subscriber.dob = moment(policy.subscriber.dob).format("YYYY-MM-DD");
                    }
                } else {
                    if (!Utility.isEmpty(policy.subscriber)) {
                        delete policy.subscriber;
                    }
                }

                // replacing the clientId by organizationPayerId
                if (!Utility.isEmpty(policy)
                    && !Utility.isEmpty(policy.payer)
                    && !Utility.isEmpty(policy.payer.clientId)
                ) {
                    if (angular.isDefined(policy.payer.organizationPayerId) && policy.payer.organizationPayerId !== null) {
                        policy.payer.clientId = parseInt(policy.payer.organizationPayerId);
                    } else {
                        policy.payer.clientId = null;
                    }
                }

                return policy;
            });
            if(!Utility.isEmpty(vm.requestPolicies[0])) {
                if(!angular.isDefined(vm.requestPolicies[0].rank)) {
                    vm.requestPolicies[0].rank = Utility.firstCharacterUpperCase(ELIGIBILITY_CONSTS.POLICY_RANK.PRIMARY);
                }
            }
        }

        /**
         * @ngdoc function
         * @name addProvider
         * @methodOf eAccess.ProviderPortal.controller:MedEligibilityController
         * @description
         *
         * Adds a new provider if it does not exist.
         *
         * @param provider
         **/

        function addProvider(provider) {
            return vm.providerInfo.showProviderDropdown ? $q.when() : ProviderService.addProvider(provider);
        }

        function AdministationChange() {
            $rootScope.$broadcast('AdministationChange', { isAio: vm.isAio });
        }

        /**
         * @ngdoc function
         * @name checkCoverage
         * @methodOf eAccess.ProviderPortal.controller:MedEligibilityController
         * @description
         *
         * Submits the Eligibility check.
         **/

        function validateZipAndCheckCoverage() {
            PatientService.validateZipCode(vm.patient.address.zipCode).then(function (validity) {
                vm.checkCoverageForm.zipCode.$setValidity('zipcode', validity);
            }).catch(function (err) {
                $log.info('could not validate zipCode');
            }).finally(function () {
                checkCoverage();
            });
        }

        function checkCoverage() {
            vm.pharmacyNCPDPID = vm.selectedPharmacyType === PHARMACY_CONSTS.PHARMACY_SELECTION_OPTIONS.PREFERRED ? vm.selectedPharmacyId : null;
            vm.splitMedicareIdAndSSN();
            // reset error message
            ToastNotificationService.clearToastNotification();
            // reset validation
            vm.providerExists = false;
            $location.hash('');
            $location.replace();

            //to verify admin code list for duplicate value
            _verifyAdminCodeList();

            // bail if the form is invalid
            if (vm.checkCoverageForm.$invalid) {
                return false;
            }

            vm.gettingCoverage = true;
            vm.ranMedBv = false;
            vm.ranRxBv = false;
            vm.ranVaccineBv = false;
            vm.redirectToMed = false;

            // Temporary patient dob object before formatting
            // Added as part of EP-1279
            vm.tempPatientDob = vm.patient.dob;

            var requestData = prepareCheckData();
            vm.coverageResponse = null;
            addProvider(vm.providerInfo.provider)
                .then(function (provider) {
                    requestData.provider = getProviderInformation(provider);
                    // Format patient dob
                    if (vm.patient.dob) {
                        vm.patientDoB = vm.patient.dob;
                        requestData.patient.dob = moment(vm.patient.dob).format("YYYY-MM-DD");
                    }

                    vm.requestData = requestData;
                    vm.requestData.pharmacyNCPDPID = vm.pharmacyNCPDPID;
                    var apiResult;

                    vm.patient.age = moment().diff(vm.patient.dob, 'years');
                    if (vm.patient.age < 65) {
                        if (vm.isAio === true || vm.isAio === 'true') {
                            apiResult = Eligibility.checkMedAdvCoverage(requestData);
                            vm.ranMedBv = true;
                        } else if (vm.isAio === "false") {
                            apiResult = Eligibility.checkRxSplitCoverage(requestData);
                            vm.ranRxBv = true;
                        }
                    }
                    else {
                        if (vm.patient.ssn != null || vm.patient.medicareId != null) {
                            apiResult = Eligibility.checkVaccineCoverage(requestData);
                            vm.ranVaccineBv = true;
                        }
                        else {
                            if (vm.insurancePolicies != null && vm.insurancePolicies[0].payer != null) {
                                apiResult = Eligibility.checkMedAdvCoverage(requestData);
                                vm.ranMedBv = true;
                            }
                        }
                    }
                    if(apiResult){
                        apiResult.then(_handleSuccess)
                            .catch(_handleFailure)
                            .finally(_cleanUp);
                    } else {
                        _setToastNotificationMessage(true, ERROR_MESSAGES.BV_ERROR.TITLE, ERROR_MESSAGES.BV_ERROR.MESSAGE_SUPPORT_DETAILS, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
                        $anchorScroll();
                    }
                })
                .catch(function (error) {
                    // Provider exists
                    $log.error('error', error.data.message);
                    if (PracticeSetupAddService.isNpiError(error)) {
                        vm.checkCoverageForm.npi.$setValidity('npiType1', false);
                    } else {
                        vm.providerExists = true;
                        vm.providerExistsMessage = error.data.message;
                    }

                    vm.gettingCoverage = false;
                    $location.hash('provider-information-heading');
                    $anchorScroll();
                })
                .finally(function () {
                    if (vm.serviceDeliveryInformation.adminCodeList.length === 0) {
                        vm.serviceDeliveryInformation.adminCodeList.push(null);
                    }
                    resetDiagnosisCodes();
                    resetAdminCodes();
                    // reset patient dob to object
                    if (vm.patient.dob) {
                        // Added as part of EP-1279
                        vm.patient.dob = angular.copy(vm.tempPatientDob);
                    }
                });
        }

        function _setToastNotificationMessage(isDisplay, title, message, messageType) {
            var notification = ToastNotificationService.buildToastNotification(
                isDisplay,
                title,
                message,
                messageType || ELIGIBILITY_CONSTS.VERIFY_BV.ERROR.TOAST.TYPE
            );

            ToastNotificationService.setToastNotification(notification);
        }


        function _verifyAdminCodeList() {
            var adminCodeList = vm.serviceDeliveryInformation.adminCodeList;
            if (adminCodeList && adminCodeList.length > 1) {
                var values = [];
                angular.forEach(adminCodeList, function (adminCodeObject, key) {
                    var value = null, adminCode = null;
                    if (adminCodeObject.otherCode) {
                        value = adminCodeObject.otherCode;
                        if (values.indexOf(value) !== -1) {
                            adminCode = ELIGIBILITY_CONSTS.ADMIN_OTHER_CODE + key;
                            vm.checkCoverageForm[adminCode].$setValidity('duplicate', false);
                            $location.hash('admin-code-row');
                            $anchorScroll();
                        }
                    } else {
                        value = adminCodeObject.id;
                        if (values.indexOf(value) !== -1) {
                            adminCode = ELIGIBILITY_CONSTS.ADMIN_CODE + key;
                            vm.checkCoverageForm[adminCode].$setValidity('duplicate', false);
                            $location.hash('admin-code-row');
                            $anchorScroll();
                        }
                    }
                    values.push(value);
                });
            }
        }


        function resetDiagnosisCodes() {
            // Reset the diagnosis code if there is an error
            // if user had selected for "Other" in Diagnosis Code then
            // we need to render the extra input field, where user had inserted the otherCode
            if (vm.serviceDeliveryInformation.diagnosisCodeList[0].id === 0) {
                vm.serviceDeliveryInformation.primaryOtherCode = vm.serviceDeliveryInformation.diagnosisCodeList[0].code;
                vm.serviceDeliveryInformation.diagnosisCodeList[0].code = GLOBAL_CONSTS.OTHER;
                vm.serviceDeliveryInformation.primaryDiagnosisCode = vm.serviceDeliveryInformation.diagnosisCodeList[0];
            } else {
                vm.serviceDeliveryInformation.primaryDiagnosisCode = vm.serviceDeliveryInformation.diagnosisCodeList[0];
            }
            if (vm.serviceDeliveryInformation.diagnosisCodeList[1] !== undefined && vm.serviceDeliveryInformation.diagnosisCodeList[1].id === 0) {
                vm.serviceDeliveryInformation.secondaryOtherCode = vm.serviceDeliveryInformation.diagnosisCodeList[1].code;
                vm.serviceDeliveryInformation.diagnosisCodeList[1].code = GLOBAL_CONSTS.OTHER;
                vm.serviceDeliveryInformation.secondaryDiagnosisCode = vm.serviceDeliveryInformation.diagnosisCodeList[1];
            } else {
                vm.serviceDeliveryInformation.secondaryDiagnosisCode = vm.serviceDeliveryInformation.diagnosisCodeList[1];
            }
        }

        function resetAdminCodes() {
            angular.forEach(vm.serviceDeliveryInformation.adminCodeList, function (adminCode) {
                if (adminCode !== null && adminCode.id === 0) {
                    adminCode.otherCode = adminCode.code;
                    adminCode.code = 'Other';
                }
            });
        }

        function reset() {
            vm.coverageResponse = null;
            vm.gettingCoverage = false;
            var newPolicy = new InsurancePolicy();
            newPolicy.rank = 'Primary';
            vm.insurancePolicies = [newPolicy];
            vm.serviceDeliveryInformation = {
                adminCodeList: [new AdminCodeModel()],
                selectedProduct: null,
                selectedLocation: null
            };
            vm.patient = {
                address: {}
            };
            vm.consentMessage = {
                onfile: false,
                expired: false
            };
            vm.providerInfo = {
                showProviderDropdown: true,
                provider: null
            };
            vm.disableInput = false;
            vm.patientConsent.type = PATIENT_CONSTS.CONSENT_TEXT.TYPE.HCP;
            vm.isEhipaa = false;
            vm.disableSubmit = false;
            vm.displayCheckForUpdate = false;
            vm.checkCoverageForm = {
                $submitted: false
            };

            // set product value to gsk 210mg for gsk specific implementation
            vm.selectedProduct = vm.products.filter(function (x) {
                return x.id === 1;
            })[0];
            vm.serviceDeliveryInformation.selectedProduct = vm.selectedProduct;

            // set pos value to office for gsk specific implementation
            vm.serviceDeliveryInformation.placeOfService = vm.placesOfService.filter(function (x) {
                return x.id === 11;
            })[0];
        }

        //populating data to form if patient is clicked on modal search
        function patientSearchEligibilityCallback(data) {
            Eligibility.prepareMedEligibilityFormData(data.request.patient.patientId, data.usedbvMethod).then(function (formData) {
                CacheDataService.setMedBvCacheData(formData);
                _initCachedData();
                vm.checkCoverageForm.$dirty = true;
                vm.patientProvider = vm.providerInfo.provider;
            });
        }

        function _fetchPatientConsent(patientId) {
            //This is to retrieve patient consent details
            ConsentService.getConsentData(patientId).then(function (data) {
                vm.consentMessage = data;
                vm.consentMessage.onFile = vm.consentMessage.onfile && !vm.consentMessage.expired;
                vm.isEhipaa = vm.consentMessage.onfile ? false : true;
                vm.editConsent = vm.consentMessage.onfile === false || vm.consentMessage.expired;

                if(!vm.consentMessage.onfile) {
                    vm.patientConsent.type = PATIENT_CONSTS.CONSENT_TEXT.TYPE.EHIPAA;
                    vm.isEhipaa = true;
                    vm.patientConsent.eHipaaStatus = vm.consentMessage.status ? vm.consentMessage.status : '';
                    vm.disableSubmit = Utility.lowerCase(vm.patientConsent.eHipaaStatus) === Utility.lowerCase(PATIENT_CONSTS.CONSENT_TEXT.E_HIPAA_INVITE_STATUS.COMPLETE) ? false : true;
                    vm.patient.patientConsent = {
                        type: PATIENT_CONSTS.CONSENT_TEXT.TYPE_API.EHIPAA
                    };
                } else {
                    vm.patientConsent.type = PATIENT_CONSTS.CONSENT_TEXT.TYPE.HCP;
                    vm.isEhipaa = false;
                    vm.disableSubmit = false;
                    vm.patient.patientConsent = {
                        type: PATIENT_CONSTS.CONSENT_TEXT.TYPE_API.HCP
                    };
                }
            }).catch(function (err) {
                // shows error toast when check for update has error
                _setToastNotificationMessage(vm.displayCheckForUpdateToast, PATIENT_CONSTS.CONSENT_TEXT.ERROR_MESSAGE.HEADER, PATIENT_CONSTS.CONSENT_TEXT.ERROR_MESSAGE.MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
                $log.error("Could not retrieve patient consent: ", err);
            }).finally(function () {
                vm.disableInput = vm.hasAlreadyRunBV
                    || PatientService.isConsentCaptured(vm.consentMessage);
            });
        }

        /**
         * @ngdoc function
         * @name setLocationForCheck
         * @methodOf eAccess.ProviderPortal.controller:MedEligibilityController
         * @description
         *
         * Sets the location name from selected location object for submitting the eligibility check request.
         **/

        function setLocationForCheck() {
            // bail if there is no selected product
            if (!vm.serviceDeliveryInformation || !vm.serviceDeliveryInformation.selectedLocation) {
                return false;
            }

            vm.location = vm.serviceDeliveryInformation.selectedLocation;
        }

        function getProviderInformation(provider) {
            if (provider) {
                vm.providerInfo.provider = new ProviderModel(provider);
            }
            return vm.providerInfo.provider;
        }

        function resetErrorMessage() {
            _setToastNotificationMessage(false, null, null, null);
        }

        function _initCachedData() {
            // check if patient has already run bv and patients consent is caputred or not
            // disable input update if bv is run or consent is captured
            if(!Utility.isEmpty(CacheDataService.patient.patientId)) {
                PatientService.hasPatientRunBV(CacheDataService.patient.patientId).then(function (status) {
                    vm.hasAlreadyRunBV = status;
                    ConsentService.getConsentData(CacheDataService.patient.patientId).then(function (data) {
                        vm.consentMessage = data;
                    }).catch(function (err) {
                        vm.consentMessage = {
                            onFile: false
                        };
                        $log.error("Could not retrieve patient consent: ", err);
                    }).finally(function () {
                        vm.disableInput = vm.hasAlreadyRunBV
                            || PatientService.isConsentCaptured(vm.consentMessage);
                        _assignCacheValue();
                    });
                });
            } else {
                _assignCacheValue();
            }
        }

        function _assignCacheValue() {
            //Initializing cached bv data if available
            vm.patient = CacheDataService.patient;
            vm.patient.gender = PatientService.setPatientGender(vm.patient);
            if (!Utility.isEmpty(vm.patient.dob)) vm.patient.dob = moment(vm.patient.dob, GLOBAL_CONSTS.DATE_FORMAT.YYYY_MM_DD.DASH).toDate();
            vm.providerInfo = CacheDataService.providerInfo;
            vm.consentMessage = CacheDataService.consentMessage;
            vm.serviceDeliveryInformation = CacheDataService.medServiceDeliveryInformation;
            _setServiceDeliveryInformation(vm.serviceDeliveryInformation);
            if (!Utility.isEmpty(CacheDataService.medPolicies)) {
                vm.insurancePolicies = CacheDataService.medPolicies;
                _mapMedicareDetailToPolicyObject();
            }

            //making sure that form should be dirty after cached data are initialize
            vm.isFormDirty = _isAnyPatientInfoFilled() ||
                !Utility.isEmpty(vm.providerInfo.provider) ||
                !Utility.isEmpty(vm.consentMessage.onFile);

            if (Utility.isEmpty(vm.providerInfo.provider)) {
                vm.providerInfo.showProviderDropdown = true;
            }

            if (!Utility.isEmpty(vm.patient.patientId)) {
                _fetchPatientConsent(vm.patient.patientId);
            }
            PlaceOfService.getByProduct("1")
                .then(function (placesOfService) {
                    vm.placesOfService = placesOfService;
                    // assign value only if the values are not pre-assigned
                    if(!angular.isDefined(vm.serviceDeliveryInformation.placeOfService)) {
                        if(vm.placesOfService.length === 1)  {
                            vm.serviceDeliveryInformation.placeOfService = vm.placesOfService[0];
                        } else {
                            if (vm.isAio === true) {
                                vm.serviceDeliveryInformation.placeOfService = vm.placesOfService.filter(function (x) {
                                    return x.id === 11;
                                })[0];
                            }
                            else {
                                vm.serviceDeliveryInformation.placeOfService = vm.placesOfService.filter(function (x) {
                                    return x.id === 22;
                                })[0];
                            }
                        }
                    }
                });
            DiagnosisCode.getByProduct("1")
                .then(function (diagnosisCodes) {
                    vm.diagnosisCodes = diagnosisCodes;
                    vm.serviceDeliveryInformation.primaryDiagnosisCode = vm.diagnosisCodes[0];
                });
            Product.get(vm.bvAdjudicationMethod)
                .then(function (products) {
                    vm.products = products;
                    // vm.selectedProduct = vm.products.filter(function (x) {
                    //     return x.id === 1;
                    // })[0];
                    // vm.serviceDeliveryInformation.selectedProduct = vm.selectedProduct;
                });
        }

        function _handleSuccess(response) {
            vm.checkCoverageForm.$setPristine();
            CacheDataService.clearBVCache();
            var requestData = setObjectAndDateFormat();
            var apiResult;

            //if vaccine is run, BV is not covered and payer information is not provided
            if (vm.ranVaccineBv === true &&
                !vm.isStatusCoveredOrPriorAuthorizationRequired(response.policies[0].plan.status.productCoverageStatus) &&
                vm.insurancePolicies[0].payer === null) {
                var redirectToState = ELIGIBILITY_CONSTS.STATE.RX_ELIGIBILITY_SPLIT_RESPONSE;

                if (!Utility.isEmpty(response.patientConflicts) && response.patientConflicts.length > 0) {
                    redirectToState = ELIGIBILITY_CONSTS.STATE.RX_DATA_CONFLICT;
                }

                $state.go(redirectToState, {
                    responseId: response.responseId,
                    adjudicationType: ELIGIBILITY_CONSTS.BV_TYPE.RX
                });

                _setToastNotificationMessage(true, GLOBAL_CONSTS.TOAST_TITLE.COVERAGE_NOT_FOUND, ELIGIBILITY_CONSTS.VERIFY_BV.ERROR.SELECT_PAYER_RERUN_MED, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
                $anchorScroll();
            }
            //if vaccine is run, BV is not covered and payer information is provided
            else if (vm.ranVaccineBv === true &&
                !isStatusCoveredOrPriorAuthorizationRequired(response.policies[0].plan.status.productCoverageStatus) &&
                vm.insurancePolicies[0].payer !== null) {
                requestData.service.adminCodeList = requestData.service.adminCodeList[0] !== null ? requestData.service.adminCodeList : [];
                apiResult = Eligibility.checkMedAdvCoverage(requestData);
                vm.ranMedBv = true;
                apiResult.then(_handleSuccessRedirect)
                    .catch(_handleFailure)
                    .finally(_cleanUp);
            }
            //if vaccine is run, BV is covered
            else if (vm.ranVaccineBv === true &&
                isStatusCoveredOrPriorAuthorizationRequired(response.policies[0].plan.status.productCoverageStatus)) {
                var redirectToState = ELIGIBILITY_CONSTS.STATE.RX_ELIGIBILITY_SPLIT_RESPONSE;

                if (!Utility.isEmpty(response.patientConflicts) && response.patientConflicts.length > 0) {
                    redirectToState = ELIGIBILITY_CONSTS.STATE.RX_DATA_CONFLICT;
                }

                $state.go(redirectToState, {
                    responseId: response.responseId,
                    adjudicationType: ELIGIBILITY_CONSTS.BV_TYPE.RX
                });
            }
            //if med is run and BV is not covered, run split
            else if (vm.ranMedBv === true && vm.ranRxBv === false && !isStatusCoveredOrPriorAuthorizationRequired(response.policies[0].plan.coverageStatus)) {
                apiResult = Eligibility.checkRxSplitCoverage(requestData);
                vm.ranRxBv = true;
                apiResult.then(_handleSuccessRedirect)
                    .catch(_handleFailure)
                    .finally(_cleanUp);
            }
            //if med is run and BV is covered, show med screen
            else if (vm.ranMedBv === true && vm.ranRxBv === false && isStatusCoveredOrPriorAuthorizationRequired(response.policies[0].plan.coverageStatus)) {
                $state.go('eligibilityResponse', {
                    responseId: response.responseId,
                    bvType: Utility.lowerCase(ELIGIBILITY_CONSTS.BV_TYPE.ELECTRONIC),
                    adjudicationType: response.bvAdjudicationType
                });
            }
            //if split is run and BV is not covered, show appropriate banner
            else if (vm.ranRxBv === true && vm.ranMedBv === false && !isStatusCoveredOrPriorAuthorizationRequired(_getProductCoverageStatusForSplitResponse(response.policies[0]))) {
                if(!Utility.isEmpty(vm.requestData.policyList[0].payer) && !Utility.isEmpty(vm.requestData.policyList[0].memberId)) {
                    requestData.service.adminCodeList = requestData.service.adminCodeList[0] !== null ? requestData.service.adminCodeList : [];
                    apiResult = Eligibility.checkMedAdvCoverage(requestData);
                    vm.ranMedBv = true;
                    vm.redirectToMed = true;
                    apiResult.then(_handleSuccessRedirect)
                        .catch(_handleFailure)
                        .finally(_cleanUp);
                } else {
                    var redirectToState = ELIGIBILITY_CONSTS.STATE.RX_ELIGIBILITY_SPLIT_RESPONSE;

                    if (!Utility.isEmpty(response.patientConflicts) && response.patientConflicts.length > 0) {
                        redirectToState = ELIGIBILITY_CONSTS.STATE.RX_DATA_CONFLICT;
                    }

                    $state.go(redirectToState, {
                        responseId: response.responseId,
                        adjudicationType: ELIGIBILITY_CONSTS.BV_TYPE.RX
                    });

                    // build toast notification
                    _setToastNotificationMessage(true,
                        ERROR_MESSAGES.NOT_COVERED_WITH_NULL_PAYER_ERROR.TITLE,
                        ERROR_MESSAGES.NOT_COVERED_WITH_NULL_PAYER_ERROR.MESSAGE,
                        GLOBAL_CONSTS.TOAST_TYPES.ERROR);
                    $anchorScroll();
                }

            }
            //if split is run, medBV has already ran and BV is covered
            else if (vm.ranRxBv === true && vm.ranMedBv === true && isStatusCoveredOrPriorAuthorizationRequired(_getProductCoverageStatusForSplitResponse(response.policies[0]))) {
                var redirectToState = ELIGIBILITY_CONSTS.STATE.RX_ELIGIBILITY_SPLIT_RESPONSE;

                if (!Utility.isEmpty(response.patientConflicts) && response.patientConflicts.length > 0) {
                    redirectToState = ELIGIBILITY_CONSTS.STATE.RX_DATA_CONFLICT;
                }

                $state.go(redirectToState, {
                    responseId: response.responseId,
                    adjudicationType: ELIGIBILITY_CONSTS.BV_TYPE.RX
                });

                // build toast notification
                _setToastNotificationMessage(true,
                    GLOBAL_CONSTS.COVERED.TITLE,
                    GLOBAL_CONSTS.COVERED.MESSAGE,
                    GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
                $anchorScroll();
            }
            //if split is run and BV is covered, show rx split screen
            else if (vm.ranRxBv === true && vm.ranMedBv === false && isStatusCoveredOrPriorAuthorizationRequired(_getProductCoverageStatusForSplitResponse(response.policies[0]))) {
                var redirectToState = ELIGIBILITY_CONSTS.STATE.RX_ELIGIBILITY_SPLIT_RESPONSE;

                if (!Utility.isEmpty(response.patientConflicts) && response.patientConflicts.length > 0) {
                    redirectToState = ELIGIBILITY_CONSTS.STATE.RX_DATA_CONFLICT;
                }
                $state.go(redirectToState, {
                    responseId: response.responseId,
                    adjudicationType: ELIGIBILITY_CONSTS.BV_TYPE.RX
                });
            }
        }

        function _getProductCoverageStatusForSplitResponse(policy) {
            if (!Utility.isEmpty(policy) && !Utility.isEmpty(policy.coverageSummary)) {
                var retail30CoverageStatus = _getCoverageSummaryCoverageStatus(policy.coverageSummary.retail30);
                var retail90CoverageStatus = _getCoverageSummaryCoverageStatus(policy.coverageSummary.retail90);
                var mail90CoverageStatus = _getCoverageSummaryCoverageStatus(policy.coverageSummary.mail90);

                if (_isCoverageStatusCovered(retail30CoverageStatus) ||
                    _isCoverageStatusCovered(retail90CoverageStatus) ||
                    _isCoverageStatusCovered(mail90CoverageStatus)) {
                    return Utility.lowerCase(STATUS_CONSTS.COVERAGE.COVERED);
                }
                return retail30CoverageStatus || retail90CoverageStatus || mail90CoverageStatus || STATUS_CONSTS.COVERAGE.UNKNOWN;
            }
            return null;
        }

        function _getCoverageSummaryCoverageStatus(summaryObject) {
            if (!Utility.isEmpty(summaryObject)) {
                if (!Utility.isEmpty(summaryObject.coverageStatus)) {
                    return summaryObject.coverageStatus;
                }
            }
            return null;
        }

        function _isCoverageStatusCovered(status) {
            return Utility.lowerCase(status) === STATUS_CONSTS.COVERAGE.COVERED
                || Utility.lowerCase(status) === STATUS_CONSTS.COVERAGE.COVERED_WITH_RESTRICTIONS;
        }

        function _handleSuccessRedirect(response) {
            vm.checkCoverageForm.$setPristine();
            CacheDataService.clearBVCache();
            var requestData = setObjectAndDateFormat();
            var apiResult;

            //if medical is run after vaccine, BV is found
            if (vm.ranMedBv === true && vm.ranRxBv === false && vm.ranVaccineBv === true && vm.isStatusCoveredOrPriorAuthorizationRequired(response.policies[0].plan.coverageStatus)) {
                $state.go('eligibilityResponse', {
                    responseId: response.responseId,
                    bvType: Utility.lowerCase(ELIGIBILITY_CONSTS.BV_TYPE.ELECTRONIC),
                    adjudicationType: response.bvAdjudicationType
                });

                // build toast notification
                _setToastNotificationMessage(true,
                    ERROR_MESSAGES.COVERED_ERROR.TITLE,
                    ERROR_MESSAGES.COVERED_ERROR.MESSAGE,
                    GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
                $anchorScroll();
            }

            //if medical is run, BV is found
            else if (vm.ranMedBv === true && vm.ranRxBv === false && isStatusCoveredOrPriorAuthorizationRequired(response.policies[0].plan.coverageStatus)) {
                $state.go('eligibilityResponse', {
                    responseId: response.responseId,
                    bvType: Utility.lowerCase(ELIGIBILITY_CONSTS.BV_TYPE.ELECTRONIC),
                    adjudicationType: response.bvAdjudicationType
                });
            }
            //if medical is run and BV is not found, run split
            else if (vm.ranMedBv === true && vm.ranRxBv === false && !isStatusCoveredOrPriorAuthorizationRequired(response.policies[0].plan.coverageStatus)) {
                apiResult = Eligibility.checkRxSplitCoverage(requestData);
                vm.ranRxBv = true;
                apiResult.then(_handleSuccessRedirectForRxe)
                    .catch(_handleFailure)
                    .finally(_cleanUp);
            }
            //if med and rxe both have ran
            else if (vm.ranMedBv === true && vm.ranRxBv === true) {
                if(vm.redirectToMed) {
                    var redirectToState = ELIGIBILITY_CONSTS.STATE.MED_ELIGIBILITY_RESPONSE;
                    var toastTitle = '';
                    var toastMessage = '';
                    var toastType = '';
                    if(isStatusCoveredOrPriorAuthorizationRequired(response.policies[0].plan.coverageStatus)) {
                        toastTitle = GLOBAL_CONSTS.COVERED.TITLE;
                        toastMessage = GLOBAL_CONSTS.COVERED.MESSAGE;
                        toastType = GLOBAL_CONSTS.TOAST_TYPES.SUCCESS;
                    } else {
                        toastTitle = ERROR_MESSAGES.NOT_COVERED_ERROR.TITLE;
                        toastMessage = ERROR_MESSAGES.NOT_COVERED_ERROR.MESSAGE;
                        toastType = GLOBAL_CONSTS.TOAST_TYPES.ERROR;
                    }
                    $state.go(redirectToState, {
                        responseId: response.responseId,
                        bvType: Utility.lowerCase(ELIGIBILITY_CONSTS.BV_TYPE.ELECTRONIC),
                        adjudicationType: response.bvAdjudicationType
                    });

                    // build toast notification
                    _setToastNotificationMessage(true,
                        toastTitle,
                        toastMessage,
                        toastType);
                    $anchorScroll();
                } else if (isStatusCoveredOrPriorAuthorizationRequired(_getProductCoverageStatusForSplitResponse(response.policies[0]))) {
                    var redirectToState = ELIGIBILITY_CONSTS.STATE.RX_ELIGIBILITY_SPLIT_RESPONSE;

                    if (!Utility.isEmpty(response.patientConflicts) && response.patientConflicts.length > 0) {
                        redirectToState = ELIGIBILITY_CONSTS.STATE.RX_DATA_CONFLICT;
                    }

                    $state.go(redirectToState, {
                        responseId: response.responseId,
                        adjudicationType: ELIGIBILITY_CONSTS.BV_TYPE.RX
                    });

                    // build toast notification
                    _setToastNotificationMessage(true,
                        GLOBAL_CONSTS.COVERED.TITLE,
                        GLOBAL_CONSTS.COVERED.MESSAGE,
                        GLOBAL_CONSTS.TOAST_TYPES.SUCCESS);
                    $anchorScroll();

                } else if (!isStatusCoveredOrPriorAuthorizationRequired(_getProductCoverageStatusForSplitResponse(response.policies[0]))) {
                    var redirectToState = ELIGIBILITY_CONSTS.STATE.RX_ELIGIBILITY_SPLIT_RESPONSE;
                    response.ranMedBv = true;
                    response.ranRxBv = true;

                    if (!Utility.isEmpty(response.patientConflicts) && response.patientConflicts.length > 0) {
                        redirectToState = ELIGIBILITY_CONSTS.STATE.RX_DATA_CONFLICT;
                    }

                    $state.go(redirectToState, {
                        responseId: response.responseId,
                        adjudicationType: ELIGIBILITY_CONSTS.BV_TYPE.RX
                    });

                    // build toast notification
                    _setToastNotificationMessage(true,
                        ERROR_MESSAGES.NOT_COVERED_ERROR.TITLE,
                        ERROR_MESSAGES.NOT_COVERED_ERROR.MESSAGE,
                        GLOBAL_CONSTS.TOAST_TYPES.ERROR);
                    $anchorScroll();
                }
            }
        }

        function _handleSuccessRedirectForRxe(response) {
            vm.checkCoverageForm.$setPristine();
            CacheDataService.clearBVCache();

            //if rxe is run, BV is covered
            if (vm.ranRxBv === true && vm.ranMedBv === false && isStatusCoveredOrPriorAuthorizationRequired(_getProductCoverageStatusForSplitResponse(response.policies[0]))) {

                var redirectToState = ELIGIBILITY_CONSTS.STATE.RX_ELIGIBILITY_SPLIT_RESPONSE;

                if (!Utility.isEmpty(response.patientConflicts) && response.patientConflicts.length > 0) {
                    redirectToState = ELIGIBILITY_CONSTS.STATE.RX_DATA_CONFLICT;
                }

                $state.go(redirectToState, {
                    responseId: response.responseId,
                    adjudicationType: ELIGIBILITY_CONSTS.BV_TYPE.RX
                });
            }
            //if rxe is run, med already ran and BV is covered
            if (vm.ranRxBv === true && vm.ranMedBv === true && isStatusCoveredOrPriorAuthorizationRequired(_getProductCoverageStatusForSplitResponse(response.policies[0]))) {

                var redirectToState = ELIGIBILITY_CONSTS.STATE.RX_ELIGIBILITY_SPLIT_RESPONSE;

                if (!Utility.isEmpty(response.patientConflicts) && response.patientConflicts.length > 0) {
                    redirectToState = ELIGIBILITY_CONSTS.STATE.RX_DATA_CONFLICT;
                }

                $state.go(redirectToState, {
                    responseId: response.responseId,
                    adjudicationType: ELIGIBILITY_CONSTS.BV_TYPE.RX
                });
            }
            //if rx is run, BV is not covered, already ran medBV
            else if (vm.ranRxBv === true && vm.ranMedBv === true && !isStatusCoveredOrPriorAuthorizationRequired(_getProductCoverageStatusForSplitResponse(response.policies[0]))) {
                var redirectToState = ELIGIBILITY_CONSTS.STATE.RX_ELIGIBILITY_SPLIT_RESPONSE;

                if (!Utility.isEmpty(response.patientConflicts) && response.patientConflicts.length > 0) {
                    redirectToState = ELIGIBILITY_CONSTS.STATE.RX_DATA_CONFLICT;
                }

                $state.go(redirectToState, {
                    responseId: response.responseId,
                    adjudicationType: ELIGIBILITY_CONSTS.BV_TYPE.RX,
                });

                // build toast notification
                _setToastNotificationMessage(true,
                    ERROR_MESSAGES.NOT_COVERED_ERROR.TITLE,
                    ERROR_MESSAGES.NOT_COVERED_ERROR.MESSAGE,
                    GLOBAL_CONSTS.TOAST_TYPES.ERROR);
                $anchorScroll();
            }
        }

        function _handleFailure(err) {
            $log.error('error getting coverage', err);

            if (err.status === STATUS_CODE["409_CONFLICT"]) {
                vm.checkCoverageForm.patientId.$invalid = true;
                vm.checkCoverageForm.patientId.$error = { duplicate: true };
                $location.hash('patient-id-label');
            } else if (err.status === STATUS_CODE["404_NOT_FOUND"]) {
                _setToastNotificationMessage(true, PATIENT_CONSTS.DANGER_TOAST_MESSAGE.PATIENT_INACTIVE_HEADER, PATIENT_CONSTS.DANGER_TOAST_MESSAGE.PATIENT_INACTIVE_MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
                $anchorScroll();
            } else {
                // Using generic message for error
                _setToastNotificationMessage(true, ERROR_MESSAGES.BV_ERROR.TITLE, ERROR_MESSAGES.BV_ERROR.MESSAGE, GLOBAL_CONSTS.TOAST_TYPES.ERROR);
                $anchorScroll();
            }


            /*
             * Set error states here
             */
            throw err;
        }

        function _cleanUp() {
            vm.gettingCoverage = false;

            // Added as part of EP-1279
            vm.patient.dob = angular.copy(vm.tempPatientDob);
        }


        function splitMedicareIdAndSSN() {
            if (vm.patient.ssnOrMedicareId) {
                if (vm.patient.ssnOrMedicareId.length === 4) {
                    vm.patient.ssn = vm.patient.ssnOrMedicareId;
                    vm.patient.medicareId = null;
                } else {
                    vm.patient.medicareId = vm.patient.ssnOrMedicareId;
                    vm.patient.ssn = null;
                }
            } else {
                vm.patient.medicareId = null;
                vm.patient.ssn = null;
            }
        }

        function setObjectAndDateFormat() {
            var requestData = vm.requestData;
            requestData.patient.dob = moment(requestData.patient.dob).format('YYYY-MM-DD');
            return requestData;
        }

        function checkConsentUpdate(patientId) {
            vm.displayCheckForUpdateToast = true;
            _fetchPatientConsent(patientId);
        }

        function isStatusCoveredOrPriorAuthorizationRequired (status) {
            status = Utility.lowerCase(status);
            return status === STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.COVERED
                || status === STATUS_CONSTS.PRODUCT_COVERAGE_STATUS.PRIOR_AUTHORIZATION_REQUIRED
        }
    }
})();
