/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.Components.PracticeSetup.controller:PracticeSetupController
 * @author Nikesh Maharjan
 * @requires $log
 * @requires PracticeSetupView
 * @description
 *
 * This is the controller for the overall practice setup page.
 **/

(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.PracticeSetup')
        .controller('PracticeSetupController', PracticeSetupController);

    PracticeSetupController.$inject = ['$log', 'PracticeSetupView', 'WarnOnLeave', '$state', 'PracticeSetupCompleteService', 'ContentStoreService', 'Utility', '$window'];

    function PracticeSetupController($log, PracticeSetupView, WarnOnLeave, $state, PracticeSetupCompleteService, ContentStoreService, Utility, $window) {

        var vm = this;

        // data
        vm.viewService = PracticeSetupView;

        vm.termsAndConditionsText = '';
        vm.hipaaBaaText = '';
        vm.termsAndConditionsEsignature = '';
        vm.hipaaBaaEsignature = '';
        vm.termsAndConditionsSignedDate = '';
        vm.hipaaBaaSignedDate = '';

        var docDefinition = {
            content: [],
            styles: {
                italics: {
                    italics: true
                },
                underline: {
                    decoration: 'underline'
                },
                indent: {
                    margin: [20, 0, 0, 0]
                }
            }
        };

        // functions
        vm.redirectToState = redirectToState;
        vm.printAgreement = printAgreement;
        vm.getRegistrationConfiguration = getRegistrationConfiguration;

        init();

        function init() {
            PracticeSetupView.setHeaderLink();
            vm.getRegistrationConfiguration();
        }

        /**
         * Function that warns user before redirecting to state.
         */
        function redirectToState() {
            if($state.current.name === 'practice-setup.welcome') {
                $state.go(PracticeSetupView.headerLink.state);
            } else if($state.current.name === 'practice-setup.complete') {
                vm.printAgreement();
            } else {
                //isFormDirty flag when set to true prevents warn-on-leave modal from displaying twice
                WarnOnLeave.isFormDirty = true;
                WarnOnLeave.show({form: undefined, toState: {name: PracticeSetupView.headerLink.state}});
            }
        }

        function printAgreement() {
            PracticeSetupCompleteService.fetchUserDetails()
                .then(function(response) {
                    vm.termsAndConditionsEsignature = response.eSignature;
                    vm.hipaaBaaEsignature = response.esignatureHIPAA;
                    vm.termsAndConditionsSignedDate = response.signedDate;
                    vm.hipaaBaaSignedDate = response.signedDateHIPAA;
                    getValuesReadyForPrint();
                    // eslint-disable-next-line no-undef
                    pdfMake.createPdf(docDefinition).open();
                });
        }

        function getValuesReadyForPrint() {
            var dom = $window.document.createElement('div');
            var htmlText = vm.termsAndConditionsText + '\n' + vm.termsAndConditionsEsignature + '\n' + vm.termsAndConditionsSignedDate;
            if(vm.hipaaBaaText && vm.hipaaBaaEsignature) {
                // Explicitly page breaking before adding HIPAA contents
                var pageBreak = '<pagebreak></pagebreak>';
                htmlText = htmlText + pageBreak + vm.hipaaBaaText + '\n\n' + vm.hipaaBaaEsignature + '\n' + vm.hipaaBaaSignedDate;
                dom.innerHTML = htmlText;

                docDefinition.content = Utility.fromHTMLToPdfMake(dom);
            } else {
                dom.innerHTML = htmlText;
                docDefinition.content = Utility.fromHTMLToPdfMake(dom);
            }
        }

        function getRegistrationConfiguration() {
            ContentStoreService.getContentStoreData('useragreementtermsconditions')
                .then(
                    function(content) {
                        vm.termsAndConditionsText = content;
                    }
                )
                .catch(function(err) {
                    $log.debug('Error', err);
                });

            ContentStoreService.getContentStoreData('hipaabaatermsconditions')
                .then(
                    function(content) {
                        vm.hipaaBaaText = content;
                    }
                )
                .catch(function(err) {
                    $log.debug('Error', err);
                });
        }
    }

})();
