(function() {
    'use strict';

    var eaFacetedSearchComponent = {
        bindings: {
            searchOptions: '=',
            instructionText: '=',
            callback: '=',
            clearAllCallback: '<',
            showClearAll: '=',
            searchType: '<',
            searchQuery: '=?',
            viewableStatus: '<',
            limit: '<',
            filterQuery: '<'
        },
        templateUrl: 'components/search/faceted-search.html',
        controller: 'FacetedSearchController as facetedSearch'
    };

    angular
        .module('eAccess.ProviderPortal.Components.Search')
        .component('eaFacetedSearchComponent', eaFacetedSearchComponent);
})();
