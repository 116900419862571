/**
 * Created by nikesh on 11/3/2017.
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.AddProvider')
        .controller('AddProviderController', AddProviderController);

    AddProviderController.$inject = ['$log', 'Storage', 'HELP_TEXT_CONSTS', 'WarnOnLeave', 'ProviderService', '$uibModal', '$anchorScroll'];

    function AddProviderController($log, Storage, HELP_TEXT_CONSTS, WarnOnLeave, ProviderService, $uibModal, $anchorScroll) {
        var vm = this;

        // data
        // Help text for help icon tooltips
        vm.helpText = HELP_TEXT_CONSTS.PROVIDER;


        //functions
        vm.showWarnOnLeaveOnCancel = showWarnOnLeaveOnCancel;
        vm.saveProvider = saveProvider;
        vm.goBack = goBack;


        vm.$onInit = function() {
            vm.provider = {};
            $anchorScroll();
        };

        function showWarnOnLeaveOnCancel() {
            WarnOnLeave.show({form: vm.parentForm, toState: {name: 'login'}});
        }

        function saveProvider() {
            if(vm.parentForm.$invalid) {
                return false;
            }
            vm.nextCallback(ProviderService.addProvider(vm.provider));
        }

        function goBack() {
            var warnModal = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'warn-on-leave-title',
                arialDescribedBy: 'warn-on-leave-content',
                templateUrl: 'partials/templates/warn-on-leave-modal.html',
                controller: 'WarnOnBackModalController',
                controllerAs: 'warnOnLeave',
                appendTo: angular.element('body')
            });
            warnModal.result.then(function() {
                Storage.clearSessionData('provider');
                vm.provider = {};

                vm.previousCallback();
            });
        }
    }

})();
