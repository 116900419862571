(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Widgets.MbvList')
        .controller('MbvListWidgetController', MbvListWidgetController);

    MbvListWidgetController.$inject = ['$log', 'CaseBVService', 'Utility', 'DATA_LIMIT_CONSTS', 'BVSelectionService', 'ELIGIBILITY_CONSTS'];

    function MbvListWidgetController($log, CaseBVService, Utility, DATA_LIMIT_CONSTS, BVSelectionService, ELIGIBILITY_CONSTS) {
        var vm = this;

        // data
        vm.manualsBvInProgressForMed = ELIGIBILITY_CONSTS.CONS_N_A;
        vm.manualsBvInProgressForRX = ELIGIBILITY_CONSTS.CONS_N_A;
        vm.na = ELIGIBILITY_CONSTS.CONS_N_A;
        vm.getCountForManualBV = true;
        vm.limit = DATA_LIMIT_CONSTS.ZERO_LIMIT;
        vm.loading = true;

        // functions
        vm.isCombined = Utility.isCombined;
        vm.isRx = Utility.isRx;
        vm.isMedical = Utility.isMedical;

        vm.$onInit = function() {
            BVSelectionService.getBvMethod().then(function(bvMethod) {
                vm.bvAdjudication = bvMethod;
                getNumManuals();
            }).catch(function(error) {
                $log.error('error getting bv method', error);
            });
        };

        function getNumManuals() {
            var bvAdjudication = Utility.firstCharacterUpperCase(vm.bvAdjudication);
            CaseBVService.fetchManualBVInProgressCount(bvAdjudication).then(function(manualBVList) {
                if(!Utility.isEmpty(manualBVList)) {
                    vm.manualsBvInProgressForMed = !Utility.isEmpty(manualBVList.medManualBv) ? manualBVList.medManualBv : ELIGIBILITY_CONSTS.CONS_N_A;
                    vm.manualsBvInProgressForRX = !Utility.isEmpty(manualBVList.rxManualBv) ? manualBVList.rxManualBv : ELIGIBILITY_CONSTS.CONS_N_A;
                }
            }).catch(function(err) {
                $log.error("Error getting Manual Bv count for a week", err);
            }).finally(function(){
                vm.loading = false;
            });
        }

    }
})();
