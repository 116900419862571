(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .factory('PasswordComplexityFactory', PasswordComplexityFactory);

    PasswordComplexityFactory.$inject = ['$log', 'API'];

    function PasswordComplexityFactory($log, API) {
        var service = {
            getPasswordComplexityData: getPasswordComplexityData
        };

        function getPasswordComplexityData() {
            return API.get('users/pwComplexityInfo');
        }

        return service;
    }

})();
