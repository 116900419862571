(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilitySplitResponse')
        .controller('DetailsRestrictionsController', DetailsRestrictionsController);

    DetailsRestrictionsController.$inject = ['Utility'];

    function DetailsRestrictionsController(Utility) {

        var vm = this;

        vm.hasRestrictionMessage = hasRestrictionMessage;

        vm.$onInit = function () {
            if (!Utility.isEmpty(vm.policy)) {
                if (!Utility.isEmpty(vm.policy.plan) && !Utility.isEmpty(vm.policy.plan.planCoverageLimitations) && !Utility.isEmpty(vm.policy.plan.planCoverageLimitations.resourceLinkMessages)) {
                    vm.resourceLinkMessages = vm.policy.plan.planCoverageLimitations.resourceLinkMessages;
                } else {
                    vm.resourceLinkMessages = null;
                }

                if (!Utility.isEmpty(vm.policy.planCoverageLimitationMessage) && !Utility.isEmpty(vm.policy.planCoverageLimitationMessage[0])) {
                    vm.planCoverageLimitationMessage = vm.policy.planCoverageLimitationMessage;
                } else {
                    vm.planCoverageLimitationMessage = null;
                }
            } else {
                vm.resourceLinkMessages = null;
                vm.planCoverageLimitationMessage = null;
            }
        };

        function hasRestrictionMessage() {
            return !Utility.isEmpty(vm.resourceLinkMessages) || !Utility.isEmpty(vm.planCoverageLimitationMessage);
        }
    }
})();
