/**
 * Created by pratik budhathoki on 2/28/2018.
 */
(function() {
    'use strict';
    angular
        .module('eAccess.ProviderPortal.Components.RxEligibility', [
            'eAccess.ProviderPortal.Services.CacheData'
        ]);
})();
