/**
 * Created by aayush regmi on 7/29/19.
 */
(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Filters')
        .filter('dec', decFilter);

    decFilter.$inject = ['ELIGIBILITY_CONSTS', 'STATUS_CONSTS'];

    function decFilter(ELIGIBILITY_CONSTS, STATUS_CONSTS) {
        return function (dec) {
            dec = dec.replace(/\,/g,'');
            if (isNaN(parseFloat(dec)) || dec === ELIGIBILITY_CONSTS.CONS_N_A || dec === STATUS_CONSTS.COVERAGE.NOT_FOUND) {
                return dec;
            }

            return (parseFloat(dec).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        };
    }
})();
