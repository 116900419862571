/**
 * Created by aayush.regmi on 10/30/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.SetPassword')
        .factory('SetPasswordService', SetPasswordService);

    SetPasswordService.$inject = ['$log', 'API', '$q'];

    function SetPasswordService($log, API, $q) {
        var service = {
            savePassword: savePassword
        };

        function savePassword(data) {
            var deferred = $q.defer();
            API.post('users/setup/password', data)
                .then(function(response) {
                    return deferred.resolve(response);
                })
                .catch(function(err) {
                    return deferred.reject(err.data);
                });
            return deferred.promise;
        }

        return service;
    }
})();
