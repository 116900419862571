(function () {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Modals.PharmacyModal', [])
        .controller('PharmacySearchModalController', PharmacySearchModalController);

    PharmacySearchModalController.$inject = ['$uibModalInstance', 'searchPharmacies', 'Storage', 'PHARMACY_CONSTS'];

    function PharmacySearchModalController($uibModalInstance, searchPharmacies, Storage, PHARMACY_CONSTS) {
        var vm = this;

        // data
        vm.pharmacy = {};
        vm.hasAnySearchProperty = false;
        vm.isSearching = false;

        // functions
        vm.closeModal = closeModal;
        vm.searchPharmacy = searchPharmacy;

        vm.$onInit = function () {
            var searchItems = Storage.getLocalItem(PHARMACY_CONSTS.SEARCH_ITEMS);
            if (searchItems) {
                vm.pharmacy = angular.fromJson(searchItems);
                // clear items stored in local storage as we have them a in variable
                Storage.clearLocalData(PHARMACY_CONSTS.SEARCH_ITEMS);
            }
        };

        function closeModal(event) {
            $uibModalInstance.close(event === PHARMACY_CONSTS.OPEN_LIST_MODAL);
        }

        function searchPharmacy() {
            vm.isSearching = true;
            // store search fields in localStorage.
            Storage.saveToLocal(PHARMACY_CONSTS.SEARCH_ITEMS, angular.toJson(vm.pharmacy, true));

            searchPharmacies(vm.pharmacy).then(function () {
                vm.isSearching = false;
                closeModal(PHARMACY_CONSTS.OPEN_LIST_MODAL);
            });
        }

    }
})();
