(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Services.WarnOnLeaveService', [
            'eAccess.ProviderPortal.Services.CacheData'
        ])
        .factory('WarnOnLeave', WarnOnLeave);

    WarnOnLeave.$inject = ['$log', '$rootScope', '$window', '$state', '$uibModal', 'CacheDataService', 'Auth', 'ConfigurationService', 'HeaderService'];

    function WarnOnLeave($log, $rootScope, $window, $state, $uibModal, CacheDataService, Auth, ConfigurationService, HeaderService) {

        var service = {
            show: show,
            leave: leave,
            isFormDirty: false,
            setLeaveCallBack: setLeaveCallBack
        };

        var _leaveCallBack = null;

        function show(config) {
            service.config = config;
            var warnModal = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'warn-on-leave-title',
                arialDescribedBy: 'warn-on-leave-content',
                templateUrl: 'partials/templates/warn-on-leave-modal.html',
                controller: 'WarnOnLeaveModalController',
                controllerAs: 'warnOnLeave',
                appendTo: angular.element('body')
            });
            warnModal.result.then(angular.noop, function() {
                //This function gets called when backdrop click event is triggered
                service.isFormDirty = false;
            });
            return warnModal;
        }

        function leave() {
            if(service.config) {
                if(angular.isDefined(service.config.form)) {
                    service.config.form.$setPristine();
                }
                if(angular.isDefined(service.config.toState)) {
                    // hack for clearing cache BV data
                    // when user leave Med or Rx filled form then we need to clear the stored BV data
                    CacheDataService.clearBVCache();
                    if(service.config.toState.name === "login") {
                        Auth.logout();
                        ConfigurationService.resetPortalConfig();
                        HeaderService.changeLoggedInUserInfo();
                    }

                    if(angular.isDefined(service.config.toState.params)) {  // If maps of params are defined then send it to $stateParams
                        $state.go(service.config.toState.name, service.config.toState.params);
                    } else {
                        $state.go(service.config.toState.name);
                    }
                }
            } else {
                if(angular.isFunction(_leaveCallBack)) {
                    _leaveCallBack();
                }
            }
        }

        function setLeaveCallBack(callBack) {
            _leaveCallBack = callBack;
        }
        return service;
    }

})();
