/**
 * Created by aayush.regmi on 10/4/2017.
 */
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.ISIContent')
        .controller('ISIContentController', ISIContentController);

    ISIContentController.$inject = ['$anchorScroll', '$location', '$scope', 'IsiService'];

    function ISIContentController($anchorScroll, $location, $scope, IsiService) {
        var vm = this;

        vm.isiService = IsiService;

        vm.gotoFullISIContent = gotoFullISIContent;

        vm.getISIHeader = getISIHeader;

        vm.getISIPageContent = getISIPageContent;

        vm.getISIDisclaimer = getISIDisclaimer;

        vm.getStickyISIContent = getStickyISIContent;

        vm.getISIVisible = getISIVisible;

        $scope.$on('$stateChangeSuccess', function() {
            vm.isVisible = true;
        });

        function gotoFullISIContent() {
            $location.hash('isi-full');
            $anchorScroll();
        }

        function getISIHeader() {
            return vm.isiService.isiHeader;
        }

        function getISIPageContent() {
            return vm.isiService.isiContent;
        }

        function getISIDisclaimer() {
            return vm.isiService.isiDisclaimer;
        }

        function getStickyISIContent() {
            return vm.isiService.stickyIsiContent;
        }

        function getISIVisible() {
            return vm.isiService.isiVisible;
        }

    }


})();
