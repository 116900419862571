/**
 * @ngdoc Component
 * @module eAccess.ProviderPortal.Components.RxEligibilityResponse
 * @name eaPolicyResponseHeader
 * @kind Component
 *
 * @description
 * Policy list Section Block. It takes in policy information and renders
 * to the view
 *
 * Basic Usage
 * <ea-policy-response-header ></ea-policy-response-header>
 *
 * @author girianish
 */
(function() {

    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.RxEligibilityResponse')
        .component('eaPolicyResponseHeader', {
            bindings: {
                insurancePolicies: '<',
                request: '<',
                requestDetailsExpanded: '=',
                // Added as part of EP-1879
                // Index of selected policy coverage drawer
                // We need this to focus on button click
                selectedPolicyCoverageDrawer: '=',
                bvIncompleteStatus: '<',
                // For request submitted date available from mbv response
                requestDate:'<',
                declinedIndicator : '<',
                responseDate:'<',
                bvStatus: '<',
                bvType: '<',
                getDisplayValue: '<'
            },
            templateUrl: 'components/eligibility/bv-selection/rx/rx-response/policy-response-header/policy-response-header.html',
            controller: 'RxPolicyResponseHeaderController as rxPolicyResponseHeaderCtrl'
        });
})();
