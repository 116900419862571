/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.Components.Practice.User.View.controller:PracticeUserViewController
 * @author Pramesh Bhattarai
 * @requires PracticeUserService
 * @description
 *
 * This is the controller for the Practice User View page.
 **/
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Components.Practice')
        .controller('PracticeUserViewController', PracticeUserViewController);

    PracticeUserViewController.$inject = ['userResponse', 'SystemNotificationService'];

    function PracticeUserViewController(userResponse, SystemNotificationService) {
        var vm = this;

        // function
        vm.$onInit = function() {
            vm.myAccount = userResponse.myAccount;
            vm.user = userResponse.response;
            if(SystemNotificationService.showPortalNotification) {
                SystemNotificationService.displayInteriorNotification();
            }
        };
    }
})();
