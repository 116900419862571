/**
 * Created by girianish.
 */

(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal.Services.RxEligibilityResponse')
        .factory('RxEligibilityService', RxEligibilityService);


    RxEligibilityService.$inject = ['$log', '$location', '$anchorScroll', 'Utility', 'STATUS_CONSTS', 'CacheDataService', 'moment', 'ELIGIBILITY_CONSTS', 'HELP_TEXT_CONSTS', 'ConfigurationService', '$window', '$q'];

    function RxEligibilityService($log, $location, $anchorScroll, Utility, STATUS_CONSTS, CacheDataService, moment,  ELIGIBILITY_CONSTS, HELP_TEXT_CONSTS, ConfigurationService, $window, $q) {

        var service = {
            gotoContent: gotoContent,
            isEveryPolicyInsuranceStatusValueSame: isEveryPolicyInsuranceStatusValueSame,
            displayTransactionMessage: displayTransactionMessage,
            doesInsuranceStatusExist: doesInsuranceStatusExist,
            getFormattedDisclaimerContent: getFormattedDisclaimerContent,
            limitApplies: limitApplies,
            isManualBv: isManualBv,
            isBvStatusCompleted:isBvStatusCompleted,
            getPolicyCoverageToolTip: getPolicyCoverageToolTip,
            isManualAndComplete: isManualAndComplete,
            getRxApiType: getRxApiType,
            mapPharmacyStatus: mapPharmacyStatus,
            policyIsCovered:policyIsCovered,
            coverageStatusIsCovered:coverageStatusIsCovered
        };

        function gotoContent(idLink) {
            $location.hash(idLink);
            $anchorScroll();
        }

        function isEveryPolicyInsuranceStatusValueSame(policies, status) {
            return policies.every(function(policy) {
                var propertyExists = !Utility.isEmpty(policy) && !Utility.isEmpty(policy.plan) && !Utility.isEmpty(policy.plan.status) && !Utility.isEmpty(policy.plan.status.insuranceStatus);
                return propertyExists && Utility.lowerCase(policy.plan.status.insuranceStatus) === status;
            });
        }

        function displayTransactionMessage(insurancePolicies) {

            var isTransactionDisplayable = false;

            if (!Utility.isEmpty(insurancePolicies)) {
                if(insurancePolicies.length === 1) {
                    // for EP-1881, if there is one and only one policy returned in the RxBV API Response,
                    // and the Insurance Status for the policy 'Inactive,' and TransactionMessage exist then set Transaction message
                    isTransactionDisplayable = isEveryPolicyInsuranceStatusValueSame(insurancePolicies, STATUS_CONSTS.STATUS.INACTIVE);
                }

                if(insurancePolicies.length > 1 && !isTransactionDisplayable) {
                    // Added as part of EP-1889
                    // If multiple insurance policies is present, indicates that there is no Drug Coverage content
                    isTransactionDisplayable = isEveryPolicyInsuranceStatusValueSame(insurancePolicies, STATUS_CONSTS.STATUS.ACTIVE);
                }

                if(!isTransactionDisplayable) {
                    isTransactionDisplayable = doesInsuranceStatusExist(insurancePolicies, STATUS_CONSTS.STATUS.UNDETERMINED);
                }

            }

            return isTransactionDisplayable;
        }


        function doesInsuranceStatusExist(policies, status) {
            return Utility.containValueInCollection(policies, function (policy) {
                var propertyExists = !Utility.isEmpty(policy) && !Utility.isEmpty(policy.plan) && !Utility.isEmpty(policy.plan.status) && !Utility.isEmpty(policy.plan.status.insuranceStatus);
                return propertyExists && Utility.lowerCase(policy.plan.status.insuranceStatus) === status;
            });
        }

        /**
         * Replaces the @{RESPONSE_DATE} with the localized date provided
         * @param responseDate
         */
        function getFormattedDisclaimerContent(responseDate) {
            return !Utility.isEmpty(CacheDataService.rxDisclaimerContent) ? Utility.replaceText(CacheDataService.rxDisclaimerContent, '@{RESPONSE_DATE}', moment(responseDate).format('MM/DD/YYYY')) : '';
        }

        /**
         * Checks if list is not empty and has atleast one object(s).
         * @param limitList
         */
        function limitApplies(limitList) {
            return !Utility.isEmpty(limitList) && limitList.length > 0;
        }

        function isManualBv(bvtype) {
            return !Utility.isEmpty(bvtype) && Utility.lowerCase(bvtype) === ELIGIBILITY_CONSTS.BV_TYPE.MANUAL;
        }

        function isBvStatusCompleted(status) {
            return !Utility.isEmpty(status) && Utility.lowerCase(status) === STATUS_CONSTS.STATUS.COMPLETE;
        }

        function isManualAndComplete(bvtype, status) {
            return isManualBv(bvtype) && isBvStatusCompleted(status);
        }

        function getPolicyCoverageToolTip() {
            return {
                policyStatusValue: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_RESPONSE_HEADER.POLICY_STATUS_VALUE,
                productNameValue: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_RESPONSE_HEADER.PRODUCT_NAME_VALUE,
                daysSupplyLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_RESPONSE_HEADER.DAYS_SUPPLY_LABEL,
                productCoverageStatusLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_RESPONSE_HEADER.PRODUCT_COVERAGE_STATUS_LABEL,
                viewFullTransactionLink: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_COVERAGE.VIEW_FULL_TRANSACTION_LINK,
                pharmacyUsedLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_RESPONSE_HEADER.PHARMACY_USED_LABEL,
                totalPatientCostLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_RESPONSE_HEADER.TOTAL_PATIENT_COST_LABEL,
                patientCopayLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_COVERAGE.PATIENT_COPAY_LABEL,
                outOfPocketLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_COVERAGE.OUT_OF_POCKET_LABEL,
                deductibleLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_COVERAGE.DEDUCTIBLE_LABEL,
                planPayAmountLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_COVERAGE.PLAN_PAY_AMOUNT_LABEL,
                totalCostOfProductLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_COVERAGE.TOTAL_COST_OF_PRODUCT_LABEL,
                priorAuthorizationStatusLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_COVERAGE.PRIOR_AUTHORIZATION_STATUS_LABEL,
                formularyStatusLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_RESPONSE_HEADER.FORMULARY_STATUS_LABEL,
                coverageLimitationLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_COVERAGE.COVERAGE_LIMITATIONS_LABEL,
                additionalCoverageDetailsLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_RESPONSE_HEADER.ADDITIONAL_COVERAGE_DETAILS_LABEL,
                pharmacyCoverageRowLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_COVERAGE.PHARMACY_COVERAGE_ROW_LABEL,
                copayCoinsuranceRowLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_COVERAGE.COPAY_COINSURANCE_ROW_LABEL,
                copayTierRowLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_COVERAGE.COPAY_TIER_ROW_LABEL,
                copayMessageRowLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_COVERAGE.COPAY_MESSAGE_ROW_LABEL,
                outOfPocketMinMaxRowLabel: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_COVERAGE.OUT_OF_POCKET_MIN_MAX_ROW_LABEL,
                planCoverageLimitationSectionHeader: HELP_TEXT_CONSTS.RX_RESPONSE.POLICY_COVERAGE.PLAN_COVERAGE_LIMITATION_SECTION_HEADER
            };
        }

        function getRxApiType() {
            //Safe check to make sure we do not call the API until user has been logged in
            if(Utility.isEmpty($window.sessionStorage.getItem('auth_token'))) return $q.reject();
            if(!Utility.isEmpty(service.rxApiType)) {
                return $q.when(service.rxApiType);
            }
            var deferred = $q.defer();

            ConfigurationService.fetchPortalConfiguration().then(function(response) {
                service.rxApiType = Utility.lowerCase(response.orgPropertiesMap.rxApiType);
                return deferred.resolve(service.rxApiType);
            }).catch(function(err) {
                return deferred.reject(err);
            });

            return deferred.promise;
        }

        function mapPharmacyStatus(policy) {
            var plan = policy.plan;
            var coverage = policy.coverage;
            if(!Utility.isEmpty(plan.status)) {
                coverage["M"] && coverage["M"][0] ? coverage["M"][0].status = plan.status.mailOrderCoverageStatus : angular.noop();
                coverage["R"] && coverage["R"][0] ? coverage["R"][0].status = plan.status.retailCoverageStatus : angular.noop();
                coverage["S"] && coverage["S"][0] ? coverage["S"][0].status = plan.status.specialtyCoverageStatus : angular.noop();
                coverage["L"] && coverage["L"][0] ? coverage["L"][0].status = plan.status.ltcCoverageStatus : angular.noop();
            }
        }

        function policyIsCovered(policy) {
            if(!policy.plan.covered) {
                return false;
            }
            return policy.plan.covered;

        }

        function coverageStatusIsCovered(policy) {
            return Utility.lowerCase(policy.coverageStatus) === STATUS_CONSTS.COVERAGE.COVERED;
        }
        return service;
    }
})();
