/**
 * @ngdoc controller
 * @name eAccess.ProviderPortal.Components.Register.controller:ValidateUserController
 * @requires $log
 * @requires VALIDATE_USER_CONSTS
 * @requires RegisterView
 * @requires ConfigurationService
 * @author pramesh
 * @description
 *
 * This is the controller for the Validate User page.
 **/
(function() {
    'use strict';

    angular
        .module('eAccess.ProviderPortal')
        .controller('ValidateUserController', ValidateUserController);

    ValidateUserController.$inject = ['$log', 'VALIDATE_USER_CONSTS', 'RegisterView', 'ConfigurationService', 'ValidateUserService', 'GLOBAL_CONSTS'];

    function ValidateUserController($log, VALIDATE_USER_CONSTS, RegisterView, ConfigurationService, ValidateUserService, GLOBAL_CONSTS) {
        var vm = this;
        vm.assistanceMessage = null;

        init();

        function init() {
            RegisterView.configure({
                title: VALIDATE_USER_CONSTS.VALIDATE_USER.PAGE_TITLE
            });

            ConfigurationService.fetchRegistrationConfiguration().then(
                function(response) {
                    vm.assistanceMessage = response.customerSupport;
                }
            );
        }

        // Data
        vm.successMessage = VALIDATE_USER_CONSTS.VALIDATE_USER.SUCCESS_MESSAGE;
        vm.message = VALIDATE_USER_CONSTS.VALIDATE_USER.MESSAGE;
        vm.additionalMessage = GLOBAL_CONSTS.EMAIL.USER_NOTIFICATION;
        vm.resendEmail = VALIDATE_USER_CONSTS.VALIDATE_USER.RESEND_EMAIL;
        vm.emailSendMessage = GLOBAL_CONSTS.EMAIL.RESENT_MESSAGE;
        vm.emailSend = false;

        // function
        vm.resendEmailLink = resendEmailLink;

        function resendEmailLink(gUid) {
            ValidateUserService.resendEmailLink(gUid)
                .then(function(response) {
                    vm.emailSend = true;
                })
                .catch(function(error) {
                    $log.error('error: ' + error);
                })
                .finally(function() {
                    $log.error('unable to send email');
                });
        }

    }
})();
